import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { matterDetailsRequest } from '../../../api/matters';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface ScheduledMatterItemProps {
  matter: any;
}

const ScheduledMatterItem = ({ matter }: ScheduledMatterItemProps) => {
  const navigate = useNavigate();

  const handleDetailMatter = () => {
    matterDetailsRequest(matter.matter_id.toString()).then((response) => {
      navigate(`/matters/detail/${matter?.matter_id}`, {
        state: { response },
      });
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px',
        gap: '8px',
        width: '100%',
        backgroundColor: '#F0F8FD',
        borderRadius: '14px',
        border: '1px solid #08684E',
      }}
      onClick={() => handleDetailMatter()}
    >
      <Box>
        <Typography
          sx={{
            ...karlaProRegularFontStyles,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#202020',
          }}
        >
          {matter.title}
        </Typography>
      </Box>

      <Box
        key={matter.task_id}
        sx={{
          '& > div': {
            marginBottom: '2px',
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              color: '#202020',
              textTransform: 'capitalize',
            }}
          >
            {matter.stage}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontWeight: 300,
              fontSize: '10px',
              lineHeight: '12px',
              letterSpacing: '0.2px',
              textTransform: 'uppercase',
              color: '#464B53',
            }}
          >
            {matter.status} - Severity {matter.severity}
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '16px',
              letterSpacing: '0.2px',
              color: '#464B53',
            }}
          >
            {matter.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduledMatterItem;
