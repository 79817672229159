// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/store';
import { UploadedAllegationQuestion } from '../../../types/matters';

// Define a type for the slice state
interface MatterInformation {
  uploadedFilesNames: string[];
  uploadedAllegationQuestionnaireFiles: UploadedAllegationQuestion[];
  uploadedInterviewFilesNames: string[];
  uploadedInterviewQuestionnaireFiles: UploadedAllegationQuestion[];
}

// Define the initial state using that type
const initialState: MatterInformation = {
  uploadedFilesNames: [],
  uploadedAllegationQuestionnaireFiles: [],
  uploadedInterviewFilesNames: [],
  uploadedInterviewQuestionnaireFiles: [],
};

export const matterInfoSlice = createSlice({
  name: 'matterInformation',
  initialState,
  reducers: {
    addAllegationQuestionnaireFile: (
      state,
      action: PayloadAction<UploadedAllegationQuestion>
    ) => {
      const { payload } = action;
      // Check if the array is empty
      if (state.uploadedAllegationQuestionnaireFiles.length === 0) {
        state.uploadedAllegationQuestionnaireFiles.push(payload);
      } else {
        // Add the file to the array
        state.uploadedAllegationQuestionnaireFiles.push(payload);
      }
    },
    removeAllegationQuestionnaireFiles: (
      state,
      action: PayloadAction<string>
    ) => {
      const { payload } = action;
      // Find the index of the file in the array
      const index = state.uploadedAllegationQuestionnaireFiles.findIndex(
        (file) => file.fileName === payload
      );
      // Remove the file if found
      if (index !== -1) {
        state.uploadedAllegationQuestionnaireFiles.splice(index, 1);
      }
    },
    replaceAllegationQuestionnaireFiles: (
      state,
      action: PayloadAction<string>
    ) => {
      const fileName = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.uploadedAllegationQuestionnaireFiles =
        state.uploadedAllegationQuestionnaireFiles.filter(
          (file) => file.fileName === fileName
        );
    },
    clearAllegationQuestionnaireFiles: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.uploadedAllegationQuestionnaireFiles = [];
    },
    addUploadedFile: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      // Check if the array is empty
      if (state.uploadedFilesNames.length === 0) {
        state.uploadedFilesNames.push(payload);
      } else {
        // Add the file to the array
        state.uploadedFilesNames.push(payload);
      }
    },
    removeUploadedFile: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      // Find the index of the file in the array
      const index = state.uploadedFilesNames.findIndex(
        (file) => file === payload
      );
      // Remove the file if found
      if (index !== -1) {
        state.uploadedFilesNames.splice(index, 1);
      }
    },
    replaceFilesName: (state, action: PayloadAction<string>) => {
      const fileName = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.uploadedFilesNames = state.uploadedFilesNames.filter(
        (file) => file === fileName
      );
    },
    clearUploadedFiles: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.uploadedFilesNames = [];
    },
    addInterviewQuestionnaireFile: (
      state,
      action: PayloadAction<UploadedAllegationQuestion>
    ) => {
      const { payload } = action;
      // Check if the array is empty
      if (state.uploadedInterviewQuestionnaireFiles.length === 0) {
        state.uploadedInterviewQuestionnaireFiles.push(payload);
      } else {
        // Add the file to the array
        state.uploadedInterviewQuestionnaireFiles.push(payload);
      }
    },
    removeInterviewQuestionnaireFiles: (
      state,
      action: PayloadAction<string>
    ) => {
      const { payload } = action;
      // Find the index of the file in the array
      const index = state.uploadedInterviewQuestionnaireFiles.findIndex(
        (file) => file.fileName === payload
      );
      // Remove the file if found
      if (index !== -1) {
        state.uploadedInterviewQuestionnaireFiles.splice(index, 1);
      }
    },
    replaceInterviewQuestionnaireFiles: (
      state,
      action: PayloadAction<string>
    ) => {
      const fileName = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.uploadedInterviewQuestionnaireFiles =
        state.uploadedInterviewQuestionnaireFiles.filter(
          (file) => file.fileName === fileName
        );
    },
    clearInterviewQuestionnaireFiles: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.uploadedInterviewQuestionnaireFiles = [];
    },
    addInterviewUploadedFile: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      // Check if the array is empty
      if (state.uploadedInterviewFilesNames.length === 0) {
        state.uploadedInterviewFilesNames.push(payload);
      } else {
        // Add the file to the array
        state.uploadedInterviewFilesNames.push(payload);
      }
    },
    removeInterviewUploadedFile: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      // Find the index of the file in the array
      const index = state.uploadedInterviewFilesNames.findIndex(
        (file) => file === payload
      );
      // Remove the file if found
      if (index !== -1) {
        state.uploadedInterviewFilesNames.splice(index, 1);
      }
    },
    replaceInterviewFilesName: (state, action: PayloadAction<string>) => {
      const fileName = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.uploadedInterviewFilesNames =
        state.uploadedInterviewFilesNames.filter((file) => file === fileName);
    },
    clearInterviewUploadedFiles: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.uploadedInterviewFilesNames = [];
    },
  },
});

export const {
  // ALLEGATION FILES
  addAllegationQuestionnaireFile,
  removeAllegationQuestionnaireFiles,
  replaceAllegationQuestionnaireFiles,
  clearAllegationQuestionnaireFiles,
  addUploadedFile,
  removeUploadedFile,
  replaceFilesName,
  clearUploadedFiles,
  // INTERVIEW FILES
  addInterviewQuestionnaireFile,
  removeInterviewQuestionnaireFiles,
  replaceInterviewQuestionnaireFiles,
  clearInterviewQuestionnaireFiles,
  addInterviewUploadedFile,
  removeInterviewUploadedFile,
  replaceInterviewFilesName,
  clearInterviewUploadedFiles,
} = matterInfoSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectUploadedFiles = (state: RootState) =>
  state.matterInfoReducer.uploadedFilesNames;

export const selectUploadedAllegationQuestionnaireFiles = (state: RootState) =>
  state.matterInfoReducer.uploadedAllegationQuestionnaireFiles;

export const selectInterviewUploadedFiles = (state: RootState) =>
  state.matterInfoReducer.uploadedInterviewFilesNames;

export const selectUploadedInterviewQuestionnaireFiles = (state: RootState) =>
  state.matterInfoReducer.uploadedInterviewQuestionnaireFiles;

export default matterInfoSlice.reducer;
