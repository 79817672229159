const templatesListWrapperStyles = (interviewRowsAreEmpty: boolean) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
    height: !interviewRowsAreEmpty ? '540px' : '250px',
  };
};

export default templatesListWrapperStyles;
