import {
  Box,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useRef } from 'react';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import {
  leiaaRenameDialogContentStyles,
  leiaaRenameDialogWrapperStyles,
} from './styles';

export interface DocumentDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  label: string;
  primaryBtnText: string;
  onPrimaryButtonClick?: () => void;
  setInputValue: any;
}

const DocumentDialog = ({
  open,
  onClose,
  label,
  title,
  onPrimaryButtonClick,
  primaryBtnText,
  setInputValue,
}: DocumentDialogProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleRenameInputChange = () => {
    const inputText = inputRef.current?.value || '';
    setInputValue(inputText);
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={leiaaRenameDialogWrapperStyles}
    >
      <Box sx={leiaaRenameDialogContentStyles()}>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: '#202020',
                ...sofiaProBoldFontStyles,
                fontSize: '18px',
                lineHeight: 'normal',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <InputLabel
                  sx={{
                    ...karlaProRegularFontStyles,
                    paddingLeft: '12px',
                    fontSize: '14px',
                  }}
                  data-testid="matter-form-label-id"
                  htmlFor={label}
                >
                  {label}
                </InputLabel>
              </Box>

              <TextField
                id={label}
                placeholder="Enter name"
                sx={{
                  '&.MuiFormControl-root': {
                    padding: '0px 12px',
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #69707B',
                    borderRadius: '14px',
                    transition: 'border-color 0.3s',
                    '&:focus-within': {
                      borderColor: '#0053FF',
                    },

                    '& input': {
                      caretColor: '#0053FF',
                    },
                  },
                  '& > div': {
                    height: 'inherit',
                    gap: '8px',
                    '&:hover': {
                      cursor: 'default',
                    },
                    '& > input': {
                      ...karlaProRegularFontStyles,
                      '&:hover': {
                        cursor: 'default',
                      },
                      padding: '0px',
                      height: '22px',
                      fontSize: '14px',
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      '::placeholder': {
                        color: '#69707B',
                      },
                      color: '#202020',
                    },
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  onChange: handleRenameInputChange,
                  inputRef,
                }}
                variant="standard"
              />
            </Box>
            <Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={onClose}
                >
                  <Typography>Cancel</Typography>
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    width: '100%',
                  }}
                  disabled={!inputRef.current || inputRef.current?.value === ''}
                  onClick={onPrimaryButtonClick}
                >
                  <Typography>{primaryBtnText}</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentDialog;
