import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Box } from '@mui/material';
import WikiTemplate from './wiki-creation-steps/wiki-template-step/WikiTemplate';
import WikiCreationHeader from './WikiCreationHeader';
import WikiScopingInterview from './wiki-creation-steps/wiki-scoping-interview-step/WikiScopingInterview';
import WikiWitnessInterview from './wiki-creation-steps/wiki-witness-interview-step/WikiWitnessInterview';
import WikiEmail from './wiki-creation-steps/wiki-email-step/WikiEmail';
import WikiDocuments from './WikiDocuments';

interface WikiCreationContentProps {
  activeStep: number;
  visited: boolean[];
  steps: string[];
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  currentStep: number;
}

const WikiCreationContent = ({
  activeStep,
  visited,
  steps,
  control,
  watch,
  setValue,
  currentStep,
}: WikiCreationContentProps) => {
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <WikiTemplate control={control} />;
      case 1:
        return (
          <WikiScopingInterview
            control={control}
            watch={watch}
            setValue={setValue}
          />
        );
      case 2:
        return (
          <WikiWitnessInterview
            control={control}
            watch={watch}
            setValue={setValue}
          />
        );
      case 3:
        return (
          <WikiEmail control={control} watch={watch} setValue={setValue} />
        );
      case 4:
        return <WikiDocuments watch={watch} setValue={setValue} />;
      default:
        return null;
    }
  };
  return (
    <Box>
      <WikiCreationHeader
        activeStep={activeStep}
        steps={steps}
        visited={visited}
        currentStep={currentStep}
      />
      {renderStepContent()}
    </Box>
  );
};

export default WikiCreationContent;
