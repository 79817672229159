import { Box, InputLabel } from '@mui/material';
import { TimeField } from '@mui/x-date-pickers';
import { Control, Controller, FieldValues } from 'react-hook-form';
import dayjs from 'dayjs';
import { loginFormInputStyles } from '../login/styles';

interface LEIAATimeInputProps {
  labelText?: string;
  controllerName: string;
  control: Control<FieldValues>;
  inputPlaceholder: string;
  required?: boolean;
  handleChange?: any;
  inputWidth: string;
  inputHeight: string;
}

const LEIAATimeInput = ({
  labelText,
  controllerName,
  control,
  inputPlaceholder,
  inputWidth,
  inputHeight,
  required = true,
  handleChange,
}: LEIAATimeInputProps) => {
  const convert = (timeString: string) => {
    const [hoursStr, minutesStr] = timeString.split(':');
    const hours = parseInt(hoursStr, 10); // Convert hoursStr to a number
    const minutes = parseInt(minutesStr, 10); // Convert minutesStr to a number

    const currentDate = new Date();
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);

    // Convert the date object to a string representation
    const dateString = `${currentDate.toDateString()} ${currentDate.toTimeString()}`;

    return dateString;
  };
  return (
    <Box>
      {labelText && (
        <InputLabel data-testid="matter-form-label-id" htmlFor={labelText}>
          {required ? '*' : ''}
          {labelText}
        </InputLabel>
      )}
      <Controller
        name={controllerName}
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...rest } }) => {
          return (
            <TimeField
              value={rest.value !== '' ? dayjs(convert(rest.value)) : null}
              inputProps={{ placeholder: inputPlaceholder }}
              size="small"
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                ...loginFormInputStyles(inputWidth, inputHeight),
                background: 'unset',
              }}
              onChange={(e) => {
                let formattedTime = '';
                let date;
                let hours;
                let minutes;

                if (e !== null) {
                  const addLeadingZero = (num: number) => {
                    return num < 10 ? `0${num}` : num.toString();
                  };

                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  date = new Date((e as any).$d!);
                  hours = date.getHours();
                  minutes = date.getMinutes();
                  formattedTime = `${hours}:${addLeadingZero(minutes)}`;
                }
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                onChange(formattedTime);

                if (handleChange) {
                  handleChange(formattedTime);
                }
              }}
              format="HH:mm"
            />
          );
        }}
      />
    </Box>
  );
};

export default LEIAATimeInput;
