import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  secondaryButtonStyles,
  primaryButtonStyles,
  templateCreationConfirmationWrapperStyles,
} from '../styles';
import CreationDocumentNotification from './CreationDocumentNotification';

const WikiRepositoryConfirmation = () => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShowNotification(true);
    }, 125);

    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleGoToRepositoryList = () => {
    navigate(-1);
  };

  const handleGoToRepositoryCreation = () => {
    navigate(0);
  };

  return (
    <Box sx={templateCreationConfirmationWrapperStyles}>
      <Box>
        <Box>
          <Box>
            <svg
              width="194"
              height="244"
              viewBox="0 0 194 244"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_1291_270289)">
                <rect
                  x="30"
                  y="30"
                  width="134"
                  height="184"
                  rx="16.8"
                  fill="#F0F8FD"
                  shapeRendering="crispEdges"
                />
                <rect
                  x="54"
                  y="66"
                  width="12"
                  height="12"
                  rx="6"
                  transform="rotate(-90 54 66)"
                  fill="#6A9FFB"
                />
                <rect
                  x="72"
                  y="66"
                  width="12"
                  height="68"
                  rx="6"
                  transform="rotate(-90 72 66)"
                  fill="#6A9FFB"
                />
                <rect
                  x="54"
                  y="80"
                  width="12"
                  height="110"
                  rx="6"
                  fill="#D3ECF7"
                />
                <rect
                  x="91"
                  y="80"
                  width="12"
                  height="58"
                  rx="6"
                  fill="#D3ECF7"
                />
                <rect
                  x="128"
                  y="80"
                  width="12"
                  height="85"
                  rx="6"
                  fill="#D3ECF7"
                />
                <rect
                  x="30.5"
                  y="30.5"
                  width="133"
                  height="183"
                  rx="16.3"
                  stroke="#E4F5FF"
                  shapeRendering="crispEdges"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_1291_270289"
                  x="0"
                  y="0"
                  width="194"
                  height="244"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="15" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0.368627 0 0 0 0 0.992157 0 0 0 0.08 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1291_270289"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1291_270289"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={secondaryButtonStyles()}
              onClick={handleGoToRepositoryList}
            >
              <span className="material-icons-round">people</span>
              <Typography>View Documents List</Typography>
            </Button>
            <Button
              variant="contained"
              sx={primaryButtonStyles()}
              onClick={handleGoToRepositoryCreation}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add New Document</Typography>
            </Button>
          </Box>
        </Box>
        {showNotification && (
          <CreationDocumentNotification
            showNotification={showNotification}
            handleCloseNotification={handleCloseNotification}
          />
        )}
      </Box>
    </Box>
  );
};

export default WikiRepositoryConfirmation;
