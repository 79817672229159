import { useEffect, useRef, useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MattersListTable from '../components/shared/matters-list-table/MattersListTable';
import ResourcePageHeader from '../components/shared/resource-page-header/ResourcePageHeader';
import './styles/matterlist.css';
import '../components/styles/datagridStyles.css';
import BaseButton from '../components/base/BaseButton';
import ListButtons from '../components/shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../components/shared/resource-page-header/styles';
import { batchDeleteMatterRequest, matterDetailsRequest } from '../api/matters';
import { MattersListResponse } from '../types/homeportal';
import { mattersListRequest } from '../api/homeportal';
import { MattersListItem } from '../components/shared/matters-list-table/types';
import { useMatterPermissions } from '../hooks/usePermissions';
import NotificationToast from '../components/shared/toast/NotificationToast';
import CreationNotification from '../components/shared/creation-notification/CreationNotification';

const MatterList = () => {
  const matterPerms = useMatterPermissions();
  const navigate = useNavigate();
  const [checkedMatters, setCheckedMatters] = useState([]);
  const [selectedMatter, setSelectedMatter] = useState<MattersListItem | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [mattersListData, setMattersListData] =
    useState<MattersListResponse | null>(null);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);

  const handleFetchMattersList = async (searchText = '') => {
    setLoading(true); // Start loading

    try {
      const response = await mattersListRequest(false, searchText);
      setMattersListData(response as MattersListResponse);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'An unknown error related to the matters list occurred.'
        );
      }
    } finally {
      setLoading(false);
      setCheckedMatters([]);
    }
  };

  useEffect(() => {
    handleFetchMattersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBatchDeleteMatters = async () => {
    try {
      await batchDeleteMatterRequest(checkedMatters);
      await handleFetchMattersList();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchMattersList(searchText);
  };

  const handleEditMatter = async () => {
    try {
      if (selectedMatter?.id) {
        matterDetailsRequest(selectedMatter.id.toString()).then((response) => {
          navigate(`/matters/edit/${selectedMatter?.id}`, {
            state: { currentStep: 0, matterDetails: response, isEdit: true },
          });
        });
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleDetailUser = (matter: any) => {
    navigate(`/matters/detail/${matter?.id}`, {
      state: { matter },
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Matter archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      <ResourcePageHeader inputRef={searchInputRef} headerText="Matters" />

      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {matterPerms.add && (
            <BaseButton
              iconClass="material-icons-round"
              iconText="add"
              buttonText="New Matter"
              linkTo="/matters/new"
              width="151px"
            />
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            {/* Search */}
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by matter..."
              sx={{
                ...homePortalSearchInputStyles,
                marginLeft: 'auto',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
            {checkedMatters.length > 0 && (
              // Edit - Archive buttons
              <ListButtons
                deleteHandler={matterPerms.delete && handleBatchDeleteMatters}
                editHandler={matterPerms.change && handleEditMatter}
                checkedResources={checkedMatters}
                resourceName="matter"
              />
            )}
          </Box>
        </Box>
        <Grid item xs={12} md={12}>
          <MattersListTable
            mattersListData={mattersListData}
            loading={loading}
            listClass="matters-list"
            setCheckedMatters={setCheckedMatters}
            setSelectedMatter={setSelectedMatter}
            handleDetailUser={handleDetailUser}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default MatterList;
