import { Box, Modal } from '@mui/material';
import MatterDetailNotes from '../matters/matter-detail/MatterDetailNotes';

interface MatterNotesListModalProps {
  matterId: any;
  open: boolean;
  setOpen: any;
  resourceName: any;
  resourceId: any;
}

const MatterNotesListModal = ({
  matterId,
  open,
  setOpen,
  resourceName,
  resourceId,
}: MatterNotesListModalProps) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '1200px',
          height: 'auto',
          padding: '48px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '24px',
          border: '1px solid #D3ECF6',
          background: '#FFF',
          boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
          zIndex: '5',
        }}
      >
        <MatterDetailNotes
          matterId={matterId}
          matterDetails={undefined}
          isModal
          resourceName={resourceName}
          taggedResourceId={resourceId}
        />
      </Box>
    </Modal>
  );
};

export default MatterNotesListModal;
