import { Box, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  simpleGoBackButtonStyles,
} from '../styles';
import {
  leiaaDialogContentStyles,
  leiaaDialogWrapperStyles,
} from '../matters/matter-creation/styles';
import { karlaProRegularFontStyles } from '../../styles/textStyles';

export interface LEIAADialogProps {
  open: boolean;
  onClose: () => void;
  hasBackButton?: boolean;
  title: string;
  description: string;
  primaryButtonText?: string;
  primaryButtonHelperText?: string;
  onPrimaryButtonClick?: () => void;
  primaryButtonHasIcon?: boolean;
  primaryButtonIcon?: string;
  secondaryButtonText?: string;
  secondaryButtonHelperText?: string;
  onSecondaryButtonClick?: () => void;
  secondaryButtonHasIcon?: boolean;
  secondaryButtonIcon?: string;
  simpleButtonText?: string;
  onSimpleButtonClick?: () => void;
  simpleButtonIcon?: string;
  onGoBackButtonClick?: () => void;
  swapButtonStyles?: boolean;
  listingContent?: any;
  isTextAlign?: boolean;
}

const LEIAADialog = ({
  open,
  onClose,
  hasBackButton = false,
  title,
  description,
  primaryButtonText,
  primaryButtonHelperText,
  onPrimaryButtonClick,
  primaryButtonHasIcon = true,
  primaryButtonIcon,
  secondaryButtonText,
  secondaryButtonHelperText,
  onSecondaryButtonClick,
  secondaryButtonHasIcon = true,
  secondaryButtonIcon,
  simpleButtonText,
  onSimpleButtonClick,
  simpleButtonIcon,
  onGoBackButtonClick,
  swapButtonStyles = false,
  listingContent,
  isTextAlign = false,
}: LEIAADialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={leiaaDialogWrapperStyles}>
      <Box sx={leiaaDialogContentStyles(hasBackButton, isTextAlign)}>
        <Box>
          {hasBackButton && (
            <Button
              variant="text"
              sx={simpleGoBackButtonStyles}
              onClick={onGoBackButtonClick}
            >
              <span className="material-icons-round">arrow_back</span>
              <Typography variant="caption" component="p">
                Back
              </Typography>
            </Button>
          )}

          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <span className="material-icons-round">warning</span>
            <Box>
              <Typography>{title}</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography textAlign={isTextAlign ? 'center' : 'left'}>
                {description}
              </Typography>
            </Box>
            {secondaryButtonText && primaryButtonText ? (
              <Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      ...(swapButtonStyles
                        ? primaryButtonStyles()
                        : secondaryButtonStyles()),
                      width: '100%',
                    }}
                    onClick={onSecondaryButtonClick}
                  >
                    {secondaryButtonHasIcon ? (
                      <span className="material-icons-round">
                        {secondaryButtonIcon}
                      </span>
                    ) : null}
                    <Typography>{secondaryButtonText}</Typography>
                  </Button>

                  {secondaryButtonHelperText && (
                    <Box>
                      <Typography>{secondaryButtonHelperText}</Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      ...(swapButtonStyles
                        ? secondaryButtonStyles()
                        : primaryButtonStyles()),
                      width: '100%',
                    }}
                    onClick={onPrimaryButtonClick}
                  >
                    {primaryButtonHasIcon ? (
                      <span className="material-icons-round">
                        {primaryButtonIcon}
                      </span>
                    ) : null}
                    <Typography>{primaryButtonText}</Typography>
                  </Button>

                  {primaryButtonHelperText && (
                    <Box>
                      <Typography>{primaryButtonHelperText}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : null}
            {simpleButtonText && (
              <Button
                variant="contained"
                sx={{
                  ...primaryButtonStyles(),
                  alignSelf: 'center',
                  width: '120px',
                }}
                onClick={onSimpleButtonClick}
              >
                {simpleButtonIcon ? (
                  <span className="material-icons-round">
                    {simpleButtonIcon}
                  </span>
                ) : null}
                <Typography>{simpleButtonText}</Typography>
              </Button>
            )}
          </Box>
          {listingContent && (
            <Box
              sx={{
                width: '100%',
                paddingLeft: '24px',
                '& > div': {
                  display: 'flex',
                  gap: '4px',
                  '& > p': {
                    color: '#202020',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                  },
                },
              }}
            >
              {listingContent.map((lc: any, index: number) => {
                return (
                  <Box key={lc.id}>
                    <Typography>
                      {index + 1}. {lc.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default LEIAADialog;
