import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface FileTypeCellProps {
  file: boolean;
  name: string;
}

const FileOrDocCell = ({ file, name }: FileTypeCellProps) => {
  const icon = file ? 'folder' : 'text_snippet';

  return (
    <Box>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          color: '#69707B',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          lineHeight: '22px',
          '& > span': {
            fontSize: '20px',
          },
        }}
      >
        <span className="material-icons-round">{icon}</span>
        {name}
      </Typography>
    </Box>
  );
};

export default FileOrDocCell;
