import { Box, Modal, CircularProgress } from '@mui/material';

interface LoaderSpinnerProps {
  loading: any;
  onClose: any;
}
const LoaderSpinner = ({ loading, onClose }: LoaderSpinnerProps) => {
  return (
    <Modal
      open={loading}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: '15px',
          borderRadius: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            '& > span': {
              color: '#0053FF',
            },
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    </Modal>
  );
};
export default LoaderSpinner;
