import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';
import {
  insightsContentStyles,
  insightsTitleStyles,
} from '../../homeportal/insights-content/styles';
import { loginFormInputStyles } from '../../login/styles';
import { secondaryButtonStyles } from '../../styles';

export const matterDetailHeaderWrapperStyles = {
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        height: '40px',
        padding: '10px 0px',
        alignItems: 'flex-start',
        gap: '5px',
        '& > p': {
          color: '#202020',
          ...sofiaProBoldFontStyles,
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: 'normal',
          letterSpacing: '2px',
          textTransform: 'uppercase',
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        '& > p': {
          color: '#949CA9',
          ...karlaProRegularFontStyles,
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          letterSpacing: '0.2px',
        },
      },
    },
  },
};

export const matterDetailHeaderDateStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  padding: '9px 16px',
  border: '1px solid #D3ECF6',
  borderRadius: '12px',
  width: 'fit-content',
  marginTop: '-6px',
  '& > div': {
    '& > p': {
      color: '#69707B',
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
    },
  },
};

export const matterDetailHeaderStepperStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '9px',
  flex: '1 0 0',
  '& > div.MuiStep-root': {
    padding: '0px !important',
    margin: '0px',
    display: 'flex',
    height: '32px',
    paddingTop: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flex: '1 0 0',
    '& > button': {
      '&.Mui-disabled': {
        opacity: 0.5,
      },
      display: 'flex',
      padding: '0px 0px 0px 16px',
      alignItems: 'center',
      flex: '1 0 0',
      alignSelf: 'stretch',
      margin: '0px',
      justifyContent: 'unset',
      '& > span': {
        gap: '8px',
        '& > span.MuiStepLabel-iconContainer:not(.Mui-active)': {
          '& > span': {
            color: '#69707B',
          },
          '& > svg > path': {
            fill: '#69707B',
          },
        },
        '& > span.MuiStepLabel-iconContainer.Mui-active': {
          '& > span': {
            color: '#0053FF',
          },
          '& > span > svg': {
            fill: '#0053FF',
          },
          '& > span > svg > path': {
            fill: '#0053FF !important',
          },
        },
        '& > span.MuiStepLabel-labelContainer': {
          '& > span': {
            color: '#69707B',
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            fontWeight: 500,
            lineHeight: '24px',
          },
          '& > span.Mui-active': {
            color: '#0053FF',
          },
        },
      },
    },
  },
  '& > .MuiStepConnector-root': {
    display: 'none', // Add this line to hide the MuiStepConnector
  },
};

export const matterDetailGeneralInsightsWrapperStyles = {
  paddingTop: '0px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  width: '100%',
  '& > div': {
    backgroundColor: '#F2FAFE',
    border: '1px solid #F0F8FD',
    borderRadius: '24px',
    padding: '24px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      '& > p': {
        ...insightsContentStyles,
      },
      '& > div': {
        '& > h1': {
          ...insightsTitleStyles,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        gap: '2px',
        alignSelf: 'stretch',
        '& > p': {
          color: '#0053FF',
          ...sofiaProRegularFontStyles,
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: 'normal',
          letterSpacing: '0.3px',
        },
      },
    },
  },
};

// Task Details page styles

export const taskDetailsStyles = {
  pageWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '48px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '40px',
  },

  header: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      '& > div': {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },

    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',

      '& .MuiBreadcrumbs-separator': {
        // align arrow with text
        paddingBottom: '12px',
      },

      '& .MuiBreadcrumbs-separator > span': {
        width: '16px',
        height: '16px',
        // the > separator icon is based on a character
        // so its sizing is done via font size instead of with/height
        fontSize: '16px',
      },

      '& > div:nth-of-type(1)': {
        display: 'flex',
        height: '40px',
        padding: '10px 0px',
        alignItems: 'flex-start',
        gap: '5px',
        '& > h4': {
          ...sofiaProRegularFontStyles,
          color: '#202020',
          fontSize: '18px',
          fontWeight: 700,
          letterSpacing: '2px',
          textTransform: 'uppercase',
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        '& > span': {
          ...karlaProRegularFontStyles,
          color: '#949CA9',
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0.2px',
        },
      },
    },

    breadcrumbs: {
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
      },

      link: {
        ...sofiaProRegularFontStyles,
        color: 'var(--shades-grey-5, #69707B)',
        fontSize: '18px',
        lineHeight: 'normal',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        padding: '10px 0px',
      },

      lastLink: {
        ...sofiaProBoldFontStyles,
        color: 'var(--shades-black, #202020)',
        fontSize: '18px',
        lineHeight: 'normal',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        padding: '10px 0px',
      },

      subtitle: {
        color: 'var(--shades-grey-4, #949CA9)',
        ...karlaProRegularFontStyles,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.2px',
      },
    },
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'strech',
    width: '100%',

    overflowX: 'hidden',
    overflowY: 'scroll',
  },

  taskFieldsWrapper: {
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    flexShrink: 3,

    width: 'min(480px, 50%)',
  },

  taskIndividualField: {
    wrapper: {
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
      alignSelf: 'stretch',
      alignItems: 'flex-start',
    },

    header: {
      color: 'var(--shades-grey-7, #464B53)',
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      lineHeight: '22px',
    },

    field: {
      color: 'var(--shades-grey-5, #69707B)',
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
};

export const taskCommentsStyles = {
  wrapper: {
    // layout
    display: 'flex',
    minWidth: '20%',
    width: '608px',
    maxWidth: '50%',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    maxHeight: '400px',
    overflowY: 'scroll',

    // style
    borderRadius: '24px',
    border: '1px solid var(--secondary-colours-sky-blue-4, #D3ECF6)',
    background: 'var(--White, #FFF)',
  },

  title: {
    ...karlaProRegularFontStyles,
    color: 'var(--main-colours-dark-blue, #140070)',
    fontSize: '17px',
    lineHeight: '30px',
  },

  commentList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },

  input: (() => {
    const baseFormStyle = loginFormInputStyles('100%', 'fit-content');
    // remove the !important in the height prop but keep everything else
    // this will allow the text area to grow vertically with its content
    baseFormStyle['& > div']['& > textarea'].height = 'auto';

    return {
      ...baseFormStyle,
      marginTop: '16px',
    };
  })(),

  addCommentButton: {
    ...secondaryButtonStyles(),
    height: '30px',
    padding: '8px 16px',
    gap: '8px',
  },

  addCommentButtonText: {
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  divider: {
    border: '1px solid #D3ECF6',
    width: '100%',
  },
};
