import { Box, IconButton } from '@mui/material';
import { GridColumnHeaderParams, GridSortDirection } from '@mui/x-data-grid';

interface SortableHeaderProps {
  params: GridColumnHeaderParams;
  sort: GridSortDirection;
}

const SortableHeader = ({ params, sort }: SortableHeaderProps) => {
  const isSorting = Boolean(sort);
  const isAscending = sort === 'asc';

  const commonIconButtonStyles = {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      color: '#949CA9',
      lineHeight: '8px',
    },
  };

  let arrowUpColor = '#949CA9';
  let arrowDownColor = '#949CA9';

  if (isSorting) {
    arrowUpColor = isAscending ? '#0053fe' : arrowUpColor;
    arrowDownColor = isAscending ? arrowDownColor : '#0053fe';
  }

  const iconComponent = (
    <IconButton size="small" sx={commonIconButtonStyles}>
      <span className="material-icons-round" style={{ color: arrowUpColor }}>
        arrow_drop_up
      </span>
      <span className="material-icons-round" style={{ color: arrowDownColor }}>
        arrow_drop_down
      </span>
    </IconButton>
  );

  return (
    <>
      <Box
        className="MuiDataGrid-columnHeaderTitle"
        sx={{
          color: isSorting ? '#0053fe' : '#69707b',
          fontWeight: isSorting ? '600' : '400',
        }}
      >
        {params.colDef.headerName}
      </Box>
      {isSorting ? (
        iconComponent
      ) : (
        <IconButton size="small" sx={commonIconButtonStyles}>
          <span className="material-icons-round">arrow_drop_up</span>
          <span className="material-icons-round">arrow_drop_down</span>
        </IconButton>
      )}
    </>
  );
};

export default SortableHeader;
