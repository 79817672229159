import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
  sofiaProRegularFontStyles,
} from '../../../../../styles/textStyles';

export const matterInformationWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};

export const matterInformationFormWrapperStyles = {
  display: 'flex',
  width: '608px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  '& > p': {
    color: '#202020',
    fontSize: '14px',
    ...karlaProRegularFontStyles,
    lineHeight: '22px',
  },
};

export const matterInformationInputWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '32px',
    alignSelf: 'stretch',
    '& > div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
      '& > label.MuiFormLabel-root': {
        display: 'flex',
        padding: '0px 12px',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        color: '#464B53',
        fontSize: '14px',
        ...karlaProRegularFontStyles,
        lineHeight: '22px',
      },
      '& > div.MuiFormControl-root': {
        margin: '0px',
        padding: '0px',
      },
    },
  },
  '& > div > .engaged-box-report': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    padding: '15px',
    background: '#f1fafd',
    borderRadius: '24px',
    '& > div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
      '& > label.MuiFormLabel-root': {
        display: 'flex',
        padding: '0px 12px',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        color: '#464B53',
        fontSize: '14px',
        ...karlaProRegularFontStyles,
        lineHeight: '22px',
      },
      '& > div.MuiFormControl-root': {
        background: 'white',
        borderRadius: '24px',
        margin: '0px',
        padding: '0px',
      },
    },
    '& > div:last-child': {
      marginLeft: 'auto',
      width: 'unset !important',
    },
  },
};

export const matterDocumentsInputWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
  },
};

export const matterInformationSelectStyles = (
  height: string,
  width: string,
  baseHeight = 'unset'
) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#202020',
    background: 'white',
    height: baseHeight,
    '& > div': {
      width,
      height,
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: '#0053FF !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      border: '1px solid #32353C',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
    },
  };
};

export const matterInformationSelectPaperStyles = {
  marginTop: '8px',
  borderRadius: '14px',
  border: '1px solid #0053FF',
  boxShadow: '0px 0px 10px 0px rgba(21, 10, 84, 0.30)',
  '& > ul': {
    '& > li': {
      color: '#464B53',
      fontSize: '14px',
      ...karlaProRegularFontStyles,
      lineHeight: '22px',
      '&:hover': {
        backgroundColor: '#EBF8FE',
      },
    },
  },
};

export const matterInformationNestedInputWrapperStyles = (
  isDoubleNested: boolean
) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px !important',
    '& > p': {
      color: '#202020',
      fontSize: '14px',
      ...karlaProRegularFontStyles,
      lineHeight: '22px',
    },
    '& > div': {
      ...(isDoubleNested
        ? {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
          }
        : {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
            paddingLeft: '24px',
          }),
      '& > label.MuiFormLabel-root': {
        display: 'flex',
        padding: '0px 12px',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        color: '#464B53',
        fontSize: '14px',
        ...karlaProRegularFontStyles,
        lineHeight: '22px',
      },
      '& > div.MuiFormControl-root': {
        margin: '0px',
        padding: '0px',
      },
    },
  };
};

export const matterQuestionnaireStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px !important',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
  },
};

export const matterUploadAreaAndButtonsWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
};

export const matterQuestionnaireRadioGroupWrapperStyles = {
  '& > div': {
    display: 'flex',
    paddingLeft: '16px',
    alignItems: 'flex-start',
    gap: '32px',
    '& > label': {
      margin: '0px',
      display: 'flex',
      gap: '8px',
      '& > span': {
        padding: '0px',
        margin: '0px',
      },
      '& > span:nth-of-type(1) ': {
        '&.Mui-checked': {
          color: '#0053FF',
        },
        '& > span > svg': {
          width: '16px',
          height: '16px',
        },
      },
      '& > span:nth-of-type(2)': {
        color: '#202020',
        fontSize: '14px',
        ...karlaProRegularFontStyles,
        lineHeight: '22px',
      },
      '& > span:nth-of-type(3)': {
        display: 'none',
      },
    },
  },
};

export const matterQuestionnaireUploadAreaWrapperStyles = {
  display: 'flex',
  paddingLeft: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& > div': {
    display: 'flex',
    padding: '24px 32px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '24px !important',
    alignSelf: 'stretch',
    borderRadius: '24px',
    border: '1px dashed #81D4E1',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',

      '& > p': {
        color: '#69707B',
        ...karlaProRegularFontStyles,
        lineHeight: 'normal',
        fontSize: '13px',
        fontWeight: 400,
      },
      '& > div': {
        display: 'flex',
        '&  > p': {
          color: '#69707B',
          ...karlaProRegularFontStyles,
          fontSize: '10px',
          fontWeight: 300,
          letterSpacing: '0.2px',
        },
        '& > div': {
          cursor: 'pointer',
          marginLeft: '2px',
          '&  > p': {
            ...karlaProRegularFontStyles,
            color: '#0053FF',
            fontSize: '10px',
            fontWeight: 300,
            letterSpacing: '0.2px',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '16px',
      alignSelf: 'stretch',
      '& > input': {
        display: 'none',
      },
      '& > div': {
        display: 'flex',
        paddingTop: '14px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
        flex: '1 0 0',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
          justifyContent: 'space-between',
          '& > p': {
            color: '#949CA9',
            ...karlaProRegularItalicFontStyles,
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: 'normal',
          },
        },
      },
    },
  },
};

export const matterQuestionnaireManualQuestionsWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    paddingLeft: '24px',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& > p': {
      color: '#464B53',
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
  },
  '& > div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
};

export const matterQuestionnaireAccordionStyles = {
  boxShadow: 'unset',
  border: 'none',
  outline: 'none',
  width: '100%',
  '& > div.MuiCollapse-root': {
    paddingLeft: '40px',
  },
  '& > div:nth-of-type(1)': {
    borderBottom: '1px solid #0053FF',
    minHeight: '50px !important',
    marginLeft: '24px',
    '.Mui-expanded': {
      minHeight: 'unset',
      margin: '0px !important',
    },
    '& > div': {
      '& > p': {
        color: '#0053FF',
        ...karlaProRegularFontStyles,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
      },
      '& > span': {
        color: '#0053FF',
      },
    },
  },
};

export const matterQuestionnaireAccordionDetailsStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  padding: '0px',
  '& > div': {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '16px',
    width: '100%',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      width: '24px',
      height: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: '12px',
      border: '1px solid #140070',
      '& > p': {
        color: '#140070',
        textAlign: 'center',
        ...sofiaProRegularFontStyles,
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.3px',
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      flex: '1 0 0',
      alignSelf: 'stretch',
      '& > div:nth-of-type(2n+1)': {
        display: 'flex',
        padding: '0px 12px',
        alignItems: 'center',
        alignSelf: 'stretch',
        '& > div': {
          width: '100%',
        },
      },
      '& > div:nth-of-type(2)': {
        width: '100%',
      },
      '& > div:nth-of-type(1) > p': {
        color: '#464B53',
        ...karlaProRegularFontStyles,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      '& > div:nth-of-type(3) > p': {
        color: '#69707B',
        ...karlaProRegularFontStyles,
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.55px',
      },
    },
    '& > div:nth-of-type(3)': {
      display: 'flex',
      width: '16px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      flexShrink: 0,
    },
  },
};

export const matterInterviewQuestionnaireAccordionDetailsStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  padding: '0px',
  '& > div': {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      width: '24px',
      height: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: '12px',
      border: '1px solid #140070',
      '& > p': {
        color: '#140070',
        textAlign: 'center',
        ...sofiaProRegularFontStyles,
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.3px',
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      flex: '1 0 0',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1), & > div:nth-of-type(2)': {
        display: 'flex',
        padding: '0px 12px',
        alignItems: 'center',
        alignSelf: 'stretch',
        '& > div': {
          width: '100%',
        },
      },
      '& > div:nth-of-type(1) > p': {
        color: '#464B53',
        ...karlaProRegularFontStyles,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      '& > div:nth-of-type(2) > p': {
        color: '#69707B',
        ...karlaProRegularFontStyles,
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.55px',
      },
      '& > div:nth-of-type(3)': {
        width: '100%',
        '& > label.MuiFormLabel-root': {
          display: 'flex',
          padding: '0px 12px',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          color: '#464B53',
          fontSize: '14px',
          ...karlaProRegularFontStyles,
          lineHeight: '22px',
        },
      },
    },
    '& > div:nth-of-type(3)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      height: '30px',
      flexShrink: 0,
    },
  },
  '& > div:nth-of-type(1)': {
    marginTop: '16px',
  },
};

export const matterQuestionnaireAddButtonWrapperStyles = {
  display: 'flex',
  paddingLeft: '64px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
};

export const matterTemplateAddButtonWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '15px 0px',
};

export const uploadedAllegationQuestionnaireWrapperStyles = {
  display: 'flex',
  paddingTop: '76px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
  '& > div': {
    display: 'flex',
    width: '480px',
    padding: '24px 16px 16px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '24px',
    backgroundColor: '#F5FAFD',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div': {
        display: 'flex',
        padding: '8px 0px 8px 16px',
        alignItems: 'center',
        gap: '8px',
        '& > *': {
          color: '#140070',
        },
        '& > p': {
          ...sofiaProRegularFontStyles,
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: 'normal',
          letterSpacing: '0.3px',
        },
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
      alignSelf: 'stretch',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
      },
    },
  },
};

export const uploadedAllegationQuestionnaireAccordionStyles = {
  boxShadow: 'unset',
  border: 'none',
  outline: 'none',
  width: '100%',
  backgroundColor: '#EBF8FE',
  '&::before': {
    content: 'none',
  },
  '&.Mui-expanded': {
    minHeight: 'unset',
    margin: '0px !important',
  },
  '& > div:nth-of-type(1)': {
    padding: '8px 16px',
    minHeight: '34px !important',
    borderRadius: '12px',
    backgroundColor: '#D3ECF6',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      margin: '0px !important',
      minHeight: '34px !important',
    },
    '.Mui-expanded': {
      minHeight: 'unset',
      margin: '0px !important',
    },
    '& > div:nth-of-type(2) > span': {
      color: '#000000',
    },
  },
};

export const uploadedAllegationQuestionnaireAccordionSummaryContentStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& > p:nth-of-type(1)': {
      color: '#949CA9',
      ...sofiaProRegularFontStyles,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.3px',
    },
    '& > p:nth-of-type(2)': {
      color: '#464B53',
      ...sofiaProRegularFontStyles,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.3px',
    },
  },
  '& > p': {
    color: '#69707B',
    ...karlaProRegularItalicFontStyles,
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
};

export const uploadedAllegationQuestionnaireAccordionDetailsStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: '#EBF8FE',
  '& > div': {
    '& > div:nth-of-type(1)': {
      display: 'flex',
      padding: '16px 16px 0px 24px',
      alignItems: 'flex-start',
      '& > p': {
        color: '#464B53',
        ...karlaProRegularFontStyles,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      padding: '8px 16px 16px 48px',
      alignItems: 'flex-start',
      '& > p': {
        color: '#464B53',
        ...karlaProRegularFontStyles,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
      },
    },
  },
};
