import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  matterDetailHeaderWrapperStyles,
  matterDetailHeaderDateStyles,
} from './styles';
import { simpleGoBackButtonStyles } from '../../styles';
import { interviewDetailInfoStyles } from '../../../pages/styles/interviewDetails';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface MatterDetailHeaderProps {
  currentMatter: any;
}

const MatterDetailHeader = ({ currentMatter }: MatterDetailHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationPreviousState = location.state?.previous;

  const handleGoBack = () => {
    if (locationPreviousState === 'my-dashboard-matters') {
      navigate('/', { state: { activeStep: 1 } });
    } else {
      navigate(-1);
    }
  };

  return (
    <Box sx={matterDetailHeaderWrapperStyles}>
      {/* BEGIN TITLE WITH BREADCRUMB  */}
      <Box>
        <Box sx={{ marginRight: '30px' }}>
          <Box>
            <Typography>{currentMatter.name}</Typography>
          </Box>
          <Box>
            <Typography>Matter</Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Box sx={matterDetailHeaderDateStyles}>
              <Box>
                <Typography>Opened - {currentMatter.date_opened}</Typography>
              </Box>
              {currentMatter.date_closed && (
                <Box>
                  <Typography>Closed - {currentMatter.date_closed}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* END TITLE WITH BREADCRUMB  */}
      <Button
        sx={{ ...simpleGoBackButtonStyles, alignSelf: 'flex-start' }}
        variant="text"
        onClick={handleGoBack}
      >
        <span className="material-icons-round">arrow_back</span>
        <Typography variant="caption" component="p">
          Back
        </Typography>
      </Button>
    </Box>
  );
};

export default MatterDetailHeader;
