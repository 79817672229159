import { karlaProRegularFontStyles } from '../../styles/textStyles';

export const imanageActionsBoxStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '40px',
  alignSelf: 'stretch',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
};

export const metadataSpanStyles = {
  fontSize: '16px',
  ...karlaProRegularFontStyles,
  lineHeight: '22px',
  fontWeight: '500',
};
