/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Box, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import LEIAAAutocompletePopper from '../matters/matter-creation/LEIAAAutocompletePopper';
import { loginFormInputStyles } from '../login/styles';
import DropdownMenuIcon from '../matters/matter-creation/matter-creation-steps/DropdownMenuIcon';

interface LEIAAAutocompleteProps {
  labelText?: string;
  controllerName: string;
  control: Control<FieldValues> | undefined;
  inputPlaceholder: string;
  inputWidth: string;
  inputHeight: string;
  required?: boolean;
  autoCompleteFilter: any;
  autoCompleteOptions: any;
  hasCreateOption?: boolean;
  startAdornment?: JSX.Element | null;
  handleChange?: any;
  mapFunction?: any;
  noOptionText?: string;
  propField?: any;
  isError?: boolean;
  setIsError?: any;
  errorMsg?: string;
  extraOnChange?: any;
}

const LEIAAAutoComplete = ({
  labelText,
  controllerName,
  control,
  inputPlaceholder,
  inputWidth,
  inputHeight,
  required = true,
  autoCompleteFilter,
  autoCompleteOptions,
  hasCreateOption = true,
  startAdornment = null,
  handleChange,
  mapFunction,
  noOptionText,
  propField,
  isError,
  setIsError,
  errorMsg,
  extraOnChange = false,
}: LEIAAAutocompleteProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDropdownIconClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const mapRestValue = (value: any) => {
    if (mapFunction) {
      return mapFunction(value);
    }
    return value;
  };

  return (
    <Box>
      {labelText && (
        <InputLabel data-testid="matter-form-label-id" htmlFor={labelText}>
          {required ? '*' : ''}
          {labelText}
        </InputLabel>
      )}
      <Controller
        name={controllerName}
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...rest } }) => {
          const errorMessage = isError ? errorMsg : '';
          return (
            <Autocomplete
              sx={{ width: '100%' }}
              // eslint-disable-next-line react/no-unstable-nested-components
              PopperComponent={(props) => (
                <LEIAAAutocompletePopper {...props} />
              )}
              value={mapRestValue(rest.value) || ''}
              open={isMenuOpen} // Pass the isMenuOpen state to the open prop
              onOpen={() => setIsMenuOpen(true)} // Handle menu open event
              onClose={() => setIsMenuOpen(false)} // Handle menu close event
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  onChange(newValue);
                  if (isError && setIsError) {
                    setIsError(false);
                  }
                  if (handleChange) {
                    handleChange(newValue);
                  }
                } else if (newValue && newValue.inputValue) {
                  // Check if the selected option is the "Add '{inputValue}'" option
                  if (newValue.name.startsWith('Add "')) {
                    // Extract the inputValue without the 'Add " ... "' part
                    const inputValue = newValue.name.substring(
                      5,
                      newValue.name.length - 1
                    );
                    onChange(inputValue);
                    if (isError && setIsError) {
                      setIsError(false);
                    }
                    if (handleChange) {
                      handleChange(inputValue);
                    }
                  } else {
                    // Regular option selection
                    onChange(newValue.inputValue);
                    if (isError && setIsError) {
                      setIsError(false);
                    }
                    if (handleChange) {
                      handleChange(newValue.inputValue);
                    }
                  }
                } else {
                  onChange(newValue ? newValue.name : '');
                  if (isError && setIsError) {
                    setIsError(false);
                  }
                  if (handleChange && propField) {
                    handleChange(newValue ? newValue[propField] : '');
                  } else if (handleChange) {
                    handleChange(newValue ? newValue.name : '');
                  }
                }
                if (extraOnChange) {
                  extraOnChange(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = autoCompleteFilter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== '' && !isExisting) {
                  if (hasCreateOption) {
                    // Checks if inputValue is only white spaces
                    if (inputValue.replace(/\s/g, '').length) {
                      filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                      });
                    }
                  } else {
                    filtered.push({
                      name: noOptionText,
                    });
                  }
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={autoCompleteOptions || []}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField
                  error={isError}
                  helperText={errorMessage}
                  {...params}
                  placeholder={inputPlaceholder}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  required={required}
                  sx={{
                    ...loginFormInputStyles(inputWidth, inputHeight),
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: startAdornment || null,
                    endAdornment: (
                      <DropdownMenuIcon
                        params={params}
                        inputValue={rest.value}
                        isMenuOpen={isMenuOpen}
                        handleDropdownClientIconClick={handleDropdownIconClick}
                        isAdornment
                      />
                    ),
                  }}
                />
              )}
            />
          );
        }}
      />
    </Box>
  );
};

export default LEIAAAutoComplete;
