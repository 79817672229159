import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { matterAddedDocsWrapperStyles } from '../matter-creation-steps/matter-documents-step/styles';

interface MatterAddedDocumentsInformationProps {
  matterDocuments: any;
  handleRemoveFile: any;
  showHeader?: boolean;
}

const MatterAddedDocumentsInformation = ({
  matterDocuments,
  handleRemoveFile,
  showHeader = true,
}: MatterAddedDocumentsInformationProps) => {
  return (
    <Box>
      {showHeader && (
        <Box>
          <Box>
            <Typography>Added Documents</Typography>
          </Box>
        </Box>
      )}

      {
        // eslint-disable-next-line no-nested-ternary
        Array.isArray(matterDocuments) && matterDocuments.length > 0 ? (
          matterDocuments.map((md: any, index: number) => {
            return (
              <Box
                key={`${md.name}-${index}`}
                sx={matterAddedDocsWrapperStyles}
              >
                <Box>
                  <Box>
                    <Box>
                      <Box>
                        <Typography>{index + 1}.</Typography>
                        <Typography>{md.name}</Typography>
                      </Box>
                      <Box>
                        <IconButton
                          data-testid="clear-file-added-id"
                          aria-label="clear file added"
                          sx={{
                            padding: '0px',
                            '&:hover': { background: 'transparent' },
                            '& > span': {
                              fontSize: '16px',
                            },
                          }}
                          onClick={() => handleRemoveFile(md.id)}
                        >
                          <span className="material-icons-round">close</span>
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : Object.keys(matterDocuments).length > 0 ? (
          <Box
            key={`${matterDocuments.name}`}
            sx={matterAddedDocsWrapperStyles}
          >
            <Box>
              <Box>
                <Box>
                  <Box>
                    <Typography>1.</Typography>
                    <Typography>{matterDocuments.name}</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      data-testid="clear-file-added-id"
                      aria-label="clear file added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                      }}
                      onClick={() => handleRemoveFile(matterDocuments.id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : null
      }
    </Box>
  );
};

export default MatterAddedDocumentsInformation;
