/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import type {
  ChatMessage,
  ReceivedChatMessage,
} from '@livekit/components-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { setupChat } from '@livekit/components-core';
import { MessageFormatter, useRoomContext } from '@livekit/components-react';
import { Box, Typography } from '@mui/material';
import { useObservableState } from '../../../vendors/useObservableState';
import { LEIAAChatEntry } from './LEIAAChatEntry';
import {
  interviewChatContainerStyles,
  interviewChatControlsStyles,
} from './styles/styles';
import { saveInterviewChatMessageRequest } from '../../../api/interviews';

export type { ChatMessage, ReceivedChatMessage };

/** @public */
export interface ChatProps extends React.HTMLAttributes<HTMLDivElement> {
  messageFormatter?: MessageFormatter;
  interviewDetails: any;
  currentUser: any;
}

/** @public */
export function useChat() {
  const room = useRoomContext();
  const [setup, setSetup] = React.useState<ReturnType<typeof setupChat>>();
  const isSending = useObservableState(setup?.isSendingObservable, false);
  const chatMessages = useObservableState(setup?.messageObservable, []);

  React.useEffect(() => {
    const setupChatReturn = setupChat(room);
    setSetup(setupChatReturn);
    return setupChatReturn.destroy;
  }, [room]);

  return { send: setup?.send, chatMessages, isSending };
}

/** @internal */
export function cloneSingleChild(
  children: React.ReactNode | React.ReactNode[],
  props?: Record<string, any>,
  key?: any
) {
  return React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child) && React.Children.only(children)) {
      return React.cloneElement(child, { ...props, key });
    }
    return child;
  });
}

/**
 * The Chat component adds a basis chat functionality to the LiveKit room. The messages are distributed to all participants
 * in the room. Only users who are in the room at the time of dispatch will receive the message.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <Chat />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function LEIAAChat({
  messageFormatter,
  interviewDetails,
  currentUser,
  ...props
}: ChatProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const ulRef = React.useRef<HTMLUListElement>(null);
  const { send, chatMessages, isSending } = useChat();
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const handleFileButtonClick = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  // const handleFileChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files?.[0];
  //   if (file && send) {
  //     await send(file);
  //   }
  // };

  const saveChatMessage = async (message: any) => {
    try {
      await saveInterviewChatMessageRequest(
        interviewDetails.id,
        currentUser.id,
        message
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (inputRef.current && inputRef.current.value.trim() !== '') {
      if (send) {
        await send(inputRef.current.value);
        saveChatMessage(inputRef.current.value);
        inputRef.current.value = '';
        inputRef.current.focus();
      }
    }
  }

  React.useEffect(() => {
    if (ulRef) {
      ulRef.current?.scrollTo({ top: ulRef.current.scrollHeight });
    }
  }, [ulRef, chatMessages]);

  return (
    <Box {...props} className="lk-chat" sx={interviewChatContainerStyles}>
      <ul
        style={{ height: '100%' }}
        className="lk-list lk-chat-messages"
        ref={ulRef}
      >
        {props.children
          ? chatMessages.map((msg, idx) =>
              cloneSingleChild(props.children, {
                entry: msg,
                key: idx,
                messageFormatter,
              })
            )
          : chatMessages.map((msg, idx, allMsg) => {
              const hideName = idx >= 1 && allMsg[idx - 1].from === msg.from;
              // If the time delta between two messages is bigger than 60s show timestamp.
              const hideTimestamp =
                idx >= 1 && msg.timestamp - allMsg[idx - 1].timestamp < 60_000;

              return (
                <LEIAAChatEntry
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  hideName={hideName}
                  hideTimestamp={hideName === false ? false : hideTimestamp} // If we show the name always show the timestamp as well.
                  entry={msg}
                  messageFormatter={messageFormatter}
                />
              );
            })}
      </ul>

      <Box sx={interviewChatControlsStyles}>
        <form className="lk-chat-form" onSubmit={handleSubmit}>
          <Box>
            <input
              className="lk-form-control lk-chat-form-input"
              disabled={isSending}
              ref={inputRef}
              type="text"
              placeholder="Enter a message"
            />

            <input
              className="lk-form-control lk-chat-form-input"
              disabled={false}
              ref={inputFileRef}
              type="file"
              // onChange={handleFileChange} // Handle file selection here
            />
          </Box>

          <Box>
            <button
              type="button"
              className="lk-button lk-chat-form-button"
              onClick={handleFileButtonClick}
            >
              <span className="material-icons-round">upload_file</span>
            </button>
            <button
              type="submit"
              className="lk-button lk-chat-form-button"
              disabled={isSending}
            >
              Send
            </button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
