import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { userMeDetailsRequest, userPermissionsRequest } from './api/auth';
import LEIAAContext from './context/context';
import Sidebar from './components/sidebar/Sidebar';
import { setUserPermissions } from './redux/features/user-permissions/userPermissionsSlice';
import NotificationToast from './components/shared/toast/NotificationToast';
import FeedbackForm from './components/shared/FeedbackForm';
import CreationNotification from './components/shared/creation-notification/CreationNotification';

const App = () => {
  const isAuthenticated = localStorage.getItem('authToken');
  const hasTempToken = localStorage.getItem('tempToken');
  const dispatch = useDispatch();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showFeedbackNotification, setShowFeedbackNotification] =
    useState(false);

  const { user, setUser } = useContext(LEIAAContext);
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const timeoutDuration = 15 * 60 * 1000;
  let timeoutId: any;

  const terminateSession = () => {
    localStorage.removeItem('authToken');
    setUser({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      role: '',
      date_joined: '',
      phone_number: '',
      organisation: '',
    });
    logout();
    navigate('/login');
  };

  const resetTimeout = () => {
    const currentUrl = window.location.href;
    clearTimeout(timeoutId);
    // if user is inside video call it should not count as idle since user might just be listening
    if (!currentUrl.includes('/preroom/')) {
      timeoutId = setTimeout(terminateSession, timeoutDuration);
    }
  };

  document.addEventListener('mousemove', resetTimeout);
  document.addEventListener('keypress', resetTimeout);

  resetTimeout();

  const handleFetchUserDetails = async () => {
    try {
      const userInfo = await userMeDetailsRequest('GET');
      setUser(userInfo.user);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
      localStorage.clear();
      navigate('/login');
    }
  };

  const handleFetchUserPermissions = async () => {
    try {
      const response = await userPermissionsRequest(undefined);

      dispatch(setUserPermissions(response.data));
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      navigate('/mobile');
    } else if (isAuthenticated) {
      handleFetchUserDetails();
      handleFetchUserPermissions();
    } else {
      navigate('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (
    !isAuthenticated ||
    (isAuthenticated && hasTempToken) ||
    user.role === 'Guest'
  ) {
    localStorage.clear();

    // if (user.role === 'Guest') navigate('/login');

    return null; // or a loading spinner, or redirect to the login page immediately
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Sidebar username={user.username} setUser={setUser} />
      <Outlet />
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {showFeedbackNotification && (
        <CreationNotification
          title="Feedback sent!"
          subTitle=""
          showNotification={showFeedbackNotification}
          handleCloseNotification={() => setShowFeedbackNotification(false)}
        />
      )}
      <FeedbackForm setShowFeedbackNotification={setShowFeedbackNotification} />
    </Box>
  );
};

export default App;
