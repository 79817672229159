import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationWrapperStyles,
} from '../matter-information-step/styles';
import {
  LEIAAInput,
  LEIAAStatusSelect,
  LEIAADatePicker,
  LEIAAAutoComplete,
} from '../../../../inputs';
import { secondaryButtonStyles } from '../../../../styles';
import {
  MatterTask,
  MatterUser,
  UsersOptionType,
} from '../../../../../types/matters';
import { matterAvailableUsersRequest } from '../../../../../api/matters';
import {
  MatterAddedGeneralInformation,
  SidePanelInformation,
} from '../../sidepanel-information';
import MatterAddedTasksInformation from '../../sidepanel-information/MatterAddedTasksInformation';
import NotificationToast from '../../../../shared/toast/NotificationToast';
import FormStatusChip from '../../../../shared/misc-components/FormStatusChip';

interface MatterTasksProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  currentStep: number;
  previousStep: number | null;
  touchedFields: any;
}

const usersFilter = createFilterOptions<UsersOptionType>();

const MatterTasks = ({
  control,
  watch,
  setValue,
  currentStep,
  previousStep,
  touchedFields,
}: MatterTasksProps) => {
  const [usersOptions, setUsersOptions] =
    useState<readonly UsersOptionType[]>();
  const [users, setUsers] = useState<MatterUser[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const matterTasks = watch('tasks');
  const matterUsers = watch('users');
  const fields = watch();

  // ... (other state and function declarations)

  const [initialTasks, setInitialTasks] = useState<MatterTask[]>([]);

  const matterInfoFields = [
    {
      label: process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
        ? 'Organisation Name'
        : 'Client Name',
      value: watch('client'),
    },
    { label: 'Matter Name', value: watch('name') },
    { label: 'Matter Description', value: watch('description') },
    { label: 'Jurisdiction', value: watch('jurisdictionName') },
    { label: 'Template', value: watch('template') },
  ];

  const handleFetchUsers = async () => {
    try {
      const response = await matterAvailableUsersRequest();
      setUsers(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
      console.error(error);
    }
  };

  const updateTasksUploadedStatus = (tasks: MatterTask[]) => {
    return tasks.map((task: MatterTask) => ({
      ...task,
      // Add other properties of the File object that you need
      uploaded: true,
    }));
  };

  const handleTaskUpload = () => {
    if ((currentStep && previousStep === null) || previousStep === 3) {
      if (matterTasks && matterTasks.length > 0) {
        const updatedTasks = updateTasksUploadedStatus(matterTasks);
        setValue('tasks', updatedTasks);
        setInitialTasks(updatedTasks ? [...updatedTasks] : []);
      }
    }
  };

  useEffect(() => {
    handleFetchUsers();
    handleTaskUpload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (matterUsers && users) {
      const usersOptionType: UsersOptionType[] = matterUsers.map(
        (user: any) => {
          const foundUser = users.find((u) => u.id === user.user_id);

          return {
            id: uuidv4(),
            name: foundUser ? foundUser.name : '', // If user is found, use the name, otherwise use an empty string
          };
        }
      );

      setUsersOptions(usersOptionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterUsers, users]);

  const handleAddMatterTask = () => {
    // Add the current task to the tasks array in the form payload
    const currentTasks = watch('tasks');

    const updatedCurrentTask = {
      id: uuidv4(),
      name: '',
      description: '',
      status: 'To Do',
      deadline: undefined,
      assigned_to: undefined,
    };

    const newTasks =
      currentTasks === undefined
        ? [updatedCurrentTask]
        : [...currentTasks, updatedCurrentTask];

    setValue('tasks', newTasks);

    if (!matterTasks || matterTasks.length === 0) {
      setSelectedIndex(0);
    } else if (newTasks.length === 1) {
      setSelectedIndex(newTasks.length);
    } else {
      setSelectedIndex(newTasks.length - 1);
    }
  };

  const handleRemoveMatterTask = (id: string | number) => {
    const newTasksValue = watch('tasks').filter(
      (mt: MatterTask) => mt.id !== id
    );
    if (typeof id === 'number') {
      const tasksIdsToDelete = watch('tasks_to_delete') || [];
      setValue('tasks_to_delete', [...tasksIdsToDelete, id]);
    }
    setValue('tasks', newTasksValue);
  };

  useEffect(() => {
    // Update the selectedIndex to point to the last task if it exceeds the valid index range
    if (matterTasks && matterTasks.length > 0) {
      if (selectedIndex >= matterTasks.length) {
        setSelectedIndex(matterTasks.length - 1);
      }
    }
  }, [matterTasks, selectedIndex]);

  const handleSelectTask = (index: number) => {
    setSelectedIndex(index);
  };

  const userIdToObject = (id: number) => {
    const user = users?.filter((u) => u.id === id)[0];
    if (user) {
      return usersOptions?.filter((u) => u.name === user.name)[0];
    }

    return undefined;
  };

  return (
    <Box sx={matterInformationWrapperStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {/* FORM  */}
      <Box sx={matterInformationFormWrapperStyles}>
        <Typography>Create tasks and allocate them to users.</Typography>
        <Box sx={matterInformationInputWrapperStyles}>
          {matterTasks && matterTasks.length > 0
            ? matterTasks.map((mt: MatterTask, index: number) => {
                const handleChangeTaskStringField = () => {
                  if (
                    watch(`tasks[${index}].uploaded`) &&
                    watch(`tasks[${index}].uploaded`) === true
                  ) {
                    setValue(`tasks[${index}].uploaded`, false);
                  }
                };

                const handleChangeTaskDeadline = (e: any) => {
                  if (
                    watch(`tasks[${index}].uploaded`) &&
                    watch(`tasks[${index}].uploaded`) === true
                  ) {
                    setValue(`tasks[${index}].uploaded`, false);
                  }

                  if (e !== '') {
                    console.log(e);
                    setValue(`tasks[${index}].deadline`, e);
                  } else {
                    setValue(`tasks[${index}].deadline`, undefined);
                  }
                };

                const handleChangeTaskAssignedTo = (selectedUserName: any) => {
                  const userToAdd = users?.find(
                    (user) => user.name === selectedUserName
                  );

                  if (
                    watch(`tasks[${index}].uploaded`) &&
                    watch(`tasks[${index}].uploaded`) === true
                  ) {
                    setValue(`tasks[${index}].uploaded`, false);
                  }

                  if (userToAdd) {
                    setValue(`tasks[${index}].assigned_to`, userToAdd.id);
                  } else {
                    setValue(`tasks[${index}].assigned_to`, undefined);
                  }
                };

                return (
                  selectedIndex === index && (
                    <Box key={`${mt.id}-${index}`}>
                      <LEIAAInput
                        labelText="Task Name"
                        controllerName={`tasks[${index}].name`}
                        control={control}
                        inputPlaceholder="Enter task name"
                        inputWidth="100%"
                        inputHeight="40px"
                        handleChange={handleChangeTaskStringField}
                      />
                      <LEIAAInput
                        labelText="Task Description"
                        controllerName={`tasks[${index}].description`}
                        control={control}
                        inputPlaceholder="Enter task description"
                        inputWidth="100%"
                        inputHeight="130px"
                        multiline
                        handleChange={handleChangeTaskStringField}
                      />
                      <LEIAAStatusSelect
                        labelText="Task Status"
                        controllerName={`tasks[${index}].status`}
                        control={control}
                        handleChange={handleChangeTaskStringField}
                      >
                        <MenuItem value="To Do">To Do</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                        <MenuItem value="In Review">In Review</MenuItem>
                        <MenuItem value="Blocked">Blocked</MenuItem>
                        <MenuItem value="Done">Done</MenuItem>
                      </LEIAAStatusSelect>
                      <LEIAADatePicker
                        labelText="Deadline"
                        inputPlaceholder="Enter a deadline"
                        controllerName={`tasks[${index}].deadline`}
                        control={control}
                        handleChange={handleChangeTaskDeadline}
                      />
                      <LEIAAAutoComplete
                        controllerName={`tasks[${index}].assigned_to`}
                        control={control}
                        autoCompleteFilter={usersFilter}
                        autoCompleteOptions={usersOptions}
                        inputPlaceholder="Search by user to allocate task..."
                        inputWidth="100%"
                        inputHeight="40px"
                        hasCreateOption={false}
                        handleChange={handleChangeTaskAssignedTo}
                        required={false}
                        mapFunction={userIdToObject}
                        noOptionText="No user found"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            sx={{ marginLeft: '12px' }}
                          >
                            <span className="material-icons-round">search</span>
                          </InputAdornment>
                        }
                      />
                      <FormStatusChip
                        trigger={
                          watch(`tasks[${index}].description`) &&
                          watch(`tasks[${index}].name`) &&
                          watch(`tasks[${index}].status`)
                        }
                      />
                    </Box>
                  )
                );
              })
            : null}
          {matterTasks === undefined || matterTasks.length === 0 ? (
            <Button
              variant="contained"
              sx={{
                ...secondaryButtonStyles(),
                height: '30px',
                padding: '8px 16px',
                gap: '8px',
              }}
              onClick={handleAddMatterTask}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add new task</Typography>
            </Button>
          ) : null}
        </Box>
      </Box>

      <SidePanelInformation>
        {matterTasks && matterTasks.length > 0 && (
          <MatterAddedTasksInformation
            selectedIndex={selectedIndex}
            matterTasks={matterTasks}
            handleSelectTask={handleSelectTask}
            handleRemoveMatterTask={handleRemoveMatterTask}
            addTaskBtn={
              <Button
                variant="contained"
                sx={{
                  ...secondaryButtonStyles(),
                  height: '30px',
                  padding: '8px 16px',
                  gap: '8px',
                }}
                onClick={handleAddMatterTask}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add new task</Typography>
              </Button>
            }
          />
        )}
        {matterInfoFields &&
          matterInfoFields.filter(
            (mi) => mi.value !== '' && mi.value !== undefined
          ).length > 0 && (
            <MatterAddedGeneralInformation
              matterInfoFields={matterInfoFields}
            />
          )}
      </SidePanelInformation>
    </Box>
  );
};

export default MatterTasks;
