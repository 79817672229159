import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  createFilterOptions,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import {
  bundleActionsRequest,
  createBundleRequest,
  folderDocsRequest,
} from '../../../api/matters';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import { addToInterviewDialog, leiaaAddToWrapperStyles } from './styles';
import { LEIAAAutoComplete } from '../../inputs';
import NotificationToast from '../toast/NotificationToast';

export interface DocumentAddToInterviewDialogProps {
  open: boolean;
  onClose: () => void;
  documentsOptions: any;
  docList: any;
  bundlesOptions: any;
  refreshTable: () => void;
  matterId: any;
  control: any;
  setValue: any;
  setShowAddedBundleNotification: any;
  selectedDocs?: any;
}

interface BundleOptionType {
  inputValue?: string;
  name: string;
  id: number;
}

interface DocumentOptionType {
  inputValue?: string;
  name: string;
  id: number | string;
}

const bundleFilter = createFilterOptions<BundleOptionType>();
const documentFilter = createFilterOptions<DocumentOptionType>();

const DocumentAddToInterviewDialog = ({
  open,
  onClose,
  documentsOptions,
  docList,
  bundlesOptions,
  refreshTable,
  matterId,
  control,
  setValue,
  setShowAddedBundleNotification,
  selectedDocs,
}: DocumentAddToInterviewDialogProps) => {
  const [bundleValue, setBundleValue] = useState<any>();
  const [isError, setIsError] = useState<boolean>();
  const [addedDocs, setAddedDocs] = useState<any>(selectedDocs ?? []);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [isErrorBundle, setIsErrorBundle] = useState<boolean>();

  const cleanData = () => {
    setBundleValue(undefined);
    setValue('bundle_name', undefined);
    setIsError(false);
    setAddedDocs([]);
  };

  const handleClose = () => {
    cleanData();
    onClose();
  };

  const handleChangeBundleName = (bundleField: any) => {
    setBundleValue(bundleField);
  };

  const handleSubmit = async () => {
    if (addedDocs.length !== 0 && bundleValue && bundlesOptions) {
      try {
        const files: number[] = [];
        addedDocs.forEach((element: any) => {
          if (element.folder) {
            if (element.documents && Array.isArray(element.documents)) {
              element.documents
                .filter((resource: any) => !resource.folder)
                .forEach((doc: any) => {
                  files.push(doc.id);
                });
            }
            if (element.subfolders && Array.isArray(element.subfolders)) {
              element.subfolders.forEach((subfolder: any) => {
                if (subfolder.docs && Array.isArray(subfolder.docs)) {
                  subfolder.docs.forEach((doc: any) => {
                    files.push(doc.id);
                  });
                }
              });
            }
          } else {
            files.push(element.id);
          }
        });

        const existingBundle = bundlesOptions.find(
          (bundle: any) => bundle.id === bundleValue
        );

        if (!existingBundle) {
          await createBundleRequest(
            bundleValue,
            matterId,
            files.length === 0 ? undefined : files,
            undefined
          );
        } else {
          await bundleActionsRequest(
            bundleValue,
            files,
            undefined,
            undefined,
            undefined,
            undefined
          );
        }
        setValue('bundle_name', undefined);
        handleClose();
        refreshTable();
        setShowAddedBundleNotification(true);
      } catch (error: any) {
        setRequestError(error.response.data.message);
        setShowErrorNotification(true);
        console.error(error);
      }
    } else {
      if (addedDocs.length === 0) setIsError(true);
      if (!bundleValue) setIsErrorBundle(true);
    }
  };

  const handleDocChange = async (docId: string | number) => {
    const selectedDocId = docId;
    const documentToAdd = docList.find((doc: any) => doc.id === selectedDocId);

    if (addedDocs.find((doc: any) => doc.id === documentToAdd.id)) {
      return;
    }

    if (documentToAdd.folder) {
      const response = await folderDocsRequest(documentToAdd.id.toString(), '');
      documentToAdd.documents = response.documents;
    }

    if (documentToAdd !== null && documentToAdd !== undefined) {
      setAddedDocs([...addedDocs, documentToAdd]);
    }
  };

  const handleRemoveDoc = (docId: any) => {
    setAddedDocs((prevAddedDocs: any) =>
      prevAddedDocs.filter((doc: any) => doc.id !== docId)
    );
  };

  const handleRemoveSubDoc = (subDocId: any, parentDoc: any) => {
    setAddedDocs((prevAddedDocs: any) =>
      prevAddedDocs.map((doc: any) => {
        if (doc.id === parentDoc.id) {
          if (doc.documents) {
            doc.documents = doc.documents.filter(
              (subDoc: any) => subDoc.id !== subDocId
            );
          }
          if (doc.subfolders) {
            doc.subfolders = doc.subfolders.map((subfolder: any) => {
              subfolder.docs = subfolder.docs.filter(
                (subDoc: any) => subDoc.id !== subDocId
              );
              return subfolder;
            });
          }
        }
        return doc;
      })
    );
  };

  const handleFolderSelection = async (selectedDocsArray: any) => {
    const docsToUpdate: any[] = [];

    await Promise.all(
      selectedDocsArray.map(async (doc: any) => {
        if (doc.folder) {
          const response = await folderDocsRequest(doc.id.toString(), '');
          doc.documents = response.documents;
          docsToUpdate.push(doc);
        }
      })
    );

    return docsToUpdate;
  };

  useEffect(() => {
    async function updateSelectedDocs() {
      const updatedDocs = await handleFolderSelection(selectedDocs);
      setAddedDocs([
        ...updatedDocs,
        ...selectedDocs.filter((doc: any) => !doc.folder),
      ]);
    }

    updateSelectedDocs();
  }, [selectedDocs]);

  return (
    <Dialog onClose={handleClose} open={open} sx={leiaaAddToWrapperStyles}>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <Box sx={addToInterviewDialog()}>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: '#202020',
                ...sofiaProBoldFontStyles,
                fontSize: '18px',
                lineHeight: 'normal',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              Add to interview bundle
            </Typography>
          </Box>
          <Box>
            <Box>
              <Box>
                <InputLabel
                  data-testid="matter-form-label-id"
                  htmlFor="Interview-Bundle-Name"
                  sx={{
                    ...karlaProRegularFontStyles,
                    paddingLeft: '12px',
                    fontSize: '14px',
                  }}
                >
                  Interview Bundle Name
                </InputLabel>
              </Box>

              <LEIAAAutoComplete
                labelText=""
                required={false}
                controllerName="bundle_name"
                control={control}
                autoCompleteFilter={bundleFilter}
                autoCompleteOptions={bundlesOptions}
                inputPlaceholder="Select or add a interview bundle"
                inputWidth="100%"
                inputHeight="40px"
                handleChange={handleChangeBundleName}
                propField="id"
                isError={isErrorBundle}
                setIsError={setIsErrorBundle}
                errorMsg="You need to select a bundle"
              />
            </Box>

            <Box
              sx={{
                alignSelf: 'stretch',
                maxHeight: '140px',
                overflowY: 'scroll',
                padding: '0px',
              }}
              className="scrollable-content"
            >
              {addedDocs?.map((doc: any, index: number) => {
                // folders and nested folders
                if (doc.documents || doc.subfolders) {
                  return (
                    <Box
                      key={doc.id}
                      sx={{
                        borderRadius: '12px',
                        background: '#F0F8FD',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '16px',
                        marginBottom: '16px',
                        '& > p': {
                          ...karlaProRegularFontStyles,
                          fontSize: '14px',
                          fontWeight: '400',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '16px', // Space between folder name and nested documents
                        }}
                      >
                        <Typography
                          sx={{ marginRight: '5px', color: '#949CA9' }}
                        >
                          {index + 1}.
                        </Typography>
                        <Typography sx={{ color: '#464B53' }}>
                          {doc.folder && (
                            <span style={{ color: '#949CA9 !important' }}>
                              Folder -
                            </span>
                          )}{' '}
                          {doc.name}
                        </Typography>
                        <IconButton
                          data-testid="clear-folder-id"
                          aria-label="clear folder"
                          sx={{
                            padding: '0px',
                            '&:hover': { background: 'transparent' },
                            '& > span': {
                              fontSize: '16px',
                            },
                            marginLeft: 'auto',
                          }}
                          onClick={() => handleRemoveDoc(doc.id)}
                        >
                          <span className="material-icons-round">close</span>
                        </IconButton>
                      </Box>
                      {doc.documents && (
                        <Typography
                          sx={{
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#464B53',
                            padding: ' 5px 5px 10px 5px',
                          }}
                        >
                          Documents:
                        </Typography>
                      )}
                      {doc.documents &&
                        doc.documents
                          .filter((subResource: any) => !subResource.folder)
                          .map((subDoc: any, docIndex: number) => (
                            <Box
                              key={subDoc.id}
                              sx={{
                                borderRadius: '8px',
                                background: '#E3F2FD',
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                padding: '8px',
                                marginBottom: '8px',
                                marginLeft: '5px',
                                '& > p': {
                                  ...karlaProRegularFontStyles,
                                  fontSize: '14px',
                                  fontWeight: '400',
                                },
                              }}
                            >
                              <Typography
                                sx={{ marginRight: '5px', color: '#949CA9' }}
                              >
                                {docIndex + 1}.
                              </Typography>
                              <Typography sx={{ color: '#464B53' }}>
                                {subDoc.name}
                              </Typography>
                              <IconButton
                                data-testid="clear-doc-id"
                                aria-label="clear doc added"
                                sx={{
                                  padding: '0px',
                                  '&:hover': { background: 'transparent' },
                                  '& > span': {
                                    fontSize: '16px',
                                  },
                                  marginLeft: 'auto',
                                }}
                                onClick={() =>
                                  handleRemoveSubDoc(subDoc.id, doc)
                                }
                              >
                                <span className="material-icons-round">
                                  close
                                </span>
                              </IconButton>
                            </Box>
                          ))}
                      {doc.subfolders && (
                        <Typography
                          sx={{
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#464B53',
                            padding: ' 5px 5px 10px 5px',
                          }}
                        >
                          All nested folders:
                        </Typography>
                      )}
                      {doc.subfolders &&
                        doc.subfolders
                          .filter(
                            (subfolder: any) => subfolder.docs.length !== 0
                          )
                          .map((subfolder: any, subIndex: number) => (
                            <Box sx={{ marginLeft: '5px' }} key={subfolder.id}>
                              <Typography
                                sx={{
                                  ...karlaProRegularFontStyles,
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: '#464B53',
                                  padding: ' 5px 5px 8px 5px',
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: '5px',
                                    color: '#949CA9 !important',
                                  }}
                                >
                                  {subIndex + 1}.
                                </span>{' '}
                                {subfolder.name}
                              </Typography>
                              {subfolder.docs &&
                                subfolder.docs.map(
                                  (subDoc: any, docIndex: number) => (
                                    <Box
                                      key={subDoc.id}
                                      sx={{
                                        borderRadius: '8px',
                                        background: '#E3F2FD',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '8px',
                                        marginBottom: '8px',
                                        marginLeft: '5px',
                                        '& > p': {
                                          ...karlaProRegularFontStyles,
                                          fontSize: '14px',
                                          fontWeight: '400',
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          marginRight: '5px',
                                          color: '#949CA9',
                                        }}
                                      >
                                        {docIndex + 1}.
                                      </Typography>
                                      <Typography sx={{ color: '#464B53' }}>
                                        {subDoc.name}
                                      </Typography>
                                      <IconButton
                                        data-testid="clear-doc-id"
                                        aria-label="clear doc added"
                                        sx={{
                                          padding: '0px',
                                          '&:hover': {
                                            background: 'transparent',
                                          },
                                          '& > span': {
                                            fontSize: '16px',
                                          },
                                          marginLeft: 'auto',
                                        }}
                                        onClick={() =>
                                          handleRemoveSubDoc(subDoc.id, doc)
                                        }
                                      >
                                        <span className="material-icons-round">
                                          close
                                        </span>
                                      </IconButton>
                                    </Box>
                                  )
                                )}
                            </Box>
                          ))}
                    </Box>
                  );
                }
                // plain document
                return (
                  <Box
                    key={doc.id}
                    sx={{
                      borderRadius: '12px',
                      background: '#F0F8FD',
                      display: 'inline-flex',
                      width: '100%',
                      height: '38px',
                      padding: '16px',
                      marginBottom: '16px',
                      alignItems: 'center',
                      '& > p': {
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        fontWeight: '400',
                      },
                    }}
                  >
                    <Typography sx={{ marginRight: '5px', color: '#949CA9' }}>
                      {index + 1}.
                    </Typography>
                    <Typography sx={{ color: '#464B53' }}>
                      {doc.name}
                    </Typography>
                    <IconButton
                      data-testid="clear-doc-id"
                      aria-label="clear doc added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                        marginLeft: 'auto',
                      }}
                      onClick={() => handleRemoveDoc(doc.id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ width: '100%' }}>
              <LEIAAAutoComplete
                controllerName=""
                control={control}
                autoCompleteFilter={documentFilter}
                autoCompleteOptions={documentsOptions}
                inputPlaceholder="Search by folder/document to add..."
                inputWidth="100%"
                inputHeight="40px"
                hasCreateOption={false}
                handleChange={handleDocChange}
                required={false}
                noOptionText="No document/folder found"
                propField="id"
                isError={isError}
                setIsError={setIsError}
                errorMsg="You need to add documents to the bundle"
                startAdornment={
                  <InputAdornment position="start" sx={{ marginLeft: '12px' }}>
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                }
              />
            </Box>
            <Box
              sx={{
                display: 'inherit',
                width: '100%',
                gap: '16px',
              }}
            >
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={handleClose}
                >
                  <Typography>Cancel</Typography>
                </Button>
              </Box>
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={handleSubmit}
                >
                  <Typography>Add</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentAddToInterviewDialog;
