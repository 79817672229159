import api from '.';

async function getAnalysisListRequest(searchText: string) {
  const filters: { limit?: number; search_text?: string; documentId?: string } =
    {};

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  const response = await api.get('analysis', {
    params: filters,
  });

  return response.data.analysis;
}

async function batchDeleteAnalysisRequest(idsToDelete: any[] = []) {
  const requestData = { ids_to_delete: idsToDelete };
  const response = await api.patch('analysis', requestData);
  return response.data;
}

async function generateAnalysisRequest(
  analysisType: string,
  startDate: any = null,
  endDate: any = null,
  targetUser: any = null,
  targetClient: any = null
) {
  const requestData = {
    type: analysisType,
    start: startDate,
    end: endDate,
    user: targetUser,
    client: targetClient,
  };

  const response = await api.post('analysis', requestData);
  return response.data;
}

async function getAnalysisURLRequest(docId: number) {
  // repurposed endpoint
  const response = await api.get(`wiki-document-get/${docId}`);
  return response.data;
}

export {
  getAnalysisURLRequest,
  batchDeleteAnalysisRequest,
  getAnalysisListRequest,
  generateAnalysisRequest,
};
