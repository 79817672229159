import { Box } from '@mui/material';
import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { matterDetailGeneraPUTRequest } from '../../../api/matters';
import { LEIAADatePicker } from '../../inputs';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../matter-creation/matter-creation-steps/matter-information-step/styles';

interface MatterDetailTimelineProps {
  matterData: any;
  matterId: any;
  refreshData: any;
  user: any;
}

const MatterDetailTimeline = ({
  matterData,
  matterId,
  refreshData,
  user,
}: MatterDetailTimelineProps) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmitTimeline: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      if (matterId) {
        await matterDetailGeneraPUTRequest(matterId, data);
      }
      refreshData();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValue('deadline', matterData.completion_deadline);
    setValue('due', matterData.deadline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      matterData &&
      matterData.completion_deadline === watch('deadline') &&
      matterData.deadline === watch('due')
    ) {
      return;
    }

    if (matterData) {
      handleSubmit(onSubmitTimeline)();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('deadline'), watch('due')]);

  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmitTimeline)}>
      <Box sx={matterInformationWrapperStyles}>
        {/* FORM */}
        <Box sx={{ ...matterInformationFormWrapperStyles, width: '100%' }}>
          <Box sx={matterInformationInputWrapperStyles}>
            <Box>
              <LEIAADatePicker
                labelText="Matter completion deadline"
                inputPlaceholder="Enter a date"
                controllerName="deadline"
                control={control}
                backgroundColor="white !important"
                disabled={!['Admin', 'Superuser', 'Leader'].includes(user.role)}
              />
            </Box>
            {matterData.stage !== 'Closed' && (
              <Box>
                <LEIAADatePicker
                  labelText={`${matterData.stage} due date`}
                  inputPlaceholder="Enter a date"
                  controllerName="due"
                  control={control}
                  backgroundColor="white !important"
                  disabled={
                    !['Admin', 'Superuser', 'Leader'].includes(user.role)
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default MatterDetailTimeline;
