import { Box, Typography } from '@mui/material';
import LEIAAAutomatedMeetingWarning from './LEIAAAutomatedMeetingWarning';
import { interviewPreJoinGuidelinesStyles } from './styles';

interface LEIAAInterviewGuidelinesProps {
  isAutomated?: boolean;
}

const LEIAAInterviewGuidelines = ({
  isAutomated = false,
}: LEIAAInterviewGuidelinesProps) => {
  return (
    <Box>
      {isAutomated ? <LEIAAAutomatedMeetingWarning /> : null}
      <Box sx={interviewPreJoinGuidelinesStyles}>
        <Box>
          <Typography>Interview Guidelines</Typography>
        </Box>
        <Box className="wifi">
          <Box sx={{ width: '24px', height: '24px' }}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="wifi">
                <path
                  id="Vector"
                  d="M2.05715 10.54C2.56715 11.05 3.37716 11.1 3.92716 10.64C8.59716 6.8 15.3772 6.8 20.0572 10.63C20.6172 11.09 21.4372 11.05 21.9472 10.54C22.5372 9.95 22.4972 8.97 21.8472 8.44C16.1372 3.77 7.87715 3.77 2.15715 8.44C1.50716 8.96 1.45715 9.94 2.05715 10.54ZM9.81715 18.3L11.2872 19.77C11.6772 20.16 12.3072 20.16 12.6972 19.77L14.1672 18.3C14.6372 17.83 14.5372 17.02 13.9372 16.71C12.7172 16.08 11.2572 16.08 10.0272 16.71C9.45715 17.02 9.34716 17.83 9.81715 18.3ZM6.08715 14.57C6.57716 15.06 7.34715 15.11 7.91715 14.7C10.3572 12.97 13.6372 12.97 16.0772 14.7C16.6472 15.1 17.4172 15.06 17.9072 14.57L17.9172 14.56C18.5172 13.96 18.4772 12.94 17.7872 12.45C14.3472 9.96 9.65716 9.96 6.20715 12.45C5.51715 12.95 5.47715 13.96 6.08715 14.57Z"
                  fill="#0053FF"
                />
              </g>
            </svg>
          </Box>
          <Box>
            <Typography>
              Ensure you have a stable internet connection to avoid any
              disruptions during the interview.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ width: '24px', height: '24px' }}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="laptop_windows">
                <path
                  id="Vector"
                  d="M20 19V18C21.1 18 21.99 17.1 21.99 16L22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V16C2 17.1 2.9 18 4 18V19H1C0.45 19 0 19.45 0 20C0 20.55 0.45 21 1 21H23C23.55 21 24 20.55 24 20C24 19.45 23.55 19 23 19H20ZM5 6H19C19.55 6 20 6.45 20 7V15C20 15.55 19.55 16 19 16H5C4.45 16 4 15.55 4 15V7C4 6.45 4.45 6 5 6Z"
                  fill="#0053FF"
                />
              </g>
            </svg>
          </Box>
          <Box>
            <Typography>
              Use a reliable device, such as a computer or laptop, with a
              functioning camera and microphone.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box sx={{ width: '24px', height: '24px' }}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="videocam">
                <path
                  id="Vector"
                  d="M17 11V7.5C17 6.95 16.55 6.5 16 6.5H4C3.45 6.5 3 6.95 3 7.5V17.5C3 18.05 3.45 18.5 4 18.5H16C16.55 18.5 17 18.05 17 17.5V14L19.29 16.29C19.92 16.92 21 16.47 21 15.58V9.41C21 8.52 19.92 8.07 19.29 8.7L17 11Z"
                  fill="#0053FF"
                />
              </g>
            </svg>
          </Box>
          <Box>
            <Typography>
              Test your audio and video settings now to ensure they are working
              correctly.
            </Typography>
          </Box>
        </Box>
        {isAutomated ? (
          <Box>
            <Box sx={{ width: '24px', height: '24px' }}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="man">
                  <g id="Vector">
                    <path
                      d="M14 7.5H10C8.9 7.5 8 8.4 8 9.5V14.5C8 15.05 8.45 15.5 9 15.5H10V21.5C10 22.05 10.45 22.5 11 22.5H13C13.55 22.5 14 22.05 14 21.5V15.5H15C15.55 15.5 16 15.05 16 14.5V9.5C16 8.4 15.1 7.5 14 7.5Z"
                      fill="#0053FF"
                    />
                    <path
                      d="M12 6.5C13.1046 6.5 14 5.60457 14 4.5C14 3.39543 13.1046 2.5 12 2.5C10.8954 2.5 10 3.39543 10 4.5C10 5.60457 10.8954 6.5 12 6.5Z"
                      fill="#0053FF"
                    />
                  </g>
                </g>
              </svg>
            </Box>
            <Box>
              <Typography>
                Make sure that you are the only one in the room or in the
                vicinity and that others cannot hear you or other participants
                talk.
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default LEIAAInterviewGuidelines;
