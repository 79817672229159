import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';

export const matterCreationHeaderWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  width: '100%',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '10px',
      '& > p': {
        color: '#202020',
        fontSize: '18px',
        ...sofiaProBoldFontStyles,
        lineHeight: 'normal',
        letterSpacing: '2px',
        textTransform: 'uppercase',
      },
    },
    '& > button': {
      justifyContent: 'flex-start',
    },
  },
};

export const matterCreationHeaderStepperStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '9px',
  flex: '1 0 0',
  '& > div.MuiStep-root': {
    padding: '0px !important',
    margin: '0px',
    display: 'flex',
    height: '32px',
    paddingTop: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flex: '1 0 0',
    '& > button': {
      display: 'flex',
      padding: '0px 0px 0px 16px',
      alignItems: 'center',
      flex: '1 0 0',
      alignSelf: 'stretch',
      margin: '0px',
      justifyContent: 'unset',
      cursor: 'default',
      pointerEvents: 'none',
      '& > span': {
        gap: '8px',
        '& > span.MuiStepLabel-iconContainer:not(.Mui-active)': {
          '& > svg': {
            color: 'transparent',
            border: '1px dashed #69707B',
            borderRadius: '50%',
            '& > text': {
              fill: '#69707B',
            },
          },
        },
        '& > span.MuiStepLabel-labelContainer': {
          '& > span': {
            color: '#69707B',
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            fontWeight: 500,
            lineHeight: '24px',
          },
          '& > span.Mui-active': {
            color: '#0053FF',
          },
        },
      },
      '&.visited-step': {
        '& > span > span.MuiStepLabel-iconContainer:not(.Mui-active)': {
          '& > svg': {
            color: 'transparent',
            border: '1px solid #69707B',
            borderRadius: '50%',
            '& > text': {
              fill: '#69707B',
            },
          },
        },
      },
    },
  },
  '& > .MuiStepConnector-root': {
    display: 'none', // Add this line to hide the MuiStepConnector
  },
};

export const matterCreationFooterWrapperStyles = {
  display: 'flex',
  width: '100%',
  padding: '32px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '24px',
  borderTop: '1px solid  #D3ECF6',
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFF',
  zIndex: 2,
  '& > div': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '16px',
  },
};

export const leiaaDialogWrapperStyles = {
  '& > div:nth-of-type(3)': {
    background: 'rgba(211, 236, 246, 0.70)',
    backdropFilter: 'blur(2px)',
    '& > div.MuiPaper-root': {
      display: 'flex',
      width: '736px',
      padding: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '24px',
      border: '1px solid #D3ECF6',
      background: '#FFF',
      boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
      maxHeight: 'unset',
      maxWidth: 'unset',
    },
  },
};

export const leiaaDialogContentStyles = (
  hasBackButton: boolean,
  isTextAlign: boolean
) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    width: '100%',
    '& > div:nth-of-type(1)': {
      ...(hasBackButton
        ? {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
          }
        : {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
          }),
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        alignSelf: 'stretch',
        '& > span': {
          color: '#EDB900',
          fontSize: '40px',
        },
        '& > div': {
          display: 'flex',
          height: '40px',
          padding: '10px 0px',
          alignItems: 'flex-start',
          gap: '5px',
          '& > p': {
            color: '#202020',
            ...sofiaProBoldFontStyles,
            fontSize: '18px',
            lineHeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'uppercase',
          },
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '48px',
        alignSelf: 'stretch',
        '& > div:nth-of-type(1)': {
          ...(isTextAlign
            ? { width: '100%', textAlign: 'center' }
            : {
                display: 'flex',
                alignItems: 'flex-start',
                gap: '5px',
                alignSelf: 'stretch',
              }),
          '& > p': {
            color: '#202020',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
        '& > div:nth-of-type(2)': {
          display: 'flex',
          alignItems: 'flex-start',
          gap: '16px',
          alignSelf: 'stretch',
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            flex: '1 0 0',
            '& > div': {
              display: 'flex',
              padding: '0px 16px',
              alignItems: 'flex-start',
              gap: '10px',
              alignSelf: 'stretch',
              '& > p': {
                color: '#949CA9',
                textAlign: 'center',
                ...karlaProRegularItalicFontStyles,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: 'normal',
              },
            },
          },
        },
      },
    },
  };
};

export const leiaaDialogContentStylesReporting = (theme: any) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: '1 0 0',
  width: '100%',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  '& > div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    alignSelf: 'stretch',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
      alignSelf: 'stretch',
      '& > span': {
        color: '#EDB900',
        fontSize: '40px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '24px',
        },
      },
      '& > div': {
        display: 'flex',
        height: '40px',
        padding: '10px 0px',
        alignItems: 'flex-start',
        gap: '5px',
        '& > p': {
          color: '#202020',
          ...sofiaProBoldFontStyles,
          fontSize: '18px',
          lineHeight: 'normal',
          letterSpacing: '2px',
          textTransform: 'uppercase',
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            letterSpacing: '1px',
          },
        },
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '48px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        width: '100%',
        textAlign: 'center',
        '& > p': {
          color: '#202020',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '22px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '20px',
          },
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch',
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          flex: '1 0 0',
          '& > div': {
            display: 'flex',
            padding: '0px 16px',
            alignItems: 'flex-start',
            gap: '10px',
            alignSelf: 'stretch',
            '& > p': {
              color: '#949CA9',
              textAlign: 'center',
              ...karlaProRegularItalicFontStyles,
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: 'normal',
              [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
              },
            },
          },
        },
      },
    },
  },
});

export const matterCreationConfirmationWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  padding: '0 32px',
  '& > div': {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: '104px 32px 0px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '64px',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '64px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        width: '200px',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '80px',
        borderRadius: '100px',
        backgroundColor: '#EBFBFB',
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '32px',
      },
    },
  },
};

export const creationNotificationStyles = (showNotification: boolean) => {
  return {
    display: 'flex',
    opacity: showNotification ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
    height: '84px',
    width: '70%',
    paddingRight: '24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed', // Changed from 'absolute' to 'fixed'
    left: '50%', // Center horizontally
    top: '48px',
    transform: 'translateX(-50%)', // Adjust for centering
    borderRadius: '28px',
    backgroundColor: '#FFF',
    boxShadow: '5px 0px 20px 0px rgba(21, 10, 84, 0.10)',
    zIndex: '999999',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      alignItems: 'center',
      gap: '32px',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        width: '84px',
        height: '84px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '24px 0px 0px 24px',
        background: '#38B763',
        '& > span': {
          fontSize: '56px',
          color: '#FFF',
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          '& > p:nth-of-type(1)': {
            color: '#202020',
            ...karlaProBoldFontStyles,
            fontSize: '17px',
            fontWeight: 700,
            lineHeight: '30px',
          },
          '& > p:nth-of-type(2)': {
            color: '#464B53',
            ...karlaProRegularFontStyles,
            fontSize: '17px',
            fontWeight: 400,
            lineHeight: '36px',
          },
        },
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      padding: '10px 10px 10px 34px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      borderLeft: '1px solid #D3ECF6',
      cursor: 'pointer',
      '& > p': {
        color: '#69707B',
        ...karlaProRegularFontStyles,
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '22px',
      },
    },
  };
};

export const matterCreationSidepanelInfoWrapperStyles = {
  display: 'flex',
  paddingTop: '46px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
  width: '480px',
};

export const matterCreationSidepanelInfoWrapperStylesTest = {
  paddingTop: 'unset',
  position: 'fixed',
  maxHeight: '50vh',
  overflowY: 'scroll',
  top: 'unset',
  '& > div': {
    marginTop: '10px',
    display: 'flex',
    width: '480px',
    padding: '24px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '24px',
    backgroundColor: '#F5FAFD',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div': {
        display: 'flex',
        padding: '8px 0px 8px 16px',
        alignItems: 'flex-start',
        gap: '8px',
        '& > *': {
          color: '#140070',
        },
        '& > p': {
          ...sofiaProRegularFontStyles,
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: 'normal',
          letterSpacing: '0.3px',
        },
      },
    },
  },
};

export const matterCreationSidepanelFieldStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    padding: '8px 16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '12px',
    backgroundColor: '#FFF',
    '& > div': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
      '& > div': {
        display: 'flex',
        alignItems: 'flex-start',
        flex: '1 0 0',
        '& > div:nth-of-type(1) > p': {
          color: '#69707B',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          fontWeight: 300,
          lineHeight: '22px',
        },
        '& > div:nth-of-type(2) > p': {
          color: '#464B53',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
        },
      },
    },
  },
};
