import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';
import { forwardRef, useImperativeHandle } from 'react';

const ExportPlugin = forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext();

  useImperativeHandle(ref, () => ({
    exportState: () => {
      let json;
      let html;
      editor.update(() => {
        const editorState = editor.getEditorState();
        json = editorState.toJSON();
        html = $generateHtmlFromNodes(editor, null);
      });

      return { json, html };
    },
  }));

  return null;
});

ExportPlugin.displayName = 'ExportPlugin';

export default ExportPlugin;
