import React, { ReactNode, useState } from 'react';
import { User } from '../types/auth';
import LEIAAContext from './context';

interface LEIAAContextProviderProps {
  children: ReactNode;
}

const LEIAAContextProvider = ({ children }: LEIAAContextProviderProps) => {
  const [user, setUser] = useState<User>({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    role: '',
    date_joined: '',
    phone_number: '',
    organisation: '',
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    user,
    setUser,
  };

  return (
    <LEIAAContext.Provider value={value}>{children}</LEIAAContext.Provider>
  );
};

export default LEIAAContextProvider;
