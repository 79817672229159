// eslint-disable-next-line import/prefer-default-export
export const videoConferenceContainerStyles = {
  height: '100vh',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '64px',
    padding: '32px !important',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div': {
        display: 'flex',
        color: '#FFF',
        '& > span': {
          marginRight: '5px',
        },
      },
    },
    '&  > div:nth-of-type(2)': {
      '& > div': {
        padding: '0px',
      },
    },
  },
};

export const videoConferenceInPersonContainerStyles = {
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: '32px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  overflowY: 'scroll',
};
