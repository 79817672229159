import { Box, Typography } from '@mui/material';
import { MatterHistory } from '../../types/matters';
import { matterStatusChipWrapperStyles } from './styles';

interface MatterStatusChipProps {
  item: MatterHistory;
}

const MatterStatusChip = ({ item }: MatterStatusChipProps) => {
  return (
    <Box sx={matterStatusChipWrapperStyles(item)}>
      <Typography variant="caption" component="p">
        {item.isMatterActive ? 'Active Matter' : 'Archived Matter'}
      </Typography>
    </Box>
  );
};

export default MatterStatusChip;
