/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  MenuItem,
  Typography,
  createFilterOptions,
  InputLabel,
  Stack,
} from '@mui/material';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import {
  clientsRequest,
  countriesRequest,
  subDivisionsRequest,
} from '../../../../../api/matters';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationNestedInputWrapperStyles,
  matterInformationWrapperStyles,
} from './styles';
import {
  ClientOptionType,
  Jurisdiction,
  SubDivisonOptionType,
  UploadedAllegationQuestion,
} from '../../../../../types/matters';
import MatterQuestionnaire from './MatterQuestionnaire';
import { LEIAAInput, LEIAAAutoComplete, LEIAASelect } from '../../../../inputs';
import { selectUploadedAllegationQuestionnaireFiles } from '../../../../../redux/features/matter-creation/matterInformationSlice';
import {
  SidePanelInformation,
  MatterAddedQuestionnaireFileInformation,
} from '../../sidepanel-information';
import NotificationToast from '../../../../shared/toast/NotificationToast';
import { getWikiTemplateRequest } from '../../../../../api/wiki';
import { InterviewQuestion } from '../../../../../types/wiki';
import { karlaProRegularFontStyles } from '../../../../../styles/textStyles';
import MatterTemplateViewer from '../../MatterTemplateViewer';

export const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'JPY',
    label: '¥',
  },
  {
    value: 'GBP',
    label: '£',
  },
  {
    value: 'CAD',
    label: 'C$',
  },
  {
    value: 'AUD',
    label: 'A$',
  },
  {
    value: 'CHF',
    label: 'Fr',
  },
  {
    value: 'CNY',
    label: '元',
  },
  {
    value: 'INR',
    label: '₹',
  },
  {
    value: 'SGD',
    label: 'S$',
  },
  {
    value: 'MXN',
    label: 'Mex$',
  },
  {
    value: 'BRL',
    label: 'R$',
  },
  {
    value: 'ZAR',
    label: 'R',
  },
  {
    value: 'RUB',
    label: '₽',
  },
  {
    value: 'KRW',
    label: '₩',
  },
  {
    value: 'TRY',
    label: '₺',
  },
  {
    value: 'NZD',
    label: 'NZ$',
  },
  {
    value: 'SEK',
    label: 'kr',
  },
  {
    value: 'NOK',
    label: 'kr',
  },
  {
    value: 'AED',
    label: 'د.إ',
  },
  {
    value: 'BHD',
    label: 'BD',
  },
  {
    value: 'QAR',
    label: '﷼',
  },
  {
    value: 'SAR',
    label: '﷼',
  },
  {
    value: 'HKD',
    label: 'HK$',
  },
  {
    value: 'TWD',
    label: 'NT$',
  },
  {
    value: 'IDR',
    label: 'Rp',
  },
  {
    value: 'MYR',
    label: 'RM',
  },
  {
    value: 'THB',
    label: '฿',
  },
  {
    value: 'PHP',
    label: '₱',
  },
  {
    value: 'BRL',
    label: 'R$',
  },
  {
    value: 'VEF',
    label: 'Bs',
  },
  {
    value: 'EGP',
    label: 'ج.م',
  },
  {
    value: 'NGN',
    label: '₦',
  },
  {
    value: 'ZMW',
    label: 'ZK',
  },
  {
    value: 'KES',
    label: 'KSh',
  },
  {
    value: 'GHS',
    label: '₵',
  },
  {
    value: 'ZWL',
    label: 'Z$',
  },
  {
    value: 'UGX',
    label: 'UGX',
  },
  {
    value: 'PKR',
    label: '₨',
  },
  {
    value: 'BDT',
    label: '৳',
  },
  {
    value: 'LKR',
    label: 'රු',
  },
];

interface MatterInformationProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
}

const clientFilter = createFilterOptions<ClientOptionType>();
const subDivisonFilter = createFilterOptions<SubDivisonOptionType>();

const MatterInformation = ({
  control,
  watch,
  setValue,
  unregister,
}: MatterInformationProps) => {
  const [clients, setClients] = useState<readonly ClientOptionType[]>();
  const [subDivisons, setSubDivisons] =
    useState<readonly SubDivisonOptionType[]>();
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>();
  const uploadedAllegationQuestions: UploadedAllegationQuestion[] = useSelector(
    selectUploadedAllegationQuestionnaireFiles
  );
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [templatesAvailable, setTemplatesAvailable] = useState<any[]>();
  const [pickedTemplate, setPickedTemplate] = useState<any>(null);
  const handleFetchClients = async () => {
    try {
      const response = await clientsRequest();
      setClients(response as ClientOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchSubDivisions = async () => {
    if (watch('client')) {
      try {
        const response = await subDivisionsRequest(watch('client'));
        setSubDivisons(response as SubDivisonOptionType[]);
      } catch (error: any) {
        setRequestError(error.response.data.message);
        setShowNotification(true);
      }
    }
  };

  const handleFetchCountries = async () => {
    try {
      const response = await countriesRequest();
      setJurisdictions(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchTemplatesAvailable = async () => {
    try {
      const response = await getWikiTemplateRequest();
      setTemplatesAvailable(response);
      if (watch('template_id')) {
        const matchingTemplate = response?.find(
          (template: any) => template.id === watch('template_id')
        );
        setValue('template', matchingTemplate.name);
        setPickedTemplate(matchingTemplate);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    handleFetchClients();
    handleFetchCountries();
    handleFetchTemplatesAvailable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFetchSubDivisions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('client')]);

  const handleTemplateChange = (event: React.ChangeEvent<{ value: any }>) => {
    const templateName = event.target.value;
    const template = templatesAvailable?.find((t) => t.name === templateName);

    if (template) {
      setValue('template_id', template.id);
      setPickedTemplate(template);
    } else {
      setValue('template_id', '');
      setPickedTemplate(null);
    }
  };

  const handleSetMatterJurisdiction = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const countryCode = event.target.value;
    const jurisdiction = jurisdictions?.find(
      (j) => Object.keys(j)[0] === countryCode
    );
    const jurisdictionName = jurisdiction ? Object.values(jurisdiction)[0] : '';

    setValue('jurisdictionName', jurisdictionName);
  };

  const handleSetMatterCurrency = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    setValue('currency', event.target.value);
  };

  return (
    <Box sx={matterInformationWrapperStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {/* FORM  */}
      <Box sx={matterInformationFormWrapperStyles}>
        <Typography>Add a new matter.</Typography>
        <Box sx={matterInformationInputWrapperStyles}>
          <Box>
            {/* MATTER CLIENT INPUT  */}
            <LEIAAAutoComplete
              labelText={
                process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
                  ? 'Organisation Name'
                  : 'Client Name'
              }
              controllerName="client"
              control={control}
              autoCompleteFilter={clientFilter}
              autoCompleteOptions={clients}
              inputPlaceholder={`Enter ${
                process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
                  ? 'organisation'
                  : 'client'
              } name`}
              inputWidth="100%"
              inputHeight="40px"
            />
            {/* MATTER NAME INPUT  */}
            <LEIAAInput
              labelText="Matter Name"
              controllerName="name"
              control={control}
              inputPlaceholder="Enter matter name"
              inputWidth="100%"
              inputHeight="40px"
            />
            {/* MATTER TEMPLATE INPUT  */}
            <LEIAASelect
              labelText="Template"
              controllerName="template"
              control={control}
              inputPlaceholder="Choose a template"
              inputHeight="40px !important"
              inputWidth="100%"
              required={false}
              handleChange={handleTemplateChange}
            >
              {templatesAvailable?.map((mt: any) => {
                return (
                  <MenuItem key={mt.id} value={mt.name}>
                    {mt.name}
                  </MenuItem>
                );
              })}
            </LEIAASelect>
            <MatterTemplateViewer template={pickedTemplate} />
            {/* MATTER DESCRIPTION INPUT  */}
            <LEIAAInput
              labelText="Matter Description"
              controllerName="description"
              control={control}
              inputPlaceholder="Enter matter description"
              inputWidth="100%"
              inputHeight="130px"
              multiline
              customClassName="scrollable-content"
            />
            <MatterQuestionnaire
              control={control}
              watch={watch}
              setValue={setValue}
              unregister={unregister}
              setRequestError={setRequestError}
              setShowNotification={setShowNotification}
            />
            {/* MATTER JURISDICTION INPUT  */}
            <LEIAASelect
              labelText="Jurisdiction"
              control={control}
              controllerName="jurisdiction"
              inputPlaceholder="Select jurisdiction"
              inputHeight="40px !important"
              inputWidth="100%"
              paperCustomStylings={{ height: '300px' }}
              handleChange={handleSetMatterJurisdiction}
            >
              {jurisdictions?.map((j: Jurisdiction) => {
                const countryCode = Object.keys(j)[0] as string;
                const countryName = Object.values(j)[0] as string;

                return (
                  <MenuItem key={countryCode} value={countryCode}>
                    {countryName}
                  </MenuItem>
                );
              })}
            </LEIAASelect>
            {/* MATTER SUBDIVISION INPUT  */}
            <LEIAAAutoComplete
              labelText="Sub-division/ Subsidiary/ Business Unit"
              required={false}
              controllerName="sub_division"
              control={control}
              autoCompleteFilter={subDivisonFilter}
              autoCompleteOptions={subDivisons}
              inputPlaceholder="Enter sub-division/ subsidiary/ business unit"
              inputWidth="100%"
              inputHeight="40px"
            />

            <Box>
              <InputLabel
                data-testid="matter-form-label-id"
                sx={{
                  display: 'flex',
                  padding: '0px 12px',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  color: '#464B53',
                  fontSize: '14px',
                  ...karlaProRegularFontStyles,
                  lineHeight: '22px',
                }}
              >
                Matter Budget
              </InputLabel>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                {/* MATTER BUDGET INPUT  */}
                <LEIAAInput
                  labelText=""
                  controllerName="budget"
                  control={control}
                  inputPlaceholder="Enter matter budget"
                  inputWidth="250px"
                  inputHeight="40px"
                  type="NUMERIC"
                  required={false}
                />
                {/* MATTER CURRENCY INPUT  */}
                <LEIAASelect
                  labelText=""
                  control={control}
                  controllerName="currency"
                  inputPlaceholder="Select Currency"
                  inputHeight="40px !important"
                  inputWidth="100%"
                  paperCustomStylings={{ height: '300px' }}
                  handleChange={handleSetMatterCurrency}
                  required={false}
                >
                  {currencies?.map((c: any) => {
                    return (
                      <MenuItem key={c.value} value={c.label}>
                        {c.value} - {c.label}
                      </MenuItem>
                    );
                  })}
                </LEIAASelect>
              </Stack>
            </Box>

            {/* MATTER NOTES INPUT  */}
            <LEIAAInput
              labelText="Notes"
              controllerName="notes"
              control={control}
              inputPlaceholder="Enter additional notes"
              inputWidth="100%"
              inputHeight="130px"
              required={false}
              multiline
              customClassName="scrollable-content"
            />
          </Box>
        </Box>
      </Box>
      <SidePanelInformation>
        {uploadedAllegationQuestions &&
          uploadedAllegationQuestions.length > 0 && (
            <MatterAddedQuestionnaireFileInformation
              uploadedInterviewQuestions={uploadedAllegationQuestions}
              showAnswer
            />
          )}
      </SidePanelInformation>
    </Box>
  );
};

export default MatterInformation;
