import { Typography, Box } from '@mui/material';
import { formStatusChipStyles } from './styles';

interface FormStatusChipProps {
  trigger: any;
}
const FormStatusChip = ({ trigger }: FormStatusChipProps) => {
  return (
    <Box
      sx={{
        ...formStatusChipStyles,
        color: trigger ? '#4BB543' : '#9a9a9a',
        border: trigger ? '1px solid #4BB543' : '1px solid #9a9a9a',
      }}
    >
      {trigger ? (
        <Typography align="center">
          <span className="material-icons-round">done</span> Created
        </Typography>
      ) : (
        <Typography align="center">
          <span className="material-icons-round">edit_note</span>
          Draft
        </Typography>
      )}
    </Box>
  );
};
export default FormStatusChip;
