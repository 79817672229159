import { Box, Typography } from '@mui/material';
import { AuthProps } from '../../types/authProps';
import Form from './form/Form';
import { formHeaderWrapperStyles } from './styles';

const LoginAuthForm = ({
  handleSubmit,
  onSubmit,
  control,
  handleForgotPassword,
  watch,
  errors,
  reportingFollowUp = false,
}: AuthProps) => {
  const fields = [
    {
      name: 'username',
      label: reportingFollowUp ? 'Report Key' : 'Username',
      inputPlaceholder: reportingFollowUp
        ? 'Enter Report Key'
        : 'Enter Username',
      required: true,
    },
    {
      name: 'password',
      label: 'Password',
      inputPlaceholder: 'Enter password',
      type: 'password',
      required: true,
    },
  ];

  return (
    <>
      <Box sx={formHeaderWrapperStyles}>
        <Typography variant="caption" component="p">
          {reportingFollowUp
            ? 'Please enter your report key and password to follow up on submitted report.'
            : 'Welcome! Please enter your registered email address and password to log in to LEIAA.'}
        </Typography>
      </Box>
      <Form
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        control={control}
        fields={fields}
        submitButtonText="Login"
        showForgotPasswordButton
        handleForgotPassword={handleForgotPassword}
        watch={watch}
        errors={errors}
      />
    </>
  );
};

export default LoginAuthForm;
