import { karlaProRegularFontStyles } from '../../../styles/textStyles';

export const mattersListHeadersStyles = {
  '& > div.MuiDataGrid-columnHeaders': {
    border: 'unset',
    '& > div.MuiDataGrid-columnHeadersInner': {
      '& > div > div:nth-of-type(1)': {
        maxWidth: 'unset !important',
        width: 'unset !important',
      },
      '& > div > div': {
        padding: '0px !important',
        height: '52px !important',
        '&:focus-within': {
          outline: 'unset',
        },
        '& > div > div.MuiDataGrid-columnHeaderTitleContainer': {
          padding: '8px 16px',
        },
      },
    },
  },
};

export const mattersListContentStyles = {
  '& > div.MuiDataGrid-virtualScroller': {
    margin: '8px 0px',
    '& > div > div': {
      gap: '8px',
    },
    '& > div > div > div': {
      minHeight: 'unset !important',
      maxHeight: 'unset !important',
      borderWidth: '1px',

      borderRadius: '24px',
      '& > div.MuiDataGrid-cell': {
        padding: '16px',
        border: 'none',
        height: '68px !important',
        maxHeight: '68px !important',
        minHeight: '68px !important',
        '&:focus-within': {
          outline: 'unset',
        },
      },
    },
    '& > div > div > div.Mui-even': {
      backgroundColor: '#F5FAFD',
    },
    '& > div > div > div.Mui-odd': {
      backgroundColor: '#FFFFFF',
    },
  },
};

export const mattersListStyles = {
  border: 'unset',
  '& > div': {
    ...mattersListHeadersStyles,
    ...mattersListContentStyles,
  },
};

export const dataGridWrapperStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '0px',
  width: '100%',
  height: '100%',
};

export const mattersListWrapperStyles = (matterRowsAreEmpty: boolean) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '8px',
    marginTop: '32px',
    height: !matterRowsAreEmpty ? '540px' : '250px',
  };
};

export const mattersListStatusChipWrapperStyles = (
  backgroundColor: string,
  color: string,
  height: string,
  wrapperHeight: string
) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    height: wrapperHeight,
    alignSelf: 'flex-start',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '6px 10px',
      gap: '4px',
      height,
      backgroundColor,
      borderRadius: '10px',
      '& > p': {
        ...karlaProRegularFontStyles,
        textTransform: 'capitalize',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.3px',
        color,
      },
    },
  };
};

export const mattersListMaskShadowStyles = (showShadow: boolean) => {
  return {
    overflow: 'hidden',
    position: 'absolute',
    width: '84px',
    height: '524px',
    right: '-29px',
    top: '0px',
    flex: 'none',
    order: 2,
    flexGrow: 0,
    zIndex: 2,
    display: showShadow ? 'block' : 'none',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      padding: '0px',
      position: 'absolute',
      width: '32px',
      height: '524px',
      right: '0px',
      '& > div': {
        width: '32px',
        height: '524px',
        background: '#FFFFFF',
        boxShadow: '5px 0px 20px rgba(21, 10, 84, 0.1)',
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 0,
      },
    },
  };
};
