import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../styles/textStyles';
import { getReportUiDataRquest } from '../../api/whistleblower';
import ReportingImageHeader from './ReportingImageHeader';

interface FaqBoxProps {
  copy: any;
  index: number;
}

const FaqBox = ({ copy, index }: FaqBoxProps) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Accordion
      key={`FAQ - ${index}`}
      expanded={expanded}
      onChange={handleChange}
      sx={{
        background: expanded ? '#d3ecf6 !important' : 'white !important',
        width: '100%',
        marginBottom: 2,
        '& > div:nth-child(2)': {
          background: expanded ? '#f5fafd !important' : 'white !important',
          borderBottomRadius: '12px !important',
        },
        borderTopRadius: '12px !important',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
          },
          borderBottom: expanded ? '1px solid #d2e9ff !important' : 'none',
        }}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        <Typography
          sx={{
            ...sofiaProRegularFontStyles,
            fontSize: { xs: '14px', md: '17px' },
          }}
        >
          {copy.question}
        </Typography>
        <Box sx={{ marginLeft: 'auto' }}>
          {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            ...karlaProRegularFontStyles,
            fontSize: { xs: '13px', md: '15px' },
            padding: '5px',
            marginTop: '5px',
          }}
        >
          {copy.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const ReportingFaq = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [copyListFaq, setCopyListFaq] = useState<any[]>([]);
  const [mainCopy, setMainCopy] = useState<any>(null);

  const handleFetchUIData = async () => {
    try {
      setLoadingData(true);
      const response = await getReportUiDataRquest();

      setMainCopy({
        header: response.faq_main_header,
        content: response.faq_main_content,
      });

      setCopyListFaq(response.faqs);
      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchUIData();
  }, []);

  return loadingData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        '& > span': {
          color: '#0053FF',
        },
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: { xs: '10px 20px', md: '10px 50px' },
      }}
    >
      <ReportingImageHeader />

      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          textTransform: 'uppercase',
          fontSize: { xs: '20px', md: '25px' },
          fontWeight: '500',
          textAlign: 'center',
          margin: 'auto',
          marginBottom: { xs: 2, md: 3 },
        }}
      >
        {mainCopy.header}
      </Typography>

      <Typography
        sx={{
          ...karlaProRegularFontStyles,
          fontSize: { xs: '12px', md: '14px' },
          color: '#202020',
          marginTop: '20px',
          paddingLeft: { xs: '5%', md: '20%' },
          paddingRight: { xs: '5%', md: '20%' },
          textAlign: 'center',
        }}
      >
        {mainCopy.content}
      </Typography>

      {/* FAQ */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: { xs: '10px 20px', md: '40px 50px' },
        }}
      >
        {copyListFaq.map((copy, index) => (
          <FaqBox copy={copy} index={index} key={index} />
        ))}
      </Box>
    </Box>
  );
};

export default ReportingFaq;
