import { createContext } from 'react';
import { User } from '../types/auth';

interface LEIAAContextProps {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const LEIAAContext = createContext<LEIAAContextProps>({
  user: {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    date_joined: '',
    phone_number: '',
    organisation: '',
  } as User,
  setUser: () => {},
});

export default LEIAAContext;
