import {
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import {
  batchDeleteMatterReportsRequest,
  createMatterReportRequest,
  matterDetailMatterReportsRequest,
  matterDetailRiskReportsRequest,
} from '../../../api/matters';
import { primaryButtonStyles } from '../../styles';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import RiskReportsListTable from '../../shared/risk-reports-list-table/RiskReportsListTable';
import MatterReportListTable from '../../shared/matter-reports-list-table/MatterReportListTable';
import ListButtons from '../../shared/list-buttons/ListButtons';
import { useReportPermissions } from '../../../hooks/usePermissions';
import NotificationToast from '../../shared/toast/NotificationToast';
import CreationNotification from '../../shared/creation-notification/CreationNotification';

interface MatterDetailReportsProps {
  matterId: any;
  matterDetails: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ display: value !== index ? 'none' : 'block' }}
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const MatterDetailReports = ({
  matterId,
  matterDetails,
}: MatterDetailReportsProps) => {
  const reportPerms = useReportPermissions();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingRisk, setLoadingRisk] = useState(true);
  const [matterDetailReports, setMatterDetailsReports] = useState<any>();
  const [matterDetailRisks, setMatterDetailsRisks] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [checkedRisks, setCheckedRisks] = useState([]);
  const [selectedRisk, setSelectedRisk] = useState<any | null>(null);
  const [checkedReports, setCheckedReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState<any | null>(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [
    showReportGeneratingNotification,
    setShowReportGeneratingNotification,
  ] = useState(false);

  const handleGetMatterDetailReports = async (searchText = '') => {
    setLoading(true); // Start loading
    try {
      if (matterId) {
        const response = await matterDetailMatterReportsRequest(
          matterId,
          searchText
        );
        setMatterDetailsReports(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetMatterDetailRisks = async (searchText = '') => {
    setLoadingRisk(true); // Start loading
    try {
      if (matterId) {
        const response = await matterDetailRiskReportsRequest(
          matterId,
          searchText
        );
        setMatterDetailsRisks(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoadingRisk(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    if (tabValue === 0) {
      handleGetMatterDetailReports(searchText);
    } else {
      handleGetMatterDetailRisks(searchText);
    }
  };

  useEffect(() => {
    handleGetMatterDetailReports();
    handleGetMatterDetailRisks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateReport = async () => {
    setShowReportGeneratingNotification(true);
    await createMatterReportRequest(matterId);
    await handleGetMatterDetailReports();
  };

  const handleDetailReport = (report: any) => {
    navigate(`/matters/detail/${matterId}/reports/${report?.id}`, {
      state: {
        report,
        matter: matterDetails,
        previous: 'matter-reports',
      },
    });
  };

  const handleBatchDeleteReports = async () => {
    try {
      await batchDeleteMatterReportsRequest(matterId, checkedReports);
      await handleGetMatterDetailReports();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  return (
    <>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Report archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {showReportGeneratingNotification && (
        <CreationNotification
          title="Matter Report generation started!"
          subTitle=""
          showNotification={showReportGeneratingNotification}
          handleCloseNotification={() =>
            setShowReportGeneratingNotification(false)
          }
        />
      )}

      {/* <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            borderBottom: '1px solid #949CA9',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& > div': {
                '& > div': {
                  '& > button': {
                    display: 'flex',
                    width: '224px',
                    padding: '8px 24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '24px 24px 0px 0px',
                    '&.Mui-selected': {
                      backgroundColor: '#F5FAFD',
                      color: '#0053FF',
                    },

                    color: '#69707B',
                    textTransform: 'none',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                '& > span.MuiTabs-indicator': {
                  backgroundColor: '#0053FF',
                },
              },
            }}
          >
            <Tab label="Investigation Reports" {...a11yProps(0)} />
            <Tab label="Risk Reporting" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box> */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '40px',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
        }}
      >
        <Box>
          {tabValue === 0 && (
            <Box
              sx={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}
            >
              {reportPerms.add && (
                <Button
                  variant="contained"
                  onClick={handleCreateReport}
                  sx={{ ...primaryButtonStyles(), height: '40px' }}
                >
                  <span className="material-icons-round">add</span>
                  <Typography>Generate LEIAA report</Typography>
                </Button>
              )}
            </Box>
          )}

          <TextField
            id="input-with-icon-textfield"
            placeholder="Search by report..."
            sx={{
              ...homePortalSearchInputStyles,
              marginLeft: 'auto',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& > span': { color: '#69707B' } }}
                >
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              onChange: handleListSearchbarInputChange,
              // YOU SHOULD HAVE TWO DIFFERENT INPUT REFS FOR EACH RECORD
              // stop yelling >:|
              inputRef,
            }}
            variant="standard"
          />
        </Box>
        {tabValue === 0 && checkedReports.length > 0 && reportPerms.delete && (
          <ListButtons
            deleteHandler={handleBatchDeleteReports}
            checkedResources={checkedReports}
            resourceName="Report"
          />
        )}
      </Box>

      <Box sx={{ width: '100%', marginTop: '-40px' }}>
        <MatterReportListTable
          matterReportsListData={matterDetailReports}
          listClass="matter-report"
          loading={loading}
          handleDetailReport={handleDetailReport}
          setCheckedReports={setCheckedReports}
          setSelectedReports={setSelectedReport}
        />
        {/* <CustomTabPanel value={tabValue} index={1}>
          <RiskReportsListTable
            riskReportsListData={matterDetailRisks}
            listClass="risk-list"
            loading={loadingRisk}
            setCheckedRisks={setCheckedRisks}
            setSelectedRisk={setSelectedRisk}
            dashboard
          />
        </CustomTabPanel> */}
      </Box>
    </>
  );
};

export default MatterDetailReports;
