import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import followup from '../../assets/followup.jpg';
import faq from '../../assets/faq.jpg';
import concern from '../../assets/concern.jpg';

import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../styles/textStyles';
import { tertiaryButtonStyles } from '../../components/styles';
import { getReportUiDataRquest } from '../../api/whistleblower';
import ReportingImageHeader from './ReportingImageHeader';

interface InformationBoxProps {
  copy: any;
}

const InformationBox = ({ copy }: InformationBoxProps) => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Box sx={{ marginBottom: 2 }}>
      <img
        src={copy.photo}
        style={{
          borderRadius: '12px',
          width: '100%',
          height: '150px',
          objectFit: 'cover',
          objectPosition: copy.objectPosition,
        }}
        alt={copy.title}
        onLoad={() => setImageLoaded(true)}
      />
      {imageLoaded && (
        <>
          <Typography
            sx={{
              ...sofiaProBoldFontStyles,
              color: '#202020',
              textTransform: 'uppercase',
              fontSize: '18px',
              fontWeight: '500',
              marginTop: 1,
            }}
          >
            {copy.title}
          </Typography>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontSize: '14px',
              color: '#202020',
              marginTop: '5px',
            }}
          >
            {copy.content}
          </Typography>
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              paddingLeft: '0px',
              marginRight: 'auto',
            }}
            variant="text"
            onClick={() => {
              navigate(`/${copy.navigate}`);
            }}
          >
            <Typography sx={{ fontSize: '14px' }}>{copy.buttonText}</Typography>
          </Button>
        </>
      )}
    </Box>
  );
};

const Reporting = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [copyList, setCopyList] = useState<any[]>([]);
  const [mainCopy, setMainCopy] = useState<any>(null);

  const handleFetchUIData = async () => {
    try {
      setLoadingData(true);
      const response = await getReportUiDataRquest();

      setMainCopy({
        header: response.reporting_main_page_main_header,
        content: response.reporting_main_page_main_content,
      });

      setCopyList([
        {
          title: response.reporting_main_page_concern_header,
          content: response.reporting_main_page_concern_content,
          buttonText: response.reporting_main_page_concern_link,
          navigate: 'report-form',
          photo: concern,
          objectPosition: 'unset',
        },
        {
          title: response.reporting_main_page_followup_header,
          content: response.reporting_main_page_followup_content,
          buttonText: response.reporting_main_page_followup_link,
          navigate: 'reporting-follow-up',
          photo: followup,
          objectPosition: '50% 10%',
        },
        {
          title: response.reporting_main_page_faq_header,
          content: response.reporting_main_page_faq_content,
          buttonText: response.reporting_main_page_faq_link,
          navigate: 'reporting-faq',
          photo: faq,
          objectPosition: 'unset',
        },
      ]);
      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchUIData();
  }, []);

  return loadingData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        '& > span': {
          color: '#0053FF',
        },
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: { xs: '10px 20px', md: '10px 50px' },
      }}
    >
      <ReportingImageHeader />

      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          textTransform: 'uppercase',
          fontSize: { xs: '20px', md: '25px' },
          fontWeight: '500',
          textAlign: 'center',
          margin: 'auto',
          marginBottom: { xs: 2, md: 3 },
        }}
      >
        {mainCopy.header}
      </Typography>

      <Typography
        sx={{
          ...karlaProRegularFontStyles,
          fontSize: { xs: '12px', md: '14px' },
          color: '#202020',
          marginTop: '20px',
          paddingLeft: { xs: '5%', md: '20%' },
          paddingRight: { xs: '5%', md: '20%' },
          textAlign: 'justify',
        }}
      >
        {mainCopy.content}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: { xs: '10px 20px', md: '10px 50px' },
        }}
      >
        <Grid container spacing={2}>
          {copyList.map((copy, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <InformationBox copy={copy} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Reporting;
