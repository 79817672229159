import type { LocalAudioTrack, LocalVideoTrack } from 'livekit-client';
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  Track,
  VideoPresets,
} from 'livekit-client';
import * as React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { log } from '@livekit/components-core';
import { Box } from '@mui/material';
import { useMediaDevices } from '@livekit/components-react';
import { LEIAATrackToggle } from '../../components/interview/interview-control-bar/LEIAATrackToggle';
import SvgParticipantPlaceholder from '../../assets/livekit/LEIAAParticipantPlaceholder';
import { LEIAAMediaDeviceMenu } from '../../components/interview/interview-control-bar/LEIAAMediaDeviceMenu';
import { controlBarButtonGroupStyles } from '../../components/interview/interview-control-bar/styles/styles';
import './prejoin.css';

/** @public */
export type LocalUserChoices = {
  videoEnabled: boolean;
  audioEnabled: boolean;
  videoDeviceId: string;
  audioDeviceId: string;
};

const DEFAULT_USER_CHOICES = {
  videoEnabled: true,
  audioEnabled: true,
  videoDeviceId: '',
  audioDeviceId: '',
};

/** @public */
export type PreJoinProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onSubmit'
> & {
  /** This function is called with the `LocalUserChoices` if validation is passed. */
  onSubmit?: (values: LocalUserChoices) => void;
  /**
   * Provide your custom validation function. Only if validation is successful the user choices are past to the onSubmit callback.
   */
  onValidate?: (values: LocalUserChoices) => boolean;
  onError?: (error: Error) => void;
  /** Prefill the input form with initial values. */
  defaults?: Partial<LocalUserChoices>;
  /** Display a debug window for your convenience. */
  debug?: boolean;
  joinLabel?: string;

  setPreRoomVideo: React.Dispatch<React.SetStateAction<boolean>>;
  setPreRoomAudio: React.Dispatch<React.SetStateAction<boolean>>;
};

/** @public */
function usePreviewDevice<T extends LocalVideoTrack | LocalAudioTrack>(
  enabled: boolean,
  deviceId: string,
  kind: 'videoinput' | 'audioinput'
) {
  const [deviceError, setDeviceError] = React.useState<Error | null>(null);

  const devices = useMediaDevices({ kind });
  const [selectedDevice, setSelectedDevice] = React.useState<
    MediaDeviceInfo | undefined
  >(undefined);

  const [localTrack, setLocalTrack] = React.useState<T>();
  const [localDeviceId, setLocalDeviceId] = React.useState<string>(deviceId);

  React.useEffect(() => {
    setLocalDeviceId(deviceId);
  }, [deviceId]);

  const createTrack = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    deviceId: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    kind: 'videoinput' | 'audioinput'
  ) => {
    try {
      const track =
        kind === 'videoinput'
          ? await createLocalVideoTrack({
              deviceId,
              resolution: VideoPresets.h720.resolution,
            })
          : await createLocalAudioTrack({ deviceId });

      const newDeviceId = await track.getDeviceId();
      if (newDeviceId && deviceId !== newDeviceId) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        prevDeviceId.current = newDeviceId;
        setLocalDeviceId(newDeviceId);
      }
      setLocalTrack(track as T);
    } catch (e) {
      if (e instanceof Error) {
        setDeviceError(e);
      }
    }
  };

  const switchDevice = async (
    track: LocalVideoTrack | LocalAudioTrack,
    id: string
  ) => {
    await track.restartTrack({
      deviceId: id,
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    prevDeviceId.current = id;
  };

  const prevDeviceId = React.useRef(localDeviceId);

  React.useEffect(() => {
    if (enabled && !localTrack && !deviceError) {
      log.debug('creating track', kind);
      createTrack(localDeviceId, kind);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, localTrack, deviceError]);

  // switch camera device
  React.useEffect(() => {
    if (!enabled) {
      if (localTrack) {
        log.debug(`muting ${kind} track`);
        localTrack.mute().then(() => log.debug(localTrack.mediaStreamTrack));
      }
      return;
    }
    if (
      localTrack &&
      selectedDevice?.deviceId &&
      prevDeviceId.current !== selectedDevice?.deviceId
    ) {
      log.debug(
        `switching ${kind} device from`,
        prevDeviceId.current,
        selectedDevice.deviceId
      );
      switchDevice(localTrack, selectedDevice.deviceId);
    } else {
      log.debug(`unmuting local ${kind} track`);
      localTrack?.unmute();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (localTrack) {
        log.debug(`stopping local ${kind} track`);
        localTrack.stop();
        localTrack.mute();
      }
    };
  }, [localTrack, selectedDevice, enabled, kind]);

  React.useEffect(() => {
    setSelectedDevice(devices.find((dev) => dev.deviceId === localDeviceId));
  }, [localDeviceId, devices]);

  return {
    selectedDevice,
    localTrack,
    deviceError,
  };
}

/**
 * The PreJoin prefab component is normally presented to the user before he enters a room.
 * This component allows the user to check and select the preferred media device (camera und microphone).
 * On submit the user decisions are returned, which can then be passed on to the LiveKitRoom so that the user enters the room with the correct media devices.
 *
 * @remarks
 * This component is independent from the LiveKitRoom component and don't has to be nested inside it.
 * Because it only access the local media tracks this component is self contained and works without connection to the LiveKit server.
 *
 * @example
 * ```tsx
 * <PreJoin />
 * ```
 * @public
 */
export const LEIAAPreJoin = ({
  defaults = {},
  onValidate,
  onSubmit,
  onError,
  debug,
  joinLabel = 'Join interview room',
  setPreRoomVideo,
  setPreRoomAudio,
  ...htmlProps
}: PreJoinProps) => {
  const [userChoices, setUserChoices] = React.useState(DEFAULT_USER_CHOICES);

  const [videoEnabled, setVideoEnabled] = React.useState<boolean>(
    defaults.videoEnabled ?? DEFAULT_USER_CHOICES.videoEnabled
  );

  const [videoDeviceId] = React.useState<string>(
    defaults.videoDeviceId ?? DEFAULT_USER_CHOICES.videoDeviceId
  );
  const [audioEnabled, setAudioEnabled] = React.useState<boolean>(
    defaults.audioEnabled ?? DEFAULT_USER_CHOICES.audioEnabled
  );
  const [audioDeviceId] = React.useState<string>(
    defaults.audioDeviceId ?? DEFAULT_USER_CHOICES.audioDeviceId
  );

  const video = usePreviewDevice(videoEnabled, videoDeviceId, 'videoinput');

  const videoEl = React.useRef(null);

  React.useEffect(() => {
    if (videoEl.current) video.localTrack?.attach(videoEl.current);

    return () => {
      video.localTrack?.detach();
    };
  }, [video.localTrack, videoEl]);

  const audio = usePreviewDevice(audioEnabled, audioDeviceId, 'audioinput');

  const handleValidation = React.useCallback(
    // eslint-disable-next-line consistent-return
    (values: LocalUserChoices) => {
      if (typeof onValidate === 'function') {
        return onValidate(values);
      }
      return true;
    },
    [onValidate]
  );

  React.useEffect(() => {
    if (audio.deviceError) {
      onError?.(audio.deviceError);
    }
  }, [audio.deviceError, onError]);
  React.useEffect(() => {
    if (video.deviceError) {
      onError?.(video.deviceError);
    }
  }, [video.deviceError, onError]);

  React.useEffect(() => {
    const newUserChoices = {
      videoEnabled,
      videoDeviceId: video.selectedDevice?.deviceId ?? '',
      audioEnabled,
      audioDeviceId: audio.selectedDevice?.deviceId ?? '',
    };
    setUserChoices(newUserChoices);
  }, [
    videoEnabled,
    video.selectedDevice,
    handleValidation,
    audioEnabled,
    audio.selectedDevice,
  ]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (handleValidation(userChoices)) {
      if (typeof onSubmit === 'function') {
        onSubmit(userChoices);
      }
    } else {
      log.warn('Validation failed with: ', userChoices);
    }
  }

  return (
    <div className="lk-prejoin" {...htmlProps}>
      <div
        style={{ width: '645px', height: '365px' }}
        className="lk-video-container"
      >
        {video.localTrack && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video ref={videoEl} width="1280" height="720" />
        )}
        {(!video.localTrack || !videoEnabled) && (
          <div className="lk-camera-off-note">
            <SvgParticipantPlaceholder />
          </div>
        )}
      </div>
      <Box
        sx={{ ...controlBarButtonGroupStyles(true), width: '645px' }}
        className="lk-button-group-container"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '20px',
          }}
        >
          <Box
            className="lk-button-group audio"
            sx={{
              '& > button > span:nth-of-type(2)': { marginLeft: 'auto' },
            }}
          >
            <LEIAATrackToggle
              initialState={audioEnabled}
              source={Track.Source.Microphone}
              showIcon
              onChange={(enabled) => {
                setAudioEnabled(enabled);
                setPreRoomAudio(enabled);
              }}
            >
              <LEIAAMediaDeviceMenu kind="audioinput" />
            </LEIAATrackToggle>
          </Box>
          <Box
            className="lk-button-group video"
            sx={{ '& > button > span:nth-of-type(2)': { marginLeft: 'auto' } }}
          >
            <LEIAATrackToggle
              initialState={videoEnabled}
              source={Track.Source.Camera}
              showIcon
              onChange={(enabled) => {
                setVideoEnabled(enabled);
                setPreRoomVideo(enabled);
              }}
            >
              <LEIAAMediaDeviceMenu kind="videoinput" />
            </LEIAATrackToggle>
          </Box>
          <Box>
            <button
              className="lk-join-button"
              type="submit"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={handleSubmit}
            >
              <span className="lk-join-image">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M19.8567 12.4207L18.5935 10.9645C19.5039 9.82555 20 8.41734 20 6.94531C20 3.39137 17.1087 0.5 13.5547 0.5C10.0007 0.5 7.10938 3.39137 7.10938 6.94531C7.10938 10.4993 10.0007 13.3906 13.5547 13.3906H19.4141C19.6435 13.3906 19.8519 13.2567 19.9471 13.048C20.0424 12.8392 20.007 12.5941 19.8567 12.4207ZM13.5547 10.9297C13.2311 10.9297 12.9687 10.6673 12.9687 10.3438C12.9687 10.0202 13.2311 9.75781 13.5547 9.75781C13.8783 9.75781 14.1406 10.0202 14.1406 10.3438C14.1406 10.6673 13.8783 10.9297 13.5547 10.9297ZM15.1849 6.71723C14.8994 7.01793 14.5357 7.23203 14.1407 7.3393V8.56977C14.1407 8.89336 13.8783 9.1557 13.5547 9.1557C13.2311 9.1557 12.9688 8.89336 12.9688 8.56977V6.83184C12.9688 6.50824 13.2311 6.2459 13.5547 6.2459C13.8525 6.2459 14.1296 6.12672 14.3351 5.91035C14.5403 5.69418 14.6448 5.41059 14.6293 5.1118C14.6003 4.55238 14.1381 4.10527 13.5772 4.09398C13.5699 4.09383 13.5625 4.09375 13.5552 4.09375C13.0195 4.09375 12.5632 4.48793 12.4891 5.01902C12.4821 5.06848 12.4787 5.11922 12.4787 5.16984C12.4787 5.49344 12.2163 5.75578 11.8927 5.75578C11.5691 5.75578 11.3068 5.49344 11.3068 5.16984C11.3068 5.06523 11.3141 4.96004 11.3284 4.85707C11.4855 3.73117 12.4637 2.89902 13.6008 2.92234C14.773 2.94598 15.7389 3.88102 15.7996 5.05105C15.8319 5.67395 15.6136 6.2657 15.1849 6.71723Z"
                      fill="white"
                    />
                    <path
                      id="Vector_2"
                      d="M8.39845 13.4689H4.4922C4.1686 13.4689 3.90626 13.2066 3.90626 12.883C3.90626 12.5594 4.1686 12.297 4.4922 12.297H8.1404C6.92966 11.0722 6.12993 9.44024 5.96837 7.6272C2.6363 7.87212 1.00356e-05 10.6613 1.00356e-05 14.0549C1.00356e-05 15.5269 0.496065 16.935 1.40653 18.0741L0.14333 19.5303C-0.00702121 19.7036 -0.0423728 19.9488 0.0529007 20.1575C0.148174 20.3662 0.356494 20.5002 0.585948 20.5002H6.44532C9.83888 20.5002 12.6281 17.8639 12.873 14.5318C11.4147 14.4018 10.0737 13.8588 8.96724 13.0215C8.90493 13.2781 8.67427 13.4689 8.39845 13.4689ZM8.39845 15.8127H4.4922C4.1686 15.8127 3.90626 15.5503 3.90626 15.2267C3.90626 14.9031 4.1686 14.6408 4.4922 14.6408H8.39845C8.72204 14.6408 8.98439 14.9031 8.98439 15.2267C8.98439 15.5503 8.72204 15.8127 8.39845 15.8127Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </span>
              {joinLabel}
            </button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
