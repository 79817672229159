/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { documentDataStreamRequest } from '../../../api/documents';
import { docsharePaginatorStyles } from '../../../pages/styles/docsharing';
import PDFDisplayFraud from '../fraud-misc/PDFDisplayFraud';

interface FraudDocumentPreviewProps {
  analysisData: any;
  indicator: any;
}

const FraudDocumentPreview: React.FC<FraudDocumentPreviewProps> = ({
  analysisData,
  indicator,
}) => {
  const [byteStream, setByteStream] = useState<Uint8Array>();
  const [totalNumPages, setTotalNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleDocumentByteStream = async () => {
    const documentData = documentDataStreamRequest(analysisData.file_id);
    const byteCharacters = window.atob(await documentData);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const data = new Uint8Array(byteNumbers);
    setByteStream(data);
  };

  useEffect(() => {
    handleDocumentByteStream();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <Box sx={{ maxWidth: '600px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: '16px',
        }}
      >
        {totalNumPages !== 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start', // Change this line to 'flex-start'
              alignItems: 'flex-end',
              gap: '8px',
              marginLeft: 'auto',
            }}
          >
            <Button
              variant="contained"
              disabled={currentPage === 1}
              onClick={goToPreviousPage}
              id="prev-page"
              sx={{
                ...docsharePaginatorStyles,
              }}
            >
              <span style={{ color: 'white' }} className="material-icons-round">
                arrow_back_ios
              </span>
            </Button>
            <Button
              variant="contained"
              disabled={currentPage === totalNumPages}
              onClick={goToNextPage}
              id="next-page"
              sx={{
                ...docsharePaginatorStyles,
              }}
            >
              <span style={{ color: 'white' }} className="material-icons-round">
                arrow_forward_ios
              </span>
            </Button>
          </Box>
        )}
      </Box>
      <PDFDisplayFraud
        byteStream={byteStream}
        currentPage={currentPage}
        setTotalNumPages={setTotalNumPages}
        indicator={indicator}
      />
    </Box>
  );
};

export default FraudDocumentPreview;
