import { User } from '../types/auth';
import { Filters, MatterHistory, TaskHistory } from '../types/matters';
import { CreateUserResponse } from '../types/users';
import {
  transformArrayObjectKeysToCamelCase,
  transformObjectKeysToCamelCase,
  transformObjectKeysToSnakeCase,
} from '../utils/utils';
import api from './index';

async function usersRequest(
  method?: 'GET' | 'POST',
  data?: Omit<User, 'dateJoined'>,
  searchText?: string,
  matterId?: string
): Promise<User[] | CreateUserResponse> {
  const endpoint = '/users';
  let transformedData: Partial<Omit<User, 'dateJoined'>> = {};

  if (data) {
    transformedData = transformObjectKeysToSnakeCase(data);
  }

  if (method === 'POST') {
    await api.post(endpoint, transformedData);
    // check if this jumps or makes unwanted requests
  }

  const filters: { search?: string; matter_id?: string } = {};

  if (searchText && searchText !== '') {
    filters.search = searchText;
  }

  if (matterId) {
    filters.matter_id = matterId;
  }

  const response = await api.get(endpoint, {
    params: filters,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformedUsers = response.data.users.map((user: any) =>
    transformObjectKeysToCamelCase(user)
  );

  return transformedUsers;
}

async function userRequest(userId: string): Promise<User> {
  const response = await api.get(`/user/${userId}`);
  return response.data;
}

async function userDeleteRequest(userId: string) {
  await api.delete(`/user/${userId}`);
}

async function userUpdateRequest(
  userId: string,
  data: Omit<User, 'dateJoined'>
) {
  let transformedData: Partial<Omit<User, 'dateJoined'>> = {};

  transformedData = transformObjectKeysToSnakeCase(data);
  await api.patch(`/user/${userId}`, transformedData);
}

async function userMatterHistoryRequest(filters: Filters, username?: string) {
  let endpoint = '/user-matter-history';
  let transformedData: Partial<MatterHistory[]> = [];

  if (username) {
    endpoint += `?user=${username}`;
  }

  const response = await api.get(endpoint, {
    params: filters,
  });

  transformedData = transformArrayObjectKeysToCamelCase(response.data) as
    | MatterHistory[];

  return transformedData;
}

async function userTaskHistoryRequest(filters: Filters, username?: string) {
  let endpoint = '/user-task-history';
  let transformedData: Partial<TaskHistory[]> = [];

  if (username) {
    endpoint += `?user=${username}`;
  }

  const response = await api.get(endpoint, {
    params: filters,
  });

  transformedData = transformArrayObjectKeysToCamelCase(response.data) as
    | TaskHistory[];

  return transformedData;
}

async function batchDeleteUserRequest(userIds: number[]) {
  await api.post(`/bulk-user-actions`, { users_to_delete: userIds });
}

export {
  usersRequest,
  userRequest,
  userDeleteRequest,
  userUpdateRequest,
  userMatterHistoryRequest,
  userTaskHistoryRequest,
  batchDeleteUserRequest,
};
