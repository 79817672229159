import { Box, Typography } from '@mui/material';
import { creationNotificationStyles } from '../../matters/matter-creation/styles';

interface CreationNotificationProps {
  title: string;
  subTitle: string;
  showNotification: boolean;
  handleCloseNotification: () => void;
}

const CreationNotification = ({
  title,
  subTitle,
  showNotification,
  handleCloseNotification,
}: CreationNotificationProps) => {
  return (
    <Box sx={creationNotificationStyles(showNotification)}>
      <Box>
        <Box>
          <span className="material-icons-round">check_circle</span>
        </Box>
        <Box>
          <Box>
            <Typography>{title}</Typography>
            <Typography>{subTitle}</Typography>
          </Box>
        </Box>
      </Box>
      <Box onClick={handleCloseNotification}>
        <Typography>Close</Typography>
      </Box>
    </Box>
  );
};

export default CreationNotification;
