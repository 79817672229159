import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import {
  matterDetailBundlesRequest,
  matterDetailDocumentsRequest,
  matterDetailsRequest,
} from '../../../api/matters';

import { useDocumentPermissions } from '../../../hooks/usePermissions';
import NotificationToast from '../../shared/toast/NotificationToast';
import RelativityTab from '../../relativity/RelativityTab';
import MatterDetailDocumentFraudTab from './MatterDetailDocumentFraudTab';
import MatterDetailDocumentBundleTab from './MatterDetailDocumentBundleTab';
import MatterDetailDocumentTab from './MatterDetailDocumentTab';
import ImanageTab from '../../imanage/ImanageTab';

interface MatterDetailDocumentsProps {
  control: any;
  setValue: any;
  matterId: any;
  matterDetails: any;
  setMatterDetails: any;
}

interface BundleOptionType {
  inputValue?: string;
  name: string;
  id: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ display: value !== index ? 'none' : 'block' }}
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

interface DocumentOptionType {
  inputValue?: string;
  name: string;
  id: number | string;
}

const MatterDetailDocuments = ({
  control,
  setValue,
  matterId,
  matterDetails,
  setMatterDetails,
}: MatterDetailDocumentsProps) => {
  const documentPerms = useDocumentPermissions();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingBundle, setLoadingBundles] = useState(true);
  const [matterDetailDocs, setMatterDetailsDocs] = useState<any>();
  const [documentsOptions, setDocumentsOptions] =
    useState<readonly DocumentOptionType[]>();
  const [matterDetailBundles, setMatterDetailsbundles] = useState<any>();
  const [bundlesOptions, setBundlesOptions] =
    useState<readonly BundleOptionType[]>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const [relativityUserData, setRelativityUserData] = useState({
    user: '',
    password: '',
    connected: false,
  });

  const [imanageUserData, setImanageUserData] = useState({
    user: '',
    password: '',
    connected: false,
    token: '',
    customer: '',
  });

  const handleGetMatterDetailDocuments = async () => {
    setLoading(true); // Start loading
    try {
      if (matterId) {
        const response = await matterDetailDocumentsRequest(
          matterId,
          inputRef.current?.value || ''
        );

        setMatterDetailsDocs(response);
        const documentsOptionType: DocumentOptionType[] =
          response.documents.map((document: any) => ({
            id: document.id,
            name: document.name,
          }));
        setDocumentsOptions(documentsOptionType);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetMatterDetailBundles = async () => {
    setLoadingBundles(true); // Start loading
    try {
      if (matterId) {
        const response = await matterDetailBundlesRequest(
          matterId,
          inputRef.current?.value || ''
        );

        setMatterDetailsbundles(response);

        const bundlesOptionType: BundleOptionType[] = response.bundles.map(
          (bundle: any) => ({
            id: bundle.id,
            name: bundle.name,
          })
        );

        setBundlesOptions(bundlesOptionType);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoadingBundles(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setTabValue(newValue);

    switch (newValue) {
      case 0:
        handleGetMatterDetailDocuments();
        break;
      case 1:
        handleGetMatterDetailBundles();
        break;

      default:
        break;
    }
  };

  const handleListSearchbarInputChange = () => {
    switch (tabValue) {
      case 0:
        handleGetMatterDetailDocuments();
        break;
      case 1:
        handleGetMatterDetailBundles();
        break;
      default:
        break;
    }
  };

  const refreshTables = () => {
    handleGetMatterDetailDocuments();
    handleGetMatterDetailBundles();
  };

  useEffect(() => {
    refreshTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateMatterDetails = async () => {
    try {
      if (matterId) {
        const response = await matterDetailsRequest(matterId);
        setMatterDetails(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  return (
    <>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            borderBottom: '1px solid #949CA9',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& > div': {
                '& > div': {
                  '& > button': {
                    display: 'flex',
                    width: '224px',
                    padding: '8px 24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '24px 24px 0px 0px',
                    '&.Mui-selected': {
                      backgroundColor: '#F5FAFD',
                      color: '#0053FF',
                    },

                    color: '#69707B',
                    textTransform: 'none',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                '& > span.MuiTabs-indicator': {
                  backgroundColor: '#0053FF',
                },
              },
            }}
          >
            <Tab label="All Documents" {...a11yProps(0)} />
            <Tab label="Interview Bundles" {...a11yProps(1)} />
            {process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' && (
              <Tab label="Fraud Detection" {...a11yProps(2)} />
            )}
            <Tab label="E-Discovery" {...a11yProps(3)} />
            <Tab label="iManage" {...a11yProps(4)} />
          </Tabs>
        </Box>
      </Box>

      <Box sx={{ width: '100%' }}>
        <CustomTabPanel value={tabValue} index={0}>
          <MatterDetailDocumentTab
            matterId={matterId}
            matterDetails={matterDetails}
            matterDetailDocs={matterDetailDocs}
            handleGetMatterDetailDocuments={handleGetMatterDetailDocuments}
            documentPerms={documentPerms}
            loadingDocs={loading}
            handleUpdateMatterDetails={handleUpdateMatterDetails}
            control={control}
            setValue={setValue}
            documentsOptions={documentsOptions}
            bundlesOptions={bundlesOptions}
            handleListSearchbarInputChange={handleListSearchbarInputChange}
            inputRef={inputRef}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <MatterDetailDocumentBundleTab
            matterId={matterId}
            matterDetails={matterDetails}
            matterDetailBundles={matterDetailBundles}
            handleGetMatterDetailBundles={handleGetMatterDetailBundles}
            documentPerms={documentPerms}
            loadingBundle={loadingBundle}
            handleListSearchbarInputChange={handleListSearchbarInputChange}
            inputRef={inputRef}
          />
        </CustomTabPanel>
        {process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' && (
          <CustomTabPanel value={tabValue} index={2}>
            <MatterDetailDocumentFraudTab
              matterId={matterId}
              matterDetails={matterDetails}
            />
          </CustomTabPanel>
        )}
        <CustomTabPanel
          value={tabValue}
          index={
            process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' ? 3 : 2
          }
        >
          <RelativityTab
            matterId={matterId}
            relativityUserData={relativityUserData}
            setRelativityUserData={setRelativityUserData}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={tabValue}
          index={
            process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' ? 4 : 3
          }
        >
          <ImanageTab
            matterId={matterId}
            imanageUserData={imanageUserData}
            setImanageUserData={setImanageUserData}
            matterDetailDocs={matterDetailDocs}
            documentsOptions={documentsOptions}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default MatterDetailDocuments;
