import { Box, Typography } from '@mui/material';
import LEIAALogo1 from '../assets/LEIAALogo1.png';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../styles/textStyles';
import {
  mainFormWrapperStyles,
  mainLoginContainerStyles,
} from './styles/login';

const MobileWarningPage = () => {
  return (
    <Box sx={mainLoginContainerStyles}>
      <Box sx={{ ...mainFormWrapperStyles, border: '0', boxShadow: '0' }}>
        <Box>
          <Box>
            <img
              src={LEIAALogo1}
              style={{ width: '228.12px', height: '99.41px' }}
              alt="LEIAALogo1"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '24px',
              width: '352px',
            }}
          >
            <Box>
              <span
                style={{ color: '#0053FF', fontSize: '40px' }}
                className="material-icons-round"
              >
                info
              </span>
            </Box>
            <Typography
              sx={{
                ...sofiaProBoldFontStyles,
                color: '#202020',
                textTransform: 'uppercase',
                fontSize: '25px',
                fontWeight: '500',
              }}
            >
              Hello!
            </Typography>
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontSize: '17px',
                color: '#202020',
              }}
            >
              Our platform is optimised for desktop usage, please head to your
              <strong> desktop</strong> or <strong>laptop</strong> in order to
              enter the platform.
            </Typography>
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontSize: '17px',
                color: '#202020',
              }}
            >
              Thank you!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileWarningPage;
