import * as React from 'react';

import type { WidgetState } from '@livekit/components-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isTrackReference, log } from '@livekit/components-core';

import { RoomEvent, Track } from 'livekit-client';
import {
  ConnectionStateToast,
  FocusLayoutContainer,
  LayoutContextProvider,
  MessageFormatter,
  RoomAudioRenderer,
  useCreateLayoutContext,
  useParticipants,
  usePinnedTracks,
  useTracks,
} from '@livekit/components-react';
import { Box, Typography } from '@mui/material';
import { LEIAAControlBar } from '../interview-control-bar/LEIAAControlBar';
import { LEIAACarouselLayout } from './LEIAACarouselLayout';
import { LEIAAParticipantTile } from './LEIAAParticipantTile';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import { videoConferenceContainerStyles } from './styles/styles';
import AutomatedQuestionnaire from './AutomatedQuestionnaire';

/**
 * @public
 */
export interface VideoConferenceProps
  extends React.HTMLAttributes<HTMLDivElement> {
  chatMessageFormatter?: MessageFormatter;
}

interface LEIAAVideoConferenceAutomatedProps extends VideoConferenceProps {
  interviewDetails: any;
  currentUser: any;
}

/**
 * This component is the default setup of a classic LiveKit video conferencing app.
 * It provides functionality like switching between participant grid view and focus view.
 *
 * @remarks
 * The component is implemented with other LiveKit components like `FocusContextProvider`,
 * `GridLayout`, `ControlBar`, `FocusLayoutContainer` and `FocusLayout`.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <VideoConference />
 * <LiveKitRoom>
 * ```
 * @public
 */
export function LEIAAVideoConferenceAutomated({
  chatMessageFormatter,
  interviewDetails,
  currentUser,
  ...props
}: LEIAAVideoConferenceAutomatedProps) {
  const [widgetState, setWidgetState] = React.useState<WidgetState>({
    showChat: false,
  });
  const numParticipants = useParticipants();

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged] }
  );

  const widgetUpdate = (state: WidgetState) => {
    log.debug('updating widget state', state);
    setWidgetState(state);
  };

  const layoutContext = useCreateLayoutContext();

  const screenShareTracks = tracks
    .filter(isTrackReference)
    .filter((track) => track.publication.source === Track.Source.ScreenShare);

  const focusTrack = usePinnedTracks(layoutContext)?.[0];
  const carouselTracks = tracks;

  React.useEffect(() => {
    // if screen share tracks are published, and no pin is set explicitly, auto set the screen share
    if (screenShareTracks.length > 0 && focusTrack === undefined) {
      layoutContext.pin.dispatch?.({
        msg: 'set_pin',
        trackReference: screenShareTracks[0],
      });
    } else if (
      (screenShareTracks.length === 0 &&
        focusTrack?.source === Track.Source.ScreenShare) ||
      tracks.length <= 1
    ) {
      layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(screenShareTracks.map((ref) => ref.publication.trackSid)),
    tracks.length,
    focusTrack?.publication?.trackSid,
  ]);

  return (
    <Box
      className="lk-video-conference"
      {...props}
      sx={videoConferenceContainerStyles}
    >
      <LayoutContextProvider
        value={layoutContext}
        // onPinChange={handleFocusStateChange}
        onWidgetChange={widgetUpdate}
      >
        <Box className="lk-video-conference-inner">
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '64px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  ...sofiaProBoldFontStyles,
                  fontSize: '18px',
                  lineHeight: '20.7px',
                  letterSpacing: '0.5px',
                  color: '#FFFFFF',
                }}
              >
                {interviewDetails.type} {interviewDetails.category}{' '}
                {interviewDetails.interviewee_name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  '& > p': {
                    color: '#FFF',
                    textAlign: 'center',
                    ...karlaProRegularFontStyles,
                    fontSize: '13px',
                    lineHeight: 'normal',
                    alignSelf: 'center',
                  },
                }}
              >
                <span className="material-icons-round">group</span>
                <Typography>{numParticipants.length}</Typography>
              </Box>
            </Box>
            <Box
              className="lk-focus-layout-wrapper"
              sx={{ '& > div': { gap: '32px', padding: '0px' } }}
            >
              <FocusLayoutContainer>
                <LEIAACarouselLayout tracks={carouselTracks}>
                  <LEIAAParticipantTile />
                </LEIAACarouselLayout>
                {/* {focusTrack && <FocusLayout track={focusTrack} />} */}
                <AutomatedQuestionnaire interviewDetails={interviewDetails} />
              </FocusLayoutContainer>
            </Box>
          </Box>
          <LEIAAControlBar
            controls={{ chat: true, screenShare: false }}
            chatBtnPressed={widgetState.showChat}
            currentUser={currentUser}
            interviewDetails={interviewDetails}
            documentUpload
            inPerson={false}
          />
        </Box>
      </LayoutContextProvider>
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </Box>
  );
}
