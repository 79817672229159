import { Box, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import LEIAAContext from '../../../context/context';
import {
  taskCardFooterBoxStyles,
  taskCardTitleStyles,
  taskCardDueTextStyles,
  taskCardFooterIconStyles,
  taskCardFooterTextStyles,
  taskCardFooterSubTextStyles,
} from '../../my-dashboard/tasks/taskstyles';

interface ReportKanbanCardProps {
  item: any;
  index: any;
}

const ReportKanbanCard = ({ item, index }: ReportKanbanCardProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(LEIAAContext);

  const handleDetail = (key: any) => {
    if (['Admin', 'Superuser', 'Leader'].includes(user.role))
      navigate(`/report/${key}`, {
        state: { previous: 'kanban' },
      });
  };
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {/* <TaskSlidePanel
            item={item}
            open={open}
            setOpen={setOpen}
            availableTags={availableTags}
            setAvailableTags={setAvailableTags}
            matterDetail={matterDetail}
          /> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              padding: '15px',
              borderRadius: '10px',
              maxWidth: '311px',
              background: 'white',
              marginTop: '15px',
              border: '1px solid #F0F8FD',
              gap: '5px',
              '&:hover': {
                background: '#f5fafd',
                border: '1px solid rgb(78 157 250)',
              },
            }}
            onClick={() => {
              handleDetail(item.key);
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={taskCardFooterBoxStyles}
            >
              <Typography className="board-title-card" sx={taskCardTitleStyles}>
                {item.publicid}
              </Typography>

              <Typography sx={taskCardFooterTextStyles}>
                <span
                  style={taskCardFooterIconStyles}
                  className="material-icons-round"
                >
                  warning
                </span>
                <span
                  style={{
                    ...taskCardFooterSubTextStyles,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '180px',
                  }}
                >
                  {item.severity}
                </span>
              </Typography>
            </Stack>
            {item.subject && (
              <Typography
                sx={{
                  taskCardDueTextStyles,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {item.subject}
              </Typography>
            )}

            <Typography
              sx={{
                ...taskCardDueTextStyles,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
            >
              <span
                style={taskCardFooterIconStyles}
                className="material-icons-round"
              >
                sell
              </span>

              <span>{item.topic}</span>
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={taskCardFooterBoxStyles}
            >
              <Typography sx={taskCardDueTextStyles}>
                <span style={{ color: '#4f4f4f', fontSize: '14px' }}>
                  Days open
                </span>

                <span style={{ marginLeft: '5px' }}>{item.daysopen}</span>
              </Typography>

              {item.deadline && (
                <Typography sx={taskCardDueTextStyles}>
                  <span style={{ color: '#4f4f4f', fontSize: '14px' }}>
                    Due
                  </span>

                  <span style={{ marginLeft: '5px' }}>{item.deadline}</span>
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default ReportKanbanCard;
