import { useNavigate } from 'react-router-dom';
import { Box, InputLabel, Typography } from '@mui/material';
import { detailsDataStyles } from './styles';
import InterviewEmailChat from '../interview-email-chat/InterviewEmailChat';

interface LEIAAInterviewDetailsProps {
  interview?: any;
  matter?: any;
}
const LEIAAInterviewDetails = ({
  interview = [],
  matter = false,
}: LEIAAInterviewDetailsProps) => {
  const navigate = useNavigate();

  return (
    <Box sx={detailsDataStyles}>
      {matter && (
        <Box>
          <InputLabel
            data-testid="interview-detail-category"
            htmlFor="Category"
          >
            Matter
          </InputLabel>
          <Typography
            onClick={() =>
              navigate(`/matters/detail/${interview.matter_id}`, {
                state: {},
              })
            }
            sx={{
              '&:hover': {
                color: '#0B25B0 !important',
                cursor: 'pointer',
              },
            }}
          >
            {matter.name}
            <span className="material-icons-round">link</span>
          </Typography>
        </Box>
      )}
      {interview.category && (
        <Box>
          <InputLabel
            data-testid="interview-detail-category"
            htmlFor="Category"
          >
            Category
          </InputLabel>
          <Typography>{interview.category}</Typography>
        </Box>
      )}
      {interview.type && (
        <Box>
          <InputLabel data-testid="interview-detail-type" htmlFor="Type">
            Type
          </InputLabel>
          <Typography>{interview.type}</Typography>
        </Box>
      )}
      {interview.date && (
        <Box>
          <InputLabel data-testid="interview-detail-date" htmlFor="Date">
            Date
          </InputLabel>
          <Typography>{interview.date}</Typography>
        </Box>
      )}
      {interview.duration && (
        <Box>
          <InputLabel
            data-testid="interview-detail-duration"
            htmlFor="Duration"
          >
            Duration
          </InputLabel>
          <Typography>{interview.duration}</Typography>
        </Box>
      )}
      {interview.description && (
        <Box>
          <InputLabel
            data-testid="interview-detail-description"
            htmlFor="Description"
          >
            Description (internal use only)
          </InputLabel>
          <Typography>{interview.description}</Typography>
        </Box>
      )}
      {interview.instructions && (
        <Box>
          <InputLabel
            data-testid="interview-detail-instructions"
            htmlFor="Intructions"
          >
            Interview Instructions (internal use only)
          </InputLabel>
          <Typography>{interview.instructions}</Typography>
        </Box>
      )}
      {interview.email_invitation && (
        <Box>
          <InputLabel data-testid="interview-detail-email" htmlFor="Email">
            Email Invitation
          </InputLabel>
          <Typography>{interview.email_invitation}</Typography>
        </Box>
      )}
      <InterviewEmailChat interview={interview} />
    </Box>
  );
};

export default LEIAAInterviewDetails;
