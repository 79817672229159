import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../../../styles/textStyles';

export const matterInterviewFormWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '8px',
    },
    '& > div:nth-of-type(2)': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      gap: '24px',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '8px',
        '& > label.MuiFormLabel-root': {
          display: 'flex',
          padding: '0px 12px',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          color: '#464B53',
          fontSize: '14px',
          ...karlaProRegularFontStyles,
          lineHeight: '22px',
        },
        '& > div.MuiFormControl-root': {
          margin: '0px',
          padding: '0px',
        },
      },
    },
  },
};

export const matterAddedInterviewInputWrapperStyles = {
  display: 'flex',
  flexDirection: 'row !important',
  alignItems: 'center',
  gap: '8px',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      width: '24px',
      height: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: '12px',
      backgroundColor: '#0053FF',
      '& > p': {
        color: '#FFF',
        textAlign: 'center',
        ...karlaProRegularFontStyles,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
      },
    },
  },
  '& > div:nth-of-type(2)': {
    display: 'flex',
    alignItems: 'flex-start',
    '& > p': {
      color: '#202020',
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
};

export const matterAddedInterviewAccordionStyles = (
  selectedIndex: number,
  index: number,
  isExpanded: boolean
) => {
  return {
    boxShadow: 'unset',
    border: 'none',
    outline: 'none',
    width: '100%',
    zIndex: 2,
    backgroundColor: '#F5FAFD',
    '&::before': {
      content: 'none',
    },
    '&.Mui-expanded': {
      minHeight: 'unset',
      margin: '0px !important',
    },
    '& > div:nth-of-type(1)': {
      display: 'flex',
      padding: '8px 16px',
      minHeight: '34px !important',
      borderRadius: '12px',
      // border: selectedIndex === index ? '1px solid #464B53' : '1px solid #FFF',
      backgroundColor: '#D3ECF6',
      gap: '16px',
      '&::before': {
        display: isExpanded ? 'block' : 'none',
        content: "''",
        position: 'absolute',
        left: 0,
        top: '15px',
        height: '37px',
        width: '100%',
        backgroundColor: '#F0F8FD',
        zIndex: -1,
      },
      '& > div': {
        width: '100%',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        margin: '0px !important',
        minHeight: '34px !important',
        '& > div': {
          margin: '0px',
          display: 'flex',
          gap: '8px',
          height: '22px',
          '& > p:nth-of-type(1)': {
            color: '#464B53',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
          },
        },
      },
      '.Mui-expanded': {
        minHeight: 'unset',
        margin: '0px !important',
      },
      '& > div:nth-of-type(2) > span': {
        color: '#000000',
      },
    },
  };
};

export const matterAddedInterviewAccordionDetailsStyles = {
  padding: '0px',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      padding: '8px 16px 8px 32px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
      alignSelf: 'stretch',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        '& > p:nth-of-type(1)': {
          color: '#949CA9',
          ...sofiaProRegularFontStyles,
          fontSize: '16px',
          fontWeight: 500,
          letterSpacing: '0.3px',
        },
        '& > p:nth-of-type(2)': {
          color: '#464B53',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
        },
      },
    },
  },
};

export const matterAddedInterviewerAccordionStyles = (isExpanded: boolean) => {
  return {
    boxShadow: 'unset',
    border: 'none',
    outline: 'none',
    width: '100%',
    zIndex: 2,
    '&::before': {
      content: 'none',
    },
    '&.Mui-expanded': {
      margin: '0px !important',
    },
    '& > div:nth-of-type(1)': {
      display: 'flex',
      gap: '8px',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      minHeight: '34px !important',
      borderRadius: '12px',
      backgroundColor: '#D3ECF6',
      position: 'relative',
      '&::before': {
        display: isExpanded ? 'block' : 'none',
        content: "''",
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '37px',
        width: '100%',
        backgroundColor: '#F2FAFE',
        zIndex: -1,
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > div': {
          margin: '0px !important',
          '& > p': {
            color: '#464B53',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
        minHeight: '34px !important',
        padding: '0px',
        '.Mui-expanded': {
          minHeight: 'unset',
          margin: '0px !important',
        },
      },
    },
  };
};

export const matterAddedInterviewerAccordionDetailsStyles = {
  display: 'flex',
  padding: '24px 0px',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
  backgroundColor: '#F2FAFE',
  '& > div': {
    display: 'flex',
    padding: '0px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    '& > div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        '& > label.MuiFormLabel-root': {
          display: 'flex',
          padding: '0px 12px',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          color: '#464B53',
          fontSize: '14px',
          ...karlaProRegularFontStyles,
          lineHeight: '22px',
        },
      },
    },
  },
};
