/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, InputAdornment } from '@mui/material';

interface DropdownMenuIconProps {
  params?: any;
  inputValue?: any;
  isMenuOpen: boolean;
  handleDropdownClientIconClick: () => void;
  isAdornment: boolean;
  iconColor?: string;
}

const DropdownMenuIcon = ({
  params,
  inputValue,
  isMenuOpen,
  handleDropdownClientIconClick,
  isAdornment = true,
  iconColor = '#69707B',
}: DropdownMenuIconProps) => {
  // eslint-disable-next-line no-nested-ternary
  return isAdornment ? (
    !inputValue && inputValue !== 0 && inputValue !== false ? (
      <InputAdornment position="end">
        <IconButton
          onClick={handleDropdownClientIconClick}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& > span': {
              color: iconColor,
            },
          }}
        >
          {isMenuOpen ? (
            <span className="material-icons-round">arrow_drop_up</span>
          ) : (
            <span className="material-icons-round">arrow_drop_down</span>
          )}
        </IconButton>
      </InputAdornment>
    ) : (
      params.InputProps.endAdornment
    )
  ) : (
    <IconButton
      onClick={handleDropdownClientIconClick}
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& > span': {
          color: iconColor,
        },
      }}
    >
      {isMenuOpen ? (
        <span className="material-icons-round">arrow_drop_up</span>
      ) : (
        <span className="material-icons-round">arrow_drop_down</span>
      )}
    </IconButton>
  );
};

export default DropdownMenuIcon;
