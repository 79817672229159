import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Divider, Step, StepButton, Stepper } from '@mui/material';
import ResourcePageHeader from '../../components/shared/resource-page-header/ResourcePageHeader';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import Tasks from './tasks/Tasks';
import MyMatters from './matters/MyMatters';
import MyInterviews from './interviews/MyInterviews';
import InsightsContent from '../../components/homeportal/insights-content/InsightsContent';
import { getMyDashboardGeneralRequest } from '../../api/general';
import MyCalendar from './calendar/MyCalendar';

const steps = [
  {
    name: 'Calendar',
    icon: <span className="material-icons-round">event_note</span>,
  },
  {
    name: 'Matters',
    icon: <span className="material-icons-round">folder</span>,
  },
  { name: 'Tasks', icon: <span className="material-icons-round">task</span> },
  {
    name: 'Interviews',
    icon: <span className="material-icons-round">groups_3</span>,
  },
];

const MyDashboard = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const locationActiveStep = location.state?.activeStep;

  const [activeStep, setActiveStep] = useState(locationActiveStep || 0);
  const [metricsData, setMetricsData] = useState<any>(null);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <MyCalendar />;
      case 1:
        return <MyMatters />;
      case 2:
        return <Tasks />;
      case 3:
        return <MyInterviews />;
      default:
        return null;
    }
  };

  const handleFetchMetricsData = async () => {
    try {
      const response = await getMyDashboardGeneralRequest();
      setMetricsData(response);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchMetricsData();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <ResourcePageHeader
              inputRef={searchInputRef}
              headerText="My Dashboard"
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            {metricsData && (
              <InsightsContent
                insightOneText="Active matters"
                insightOneContentSent={metricsData.activeMatters}
                insightTwoText="Avg. matter duration"
                insightTwoContentSent={metricsData.avgMatterDuration}
                insightThreeText="Assigned tasks"
                insightThreeContentSent={metricsData.assignedTasks}
                insightFourText="Scheduled interviews"
                insightFourContentSent={metricsData.scheduledInterviews}
              />
            )}
          </Box>
          <Box sx={{ width: '100%' }}>
            <Stepper
              nonLinear
              activeStep={activeStep}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '8px',
                '& > div.MuiStep-root': {
                  padding: '0px !important',
                  margin: '0px',
                  display: 'flex',
                  height: '32px',
                  paddingTop: '8px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  width: '224px',
                  flex: '1 0 0',
                  '& > button': {
                    display: 'flex',
                    padding: '0px 0px 0px 16px',
                    alignItems: 'center',
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                    margin: '0px',
                    justifyContent: 'unset',
                    '& > span': {
                      gap: '8px',
                      '& > span.MuiStepLabel-iconContainer:not(.Mui-active)': {
                        '& > span': {
                          color: '#69707B',
                        },
                        '& > svg > path': {
                          fill: '#69707B',
                        },
                      },
                      '& > span.MuiStepLabel-iconContainer.Mui-active': {
                        '& > span': {
                          color: '#0053FF',
                        },
                        '& > span > svg': {
                          fill: '#0053FF',
                        },
                        '& > span > svg > path': {
                          fill: '#0053FF !important',
                        },
                      },
                      '& > span.MuiStepLabel-labelContainer': {
                        '& > span': {
                          color: '#69707B',
                          fontSize: '16px',
                          ...karlaProRegularFontStyles,
                          fontWeight: 500,
                          lineHeight: '24px',
                        },
                        '& > span.Mui-active': {
                          color: '#0053FF',
                        },
                      },
                    },
                  },
                },
                '& > .MuiStepConnector-root': {
                  display: 'none', // Add this line to hide the MuiStepConnector
                },
              }}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                    icon={step.icon}
                  >
                    {step.name}
                  </StepButton>
                  {activeStep === index ? (
                    <Divider
                      sx={{ width: '100%', border: '1px solid #0053FF' }}
                    />
                  ) : (
                    <Divider
                      sx={{ width: '100%', border: '1px solid #69707B' }}
                    />
                  )}
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        {renderStepContent()}
      </Box>
    </Box>
  );
};

export default MyDashboard;
