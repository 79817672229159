import { Badge, Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import LEIAAPagination from '../lists/LEIAAPagination';
import SortableHeader from '../matters-list-table/SortableHeader';
import {
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
  mattersListWrapperStyles,
} from '../matters-list-table/styles';
import SeverityChip from './SeverityChip';
import StageChip from './StageChip';
import StatusChip from './StatusChip';

interface ReportListTableProps {
  reportsListData: any | null;
  loading: boolean;
  listClass: string;
  handleReportDetail: any;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No reports.
    </Typography>
  </GridOverlay>
);

const ReportListTable = ({
  reportsListData,
  loading,
  listClass,
  handleReportDetail,
}: ReportListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);
  const [badgeUpperLimit, setBadgeUpperLimit] = useState(5);
  const [badgeLowerLimit, setBadgeLowerLimit] = useState(0);
  const [sorting, setSorting] = useState<any>(null);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateNotificationPos = (index: number) => {
    const wrappedIndex = index % 5; // Wraps index to 0-4 range
    const adjustedIndex = wrappedIndex + 1; // Adjust to 1-5 range

    if (adjustedIndex !== 1) {
      return adjustedIndex * 57 + 18 * adjustedIndex;
    }
    return adjustedIndex * 57;
  };

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && reportsListData?.reports !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, reportsListData]);

  const columns: GridColDef[] = [
    {
      field: 'publicid',
      headerName: 'ID',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'topic',
      headerName: 'Topic',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'date',
      headerName: 'Date Received',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'severity',
      headerName: 'Severity',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <SeverityChip
            status={cellValue.toString()}
            heightValue="30px"
            wrapperHeight="36px"
          />
        );
      },
    },
    {
      field: 'stage',
      headerName: 'Stage',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <StageChip
            status={cellValue.toString()}
            heightValue="30px"
            wrapperHeight="36px"
          />
        );
      },
    },
    {
      field: 'deadline',
      headerName: 'Next Deadline',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <StatusChip
            status={cellValue.toString()}
            heightValue="30px"
            wrapperHeight="36px"
          />
        );
      },
    },
    {
      field: 'finaldeadline',
      headerName: 'Completion Deadline',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
  ];

  const rowsAreEmpty = reportsListData?.reports.length === 0;

  return (
    <Box sx={mattersListWrapperStyles(rowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={reportsListData?.reports as any[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            onPaginationModelChange={(params) => {
              setBadgeLowerLimit(params.page * params.pageSize);
              setBadgeUpperLimit((params.page + 1) * params.pageSize);
            }}
            onSortModelChange={(params) => {
              setSorting(params[0]);
            }}
            pageSizeOptions={[5]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            sx={mattersListStyles}
            className={listClass}
            onRowClick={(params) => {
              handleReportDetail(params.row);
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {/* Render the badge on top of each row - unusual implementation but datagrid was buggy and intruced way to much complexity */}
        {!loading &&
          reportsListData &&
          reportsListData?.reports.length > 0 &&
          reportsListData?.reports
            .slice()
            .sort((a: any, b: any) => {
              if (sorting) {
                return sorting.sort === 'asc'
                  ? a[sorting.field] - b[sorting.field]
                  : b[sorting.field] - a[sorting.field];
              }
              return 0;
            })
            .map((row: any, index: any) => {
              return (
                (row.status === 'New' || !row.matter) &&
                index >= badgeLowerLimit &&
                index < badgeUpperLimit && (
                  <Box
                    key={row.id}
                    sx={{
                      position: 'absolute',
                      top: `${calculateNotificationPos(index)}px`, // Adjust based on row height
                      left: '8px',
                      zIndex: 1,
                    }}
                  >
                    <Badge
                      color="secondary"
                      overlap="circular"
                      badgeContent=""
                      sx={{
                        '& > span': {
                          backgroundColor: '#ffb900',
                          width: '20px',
                          height: '20px',
                        },
                      }}
                    >
                      {/* commented in favor of only badge icon <MailIcon sx={{ fontSize: '20px', color: '#979ea9' }} /> */}
                    </Badge>
                  </Box>
                )
              );
            })}
      </Box>
    </Box>
  );
};

export default ReportListTable;
