import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { FieldValues, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getWikiTemplateDetailsRequest } from '../../../../api/wiki';
import { LEIAAUploadFileArea, LEIAADialog } from '../../../inputs';
import { matterQuestionnaireUploadAreaWrapperStyles } from '../../../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  MatterAddedDocumentsInformation,
  SidePanelInformation,
} from '../../../matters/matter-creation/sidepanel-information';
import {
  wikiTemplateWrapperStyles,
  wikiTemplateFormWrapperStyles,
  wikiTemplateInputWrapperStyles,
} from './styles';

interface WikiReportProps {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

interface FileWithId extends File {
  id: string;
  uploaded: boolean;
}

const WikiReport = ({ watch, setValue }: WikiReportProps) => {
  const templateReport = watch('report');
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [repeatedFile, setRepeatedFile] = useState<FileWithId>();
  const { templateId } = useParams();

  const handleFetchTemplate = async () => {
    try {
      if (templateId) {
        await getWikiTemplateDetailsRequest(templateId).then((response) => {
          setValue('interviewQuestions', response.interview_template);
          setValue('emailTemplate', response.email_template);
          setValue('oldEmailTemplate', response.email_template);
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDocsUploadedStatus = (file: FileWithId) => {
    return {
      id: file.id,
      name: file.name,
      size: file.size,
      type: file.type,
    };
  };

  const handleDocUpload = () => {
    if (templateReport && Object.keys(templateReport).length > 0) {
      const updatedFiles = updateDocsUploadedStatus(templateReport);
      setValue('report', updatedFiles);
    }
  };

  useEffect(() => {
    handleDocUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFiles = (file: File) => {
    let existingFile: FileWithId | undefined;

    // Check if any of the selected files already exist in templateReport
    if (templateReport && templateReport.length > 0) {
      existingFile = templateReport.find(
        (md: FileWithId) => md.name === file.name
      );
    }

    if (existingFile) {
      setRepeatedFile(existingFile);
      setOpenAlertOpenDialog(true);
    } else {
      // Generate a unique id for the file
      const fileId = uuidv4();
      // Add the id property to the file object
      const fileWithId: FileWithId = Object.assign(file, {
        id: fileId,
        uploaded: false,
      });

      setValue('report', fileWithId);
    }
  };

  const handleAddFile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.target.files[0] as File;
    if (!file) return;

    handleUploadFiles(file);
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const file = e.dataTransfer.files[0] as File;
      handleUploadFiles(file);
    }
  };

  const handleRemoveFile = () => {
    // Update the 'files' field in the form payload
    setValue('report', undefined);
  };

  return (
    <Box sx={wikiTemplateWrapperStyles}>
      {/* FORM  */}
      <Box sx={wikiTemplateFormWrapperStyles}>
        <Box sx={wikiTemplateInputWrapperStyles}>
          <LEIAAUploadFileArea
            title="Select a report to import."
            subTitle="The imported file will be then possible to download for consultation."
            handleDrag={handleDrag}
            handleAddFile={handleAddFile}
            uploadAreaStyles={{
              ...matterQuestionnaireUploadAreaWrapperStyles,
              paddingLeft: '0px',
            }}
            multiple={false}
          />
        </Box>
      </Box>
      <SidePanelInformation>
        {templateReport && Object.keys(templateReport).length > 0 && (
          <MatterAddedDocumentsInformation
            matterDocuments={templateReport}
            handleRemoveFile={handleRemoveFile}
          />
        )}
      </SidePanelInformation>

      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description="We have detected similar existing files. Please change the final name if you want to add this file."
        simpleButtonText="Ok"
        onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
        isTextAlign
      />
    </Box>
  );
};

export default WikiReport;
