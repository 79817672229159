import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../../../../styles/textStyles';

export const matterUsersAccordionWrapperStyles = (isExpanded: boolean) => {
  return {
    boxShadow: 'unset',
    border: 'none',
    outline: 'none',
    width: '100%',
    zIndex: 2,
    '&::before': {
      content: 'none',
    },
    '&.Mui-expanded': {
      margin: '0px !important',
    },
    '& > div:nth-of-type(1)': {
      display: 'flex',
      gap: '8px',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
      minHeight: '34px !important',
      borderRadius: '12px',
      backgroundColor: '#D3ECF6',
      position: 'relative',
      '&::before': {
        display: isExpanded ? 'block' : 'none',
        content: "''",
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '37px',
        width: '100%',
        backgroundColor: '#F5FAFD',
        zIndex: -1,
      },
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > div': {
          margin: '0px !important',
          '& > div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& > div:nth-of-type(1) > p': {
              color: '#464B53',
              ...karlaProRegularFontStyles,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '22px',
            },
            '& > div:nth-of-type(2) > p': {
              color: '#69707B',
              ...karlaProRegularItalicFontStyles,
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: 'normal',
            },
          },
        },
        minHeight: '34px !important',
        padding: '0px',
        '.Mui-expanded': {
          minHeight: 'unset',
          margin: '0px !important',
        },
      },
    },
  };
};

export const matterUsersAccordionDetailsStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
  padding: '0px',
  '& > div': {
    display: 'flex',
    paddingBottom: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '0px 0px 14px 14px',
    backgroundColor: '#F5FAFD',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      height: '40px',
      padding: '0px 16px 0px 24px',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
      borderBottom: '1px solid #D3ECF6',
      '& > p': {
        color: '#464B53',
        ...karlaProRegularFontStyles,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      },
    },
    '& > div:not(:first-of-type)': {
      display: 'flex',
      padding: '0px 16px 0px 40px',
      justifyContent: 'space-between',
      alignSelf: 'stretch',
      alignItems: 'center',
      '& > div:nth-of-type(1) > p': {
        color: '#464B53',
        ...karlaProRegularFontStyles,
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '22px',
      },
      '& > div:nth-of-type(2)': {
        width: '285px',
      },
    },
  },
};
