import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { matterDetailsRequest } from '../../../api/matters';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import { interviewsInfoWrapperStyles } from './styles';
import { InterviewsItem } from './types';

interface ScheduledInterviewItemProps {
  matterTitle: string;
  info: any;
  interviews: InterviewsItem[];
}

const ScheduledInterviewItem = ({
  matterTitle,
  info,
  interviews,
}: ScheduledInterviewItemProps) => {
  const navigate = useNavigate();

  const handleDetailInterview = (_interview: any) => {
    if (_interview.matterId) {
      matterDetailsRequest(_interview.matterId.toString()).then((response) => {
        navigate(`/interviews/${_interview?.interview_id}`, {
          state: { interview: _interview, matter: response },
        });
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px',
        gap: '8px',
        width: '100%',
        backgroundColor: '#F0F8FD',
        borderRadius: '14px',
        border: '1px solid #0053ff',
      }}
      onClick={handleDetailInterview}
    >
      <Box
        sx={{
          borderBottom: 'solid 1px #def6ff',
          width: '100%',
          paddingBottom: '5px',
        }}
      >
        <Typography
          sx={{
            ...karlaProRegularFontStyles,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#202020',
          }}
        >
          {matterTitle}
        </Typography>
        <Box>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontWeight: 300,
              fontSize: '10px',
              lineHeight: '12px',
              letterSpacing: '0.2px',
              textTransform: 'uppercase',
              color: '#464B53',
            }}
          >
            {info.status} - Severity {info.severity}
          </Typography>
        </Box>
      </Box>
      {interviews?.map((i: InterviewsItem) => {
        return (
          <Box
            onClick={() => handleDetailInterview(i)}
            key={i.interview_id}
            sx={interviewsInfoWrapperStyles}
          >
            <Box>
              <Typography>
                {i.start_hour}-{i.end_hour}
              </Typography>
            </Box>
            <Box>
              <Typography>
                {i.interviewee} ({i.type_of_interview})
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ScheduledInterviewItem;
