import {
  Box,
  Button,
  Divider,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  matterCreationHeaderWrapperStyles,
  matterCreationHeaderStepperStyles,
} from '../matters/matter-creation/styles';
import { simpleGoBackButtonStyles } from '../styles';

interface WikiCreationHeaderProps {
  activeStep: number;
  steps: string[];
  visited?: boolean[];
  currentStep: number;
}

const WikiCreationHeader = ({
  activeStep,
  steps,
  visited,
  currentStep,
}: WikiCreationHeaderProps) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={matterCreationHeaderWrapperStyles}>
      <Box>
        <Box>
          <Typography>
            {currentStep === undefined ? 'New Template' : 'Edit Template'}
          </Typography>
        </Box>
        <Button
          sx={simpleGoBackButtonStyles}
          variant="text"
          onClick={handleGoBack}
        >
          <span className="material-symbols-outlined">arrow_back</span>
          <Typography variant="caption" component="p">
            Back
          </Typography>
        </Button>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={matterCreationHeaderStepperStyles}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                color="inherit"
                className={visited && visited[index] ? 'visited-step' : ''}
              >
                {label}
              </StepButton>
              {activeStep === index ? (
                <Divider sx={{ width: '100%', border: '1px solid #0053FF' }} />
              ) : (
                <Divider sx={{ width: '100%', border: '1px solid #69707B' }} />
              )}
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default WikiCreationHeader;
