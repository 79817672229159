import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthProps } from '../../types/authProps';
import Form from './form/Form';
import FormMessage from './form/FormMessage';
import {
  formContentWrapperStyles,
  formHeaderWrapperStyles,
  formSubHeaderWrapperStyles,
} from './styles';

const ResetPasswordForm = ({
  handleSubmit,
  onSubmit,
  control,
  passwordReset,
  watch,
  errors,
  loading,
  authFailed,
  reportingFollowUp = false,
}: AuthProps) => {
  const navigate = useNavigate();
  const fields = [
    {
      name: 'new_password',
      label: 'New Password',
      inputPlaceholder: 'Enter new password',
      type: 'password',
      required: true,
    },
  ];

  const handleGoBackToLoginPage = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleGoBackToFollowUpPage = () => {
    localStorage.clear();
    navigate('/reporting-follow-up');
  };

  const handleGoBackToForgotPasswordPage = () => {
    localStorage.clear();
    navigate('/reset-password');
  };

  return (
    <>
      <Box sx={formHeaderWrapperStyles}>
        <Typography variant="h3" data-testid="reset-password-form-title">
          {
            // eslint-disable-next-line no-nested-ternary
            authFailed
              ? 'Authentication failed'
              : !passwordReset
              ? 'Reset password'
              : 'Password Reset'
          }
        </Typography>
      </Box>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            '& > span': {
              color: '#0053FF',
            },
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            ...formContentWrapperStyles,
            width: '352px',
            height: '380px',
          }}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            authFailed ? (
              <FormMessage
                type="error"
                titleMessage="Reset password failed"
                message="Something went wrong when making the request to reset your password. Please try again."
                buttonText="Try again"
                onClickAction={handleGoBackToForgotPasswordPage}
              />
            ) : !passwordReset ? (
              <>
                <Box sx={formSubHeaderWrapperStyles('42px')}>
                  <Typography variant="caption" component="p">
                    Please enter your new password.
                  </Typography>
                </Box>
                <Form
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  control={control}
                  fields={fields}
                  submitButtonText="Reset password"
                  isResetPasswordStep
                  watch={watch}
                  errors={errors}
                />
              </>
            ) : (
              <FormMessage
                type="success"
                message="Your new password has been successfully reset."
                buttonText="Return to Login"
                onClickAction={
                  reportingFollowUp
                    ? handleGoBackToFollowUpPage
                    : handleGoBackToLoginPage
                }
              />
            )
          }
        </Box>
      )}
    </>
  );
};

export default ResetPasswordForm;
