import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  secondaryButtonStyles,
  primaryButtonStyles,
  userCreationConfirmationWrapperStyles,
} from '../styles';
import CreationNotification from '../shared/creation-notification/CreationNotification';

interface UserCreationConfirmationProps {
  isEdition: boolean;
}

const UserCreationConfirmation = ({
  isEdition,
}: UserCreationConfirmationProps) => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShowNotification(true);
    }, 125);

    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleGoToUsersList = () => {
    navigate('/users');
  };

  const handleGoToUserCreation = () => {
    navigate(-1);
  };

  return (
    <Box sx={userCreationConfirmationWrapperStyles}>
      <Box>
        <Box>
          <Box>
            <svg
              width="180"
              height="38"
              viewBox="0 0 180 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 797">
                <path
                  id="Vector"
                  d="M34.186 37.0762H3.28975C1.5835 37.0762 0.199951 35.6926 0.199951 33.9864V3.0898C0.199951 1.38355 1.5835 0 3.28975 0H34.1863C35.8926 0 37.2761 1.38355 37.2761 3.0898V33.9864C37.2758 35.6926 35.8922 37.0762 34.186 37.0762Z"
                  fill="#D3ECF7"
                />
                <path
                  id="Vector_2"
                  d="M145.414 6.1796H52.7242C51.0165 6.1796 49.6344 4.7978 49.6344 3.0898C49.6344 1.3818 51.0162 0 52.7242 0H145.414C147.122 0 148.504 1.3818 148.504 3.0898C148.504 4.7978 147.122 6.1796 145.414 6.1796Z"
                  fill="#D3ECF7"
                />
                <path
                  id="Vector_3"
                  d="M108.338 37.0761H52.7242C51.0165 37.0761 49.6344 35.6943 49.6344 33.9863C49.6344 32.2786 51.0162 30.8965 52.7242 30.8965H108.338C110.046 30.8965 111.428 32.2783 111.428 33.9863C111.428 35.6943 110.046 37.0761 108.338 37.0761Z"
                  fill="#D3ECF7"
                />
                <path
                  id="Vector_4"
                  d="M176.31 21.6288H52.7242C51.0165 21.6288 49.6344 20.247 49.6344 18.539C49.6344 16.8314 51.0162 15.4492 52.7242 15.4492H176.31C178.018 15.4492 179.4 16.831 179.4 18.539C179.4 20.247 178.018 21.6288 176.31 21.6288Z"
                  fill="#D3ECF7"
                />
                <path
                  id="Vector_5"
                  d="M18.7381 27.8088C17.9475 27.8088 17.1568 27.5071 16.5538 26.9037L7.28474 17.6346C6.07794 16.4278 6.07794 14.4724 7.28474 13.2656C8.49154 12.0588 10.447 12.0588 11.6538 13.2656L18.7381 20.3506L35.0915 3.9969C36.2983 2.7901 38.2538 2.7901 39.4606 3.9969C40.6674 5.2037 40.6674 7.15915 39.4606 8.36595L20.9225 26.904C20.3191 27.5071 19.5284 27.8088 18.7381 27.8088Z"
                  fill="#6A9FFB"
                />
              </g>
            </svg>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={secondaryButtonStyles()}
              onClick={handleGoToUsersList}
            >
              <span className="material-icons-round">people</span>
              <Typography>View User List</Typography>
            </Button>
            <Button
              variant="contained"
              sx={primaryButtonStyles()}
              onClick={handleGoToUserCreation}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add New User</Typography>
            </Button>
          </Box>
        </Box>
        <CreationNotification
          title={
            !isEdition
              ? 'New user created successfully!'
              : 'User updated successfully!'
          }
          subTitle={
            !isEdition
              ? 'You can access your new user in the User List.'
              : 'You can access your user in the User List.'
          }
          showNotification={showNotification}
          handleCloseNotification={handleCloseNotification}
        />
      </Box>
    </Box>
  );
};

export default UserCreationConfirmation;
