import { Box } from '@mui/material';
import React, { useEffect } from 'react'; // Import React and useEffect
import {
  matterCreationSidepanelInfoWrapperStyles,
  matterCreationSidepanelInfoWrapperStylesTest,
} from '../styles';

interface SidePanelInformationProps {
  children: any;
}

const SidePanelInformation = ({ children }: SidePanelInformationProps) => {
  useEffect(() => {
    const fixedElement = document.getElementById('content-box-side-panel-info');

    const handleScroll = () => {
      const page = document.getElementById('creation-box');
      if (page && fixedElement) {
        const scrollY = page.scrollTop;

        if (scrollY) {
          if (scrollY > 159) {
            fixedElement.style.position = 'fixed';
            fixedElement.style.top = '0';
            fixedElement.style.paddingTop = '15px';
            fixedElement.style.maxHeight = '85%';
          } else {
            fixedElement.style.position = 'unset';
            fixedElement.style.top = 'unset';
            fixedElement.style.paddingTop = 'unset';
            fixedElement.style.maxHeight = '350px';
          }
        }
      }
    };
    const page = document.getElementById('creation-box');
    if (page) {
      page.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Cleanup: remove the scroll event listener when the component unmounts
      if (page) {
        page.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Box sx={matterCreationSidepanelInfoWrapperStyles}>
      <Box
        className="scrollable-content"
        sx={matterCreationSidepanelInfoWrapperStylesTest}
        id="content-box-side-panel-info"
      >
        {children}
      </Box>
    </Box>
  );
};

export default SidePanelInformation;
