import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { BaseButtonStyles } from './styles';
import { createMatterDraftRequest } from '../../api/matters';
import NotificationToast from '../shared/toast/NotificationToast';

interface BaseButtonProps {
  iconText?: string;
  iconClass?: string;
  buttonText: string;
  linkTo?: string;
  width?: string;
}

const BaseButton = ({
  iconText,
  iconClass,
  buttonText,
  linkTo,
  width,
}: BaseButtonProps) => {
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleLinkTo = async () => {
    // quick hack
    if (linkTo) {
      if (linkTo === '/matters/new') {
        try {
          const response = await createMatterDraftRequest();
          navigate(`${linkTo}/${response.matter_id}`);
        } catch (error: any) {
          setRequestError(error.response.data.message);
          setShowNotification(true);
        }
      } else {
        navigate(linkTo);
      }
    }
  };

  return (
    <>
      <Button sx={BaseButtonStyles(width)} onClick={handleLinkTo}>
        {iconClass && <span className={iconClass}> {iconText}</span>}
        {buttonText}
      </Button>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default BaseButton;
