import { karlaProRegularFontStyles } from '../../../../styles/textStyles';

export const controlBarGenericButtonStyles = {
  ...karlaProRegularFontStyles,
  fontWeight: 500,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 20px',
  height: '48px',
  borderRadius: '16px !important',
};

export const screenShareButtonStyles = {
  '&.lk-button[aria-pressed=false][data-lk-source="screen_share"]': {
    '&:disabled': {
      opacity: 1,
    },
  },
  '&.lk-button[aria-pressed=true][data-lk-source="screen_share"]': {
    border: '2px solid #0053FF',
    backgroundColor: '#464B53 !important',
  },
};

export const chatButtonStyles = {
  '&.lk-chat-toggle[aria-pressed=true]': {
    border: '2px solid #0053FF',
    backgroundColor: '#464B53',
  },
};

export const disconnectButtonStyles = {
  '&.lk-disconnect-button': {
    backgroundColor: '#E22727',
    border: '2px solid #D32B2B',
    '&:hover': {
      border: '2px solid #0053FF',
      backgroundColor: '#D32B2B !important',
    },
    '&:active': {
      border: '2px solid #B62626 !important',
      backgroundColor: '#B62626 !important',
    },
  },
};

export const joinButtonStyles = {
  '&.lk-join-button': {
    backgroundColor: '#0053FF !important',
    color: 'white !important',
    width: '225px !important',
    height: '48px !important',
    padding: '8px 20px !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    gap: '8px !important',
    border: '2px solid #0053FF !important',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0B25B0 !important',
      color: 'white !important',
      border: '2px solid #0B25B0 !important',
    },
    '&:active': {
      border: '2px solid #140070 !important',
      backgroundColor: '#140070 !important',
    },
    '& > span': {
      display: 'flex',
      width: '24px',
      height: '24px',
      padding: '2px',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
    },
  },
};

export const micAndCameraButtonStyles = (inPreRoom: boolean) => {
  return {
    ...(inPreRoom
      ? {
          border: '2px solid #E22727',
          backgroundColor: '#FFFFFF',
          color: '#E22727',
          '&.lk-button[aria-pressed=true]': {
            backgroundColor: '#FFFFFF',
            border: '2px solid #464B53',
            color: '#464B53',
            '&:hover': {
              border: '2px solid #464B53',
              backgroundColor: '#464B53 !important',
              color: '#FFFFFF',
            },
          },
          '&:hover': {
            border: '2px solid #E22727',
            backgroundColor: '#E22727 !important',
            color: '#FFFFFF',
          },
          '&:active': {
            border: '2px solid #32353C !important',
            backgroundColor: '#464B53 !important',
            color: '#FFFFFF',
          },
        }
      : {
          border: '2px solid #E22727',
          backgroundColor: '#E22727',
          color: '#FFF',
          '&.lk-button[aria-pressed=true]': {
            backgroundColor: '#464B53',
            border: '2px solid #464B53',
            color: '#FFF',
            '&:hover': {
              border: '2px solid #0053FF',
              backgroundColor: '#464B53 !important',
              color: '#FFFFFF',
            },
          },
          '&.lk-button[aria-pressed=false]': {
            '&:hover': {
              border: '2px solid #0053FF',
            },
          },
          '&:hover': {
            border: '2px solid #E22727',
            backgroundColor: '#E22727 !important',
            color: '#FFFFFF',
          },
          '&:active': {
            border: '2px solid #32353C !important',
            backgroundColor: '#464B53 !important',
            color: '#FFFFFF',
          },
        }),
  };
};

export const controlBarButtonStyles = {
  ...controlBarGenericButtonStyles,
  backgroundColor: '#464B53',
  border: '2px solid #464B53',
  '&:hover': {
    border: '2px solid #0053FF',
    backgroundColor: '#464B53 !important',
  },
  '&:active': {
    border: '2px solid #32353C !important',
    backgroundColor: '#32353C !important',
  },
  ...screenShareButtonStyles,
  ...chatButtonStyles,
  ...disconnectButtonStyles,
};

export const controlBarButtonGroupStyles = (inPreRoom: boolean) => {
  return {
    '& > div > div': {
      '& > button': {
        ...controlBarGenericButtonStyles,
        ...joinButtonStyles,
        ...micAndCameraButtonStyles(inPreRoom),
      },
    },
  };
};

export const controlBarStyles = {
  width: '100%',
  borderTop: 'none',
  padding: '0px',
  maxHeight: 'unset',
};
