import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  IconButton,
  Rating,
  Radio,
  TextField,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import { feedbackRequest } from '../../api/homeportal';
import { noteInputStyles } from '../interview/panel/styles/styles';
import { primaryButtonStyles, secondaryButtonStyles } from '../styles';

export interface FeedbackProps {
  setShowFeedbackNotification: any;
}

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#2b95ff',
  },
  '& .MuiRating-iconHover': {
    color: '#007ffe',
  },
});

const FeedbackForm = ({ setShowFeedbackNotification }: FeedbackProps) => {
  const [open, setOpen] = useState(false);
  const [relevance, setRelevance] = useState<number | null>(-1);
  const [speed, setSpeed] = useState<number | null>(-1);
  const [process, setProcess] = useState<number | null>(-1);
  const [recommended, setRecommended] = useState<boolean | null>(null);
  const [suggestions, setSuggestions] = useState<string | null>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecommended(event.target.value === 'a');
  };

  const handleInputChange = (event: any) => {
    setSuggestions(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRelevance(-1);
    setSpeed(-1);
    setProcess(-1);
    setRecommended(null);
    setSuggestions('');
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      await feedbackRequest(
        relevance,
        speed,
        process,
        recommended,
        suggestions
      );
      setShowFeedbackNotification(true);
      handleClose();
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%) rotate(180deg)',
          background: '#f1fafe',
          padding: '15px 6px 15px 4px',
          writingMode: 'vertical-rl',
          textOrientation: 'mixed',
          borderBottomRightRadius: '15px',
          borderTopRightRadius: '15px',
          ...karlaProRegularFontStyles,
          fontWeight: 500,
          borderRight: '1px solid #00abf8',
          borderBottom: '1px solid #00abf8',
          borderTop: '1px solid #00abf8',
          '&:hover': {
            background: '#c3e9fa',
            borderRight: '1px solid #0087c4',
            borderBottom: '1px solid #0087c4',
            borderTop: '1px solid #0087c4',
          },
        }}
        className="noselect"
        onClick={handleOpen}
      >
        Feedback
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '24px',
            border: '1px solid #D3ECF6',
            background: '#FFF',
            boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ marginBottom: '10px' }}
          >
            <Typography variant="h4">Feedback</Typography>
            <Box>
              <IconButton
                onClick={handleClose}
                sx={{ '& > span': { color: '#464B53' } }}
              >
                <span className="material-icons-round">close</span>
              </IconButton>
            </Box>
          </Stack>

          <Box>
            <InputLabel
              data-testid="matter-form-label-id"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                color: '#464B53',
                fontSize: '16px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              How much relevant data flows through the platform?
            </InputLabel>
            <StyledRating
              name="customized-color"
              defaultValue={-1}
              getLabelText={(valueI: number) =>
                `${valueI} Heart${valueI !== 1 ? 's' : ''}`
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarOutlineIcon fontSize="inherit" />}
              onChange={(event, newValue) => {
                setRelevance(newValue);
              }}
            />
          </Box>
          <Box>
            <InputLabel
              data-testid="matter-form-label-id"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                color: '#464B53',
                fontSize: '16px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              How much faster are the generated reports compared to the original
              process?
            </InputLabel>
            <StyledRating
              name="customized-color"
              defaultValue={-1}
              getLabelText={(valueI: number) =>
                `${valueI} Heart${valueI !== 1 ? 's' : ''}`
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarOutlineIcon fontSize="inherit" />}
              onChange={(event, newValue) => {
                setSpeed(newValue);
              }}
            />
          </Box>
          <Box>
            <InputLabel
              data-testid="matter-form-label-id"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                color: '#464B53',
                fontSize: '16px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              How was the interview process?
            </InputLabel>
            <StyledRating
              name="customized-color"
              defaultValue={-1}
              getLabelText={(valueI: number) =>
                `${valueI} Heart${valueI !== 1 ? 's' : ''}`
              }
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarOutlineIcon fontSize="inherit" />}
              onChange={(event, newValue) => {
                setProcess(newValue);
              }}
            />
          </Box>
          <Box>
            <InputLabel
              data-testid="matter-form-label-id"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                color: '#464B53',
                fontSize: '16px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              Would you recommend Leiaa?
            </InputLabel>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Radio
                checked={recommended === true}
                onChange={handleChange}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  ...karlaProRegularFontStyles,
                  marginRight: '35px !important',
                }}
              >
                Yes
              </Typography>
              <Radio
                checked={recommended === false}
                onChange={handleChange}
                value="b"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'B' }}
              />
              <Typography
                sx={{ fontSize: '16px', ...karlaProRegularFontStyles }}
              >
                No
              </Typography>
            </Stack>
          </Box>
          <Box>
            <InputLabel
              data-testid="matter-form-label-id"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                color: '#464B53',
                fontSize: '16px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              Do you have any suggestions to improve our product and service?
            </InputLabel>
            <TextField
              placeholder="Write your suggestions here"
              inputProps={{
                'data-testid': 'form-text-field-id',
                inputMode: 'text',
                className: 'scrollable-content',
              }}
              type="text"
              size="small"
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                ...noteInputStyles('100%', '130px'),
              }}
              multiline
              value={suggestions}
              onChange={handleInputChange}
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            sx={{ marginTop: '20px' }}
          >
            <Button
              variant="contained"
              sx={{
                ...secondaryButtonStyles(),
                width: '100%',
              }}
              onClick={handleClose}
            >
              <Typography>Close</Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                ...primaryButtonStyles(),
                width: '100%',
              }}
              onClick={handleSubmit}
            >
              <Typography>Send</Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default FeedbackForm;
