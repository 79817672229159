import { Box, List, ListItem } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { loginInstructionsListWrapperStyles } from '../styles';

interface PasswordInputInstructionsProps {
  fieldsData: FieldValues;
}

const PasswordInputInstructions = ({
  fieldsData,
}: PasswordInputInstructionsProps) => {
  const isNumberIncluded = /\d/.test(
    fieldsData.password || fieldsData.new_password
  );
  const isUppercaseIncluded = /[A-Z]/.test(
    fieldsData.password || fieldsData.new_password
  );
  const isSpecialCharacterIncluded = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
    fieldsData.password || fieldsData.new_password
  );

  return (
    <Box sx={loginInstructionsListWrapperStyles}>
      <List>
        <ListItem
          sx={{
            display: 'list-item',
            color: isNumberIncluded ? '#A962D5' : '#69707B',
            wordWrap: 'break-word',
          }}
        >
          Password must contain numbers;
        </ListItem>
        <ListItem
          sx={{
            display: 'list-item',
            color: isUppercaseIncluded ? '#A962D5' : '#69707B',
            wordWrap: 'break-word',
          }}
        >
          Password must have at least one uppercase letter;
        </ListItem>
        <ListItem
          sx={{
            display: 'list-item',
            color: isSpecialCharacterIncluded ? '#A962D5' : '#69707B',
            wordWrap: 'break-word',
          }}
        >
          Password must have at least one special character.
        </ListItem>
      </List>
    </Box>
  );
};

export default PasswordInputInstructions;
