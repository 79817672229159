import { IconButton, InputAdornment } from '@mui/material';

interface ShowPasswordProps {
  showPassword: boolean;
  handleClickShowPassword: () => void;
}

const ShowPassword = ({
  showPassword,
  handleClickShowPassword,
}: ShowPasswordProps) => {
  return (
    <InputAdornment position="end">
      <IconButton
        data-testid="show-password-button-id"
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        edge="end"
        sx={{ marginRight: 0, '& > span': { color: '#32353C' } }}
      >
        {!showPassword ? (
          <span className="material-icons-round">visibility_off</span>
        ) : (
          <span className="material-icons-round">visibility</span>
        )}
      </IconButton>
    </InputAdornment>
  );
};

export default ShowPassword;
