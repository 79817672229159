/* eslint-disable @typescript-eslint/no-shadow */
import { Box, Stack, Typography } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  getAvailableFlairsRequest,
  moveTaskOnBoardRequest,
} from '../../../api/tasks';
import {
  boardColumnHeaderStyles,
  boardColumnHeaderWrapperBoxStyles,
  boardWrapperBoxStyles,
  Column,
} from './taskstyles';
import TaskCard from './TaskCard';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';

interface TaskBoardProps {
  columns: Column;
  setShowErrorNotification: any;
  setRequestError: any;
  setColumns: any;
  matterDetail?: boolean;
}

const TaskBoard = ({
  columns,
  setShowErrorNotification,
  setRequestError,
  setColumns,
  matterDetail = false,
}: TaskBoardProps) => {
  const [availableTags, setAvailableTags] = useState([]);

  const handleGetAvailableTags = async () => {
    try {
      const response = await getAvailableFlairsRequest('0');
      setAvailableTags(response);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const handleMoveTaskSave = async (id: any, status: any) => {
    try {
      await moveTaskOnBoardRequest(id, status);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setShowErrorNotification(true);
        setRequestError(error.response.data.message);
      } else {
        setShowErrorNotification(true);
        setRequestError('Task management move error');
      }
    }
  };

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      handleMoveTaskSave(
        result.draggableId,
        columns[result.destination.droppableId].title
      );
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  useEffect(() => {
    handleGetAvailableTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <Box sx={{ display: 'flex' }}>
        <Box sx={boardWrapperBoxStyles}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <Box
                    sx={boardColumnHeaderWrapperBoxStyles}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Box sx={boardColumnHeaderStyles}>{column.title}</Box>
                    {column.items.length > 0 ? (
                      column.items.map((item: any, index: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TaskCard
                          item={item}
                          index={index}
                          availableTags={availableTags}
                          setAvailableTags={setAvailableTags}
                          matterDetail={matterDetail}
                        />
                      ))
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          ...karlaProRegularItalicFontStyles,
                          color: '#69707B',
                          fontSize: '14px',
                          textAlign: 'center',
                          marginTop: '40px',
                        }}
                      >
                        No tasks.
                      </Typography>
                    )}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            );
          })}
        </Box>
      </Box>
    </DragDropContext>
  );
};

export default TaskBoard;
