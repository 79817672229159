export const ResourcePageHeaderWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 0px',
};

export const homePortalSearchInputStyles = {
  '&.MuiFormControl-root': {
    padding: '0px 12px',
    width: '224px',
    height: '40px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #69707B',
    borderRadius: '14px',
    transition: 'border-color 0.3s',
    '&:focus-within': {
      borderColor: '#0053FF',
    },

    '& input': {
      caretColor: '#0053FF',
    },
  },
  '& > div': {
    height: 'inherit',
    gap: '8px',
    '&:hover': {
      cursor: 'default',
    },
    '& > input': {
      '&:hover': {
        cursor: 'default',
      },
      padding: '0px',
      height: '22px',
      fontSize: '14px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      '::placeholder': {
        color: '#69707B',
      },
      color: '#202020',
    },
  },
};
