import { karlaProBoldFontStyles } from '../../../styles/textStyles';

// eslint-disable-next-line import/prefer-default-export
export const notificationToastWrapperStyles = (
  showNotification: boolean,
  border: string,
  backgroundColor: string
) => {
  return {
    display: 'flex',
    opacity: showNotification ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out',
    padding: '0px 24px 0px 32px',
    alignItems: 'center',
    position: 'absolute',
    left: '50%' /* Center the element horizontally by pushing it right 50% from the left */,
    transform: 'translate(-50%, -50%)',
    top: '48px',
    borderRadius: '24px',
    border,
    backgroundColor,
    boxShadow: '5px 0px 20px 0px rgba(21, 10, 84, 0.10)',
    zIndex: 9999999,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '32px',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        '& > div': {
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          '& > p': {
            color: '#202020',
            ...karlaProBoldFontStyles,
            fontSize: '17px',
            fontWeight: 700,
            lineHeight: '30px' /* 176.471% */,
          },
        },
      },
    },
  };
};
