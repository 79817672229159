import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { clientDashboardRequest, mattersListRequest } from '../api/homeportal';
import './styles/homeportal.css';
import '../components/styles/datagridStyles.css';
import AllocatedUsers from '../components/homeportal/allocated-users/AllocatedUsers';
import HomePortalButtons from '../components/homeportal/HomePortalButtons';
import MattersListTable from '../components/shared/matters-list-table/MattersListTable';
import { usePermissions } from '../hooks/usePermissions';
import ResourcePageHeader from '../components/shared/resource-page-header/ResourcePageHeader';
import { insightsContentWrapperStyles } from '../components/homeportal/insights-content/styles';
import { simpleGoBackButtonStyles } from '../components/styles';
import { homePortalSearchInputStyles } from '../components/shared/resource-page-header/styles';
import { MattersListResponse } from '../types/homeportal';
import ScheduledResources from './my-dashboard/calendar/ScheduledResources';

interface ClientDetailDashboardData {
  average_length: number | string;
  matters_count: number | string;
  scheduled_interviews: any[];
  allocated_users: any[];
  mattersList: any[];
  name: string;
}

const ClientDetailDashboard = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [loading, setLoading] = useState(true);
  const currentDate = new Date();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [clientDetailDashboard, setClientDetailDashboard] =
    useState<ClientDetailDashboardData | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [mattersListData, setMattersListData] =
    useState<MattersListResponse | null>(null);

  const handleGetDashboardDetails = async () => {
    setLoading(true); // Start loading
    try {
      if (clientId) {
        const response = await clientDashboardRequest(
          clientId,
          currentDate.toLocaleDateString('pt-PT', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          })
        );

        setClientDetailDashboard(response);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFetchMattersList = async (searchText = '') => {
    try {
      const response = await mattersListRequest(
        false,
        searchText,
        clientId as any
      );
      setMattersListData(response as MattersListResponse);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchMattersList(searchText);
  };

  useEffect(() => {
    handleGetDashboardDetails();
    handleFetchMattersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetailMatter = (matter: any) => {
    navigate(`/matters/detail/${matter?.id}`, {
      state: { matter },
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '32px 32px 10px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
      }}
    >
      {/* Header */}
      <ResourcePageHeader
        inputRef={searchInputRef}
        headerText={`${clientDetailDashboard?.name || 'Client'} Overview`}
      />
      <Box>
        <Button
          sx={simpleGoBackButtonStyles}
          variant="text"
          onClick={handleGoBack}
        >
          <span className="material-symbols-outlined">arrow_back</span>
          <Typography variant="caption" component="p">
            Back
          </Typography>
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ height: '100%', marginTop: '5px' }}>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!loading &&
          clientDetailDashboard !== null &&
          clientDetailDashboard !== undefined && (
            <>
              <Grid item xs={8} md={8}>
                <Box
                  sx={{
                    padding: '0px',
                    gap: '32px',
                    width: '100%',
                    height: '571px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={insightsContentWrapperStyles}>
                    <Box>
                      <Typography>Average matter length</Typography>
                      <Typography variant="h1">
                        {clientDetailDashboard?.average_length !== '-' ? (
                          <>
                            {clientDetailDashboard?.average_length}{' '}
                            <span style={{ textTransform: 'none' }}>Days</span>
                          </>
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>Active matters</Typography>

                      <Typography variant="h1">
                        {clientDetailDashboard?.matters_count}
                      </Typography>
                    </Box>
                  </Box>

                  <ScheduledResources page="clients" targetId={clientId} />
                </Box>
              </Grid>

              <Grid item xs={4} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    padding: '0px',
                    gap: '32px',
                    width: '100%',
                  }}
                >
                  {permissions.matter.add && <HomePortalButtons />}
                  {/* Allocated Users */}

                  <AllocatedUsers
                    allocatedUsersSent={clientDetailDashboard?.allocated_users}
                  />
                </Box>
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '15px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    marginLeft: 'auto',
                  }}
                >
                  {/* Search */}
                  <TextField
                    id="input-with-icon-textfield"
                    placeholder="Search by matter..."
                    sx={{
                      ...homePortalSearchInputStyles,
                      marginLeft: 'auto',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ '& > span': { color: '#69707B' } }}
                        >
                          <span className="material-icons-round">search</span>
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      onChange: handleListSearchbarInputChange,
                      inputRef,
                    }}
                    variant="standard"
                  />
                </Box>
              </Box>
              {!loading &&
                mattersListData !== null &&
                mattersListData !== undefined && (
                  <Grid sx={{ paddingBottom: '25px' }} item xs={12} md={12}>
                    <MattersListTable
                      mattersListData={mattersListData}
                      listClass="matters-list-homeportal"
                      loading={loading}
                      clientDashboard
                      handleDetailUser={handleDetailMatter}
                    />
                  </Grid>
                )}
            </>
          )}
      </Grid>
    </Box>
  );
};

export default ClientDetailDashboard;
