import api from './index';

async function getTaskBoardRequest(
  matterId = false,
  sortCode = false,
  mine = false
): Promise<any> {
  const params = {
    ...(sortCode && { sort: sortCode }),
    ...(matterId && { matter: matterId }),
    ...(mine && { mine }),
  };
  const response = await api.get('/tasks-management', { params });
  return response.data;
}

async function moveTaskOnBoardRequest(id: any, status: any): Promise<any> {
  const response = await api.post('/tasks-management', {
    id,
    status,
  });

  return response.data;
}

async function updateTaskRequest(
  id: any,
  tag: any = null,
  status: any = null,
  date: any = null,
  assignee: any = null,
  name: any = null,
  description: any = null
): Promise<any> {
  const payload = {
    tag,
    status,
    date,
    assignee,
    name,
    description,
  };

  const nonNullPayload = Object.fromEntries(
    Object.entries(payload).filter(([_, value]) => value !== null)
  );

  const response = await api.put(`/task-management/${id}`, nonNullPayload);

  return response.data;
}

async function getAvailableFlairsRequest(
  id: string,
  resource: any = undefined
): Promise<any> {
  const filters: { resource?: string } = {};

  if (resource) {
    filters.resource = resource;
  }

  const response = await api.get(`/task-management/${id}`, {
    params: filters,
  });

  return response.data;
}

async function createGeneralTaskRequest(): Promise<any> {
  const response = await api.post('general-tasks', {});
  return response.data;
}

export {
  getTaskBoardRequest,
  moveTaskOnBoardRequest,
  updateTaskRequest,
  getAvailableFlairsRequest,
  createGeneralTaskRequest,
};
