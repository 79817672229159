import { Box, Button, MenuItem, Modal, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generateAnalysisRequest } from '../../api/analysis';
import { clientsRequest } from '../../api/matters';
import { usersRequest } from '../../api/users';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import { User } from '../../types/auth';
import { ClientOptionType } from '../../types/matters';
import { LEIAASelect } from '../inputs';
import NotificationToast from '../shared/toast/NotificationToast';

import { primaryButtonStyles, secondaryButtonStyles } from '../styles';
import LoaderSpinner from '../shared/misc-components/LoaderSpinner';

interface AnalysisCreateModalProps {
  refreshData: any;
  triggerSuccessNotification: any;
}

const AnalysisCreateModal = ({
  refreshData,
  triggerSuccessNotification,
}: AnalysisCreateModalProps) => {
  const { control, setValue, handleSubmit } = useForm();
  const reportTypes =
    process.env.REACT_APP_CLIENT_LIST_DISABLE_AND_REDIRECT === 'true'
      ? ['LEIAA User', 'Investigation Topic', 'All Matters', 'Corporate']
      : ['LEIAA User', 'Investigation Topic', 'All Matters', 'Client'];
  const [type, setType] = useState('');
  const [targetUser, setTargetUser] = useState(null);
  const [targetClient, setTargetClient] = useState(null);
  const [open, setOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [usersListData, setUsersListData] = useState<User[] | null>(null);
  const [clientsListData, setClientsListData] =
    useState<readonly ClientOptionType[]>();
  const [loading, Setloading] = useState(false);

  const handleSetType = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('analysisType', event.target.value);
    setType(event.target.value);
  };

  const handleSetTargetUser = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('targetUser', event.target.value);
    setTargetUser(event.target.value);
  };

  const handleSetTargetClient = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('targetClient', event.target.value);
    setTargetClient(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setType('');
    setTargetUser(null);
    setTargetClient(null);
    setValue('type', '');
    setValue('targetUser', null);
    setValue('targetClient', null);
  };

  const handleGenerateAnalysis = async () => {
    try {
      Setloading(true);
      await generateAnalysisRequest(type, null, null, targetUser, targetClient);
      refreshData();
      triggerSuccessNotification(true);
      handleClose();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      Setloading(false);
    }
  };

  const handleFetchUsersList = async () => {
    try {
      const response = await usersRequest('GET', undefined, '', undefined);
      setUsersListData(response as User[]);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setRequestError(error.response.data.message);
        setShowNotification(true);
      } else {
        setRequestError('An unknown error related to the users list occurred.');
        setShowNotification(true);
      }
    }
  };

  const handleFetchClients = async () => {
    try {
      const response = await clientsRequest();
      setClientsListData(response as ClientOptionType[]);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setRequestError(error.response.data.message);
        setShowNotification(true);
      } else {
        setRequestError('An unknown error related to the users list occurred.');
        setShowNotification(true);
      }
    }
  };

  useEffect(() => {
    if (type === 'LEIAA User') handleFetchUsersList();
    if (type === 'Client' || type === 'Investigation Topic')
      handleFetchClients();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{ ...primaryButtonStyles(), height: '40px' }}
      >
        <span className="material-icons-round">add</span>
        <Typography>Generate Analysis</Typography>
      </Button>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {loading && (
        <LoaderSpinner loading={loading} onClose={() => Setloading(false)} />
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-create-analysis"
          aria-describedby="modal-create-analysis-description"
        >
          <form onSubmit={handleSubmit(handleGenerateAnalysis)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '500px',
                padding: '48px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '24px',
                border: '1px solid #D3ECF6',
                background: '#FFF',
                boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
              }}
            >
              <Typography
                id="modal-modal-title"
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '22px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Generate Analysis
                <br />
                <span style={{ fontSize: '12px', color: 'gray' }}>
                  Select an analysis type below
                </span>
              </Typography>

              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '22px',
                  marginBottom: '5px',
                  marginLeft: '5px',
                }}
              >
                Analysis type
              </Typography>
              <LEIAASelect
                labelText=""
                control={control}
                controllerName="type"
                inputPlaceholder="Select analysis type"
                inputHeight="40px !important"
                inputWidth="100%"
                paperCustomStylings={{ height: 'auto' }}
                handleChange={handleSetType}
              >
                {reportTypes?.map((name, index) => {
                  return (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </LEIAASelect>
              <br />
              {type === 'LEIAA User' && usersListData && (
                <>
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '5px',
                      marginLeft: '5px',
                    }}
                  >
                    Target User
                  </Typography>
                  <LEIAASelect
                    labelText=""
                    control={control}
                    controllerName="targetUser"
                    inputPlaceholder="Select User"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '200px' }}
                    handleChange={handleSetTargetUser}
                  >
                    {usersListData?.map((user: any, index) => {
                      return (
                        <MenuItem key={index} value={user.id}>
                          {user.firstName} {user.lastName}
                        </MenuItem>
                      );
                    })}
                  </LEIAASelect>
                </>
              )}
              {(type === 'Client' || type === 'Investigation Topic') &&
                clientsListData && (
                  <>
                    <Typography
                      sx={{
                        ...karlaProRegularFontStyles,
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '22px',
                        marginBottom: '5px',
                        marginLeft: '5px',
                      }}
                    >
                      Target Client
                    </Typography>
                    <LEIAASelect
                      labelText=""
                      control={control}
                      controllerName="targetClient"
                      inputPlaceholder="Select Client"
                      inputHeight="40px !important"
                      inputWidth="100%"
                      paperCustomStylings={{ height: '200px' }}
                      handleChange={handleSetTargetClient}
                    >
                      {clientsListData?.map((client: any, index) => {
                        return (
                          <MenuItem key={index} value={client.name}>
                            {client.name}
                          </MenuItem>
                        );
                      })}
                    </LEIAASelect>
                  </>
                )}

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', marginTop: '35px !important' }}
              >
                <Button
                  variant="contained"
                  sx={{ ...secondaryButtonStyles(), width: '100%' }}
                  onClick={handleClose}
                >
                  <Typography>Cancel</Typography>
                </Button>
                <Button
                  variant="contained"
                  sx={{ ...primaryButtonStyles(), width: '100%' }}
                  type="submit"
                >
                  <Typography>Generate</Typography>
                  <span className="material-icons-round">add_chart</span>
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      )}
    </>
  );
};

export default AnalysisCreateModal;
