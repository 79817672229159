import { Box, Typography } from '@mui/material';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import {
  detailsInterviewNotesDataStyles,
  detailsRecordingsDataStyles,
} from './styles';

interface LEIAAInterviewNotesProps {
  interview?: any;
}
const LEIAAInterviewNotes = ({ interview = [] }: LEIAAInterviewNotesProps) => {
  return (
    <Box sx={detailsRecordingsDataStyles}>
      <Typography>Interview Notes</Typography>
      <Box>
        {interview.notes && interview.notes.length > 0 ? (
          <Box sx={detailsInterviewNotesDataStyles}>
            <Typography>{interview.notes}</Typography>
          </Box>
        ) : (
          <Typography
            variant="body1"
            sx={{
              ...karlaProRegularItalicFontStyles,
              color: '#69707B',
              fontSize: '14px',
            }}
          >
            No interview notes.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default LEIAAInterviewNotes;
