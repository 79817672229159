import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { EmailTemplate } from '../../../types/wiki';
import { matterAddedTasksWrapperStyles } from '../../matters/matter-creation/matter-creation-steps/matter-tasks-step/styles';

interface TemplateAddedEmailsInformationProps {
  selectedIndex: number;
  emailTemplates: any;
  handleSelectEmail: any;
  handleRemoveEmailTemplate: any;
  addEmailTemplateBtn: any;
}

const TemplateAddedEmailsInformation = ({
  selectedIndex,
  emailTemplates,
  handleSelectEmail,
  handleRemoveEmailTemplate,
  addEmailTemplateBtn,
}: TemplateAddedEmailsInformationProps) => {
  const validEmailSubject = (emailInvitationSubject: EmailTemplate) => {
    const { subject } = emailInvitationSubject;
    return subject !== '';
  };

  return (
    <Box>
      <Box>
        <Box>
          <Typography>Added Emails</Typography>
        </Box>
      </Box>
      {emailTemplates.map((mt: EmailTemplate, index: number) => {
        return (
          <Box
            key={`${mt.id}-${index}`}
            sx={matterAddedTasksWrapperStyles(selectedIndex, index)}
            onClick={() => handleSelectEmail(index)}
          >
            <Box>
              <Box>
                <Box>
                  <Box>
                    <>
                      <Typography>{index + 1}.</Typography>
                      <Typography>
                        {mt.subject === '' ? 'Current Email' : mt.subject}
                      </Typography>
                    </>
                  </Box>
                  <Box>
                    {!validEmailSubject(mt) && (
                      <Box
                        sx={{
                          display: 'flex',
                          '& > span': {
                            fontSize: '16px',
                            color: '#E22727',
                          },
                        }}
                      >
                        <span className="material-icons-round">error</span>
                      </Box>
                    )}
                    <IconButton
                      data-testid="clear-task-added-id"
                      aria-label="clear task added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                      }}
                      onClick={() => handleRemoveEmailTemplate(mt.id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
      {addEmailTemplateBtn}
    </Box>
  );
};

export default TemplateAddedEmailsInformation;
