import { sofiaProRegularFontStyles } from '../../../styles/textStyles';

export const insightsTitleStyles = {
  ...sofiaProRegularFontStyles,
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '37px',
  letterSpacing: '4px',
  color: '#0053FF',
};

export const insightsContentStyles = {
  ...sofiaProRegularFontStyles,
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '18px',
  letterSpacing: '0.3px',
  color: '#202020',
};

export const insightsContentWrapperStyles = {
  display: 'flex',
  gap: '32px',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '16px',
    width: '100%',
    height: '112px',
    backgroundColor: '#F2FAFE',
    border: '1px solid #F0F8FD',
    borderRadius: '24px',
    '& > p': {
      ...insightsContentStyles,
    },
    '& > h1': {
      ...insightsTitleStyles,
    },
  },
};
