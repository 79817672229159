// eslint-disable-next-line import/prefer-default-export
export const BaseButtonStyles = (_width = '100%') => {
  return {
    display: 'flex',
    padding: '8px 20px',
    gap: '8px',
    width: _width,
    height: '40px',
    borderRadius: '16px',
    textTransform: 'none',
    backgroundColor: '#0053FF',
    color: '#FFF',
    border: '1px solid #0053FF',
    minWidth: '151px',

    '&:hover': {
      backgroundColor: '#0B25B0',
      border: '1px solid #0B25B0',
    },
    '&:focus': {
      border: '1px solid #A962D5',
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:active': {
      backgroundColor: '#140070',
    },
  };
};
