// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from '@reduxjs/toolkit';
import matterInfoReducer from '../redux/features/matter-creation/matterInformationSlice';
import matterUsersReducer from '../redux/features/matter-creation/matterUsersSlice';
import userPermissionsReducer from '../redux/features/user-permissions/userPermissionsSlice';

export const store = configureStore({
  reducer: {
    matterInfoReducer,
    matterUsersReducer,
    userPermissionsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
