import { karlaProRegularFontStyles } from '../styles/textStyles';

export const primaryButtonStyles = (isUppercase?: boolean) => {
  return {
    display: 'flex',
    height: '48px',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '16px',
    backgroundColor: '#0053FF',
    boxShadow: 'none',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#0B25B0',
      boxShadow: 'none',
    },
    '&:focus': {
      border: '1px solid #A962D5',
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:active': {
      backgroundColor: '#140070',
    },
    ...(isUppercase
      ? {
          '& > p': {
            color: '#FFF',
            textAlign: 'center',
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            letterSpacing: '0.2px',
            textTransform: 'uppercase !important',
          },
        }
      : {
          '& > p': {
            color: '#FFF',
            textAlign: 'center',
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            letterSpacing: '0.2px',
            textTransform: 'none !important',
          },
        }),
  };
};

export const secondaryButtonStyles = (isUppercase?: boolean) => {
  return {
    display: 'flex',
    height: '48px',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '16px',
    border: '1px solid #0053FF',
    backgroundColor: '#FFF',
    boxShadow: 'none',
    color: '#0053FF',
    '&:hover': {
      backgroundColor: '#EBF8FE',
      border: '1px solid #0B25B0',
      boxShadow: 'none',
      '& > *': {
        color: '#0B25B0',
      },
    },
    '&:focus': {
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:active': {
      backgroundColor: '#EBF8FE',
      borderColor: '#140070',
      color: '#140070',
    },
    '&.Mui-disabled': {
      border: '1px solid #464B53',
      backgroundColor: '#FFF',
      '& > *': {
        color: '#464B53',
      },
    },
    '& > p': {
      textAlign: 'center',
      fontSize: '16px',
      ...karlaProRegularFontStyles,
      letterSpacing: '0.2px',
      textTransform: isUppercase ? 'uppercase' : 'none',
    },
  };
};

export const tertiaryButtonStyles = (isUppercase?: boolean) => {
  return {
    display: 'flex',
    height: '44px',
    padding: '8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '12px',
    backgroundColor: '#fff',
    boxShadow: 'unset',
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: 'unset',
      '& > *': {
        color: '#0B25B0',
      },
    },
    '&:focus': {
      boxShadow: '0px 0px 10px 0px rgba(21, 10, 84, 0.30)',
      '& > p': {
        color: '#0053FF',
      },
    },
    '&:active': {
      boxShadow: 'unset',
      '& > p': {
        color: '#140070',
      },
    },
    '& > p': {
      color: '#0053FF',
      textAlign: 'center',
      ...karlaProRegularFontStyles,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 'normal',
      letterSpacing: '0.2px',
      textTransform: isUppercase ? 'uppercase' : 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: '#FFF',
      '& > *': {
        color: '#464B53',
      },
    },
  };
};

export const secondaryPaleButtonStyles = (isUppercase?: boolean) => {
  return {
    display: 'flex',
    height: '48px',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '16px',
    border: '1px solid #D3ECF7',
    backgroundColor: '#FFF',
    boxShadow: 'none',
    color: '#0353ff',
    '&:hover': {
      backgroundColor: '#EBF8FE',
      border: '1px solid #0B25B0',
      boxShadow: 'none',
      '& > *': {
        color: '#0B25B0',
      },
    },
    '&:focus': {
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:active': {
      backgroundColor: '#EBF8FE',
      borderColor: '#140070',
      color: '#140070',
    },
    '&.Mui-disabled': {
      border: '1px solid #464B53',
      backgroundColor: '#FFF',
      '& > *': {
        color: '#464B53',
      },
    },
    '& > p': {
      textAlign: 'center',
      fontSize: '16px',
      ...karlaProRegularFontStyles,
      letterSpacing: '0.2px',
      textTransform: isUppercase ? 'uppercase' : 'none',
    },
  };
};

export const simpleGoBackButtonStyles = {
  display: 'flex',
  height: '16px',
  padding: '8px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '12px',
  color: '#69707B',
  '& > span': {
    color: '#69707B',
    fontSize: '16px',
    lineHeight: '16px',
  },
  '& > p': {
    ...karlaProRegularFontStyles,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    letterspacing: '0.2px',
    margin: '0px !important',
  },
};

export const userCreationFooterWrapperStyles = {
  display: 'flex',
  width: '100%',
  padding: '32px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '24px',
  borderTop: '1px solid  #D3ECF6',
  marginTop: '40px',
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFF',
  zIndex: 2,
  '& > div': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '16px',
  },
};

export const userCreationConfirmationWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  padding: '0 32px',
  '& > div': {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: '104px 32px 0px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '64px',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '64px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '14px',
        borderRadius: '16.8px',
        backgroundColor: '#EBFBFB',
        padding: '24px',
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '32px',
      },
    },
  },
};

export const wikiHeaderStepperStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  '& > div.MuiStep-root:first-of-type': {
    padding: '0px !important',
    margin: '0px',
    display: 'flex',
    height: '32px',
    paddingTop: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    width: '224px',
    '& > button': {
      display: 'flex',
      padding: '0px 0px 0px 16px',
      alignItems: 'center',
      flex: '1 0 0',
      alignSelf: 'stretch',
      margin: '0px',
      justifyContent: 'unset',
      '& > span': {
        gap: '8px',
        '& > span.MuiStepLabel-iconContainer:not(.Mui-active)': {
          '& > span': {
            color: '#69707B',
          },
          '& > svg > path': {
            fill: '#69707B',
          },
        },
        '& > span.MuiStepLabel-iconContainer.Mui-active': {
          '& > span': {
            color: '#0053FF',
          },
          '& > span > svg': {
            fill: '#0053FF',
          },
          '& > span > svg > path': {
            fill: '#0053FF !important',
          },
        },
        '& > span.MuiStepLabel-labelContainer': {
          '& > span': {
            color: '#69707B',
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            fontWeight: 500,
            lineHeight: '24px',
          },
          '& > span.Mui-active': {
            color: '#0053FF',
          },
        },
      },
    },
  },
  '& > div.MuiStep-root:not(:first-of-type)': {
    padding: '0px !important',
    margin: '0px',
    display: 'flex',
    height: '32px',
    paddingTop: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flex: '1 0 0',
    '& > button': {
      display: 'flex',
      padding: '0px 0px 0px 16px',
      alignItems: 'center',
      flex: '1 0 0',
      alignSelf: 'stretch',
      margin: '0px',
      justifyContent: 'unset',
      '& > span': {
        gap: '8px',
        '& > span.MuiStepLabel-iconContainer:not(.Mui-active)': {
          '& > span': {
            color: '#69707B',
          },
          '& > svg > path': {
            fill: '#69707B',
          },
        },
        '& > span.MuiStepLabel-iconContainer.Mui-active': {
          '& > span': {
            color: '#0053FF',
          },
          '& > span > svg': {
            fill: '#0053FF',
          },
          '& > span > svg > path': {
            fill: '#0053FF !important',
          },
        },
        '& > span.MuiStepLabel-labelContainer': {
          '& > span': {
            color: '#69707B',
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            fontWeight: 500,
            lineHeight: '24px',
          },
          '& > span.Mui-active': {
            color: '#0053FF',
          },
        },
      },
    },
  },
  '& > .MuiStepConnector-root': {
    display: 'none', // Add this line to hide the MuiStepConnector
  },
};

export const templateCreationConfirmationWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  padding: '0 32px',
  '& > div': {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: '104px 32px 0px 32px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '64px',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '64px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        width: '200px',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '80px',
        borderRadius: '100px',
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '32px',
      },
    },
  },
};
