import { Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';

import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import LEIAAPagination from '../lists/LEIAAPagination';
import SortableHeader from '../matters-list-table/SortableHeader';
import {
  mattersListWrapperStyles,
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
} from '../matters-list-table/styles';
import NoteTagsChips from './NoteTagsChips';

interface NotesListTableProps {
  notesListData: any[] | null;
  loading: boolean;
  setCheckedNotes?: any;
  setSelectedNote?: any;
  handleDetailNotes?: any;
  isModal?: boolean;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No Notes.
    </Typography>
  </GridOverlay>
);

const NotesListTable = ({
  notesListData,
  loading,
  setCheckedNotes,
  setSelectedNote,
  handleDetailNotes,
  isModal = false,
}: NotesListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleScroll = () => {
    const containerElement = document.querySelector('.notes-list');

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector('.notes-list');

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && notesListData !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector('.notes-list');
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, notesListData]);

  const columns: GridColDef[] = [
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 2,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as any[];
        return <NoteTagsChips tags={cellValue} />;
      },
    },
    {
      field: 'authors',
      headerName: 'Authors',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'updated',
      headerName: 'Last Updated',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
  ];

  const notesRowsAreEmpty = notesListData?.length === 0;

  return (
    <Box sx={mattersListWrapperStyles(notesRowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
          maxWidth: isModal ? '1100px' : 'unset',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            ref={containerRef}
            rows={notesListData as any[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            onRowSelectionModelChange={(ids) => {
              setCheckedNotes(ids);
              if (ids.length !== 0) {
                setSelectedNote(
                  notesListData?.find((row) => row.id === ids[0])
                );
              }
            }}
            pageSizeOptions={[6]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowClick={(params) => {
              handleDetailNotes(params.row);
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            sx={mattersListStyles}
            className="notes-list"
          />
        )}
      </Box>
    </Box>
  );
};

export default NotesListTable;
