import * as React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  setupDeviceSelector,
  createMediaDeviceObserver,
} from '@livekit/components-core';

import type { Room } from 'livekit-client';
import { useMaybeRoomContext } from '@livekit/components-react';
import { Box } from '@mui/material';
import { useObservableState } from '../../../vendors/useObservableState';
import { mergeProps } from '../../../vendors/mergeProps';

/** @public */
export function useMediaDevices({ kind }: { kind: MediaDeviceKind }) {
  const deviceObserver = React.useMemo(
    () => createMediaDeviceObserver(kind),
    [kind]
  );
  const devices = useObservableState(deviceObserver, []);
  return devices;
}

/** @public */
export interface UseMediaDeviceSelectProps {
  kind: MediaDeviceKind;
  room?: Room;
}

/** @public */
export function useMediaDeviceSelect({
  kind,
  room,
}: UseMediaDeviceSelectProps) {
  const roomContext = useMaybeRoomContext();
  // List of all devices.
  const deviceObserver = React.useMemo(
    () => createMediaDeviceObserver(kind),
    [kind]
  );
  const devices = useObservableState(deviceObserver, []);
  // Active device management.
  const [currentDeviceId, setCurrentDeviceId] = React.useState<string>('');
  const { className, activeDeviceObservable, setActiveMediaDevice } =
    React.useMemo(
      () => setupDeviceSelector(kind, room ?? roomContext),
      [kind, room, roomContext]
    );

  React.useEffect(() => {
    const listener = activeDeviceObservable.subscribe((deviceId) => {
      if (deviceId) setCurrentDeviceId(deviceId);
    });
    return () => {
      listener?.unsubscribe();
    };
  }, [activeDeviceObservable]);

  return {
    devices,
    className,
    activeDeviceId: currentDeviceId,
    setActiveMediaDevice,
  };
}

/** @public */
export interface MediaDeviceSelectProps
  extends React.HTMLAttributes<HTMLUListElement> {
  kind: MediaDeviceKind;
  onActiveDeviceChange?: (deviceId: string) => void;
  onDeviceListChange?: (devices: MediaDeviceInfo[]) => void;
  initialSelection?: string;
}

/**
 * The MediaDeviceSelect list all media devices of one kind.
 * Clicking on one of the listed devices make it the active media device.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <MediaDeviceSelect kind='audioinput' />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function LEIAAMediaDeviceSelect({
  kind,
  initialSelection,
  onActiveDeviceChange,
  onDeviceListChange,
  ...props
}: MediaDeviceSelectProps) {
  const room = useMaybeRoomContext();
  const { devices, activeDeviceId, setActiveMediaDevice, className } =
    useMediaDeviceSelect({
      kind,
      room,
    });
  React.useEffect(() => {
    if (initialSelection) {
      setActiveMediaDevice(initialSelection);
    }
  });

  React.useEffect(() => {
    if (typeof onDeviceListChange === 'function') {
      onDeviceListChange(devices);
    }
  }, [onDeviceListChange, devices]);

  const handleActiveDeviceChange = async (
    deviceId: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setActiveMediaDevice(deviceId);
    if (onActiveDeviceChange) {
      onActiveDeviceChange(deviceId);
    }
    event.stopPropagation();
  };
  // Merge Props
  const mergedProps = React.useMemo(
    () => mergeProps(props, { className }, { className: 'lk-list' }),
    [className, props]
  );

  return (
    <ul {...mergedProps}>
      {devices.map((device) => (
        <li
          key={device.deviceId}
          id={device.deviceId}
          data-lk-active={device.deviceId === activeDeviceId}
          aria-selected={device.deviceId === activeDeviceId}
          role="option"
        >
          {/* eslint-disable-next-line react/button-has-type, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <Box
            component="span"
            className="lk-button"
            onClick={(event) =>
              handleActiveDeviceChange(device.deviceId, event)
            }
          >
            {device.label}
          </Box>
        </li>
      ))}
    </ul>
  );
}
