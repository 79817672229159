import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { matterDetailsRequest } from '../../../api/matters';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface ScheduledTaskItemProps {
  matterTitle?: string;
  info?: any;
  tasks?: any[];
  singleTask?: any;
}

const ScheduledTaskItem = ({
  matterTitle,
  info,
  tasks,
  singleTask,
}: ScheduledTaskItemProps) => {
  const navigate = useNavigate();

  const handleDetailMatterTask = (task: any) => {
    if (task.matterId) {
      matterDetailsRequest(task.matterId.toString()).then((response) => {
        navigate(`/matters/detail/${task.matterId}/tasks/${task.task_id}`, {
          state: { currentStep: 0, response },
        });
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px',
        gap: '8px',
        width: '100%',
        backgroundColor: '#F0F8FD',
        borderRadius: '14px',
        border: '1px solid #8A2BE2',
      }}
    >
      <Box
        sx={{
          borderBottom: 'solid 1px #def6ff',
          width: '100%',
          paddingBottom: '5px',
        }}
      >
        <Typography
          sx={{
            ...karlaProRegularFontStyles,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#202020',
          }}
        >
          {matterTitle || 'Personal Task'}
        </Typography>
        {info && (
          <>
            <Box>
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '22px',
                  color: '#202020',
                  textTransform: 'capitalize',
                }}
              >
                {info.stage}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '22px',
                  color: '#202020',
                  textTransform: 'capitalize',
                }}
              >
                due {info.deadline}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 300,
                  fontSize: '10px',
                  lineHeight: '12px',
                  letterSpacing: '0.2px',
                  textTransform: 'uppercase',
                  color: '#464B53',
                }}
              >
                {info.status} - Severity {info.severity}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {singleTask && (
        <Box
          // onClick={() => handleDetailTask(i)}
          key={singleTask.task_id}
          sx={{
            '& > div': {
              marginBottom: '2px',
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '22px',
                color: '#202020',
                textTransform: 'capitalize',
              }}
            >
              {singleTask.title}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 300,
                fontSize: '10px',
                lineHeight: '12px',
                letterSpacing: '0.2px',
                textTransform: 'uppercase',
                color: '#464B53',
              }}
            >
              {singleTask.status}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0.2px',
                color: '#464B53',
              }}
            >
              {singleTask.description}
            </Typography>
          </Box>
        </Box>
      )}
      {tasks &&
        tasks?.map((t: any) => {
          return (
            <Box
              onClick={() => handleDetailMatterTask(t)}
              key={t.task_id}
              sx={{
                '& > div': {
                  marginBottom: '2px',
                },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '22px',
                    color: '#202020',
                    textTransform: 'capitalize',
                  }}
                >
                  {t.title}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 300,
                    fontSize: '10px',
                    lineHeight: '12px',
                    letterSpacing: '0.2px',
                    textTransform: 'uppercase',
                    color: '#464B53',
                  }}
                >
                  {t.status}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.2px',
                    color: '#464B53',
                  }}
                >
                  {t.description}
                </Typography>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default ScheduledTaskItem;
