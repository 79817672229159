import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { karlaProRegularFontStyles } from '../../../styles/textStyles';

const commentStyle = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },

  text: {
    ...karlaProRegularFontStyles,
    alignSelf: 'stretch',
    color: 'var(--shades-grey-7, #464B53)',
    fontSize: '14px',
    lineHeight: '22px',
    wordWrap: 'break-word',
  },

  timestamp: {
    ...karlaProRegularFontStyles,
    color: 'var(--main-colours-dark-blue, #140070)',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
  },
};

type Props = {
  comment: any;
};

const TaskComment: FC<Props> = ({ comment }) => {
  // add state to signal new comment is being added
  return (
    <Box sx={commentStyle.wrapper}>
      <Typography sx={commentStyle.text}>{comment.comment}</Typography>
      <Typography sx={commentStyle.timestamp}>
        {`${comment.author}, ${comment.created_date}`}
      </Typography>
    </Box>
  );
};

export default TaskComment;
