import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';
import { formSuccessButtonStyles } from '../styles';

interface FormMessageProps {
  type: string;
  titleMessage?: string;
  message: string;
  buttonText?: string;
  onClickAction?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const FormMessage = ({
  type,
  titleMessage,
  message,
  buttonText,
  onClickAction,
}: FormMessageProps) => {
  let icon;
  let iconColor;
  switch (type) {
    case 'success':
      icon = <span className="material-symbols-outlined">check_circle</span>;
      iconColor = '#38B763';
      break;
    case 'error':
      icon = <span className="material-icons-round">dangerous</span>;
      iconColor = '#E22727';
      break;
    default:
      break;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '24px',
          width: '352px',
          height: '168px',
          '& > span': {
            color: iconColor,
            fontSize: '40px',
          },
        }}
        data-testid="form-message-container-id"
      >
        {icon}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
          }}
        >
          {titleMessage && (
            <Typography
              sx={{
                ...sofiaProRegularFontStyles,
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'center',
                letterSpacing: '0.3px',
                color: '#202020',
              }}
            >
              {titleMessage}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px 0px',
              gap: '5px',
            }}
          >
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
                color: '#202020',
              }}
            >
              {message}
            </Typography>
          </Box>
        </Box>
      </Box>

      {onClickAction && (
        <Box
          sx={{
            height: '266px',
            display: 'flex',
            gap: '80px',
            alignItems: 'flex-end',
          }}
        >
          <Button
            data-testid="form-message-container-button-id"
            variant="contained"
            type="button"
            onClick={onClickAction}
            sx={formSuccessButtonStyles}
          >
            {buttonText}
          </Button>
        </Box>
      )}
    </>
  );
};

export default FormMessage;
