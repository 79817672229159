import { Typography } from '@mui/material';
import { sofiaProRegularFontStyles } from '../../styles/textStyles';
import { MatterHistory, TaskHistory } from '../../types/matters';

interface ActivityHistoryListItemHeaderProps {
  item: MatterHistory | TaskHistory;
  typeOfData: 'MATTERS' | 'TASKS';
}

const ActivityHistoryListItemHeader = ({
  item,
  typeOfData,
}: ActivityHistoryListItemHeaderProps) => {
  return (
    <Typography
      sx={{
        color: '#464B53',
        ...sofiaProRegularFontStyles,
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.3px',
      }}
      variant="caption"
      component="p"
    >
      {typeOfData === 'MATTERS'
        ? (item as MatterHistory).clientName
        : (item as TaskHistory).taskName}
    </Typography>
  );
};

export default ActivityHistoryListItemHeader;
