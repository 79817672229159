import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { FieldValues, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getWikiTemplateDetailsRequest } from '../../../../api/wiki';
import { LEIAAUploadFileArea, LEIAADialog } from '../../../inputs';
import { matterQuestionnaireUploadAreaWrapperStyles } from '../../../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  MatterAddedDocumentsInformation,
  SidePanelInformation,
} from '../../../matters/matter-creation/sidepanel-information';
import {
  wikiTemplateWrapperStyles,
  wikiTemplateFormWrapperStyles,
  wikiTemplateInputWrapperStyles,
} from './styles';

interface WikiReportProps {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

interface FileWithId extends File {
  id: string;
  uploaded: boolean;
}

const WikiPlans = ({ watch, setValue }: WikiReportProps) => {
  const plans = watch('plans');
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [repeatedFile, setRepeatedFile] = useState<FileWithId[]>();
  const { templateId } = useParams();

  const handleFetchTemplate = async () => {
    try {
      if (templateId) {
        await getWikiTemplateDetailsRequest(templateId).then((response) => {
          setValue('interviewQuestions', response.interview_template);
          setValue('emailTemplate', response.email_template);
          setValue('oldEmailTemplate', response.email_template);
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDocsUploadedStatus = (files: FileWithId[]) => {
    return files.map((file: FileWithId) => ({
      id: file.id,
      name: file.name,
    }));
  };

  const handleDocUpload = () => {
    if (plans && plans.length > 0) {
      const updatedFiles = updateDocsUploadedStatus(plans);
      setValue('plans', updatedFiles);
    }
  };

  useEffect(() => {
    handleDocUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFiles = (fileList: FileList) => {
    let existingFiles: FileWithId[] = [];

    if (fileList.length === 0) return;

    // Check if any of the selected files already exist in templateReport
    if (plans && plans.length > 0) {
      existingFiles = plans.filter((md: FileWithId) =>
        Array.from(fileList).some(
          (selectedFile) => md.name === selectedFile.name
        )
      );
      setRepeatedFile(existingFiles);
    }

    if (existingFiles.length > 0) {
      setOpenAlertOpenDialog(true);
    } else {
      const filesArray: FileWithId[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];

        // Generate a unique id for the file
        const fileId = uuidv4();
        // Add the id property to the file object
        const fileWithId: FileWithId = Object.assign(file, {
          id: fileId,
          uploaded: false,
        });

        filesArray.push(fileWithId);
      }
      setValue('plans', [...(plans || []), ...filesArray]);
    }
  };

  const handleAddFile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.target.files as FileList;
    handleUploadFiles(fileList);
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const fileList = e.dataTransfer.files;
      handleUploadFiles(fileList);
    }
  };

  const handleRemoveFile = (id: string | number) => {
    // Update the 'files' field in the form payload
    setValue(
      'plans',
      (plans || []).filter((file: FileWithId) => file.id !== id)
    );
    if (typeof id === 'number') {
      const filesIdsToDelete = watch('files_to_delete') || [];
      setValue('files_to_delete', [...filesIdsToDelete, id]);
    }
  };

  return (
    <Box sx={wikiTemplateWrapperStyles}>
      {/* FORM  */}
      <Box sx={wikiTemplateFormWrapperStyles}>
        <Box sx={wikiTemplateInputWrapperStyles}>
          <LEIAAUploadFileArea
            title="Select plans to import."
            subTitle="The imported file will be then possible to download for consultation."
            handleDrag={handleDrag}
            handleAddFile={handleAddFile}
            uploadAreaStyles={{
              ...matterQuestionnaireUploadAreaWrapperStyles,
              paddingLeft: '0px',
            }}
          />
        </Box>
      </Box>
      <SidePanelInformation>
        {plans && plans.length > 0 && (
          <MatterAddedDocumentsInformation
            matterDocuments={plans}
            handleRemoveFile={handleRemoveFile}
          />
        )}
      </SidePanelInformation>

      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description="We have detected similar existing files. Please change the final name if you want to add this file."
        simpleButtonText="Ok"
        onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
        isTextAlign
      />
    </Box>
  );
};

export default WikiPlans;
