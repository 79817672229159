import { Popper } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LEIAAAutocompletePopper = (props: any) => {
  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 8], // Adjust the vertical offset as needed
      },
    },
    {
      name: 'preventOverflow',
      options: {
        padding: 8, // Adjust the padding as needed
      },
    },
    {
      name: 'flip',
      options: {
        padding: 8, // Adjust the padding as needed
      },
    },
  ];

  return (
    <Popper
      {...props}
      modifiers={popperModifiers}
      sx={{
        '&.MuiAutocomplete-popper': {
          backgroundColor: 'transparent',
          '& > div': {
            border: '1px solid #0053FF',
            boxShadow: '0px 0px 10px 0px rgba(21, 10, 84, 0.30)',
            borderRadius: '14px',
          },
          '& > div > ul.MuiAutocomplete-listbox': {
            '& > li': {
              color: '#464B53',
              fontSize: '14px',
              ...karlaProRegularFontStyles,
              lineHeight: '22px',
              '&:hover': {
                backgroundColor: '#EBF8FE',
              },
            },
          },
        },
      }}
    />
  );
};

export default LEIAAAutocompletePopper;
