import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  tertiaryButtonStyles,
  secondaryButtonStyles,
  primaryButtonStyles,
  userCreationFooterWrapperStyles,
} from '../styles';

const UserCreationFooter = () => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Box sx={userCreationFooterWrapperStyles}>
      <Box>
        <Button
          sx={tertiaryButtonStyles()}
          variant="contained"
          onClick={handleCancel}
        >
          <Typography>Cancel</Typography>
        </Button>

        <Button
          variant="contained"
          sx={{ ...secondaryButtonStyles(), display: 'none' }}
        >
          <span className="material-symbols-rounded">draft</span>
          <Typography>Save as draft</Typography>
        </Button>

        <Button variant="contained" sx={primaryButtonStyles()} type="submit">
          <Typography>Save</Typography>
          <span className="material-icons-round">save</span>
        </Button>
      </Box>
    </Box>
  );
};

export default UserCreationFooter;
