import { Box, Typography } from '@mui/material';

interface ErrorMessageProps {
  type: string;
  message: string;
}

const ErrorMessage = ({ type, message }: ErrorMessageProps) => {
  const typeColor =
    // eslint-disable-next-line no-nested-ternary
    type === 'error' ? 'red' : type === 'warning' ? 'yellow' : 'blue';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          padding: '12px 24px',
          borderRadius: '4px',
          backgroundColor: typeColor,
          color: 'white',
          '> p': {
            fontWeight: 600,
          },
        }}
        data-testid="error-message-container-id"
      >
        <Typography variant="caption" component="p">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorMessage;
