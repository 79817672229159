import * as React from 'react';
import {
  ChatToggle,
  DisconnectButton,
  StartAudio,
  TrackToggle,
  useLocalParticipantPermissions,
  useMaybeLayoutContext,
} from '@livekit/components-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isMobileBrowser } from '@livekit/components-core';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { Track } from 'livekit-client';
import { LEIAATrackToggle } from './LEIAATrackToggle';
import { LEIAAMediaDeviceMenu } from './LEIAAMediaDeviceMenu';
import {
  controlBarButtonGroupStyles,
  controlBarButtonStyles,
  controlBarGenericButtonStyles,
  controlBarStyles,
} from './styles/styles';
import LEIAAPanelBtn from '../panel/LEIAAPanelBtn';
import FileUploadModal from '../video-conference/FileUploadModal';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import {
  getInterviewTranscriptionEnabledRequest,
  changeInterviewTranscriptionEnabledRequest,
} from '../../../api/interviews';

/** @public */
export type ControlBarControls = {
  microphone?: boolean;
  camera?: boolean;
  chat?: boolean;
  screenShare?: boolean;
  leave?: boolean;
};

/** @public */
export type ControlBarProps = React.HTMLAttributes<HTMLDivElement> & {
  variation?: 'minimal' | 'verbose' | 'textOnly';
  controls?: ControlBarControls;
  chatBtnPressed: boolean;
  openPanel?: any;
  setOpenPanel?: any;
  currentUser: any;
  intervieweeName?: string;
  documentUpload?: boolean;
  interviewDetails?: any;
  inPerson?: boolean;
};

/**
 * The ControlBar prefab component gives the user the basic user interface
 * to control their media devices and leave the room.
 *
 * @remarks
 * This component is build with other LiveKit components like `TrackToggle`,
 * `DeviceSelectorButton`, `DisconnectButton` and `StartAudio`.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <ControlBar />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function LEIAAControlBar({
  variation,
  controls,
  openPanel,
  setOpenPanel,
  chatBtnPressed = false,
  currentUser,
  intervieweeName,
  documentUpload = false,
  interviewDetails = {},
  inPerson = true,
  ...props
}: ControlBarProps) {
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [transcriptOn, setTranscriptOn] = React.useState<boolean>(true);
  const layoutContext = useMaybeLayoutContext();
  React.useEffect(() => {
    if (layoutContext?.widget.state?.showChat !== undefined) {
      setIsChatOpen(layoutContext?.widget.state?.showChat);
    }
  }, [layoutContext?.widget.state?.showChat]);
  const isTooLittleSpace = useMediaQuery(
    `(max-width: ${isChatOpen ? 1000 : 760}px)`
  );

  const defaultVariation = isTooLittleSpace ? 'minimal' : 'verbose';
  // eslint-disable-next-line no-param-reassign
  variation ??= defaultVariation;

  const visibleControls = { leave: true, ...controls };

  const localPermissions = useLocalParticipantPermissions();

  const currentUserName = `${currentUser.first_name} ${currentUser.last_name}`;

  if (!localPermissions) {
    visibleControls.camera = false;
    visibleControls.chat = false;
    visibleControls.microphone = false;
    visibleControls.screenShare = false;
  } else {
    visibleControls.camera ??= localPermissions.canPublish;
    visibleControls.microphone ??= localPermissions.canPublish;
    visibleControls.screenShare ??= localPermissions.canPublish;
    visibleControls.chat ??= localPermissions.canPublishData && controls?.chat;
  }

  const showIcon = React.useMemo(
    () => variation === 'minimal' || variation === 'verbose',
    [variation]
  );
  const showText = React.useMemo(
    () => variation === 'textOnly' || variation === 'verbose',
    [variation]
  );

  const isMobile = React.useMemo(() => isMobileBrowser(), []);

  const [isScreenShareEnabled, setIsScreenShareEnabled] = React.useState(false);

  const onScreenShareChange = (enabled: boolean) => {
    setIsScreenShareEnabled(enabled);
  };
  const handleFetchTranscriptStatus = async () => {
    try {
      const response = await getInterviewTranscriptionEnabledRequest(
        interviewDetails.id
      );
      setTranscriptOn(response);
    } catch (error: any) {
      console.log(error);
    }
  };

  const updateTranscriptStatus = async () => {
    try {
      const value = !transcriptOn;
      await changeInterviewTranscriptionEnabledRequest(
        interviewDetails.id,
        value
      );
      setTranscriptOn(value);
    } catch (error: any) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (inPerson) handleFetchTranscriptStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className="lk-control-bar"
      {...props}
      sx={{
        ...controlBarStyles,
        '& > div > button': {
          ...controlBarButtonStyles,
        },
        ...controlBarButtonGroupStyles(false),
      }}
    >
      <Box
        sx={{
          gap: '20px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {visibleControls.microphone && (
          <Box className="lk-button-group">
            <LEIAATrackToggle
              source={Track.Source.Microphone}
              showIcon={showIcon}
            >
              <LEIAAMediaDeviceMenu kind="audioinput" />
            </LEIAATrackToggle>
          </Box>
        )}
        {visibleControls.camera && (
          <Box className="lk-button-group">
            <LEIAATrackToggle source={Track.Source.Camera} showIcon={showIcon}>
              <LEIAAMediaDeviceMenu kind="videoinput" />
            </LEIAATrackToggle>
          </Box>
        )}
        {visibleControls.screenShare && !isMobile && (
          <TrackToggle
            source={Track.Source.ScreenShare}
            captureOptions={{ audio: true, selfBrowserSurface: 'include' }}
            showIcon={showIcon}
            onChange={onScreenShareChange}
          >
            {showText &&
              (isScreenShareEnabled ? 'Stop screen share' : 'Share screen')}
          </TrackToggle>
        )}
        {['Admin', 'Superuser', 'Leader', 'Agent'].includes(currentUser.role) &&
          inPerson &&
          process.env.REACT_APP_DISABLE_AI === 'false' && (
            <Button
              variant="contained"
              sx={{
                ...controlBarGenericButtonStyles,
                backgroundColor: '#464B53 !important',
                '&:hover': {
                  border: '2px solid #0053FF !important',
                },
                '& > p': {
                  textAlign: 'center',
                  fontSize: '16px',
                  ...karlaProRegularFontStyles,
                  letterSpacing: '0.2px',
                  textTransform: 'none',
                },
              }}
              onClick={() => updateTranscriptStatus()}
            >
              <span
                style={{ marginRight: '8px' }}
                className="material-icons-round"
              >
                record_voice_over
              </span>
              <Typography>
                Transcription is {transcriptOn ? 'on' : 'off'}
              </Typography>
            </Button>
          )}
        {visibleControls.leave && (
          <DisconnectButton>
            {showIcon && <span className="material-icons-round">call_end</span>}
            {showText && 'End'}
          </DisconnectButton>
        )}
        <StartAudio label="Start Audio" />
      </Box>

      {documentUpload && (
        <FileUploadModal
          matterId={interviewDetails.matter_id}
          interviewDetails={interviewDetails}
        />
      )}

      {(openPanel || setOpenPanel) && (
        <LEIAAPanelBtn openPanel={openPanel} setOpenPanel={setOpenPanel} />
      )}
    </Box>
  );
}
