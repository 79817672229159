import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface FileTypeCellProps {
  type: string;
}

const FileTypeCell = ({ type }: FileTypeCellProps) => {
  let icon;
  let text;
  switch (type.toLowerCase()) {
    case 'jpg':
      text = 'Image';
      icon = 'image';
      break;
    case 'png':
      text = 'Image';
      icon = 'image';
      break;
    case 'jpeg':
      text = 'Image';
      icon = 'image';
      break;
    case 'txt':
      text = 'Text';
      icon = 'text_fields';
      break;
    case 'pdf':
      text = 'PDF';
      icon = 'picture_as_pdf';
      break;
    case 'email':
      text = 'Email';
      icon = 'mail';
      break;
    case 'docx':
      text = 'Docx';
      icon = 'article';
      break;
    case '':
      text = '-';
      icon = '';
      break;
    default:
      text = type;
      icon = 'insert_drive_file';
      break;
  }

  return (
    <Box>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          color: '#69707B',
          ...karlaProRegularFontStyles,
          fontSize: '14px',
          lineHeight: '22px',
          '& > span': {
            fontSize: '20px',
          },
        }}
      >
        <span className="material-icons-round">{icon}</span>
        {text}
      </Typography>
    </Box>
  );
};

export default FileTypeCell;
