import api from './index';

async function documentDataStreamRequest(docId: any) {
  const response = await api.get(`/document-data-stream/${docId}`);
  return response.data.document_data_stream;
}

async function filesRequest(action: string, uri = '') {
  const filters: {
    action: string;
    uri?: string;
  } = {
    action,
  };

  if (uri !== '') {
    filters.uri = uri;
  }

  const response = await api.get('/files', {
    params: filters,
  });

  return response.data;
}

export { documentDataStreamRequest, filesRequest };
