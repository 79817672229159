import { Box, Typography } from '@mui/material';
import { AuthProps } from '../../types/authProps';
import Form from './form/Form';
import FormSuccessMessage from './form/FormMessage';
import {
  formContentWrapperStyles,
  formHeaderWrapperStyles,
  formSubHeaderWrapperStyles,
  formSuccessMessageWrapperStyles,
} from './styles';

const ForgotPasswordForm = ({
  handleSubmit,
  onSubmit,
  emailSent,
  control,
  watch,
  errors,
}: AuthProps) => {
  const fields = [
    {
      name: 'email_address',
      label: 'Email',
      inputPlaceholder: 'Enter email',
      required: true,
    },
  ];

  return (
    <>
      <Box sx={formHeaderWrapperStyles}>
        <Typography variant="h3" data-testid="reset-password-form-title">
          Reset password
        </Typography>
      </Box>
      <Box sx={formContentWrapperStyles}>
        {!emailSent ? (
          <>
            <Box sx={formSubHeaderWrapperStyles('64px')}>
              <Typography variant="caption" component="p">
                Please enter your email address and we will send you a link to
                reset your password.
              </Typography>
            </Box>
            <Form
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              fields={fields}
              submitButtonText="Send"
              watch={watch}
              errors={errors}
            />
          </>
        ) : (
          <Box sx={formSuccessMessageWrapperStyles}>
            <FormSuccessMessage
              type="success"
              titleMessage="Email sent"
              message="We have sent you a link to your email address. Please follow the instructions contained in the email to reset your password."
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
