import { useEffect, useRef, useState } from 'react';
import { Box, Divider, Step, StepButton, Stepper } from '@mui/material';
import ResourcePageHeader from '../components/shared/resource-page-header/ResourcePageHeader';
import './styles/interviewlist.css';
import '../components/styles/datagridStyles.css';
import WikiTemplates from '../components/wiki/WikiTemplates';
import WikiRepository from '../components/wiki/WikiRepository';
import { wikiHeaderStepperStyles } from '../components/styles';
import { useWikiPermissions } from '../hooks/usePermissions';
import { PermissionsObject } from '../redux/features/user-permissions/userPermissionsSlice';

const stepIcons = ['view_kanban', 'archive'];

const getPermittedSteps = ({
  wikiTemplate,
  wikiRepository,
}: {
  wikiTemplate: PermissionsObject['wiki_template'];
  wikiRepository: PermissionsObject['wiki_repository'];
}) => {
  // I think there maybe a bug here because the activeStep state
  // no longer matches with the number of steps
  // and the wrong tab is highlighted
  // needs further testing
  const steps = [
    // 'Templates',
    // 'Repository',
  ];

  if (wikiTemplate.view) {
    steps.push('Templates');
  }

  if (wikiRepository.view) {
    steps.push('Repository');
  }

  return steps;
};

const WikiList = () => {
  const wikiPermissions = useWikiPermissions();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [activeStep, setActiveStep] = useState(
    wikiPermissions.wikiTemplate.view ? 0 : 1
  );
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return wikiPermissions.wikiTemplate.view ? <WikiTemplates /> : null;
      case 1:
        return wikiPermissions.wikiRepository.view ? <WikiRepository /> : null;

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <ResourcePageHeader inputRef={searchInputRef} headerText="Wiki" />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Stepper
              nonLinear
              activeStep={activeStep}
              sx={wikiHeaderStepperStyles}
            >
              {getPermittedSteps(wikiPermissions).map((label, index) => (
                <Step key={label}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                    icon={
                      <span className="material-icons-round">
                        {stepIcons[index]}
                      </span>
                    }
                  >
                    {label}
                  </StepButton>
                  {activeStep === index ? (
                    <Divider
                      sx={{ width: '100%', border: '1px solid #0053FF' }}
                    />
                  ) : (
                    <Divider
                      sx={{ width: '100%', border: '1px solid #69707B' }}
                    />
                  )}
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        {renderStepContent()}
      </Box>
    </Box>
  );
};

export default WikiList;
