import { Box } from '@mui/material';
import LEIAALogo1 from '../../assets/LEIAALogo1.png';

const ReportingImageHeader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '121px',
        width: '100%',
      }}
    >
      <img
        src={
          process.env.REACT_APP_REPORTING_WHITELABEL === 'true'
            ? process.env.REACT_APP_REPORTING_WHITELABEL_LOGO
            : LEIAALogo1
        }
        style={{ width: '228.12px', height: 'auto' }}
        alt="Enterprise logo"
      />
    </Box>
  );
};

export default ReportingImageHeader;
