import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useState } from 'react';
import DropdownMenuIcon from '../matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import {
  matterInformationSelectPaperStyles,
  matterInformationSelectStyles,
} from '../matters/matter-creation/matter-creation-steps/matter-information-step/styles';

interface LEIAASelectProps {
  labelText?: string;
  controllerName: string;
  control: Control<FieldValues>;
  inputPlaceholder?: string;
  inputWidth: string;
  inputHeight: string;
  required?: boolean;
  children: React.ReactNode;
  paperCustomStylings?: any;
  handleChange?: any;
  mapFunction?: any;
  inputValue?: string;
  baseHeight?: string;
}

const LEIAASelect = ({
  labelText,
  controllerName,
  control,
  inputPlaceholder,
  inputWidth,
  inputHeight,
  required = true,
  children,
  paperCustomStylings,
  handleChange,
  mapFunction,
  inputValue = '',
  baseHeight = 'unset',
}: LEIAASelectProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const mapRestValue = (value: any) => {
    if (mapFunction) {
      return mapFunction(value);
    }
    return value;
  };

  return (
    <Box>
      {labelText && (
        <InputLabel data-testid="matter-form-label-id" htmlFor={labelText}>
          {required ? '*' : ''}
          {labelText}
        </InputLabel>
      )}
      <Controller
        name={controllerName}
        control={control}
        defaultValue={inputValue}
        render={({ field: { onChange, ...rest } }) => (
          <FormControl fullWidth required={required}>
            <Select
              {...rest}
              displayEmpty
              labelId="leiaa-simple-select-label"
              id="leiaa-simple-select"
              size="small"
              open={menuOpen}
              onClose={handleMenuClose}
              onOpen={handleMenuOpen}
              // eslint-disable-next-line react/no-unstable-nested-components
              IconComponent={() => (
                <DropdownMenuIcon
                  isMenuOpen={menuOpen}
                  handleDropdownClientIconClick={handleMenuOpen}
                  isAdornment={false}
                />
              )}
              MenuProps={{
                PaperProps: {
                  sx: {
                    ...matterInformationSelectPaperStyles,
                    ...paperCustomStylings,
                  },
                },
              }}
              sx={matterInformationSelectStyles(
                inputHeight,
                inputWidth,
                baseHeight
              )}
              value={mapRestValue(rest.value) || ''}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value);
                }
                if (handleChange) {
                  handleChange(e);
                }
              }}
            >
              {inputPlaceholder && (
                <MenuItem disabled value="">
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#69707B',
                    }}
                  >
                    {inputPlaceholder}
                  </Typography>
                </MenuItem>
              )}
              {children}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default LEIAASelect;
