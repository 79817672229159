import { Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../../styles/textStyles';
import LEIAAPagination from '../lists/LEIAAPagination';
import SortableHeader from '../matters-list-table/SortableHeader';
import {
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStatusChipWrapperStyles,
  mattersListStyles,
  mattersListWrapperStyles,
} from '../matters-list-table/styles';

interface BundlesListTableProps {
  bundlesListData: any | null;
  loading: boolean;
  listClass: string;
  dashboard?: boolean;
  setCheckedBundles?: any;
  setSelectedBundle?: any;
  handleDetailBundle?: any;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No bundles.
    </Typography>
  </GridOverlay>
);

const BundlesListTable = ({
  bundlesListData,
  loading,
  dashboard = false,
  listClass,
  setCheckedBundles,
  setSelectedBundle,
  handleDetailBundle,
}: BundlesListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && bundlesListData?.bundles !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, bundlesListData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <Box
            sx={mattersListStatusChipWrapperStyles(
              'backgroundColor',
              'color',
              '30px',
              '36px'
            )}
          >
            <Box>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  color: '#69707B',
                  ...karlaProRegularFontStyles,
                }}
              >
                <span className="material-icons-round">topic</span>
                {cellValue}
              </Typography>
            </Box>
          </Box>
        );
      },
    },

    {
      field: 'interviee_name',
      headerName: 'Interviewee Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'interview_date',
      headerName: 'Interview Time/Date',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;

        const jsDate = new Date(cellValue);

        const day = jsDate.getDate().toString().padStart(2, '0');
        const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
        const year = jsDate.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;

        return (
          cellValue !== '' && (
            <Box>
              <p className="MuiDataGrid-cellContent">{formattedDate}</p>
            </Box>
          )
        );
      },
    },
    {
      field: 'updated',
      headerName: 'Last modified',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;

        const jsDate = new Date(cellValue);

        const day = jsDate.getDate().toString().padStart(2, '0');
        const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
        const year = jsDate.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;

        return (
          <Box>
            <p className="MuiDataGrid-cellContent">{formattedDate}</p>
          </Box>
        );
      },
    },
  ];

  const rowsAreEmpty = bundlesListData?.bundles.length === 0;

  return (
    <Box sx={mattersListWrapperStyles(rowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={bundlesListData?.bundles as any[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: dashboard ? 6 : 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection={!dashboard}
            onRowSelectionModelChange={(ids) => {
              setCheckedBundles(ids);
              if (ids.length !== 0) {
                setSelectedBundle(
                  bundlesListData?.bundles.find((row: any) => row.id === ids[0])
                );
              }
            }}
            onRowClick={(params) => {
              handleDetailBundle(params.row);
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            hideFooter={dashboard}
            sx={mattersListStyles}
            className={listClass}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BundlesListTable;
