import { Box, Typography } from '@mui/material';
import { interviewAutomatedStyles } from './styles';

const LEIAAAutomatedMeetingWarning = () => {
  return (
    <Box sx={interviewAutomatedStyles}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="info">
          <path
            id="Vector"
            d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM12 17.5C11.45 17.5 11 17.05 11 16.5V12.5C11 11.95 11.45 11.5 12 11.5C12.55 11.5 13 11.95 13 12.5V16.5C13 17.05 12.55 17.5 12 17.5ZM13 9.5H11V7.5H13V9.5Z"
            fill="#0053FF"
          />
        </g>
      </svg>
      <Box>
        <Typography>This is an unmoderated interview.</Typography>
        <Box>
          <Typography>
            You will be presented with several questions that you can answer
            verbally at your own pace.
          </Typography>
          <Typography>
            There is no time limit to finalise your interview.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LEIAAAutomatedMeetingWarning;
