import api from '.';

async function getReportUiDataRquest() {
  const response = await api.get('/whistleblower-ui-data');

  return response.data;
}

async function submitConcernReportRequest(data: any) {
  const formData = new FormData();

  formData.append('jsonField', JSON.stringify(data));

  if (data.files) {
    data.files.forEach((file: any) => {
      formData.append('sent_files', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await api.post(`/whistleblower`, formData, config);

  return response.data;
}

async function getWhistleblowerReport(report_id: any) {
  const response = await api.get(`/whistleblower/${report_id}`);
  return response.data;
}

async function getWhistleblowerReportMessagesRequest(report_id: any) {
  const response = await api.get(`/whistleblower-chat/${report_id}`);
  return response.data;
}

async function createWhistleblowerReportMessageRequest(
  report_id: any,
  data: any
) {
  const formData = new FormData();

  formData.append('json', JSON.stringify(data));

  if (data.files) {
    data.files.forEach((file: any) => {
      formData.append('sent_files', file);
    });
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await api.post(
    `/whistleblower-chat/${report_id}`,
    formData,
    config
  );

  return response.data;
}

async function reportsRequest(
  type: string,
  search: string,
  closedFilter: boolean
): Promise<any> {
  const filters: { type: string; search?: string; closed?: boolean } = {
    type,
  };

  if (search !== '') {
    filters.search = search;
  }

  filters.closed = false;
  if (closedFilter) {
    filters.closed = true;
  }

  const response = await api.get('/reports', {
    params: filters,
  });

  return response.data;
}

async function getReportAdminDataRequest(report_id: any) {
  const response = await api.get(`/report/${report_id}`);
  return response.data;
}

async function pushToMatterRequest(report_id: any) {
  const response = await api.put(`/report/${report_id}`, {});
  return response.data;
}

async function updateAdminReportRequest(report_id: any, data: any) {
  const response = await api.patch(`/report/${report_id}`, data);
  return response.data;
}

async function moveReportStageRequest(report_id: any, stage: any) {
  const response = await api.put(`/kanban-report/${report_id}`, {
    stage,
  });
  return response.data;
}

async function updateAdminReportingSettingsRequest(data: any) {
  const response = await api.post('/admin-reporting-settings', data);
  return response.data;
}

async function getAdminReportingSettingsRequest() {
  const response = await api.get('/admin-reporting-settings');
  return response.data;
}

async function updateAllegationSubject(report_id: any, data: any) {
  const response = await api.patch(`/allegation-subjects/${report_id}`, data);
  return response.data;
}

async function createAllegationSubject(report_id: any, data: any) {
  const response = await api.post(`/allegation-subjects/${report_id}`, data);
  return response.data;
}

async function createAllegationSubjectIndividual(data: any) {
  const response = await api.post(`/individuals`, data);
  return response.data;
}

export {
  submitConcernReportRequest,
  getReportUiDataRquest,
  getWhistleblowerReport,
  getWhistleblowerReportMessagesRequest,
  createWhistleblowerReportMessageRequest,
  reportsRequest,
  pushToMatterRequest,
  getReportAdminDataRequest,
  updateAdminReportRequest,
  moveReportStageRequest,
  updateAdminReportingSettingsRequest,
  getAdminReportingSettingsRequest,
  updateAllegationSubject,
  createAllegationSubject,
  createAllegationSubjectIndividual,
};
