import { Box, Grid, Typography } from '@mui/material';
import { matterCreationSidepanelFieldStyles } from '../styles';

interface MatterAddedGeneralInformationProps {
  matterInfoFields: any;
}

const MatterAddedGeneralInformation = ({
  matterInfoFields,
}: MatterAddedGeneralInformationProps) => {
  return (
    <Box>
      <Box>
        <Box>
          <Typography>Matter</Typography>
        </Box>
      </Box>
      {matterInfoFields.map((mi: any) => {
        return (
          mi.value !== '' && (
            <Box key={mi.label} sx={matterCreationSidepanelFieldStyles}>
              <Box>
                <Box>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography>{mi.label}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{mi.value}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )
        );
      })}
    </Box>
  );
};

export default MatterAddedGeneralInformation;
