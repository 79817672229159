import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { matterAddedTasksWrapperStyles } from '../matter-creation-steps/matter-tasks-step/styles';
import { MatterTask } from '../../../../types/matters';

interface MatterAddedTasksInformationProps {
  selectedIndex: number;
  matterTasks: any;
  handleSelectTask: any;
  handleRemoveMatterTask: any;
  addTaskBtn: any;
}

const MatterAddedTasksInformation = ({
  selectedIndex,
  matterTasks,
  handleSelectTask,
  handleRemoveMatterTask,
  addTaskBtn,
}: MatterAddedTasksInformationProps) => {
  const vadidTask = (task: MatterTask) => {
    const { name, description, status, deadline } = task;
    return (
      name !== '' && description !== '' && status !== '' && deadline !== ''
    );
  };

  return (
    <Box>
      <Box>
        <Box>
          <Typography>Added Tasks</Typography>
        </Box>
      </Box>
      {matterTasks.map((mt: MatterTask, index: number) => {
        return (
          <Box
            key={`${mt.id}-${index}`}
            sx={matterAddedTasksWrapperStyles(selectedIndex, index)}
            onClick={() => handleSelectTask(index)}
          >
            <Box>
              <Box>
                <Box>
                  <Box>
                    <Typography>{index + 1}.</Typography>
                    <Typography>
                      {mt.name === '' ? 'Current Task' : mt.name}
                    </Typography>
                  </Box>
                  <Box>
                    {!vadidTask(mt) && (
                      <Box
                        sx={{
                          display: 'flex',
                          '& > span': {
                            fontSize: '16px',
                            color: '#E22727',
                          },
                        }}
                      >
                        <span className="material-icons-round">error</span>
                      </Box>
                    )}
                    <IconButton
                      data-testid="clear-task-added-id"
                      aria-label="clear task added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                      }}
                      onClick={() => handleRemoveMatterTask(mt.id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
      {addTaskBtn}
    </Box>
  );
};

export default MatterAddedTasksInformation;
