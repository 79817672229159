/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  computeMenuPosition,
  wasClickOutside,
  log,
} from '@livekit/components-core';
import * as React from 'react';
import { Box } from '@mui/material';
import { LEIAAMediaDeviceSelect } from './LEIAAMediaDeviceSelect';

/** @public */
export interface MediaDeviceMenuProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: MediaDeviceKind;
  initialSelection?: string;
  onActiveDeviceChange?: (kind: MediaDeviceKind, deviceId: string) => void;
}

/**
 * The MediaDeviceMenu prefab component is a button that opens a menu that lists
 * all media devices and allows the user to select them.
 *
 * @remarks
 * This component is implemented with the `MediaDeviceSelect` LiveKit components.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <MediaDeviceMenu />
 * </LiveKitRoom>
 * ```
 * @public
 */
export const LEIAAMediaDeviceMenu = ({
  kind,
  initialSelection,
  onActiveDeviceChange,
  ...props
}: MediaDeviceMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);
  const [updateRequired, setUpdateRequired] = React.useState<boolean>(true);

  const handleActiveDeviceChange = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    kind: MediaDeviceKind,
    deviceId: string
  ) => {
    log.debug('handle device change');
    setIsOpen(false);
    onActiveDeviceChange?.(kind, deviceId);
  };

  const button = React.useRef<HTMLButtonElement>(null);
  const tooltip = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (button.current && tooltip.current && (devices || updateRequired)) {
      computeMenuPosition(button.current, tooltip.current).then(({ x, y }) => {
        if (tooltip.current) {
          Object.assign(tooltip.current.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        }
      });
    }
    setUpdateRequired(false);
  }, [button, tooltip, devices, updateRequired, isOpen]);

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (!tooltip.current) {
        return;
      }
      if (event.target === button.current) {
        return;
      }
      if (isOpen && wasClickOutside(tooltip.current, event)) {
        setIsOpen(false);
      }
    },
    [isOpen, tooltip, button]
  );

  React.useEffect(() => {
    document.addEventListener<'click'>('click', handleClickOutside);
    window.addEventListener<'resize'>('resize', () => setUpdateRequired(true));
    return () => {
      document.removeEventListener<'click'>('click', handleClickOutside);
      window.removeEventListener<'resize'>('resize', () =>
        setUpdateRequired(true)
      );
    };
  }, [handleClickOutside, setUpdateRequired]);

  const handleIconClick = (event: { stopPropagation: () => void }) => {
    setIsOpen(!isOpen);
    // Stop the event propagation to prevent it from reaching the button
    event.stopPropagation();
  };

  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <>
      <span
        aria-pressed={isOpen}
        {...props}
        onClick={handleIconClick}
        ref={button}
        className="material-icons-round"
      >
        arrow_drop_down
      </span>

      <Box
        className="lk-device-menu"
        ref={tooltip}
        style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      >
        {kind ? (
          <LEIAAMediaDeviceSelect
            initialSelection={initialSelection}
            onActiveDeviceChange={(deviceId) =>
              handleActiveDeviceChange(kind, deviceId)
            }
            onDeviceListChange={setDevices}
            kind={kind}
          />
        ) : (
          <>
            <Box className="lk-device-menu-heading">Audio inputs</Box>
            <LEIAAMediaDeviceSelect
              kind="audioinput"
              onActiveDeviceChange={(deviceId) =>
                handleActiveDeviceChange('audioinput', deviceId)
              }
              onDeviceListChange={setDevices}
            />
            <Box className="lk-device-menu-heading">Video inputs</Box>
            <LEIAAMediaDeviceSelect
              kind="videoinput"
              onActiveDeviceChange={(deviceId) =>
                handleActiveDeviceChange('videoinput', deviceId)
              }
              onDeviceListChange={setDevices}
            />
          </>
        )}
      </Box>
    </>
  );
};
