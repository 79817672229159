const sofiaProFontFamily = {
  fontFamily: 'sofia-pro, sans-serif !important',
};

const karlaFontFamily = {
  fontFamily: 'Karla, sans-serif !important',
};

export const sofiaProRegularFontStyles = {
  ...sofiaProFontFamily,
  fontWeight: 400,
  fontStyle: 'normal',
};

export const sofiaProRegularItalicFontStyles = {
  ...sofiaProFontFamily,
  fontWeight: 400,
  fontStyle: 'italic',
};

export const sofiaProBoldFontStyles = {
  ...sofiaProFontFamily,
  fontWeight: 700,
  fontStyle: 'normal',
};

export const sofiaProBoldItalicFontStyles = {
  ...sofiaProFontFamily,
  fontWeight: 700,
  fontStyle: 'italic',
};

export const karlaProRegularFontStyles = {
  ...karlaFontFamily,
  fontWeight: 400,
  fontStyle: 'normal',
};

export const karlaProRegularItalicFontStyles = {
  ...karlaFontFamily,
  fontWeight: 400,
  fontStyle: 'italic',
};

export const karlaProBoldFontStyles = {
  ...karlaFontFamily,
  fontWeight: 700,
  fontStyle: 'normal',
};

export const karlaProBoldItalicFontStyles = {
  ...karlaFontFamily,
  fontWeight: 700,
  fontStyle: 'italic',
};
