import { Box, Typography, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { insightsContentWrapperStyles } from './styles';
import { MatterInfoResponse } from '../../../types/homeportal';
import { matterInfoRequest } from '../../../api/homeportal';

interface InsightsContentProps {
  insightOneText: string;
  insightTwoText: string;
  insightOneContentSent?: any;
  insightTwoContentSent?: any;
  insightThreeText?: string;
  insightThreeContentSent?: any;
  insightFourText?: string;
  insightFourContentSent?: any;
}

const InsightsContent = ({
  insightOneText,
  insightTwoText,
  insightThreeText,
  insightOneContentSent,
  insightTwoContentSent,
  insightThreeContentSent,
  insightFourText,
  insightFourContentSent,
}: InsightsContentProps) => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [insightsContent, setInsightsContent] =
    useState<MatterInfoResponse | null>(null);

  const handleFetchMatterInfo = async () => {
    setLoading(true);

    try {
      const matterInfo = await matterInfoRequest();
      setInsightsContent(matterInfo as MatterInfoResponse);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'An unknown error related to the matter insights occurred.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!insightOneContentSent && !insightTwoContentSent) {
      // Fetch matter information
      handleFetchMatterInfo();
    } else {
      const insightsData: {
        insightOne: any;
        insightTwo: any;
        insightThree?: any;
        insightFour?: any;
      } = {
        insightOne: insightOneContentSent,
        insightTwo: insightTwoContentSent,
      };

      if (insightThreeContentSent) {
        insightsData.insightThree = insightThreeContentSent;
      }

      if (insightFourContentSent) {
        insightsData.insightFour = insightFourContentSent;
      }

      setInsightsContent(insightsData);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={insightsContentWrapperStyles}>
      <Box>
        <Typography>{insightOneText}</Typography>
        {loading ? (
          <Skeleton animation="wave" width="50%" height="inherit" />
        ) : (
          <Typography variant="h1">
            {insightsContent?.insightOne !== null
              ? `${insightsContent?.insightOne}`
              : '-'}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography>{insightTwoText}</Typography>
        {loading ? (
          <Skeleton animation="wave" width="50%" height="inherit" />
        ) : (
          <Typography variant="h1">
            {insightsContent?.insightTwo !== null
              ? `${insightsContent?.insightTwo}`
              : '-'}
          </Typography>
        )}
      </Box>
      {insightThreeText && (
        <Box>
          <Typography>{insightThreeText}</Typography>
          {loading ? (
            <Skeleton animation="wave" width="50%" height="inherit" />
          ) : (
            <Typography variant="h1">
              {insightsContent?.insightThree !== null ? (
                <>{insightsContent?.insightThree} </>
              ) : (
                '-'
              )}
            </Typography>
          )}
        </Box>
      )}
      {insightFourText && (
        <Box>
          <Typography>{insightFourText}</Typography>
          {loading ? (
            <Skeleton animation="wave" width="50%" height="inherit" />
          ) : (
            <Typography variant="h1">
              {insightsContent?.insightFour !== null
                ? insightsContent?.insightFour
                : '-'}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default InsightsContent;
