import { Box, Typography, Popper, Paper } from '@mui/material';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import { listNoteTagStyles } from '../../matter-notes/styles';

const TAG_PROPERTIES: Record<string, { color: string; icon: string }> = {
  investigation: { color: '#4682B4', icon: 'folder' },
  investigationdocument: { color: '#32CD32', icon: 'article' },
  investigationtask: { color: '#1E90FF', icon: 'task' },
  interview: { color: '#FFD700', icon: 'groups' },
  user: { color: '#FF69B4', icon: 'person' },
  stage: { color: '#CC8899', icon: 'approval' },
};

const DEFAULT_PROPERTIES = { color: '#A962D5', icon: 'help' };

interface NoteTagsChipsProps {
  tags: any[];
}

const NoteTagsChips = ({ tags }: NoteTagsChipsProps) => {
  const heightValue = '30px';
  const wrapperHeight = '36px';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleTooltipOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!tags) return null;

  const renderTags = (tagsToRender: any[]) =>
    tagsToRender.map((tag) => {
      const { color: backgroundColor, icon } =
        TAG_PROPERTIES[tag.tag_type] || DEFAULT_PROPERTIES;

      return (
        <Box
          key={uuidv4()}
          sx={listNoteTagStyles(
            backgroundColor,
            '#FFFFFF',
            heightValue,
            wrapperHeight
          )}
        >
          <Box>
            <Typography>
              <span className="material-icons-round">{icon}</span>
              <span>{tag.tag_name}</span>
            </Typography>
          </Box>
        </Box>
      );
    });

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    handleTooltipOpen(event);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        gap: '8px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: '8px',
          overflow: 'hidden',
        }}
      >
        {renderTags(tags)}
      </Box>
      {tags.length > 1 && (
        <Box
          onMouseEnter={handleTooltipOpen}
          onMouseLeave={handleTooltipClose}
          onClick={handleMoreClick}
          sx={{
            cursor: 'pointer',
            backgroundColor: 'white',
            border: '1px solid #d2eafa',
            borderRadius: '10px',
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            height: heightValue,
          }}
        >
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              textTransform: 'capitalize',
              fontSize: '16px',
              color: '#464b53',
            }}
          >
            {tags.length} tags
          </Typography>
        </Box>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top"
        onMouseLeave={handleTooltipClose}
        sx={{
          zIndex: '99999999',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            padding: '6px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
            border: '1px solid #d2eafa',
            borderRadius: '12px',
            width: 'auto',
            marginX: '100px',
          }}
        >
          {renderTags(tags)}
        </Paper>
      </Popper>
    </Box>
  );
};

export default NoteTagsChips;
