import { Box, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { createAllegationSubjectIndividual } from '../../../api/whistleblower';
import { LEIAAInput } from '../../../components/inputs';
import { matterInformationInputWrapperStyles } from '../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  leiaaRenameDialogWrapperStyles,
  leiaaRenameDialogContentStyles,
} from '../../../components/shared/docs-list-table/styles';
import {
  secondaryButtonStyles,
  primaryButtonStyles,
} from '../../../components/styles';
import {
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';

export interface CreateIndividualModalProps {
  open: boolean;
  onClose: () => void;
  refreshData: any;
}

const CreateIndividualModal = ({
  open,
  onClose,
  refreshData,
}: CreateIndividualModalProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmitIndividual: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      await createAllegationSubjectIndividual(data);
      refreshData();
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <form onSubmit={handleSubmit(onSubmitIndividual)}>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={leiaaRenameDialogWrapperStyles}
      >
        <Box sx={leiaaRenameDialogContentStyles()}>
          <Box>
            <IconButton
              onClick={handleClose}
              sx={{ '& > span': { color: '#464B53' } }}
            >
              <span className="material-icons-round">close</span>
            </IconButton>
          </Box>
          <Box>
            <Box>
              <Typography
                sx={{
                  color: '#202020',
                  ...sofiaProBoldFontStyles,
                  fontSize: '18px',
                  lineHeight: 'normal',
                  letterSpacing: '2px',
                  textTransform: 'uppercase',
                }}
              >
                Create Individual
              </Typography>
              <Typography
                sx={{
                  color: '#6e6b6b',
                  ...sofiaProRegularFontStyles,
                  fontSize: '13px',
                  lineHeight: 'normal',
                  marginBottom: '20px',
                  textAlign: 'center',
                }}
              >
                Created Individual will only be used for allegation subject
                related purposes
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{ ...matterInformationInputWrapperStyles, width: '100%' }}
              >
                <Box>
                  <LEIAAInput
                    labelText="First name"
                    controllerName="first_name"
                    control={control}
                    inputPlaceholder="Enter name"
                    inputWidth="100%"
                    inputHeight="48px"
                    required
                  />
                </Box>
                <Box sx={{ marginTop: '5px' }}>
                  <LEIAAInput
                    labelText="Last name"
                    controllerName="last_name"
                    control={control}
                    inputPlaceholder="Enter name"
                    inputWidth="100%"
                    inputHeight="48px"
                    required
                  />
                </Box>
                <Box sx={{ marginTop: '5px' }}>
                  <LEIAAInput
                    labelText="Job title"
                    controllerName="job_title"
                    control={control}
                    inputPlaceholder="Enter title"
                    inputWidth="100%"
                    inputHeight="48px"
                    required={false}
                  />
                </Box>
              </Box>

              <Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      ...secondaryButtonStyles(),
                      width: '100%',
                    }}
                    onClick={onClose}
                  >
                    <Typography>Cancel</Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      ...primaryButtonStyles(),
                      width: '100%',
                    }}
                    onClick={() => handleSubmit(onSubmitIndividual)()}
                  >
                    <Typography>Create</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </form>
  );
};

export default CreateIndividualModal;
