import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, InputLabel } from '@mui/material';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import DropdownMenuIcon from '../../components/matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import {
  matterInformationSelectPaperStyles,
  matterInformationSelectStyles,
} from '../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';

const ReportingFormPhone = ({ phoneLines }: { phoneLines: any }) => {
  const [pickedLine, setPickedLine] = useState<{
    name: string;
    phone: string;
  } | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleSetMatterJurisdiction = (
    event: React.ChangeEvent<{ value: any }>
  ) => {
    const phoneLine = phoneLines.find(
      (line: any) => line.name === event.target.value
    );
    setPickedLine(phoneLine);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: { xs: '10px 20px', md: '40px 50px' },
      }}
    >
      <InputLabel
        sx={{
          color: '#464B53',
          fontSize: { xs: '14px', md: '16px' },
          ...karlaProRegularFontStyles,
          lineHeight: '22px',
          paddingBottom: '5px',
        }}
        data-testid="matter-form-label-id"
        htmlFor="leiaa-simple-select"
      >
        Location where incident occurred
      </InputLabel>
      <Select
        displayEmpty
        labelId="leiaa-simple-select-label"
        id="leiaa-simple-select"
        size="small"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent={() => (
          <DropdownMenuIcon
            isMenuOpen={menuOpen}
            handleDropdownClientIconClick={() => setMenuOpen(true)}
            isAdornment={false}
          />
        )}
        MenuProps={{
          PaperProps: {
            sx: {
              ...matterInformationSelectPaperStyles,
              maxHeight: '300px',
            },
          },
        }}
        sx={matterInformationSelectStyles('40px !important', '250px')}
        value={pickedLine ? pickedLine.name : ''}
        onChange={(e: any) => handleSetMatterJurisdiction(e)}
      >
        <MenuItem disabled value="">
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
              color: '#69707B',
            }}
          >
            Select country
          </Typography>
        </MenuItem>
        {phoneLines?.map(
          (line: { name: string; phone: string }, index: number) => (
            <MenuItem key={index} value={line.name}>
              {line.name}
            </MenuItem>
          )
        )}
      </Select>
      {pickedLine && (
        <Box sx={{ marginTop: '20px', width: { xs: '100%', md: '364px' } }}>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontSize: { xs: '15px', md: '17px' },
              color: '#202020',
              marginTop: '5px',
            }}
          >
            <span
              style={{
                fontSize: '22px',
                color: '#0053fe',
                verticalAlign: 'middle',
                marginRight: '5px',
              }}
              className="material-icons-round"
            >
              flag
            </span>
            <span style={{ color: 'rgb(109 109 109)' }}>Country: </span>
            <span style={{ float: 'right' }}>{pickedLine.name}</span>
          </Typography>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontSize: { xs: '15px', md: '17px' },
              color: '#202020',
              marginTop: '15px',
            }}
          >
            <span
              style={{
                fontSize: '22px',
                color: '#0053fe',
                verticalAlign: 'middle',
                marginRight: '5px',
              }}
              className="material-icons-round"
            >
              call
            </span>
            <span style={{ color: 'rgb(109 109 109)' }}>Phone number: </span>
            <span style={{ float: 'right' }}>{pickedLine.phone}</span>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReportingFormPhone;
