/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material';

import '../../../pages/styles/matterlist.css';
import '../../styles/datagridStyles.css';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  simpleGoBackButtonStyles,
} from '../../styles';
import { taskDetailsStyles } from './styles';
import TaskCommentBox from './MatterDetailTaskComments';
import StatusChipTasks from '../../shared/matters-list-table/StatusChipTasks';
import { MatterTaskDetail } from '../../../types/matters';
import { formatDate } from '../../../utils/utils';
import {
  matterDetailTaskDetailRequest,
  matterDetailAddTaskComment,
  matterDetailArchiveTask,
  matterDetailsRequest,
} from '../../../api/matters';
import NotificationToast from '../../shared/toast/NotificationToast';
import { LEIAADialog } from '../../inputs';

const MatterDetailTaskDetail = () => {
  const { matterId, taskId } = useParams();
  const location = useLocation();
  const locationPreviousState = location.state?.previous;
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState<MatterTaskDetail | undefined>();
  const [matterDetails, setMatterDetails] = useState(
    location.state?.matterDetails
  );
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);

  const goToMatterTasks = () => {
    if (locationPreviousState && locationPreviousState === 'user-details') {
      navigate(-1);
    } else {
      navigate(`/matters/detail/${task?.matter.id}`, {
        state: { currentStep: 4 },
      });
    }
  };

  const getTaskDetails = async () => {
    setLoading(true);
    try {
      if (!taskId) {
        return;
      }

      const apiTask = await matterDetailTaskDetailRequest(taskId);
      setTask(apiTask);
    } catch (error: any) {
      navigate('/not-found', {
        state: { goBackDashboard: true },
      });
    } finally {
      setLoading(false);
    }
  };

  const getMatterDetailsRequest = async () => {
    setLoading(true);
    try {
      if (!matterDetails && matterId) {
        const matterData = await matterDetailsRequest(matterId);
        setMatterDetails(matterData);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaskDetails();
    getMatterDetailsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const deleteTaskHandler = async () => {
    if (!taskId) {
      return;
    }

    await matterDetailArchiveTask(taskId);
    goToMatterTasks();
  };

  return (
    <Box sx={taskDetailsStyles.pageWrapper}>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description="You are about to archive a task. Are you sure you want to archive it?"
        primaryButtonText="Yes, archive task"
        primaryButtonIcon="archive"
        onPrimaryButtonClick={deleteTaskHandler}
        secondaryButtonText="Cancel"
        secondaryButtonHasIcon={false}
        onSecondaryButtonClick={() => setOpenAlertOpenDialog(false)}
      />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            '& > span': {
              color: '#0053FF',
            },
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Task Details Header */}
          <Box sx={taskDetailsStyles.header.wrapper}>
            <Breadcrumbs
              sx={taskDetailsStyles.header.infoWrapper}
              separator={
                <span className="material-icons-round">arrow_forward_ios</span>
              }
              aria-label="breadcrumb"
            >
              <Box sx={taskDetailsStyles.header.breadcrumbs.wrapper}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  sx={taskDetailsStyles.header.breadcrumbs.link}
                  onClick={() => navigate(`/matters/detail/${task?.matter.id}`)}
                >
                  {task?.matter.title}
                </Link>
                <Typography
                  variant="caption"
                  component="span"
                  sx={taskDetailsStyles.header.breadcrumbs.subtitle}
                >
                  Matter
                </Typography>
              </Box>
              <Box sx={taskDetailsStyles.header.breadcrumbs.wrapper}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  sx={taskDetailsStyles.header.breadcrumbs.link}
                  onClick={() => goToMatterTasks()}
                >
                  Tasks
                </Link>
                <Typography
                  variant="caption"
                  component="span"
                  sx={taskDetailsStyles.header.breadcrumbs.subtitle}
                >
                  Tasks
                </Typography>
              </Box>
              <Box sx={taskDetailsStyles.header.breadcrumbs.wrapper}>
                <Link
                  variant="h4"
                  underline="none"
                  sx={taskDetailsStyles.header.breadcrumbs.lastLink}
                >
                  {task?.title}
                </Link>
                <Typography
                  variant="caption"
                  component="span"
                  sx={taskDetailsStyles.header.breadcrumbs.subtitle}
                >
                  Task
                </Typography>
              </Box>
            </Breadcrumbs>
            <Button
              sx={simpleGoBackButtonStyles}
              variant="text"
              onClick={() => goToMatterTasks()}
            >
              <span className="material-symbols-outlined">arrow_back</span>
              <Typography variant="caption" component="p">
                Back
              </Typography>
            </Button>
          </Box>

          {/* Edit - Archive buttons */}
          <Box sx={{ display: 'flex', width: '100%', gap: '24px' }}>
            <Button
              sx={primaryButtonStyles()}
              variant="contained"
              onClick={() => {
                navigate(`/matters/edit/${matterId}`, {
                  state: {
                    currentStep: 2,
                    matterDetails,
                  },
                });
              }}
              role="button"
            >
              <span className="material-icons-round">edit</span>
              <Typography variant="caption" component="p">
                Edit
              </Typography>
            </Button>
            <Button
              sx={secondaryButtonStyles()}
              variant="contained"
              role="button"
              onClick={() => {
                setOpenAlertOpenDialog(true);
              }}
            >
              <span className="material-icons-round">archive</span>
              <Typography variant="caption" component="p">
                Archive
              </Typography>
            </Button>
          </Box>

          {/* Page Relevant Content */}
          <Box sx={taskDetailsStyles.contentWrapper}>
            {/* Task fields  */}
            <Box sx={taskDetailsStyles.taskFieldsWrapper}>
              {/* Description */}
              <Box sx={taskDetailsStyles.taskIndividualField.wrapper}>
                <Typography
                  variant="caption"
                  component="p"
                  sx={taskDetailsStyles.taskIndividualField.header}
                >
                  Task Description
                </Typography>
                <Typography
                  variant="caption"
                  component="p"
                  sx={taskDetailsStyles.taskIndividualField.field}
                >
                  {task?.description}
                </Typography>
              </Box>

              {/* User */}
              {task?.user && (
                <Box sx={taskDetailsStyles.taskIndividualField.wrapper}>
                  <Typography
                    variant="caption"
                    component="p"
                    sx={taskDetailsStyles.taskIndividualField.header}
                  >
                    User
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    sx={taskDetailsStyles.taskIndividualField.field}
                  >
                    {task.user.name || ''}
                  </Typography>
                </Box>
              )}

              {/* Status */}
              <Box sx={taskDetailsStyles.taskIndividualField.wrapper}>
                <Typography
                  variant="caption"
                  component="p"
                  sx={taskDetailsStyles.taskIndividualField.header}
                >
                  Status
                </Typography>
                <StatusChipTasks
                  status={task?.status || ''}
                  heightValue="40px"
                  wrapperHeight="inherit"
                />
              </Box>

              {/* Deadline */}
              <Box sx={taskDetailsStyles.taskIndividualField.wrapper}>
                <Typography
                  variant="caption"
                  component="p"
                  sx={taskDetailsStyles.taskIndividualField.header}
                >
                  Deadline
                </Typography>
                <Typography
                  variant="caption"
                  component="p"
                  sx={taskDetailsStyles.taskIndividualField.field}
                >
                  {task?.deadline && formatDate(task.deadline)}
                </Typography>
              </Box>
            </Box>

            <TaskCommentBox
              task={task}
              onNewCommentAdded={async (comment) => {
                await matterDetailAddTaskComment(task?.id as any, comment);
                await getTaskDetails();
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default MatterDetailTaskDetail;
