import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Typography,
  createFilterOptions,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import {
  matterInformationFormWrapperStyles,
  matterInformationWrapperStyles,
  matterInterviewQuestionnaireAccordionDetailsStyles,
  matterQuestionnaireAccordionStyles,
  matterQuestionnaireAddButtonWrapperStyles,
  matterQuestionnaireManualQuestionsWrapperStyles,
  matterQuestionnaireRadioGroupWrapperStyles,
  matterQuestionnaireUploadAreaWrapperStyles,
  matterTemplateAddButtonWrapperStyles,
} from '../matter-information-step/styles';
import {
  LEIAAInput,
  LEIAAAutoComplete,
  LEIAASelect,
  LEIAAUploadFileArea,
  LEIAADatePicker,
  LEIAADialog,
  LEIAATimeInput,
} from '../../../../inputs';
import { primaryButtonStyles, secondaryButtonStyles } from '../../../../styles';
import {
  ClientOptionType,
  MatterInterviewee,
  MatterUser,
  SubDivisonOptionType,
  UploadedAllegationQuestion,
  UsersOptionType,
} from '../../../../../types/matters';
import {
  intervieweeOrgsRequest,
  matterAvailableUsersRequest,
  intervieweeSubDivisionsRequest,
} from '../../../../../api/matters';
import {
  matterAddedInterviewInputWrapperStyles,
  matterAddedInterviewerAccordionDetailsStyles,
  matterAddedInterviewerAccordionStyles,
  matterInterviewFormWrapperStyles,
} from './styles';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../../../../styles/textStyles';
import {
  addInterviewQuestionnaireFile,
  addInterviewUploadedFile,
  clearInterviewQuestionnaireFiles,
  removeInterviewQuestionnaireFiles,
  removeInterviewUploadedFile,
  replaceInterviewFilesName,
  replaceInterviewQuestionnaireFiles,
  selectUploadedInterviewQuestionnaireFiles,
} from '../../../../../redux/features/matter-creation/matterInformationSlice';
// eslint-disable-next-line import/no-named-as-default
import {
  useInterviewManagement,
  useQuestionnaireManagement,
} from '../../../../../hooks';
import {
  MatterAddedInterviewsInformation,
  MatterAddedQuestionnaireFileInformation,
  MatterAddedInterviewersInformation,
  SidePanelInformation,
  MatterAddedGeneralInformation,
} from '../../sidepanel-information';
import NotificationToast from '../../../../shared/toast/NotificationToast';
import { getWikiTemplateDetailsRequest } from '../../../../../api/wiki';
import FormStatusChip from '../../../../shared/misc-components/FormStatusChip';

interface MatterInterviewsProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  handleAddMatterInterview: any;
  selectedIndex: any;
  setSelectedIndex: any;
}

const subDivisonFilter = createFilterOptions<SubDivisonOptionType>();
const usersFilter = createFilterOptions<UsersOptionType>();

const MatterInterviews = ({
  control,
  watch,
  setValue,
  unregister,
  handleAddMatterInterview,
  selectedIndex,
  setSelectedIndex,
}: MatterInterviewsProps) => {
  // Selectors
  const uploadedInterviewQuestions: UploadedAllegationQuestion[] = useSelector(
    selectUploadedInterviewQuestionnaireFiles
  );
  const dispatch = useDispatch();

  // State Hooks
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [subDivisons, setSubDivisons] =
    useState<readonly SubDivisonOptionType[]>();
  const [usersOptions, setUsersOptions] =
    useState<readonly UsersOptionType[]>();
  const [isAccordionExpanded, setIsAccordionExpanded] =
    useState<boolean>(false);
  const [users, setUsers] = useState<MatterUser[]>();
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [openConfirmationDialog, setConfirmationDialogOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const templateId = watch('template_id');
  const templateData = watch('template_data');
  // In case format change check update for interview in Backend WARN: Tightly coupled
  const [selectedTimezone, setSelectedTimezone] = useState({
    value: 'Etc/GMT',
    label: '(GMT+0:00) UTC',
    offset: 0,
    abbrev: 'GMT',
    altName: 'British Standard Time',
  });

  const handleCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    setValue(
      `interviews[${index}].whistleblower`,
      !watch(`interviews[${index}].whistleblower`)
    );
  };

  const handleRecordingCheckedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    setValue(
      `interviews[${index}].transcription_enabled`,
      !watch(`interviews[${index}].transcription_enabled`)
    );
  };

  // Watch
  const matterInterviews = watch('interviews');
  const matterUsers = watch('users');
  const matterAddedInterviewers = watch(
    `interviews[${selectedIndex}].interviewer_ids`
  );

  const matterAddedQuestionnaire = watch(
    `interviews[${selectedIndex}].questionnaire`
  );
  const matterAddedFileNames = watch(`interviews[${selectedIndex}].file_names`);
  const [clients, setClients] = useState<readonly ClientOptionType[]>();
  const clientFilter = createFilterOptions<ClientOptionType>();

  // Initial State

  const [
    intervieweeAccordionExpandedState,
    setIntervieweeAccordionExpandedState,
  ] = useState<boolean>(true);

  const matterInfoFields = [
    {
      label: process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
        ? 'Organisation Name'
        : 'Client Name',
      value: watch('client'),
    },
    { label: 'Matter Name', value: watch('name') },
    { label: 'Matter Description', value: watch('description') },
    { label: 'Jurisdiction', value: watch('jurisdictionName') },
    { label: 'Template', value: watch('template') },
  ];

  const handleClickConfirmationDialogOpen = () => {
    setOpenAlertOpenDialog(false);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleReopenDialog = () => {
    setConfirmationDialogOpen(false);
    setOpenAlertOpenDialog(true);
  };

  const handleFetchClients = async () => {
    try {
      const response = await intervieweeOrgsRequest();
      setClients(response as ClientOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchSubDivisions = async (value: any) => {
    try {
      const response = await intervieweeSubDivisionsRequest(value);
      setSubDivisons(response as SubDivisonOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchUsers = async () => {
    try {
      const response = await matterAvailableUsersRequest();
      setUsers(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('interview_category', event.target.value);
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('interview_type', event.target.value);
  };

  const handleFetchTemplate = async () => {
    try {
      if (templateId) {
        await getWikiTemplateDetailsRequest(templateId).then((response) => {
          setValue('template_data', response);
        });
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };
  const handleTimezoneChange = (timezone: any, index: any) => {
    if (timezone) {
      setSelectedTimezone(timezone);
      setValue(
        `interviews[${index}].timezone`,
        `${timezone.abbrev}, ${timezone.label}; ${timezone.value}`
      );
    }
  };

  useEffect(() => {
    if (matterUsers && users) {
      const usersOptionType: UsersOptionType[] = matterUsers.map(
        (user: any) => {
          const foundUser = users.find((u) => u.id === user.user_id);
          return {
            id: uuidv4(),
            name: foundUser ? foundUser.name : '', // If user is found, use the name, otherwise use an empty string
          };
        }
      );

      setUsersOptions(usersOptionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterUsers, users]);

  const {
    handleRemoveMatterField,
    handleReplaceCurrentQuestions,
    handleAddNewQuestions,
    handleAddFile,
    handleDrag,
    handleRemoveFile,
    handleAddManualQuestionAnswer,
    handleRemoveManualQuestionAnswer,
    handleAddTemplateQuestionAnswer,
  } = useQuestionnaireManagement(
    // FORM CONTROLS PROPS
    watch,
    setValue,
    // MATTER QUESTIONNAIRE PROPS, PATH
    matterAddedQuestionnaire,
    'interviews',
    `interviews[${selectedIndex}].questionnaire`,
    // ADD ANSWERS TO QUESTIONNAIRE OR NOT
    false,
    // FILE PROPS AND QUESTIONNAIRE FIELD PATH
    uploadedFile,
    setUploadedFile,
    `interviews[${selectedIndex}].questionnaire`,
    `interviews[${selectedIndex}].file_names`,
    // DISPATCH FUNCTIONS TO CONTROL FILES AND FILENAMES
    addInterviewQuestionnaireFile,
    addInterviewUploadedFile,
    replaceInterviewFilesName,
    replaceInterviewQuestionnaireFiles,
    removeInterviewUploadedFile,
    removeInterviewQuestionnaireFiles,
    undefined /* --> change this to the string path to delete interview question item of the interviews array */,
    'interviews_to_delete' /* --> change this to the string path to delete interview item of the interviews array */,
    // ACCORDIONS STATES PROPS
    setIsAccordionExpanded,
    handleConfirmationDialogClose,
    setOpenAlertOpenDialog,
    setRequestError,
    setShowNotification,
    // TRANSLATOR FOR MATTER OR INTERVIEW QA
    true
  );

  const { handleSelectInterview, handleRemoveInterviewer } =
    useInterviewManagement(
      watch,
      setValue,
      setSelectedIndex,
      matterAddedInterviewers,
      selectedIndex
    );

  useEffect(() => {
    handleFetchClients();
    handleFetchUsers();
    handleFetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update the selectedIndex to point to the last task if it exceeds the valid index range
    if (matterInterviews && matterInterviews.length > 0) {
      if (selectedIndex >= matterInterviews.length) {
        setSelectedIndex(matterInterviews.length - 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterInterviews, selectedIndex]);

  const userIdToObject = (id: number) => {
    const user = users?.filter((u) => u.id === id)[0];
    if (user) {
      return usersOptions?.filter((u) => u.name === user.name)[0];
    }
    return undefined;
  };

  const userIdToName = (id: number) => {
    const user = users?.filter((u) => u.id === id)[0];
    if (user) {
      return user.name;
    }
    return '';
  };

  const handleEmailTemplateChange = (
    event: React.ChangeEvent<{ value: any }>,
    index: number
  ) => {
    const emailTemplateName = event.target.value;
    const template = templateData?.email_template.find(
      (t: any) => t.id === emailTemplateName
    );

    if (template) {
      setValue(`interviews[${index}].email_invitation`, template.message);
    } else {
      setValue(`interviews[${index}].email_invitation`, '');
    }
  };

  const extraOnChange = (value: any) => {
    if (value) {
      handleFetchSubDivisions(value.name);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearInterviewQuestionnaireFiles());
    };
  }, [dispatch]);

  return (
    <Box sx={matterInformationWrapperStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {/* FORM  */}
      <Box sx={matterInformationFormWrapperStyles}>
        <Typography>Add upcoming interview(s) to the matter.</Typography>
        <Box sx={matterInterviewFormWrapperStyles}>
          {matterInterviews && matterInterviews.length > 0
            ? matterInterviews.map((mi: any, index: number) => {
                const handleChangeInterviewers = (selectedUserName: any) => {
                  const userToAdd = users?.find(
                    (user: any) => user.name === selectedUserName
                  );
                  const interviewersIds =
                    watch(`interviews[${index}].interviewer_ids`) || [];

                  if (userToAdd) {
                    interviewersIds.push(userToAdd.id);
                    setValue(
                      `interviews[${index}].interviewer_ids`,
                      interviewersIds
                    );
                  }
                };

                return (
                  selectedIndex === index && (
                    <Fragment key={`${mi.id}-${index}`}>
                      {/* INTERVIEW INFO  */}
                      <Box>
                        <Box sx={matterAddedInterviewInputWrapperStyles}>
                          <Box>
                            <Box>
                              <Typography>1</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>
                              Choose interview category and type
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ paddingLeft: '24px' }}>
                          <LEIAASelect
                            labelText="Interview Category"
                            controllerName={`interviews[${index}].interview_category`}
                            control={control}
                            inputPlaceholder="Choose an interview category"
                            inputHeight="40px !important"
                            inputWidth="100%"
                            handleChange={handleCategoryChange}
                          >
                            <MenuItem value="Scoping">Scoping</MenuItem>
                            <MenuItem value="Witness">Witness</MenuItem>
                          </LEIAASelect>

                          <LEIAASelect
                            labelText="Interview Type"
                            controllerName={`interviews[${index}].interview_type`}
                            control={control}
                            inputPlaceholder="Choose an interview type"
                            inputHeight="40px !important"
                            inputWidth="100%"
                            handleChange={handleTypeChange}
                          >
                            <MenuItem value="In-person">In-person</MenuItem>
                            <MenuItem value="Automated">Automated</MenuItem>
                          </LEIAASelect>
                          {process.env.REACT_APP_DISABLE_AI === 'false' &&
                            watch(`interviews[${index}].interview_type`) ===
                              'In-person' && (
                              <FormControlLabel
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    checked={watch(
                                      `interviews[${index}].transcription_enabled`
                                    )}
                                    onChange={(event) =>
                                      handleRecordingCheckedChange(
                                        event,
                                        selectedIndex
                                      )
                                    }
                                    sx={{
                                      '& .MuiSvgIcon-root': { fontSize: 24 },
                                    }}
                                  />
                                }
                                label={
                                  <InputLabel
                                    data-testid="matter-form-label-id"
                                    sx={{
                                      color: '#464B53',
                                      fontSize: '14px',
                                      ...karlaProRegularFontStyles,
                                      lineHeight: '22px',
                                      display: 'flex',
                                      marginRight: 'auto',
                                    }}
                                  >
                                    Recorded Interview
                                  </InputLabel>
                                }
                                sx={{
                                  borderBottom: '1px solid  #D3ECF6',
                                  width: '100%',
                                  paddingBottom: '10px',
                                  marginLeft: '0px',
                                  justifyContent: 'flex-end',
                                }}
                              />
                            )}
                        </Box>
                      </Box>
                      {/* INTERVIEWEES INFO  */}
                      <Box>
                        <Box sx={matterAddedInterviewInputWrapperStyles}>
                          <Box>
                            <Box>
                              <Typography>2</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>
                              Add individual to be interviewed
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ paddingLeft: '24px' }}>
                          <Fragment key={`${mi.id}-${index}-interviewee`}>
                            {watch('report') && (
                              <FormControlLabel
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    checked={watch(
                                      `interviews[${index}].whistleblower`
                                    )}
                                    onChange={(event) =>
                                      handleCheckedChange(event, selectedIndex)
                                    }
                                    sx={{
                                      '& .MuiSvgIcon-root': { fontSize: 24 },
                                    }}
                                  />
                                }
                                label={
                                  <InputLabel
                                    data-testid="matter-form-label-id"
                                    sx={{
                                      color: '#464B53',
                                      fontSize: '14px',
                                      ...karlaProRegularFontStyles,
                                      lineHeight: '22px',
                                      display: 'flex',
                                      marginRight: 'auto',
                                    }}
                                  >
                                    Whistleblower
                                  </InputLabel>
                                }
                                sx={{
                                  borderBottom: '1px solid  #D3ECF6',
                                  width: '100%',
                                  paddingBottom: '10px',
                                  marginLeft: '0px',
                                  justifyContent: 'flex-end',
                                }}
                              />
                            )}

                            {!watch(`interviews[${index}].whistleblower`) && (
                              <>
                                <LEIAAInput
                                  labelText="First Name"
                                  controllerName={`interviews[${selectedIndex}].interviewee_first_name`}
                                  control={control}
                                  inputPlaceholder="Enter first name"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                />
                                <LEIAAInput
                                  labelText="Last Name"
                                  controllerName={`interviews[${selectedIndex}].interviewee_last_name`}
                                  control={control}
                                  inputPlaceholder="Enter last name"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                />
                                <LEIAAInput
                                  labelText="Job Title"
                                  controllerName={`interviews[${selectedIndex}].interviewee_job_title`}
                                  control={control}
                                  required={false}
                                  inputPlaceholder="Enter job title"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                />
                                <LEIAAAutoComplete
                                  labelText="Organisation"
                                  controllerName={`interviews[${selectedIndex}].interviewee_organisation`}
                                  control={control}
                                  autoCompleteFilter={clientFilter}
                                  autoCompleteOptions={clients}
                                  inputPlaceholder="Enter organisation name"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                  extraOnChange={extraOnChange}
                                />
                                <LEIAAAutoComplete
                                  labelText="Sub-division/ Subsidiary/ Business Unit"
                                  required={false}
                                  controllerName={`interviews[${selectedIndex}].interviewee_subsidiary`}
                                  control={control}
                                  autoCompleteFilter={subDivisonFilter}
                                  autoCompleteOptions={subDivisons}
                                  inputPlaceholder="Enter sub-division/ subsidiary/ business unit"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                />
                                <LEIAAInput
                                  labelText="Employee ID"
                                  controllerName={`interviews[${selectedIndex}].interviewee_employee_id`}
                                  control={control}
                                  required={false}
                                  inputPlaceholder="Enter employee id"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                />
                                <LEIAAInput
                                  labelText="Email Address"
                                  controllerName={`interviews[${selectedIndex}].interviewee_email`}
                                  control={control}
                                  inputPlaceholder="Enter email address"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                  type="email"
                                />
                              </>
                            )}

                            <Box key={`${mi.id}-${index}-date-box`}>
                              <Accordion
                                expanded={intervieweeAccordionExpandedState}
                                onChange={() => {
                                  setIntervieweeAccordionExpandedState(
                                    !intervieweeAccordionExpandedState
                                  );
                                }}
                                sx={matterAddedInterviewerAccordionStyles(
                                  intervieweeAccordionExpandedState
                                )}
                              >
                                <Box>
                                  <AccordionSummary
                                    expandIcon={
                                      <span className="material-icons-round">
                                        arrow_drop_down
                                      </span>
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography>
                                      {watch(
                                        `interviews[${selectedIndex}].interviewee_first_name`
                                      ) === ''
                                        ? 'Current Interview'
                                        : watch(
                                            `interviews[${selectedIndex}].interviewee_first_name`
                                          )}
                                    </Typography>
                                  </AccordionSummary>
                                </Box>
                                <AccordionDetails
                                  sx={
                                    matterAddedInterviewerAccordionDetailsStyles
                                  }
                                >
                                  <Box>
                                    <Box>
                                      <LEIAADatePicker
                                        labelText="Interview Date"
                                        inputPlaceholder="Enter a date"
                                        controllerName={`interviews[${index}].date`}
                                        control={control}
                                      />
                                    </Box>
                                    <Box>
                                      <InputLabel
                                        data-testid="matter-form-label-id"
                                        sx={{
                                          display: 'flex',
                                          padding: '0px 12px',
                                          alignItems: 'flex-start',
                                          alignSelf: 'stretch',
                                          color: '#464B53',
                                          fontSize: '14px',
                                          ...karlaProRegularFontStyles,
                                          lineHeight: '22px',
                                        }}
                                      >
                                        *Interview Time
                                      </InputLabel>
                                      <Box
                                        sx={{
                                          flexDirection: 'row !important',
                                          '& > div': {
                                            width: '100%',
                                          },
                                        }}
                                      >
                                        <LEIAATimeInput
                                          controllerName={`interviews[${index}].start_hour`}
                                          control={control}
                                          inputPlaceholder="Enter start hour"
                                          inputWidth="100%"
                                          inputHeight="40px"
                                        />
                                        <LEIAATimeInput
                                          controllerName={`interviews[${index}].end_hour`}
                                          control={control}
                                          inputPlaceholder="Enter end hour"
                                          inputWidth="100%"
                                          inputHeight="40px"
                                        />
                                      </Box>
                                      <Typography
                                        sx={{
                                          color: '#69707B',
                                          ...karlaProRegularItalicFontStyles,
                                          fontSize: '12px',
                                          fontWeight: 400,
                                          lineHeight: '16px',
                                          letterSpacing: '0.2px',
                                        }}
                                      >
                                        Make sure end hour is greater than start
                                        hour.
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <InputLabel
                                        data-testid="matter-form-label-id"
                                        sx={{
                                          display: 'flex',
                                          padding: '0px 12px',
                                          alignItems: 'flex-start',
                                          alignSelf: 'stretch',
                                          color: '#464B53',
                                          fontSize: '14px',
                                          ...karlaProRegularFontStyles,
                                          lineHeight: '22px',
                                        }}
                                      >
                                        *Timezone
                                      </InputLabel>
                                      <Box className="timezoneSelect">
                                        <TimezoneSelect
                                          value={
                                            watch(
                                              `interviews[${index}].timezone`
                                            ) !== undefined
                                              ? watch(
                                                  `interviews[${index}].timezone`
                                                )
                                                  .split(';')[1]
                                                  .trimStart()
                                              : selectedTimezone
                                          }
                                          onChange={(timezone) =>
                                            handleTimezoneChange(
                                              timezone,
                                              index
                                            )
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          </Fragment>
                        </Box>
                      </Box>
                      {/* INTERVIEWERS INFO  */}
                      <Box>
                        <Box sx={matterAddedInterviewInputWrapperStyles}>
                          <Box>
                            <Box>
                              <Typography>3</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>
                              {watch(`interviews[${index}].interview_type`) ===
                              'In-person'
                                ? 'Add user(s) to conduct the interview'
                                : 'Add user(s) to oversee the interview'}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ paddingLeft: '24px' }}>
                          <LEIAAAutoComplete
                            controllerName=""
                            control={control}
                            autoCompleteFilter={usersFilter}
                            autoCompleteOptions={usersOptions}
                            inputPlaceholder="Search by user to conduct the interview..."
                            inputWidth="100%"
                            inputHeight="40px"
                            hasCreateOption={false}
                            required={
                              (matterAddedInterviewers &&
                                matterAddedInterviewers.length > 0) ||
                              watch(`interviews[${index}].interview_type`) ===
                                'Automated'
                                ? false
                                : undefined
                            }
                            handleChange={handleChangeInterviewers}
                            mapFunction={userIdToObject}
                            noOptionText="No user found"
                            startAdornment={
                              <InputAdornment
                                position="start"
                                sx={{ marginLeft: '12px' }}
                              >
                                <span className="material-icons-round">
                                  search
                                </span>
                              </InputAdornment>
                            }
                          />
                        </Box>
                      </Box>
                      {/* INTERVIEW QUESTIONS INFO  */}
                      <Box>
                        <Box sx={matterAddedInterviewInputWrapperStyles}>
                          <Box>
                            <Box>
                              <Typography>4</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>
                              {watch(`interviews[${index}].interview_type`) ===
                              'In-person'
                                ? 'Prepare the interview outline'
                                : 'Prepare the interview questions'}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            '& > div': {
                              display: 'flex',
                              gap: '24px !important',
                            },
                          }}
                        >
                          <Box>
                            <Box
                              sx={
                                matterQuestionnaireManualQuestionsWrapperStyles
                              }
                            >
                              <Box>
                                {templateData &&
                                  watch('interview_category') &&
                                  watch('interview_type') && (
                                    <>
                                      <Typography>
                                        Do you want to use the template
                                        questions?
                                      </Typography>
                                      <Box
                                        sx={
                                          matterTemplateAddButtonWrapperStyles
                                        }
                                      >
                                        <Button
                                          variant="contained"
                                          sx={{
                                            ...secondaryButtonStyles(),
                                            height: '30px',
                                            padding: '8px 16px',
                                            gap: '8px',
                                          }}
                                          onClick={
                                            handleAddTemplateQuestionAnswer
                                          }
                                        >
                                          <span className="material-icons-round">
                                            add
                                          </span>
                                          <Typography>
                                            Add template questions
                                          </Typography>
                                        </Button>
                                      </Box>
                                    </>
                                  )}
                              </Box>
                              <Box>
                                <Accordion
                                  sx={matterQuestionnaireAccordionStyles}
                                  expanded={isAccordionExpanded}
                                  onChange={() =>
                                    setIsAccordionExpanded(!isAccordionExpanded)
                                  }
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <span className="material-icons-round">
                                        arrow_drop_down
                                      </span>
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography>Questions</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={
                                      matterInterviewQuestionnaireAccordionDetailsStyles
                                    }
                                  >
                                    {matterAddedQuestionnaire &&
                                      matterAddedQuestionnaire.map(
                                        (m: any, qaIndex: number) => {
                                          const questionName = `interviews[${selectedIndex}].questionnaire[${qaIndex}].question`;
                                          const commentName = `interviews[${selectedIndex}].questionnaire[${qaIndex}].comment`;
                                          const hasComment = watch(
                                            `interviews[${selectedIndex}].questionnaire[${qaIndex}].comment`
                                          );

                                          const toggleCommentInput = () => {
                                            if (hasComment !== undefined) {
                                              // If the comment input is being toggled off, remove the .comment field from the data
                                              unregister(
                                                `interviews[${selectedIndex}].questionnaire[${qaIndex}].comment`
                                              );
                                            } else {
                                              setValue(
                                                `interviews[${selectedIndex}].questionnaire[${qaIndex}].comment`,
                                                ''
                                              );
                                            }
                                          };

                                          return (
                                            <Box key={`${m.id}-${qaIndex}}`}>
                                              <Box>
                                                <Typography>
                                                  {qaIndex + 1}
                                                </Typography>
                                              </Box>
                                              <Box>
                                                <Box>
                                                  {/* Render a TextField instead of Typography when question is empty */}
                                                  {m.type !== 'manual' ? (
                                                    <Typography>
                                                      {m.question}
                                                    </Typography>
                                                  ) : (
                                                    <LEIAAInput
                                                      controllerName={
                                                        questionName
                                                      }
                                                      control={control}
                                                      inputPlaceholder="Enter question"
                                                      inputWidth="100%"
                                                      inputHeight="40px"
                                                      required={false}
                                                    />
                                                  )}
                                                </Box>
                                                <Box>
                                                  <Typography>
                                                    {m.source}
                                                  </Typography>
                                                </Box>
                                                {hasComment !== undefined &&
                                                  watch(
                                                    `interviews[${index}].interview_type`
                                                  ) === 'In-person' && (
                                                    <LEIAAInput
                                                      labelText="Notes for interviewer"
                                                      controllerName={
                                                        commentName
                                                      }
                                                      control={control}
                                                      inputPlaceholder="Enter note"
                                                      inputWidth="100%"
                                                      inputHeight="40px"
                                                      required={false}
                                                    />
                                                  )}
                                              </Box>
                                              <Box>
                                                {watch(
                                                  `interviews[${index}].interview_type`
                                                ) === 'In-person' &&
                                                  (hasComment !== undefined ? (
                                                    <IconButton
                                                      onClick={
                                                        toggleCommentInput
                                                      }
                                                      sx={{
                                                        padding: '0px',
                                                        '&:hover': {
                                                          backgroundColor:
                                                            'transparent',
                                                        },
                                                        '& > span': {
                                                          fontSize: '16px',
                                                          lineHeight: '16px',
                                                          color: '#464B53',
                                                        },
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="30"
                                                        viewBox="0 0 16 30"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M13.333 18.4463L12.553 17.6663H2.66634V9.66634H13.333V18.4463ZM13.333 8.33301H2.66634C1.93301 8.33301 1.33301 8.93301 1.33301 9.66634V17.6663C1.33301 18.3997 1.93301 18.9997 2.66634 18.9997H11.9997L14.6663 21.6663V9.66634C14.6663 8.93301 14.0663 8.33301 13.333 8.33301Z"
                                                          fill="#464B53"
                                                        />
                                                        <path
                                                          d="M10.3338 10.5117C10.5424 10.3031 10.8796 10.3031 11.0883 10.5118C11.2921 10.7156 11.2921 11.0577 11.0883 11.2615L8.75191 13.5979L11.0883 15.9343C11.297 16.143 11.297 16.4802 11.0883 16.6889C10.8796 16.8976 10.5424 16.8976 10.3337 16.6889L7.99731 14.3525L5.66091 16.6889C5.45223 16.8976 5.115 16.8976 4.90632 16.6889C4.69763 16.4802 4.69763 16.143 4.90632 15.9343L7.24272 13.5979L4.90632 11.2615C4.69763 11.0528 4.69763 10.7156 4.90632 10.5069C5.115 10.2982 5.45223 10.2982 5.66091 10.5069L7.99735 12.8433L10.3337 10.5118L10.3338 10.5117ZM10.3338 10.5117L10.3691 10.5471M10.3338 10.5117L10.3691 10.5471M10.3691 10.5471C10.5582 10.358 10.8638 10.358 11.053 10.5471C11.2373 10.7314 11.2373 11.0418 11.053 11.2261L8.71655 13.5625L10.3691 10.5471Z"
                                                          fill="#464B53"
                                                          stroke="#464B53"
                                                          strokeWidth="0.1"
                                                        />
                                                      </svg>
                                                    </IconButton>
                                                  ) : (
                                                    <IconButton
                                                      onClick={
                                                        toggleCommentInput
                                                      }
                                                      sx={{
                                                        padding: '0px',
                                                        '&:hover': {
                                                          backgroundColor:
                                                            'transparent',
                                                        },
                                                        '& > span': {
                                                          fontSize: '16px',
                                                          lineHeight: '16px',
                                                          color: '#464B53',
                                                        },
                                                      }}
                                                    >
                                                      <span className="material-icons-round">
                                                        add_comment
                                                      </span>
                                                    </IconButton>
                                                  ))}

                                                <IconButton
                                                  onClick={() =>
                                                    handleRemoveManualQuestionAnswer(
                                                      m.id
                                                    )
                                                  }
                                                  sx={{
                                                    padding: '0px',
                                                    '&:hover': {
                                                      backgroundColor:
                                                        'transparent',
                                                    },
                                                    '& > span': {
                                                      fontSize: '16px',
                                                      lineHeight: '16px',
                                                      color: '#464B53',
                                                    },
                                                  }}
                                                >
                                                  <span className="material-icons-round">
                                                    clear
                                                  </span>
                                                </IconButton>
                                              </Box>
                                            </Box>
                                          );
                                        }
                                      )}
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            </Box>
                            <Box sx={matterQuestionnaireAddButtonWrapperStyles}>
                              <Button
                                variant="contained"
                                sx={{
                                  ...secondaryButtonStyles(),
                                  height: '30px',
                                  padding: '8px 16px',
                                  gap: '8px',
                                }}
                                onClick={handleAddManualQuestionAnswer}
                              >
                                <span className="material-icons-round">
                                  add
                                </span>
                                <Typography>Add new question</Typography>
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {/* INTERVIEW OPTIONALS  */}
                      <Box>
                        <Box sx={matterAddedInterviewInputWrapperStyles}>
                          <Box>
                            <Box>
                              <Typography>5</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>
                              Additional information to interviewers (optional)
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ paddingLeft: '24px' }}>
                          <Typography
                            sx={{
                              color: '#464B53',
                              ...karlaProRegularFontStyles,
                              fontWeight: '400',
                              fontStyle: 'normal',
                              fontSize: '14px',
                              lineHeight: '22px',
                            }}
                          >
                            This information is never visible to interviewees
                          </Typography>
                          <LEIAAInput
                            labelText="Please describe what this interview is for"
                            controllerName={`interviews[${index}].description`}
                            control={control}
                            inputPlaceholder="Enter description"
                            required={false}
                            inputWidth="100%"
                            inputHeight="130px"
                            multiline
                            customClassName="scrollable-content"
                          />
                          {watch(`interviews[${index}].interview_type`) ===
                            'In-person' && (
                            <LEIAAInput
                              labelText="Please add any specific instructions for the interviewer"
                              controllerName={`interviews[${index}].instructions`}
                              control={control}
                              inputPlaceholder="Enter interview instructions"
                              required={false}
                              inputWidth="100%"
                              inputHeight="130px"
                              multiline
                              customClassName="scrollable-content"
                            />
                          )}
                        </Box>
                      </Box>
                      {/* INTERVIEW EMAIL  */}
                      <Box>
                        <Box sx={matterAddedInterviewInputWrapperStyles}>
                          <Box>
                            <Box>
                              <Typography>6</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography>Add the email invitation</Typography>
                          </Box>
                        </Box>
                        <Box sx={{ paddingLeft: '24px' }}>
                          <LEIAASelect
                            labelText="Email Invitation Template"
                            controllerName={`interviews[${index}].interview_email_template`}
                            control={control}
                            required={false}
                            inputPlaceholder="Select an email invitation template"
                            inputHeight="40px !important"
                            inputWidth="100%"
                            handleChange={(e: ChangeEvent<{ value: any }>) =>
                              handleEmailTemplateChange(e, index)
                            }
                          >
                            {templateData &&
                              templateData.email_template
                                .filter(
                                  (mt: any) =>
                                    mt.category ===
                                      watch('interview_category') &&
                                    mt.type === watch('interview_type')
                                )
                                .map((mt: any) => (
                                  <MenuItem key={mt.id} value={mt.id}>
                                    {mt.subject}
                                  </MenuItem>
                                ))}
                          </LEIAASelect>
                          <Typography
                            sx={{
                              color: '#464B53',
                              ...karlaProRegularFontStyles,
                              fontWeight: '400',
                              fontStyle: 'normal',
                              fontSize: '14px',
                              lineHeight: '22px',
                            }}
                          >
                            If you want to choose an email invitation template,
                            please select the interview type and category that
                            have a corresponding email template.
                          </Typography>
                          <LEIAAInput
                            labelText="Email Invitation"
                            controllerName={`interviews[${index}].email_invitation`}
                            control={control}
                            inputPlaceholder="Enter email invitation"
                            required={false}
                            inputWidth="100%"
                            inputHeight="130px"
                            multiline
                            customClassName="scrollable-content"
                          />
                        </Box>
                      </Box>
                      <FormStatusChip
                        trigger={
                          watch(`interviews[${index}].interview_category`) &&
                          watch(`interviews[${index}].interview_type`) &&
                          watch(
                            `interviews[${index}].interviewee_first_name`
                          ) &&
                          watch(`interviews[${index}].interviewee_last_name`) &&
                          watch(`interviews[${index}].interviewee_email`)
                        }
                      />
                    </Fragment>
                  )
                );
              })
            : null}
          {matterInterviews === undefined || matterInterviews.length === 0 ? (
            <Button
              variant="contained"
              sx={{
                ...primaryButtonStyles(),
                height: '30px',
                padding: '8px 16px',
                gap: '8px',
              }}
              onClick={handleAddMatterInterview}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add new interview</Typography>
            </Button>
          ) : null}
        </Box>
      </Box>

      <SidePanelInformation>
        {matterInterviews && matterInterviews.length > 0 && (
          <MatterAddedInterviewsInformation
            selectedIndex={selectedIndex}
            matterInterviews={matterInterviews}
            handleSelectInterview={handleSelectInterview}
            handleRemoveMatterField={handleRemoveMatterField}
          />
        )}
        {uploadedInterviewQuestions &&
          uploadedInterviewQuestions.length > 0 && (
            <MatterAddedQuestionnaireFileInformation
              uploadedInterviewQuestions={uploadedInterviewQuestions}
              showAnswer={false}
            />
          )}
        {matterAddedInterviewers && matterAddedInterviewers.length > 0 && (
          <MatterAddedInterviewersInformation
            matterAddedInterviewers={matterAddedInterviewers}
            userIdToName={userIdToName}
            handleRemoveInterviewer={handleRemoveInterviewer}
          />
        )}

        {matterInfoFields &&
          matterInfoFields.filter(
            (mi) => mi.value !== '' && mi.value !== undefined
          ).length > 0 && (
            <MatterAddedGeneralInformation
              matterInfoFields={matterInfoFields}
            />
          )}
      </SidePanelInformation>

      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description="You are about to upload a new collection of questions/answers. Would you like to replace the current ones, or add new ones?"
        primaryButtonText="Add new questions"
        primaryButtonHelperText="This action will add new uploaded questions to your current ones"
        primaryButtonIcon="add"
        onPrimaryButtonClick={handleAddNewQuestions}
        secondaryButtonText="Replace current questions"
        secondaryButtonHelperText="This action will replace your current questions with the new uploaded ones"
        secondaryButtonIcon="sync"
        onSecondaryButtonClick={handleClickConfirmationDialogOpen}
      />
      <LEIAADialog
        open={openConfirmationDialog}
        onClose={handleConfirmationDialogClose}
        hasBackButton
        title="confirm your request"
        description="You are about to replace your current questions with the new uploaded ones. Are you sure you want to remove the existing questions/answers? This action cannot be undone."
        primaryButtonText="Cancel"
        primaryButtonHasIcon={false}
        secondaryButtonText="Yes, replace current questions"
        secondaryButtonHelperText="This action will replace your current questions with the new uploaded ones"
        secondaryButtonIcon="sync"
        onPrimaryButtonClick={handleConfirmationDialogClose}
        onSecondaryButtonClick={handleReplaceCurrentQuestions}
        onGoBackButtonClick={handleReopenDialog}
        swapButtonStyles
      />
    </Box>
  );
};

export default MatterInterviews;
