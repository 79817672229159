import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { validateStepPayload } from '../utils/utils';
import NotificationToast from '../components/shared/toast/NotificationToast';
import WikiCreationFooter from '../components/wiki/WikiCreationFooter';
import WikiCreationContent from '../components/wiki/WikiCreationContent';
import {
  createWikiTemplateRequest,
  updateWikiTemplateRequest,
} from '../api/wiki';
import { WikiInterviewPayload } from '../types/wiki';
import WikiCreationConfirmation from '../components/wiki/WikiCreationConfirmation';

const steps = [
  'Template',
  'Scoping Interview',
  'Witness Interview',
  'Email',
  'Documents',
];

const TemplatesCreation = () => {
  const location = useLocation();
  const currentStep: number = location.state?.currentStep;
  const templateDetails: any = location.state?.templateDetails;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(currentStep || 0);
  const [previousStep, setPreviousStep] = useState<number | null>(null);
  const [templateCreationFinish, setTemplateCreationFinish] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [visited, setVisited] = useState(new Array(steps.length).fill(false));
  const { handleSubmit, control, watch, setValue } = useForm();
  const [showNotification, setShowNotification] = useState(false);
  const [templateId, setTemplateId] = useState();
  const fieldsData = watch();

  useEffect(() => {
    if (templateDetails) {
      setValue('id', templateDetails.id || '');
      setValue('name', templateDetails.name || '');
      setValue('investigation', templateDetails.topic.name || '');
      setValue('interviewQuestions', templateDetails.interview_template || '');
      setValue('emailTemplate', templateDetails.email_template || '');
      setValue('report', templateDetails.report || '');
      setValue('plans', templateDetails.plans || '');
      setValue('protocols', templateDetails.protocols || '');
    }
  }, [templateDetails, setValue]);

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, []);

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = async () => {
    if (!isLastStep) {
      setPreviousStep(activeStep);
      if (requestError !== null) {
        setRequestError(null);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setVisited((prevVisited) => {
        const newVisited = [...prevVisited];
        newVisited[activeStep] = true; // Mark the current step as visited
        return newVisited;
      });
    } else {
      setTemplateCreationFinish(true);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setPreviousStep(activeStep);
      if (requestError !== null) {
        setRequestError(null);
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setVisited((prevVisited) => {
        const newVisited = [...prevVisited];
        newVisited[activeStep] = false; // Mark the current step as visited
        return newVisited;
      });
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const validateTemplateInformation = (payload: any): WikiInterviewPayload => {
    const allowedFields: (keyof WikiInterviewPayload)[] = [
      'name',
      'investigation',
    ];

    return validateStepPayload<WikiInterviewPayload>(
      payload,
      allowedFields
    ) as WikiInterviewPayload;
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    // SANITIZE EMPTY DATA
    // const sanitizedData = sanitizePayload(data);
    const sanitizedData = data;
    let cleanedData;

    // Validate the payload based on the active step
    switch (activeStep) {
      case 0:
        cleanedData = validateTemplateInformation(sanitizedData);
        break;
      default:
        cleanedData = sanitizedData;
        break;
    }

    try {
      if (!templateDetails) {
        if (activeStep === 0) {
          const response = await createWikiTemplateRequest(cleanedData);
          setTemplateId(response);
        } else {
          await updateWikiTemplateRequest(templateId, cleanedData, activeStep);
        }
      } else {
        await updateWikiTemplateRequest(
          templateDetails.id,
          cleanedData,
          activeStep
        );
      }

      handleNext();
      if (requestError !== null) setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        position: 'relative',
        '& > form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            padding: '48px 32px',
            flex: 1,
          },

          width: '100%',
        },
      }}
      id="creation-box"
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={handleCloseNotification}
        />
      )}
      {!templateCreationFinish ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <WikiCreationContent
            activeStep={activeStep}
            steps={steps}
            visited={visited}
            control={control}
            watch={watch}
            setValue={setValue}
            currentStep={currentStep}
          />
          <WikiCreationFooter
            handleCancel={handleCancel}
            handlePrevious={handlePrevious}
            activeStep={activeStep}
            isLastStep={isLastStep}
          />
        </form>
      ) : (
        <WikiCreationConfirmation isEdition={currentStep !== undefined} />
      )}
    </Box>
  );
};

export default TemplatesCreation;
