import { Box, Typography } from '@mui/material';
import { interviewWeekDayItemWrapper } from './styles';
import { getDateReadable } from '../../../utils/utils';

interface InterviewWeekDayItemProps {
  interviewDay: string;
}

const InterviewWeekDayItem = ({ interviewDay }: InterviewWeekDayItemProps) => {
  return (
    <Box sx={interviewWeekDayItemWrapper}>
      <Box>
        <Typography>
          {getDateReadable(interviewDay).toLocaleString('en-US', {
            weekday: 'short',
          })}
        </Typography>
        <Typography variant="h4">
          {getDateReadable(interviewDay).getDate()}
        </Typography>
      </Box>
    </Box>
  );
};

export default InterviewWeekDayItem;
