import React from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { Box } from '@mui/material';

interface PDFDisplayFraudProps {
  byteStream: any;
  currentPage: any;
  setTotalNumPages: any;
  indicator: any;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface HighlightBoxesProps {
  currentPage: number;
  indicator: any;
}

const HighlightBoxes: React.FC<HighlightBoxesProps> = ({
  currentPage,
  indicator,
}) => {
  const color = indicator.type === 'TRUST' ? 'green' : '#f57373';

  const elements = indicator.metadata.data
    .filter((item: any) => item.page_id === currentPage - 1)
    .map((item: any) => item.bbox)
    .flat();

  // const uiHeight =
  //   document.querySelector('.react-pdf__Document')?.clientHeight || 0;

  return (
    <div className="highlight-container">
      {elements.map((highlight: any, index: any) => {
        return (
          <div
            key={`highlight_${index}`}
            style={{
              left: `${highlight.x}px`,
              top: `${842 - (highlight.y + highlight.height)}px`,
              width: `${highlight.width}px`,
              height: `${highlight.height}px`,
              position: 'absolute',
              backgroundColor: color,
              opacity: 0.5,
              border: `2px solid ${color}`,
            }}
          />
        );
      })}
    </div>
  );
};

const PDFDisplayFraud: React.FC<PDFDisplayFraudProps> = ({
  byteStream,
  currentPage,
  setTotalNumPages,
  indicator,
}) => {
  // pdf viewer set up
  const onDocumentLoadSuccess = (document: any) => {
    const { numPages } = document;
    setTotalNumPages(numPages);
  };

  const pdfBlob = new Blob([byteStream], { type: 'application/pdf' });
  const pdfUrl = URL.createObjectURL(pdfBlob);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        overflowY: 'scroll',
        alignSelf: 'center',
      }}
      id="doc-fraud-box"
      className="scrollable-content"
    >
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Box />}
        error={<Box />}
      >
        <Page height={842} pageNumber={currentPage}>
          {indicator && (
            <HighlightBoxes currentPage={currentPage} indicator={indicator} />
          )}
        </Page>
      </Document>
    </Box>
  );
};

export default PDFDisplayFraud;
