import { karlaProRegularFontStyles } from '../../../styles/textStyles';

// eslint-disable-next-line import/prefer-default-export
export const formStatusChipStyles = {
  display: 'flex !important',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  textAlign: 'center !important',
  gap: '8px',
  borderRadius: '16px',
  backgroundColor: '#FFF',
  boxShadow: 'none',
  height: '30px',
  padding: '8px 16px',
  marginLeft: 'auto',
  width: '120px !important',

  '& > p': {
    textAlign: 'center',
    fontSize: '16px',
    ...karlaProRegularFontStyles,
    letterSpacing: '0.2px',
    display: 'flex',
    alignItems: 'center !important',
  },
  '& > p > span': {
    marginRight: '5px',
  },
};
