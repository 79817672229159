export const mainLoginContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
};

export const mainFormWrapperStyles = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '48px 10px',
  gap: '10px',
  width: '480px',
  maxHeight: '777px',
  background: '#FFFFFF',
  border: '1px solid #D3ECF7',
  boxShadow: '0px 0px 45px rgba(0, 94, 253, 0.08)',
  borderRadius: '24px',
  flex: 'none',
  order: 0,
  flexGrow: 0,
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '48px',
    width: '352px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '121px',
      width: '100%',
    },
  },
};
