import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../styles/textStyles';

export const userDetailsPageWrapperStyles = {
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: '48px 32px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',
};

export const userDetailsHeaderWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  '& > div': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export const userDetailsHeaderInfoWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    height: '40px',
    padding: '10px 0px',
    alignItems: 'flex-start',
    gap: '5px',
    '& > h4': {
      ...sofiaProRegularFontStyles,
      color: '#202020',
      fontSize: '18px',
      fontWeight: 700,
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
  },
  '& > div:nth-of-type(2)': {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    '& > span': {
      ...karlaProRegularFontStyles,
      color: '#949CA9',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.2px',
    },
  },
};

export const userDetailsMainContentStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flex: '1 0 0',
  alignSelf: 'stretch',
  width: '100%',
  gap: 'inherit',
  overflowY: 'overlay',
  '& > div:nth-of-type(1)': {
    width: '100%',
  },
  '& > div:nth-of-type(2), & > div:nth-of-type(3)': {
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    borderRadius: '24px',
    border: '1px solid #D3ECF6',
    backgroundColor: '#FFF',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      flexDirection: 'column',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '21px',
        width: '100%',
        '& > h5': {
          ...karlaProRegularFontStyles,
          color: '#140070',
          fontSize: '17px',
          lineHeight: '30px',
        },
      },
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      padding: '8px 0px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '4px',
      width: '100%',
    },
  },
};

export const userDetailsFieldsWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
  },
  '& > div:nth-of-type(2)': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '24px',
  },
};

export const userDetailsSingularFieldWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    '& > p': {
      ...karlaProRegularFontStyles,
      color: '#464B53',
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  '& > div:nth-of-type(2)': {
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    padding: '8px 0px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '& > *': {
        color: '#69707B',
      },
      '& > p': {
        fontSize: '14px',
        fontFamily: 'Karla',
        lineHeight: '22px',
      },
    },
  },
};
