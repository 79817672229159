import { LiveKitRoom } from '@livekit/components-react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getLivekitTokenRequest } from '../api/livekit';
import { LEIAAPreJoin } from './interview/LEIAAPreJoin';
import {
  interviewPreJoinContainerStyles,
  interviewPreJoinContentStyles,
  interviewPreJoinCopyStyles,
  interviewRoomContainerStyles,
} from './styles/interview';
import LEIAAInterviewGuidelines from '../components/interview/preroom/LEIAAInterviewGuidelines';
import { userMeDetailsRequest } from '../api/auth';
import { User } from '../types/auth';
import { interviewRoomDetailsRequest } from '../api/interviews';
import { LEIAAVideoConferenceInPerson } from '../components/interview/video-conference/LEIAAVideoConferenceInPerson';
import { LEIAAVideoConferenceAutomated } from '../components/interview/video-conference/LEIAAVIdeoConferenceAutomated';
import NotificationToast from '../components/shared/toast/NotificationToast';

const LEIAAInterview = () => {
  const { room, token } = useParams();
  const navigate = useNavigate();
  const [joinRoom, setJoinRoom] = useState(false);
  const [video, setPreRoomVideo] = useState(false);
  const [audio, setPreRoomAudio] = useState(false);
  const serverUrl = process.env.REACT_APP_INTERVIEW_SERVICE_LIVEKIT_WS_URL;
  const accessToken = localStorage.getItem('livekit_token');
  const [interviewDetails, setInterviewDetails] = useState<any>();
  const [meUser, setMeUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleJoinRoom = async () => {
    try {
      await getLivekitTokenRequest(interviewDetails.id);
      setJoinRoom(true);
      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      console.error(error);
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleGetInterviewDetails = async () => {
    try {
      if (room) {
        const response = await interviewRoomDetailsRequest(room);
        setInterviewDetails(response);
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const getMeDetails = async () => {
    try {
      if (token && token !== 'staff') {
        localStorage.setItem('authToken', token);
      }
      const meDetails = await userMeDetailsRequest('GET');
      setMeUser(meDetails.user);
      return meDetails;
    } catch (error: unknown) {
      console.error(error);
    }
    return null;
  };

  const getPreRoomInformation = async () => {
    await handleGetInterviewDetails();
    await getMeDetails();
    setLoading(false);
  };

  useEffect(() => {
    getPreRoomInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disconnectedHandler = async () => {
    navigate('/interview/end', {
      state: { interviewDetails, isWitness: token && token !== 'staff' },
    });
  };

  return !joinRoom ? (
    <Box id="pre-join-wrapper" sx={interviewPreJoinContainerStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={handleCloseNotification}
        />
      )}
      <Box sx={interviewPreJoinContentStyles}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xl={4} md={5} xs={12}>
              <Box sx={interviewPreJoinCopyStyles}>
                <Box>
                  <Typography component="h4">
                    {interviewDetails?.name}
                  </Typography>
                </Box>
                <LEIAAInterviewGuidelines
                  isAutomated={interviewDetails?.type !== 'In-person'}
                />
              </Box>
            </Grid>
            <Grid item xl={8} md={7} xs={12}>
              <LEIAAPreJoin
                data-lk-theme="default"
                onSubmit={handleJoinRoom}
                defaults={{ videoEnabled: false, audioEnabled: false }}
                setPreRoomVideo={setPreRoomVideo}
                setPreRoomAudio={setPreRoomAudio}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={interviewRoomContainerStyles}>
      {accessToken && (
        <LiveKitRoom
          data-lk-theme="default"
          serverUrl={serverUrl}
          token={accessToken}
          connect
          video={video}
          audio={audio}
          onDisconnected={disconnectedHandler}
        >
          {interviewDetails.type === 'In-person' ? (
            <LEIAAVideoConferenceInPerson
              interviewDetails={interviewDetails}
              currentUser={meUser}
            />
          ) : (
            <LEIAAVideoConferenceAutomated
              interviewDetails={interviewDetails}
              currentUser={meUser}
            />
          )}
        </LiveKitRoom>
      )}
    </Box>
  );
};

export default LEIAAInterview;
