import { Box, ListItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  allocatedUsersListItemStyles,
  tasksContentWrapperStyles,
  userContentWrapperStyles,
} from './styles';
import { AllocatedUsersItem } from './types';

interface AllocatedUsersListItemProps {
  allocatedUser: AllocatedUsersItem;
}

const AllocatedUsersListItem = ({
  allocatedUser,
}: AllocatedUsersListItemProps) => {
  const navigate = useNavigate();

  const handleDetailUser = (_user: any) => {
    navigate(`/user-details/${_user?.id}`, {
      state: { user: _user },
    });
  };

  return (
    <ListItem
      onClick={() => {
        handleDetailUser(allocatedUser);
      }}
      sx={allocatedUsersListItemStyles}
    >
      <Box>
        <Box sx={tasksContentWrapperStyles}>
          <Box>
            <Box>
              <Typography>{allocatedUser.completed_tasks}</Typography>
              <Typography>/{allocatedUser.total_tasks}</Typography>
            </Box>
            <Box>
              <Typography>completed tasks</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={userContentWrapperStyles}>
          <Box>
            <Typography>{allocatedUser.name}</Typography>
            {allocatedUser.assigned_matters !== undefined ? (
              <Typography>
                {`${allocatedUser.assigned_matters} assigned matters`}
              </Typography>
            ) : (
              <Typography>
                {`${allocatedUser.assigned_tasks} assigned tasks`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

export default AllocatedUsersListItem;
