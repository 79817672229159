import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import NotificationToast from '../../../components/shared/toast/NotificationToast';
import { getMyDashboardRequest } from '../../../api/general';
import InterviewListTable from '../../../components/shared/interviews-list-table/InterviewListTable';
import { InterviewsListItem } from '../../../components/shared/interviews-list-table/types';
import { matterDetailsRequest } from '../../../api/matters';
import ListButtons from '../../../components/shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../../../components/shared/resource-page-header/styles';
import { useInterviewPermissions } from '../../../hooks/usePermissions';
import { batchDeleteInterviewRequest } from '../../../api/interviews';
import CreationNotification from '../../../components/shared/creation-notification/CreationNotification';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import {
  a11yProps,
  CustomTabPanel,
} from '../../../components/matters/matter-detail/MatterDetailDocuments';
import LEIAAContext from '../../../context/context';

const MyInterviews = () => {
  const interviewPerms = useInterviewPermissions();
  const navigate = useNavigate();
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [pastFilter, setPastFilter] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [loadingList, setLoadingList] = useState(true);
  const [listData, setListData] = useState(null);
  const [selectedInterview, setSelectedInterview] =
    useState<InterviewsListItem | null>(null);
  const [checkedInterviews, setCheckedInterviews] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const { user } = useContext(LEIAAContext);

  const handleFetchListData = async (
    searchText = '',
    mine = tabValue === 0,
    filter = pastFilter
  ) => {
    setLoadingList(true);
    try {
      const response = await getMyDashboardRequest(
        'interviews',
        searchText,
        filter,
        mine
      );
      setListData(response);
      setLoadingList(false);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setShowErrorNotification(true);
        setRequestError(error.response.data.message);
      } else {
        setShowErrorNotification(true);
        setRequestError('My interviews error');
      }
    }
  };

  const handleClosedReportChange = () => {
    const searchText = inputRef.current?.value || '';
    const newValue = !pastFilter;
    setPastFilter(newValue);
    handleFetchListData(searchText, tabValue === 0, newValue);
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchListData(searchText, tabValue === 0, pastFilter);
  };
  const handleEditInterview = async () => {
    try {
      if (selectedInterview?.matterId) {
        matterDetailsRequest(selectedInterview.matterId.toString()).then(
          (response) => {
            navigate(`/matters/edit/${selectedInterview?.matterId}`, {
              state: {
                currentStep: 4,
                matterDetails: response,
                previous: 'my-dashboard-interviews',
              },
            });
          }
        );
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleJoinInterview = () => {
    if (selectedInterview?.status !== 'Conducted') {
      navigate(`${selectedInterview?.link}`);
    } else {
      navigate('/interview/end', {
        state: { interviewDetails: selectedInterview },
      });
    }
  };

  const handleDetailInterview = (_interview: any) => {
    matterDetailsRequest(_interview.matterId.toString()).then((response) => {
      navigate(`/interviews/${_interview?.id}`, {
        state: {
          interview: _interview,
          matter: response,
          previous: 'my-dashboard-interviews',
        },
      });
    });
  };

  const handleBatchDeleteInterviews = async () => {
    try {
      await batchDeleteInterviewRequest(checkedInterviews);
      await handleFetchListData('', tabValue === 0, pastFilter);
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchListData('', tabValue === 0, pastFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    handleFetchListData('', newValue === 0, pastFilter);
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}

      {showArchiveNotification && (
        <CreationNotification
          title="Interview archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            borderBottom: '1px solid #949CA9',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& > div': {
                '& > div': {
                  '& > button': {
                    display: 'flex',
                    width: '300px',
                    padding: '8px 24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '24px 24px 0px 0px',
                    '&.Mui-selected': {
                      backgroundColor: '#F5FAFD',
                      color: '#0053FF',
                    },

                    color: '#69707B',
                    textTransform: 'none',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                '& > span.MuiTabs-indicator': {
                  backgroundColor: '#0053FF',
                },
              },
            }}
          >
            <Tab label="My interviews" {...a11yProps(0)} />
            {['Admin', 'Superuser', 'Leader'].includes(user?.role) && (
              <Tab label="All assigned matter interviews" {...a11yProps(1)} />
            )}
          </Tabs>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            paddingLeft: '18px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Checkbox
                  checked={pastFilter}
                  onChange={() => handleClosedReportChange()}
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 24 },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: '#464B53',
                    fontSize: '16px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                    display: 'flex',
                    marginRight: 'auto',
                  }}
                >
                  Past interviews
                </Typography>
              }
            />
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by interviewee..."
              sx={{
                width: '250px !important',
                ...homePortalSearchInputStyles,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
            {checkedInterviews.length > 0 &&
              (interviewPerms.change || interviewPerms.delete) && (
                <ListButtons
                  deleteHandler={
                    interviewPerms.delete && handleBatchDeleteInterviews
                  }
                  editHandler={interviewPerms.change && handleEditInterview}
                  interviewHandler={handleJoinInterview}
                  checkedResources={checkedInterviews}
                  resourceName="interview"
                />
              )}
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <CustomTabPanel value={tabValue} index={0}>
            <InterviewListTable
              interviewsListData={listData}
              loading={loadingList}
              listClass="interviews-list"
              setCheckedInterviews={setCheckedInterviews}
              setSelectedInterview={setSelectedInterview}
              handleDetailInterview={handleDetailInterview}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <InterviewListTable
              interviewsListData={listData}
              loading={loadingList}
              listClass="interviews-list"
              setCheckedInterviews={setCheckedInterviews}
              setSelectedInterview={setSelectedInterview}
              handleDetailInterview={handleDetailInterview}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default MyInterviews;
