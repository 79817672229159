import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, createFilterOptions, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { matterCreationHeaderWrapperStyles } from '../components/matters/matter-creation/styles';
import { simpleGoBackButtonStyles } from '../components/styles';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterQuestionnaireUploadAreaWrapperStyles,
} from '../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import UserCreationFooter from '../components/user-profile/UserCreationFooter';
import NotificationToast from '../components/shared/toast/NotificationToast';
import 'react-phone-number-input/style.css';
import {
  LEIAAAutoComplete,
  LEIAADialog,
  LEIAAUploadFileArea,
} from '../components/inputs';
import {
  SidePanelInformation,
  MatterAddedDocumentsInformation,
} from '../components/matters/matter-creation/sidepanel-information';
import { createDocumentRequest, investigationTopicsRequest } from '../api/wiki';
import { InvestigationOptionType } from '../types/wiki';
import WikiRepositoryConfirmation from '../components/wiki/WikiRepositoryConfirmation';

interface FileWithId extends File {
  id: string;
  uploaded: boolean;
}

const topicsFilter = createFilterOptions<InvestigationOptionType>();

const DocumentCreate = () => {
  const navigate = useNavigate();
  const [documentCreationFinish, setDocumentCreationFinish] = useState(false);
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [repeatedFiles, setRepeatedFiles] = useState<FileWithId[]>([]);
  const { handleSubmit, control, watch, setValue } = useForm();
  const templateReport = watch('files');
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [topics, setTopics] = useState<InvestigationOptionType[]>();

  const handleFetchInvestigationTopics = async () => {
    try {
      const response = await investigationTopicsRequest();
      setTopics(response as InvestigationOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchInvestigationId = async (investigation: string) => {
    try {
      const response = await investigationTopicsRequest();
      const selectedInvestigation = response.find(
        (topic) => topic.name === investigation
      );
      return selectedInvestigation ? selectedInvestigation.id : null;
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
      return null;
    }
  };

  useEffect(() => {
    handleFetchInvestigationTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    try {
      const investigationName = data.investigation;
      const investigationId = await handleFetchInvestigationId(
        investigationName
      );

      const updatedData = {
        ...data,
        investigation: investigationId,
      };

      await createDocumentRequest(updatedData);
      setDocumentCreationFinish(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const updateDocsUploadedStatus = (files: FileWithId[]) => {
    return files.map((file: FileWithId) => ({
      id: file.id,
      name: file.name,
      size: file.size,
      type: file.type,
      // Add other properties of the File object that you need
      uploaded: true,
    }));
  };

  const handleDocUpload = () => {
    if (templateReport && templateReport.length > 0) {
      const updatedFiles = updateDocsUploadedStatus(templateReport);
      setValue('files', updatedFiles);
    }
  };

  useEffect(() => {
    handleDocUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFiles = (fileList: FileList) => {
    let existingFiles: FileWithId[] = [];

    if (fileList.length === 0) return;

    if (templateReport && templateReport.length > 0) {
      existingFiles = templateReport.filter((md: FileWithId) =>
        Array.from(fileList).some(
          (selectedFile) => md.name === selectedFile.name
        )
      );
      setRepeatedFiles(existingFiles);
    }

    if (existingFiles.length > 0) {
      setOpenAlertOpenDialog(true);
    } else {
      const filesArray: FileWithId[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        const fileId = uuidv4();
        const fileWithId = Object.assign(file, { id: fileId, uploaded: false });
        filesArray.push(fileWithId);
      }

      setValue('files', [...(templateReport || []), ...filesArray]);
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.target.files as FileList;
    handleUploadFiles(fileList);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const fileList = e.dataTransfer.files;
      handleUploadFiles(fileList);
    }
  };

  const handleRemoveFile = (id: string | number) => {
    setValue(
      'files',
      (templateReport || []).filter((file: FileWithId) => file.id !== id)
    );
    if (typeof id === 'number') {
      const filesIdsToDelete = watch('files_to_delete') || [];
      setValue('files_to_delete', [...filesIdsToDelete, id]);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        position: 'relative',
        '& > form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            padding: '48px 32px',
            flex: 1,
          },

          width: '100%',
        },
      }}
      id="creation-box"
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {!documentCreationFinish ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box sx={matterCreationHeaderWrapperStyles}>
              <Box>
                <Box>
                  <Typography>New Document</Typography>
                </Box>
                <Button
                  sx={simpleGoBackButtonStyles}
                  variant="text"
                  onClick={handleCancel}
                >
                  <span className="material-symbols-outlined">arrow_back</span>
                  <Typography variant="caption" component="p">
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box sx={matterInformationWrapperStyles}>
              <Box sx={matterInformationFormWrapperStyles}>
                <Typography>Add new documents.</Typography>
                <Box sx={matterInformationInputWrapperStyles}>
                  <Box>
                    <LEIAAAutoComplete
                      labelText="Investigation Topic"
                      controllerName="investigation"
                      control={control}
                      autoCompleteFilter={topicsFilter}
                      autoCompleteOptions={topics}
                      inputPlaceholder="Enter investigation topic (e.g. Fraud, Theft, etc.)"
                      inputWidth="100%"
                      inputHeight="40px"
                    />
                  </Box>
                  <Box>
                    <LEIAAUploadFileArea
                      title="Select a document to import."
                      subTitle="The imported file will be then possible to download for consultation."
                      handleDrag={handleDrag}
                      handleAddFile={handleAddFile}
                      uploadAreaStyles={{
                        ...matterQuestionnaireUploadAreaWrapperStyles,
                        paddingLeft: '0px',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <SidePanelInformation>
                {templateReport && templateReport.length > 0 && (
                  <MatterAddedDocumentsInformation
                    matterDocuments={templateReport}
                    handleRemoveFile={handleRemoveFile}
                  />
                )}
              </SidePanelInformation>
              <LEIAADialog
                open={openAlertOpenDialog}
                onClose={() => setOpenAlertOpenDialog(false)}
                title="alert"
                description="We have detected similar existing files. Please change the final name if you want to add this file."
                simpleButtonText="Ok"
                onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
                isTextAlign
              />
            </Box>
          </Box>
          <UserCreationFooter />
        </form>
      ) : (
        <WikiRepositoryConfirmation />
      )}
    </Box>
  );
};

export default DocumentCreate;
