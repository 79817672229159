import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import {
  sortButtonsPaperWrapperStyles,
  sortButtonsWrapperStyles,
} from './styles';

export interface ActivityHistorySortButtonsProps {
  typeOfData: 'MATTERS' | 'TASKS';
  onMatterFilterChange: (filterName: string, filterValue: string) => void;
  onTaskFilterChange: (filterName: string, filterValue: string) => void;
  onFilterGet: (filter: string, filterName: string) => string;
}

const ActivityHistorySortButtons = ({
  typeOfData,
  onMatterFilterChange,
  onTaskFilterChange,
  onFilterGet,
}: ActivityHistorySortButtonsProps) => {
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const openStatusMenu = Boolean(statusAnchorEl);
  const openSortMenu = Boolean(sortAnchorEl);

  const buttonTitle =
    typeOfData === 'MATTERS' ? 'Matter Status' : 'Task Status';
  const [matterStatusChecked, setmatterStatusChecked] = useState([
    false,
    false,
    false,
  ]);
  const [selectedSortMatters, setSelectedSortMatters] = useState('');

  const [taskStatusChecked, setTaskStatusChecked] = useState([
    false, // All
    false, // Active
    false, // Archived
    false, // To Do
    false, // In Progress
    false, // Blocked
    false, // In Review
    false, // Done
  ]);
  const [taskStatusIndeterminate, setTaskStatusIndeterminate] = useState(false);
  const [selectedSortTasks, setSelectedSortTasks] = useState('');

  const handleSortMattersChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const { value } = event.currentTarget.childNodes[0] as HTMLInputElement;
    if (value === selectedSortMatters) {
      setSelectedSortMatters('');
      onMatterFilterChange('sort', '');
    } else if (value) {
      setSelectedSortMatters(value);
      onMatterFilterChange('sort', value);
    }
  };

  const handleSortTasksChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const { value } = event.currentTarget.childNodes[0] as HTMLInputElement;
    if (value === selectedSortTasks) {
      setSelectedSortTasks('');
      onTaskFilterChange('sort', '');
    } else if (value) {
      setSelectedSortTasks(value);
      onTaskFilterChange('sort', value);
    }
  };

  const handleChangeMatterStatus =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newChecked = [...matterStatusChecked];
      newChecked[index] = event.target.checked;

      if (index === 0) {
        // All checkbox
        newChecked.fill(event.target.checked);
        onMatterFilterChange('status', '');
      } else {
        // Active Matter or Archived Matter checkbox
        const [, activeMatterChecked, archivedMatterChecked] = newChecked;

        if (!activeMatterChecked || !archivedMatterChecked) {
          newChecked[0] = false;
          if (activeMatterChecked) {
            onMatterFilterChange('status', 'active');
          } else if (archivedMatterChecked) {
            onMatterFilterChange('status', 'archived');
          } else {
            onMatterFilterChange('status', '');
          }
        } else if (activeMatterChecked && archivedMatterChecked) {
          newChecked[0] = true;
          onMatterFilterChange('status', '');
        }
      }

      setmatterStatusChecked(newChecked);
    };

  const handleChangeTaskStatus =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newTaskStatusChecked = [...taskStatusChecked];
      newTaskStatusChecked[index] = event.target.checked;

      const nestedStatuses = [
        'To Do',
        'In Progress',
        'Blocked',
        'In Review',
        'Done',
      ];

      const [allChecked, activeChecked, archivedChecked, ...nestedChecked] =
        newTaskStatusChecked;

      const trueStatuses = nestedStatuses.filter((_, i) => nestedChecked[i]);

      // Handle All checkbox
      if (index === 0) {
        if (allChecked) {
          newTaskStatusChecked.fill(true);
          onTaskFilterChange('status', '');
          onTaskFilterChange('report', '');
        } else {
          newTaskStatusChecked.fill(false);
          onTaskFilterChange('status', '');
          onTaskFilterChange('report', '');
        }
      }

      // Handle Active checkbox
      if (index === 1) {
        if (!activeChecked) {
          newTaskStatusChecked.fill(false, 3, 8);
          if (archivedChecked) {
            newTaskStatusChecked[0] = false;
            onTaskFilterChange('status', 'archived');
          } else {
            onTaskFilterChange('status', '');
            onTaskFilterChange('report', '');
          }
        } else {
          newTaskStatusChecked.fill(true, 3, 8);
          if (!archivedChecked) {
            onTaskFilterChange('status', 'active');
            onTaskFilterChange('report', nestedStatuses.join(';'));
          } else {
            newTaskStatusChecked[0] = true;
            onTaskFilterChange('status', '');
            onTaskFilterChange('report', '');
          }
        }
      }

      // Handle Archived checkbox
      if (index === 2) {
        if (!archivedChecked) {
          if (activeChecked) {
            newTaskStatusChecked[0] = false;
            onTaskFilterChange('status', 'active');
          } else {
            onTaskFilterChange('status', '');
            onTaskFilterChange('report', '');
          }
        } else if (activeChecked) {
          if (trueStatuses.length === 5) {
            newTaskStatusChecked[0] = true;
            onTaskFilterChange('status', '');
            onTaskFilterChange('report', '');
          } else {
            onTaskFilterChange('status', '');
            const currentReportFilter = onFilterGet('tasks', 'report');
            onTaskFilterChange('report', currentReportFilter);
          }
        } else {
          onTaskFilterChange('report', '');
          onTaskFilterChange('status', 'archived');
        }
      }

      // Handle Nested checkboxes
      if (index >= 3 && index <= 7) {
        newTaskStatusChecked[index] = event.target.checked;
        const nestedCheckedCount = nestedChecked.filter(
          (isChecked) => isChecked
        ).length;

        if (nestedCheckedCount === 0 && activeChecked) {
          newTaskStatusChecked[1] = false;
          if (archivedChecked) {
            onTaskFilterChange('status', 'archived');
            onTaskFilterChange('report', '');
          } else {
            onTaskFilterChange('status', '');
            onTaskFilterChange('report', '');
          }
        } else if (nestedCheckedCount === 1 && !activeChecked) {
          newTaskStatusChecked[1] = true;
          if (archivedChecked) {
            onTaskFilterChange('status', '');
            onTaskFilterChange('report', nestedStatuses[index - 3]);
          } else {
            onTaskFilterChange('status', 'active');
            onTaskFilterChange('report', nestedStatuses[index - 3]);
          }
        } else if (event.target.checked) {
          if (nestedCheckedCount === 5 && !allChecked && archivedChecked) {
            newTaskStatusChecked[0] = true;
            onTaskFilterChange('status', '');
            onTaskFilterChange('report', '');
          } else {
            const currentReportFilter = onFilterGet('tasks', 'report');
            const updatedReportFilter = currentReportFilter
              ? `${currentReportFilter};${nestedStatuses[index - 3]}`
              : nestedStatuses[index - 3];
            onTaskFilterChange('report', updatedReportFilter);
          }
        } else {
          if (newTaskStatusChecked[0] && archivedChecked) {
            newTaskStatusChecked[0] = false;
          }
          if (!allChecked) {
            onTaskFilterChange('report', trueStatuses.join(';'));
          }
        }
      }

      const isIndeterminate =
        !newTaskStatusChecked[0] &&
        (newTaskStatusChecked[1] || newTaskStatusChecked[2]);
      setTaskStatusIndeterminate(isIndeterminate);

      // Update the state
      setTaskStatusChecked(newTaskStatusChecked);
    };

  const handleStatusClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  return (
    <Box sx={sortButtonsWrapperStyles}>
      <Button
        id="status-button"
        aria-controls={openStatusMenu ? 'status-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openStatusMenu ? 'true' : undefined}
        onClick={handleStatusClick}
        endIcon={
          openStatusMenu ? (
            <span className="material-icons-round">arrow_drop_up</span>
          ) : (
            <span className="material-icons-round">arrow_drop_down</span>
          )
        }
        sx={{
          boxShadow: openStatusMenu
            ? '0px 0px 10px 0px rgba(21, 10, 84, 0.30)'
            : 'none',
          '& > p': {
            width: '109px',
            ...karlaProRegularFontStyles,
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: openStatusMenu ? 700 : 500,
            letterSpacing: '0.2px',
          },
        }}
      >
        <Typography variant="caption" component="p">
          {buttonTitle}
        </Typography>
      </Button>
      <Menu
        id="status-menu"
        anchorEl={statusAnchorEl}
        open={openStatusMenu}
        onClose={handleStatusClose}
        MenuListProps={{
          'aria-labelledby': 'status-button',
        }}
        sx={sortButtonsPaperWrapperStyles}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {typeOfData === 'MATTERS' ? (
            <>
              <FormControlLabel
                label="All"
                control={
                  <Checkbox
                    checked={matterStatusChecked[0]}
                    indeterminate={
                      !matterStatusChecked[0] &&
                      (matterStatusChecked[1] || matterStatusChecked[2])
                    }
                    onChange={handleChangeMatterStatus(0)}
                  />
                }
              />
              <FormControlLabel
                label="Active Matter"
                control={
                  <Checkbox
                    checked={matterStatusChecked[1]}
                    onChange={handleChangeMatterStatus(1)}
                  />
                }
              />
              <FormControlLabel
                label="Archived Matter"
                control={
                  <Checkbox
                    checked={matterStatusChecked[2]}
                    onChange={handleChangeMatterStatus(2)}
                  />
                }
              />
            </>
          ) : (
            <>
              <FormControlLabel
                label="All"
                control={
                  <Checkbox
                    checked={taskStatusChecked[0]}
                    indeterminate={taskStatusIndeterminate}
                    onChange={handleChangeTaskStatus(0)}
                  />
                }
              />
              <FormControlLabel
                label="Active"
                control={
                  <Checkbox
                    checked={taskStatusChecked[1]}
                    onChange={handleChangeTaskStatus(1)}
                  />
                }
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  '& > label': {
                    display: 'flex',
                    height: '32px',
                    padding: '0px 16px 0px 40px',
                    alignItems: 'center',
                    gap: '8px',
                    alignSelf: 'stretch',
                    margin: '0px',
                    '& > span': {
                      padding: '0px',
                    },
                    '& > span:nth-of-type(1) > svg': {
                      width: '16px',
                      height: '16px',
                    },
                    '& > span:nth-of-type(2)': {
                      color: '#464B53',
                      textAlign: 'center',
                      fontSize: '14px',
                      ...karlaProRegularFontStyles,
                      lineHeight: '22px',
                    },
                  },
                }}
              >
                <FormControlLabel
                  label="To Do"
                  control={
                    <Checkbox
                      checked={taskStatusChecked[3]}
                      onChange={handleChangeTaskStatus(3)}
                    />
                  }
                />
                <FormControlLabel
                  label="In Progress"
                  control={
                    <Checkbox
                      checked={taskStatusChecked[4]}
                      onChange={handleChangeTaskStatus(4)}
                    />
                  }
                />
                <FormControlLabel
                  label="Blocked"
                  control={
                    <Checkbox
                      checked={taskStatusChecked[5]}
                      onChange={handleChangeTaskStatus(5)}
                    />
                  }
                />
                <FormControlLabel
                  label="In Review"
                  control={
                    <Checkbox
                      checked={taskStatusChecked[6]}
                      onChange={handleChangeTaskStatus(6)}
                    />
                  }
                />
                <FormControlLabel
                  label="Done"
                  control={
                    <Checkbox
                      checked={taskStatusChecked[7]}
                      onChange={handleChangeTaskStatus(7)}
                    />
                  }
                />
              </Box>
              <FormControlLabel
                label="Archived"
                control={
                  <Checkbox
                    checked={taskStatusChecked[2]}
                    onChange={handleChangeTaskStatus(2)}
                  />
                }
              />
            </>
          )}
        </Box>
      </Menu>

      <Button
        id="sort-button"
        aria-controls={openSortMenu ? 'sort-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openSortMenu ? 'true' : undefined}
        onClick={handleSortClick}
        endIcon={
          openSortMenu ? (
            <span className="material-icons-round">arrow_drop_up</span>
          ) : (
            <span className="material-icons-round">arrow_drop_down</span>
          )
        }
        sx={{
          boxShadow: openSortMenu
            ? '0px 0px 10px 0px rgba(21, 10, 84, 0.30)'
            : 'none',
          '& > p': {
            width: '56px',
            ...karlaProRegularFontStyles,
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: openSortMenu ? 700 : 500,
            letterSpacing: '0.2px',
          },
        }}
      >
        <Typography variant="caption" component="p">
          Sort By
        </Typography>
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={sortAnchorEl}
        open={openSortMenu}
        onClose={handleSortClose}
        MenuListProps={{
          'aria-labelledby': 'sort-button',
        }}
        sx={sortButtonsPaperWrapperStyles}
      >
        {typeOfData === 'MATTERS' ? (
          <RadioGroup value={selectedSortMatters}>
            <FormControlLabel
              value="date"
              control={<Radio onClick={handleSortMattersChange} />}
              label="Date Allocated: newest to oldest"
            />
            <FormControlLabel
              value="client"
              control={<Radio onClick={handleSortMattersChange} />}
              label={`Alphabetically: ${
                process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
                  ? 'Organisation'
                  : 'Client'
              }`}
            />
            <FormControlLabel
              value="matter"
              control={<Radio onClick={handleSortMattersChange} />}
              label="Alphabetically: Matter"
            />
          </RadioGroup>
        ) : (
          <RadioGroup value={selectedSortTasks}>
            <FormControlLabel
              value="date"
              control={<Radio onClick={handleSortTasksChange} />}
              label="Date Allocated: newest to oldest"
            />
            <FormControlLabel
              value="task"
              control={<Radio onClick={handleSortTasksChange} />}
              label="Alphabetically: Task"
            />
            <FormControlLabel
              value="matter"
              control={<Radio onClick={handleSortTasksChange} />}
              label="Alphabetically: Matter"
            />
          </RadioGroup>
        )}
      </Menu>
    </Box>
  );
};

export default ActivityHistorySortButtons;
