import { Box, Button, Stack, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import { controlBarGenericButtonStyles } from '../interview-control-bar/styles/styles';

interface LEIAAPanelBtnProps {
  openPanel: any;
  setOpenPanel?: any;
}
const LEIAAPanelBtn = ({ openPanel, setOpenPanel }: LEIAAPanelBtnProps) => {
  return (
    <Box sx={{ marginLeft: 'auto' }}>
      <Button
        variant="contained"
        sx={{
          ...controlBarGenericButtonStyles,
          width: '100%',
          border: !openPanel
            ? '2px solid #464B53 !important'
            : '2px solid #0053FF !important',
          backgroundColor: '#464B53 !important',
          '&:hover': {
            border: '2px solid #0053FF !important',
          },
          '& > p': {
            textAlign: 'center',
            fontSize: '16px',
            ...karlaProRegularFontStyles,
            letterSpacing: '0.2px',
            textTransform: 'none',
          },
        }}
        onClick={() => setOpenPanel(!openPanel)}
      >
        <span style={{ marginRight: '8px' }} className="material-icons-round">
          speaker_notes
        </span>
        <Typography>Panel</Typography>
      </Button>
    </Box>
  );
};
export default LEIAAPanelBtn;
