import api from '.';
import { DocumentsListItem } from '../components/shared/documents-list-table/types';
import { TemplatesListItem } from '../components/shared/templates-list-table/types';
import {
  DocumentsListResponse,
  InvestigationOptionType,
  TemplatesListResponse,
} from '../types/wiki';
import { transformArrayObjectKeysToCamelCase } from '../utils/utils';

async function investigationTopicsRequest(): Promise<
  InvestigationOptionType[]
> {
  const response = await api.get('/topic');
  return response.data.topics;
}

async function createWikiTemplateRequest(cleanedData: any) {
  const response = await api.post(`/template`, {
    name: cleanedData.name,
    topic_name: cleanedData.investigation,
  });
  return response.data.id;
}

async function batchDeleteTemplateRequest(templateIds: number[]) {
  await Promise.all(
    templateIds.map(async (templateId) => {
      await api.delete(`/template/${templateId}`);
    })
  );
}

async function getWikiTemplateRequest() {
  const response = await api.get(`/template`);
  return response.data.templates;
}

async function getWikiTemplateDetailsRequest(templateId: string): Promise<any> {
  const response = await api.get(`/template/${templateId}`);
  return response.data;
}

async function updateWikiTemplateRequest(
  templateId: any,
  cleanedData: any,
  activeStep: any
) {
  if (activeStep === 4) {
    const formData = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (cleanedData.protocols) {
      if (cleanedData.protocols) {
        for (let i = 0; i < cleanedData.protocols.length; i += 1) {
          if (cleanedData.protocols[i].uploaded === false) {
            formData.append(
              'protocols',
              cleanedData.protocols[i],
              cleanedData.protocols[i].name
            );
          }
        }
      }

      if (cleanedData.protocols_to_delete) {
        for (let i = 0; i < cleanedData.protocols_to_delete.length; i += 1) {
          formData.append(
            'protocols_to_delete',
            cleanedData.protocols_to_delete[i]
          );
        }
      }
    }
    if (cleanedData.plans) {
      if (cleanedData.plans) {
        for (let i = 0; i < cleanedData.plans.length; i += 1) {
          if (cleanedData.plans[i].uploaded === false) {
            formData.append(
              'plans',
              cleanedData.plans[i],
              cleanedData.plans[i].name
            );
          }
        }
      }

      if (cleanedData.files_to_delete) {
        for (let i = 0; i < cleanedData.files_to_delete.length; i += 1) {
          formData.append('plans_to_delete', cleanedData.files_to_delete[i]);
        }
      }
    }
    if (cleanedData.report) {
      // Step 4: Upload file
      const file = cleanedData.report; // Assuming you want to work with the first file

      if (file.uploaded === false) {
        formData.append('file', file, file.name);
      }
    }
    await api.patch(`template-documents/${templateId}`, formData, config);
  } else {
    let newInterviewDataScoping = [];
    let newInterviewDataWitness = [];

    if (!cleanedData.scopingQuestions && !cleanedData.witnessQuestions) {
      cleanedData.interview = [];
    } else {
      if (cleanedData.scopingQuestions && activeStep === 1) {
        newInterviewDataScoping = cleanedData.scopingQuestions.map(
          (item: any) => ({
            ...item,
            category: 'Scoping',
          })
        );
      } else if (cleanedData.witnessQuestions && activeStep === 2) {
        newInterviewDataWitness = cleanedData.witnessQuestions.map(
          (item: any) => ({
            ...item,
            category: 'Witness',
          })
        );
      }

      cleanedData.interview = [
        ...newInterviewDataScoping,
        ...newInterviewDataWitness,
      ];
    }

    if (cleanedData.questions_to_delete) {
      cleanedData.interviewRemoved = cleanedData.interviewQuestions.filter(
        (item: any) => cleanedData.questions_to_delete.includes(item.id)
      );
    }

    if (cleanedData.emails_to_delete) {
      cleanedData.emailTemplateRemoved = cleanedData.oldEmailTemplate.filter(
        (item: any) => cleanedData.emails_to_delete.includes(item.id)
      );
    }

    await api.put(`/template/${templateId}`, {
      name: cleanedData.name,
      topic_name: cleanedData.investigation,
      interview_templates: cleanedData.interview,
      interview_templates_to_remove: cleanedData.interviewRemoved,
      email_templates: cleanedData.emailTemplate,
      email_templates_to_remove: cleanedData.emailTemplateRemoved,
    });
  }
}

async function templatesListRequest(
  dashboard: boolean,
  searchText: string,
  templateId?: any
) {
  const filters: { limit?: number; search_text?: string; templateId?: string } =
    {};

  if (dashboard) {
    filters.limit = 6;
  }

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  if (templateId !== undefined) {
    filters.templateId = templateId;
  }

  const response = await api.get('/template', {
    params: filters,
  });

  return {
    templatesList: response.data.templates,
  };
}

async function documentsListRequest(
  dashboard: boolean,
  searchText: string,
  documentId?: any
): Promise<DocumentsListResponse> {
  const filters: { limit?: number; search_text?: string; documentId?: string } =
    {};

  if (dashboard) {
    filters.limit = 6;
  }

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  if (documentId !== undefined) {
    filters.documentId = documentId;
  }

  const response = await api.get('/repository', {
    params: filters,
  });

  const transformedData: DocumentsListItem[] =
    transformArrayObjectKeysToCamelCase(
      response.data.repository
    ) as DocumentsListItem[];

  return {
    documentsList: transformedData,
  };
}

async function createDocumentRequest(cleanedData: any) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  cleanedData.files.forEach(async (fileData: any) => {
    await api.post(
      `/repository`,
      {
        file: fileData,
        topic_id: cleanedData.investigation,
      },
      config
    );
  });
}

async function batchDeleteDocumentRequest(documentIds: number[]) {
  await Promise.all(
    documentIds.map(async (documentId) => {
      await api.delete(`/repository/${documentId}`);
    })
  );
}

async function getDocumentWikiURLRequest(docId: number) {
  const response = await api.get(`wiki-document-get/${docId}`);

  return response.data;
}

async function editDocumentWikiRequest(
  fileId: number,
  renameValue?: string,
  targetFolderId?: string,
  removeFromFolder?: boolean
) {
  const patchData: { [key: string]: any } = {};
  if (renameValue !== undefined) {
    patchData.rename = renameValue;
  }
  if (targetFolderId !== undefined) {
    patchData.add_to_folder = targetFolderId;
  }
  if (removeFromFolder !== undefined) {
    patchData.remove_from_folder = true;
  }
  await api.patch(`wiki-document-get/${fileId}`, patchData);
}

async function wikiFolderDocsRequest(
  folderId: string,
  searchText: string
): Promise<any> {
  const splitId = folderId.split('-');
  const filters: { limit?: number; search_text?: string } = {};

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  const response = await api.get(`folder-wiki/${splitId[splitId.length - 1]}`, {
    params: filters,
  });
  return response.data;
}

export {
  createWikiTemplateRequest,
  updateWikiTemplateRequest,
  investigationTopicsRequest,
  batchDeleteTemplateRequest,
  getWikiTemplateRequest,
  getWikiTemplateDetailsRequest,
  templatesListRequest,
  documentsListRequest,
  createDocumentRequest,
  batchDeleteDocumentRequest,
  getDocumentWikiURLRequest,
  editDocumentWikiRequest,
  wikiFolderDocsRequest,
};
