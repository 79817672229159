import { Badge, Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  dataGridWrapperStyles,
  interviewsListMaskShadowStyles,
  interviewsListStyles,
  interviewsListWrapperStyles,
} from './styles';
import { InterviewsListItem } from './types';
import { InterviewsListResponse } from '../../../types/homeportal';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import SortableHeader from './SortableHeader';
import StatusChip from './StatusChip';
import LEIAAPagination from '../lists/LEIAAPagination';

interface InterviewListTableProps {
  interviewsListData: InterviewsListResponse | null;
  loading: boolean;
  listClass: string;
  dashboard?: boolean;
  setCheckedInterviews?: any;
  setSelectedInterview?: any;
  handleDetailInterview?: any;
  matterDetail?: boolean;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No interviews.
    </Typography>
  </GridOverlay>
);

const InterviewListTable = ({
  interviewsListData,
  loading,
  dashboard = false,
  listClass,
  setCheckedInterviews,
  setSelectedInterview,
  handleDetailInterview,
  matterDetail = false,
}: InterviewListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);
  const [badgeUpperLimit, setBadgeUpperLimit] = useState(5);
  const [badgeLowerLimit, setBadgeLowerLimit] = useState(0);
  const [sorting, setSorting] = useState<any>(null);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateNotificationPos = (index: number) => {
    const wrappedIndex = index % 5; // Wraps index to 0-4 range
    const adjustedIndex = wrappedIndex + 1; // Adjust to 1-5 range

    if (adjustedIndex !== 1) {
      return adjustedIndex * 57 + 18 * adjustedIndex;
    }
    return adjustedIndex * 57;
  };

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && interviewsListData?.interviewsList !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, interviewsListData]);

  const columns: GridColDef[] = [
    {
      field: 'intervieweeName',
      headerName: 'Interviewee Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'date',
      headerName: 'Date/Deadline',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string; // date value

        return (
          <Box>
            <p className="MuiDataGrid-cellContent">{cellValue}</p>
          </Box>
        );
      },
    },

    {
      field: 'duration',
      headerName: 'Duration',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;

        return (
          <Box>
            <p className="MuiDataGrid-cellContent">{cellValue} min</p>
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Interview Status',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <StatusChip
            status={cellValue}
            heightValue="30px"
            wrapperHeight="36px"
          />
        );
      },
    },
    {
      field: 'allocatedUsers',
      headerName: 'Added Users',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'archived',
      headerName: 'Status',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <StatusChip
            status={cellValue.toString()}
            heightValue="30px"
            wrapperHeight="36px"
          />
        );
      },
    },
  ];

  if (matterDetail === false) {
    // Include the 'Client' and 'Matter' columns when matterDetail is false
    columns.unshift(
      {
        field: 'organisation',
        headerName: process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
          ? 'Organisation'
          : 'Client',
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
          const sort = apiRef.current.state.sorting.sortModel.filter(
            (s) => s.field === params.field
          )?.[0]?.sort;
          return <SortableHeader params={params} sort={sort} />;
        },
      },
      {
        field: 'matter',
        headerName: 'Matter',
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
          const sort = apiRef.current.state.sorting.sortModel.filter(
            (s) => s.field === params.field
          )?.[0]?.sort;
          return <SortableHeader params={params} sort={sort} />;
        },
      }
    );
  }
  const interviewRowsAreEmpty = interviewsListData?.interviewsList.length === 0;

  return (
    <Box sx={interviewsListWrapperStyles(interviewRowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={interviewsListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={interviewsListData?.interviewsList as InterviewsListItem[]}
            columns={columns}
            apiRef={apiRef}
            // </DataGrid>onPaginationModelChange={}
            onPaginationModelChange={(params) => {
              setBadgeLowerLimit(params.page * params.pageSize);
              setBadgeUpperLimit((params.page + 1) * params.pageSize);
            }}
            onSortModelChange={(params) => {
              setSorting(params[0]);
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection={!dashboard}
            onRowClick={(params) => {
              handleDetailInterview(params.row);
            }}
            onRowSelectionModelChange={(ids) => {
              setCheckedInterviews(ids);
              if (ids.length !== 0) {
                setSelectedInterview(
                  interviewsListData?.interviewsList.find(
                    (row: any) => row.id === ids[0]
                  )
                );
              }
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            hideFooter={dashboard}
            sx={interviewsListStyles}
            className={listClass}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {/* Render the badge on top of each row - unusual implementation but datagrid was buggy and intruced way to much complexity */}
        {!loading &&
          interviewsListData &&
          interviewsListData?.interviewsList.length > 0 &&
          interviewsListData?.interviewsList
            .slice()
            .sort((a: any, b: any) => {
              if (sorting) {
                return sorting.sort === 'asc'
                  ? a[sorting.field] - b[sorting.field]
                  : b[sorting.field] - a[sorting.field];
              }
              return 0;
            })
            .map((row, index) => {
              return (
                row.notification !== 0 &&
                index >= badgeLowerLimit &&
                index < badgeUpperLimit && (
                  <Box
                    key={row.id}
                    sx={{
                      position: 'absolute',
                      top: `${calculateNotificationPos(index)}px`, // Adjust based on row height
                      left: '8px',
                      zIndex: 1,
                    }}
                  >
                    <Badge
                      color="secondary"
                      overlap="circular"
                      badgeContent={row.notification}
                      sx={{
                        '& > span': {
                          backgroundColor: '#ffb900',
                          width: '20px',
                          height: '20px',
                        },
                      }}
                    >
                      {/* commented in favor of only badge icon <MailIcon sx={{ fontSize: '20px', color: '#979ea9' }} /> */}
                    </Badge>
                  </Box>
                )
              );
            })}
      </Box>
    </Box>
  );
};

export default InterviewListTable;
