import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../../../styles/textStyles';

// eslint-disable-next-line import/prefer-default-export
export const matterAddedDocsWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '12px',
    background: '#FFF',
    '& > div': {
      display: 'flex',
      padding: '8px 16px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
      alignSelf: 'stretch',
      borderRadius: '12px',
      background: '#FFF',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        '& > div:nth-of-type(1)': {
          display: 'flex',
          gap: '4px',
          height: '22px',
          '& > p:nth-of-type(1)': {
            color: '#949CA9',
            ...sofiaProRegularFontStyles,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '18px',
            height: '18px',
            letterSpacing: '0.3px',
          },
          '& > p:nth-of-type(2)': {
            color: '#464B53',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
          },
        },
        '& > div:nth-of-type(2)': {
          display: 'flex',
          gap: '16px',
        },
      },
    },
  },
};
