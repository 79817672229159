import { sofiaProRegularFontStyles } from '../../styles/textStyles';

export const interviewPreJoinContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  padding: '0px 160px',
  '> div > form > input': {
    display: 'none',
  },
};

export const interviewPreJoinContentStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '32px',
  width: '100%',
};

export const interviewPreJoinCopyStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
  width: '100%',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
    '& > h4': {
      ...sofiaProRegularFontStyles,
      fontWeight: 700,
      fontSize: '18px',
      letterSpacing: '0.5px',
      color: '#202020',
      textAlign: 'center',
    },
  },
};

export const interviewRoomContainerStyles = {
  height: '100vh',
  '& > div:nth-of-type(1) ': {
    height: '100vh',
  },
  backgroundColor: '#202020',
  '& > div:nth-of-type(2) ': {
    backgroundColor: '#202020',
  },
};
