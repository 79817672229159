import { Box, FormControl, InputLabel, Select } from '@mui/material';
import React, { useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import DropdownMenuIcon from '../matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import { matterInformationSelectPaperStyles } from '../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { statusSelectStyles } from './styles';

interface LEIAAStatusSelectProps {
  labelText: string;
  controllerName: string;
  control: Control<FieldValues>;
  required?: boolean;
  children: React.ReactNode;
  paperCustomStylings?: any;
  handleChange?: any;
  statusSelectStyle?: any;
  defaultValue?: any;
}

const LEIAAStatusSelect = ({
  labelText,
  controllerName,
  control,
  required = true,
  children,
  paperCustomStylings,
  handleChange,
  statusSelectStyle = statusSelectStyles, // default is task colors
  defaultValue = 'To Do', // default is task value
}: LEIAAStatusSelectProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <Box>
      <InputLabel data-testid="matter-form-label-id" htmlFor={labelText}>
        {required ? '*' : ''}
        {labelText}
      </InputLabel>
      <Controller
        name={controllerName}
        control={control}
        defaultValue="To Do"
        render={({ field: { onChange, ...rest } }) => (
          <FormControl fullWidth required={required} sx={{ width: 'auto' }}>
            <Select
              {...rest}
              defaultValue="To Do"
              displayEmpty
              labelId="leiaa-simple-select-label"
              id="leiaa-simple-select"
              size="small"
              open={menuOpen}
              onClose={handleMenuClose}
              onOpen={handleMenuOpen}
              // eslint-disable-next-line react/no-unstable-nested-components
              IconComponent={() => (
                <DropdownMenuIcon
                  isMenuOpen={menuOpen}
                  handleDropdownClientIconClick={handleMenuOpen}
                  isAdornment={false}
                  iconColor="#FFF !important"
                />
              )}
              MenuProps={{
                PaperProps: {
                  sx: {
                    ...matterInformationSelectPaperStyles,
                    ...paperCustomStylings,
                  },
                },
              }}
              sx={statusSelectStyle(rest.value)}
              value={rest.value === '' ? defaultValue : rest.value}
              onChange={(e) => {
                onChange(e.target.value);
                if (handleChange) {
                  handleChange(e.target.value);
                }
              }}
            >
              {children}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default LEIAAStatusSelect;
