import { Box, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import LEIAAContext from '../../../context/context';
import TaskSlidePanel from './TaskSlidePanel';
import {
  taskCardDueTextStyles,
  taskCardFooterBoxStyles,
  taskCardFooterIconStyles,
  taskCardFooterSubTextStyles,
  taskCardFooterTextStyles,
  taskCardTitleStyles,
  taskCardWrapperBoxStyles,
} from './taskstyles';

interface TaskCardProps {
  item: any;
  index: any;
  availableTags: any;
  setAvailableTags: any;
  matterDetail?: boolean;
}

const TaskCard = ({
  item,
  index,
  availableTags,
  setAvailableTags,
  matterDetail = false,
}: TaskCardProps) => {
  const [open, setOpen] = useState(false);
  const { user } = useContext(LEIAAContext);
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TaskSlidePanel
            item={item}
            open={open}
            setOpen={setOpen}
            availableTags={availableTags}
            setAvailableTags={setAvailableTags}
            matterDetail={matterDetail}
          />
          <Box
            sx={taskCardWrapperBoxStyles}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={taskCardFooterBoxStyles}
            >
              <Typography className="board-title-card" sx={taskCardTitleStyles}>
                {item.title}
              </Typography>

              {item.tag && (
                <Typography sx={taskCardDueTextStyles}>
                  <span
                    style={taskCardFooterIconStyles}
                    className="material-icons-round"
                  >
                    sell
                  </span>

                  <span>{item.tag}</span>
                </Typography>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={taskCardFooterBoxStyles}
            >
              {item.description && (
                <Typography
                  sx={{
                    taskCardDueTextStyles,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '50%',
                  }}
                >
                  {item.description}
                </Typography>
              )}
              {item.due_date && (
                <Typography sx={taskCardDueTextStyles}>
                  <span style={{ color: '#4f4f4f', fontSize: '14px' }}>
                    Due
                  </span>

                  <span style={{ marginLeft: '5px' }}>{item.due_date}</span>
                </Typography>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={taskCardFooterBoxStyles}
            >
              {matterDetail
                ? item.assignee &&
                  item.assignee.name && (
                    <Typography sx={taskCardFooterTextStyles}>
                      <span
                        style={taskCardFooterIconStyles}
                        className="material-icons-round"
                      >
                        person
                      </span>
                      <span
                        style={{
                          ...taskCardFooterSubTextStyles,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: '180px',
                        }}
                      >
                        {item.assignee.name}
                      </span>
                    </Typography>
                  )
                : item.investigation_name && (
                    <Typography sx={taskCardFooterTextStyles}>
                      <span
                        style={taskCardFooterIconStyles}
                        className="material-icons-round"
                      >
                        folder
                      </span>
                      <span
                        style={{
                          ...taskCardFooterSubTextStyles,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: '180px',
                        }}
                      >
                        {item.investigation_name}
                        {item.assignee &&
                          item.assignee.name &&
                          ` - ${item.assignee.name}`}
                      </span>
                    </Typography>
                  )}

              {item.comments_count !== 0 && (
                <Typography sx={taskCardFooterTextStyles}>
                  <span
                    style={taskCardFooterIconStyles}
                    className="material-icons-round"
                  >
                    chat
                  </span>
                  <span style={taskCardFooterSubTextStyles}>
                    {item.comments_count}
                  </span>
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default TaskCard;
