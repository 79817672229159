import { Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import SortableHeader from './SortableHeader';
import {
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
  mattersListWrapperStyles,
} from './styles';
import { MattersListItem } from './types';
import { MattersListResponse } from '../../../types/homeportal';
import StatusChip from './StatusChip';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import LEIAAPagination from '../lists/LEIAAPagination';

interface MattersListTableProps {
  mattersListData: MattersListResponse | null;
  loading: boolean;
  listClass: string;
  dashboard?: boolean;
  setCheckedMatters?: any;
  setSelectedMatter?: any;
  handleDetailUser?: any;
  clientDashboard?: boolean;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No matters.
    </Typography>
  </GridOverlay>
);

const MattersListTable = ({
  mattersListData,
  loading,
  dashboard = false,
  listClass,
  setCheckedMatters,
  setSelectedMatter,
  handleDetailUser,
  clientDashboard = false,
}: MattersListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && mattersListData?.mattersList !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, mattersListData]);

  const columns: GridColDef[] = [
    {
      field: 'client',
      headerName: process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
        ? 'Organisation'
        : 'Client',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'matterTitle',
      headerName: 'Matter',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'matterDescription',
      headerName: 'Matter Description',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '0px',
              height: '36px',
              '& > p': {
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                width: '100%',
              },
            }}
          >
            <p className="MuiDataGrid-cellContent" title={cellValue}>
              {cellValue}
            </p>
          </Box>
        );
      },
    },
    {
      field: 'dateCreated',
      headerName: 'Date Opened',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;

        const jsDate = new Date(cellValue);

        const day = jsDate.getDate().toString().padStart(2, '0');
        const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
        const year = jsDate.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;

        return (
          <Box>
            <p className="MuiDataGrid-cellContent">{formattedDate}</p>
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <StatusChip
            status={cellValue}
            heightValue="30px"
            wrapperHeight="36px"
          />
        );
      },
    },
    {
      field: 'onGoingTasks',
      headerName: 'Ongoing Tasks',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'upcomingInterviews',
      headerName: 'Upcoming Interviews',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'activeUsers',
      headerName: 'Active Users',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
  ];

  const matterRowsAreEmpty = mattersListData?.mattersList.length === 0;

  return (
    <Box sx={mattersListWrapperStyles(matterRowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={mattersListData?.mattersList as MattersListItem[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: dashboard ? 6 : 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection={!dashboard && !clientDashboard}
            onRowSelectionModelChange={(ids) => {
              setCheckedMatters(ids);
              if (ids.length !== 0) {
                setSelectedMatter(
                  mattersListData?.mattersList.find((row) => row.id === ids[0])
                );
              }
            }}
            onRowClick={(params) => {
              handleDetailUser(params.row);
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            hideFooter={dashboard}
            sx={mattersListStyles}
            className={listClass}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MattersListTable;
