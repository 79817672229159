/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
  IconButton,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationWrapperStyles,
} from '../../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { LEIAAInput } from '../../../../components/inputs';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
} from '../../../../components/styles';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../../styles/textStyles';
import {
  getAdminReportingSettingsRequest,
  updateAdminReportingSettingsRequest,
} from '../../../../api/whistleblower';
import CreationNotification from '../../../../components/shared/creation-notification/CreationNotification';

const FaqPage = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [faqs, setFAQS] = useState<any>([]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleFetchData = async () => {
    setLoadingData(true);
    try {
      const response = await getAdminReportingSettingsRequest();
      Object.keys(response).forEach((fieldName) => {
        setValue(fieldName, response[fieldName]);
      });
      setFAQS(() => [...response.faqs]);

      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      data.faqs = watch('faqs');
      await updateAdminReportingSettingsRequest(data);
      setShowNotification(true);
      handleFetchData();
    } catch (error: any) {
      console.log(error);
    }
  };

  const addNewFAQ = () => {
    const newFAQ = {
      question: '',
      answer: '',
      id: uuidv4(),
    };

    // Update form data using setValue
    setValue('faqs', [...faqs, newFAQ]);

    // Update local state
    setFAQS([...faqs, newFAQ]);
  };

  const removeFAQ = (id: number, index: number) => {
    const updatedFAQS = faqs.filter((faq: any) => faq.id !== id);
    setValue('faqs', updatedFAQS);
    setFAQS(updatedFAQS);
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showNotification) {
      // Scroll to the top after form submission
      window.scrollTo({ top: 0 });
    }
  }, [showNotification]);

  return loadingData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        '& > span': {
          color: '#0053FF',
        },
        marginTop: '50%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showNotification && (
        <CreationNotification
          title="Settings saved successfully!"
          subTitle=""
          showNotification={showNotification}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '20px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        Frequently asked questions page
      </Typography>

      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '15px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        This page provides the whistleblower a list of answers to frequently
        asked questions located at{' '}
        <a
          href={`${process.env.REACT_APP_SELF_URL}reporting-faq`}
          target="_blank"
          rel="noreferrer"
        >
          /reporting-faq
        </a>
      </Typography>

      <Box sx={matterInformationWrapperStyles}>
        <Box
          sx={{
            ...matterInformationFormWrapperStyles,
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...matterInformationInputWrapperStyles,
              paddingTop: '5px',
            }}
          >
            <Box>
              <LEIAAInput
                labelText="FAQ header text"
                controllerName="faq_main_header"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="40px !important"
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box>
              <LEIAAInput
                labelText="FAQ header content"
                controllerName="faq_main_content"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="100px"
                multiline
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '22px',
                  marginBottom: '-20px',
                }}
              >
                Frequently asked questions list
              </Typography>
              {faqs.map((faq: any, index: any) => {
                return (
                  <Box
                    key={`faqs-b-${uuidv4()}`}
                    className="engaged-box-report"
                  >
                    <LEIAAInput
                      labelText="Question"
                      controllerName={`faqs[${index}].question`}
                      control={control}
                      handleChange={(e: ChangeEvent<{ value: any }>) => {
                        faqs[index].question = e;
                        setFAQS(faqs);
                      }}
                      inputPlaceholder="Enter question"
                      inputWidth="100%"
                      inputHeight="40px"
                      required
                      key={`faqs-f-n-${uuidv4()}`}
                    />
                    <LEIAAInput
                      labelText="Answer"
                      controllerName={`faqs[${index}].answer`}
                      control={control}
                      handleChange={(e: ChangeEvent<{ value: any }>) => {
                        faqs[index].answer = e;
                        setFAQS(faqs);
                      }}
                      inputPlaceholder="Enter answer"
                      inputWidth="100%"
                      inputHeight="40px"
                      required
                      key={`faqs-l-n-${uuidv4()}`}
                    />

                    <Box key={`faqs-delete-${uuidv4()}`}>
                      <IconButton
                        onClick={() => removeFAQ(faq.id, index)}
                        sx={{ '& > span': { color: '#464B53' } }}
                      >
                        <span className="material-icons-round">close</span>
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}

              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    height: '30px',
                    padding: '8px 16px',
                    gap: '8px',
                  }}
                  onClick={() => addNewFAQ()}
                >
                  <span className="material-icons-round">add</span>
                  <Typography>Add new question</Typography>
                </Button>
              </Box>
            </Box>
            <Box sx={{ height: '2px', borderBottom: '1px solid  #D3ECF6' }} />
          </Box>
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Button variant="contained" sx={primaryButtonStyles()} type="submit">
          <Typography>Save</Typography>
          <span className="material-icons-round">save</span>
        </Button>
      </Stack>
    </form>
  );
};

export default FaqPage;
