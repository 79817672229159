/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Drawer,
  Typography,
  IconButton,
  List,
  ListItem,
  InputLabel,
  Autocomplete,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { v4 as uuidv4 } from 'uuid';

import { datePickerStyles } from '../../../components/inputs/styles';
import DropdownMenuIcon from '../../../components/matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import {
  getAvailableFlairsRequest,
  updateTaskRequest,
} from '../../../api/tasks';
import {
  convertDateFormat,
  OpenPickerIcon,
} from '../../../components/inputs/LEIAADatePicker';
import LEIAAAutocompletePopper from '../../../components/matters/matter-creation/LEIAAAutocompletePopper';
import { loginFormInputStyles } from '../../../components/login/styles';
import TaskCommentBox from '../../../components/matters/matter-detail/MatterDetailTaskComments';
import {
  batchDeleteTasksRequest,
  matterDetailAddTaskComment,
} from '../../../api/matters';
import {
  karlaProRegularFontStyles,
  karlaProBoldFontStyles,
} from '../../../styles/textStyles';
import LEIAADialog from '../../../components/inputs/LEIAADialog';
import { userMeDetailsRequest } from '../../../api/auth';
import { User } from '../../../types/auth';

// split into smaller components when time allows

interface TaskSlidePanelProps {
  item: {
    id: string;
    title: string;
    description: string;
    investigation_name: string | null;
    due_date: any;
    comments: any[];
    tag: string | null;
    status: string;
    comments_count: any;
    matter_id: string;
    assignee: any;
  };
  open: boolean;
  setOpen: any;
  availableTags: any;
  setAvailableTags: any;
  matterDetail: any;
}

const TaskSlidePanel = ({
  item,
  open,
  setOpen,
  availableTags,
  setAvailableTags,
  matterDetail = false,
}: TaskSlidePanelProps) => {
  // const [menuOpen, setMenuOpen] = useState(false);
  const [toggleDescriptionEdit, setToggleDescriptionEdit] = useState(false);
  const [toggleTitleEdit, setToggleTitleEdit] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenAllocation, setIsMenuOpeAllocation] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [me, setMe] = useState<User | null>(null);

  const navigate = useNavigate();

  // const handleMenuOpen = () => {
  //   setMenuOpen(true);
  // };

  // const handleMenuClose = () => {
  //   setMenuOpen(false);
  // };

  const handleDropdownIconClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDropdownIconClickAllocation = () => {
    setIsMenuOpeAllocation(!isMenuOpenAllocation);
  };

  const handleTitleClick = (e: any) => {
    // Prevent event propagation to the parent (Box) element
    e.stopPropagation();
    setToggleTitleEdit(true);
  };

  const handleDescriptionClick = (e: any) => {
    // Prevent event propagation to the parent (Box) element
    e.stopPropagation();
    setToggleDescriptionEdit(true);
  };

  const closeEdits = () => {
    if (toggleDescriptionEdit) {
      setToggleDescriptionEdit(false);
    }
    if (toggleTitleEdit) {
      setToggleTitleEdit(false);
    }
  };
  const autoCompleteFilter = createFilterOptions<any>();
  const usersFilter = createFilterOptions<any>();

  const handleTaskUpdate = async (
    tag: any = null,
    status: any = null,
    date: any = null,
    assignee: any = null,
    name: any = null,
    description: any = null
  ) => {
    try {
      await updateTaskRequest(
        item.id,
        tag,
        status,
        date,
        assignee,
        name,
        description
      );
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const handleDetailMatter = (id: any) => {
    navigate(`/matters/detail/${id}`, {
      state: {},
    });
  };

  const userIdToObject = (assignee: any | null = null) => {
    if (!assignee) return undefined;

    const { id } = assignee;
    const user = users?.filter((u: any) => u.id === id)[0];
    if (user) {
      return users?.filter((u: any) => u.name === user.name)[0];
    }

    return undefined;
  };

  const handleGetAllocationUsers = async () => {
    try {
      const response = await getAvailableFlairsRequest(item.id, 'users');
      setUsers(response);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const handleFetchMeUserDetails = async () => {
    try {
      const userInfo = await userMeDetailsRequest('GET');
      setMe(userInfo.user);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllocationUsers();
    if (!matterDetail) handleFetchMeUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeElement = () => {
    const element = document.querySelector(
      `[data-rbd-draggable-id="${item.id}"]`
    );
    if (element) {
      element.remove();
    }
  };

  const deleteTaskHandler = async () => {
    try {
      await batchDeleteTasksRequest([item.id]);
      removeElement();
      setOpenAlertOpenDialog(false);
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{ width: '400px', padding: '16px' }}
        role="presentation"
        onClick={() => closeEdits()}
      >
        <IconButton
          onClick={() => setOpenAlertOpenDialog(true)}
          sx={{ float: 'right' }}
        >
          <span className="material-icons-round">archive</span>
        </IconButton>
        <LEIAADialog
          open={openAlertOpenDialog}
          onClose={() => setOpenAlertOpenDialog(false)}
          title="alert"
          description="You are about to archive a task. Are you sure you want to archive it?"
          primaryButtonText="Yes, archive task"
          primaryButtonIcon="archive"
          onPrimaryButtonClick={deleteTaskHandler}
          secondaryButtonText="Cancel"
          secondaryButtonHasIcon={false}
          onSecondaryButtonClick={() => setOpenAlertOpenDialog(false)}
          isTextAlign
        />

        {/* title */}
        {toggleTitleEdit || !item.title ? (
          <TextField
            defaultValue={item.title}
            placeholder="Enter task name"
            inputProps={{
              'data-testid': 'form-text-field-id',
              inputMode: 'text',
              className: 'scrollable-content',
            }}
            onChange={(e) => {
              handleTaskUpdate(null, null, null, null, e.target.value, null);
              item.title = e.target.value;
            }}
            autoComplete="off"
            type="text"
            size="small"
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            margin="normal"
            sx={{
              ...loginFormInputStyles('auto', '40px'),
              marginBottom: '5px',
            }}
            onClick={handleTitleClick} // Add click event handler
          />
        ) : (
          <Typography
            variant="h5"
            mb={2}
            onClick={() => setToggleTitleEdit(true)}
            sx={{
              ...karlaProBoldFontStyles,
            }}
          >
            {item.title}
          </Typography>
        )}

        {/* description */}
        {toggleDescriptionEdit || !item.description ? (
          <TextField
            defaultValue={item.description}
            placeholder="Enter task description"
            inputProps={{
              'data-testid': 'form-text-field-id',
              inputMode: 'text',
              className: 'scrollable-content',
            }}
            onChange={(e) => {
              handleTaskUpdate(null, null, null, null, null, e.target.value);
              item.description = e.target.value;
            }}
            autoComplete="off"
            type="text"
            size="small"
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            fullWidth
            margin="normal"
            sx={{
              ...loginFormInputStyles('100%', '130px'),
            }}
            multiline
            onClick={handleDescriptionClick} // Add click event handler
          />
        ) : (
          <Typography
            variant="subtitle1"
            color="text.secondary"
            mb={2}
            onClick={() => setToggleDescriptionEdit(true)}
            sx={{
              ...karlaProRegularFontStyles,
            }}
          >
            {item.description}
          </Typography>
        )}

        <List sx={{ marginBottom: '8px' }}>
          {/* status */}
          {/* <ListItem key={uuidv4()}>
            <InputLabel
              data-testid="matter-form-label-id"
              htmlFor="leiaa-simple-select"
              sx={{
                ...karlaProBoldFontStyles,
                marginRight: '5px',
              }}
            >
              Status:
            </InputLabel>
            <Select
              defaultValue="To Do"
              displayEmpty
              labelId="leiaa-simple-select-label"
              id="leiaa-simple-select"
              size="small"
              open={menuOpen}
              onClose={handleMenuClose}
              onOpen={handleMenuOpen}
              // eslint-disable-next-line react/no-unstable-nested-components
              IconComponent={() => (
                <DropdownMenuIcon
                  isMenuOpen={menuOpen}
                  handleDropdownClientIconClick={handleMenuOpen}
                  isAdornment={false}
                  iconColor="#FFF !important"
                />
              )}
              MenuProps={{
                PaperProps: {
                  sx: {
                    ...matterInformationSelectPaperStyles,
                  },
                },
              }}
              sx={statusSelectStyles(item.status)}
              value={item.status}
              onChange={(e) => {
                handleTaskUpdate(null, e.target.value, null, null, null, null);
                item.status = e.target.value;
              }}
            >
              <MenuItem value="To Do">To Do</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="In Review">In Review</MenuItem>
              <MenuItem value="Blocked">Blocked</MenuItem>
              <MenuItem value="Done">Done</MenuItem>
            </Select>
          </ListItem> */}
          {/* investigation_name */}
          {item.investigation_name && (
            <ListItem onClick={() => handleDetailMatter(item.matter_id)}>
              <InputLabel
                data-testid="matter-form-label-id"
                htmlFor="leiaa-simple-select"
                sx={{
                  ...karlaProBoldFontStyles,
                  marginRight: '5px',
                  width: '100%',
                }}
              >
                <span
                  style={{
                    verticalAlign: 'middle',
                    marginRight: '5px',
                    fontSize: '18px',
                  }}
                  className="material-icons-round"
                >
                  folder
                </span>
                Investigation:
              </InputLabel>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    ...karlaProRegularFontStyles,
                  }}
                >
                  {item.investigation_name}
                </Typography>
              </Box>
            </ListItem>
          )}
          {/* allocation */}
          {item.matter_id && (
            <ListItem key={uuidv4()}>
              <InputLabel
                data-testid="matter-form-label-id"
                htmlFor="leiaa-simple-select"
                sx={{
                  ...karlaProBoldFontStyles,
                  marginRight: '5px',
                  width: '100%',
                }}
              >
                <span
                  style={{
                    verticalAlign: 'middle',
                    marginRight: '5px',
                    fontSize: '18px',
                  }}
                  className="material-icons-round"
                >
                  person
                </span>
                Assignee:
              </InputLabel>
              <Autocomplete
                sx={{ width: '100%' }}
                // eslint-disable-next-line react/no-unstable-nested-components
                PopperComponent={(props) => (
                  <LEIAAAutocompletePopper {...props} />
                )}
                value={userIdToObject(item.assignee)}
                open={isMenuOpenAllocation}
                onOpen={() => setIsMenuOpeAllocation(true)}
                onClose={() => setIsMenuOpeAllocation(false)}
                onChange={(event, newValue) => {
                  let pickedValue = newValue;
                  if (newValue && newValue.name === 'No user found') return;
                  if (!newValue) {
                    pickedValue = { id: 'REMOVE_ASSIGNEE_COMMAND' };
                    item.assignee = null;
                    if (!matterDetail) {
                      removeElement();
                    }
                  }
                  handleTaskUpdate(
                    null,
                    null,
                    null,
                    pickedValue.id,
                    null,
                    null
                  );
                  if (pickedValue !== 'REMOVE_ASSIGNEE_COMMAND') {
                    item.assignee = {
                      id: pickedValue.id,
                      name: pickedValue.name,
                    };
                    if (
                      !matterDetail &&
                      me &&
                      pickedValue.name !== `${me.first_name} ${me.last_name}}`
                    ) {
                      removeElement();
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = usersFilter(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      name: 'No user found',
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={users || []}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }

                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search by user..."
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    size="small"
                    sx={{
                      ...loginFormInputStyles('unset', '40px'),
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: null,
                      endAdornment: (
                        <DropdownMenuIcon
                          params={params}
                          inputValue={item.due_date}
                          isMenuOpen={isMenuOpenAllocation}
                          handleDropdownClientIconClick={
                            handleDropdownIconClickAllocation
                          }
                          isAdornment
                        />
                      ),
                    }}
                  />
                )}
              />
            </ListItem>
          )}
          {/* date */}
          <ListItem key={uuidv4()}>
            <InputLabel
              data-testid="matter-form-label-id"
              htmlFor="leiaa-simple-select"
              sx={{
                ...karlaProBoldFontStyles,
                marginRight: '5px',
                width: '100%',
              }}
            >
              <span
                style={{
                  verticalAlign: 'middle',
                  marginRight: '5px',
                  fontSize: '18px',
                }}
                className="material-icons-round"
              >
                event
              </span>
              Due Date:
            </InputLabel>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <DatePicker
                value={
                  item.due_date !== '' && item.due_date !== null
                    ? dayjs(convertDateFormat(item.due_date))
                    : null
                }
                format="D/M/YYYY"
                slotProps={{
                  textField: {
                    placeholder: 'Enter a deadline',
                  },
                  popper: {
                    placement: 'bottom-start',
                  },
                }}
                slots={{
                  openPickerIcon: OpenPickerIcon,
                }}
                sx={{ ...datePickerStyles, width: 'unset' }}
                onChange={(e: any) => {
                  let formattedDate = '';

                  if (e !== null) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    formattedDate = (e as any).$d!.toLocaleDateString('pt-PT', {
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric',
                    });
                  }

                  handleTaskUpdate(null, null, formattedDate, null, null, null);
                  item.due_date = formattedDate;
                }}
              />
            </Box>
          </ListItem>
          {/* tag */}
          <ListItem key={uuidv4()}>
            <InputLabel
              data-testid="matter-form-label-id"
              htmlFor="leiaa-simple-select"
              sx={{
                ...karlaProBoldFontStyles,
                marginRight: '5px',
                width: '100%',
              }}
            >
              <span
                style={{
                  verticalAlign: 'middle',
                  marginRight: '5px',
                  fontSize: '18px',
                }}
                className="material-icons-round"
              >
                sell
              </span>
              Tag:
            </InputLabel>
            <Autocomplete
              sx={{ width: '100%' }}
              // eslint-disable-next-line react/no-unstable-nested-components
              PopperComponent={(props) => (
                <LEIAAAutocompletePopper {...props} />
              )}
              value={item.tag || ''}
              open={isMenuOpen}
              onOpen={() => setIsMenuOpen(true)}
              onClose={() => setIsMenuOpen(false)}
              onChange={(event, newValue) => {
                let pickedValue = newValue;
                if (newValue && newValue.startsWith('Add "')) {
                  // Extract the inputValue without the 'Add " ... "' part
                  pickedValue = newValue.substring(5, newValue.length - 1);
                }
                if (!newValue) {
                  pickedValue = 'REMOVE_TAG_COMMAND';
                  item.tag = null;
                }
                handleTaskUpdate(pickedValue, null, null, null, null, null);
                if (pickedValue !== 'REMOVE_TAG_COMMAND') {
                  item.tag = pickedValue;
                  setAvailableTags([...availableTags, pickedValue]);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = autoCompleteFilter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value

                const isExisting = options.some(
                  (option) => inputValue === option
                );

                if (inputValue !== '' && !isExisting) {
                  filtered.push(`Add "${inputValue}"`);
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={availableTags || []}
              getOptionLabel={(option) => {
                return option;
              }}
              renderOption={(props, option) => (
                <li {...props} key={uuidv4()}>
                  {option}
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter tag name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  size="small"
                  sx={{
                    ...loginFormInputStyles('unset', '40px'),
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: null,
                    endAdornment: (
                      <DropdownMenuIcon
                        params={params}
                        inputValue={item.due_date}
                        isMenuOpen={isMenuOpen}
                        handleDropdownClientIconClick={handleDropdownIconClick}
                        isAdornment
                      />
                    ),
                  }}
                />
              )}
            />
          </ListItem>
        </List>

        {/* comment */}
        <TaskCommentBox
          task={item}
          onNewCommentAdded={async (comment) => {
            await matterDetailAddTaskComment(item?.id as any, comment);
            item.comments.push({
              comment,
              created_date: 'Just now',
              author: 'Me',
            });
            item.comments_count += 1;
          }}
          styleOverride={{
            // layout
            display: 'flex',
            minWidth: '20%',
            width: 'auto',
            padding: '24px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            overflowY: 'scroll',

            // style
            borderRadius: '24px',
            border: '1px solid var(--secondary-colours-sky-blue-4, #D3ECF6)',
            background: 'var(--White, #FFF)',
          }}
        />
      </Box>
    </Drawer>
  );
};

export default TaskSlidePanel;
