import { karlaProRegularFontStyles } from '../../styles/textStyles';

export const datePickerStyles = {
  margin: '0px',
  borderWidth: '1px',
  width: '100%',
  '&:focus-within': {
    borderColor: '#0053FF',
  },
  '&:focus-within > div > fieldset': {
    borderColor: '#0053FF !important',
    borderWidth: '1px !important',
  },
  '&:focus-within > div.Mui-error > fieldset': {
    borderColor: '#E22727 !important',
    borderWidth: '1px !important',
  },

  '& input': {
    caretColor: '#0053FF',
  },
  '& > div': {
    width: '100%',
    height: '40px',
    padding: '0px !important',
    display: 'flex',
    justifyContent: 'space-between',

    '& > input': {
      padding: '0px 12px !important',
      borderRadius: '14px !important',
      width: 'auto',
      height: '40px',
      ...karlaProRegularFontStyles,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#202020',
      '&:hover': {
        cursor: 'default',
      },
      '::placeholder': {
        ...karlaProRegularFontStyles,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        color: '#69707B',
      },
    },
    '& > div.MuiInputAdornment-root': {
      margin: '0px 12px 0px 0px',
    },

    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      border: '1px solid #32353C',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
    },
    '&.Mui-focused > fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important',
      borderColor: '#0053FF !important',
    },
  },
  '& > div.Mui-error': {
    '& > input, & > textarea': {
      color: '#202020',
    },
    '& > fieldset': {
      backgroundColor: 'rgba(226, 39, 39, 0.04)',
      border: '1px solid #E22727',
    },
  },
  '& > p.MuiFormHelperText-root.Mui-error': {
    ...karlaProRegularFontStyles,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    color: '#E22727',
  },
  '& > p.MuiFormHelperText-root': {
    ...karlaProRegularFontStyles,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    color: '#454545',
    margin: '8px 0px 0px 0px',
    padding: '0px 8px',
  },
};

export const statusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: inputValue === 'In Review' ? '#202020' : '#FFF',
    backgroundColor: () => {
      switch (inputValue) {
        case 'To Do':
          return '#A962D5';
        case 'In Progress':
          return '#3375FF';
        case 'In Review':
          return '#EDB900';
        case 'Blocked':
          return '#E22727';
        case 'Done':
          return '#70BC42';
        default:
          return '#A962D5';
      }
    },
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: () => {
        switch (inputValue) {
          case 'To Do':
            return '#A962D5';
          case 'In Progress':
            return '#3375FF';
          case 'In Review':
            return '#EDB900';
          case 'Blocked':
            return '#E22727';
          case 'Done':
            return '#70BC42';
          default:
            return '#A962D5';
        }
      },
    },
  };
};

// refactor down
export const severitySelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',
    backgroundColor: () => {
      switch (inputValue) {
        case '1':
        case 'ONE':
          return '#38B763';
        case '2':
        case 'TWO':
          return '#3375FF';
        case '3':
        case 'THREE':
          return '#A962D5';
        case '4':
        case 'FOUR':
          return '#EDB900';
        case '5':
        case 'FIVE':
          return '#E22727';
        case 'TBD':
          return '#CCCCCC';
        default:
          return '#A962D5';
      }
    },
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: () => {
        switch (inputValue) {
          case '1':
          case 'ONE':
            return '#38B763';
          case '2':
          case 'TWO':
            return '#3375FF';
          case '3':
          case 'THREE':
            return '#A962D5';
          case '4':
          case 'FOUR':
            return '#EDB900';
          case '5':
          case 'FIVE':
            return '#E22727';
          case 'TBD':
            return '#CCCCCC';
          default:
            return '#A962D5';
        }
      },
    },
  };
};

// refactor down
export const reportStatusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',
    backgroundColor: () => {
      switch (inputValue) {
        case 'New':
          return '#38B763';
        case 'Open':
          return '#3375FF';
        case 'Delayed':
          return '#EDB900';
        case 'Blocked':
          return '#E22727';
        case 'Closed':
          return '#A962D5';
        default:
          return '#CCCCCC';
      }
    },
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: () => {
        switch (inputValue) {
          case 'New':
            return '#38B763';
          case 'Open':
            return '#3375FF';
          case 'Delayed':
            return '#EDB900';
          case 'Blocked':
            return '#E22727';
          case 'Closed':
            return '#A962D5';
          default:
            return '#CCCCCC';
        }
      },
    },
  };
};

// refactor down
export const reportWhistleblowerStatusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',
    backgroundColor: () => {
      switch (inputValue) {
        case 'Received':
          return '#A962D5';
        case 'In Progress':
          return '#3375FF';
        case 'Further Information Required':
          return '#EDB900';
        case 'Completed':
          return '#38B763';
        default:
          return '#CCCCCC';
      }
    },
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: () => {
        switch (inputValue) {
          case 'Received':
            return '#A962D5';
          case 'In Progress':
            return '#3375FF';
          case 'Further Information Required':
            return '#EDB900';
          case 'Completed':
            return '#38B763';
          default:
            return '#CCCCCC';
        }
      },
    },
  };
};

// refactor down
export const stageStatusSelectStyles = (inputValue: string) => {
  return {
    margin: '0px',
    borderWidth: '1px',
    borderRadius: '14px',
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    // color: inputValue === 'TDB' ? '#202020' : '#FFF',
    color: '#FFF',
    backgroundColor: () => {
      switch (inputValue) {
        case 'Report Acknowledgement':
          return '#4682B4';
        case 'Scoping':
          return '#32CD32';
        case 'Data Collection':
          return '#1E90FF';
        case 'Analysis':
          return '#FFD700';
        case 'Further Steps':
          return '#FF6347';
        case 'Interviews':
          return '#8A2BE2';
        case 'Report':
          return '#4169E1';
        case 'Closed':
          return '#008000';
        default:
          return '#CCCCCC';
      }
    },
    '&:hover': {
      borderColor: 'transparent !important',
      '& > fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '& > div': {
      height: '40px !important',
      padding: '0px 12px !important',
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused > fieldset': {
      borderColor: 'transparent !important',
      borderWidth: '1px !important',
    },
    '& > fieldset': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderRadius: '14px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0,
      borderColor: () => {
        switch (inputValue) {
          case 'New':
            return '#38B763';
          case 'Open':
            return '#3375FF';
          case 'Delayed':
            return '#EDB900';
          case 'Blocked':
            return '#E22727';
          case 'Closed':
            return '#A962D5';
          default:
            return '#CCCCCC';
        }
      },
    },
  };
};
