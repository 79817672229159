/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useRef } from 'react';
import { secondaryButtonStyles } from '../styles';

interface LEIAAUploadFileAreaProps {
  uploadedFilesNames?: string[];
  title: string;
  subTitle?: string;
  downloadText?: string;
  handleDrag: any;
  handleAddFile: any;
  handleRemoveFile?: (fileName: string) => void;
  downloadUrl?: any;
  uploadAreaStyles: any;
  acceptedFileTypes?: string[]; // Change the type to an array of strings
  multiple?: boolean;
}

const LEIAAUploadFileArea = ({
  uploadedFilesNames,
  title,
  subTitle,
  downloadText,
  handleDrag,
  handleAddFile,
  handleRemoveFile,
  downloadUrl,
  uploadAreaStyles,
  acceptedFileTypes,
  multiple = true,
}: LEIAAUploadFileAreaProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDownloadStructuredFile = (url: string) => {
    const fileName = url.split('/').pop();
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download', fileName || 'file');
    document.body.appendChild(aTag);
    aTag.click();
  };

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const acceptTypes = acceptedFileTypes
    ? acceptedFileTypes.join(',')
    : undefined;

  return (
    <Box sx={uploadAreaStyles}>
      <Box>
        <Box>
          <Typography>{title}</Typography>
          {subTitle && (
            <Box>
              <Typography>{subTitle}</Typography>
              {downloadText && (
                <Box>
                  <Typography
                    onClick={() => handleDownloadStructuredFile(downloadUrl)}
                  >
                    {downloadText}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box
          onDrop={handleDrag}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
        >
          <input
            accept={acceptTypes}
            ref={inputRef}
            onChange={handleAddFile}
            onClick={(event) => {
              event.currentTarget.value = '';
            }}
            type="file"
            id="input-file-upload"
            multiple={multiple}
          />
          <Button
            variant="contained"
            sx={secondaryButtonStyles()}
            onClick={onButtonClick}
          >
            <Typography>Upload</Typography>
            <span className="material-icons-round">file_upload</span>
          </Button>
          {/* FILES UPLOADED INFO  */}
          <Box>
            {uploadedFilesNames &&
              uploadedFilesNames.length > 0 &&
              uploadedFilesNames.map((f: any, index: number) => {
                return (
                  <Box key={f}>
                    <Typography>{`${index + 1}. ${f}`}</Typography>
                    <IconButton
                      data-testid="clear-file-uploaded-id"
                      aria-label="clear file uploaded"
                      onClick={() => {
                        if (handleRemoveFile) {
                          handleRemoveFile(f);
                        }
                      }}
                      sx={{
                        '&:hover': {
                          backgroundColor: '#fff',
                        },
                        '& > span': {
                          width: '16px',
                          height: '16px',
                          lineHeight: '16px',
                          fontSize: '16px',
                          color: '#69707B',
                        },
                      }}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LEIAAUploadFileArea;
