import { Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import LEIAAPagination from '../lists/LEIAAPagination';
import SortableHeader from '../matters-list-table/SortableHeader';
import {
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
  mattersListWrapperStyles,
} from '../matters-list-table/styles';
import FileOrDocCell from './FileOrDocCell';
import FileTypeCell from './FileTypeCell';

interface DocumentsListTableProps {
  documentsListData: any | null;
  loading: boolean;
  listClass: string;
  dashboard?: boolean;
  setCheckedDocs?: any;
  setSelectedDocs?: any;
  handleDetailDoc?: any;
  informationSideBarHandler?: any;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No documents.
    </Typography>
  </GridOverlay>
);

const DocumentsListTable = ({
  documentsListData,
  loading,
  dashboard = false,
  listClass,
  setCheckedDocs,
  setSelectedDocs,
  handleDetailDoc,
  informationSideBarHandler,
}: DocumentsListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && documentsListData?.documents !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, documentsListData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return <FileOrDocCell file={params.row.folder} name={cellValue} />;
      },
    },
    {
      field: 'updated',
      headerName: 'Last Modified',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;

        const jsDate = new Date(cellValue);

        const day = jsDate.getDate().toString().padStart(2, '0');
        const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
        const year = jsDate.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;

        return (
          <Box>
            <p className="MuiDataGrid-cellContent">{formattedDate}</p>
          </Box>
        );
      },
    },
    {
      field: 'file_extension',
      headerName: 'Document type',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return <FileTypeCell type={cellValue || ''} />;
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'access',
      headerName: 'Access',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
  ];

  const rowsAreEmpty = documentsListData?.documents.length === 0;

  // Now `modifiedTasksListData` contains the array of objects with or without the 'description' field based on the dashboard prop

  return (
    <Box sx={mattersListWrapperStyles(rowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={documentsListData?.documents as any[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: dashboard ? 6 : 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection={!dashboard}
            onRowSelectionModelChange={(ids) => {
              setCheckedDocs(ids);
              if (ids.length !== 0) {
                setSelectedDocs(
                  documentsListData?.documents.find(
                    (row: any) => row.id === ids[0]
                  )
                );
              }
            }}
            onRowClick={(params) => {
              if (handleDetailDoc) handleDetailDoc(params.row);
              if (!params.row.folder) {
                setSelectedDocs(
                  documentsListData?.documents.find(
                    (row: any) => row.id === params.id
                  )
                );
                if (informationSideBarHandler) {
                  informationSideBarHandler();
                }
              }
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            hideFooter={dashboard}
            sx={mattersListStyles}
            className={listClass}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DocumentsListTable;
