import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fraudDocDetailRequest,
  matterFraudDetectionListRequest,
  requestDocumentAnalysisRequest,
} from '../../../api/matters';
import LEIAAContext from '../../../context/context';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import FraudListTable from '../../shared/fraud-list-table/FraudListTable';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import { primaryButtonStyles } from '../../styles';

interface MatterDetailDocumentFraudTabProps {
  matterId: string;
  matterDetails: any;
}

const MatterDetailDocumentFraudTab = ({
  matterId,
  matterDetails,
}: MatterDetailDocumentFraudTabProps) => {
  const { user } = useContext(LEIAAContext);
  const [matterDetailFraudDatabase, setMatterDetailFraudDatabase] =
    useState<any>();
  const [checkedFraudDocument, setCheckedFraudDocument] = useState([]);
  const [selectedFraudDocument, setSelectedFraudDocument] = useState<
    any | null
  >(null);
  const [loadingFraud, setLoadingFraud] = useState(true);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const navigate = useNavigate();
  const [showAnalysisStarted, setShowAnalysisStarted] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDetailFraudDocument = async (fraudDoc: any) => {
    try {
      fraudDocDetailRequest(fraudDoc.analysis).then((response: any) => {
        navigate(`/matters/detail/${matterId}/fraud/${fraudDoc.analysis}`, {
          state: {
            response,
            matterDetails,
          },
        });
      });
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleGetMatterFraudDetectionList = async () => {
    setLoadingFraud(true); // Start loading
    try {
      if (matterId) {
        const response = await matterFraudDetectionListRequest(
          matterId,
          inputRef.current?.value || ''
        );

        setMatterDetailFraudDatabase(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoadingFraud(false);
    }
  };

  const handleAnalyzeDocs = async () => {
    setShowAnalysisStarted(true);
    await requestDocumentAnalysisRequest(matterId, checkedFraudDocument);
    await handleGetMatterFraudDetectionList();
  };

  const handleListSearchbarInputChange = () => {
    handleGetMatterFraudDetectionList();
  };

  useEffect(() => {
    handleGetMatterFraudDetectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showAnalysisStarted && (
        <CreationNotification
          title="Documents analysis successfully started!"
          subTitle=""
          showNotification={showAnalysisStarted}
          handleCloseNotification={() => setShowAnalysisStarted(false)}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '40px',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '32px',
            }}
          >
            <Button
              variant="contained"
              onClick={handleAnalyzeDocs}
              sx={{ ...primaryButtonStyles(), height: '40px' }}
              disabled={
                checkedFraudDocument.length <= 0 ||
                !['Admin', 'Superuser', 'Leader'].includes(user?.role || '')
              }
            >
              <span
                style={{
                  color:
                    checkedFraudDocument.length <= 0 ||
                    !['Admin', 'Superuser', 'Leader'].includes(user?.role || '')
                      ? '#f3f3f3'
                      : '',
                }}
                className="material-icons-round"
              >
                manage_search
              </span>
              <Typography>Analyze Documents</Typography>
            </Button>
          </Box>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by document..."
              sx={{
                ...homePortalSearchInputStyles,
                marginLeft: 'auto',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
          </Stack>
        </Box>
      </Box>
      <FraudListTable
        fraudListData={matterDetailFraudDatabase}
        listClass="fraud-list"
        loading={loadingFraud}
        setCheckedFraudDocument={setCheckedFraudDocument}
        setSelectedFraudDocument={setSelectedFraudDocument}
        handleDetailFraudDocument={handleDetailFraudDocument}
      />
    </>
  );
};

export default MatterDetailDocumentFraudTab;
