import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  matterDetailRelativityFileListRequest,
  matterDetailRelativityDocOpsRequest,
  handleGetRelativityMetadata,
  matterDetailDocumentsRequest,
} from '../../api/matters';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import { LEIAAInput, LEIAASelect } from '../inputs';
import CreationNotification from '../shared/creation-notification/CreationNotification';
import { homePortalSearchInputStyles } from '../shared/resource-page-header/styles';
import NotificationToast from '../shared/toast/NotificationToast';
import { primaryButtonStyles, secondaryButtonStyles } from '../styles';
import RelativityAuth from './RelativityAuth';
import RelativityDocumentsTable from './RelativityDocumentsTable';
import {
  relativityFilterByButtonStyles,
  relativityActionsBoxStyles,
  metadataSpanStyles,
} from './styles';

interface RelativityTabProps {
  matterId: string;
  relativityUserData: any;
  setRelativityUserData: any;
}

const RelativityTab = ({
  matterId,
  relativityUserData,
  setRelativityUserData,
}: RelativityTabProps) => {
  const [ediscoveryDocListData, setEdiscoveryDocListData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [checkedEdiscoveryDocuments, setCheckedEdiscoveryDocuments] = useState(
    []
  );
  const [selectedEdiscoveryDocument, setSelectedEdiscoveryDocument] = useState<
    any | null
  >(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [operationMessage, setOperationMessage] = useState<string>('');
  const [showOperationMessage, setShowOperationMessage] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedFilterBy, setSelectedFilterBy] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedFilterValue, setSelectedFilterValue] = useState<any>('');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const { control, setValue } = useForm();
  const [openMetadataModal, setOpenMetadataModal] = useState(false);
  const [openImportIntoFolderModal, setOpenImportIntoFolderModal] =
    useState(false);
  const [clickedDocument, setClickedDocument] = useState<any>(false);
  const [matterDocs, setMatterDocs] = useState([]);

  const handleDetailEdiscoveryDocument = async (document: any) => {
    // after specing and refining turn this on
    // navigate(`/document/${document.id}`, {
    //   state: {
    //     docData: document,
    //     matterId,
    //     type: 'E-Discovery',
    //     previous: 'relativity-tab',
    //   },
    // });
    setClickedDocument(document);
    setOpenMetadataModal(true);
  };

  const handleGetSortFields = async () => {
    try {
      const response = await handleGetRelativityMetadata(
        {
          username: relativityUserData.username,
          password: relativityUserData.password,
        },
        matterId,
        'fields'
      );

      setFilters(response.fields);
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  };

  const handleGetEdiscoveryDocuments = async (
    filterBy = selectedFilterBy,
    filterValue = selectedFilterValue
  ) => {
    setLoading(true);
    try {
      if (filters.length === 0) await handleGetSortFields();
      const response = await matterDetailRelativityFileListRequest(
        {
          username: relativityUserData.username,
          password: relativityUserData.password,
        },
        matterId,
        inputRef.current?.value || '',
        filterBy,
        filterValue
      );

      setEdiscoveryDocListData(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentsOperations = async (
    action: string,
    all_documents: boolean,
    folder?: string
  ) => {
    try {
      if (action === 'pull') {
        await matterDetailRelativityDocOpsRequest(
          {
            username: relativityUserData.username,
            password: relativityUserData.password,
          },
          matterId,
          action,
          all_documents
            ? ediscoveryDocListData.documents.map((doc: any) => doc.id)
            : checkedEdiscoveryDocuments,
          folder
        );
        setOperationMessage(
          'Importing E-Discovery documents, this may take a while.'
        );
      }
      // TODO  else push logic when unblocked
      // adds a little delay for UX - status updates, although detached we still need to create the link record
      setLoading(true);
      setShowOperationMessage(true);
      setTimeout(() => {
        handleGetEdiscoveryDocuments();
      }, 4000);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handleListSearchbarInputChange = () => {
    handleGetEdiscoveryDocuments();
  };

  const handleGetMatterDetailDocuments = async () => {
    try {
      const response = await matterDetailDocumentsRequest(matterId, '');
      setMatterDocs(response.documents);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  useEffect(() => {
    if (relativityUserData.connected) {
      handleGetEdiscoveryDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relativityUserData]);

  useEffect(() => {
    handleGetMatterDetailDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetFilterBy = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('filterBy', event.target.value);
    setSelectedFilterBy(event.target.value);
  };

  const handleFolderSelect = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('selectedFolder', event.target.value);
    setSelectedFolder(event.target.value);
  };

  const handleClearFilters = () => {
    setValue('filterBy', '');
    setSelectedFilterBy('');
    setValue('filterValue', '');
    setSelectedFilterValue('');
    handleGetEdiscoveryDocuments('', '');
    setOpenFilterModal(false);
  };

  const handleCloseFolderSelection = () => {
    setValue('selectedFolder', '');
    setSelectedFolder('');
    setOpenImportIntoFolderModal(false);
  };

  const handleFilterSearch = () => {
    setOpenFilterModal(false);
    handleGetEdiscoveryDocuments();
  };

  const renderValue = (value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return value.map((item, index) => (
          <span style={metadataSpanStyles} key={index}>
            {renderValue(item)}
          </span>
        ));
      }
      // Render object properties
      const objectKeys = Object.keys(value);
      if (objectKeys.length === 0) {
        return 'Empty Object';
      }
      return objectKeys.map((key, index) => (
        <span style={metadataSpanStyles} key={index}>
          {index !== 0 && ', '}
          {key}: {renderValue(value[key])}
        </span>
      ));
    }
    return <span style={metadataSpanStyles}>{value || ''}</span>;
  };

  return (
    <Box sx={{ marginTop: '40px' }}>
      <RelativityAuth
        matterId={matterId}
        relativityUserData={relativityUserData}
        setRelativityUserData={setRelativityUserData}
      />
      {relativityUserData.connected && (
        <>
          {showErrorNotification && (
            <NotificationToast
              showNotification={showErrorNotification}
              requestError={requestError}
              handleCloseNotification={() => setShowErrorNotification(false)}
            />
          )}
          {showOperationMessage && (
            <CreationNotification
              title={operationMessage}
              subTitle=""
              showNotification={showOperationMessage}
              handleCloseNotification={() => setShowOperationMessage(false)}
            />
          )}
          {openFilterModal && (
            <Modal
              open={openFilterModal}
              onClose={() => setOpenFilterModal(false)}
              aria-labelledby="modal-filter-title"
              aria-describedby="modal-filter-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '736px',
                  padding: '48px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  border: '1px solid #D3ECF6',
                  background: '#FFF',
                  boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  Choose a metadata filter to apply and write your condition
                  below.
                  <br />
                  <span style={{ fontSize: '12px', color: 'gray' }}>
                    For condition rules, please refer to{' '}
                    <a
                      href="https://platform.relativity.com/RelativityOne/Content/REST_API/Resource_Tasks/Querying_for_resources.htm#Syntaxforqueryconditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this guide
                    </a>
                    .
                  </span>
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <LEIAASelect
                    labelText=""
                    control={control}
                    controllerName="filterBy"
                    inputPlaceholder="Select field to filter"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '300px' }}
                    handleChange={handleSetFilterBy}
                  >
                    {filters?.map((name, index) => {
                      return (
                        <MenuItem key={index} value={name}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </LEIAASelect>
                  <Box sx={{ width: '100%' }}>
                    <LEIAAInput
                      labelText=""
                      controllerName="filterValue"
                      control={control}
                      inputPlaceholder="Enter filter condition"
                      inputWidth="100%"
                      inputHeight="40px"
                      handleChange={(e: React.ChangeEvent<{ value: any }>) => {
                        setSelectedFilterValue(e);
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '100%', marginTop: '35px !important' }}
                >
                  <Button
                    variant="contained"
                    sx={{ ...secondaryButtonStyles(), width: '100%' }}
                    onClick={handleClearFilters}
                  >
                    <Typography>Clear filter</Typography>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ ...primaryButtonStyles(), width: '100%' }}
                    onClick={handleFilterSearch}
                  >
                    <Typography>Search</Typography>
                    <span className="material-icons-round">manage_search</span>
                  </Button>
                </Stack>
              </Box>
            </Modal>
          )}
          {openImportIntoFolderModal && (
            <Modal
              open={openImportIntoFolderModal}
              onClose={handleCloseFolderSelection}
              aria-labelledby="modal-metadata-title"
              aria-describedby="modal-metadata-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '736px',
                  padding: '48px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  border: '1px solid #D3ECF6',
                  background: '#FFF',
                  boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  Select Folder to import into
                </Typography>
                <Stack>
                  <LEIAASelect
                    labelText=""
                    control={control}
                    controllerName="selectedFolder"
                    inputPlaceholder="Select folder to import into"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '300px' }}
                    handleChange={handleFolderSelect}
                  >
                    {matterDocs
                      ?.filter((doc: any) => doc.folder)
                      .map((folder: any) => {
                        return (
                          <MenuItem key={folder.id} value={folder.id}>
                            {folder.name}
                          </MenuItem>
                        );
                      })}
                  </LEIAASelect>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '100%', marginTop: '35px !important' }}
                >
                  <Button
                    variant="contained"
                    sx={{ ...primaryButtonStyles(), width: '100%' }}
                    onClick={() => {
                      handleDocumentsOperations(
                        'pull',
                        false,
                        selectedFolder.split('-')[1]
                      );
                      setOpenImportIntoFolderModal(false);
                    }}
                    disabled={selectedFolder === ''}
                  >
                    <Typography>Import into folder</Typography>
                    <span className="material-icons-round">
                      drive_folder_upload
                    </span>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ ...secondaryButtonStyles(), width: '100%' }}
                    onClick={handleCloseFolderSelection}
                  >
                    <Typography>Cancel</Typography>
                  </Button>
                </Stack>
              </Box>
            </Modal>
          )}

          {openMetadataModal && (
            <Modal
              open={openMetadataModal}
              onClose={() => setOpenMetadataModal(false)}
              aria-labelledby="modal-metadata-title"
              aria-describedby="modal-metadata-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '736px',
                  padding: '48px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  border: '1px solid #D3ECF6',
                  background: '#FFF',
                  boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  E-Discovery document metadata
                </Typography>
                {clickedDocument && (
                  <Box
                    sx={{
                      padding: '0px 16px',
                      maxHeight: '200px',
                      overflowY: 'scroll',
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                      sx={{
                        marginBottom: '10px',
                      }}
                    >
                      {clickedDocument.metadata.map((item: any, index: any) => (
                        <Box key={index}>
                          <Typography>
                            <span
                              style={{
                                color: '#464B53',
                                fontSize: '16px',
                                ...karlaProRegularFontStyles,
                                lineHeight: '22px',
                              }}
                            >
                              {item.Name}
                            </span>
                            : {renderValue(item.Value)}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                )}

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '100%', marginTop: '35px !important' }}
                >
                  <Button
                    variant="contained"
                    sx={{ ...secondaryButtonStyles(), width: '100%' }}
                    onClick={() => setOpenMetadataModal(false)}
                  >
                    <Typography>Close</Typography>
                  </Button>
                </Stack>
              </Box>
            </Modal>
          )}

          <Box sx={relativityActionsBoxStyles}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '32px',
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDocumentsOperations('pull', true);
                  }}
                  sx={{ ...primaryButtonStyles(), height: '40px' }}
                >
                  <span className="material-icons-round">cloud_download</span>
                  <Typography>Import All</Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDocumentsOperations('pull', false);
                  }}
                  sx={{ ...secondaryButtonStyles(), height: '40px' }}
                  disabled={checkedEdiscoveryDocuments.length === 0}
                >
                  <span className="material-icons-round">
                    system_update_alt
                  </span>
                  <Typography>Import Selected</Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenImportIntoFolderModal(true);
                  }}
                  sx={{ ...secondaryButtonStyles(), height: '40px' }}
                  disabled={checkedEdiscoveryDocuments.length === 0}
                >
                  <span className="material-icons-round">
                    system_update_alt
                  </span>
                  <Typography>Import into folder</Typography>
                </Button>
              </Box>

              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Button
                  id="filter-button"
                  aria-haspopup="true"
                  onClick={() => setOpenFilterModal(true)}
                  sx={relativityFilterByButtonStyles}
                  disabled={filters.length === 0}
                >
                  <Typography variant="caption" component="p">
                    Filter
                  </Typography>
                </Button>
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Search by document..."
                  sx={{
                    ...homePortalSearchInputStyles,
                    marginLeft: 'auto',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ '& > span': { color: '#69707B' } }}
                      >
                        <span className="material-icons-round">search</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    onChange: handleListSearchbarInputChange,
                    inputRef,
                  }}
                  variant="standard"
                />
              </Stack>
            </Box>
            {/* <ListButtons
            deleteHandler={handleBatchDeleteDocs}
            checkedResources={checkedDocs}
            resourceName={checkedBundles.length > 1 ? 'documents' : 'document'}
            renameHandler={() => setRenameDialogOpen(true)}
            handleDownloadFile={handleDownloadFile}
          /> */}
          </Box>
          <RelativityDocumentsTable
            ediscoveryDocListData={ediscoveryDocListData}
            listClass="relativity-list"
            loading={loading}
            setCheckedEdiscoveryDocuments={setCheckedEdiscoveryDocuments}
            setSelectedEdiscoveryDocument={setSelectedEdiscoveryDocument}
            handleDetailEdiscoveryDocument={handleDetailEdiscoveryDocument}
          />
        </>
      )}
    </Box>
  );
};

export default RelativityTab;
