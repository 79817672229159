import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { forwardRef, useImperativeHandle } from 'react';

const LoadPlugin = forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext();

  useImperativeHandle(ref, () => ({
    loadState: (state: any) => {
      const editorState = editor.parseEditorState(state);
      editor.setEditorState(editorState);
    },
  }));

  return null;
});

LoadPlugin.displayName = 'LoadPlugin';

export default LoadPlugin;
