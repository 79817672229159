import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { sofiaProBoldFontStyles } from '../../../styles/textStyles';
import {
  ResourcePageHeaderWrapperStyles,
  homePortalSearchInputStyles,
} from './styles';

interface ResourcePageHeaderProps {
  inputRef: React.RefObject<HTMLInputElement>;
  headerText: string;
  // handleInputChange: (event: {
  //   target: {
  //     value: SetStateAction<string>;
  //   };
  // }) => void;
}

const ResourcePageHeader = ({
  inputRef,
  headerText,
}: // handleInputChange,
ResourcePageHeaderProps) => {
  return (
    <Box sx={ResourcePageHeaderWrapperStyles}>
      <Typography
        variant="h4"
        sx={{
          ...sofiaProBoldFontStyles,
          letterSpacing: '2px',
          textTransform: 'uppercase',
          fontSize: '18px',
        }}
      >
        {headerText}
      </Typography>
      {/* Search input */}
      <TextField
        id="input-with-icon-textfield"
        placeholder="Search..."
        sx={homePortalSearchInputStyles}
        style={{ display: 'none' }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ '& > span': { color: '#69707B' } }}
            >
              <span className="material-icons-round">search</span>
            </InputAdornment>
          ),
          disableUnderline: true,
          // onChange: handleInputChange,
          inputRef,
        }}
        variant="standard"
      />
    </Box>
  );
};

export default ResourcePageHeader;
