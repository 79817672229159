import { Box, Button, Typography } from '@mui/material';
import { matterCreationFooterWrapperStyles } from '../matters/matter-creation/styles';
import {
  tertiaryButtonStyles,
  secondaryButtonStyles,
  primaryButtonStyles,
} from '../styles';

interface WikiCreationFooterProps {
  handleCancel: () => void;
  handlePrevious: () => void;
  isLastStep: boolean;
  activeStep: number;
}

const WikiCreationFooter = ({
  handleCancel,
  handlePrevious,
  isLastStep,
  activeStep,
}: WikiCreationFooterProps) => {
  const nextButtonContent = isLastStep ? (
    <>
      <Typography>Save</Typography>
      <span className="material-icons-round">save</span>
    </>
  ) : (
    <>
      <Typography>Next</Typography>
      <span className="material-icons-round">arrow_forward</span>
    </>
  );

  return (
    <Box sx={matterCreationFooterWrapperStyles}>
      <Box>
        <Button
          sx={tertiaryButtonStyles()}
          variant="contained"
          onClick={handleCancel}
        >
          <Typography>Cancel</Typography>
        </Button>

        {activeStep !== 0 ? (
          <Button
            sx={secondaryButtonStyles()}
            variant="contained"
            onClick={handlePrevious}
          >
            <span className="material-icons-round">arrow_back</span>
            <Typography>Previous</Typography>
          </Button>
        ) : null}

        <Button
          variant="contained"
          sx={{ ...secondaryButtonStyles(), display: 'none' }}
        >
          <span className="material-icons-round">draft</span>
          <Typography>Save as draft</Typography>
        </Button>

        <Button variant="contained" sx={primaryButtonStyles()} type="submit">
          {nextButtonContent}
        </Button>
      </Box>
    </Box>
  );
};

export default WikiCreationFooter;
