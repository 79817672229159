import { useEffect, useRef, useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ResourcePageHeader from '../components/shared/resource-page-header/ResourcePageHeader';
import './styles/userlist.css';
import '../components/styles/datagridStyles.css';
import BaseButton from '../components/base/BaseButton';
import UsersListTable from '../components/shared/users-list-table/UsersListTable';
import { homePortalSearchInputStyles } from '../components/shared/resource-page-header/styles';
import ListButtons from '../components/shared/list-buttons/ListButtons';
import { User } from '../types/auth';
import { batchDeleteUserRequest, usersRequest } from '../api/users';
import { useUsersPermissions } from '../hooks/usePermissions';
import CreationNotification from '../components/shared/creation-notification/CreationNotification';
import NotificationToast from '../components/shared/toast/NotificationToast';

const UsersList = () => {
  const userPerms = useUsersPermissions();
  const navigate = useNavigate();
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [usersListData, setUsersListData] = useState<User[] | null>(null);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);

  const handleFetchUsersList = async (searchText = '') => {
    setLoading(true); // Start loading

    try {
      const response = await usersRequest(
        'GET',
        undefined,
        searchText,
        undefined
      );
      setUsersListData(response as User[]);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setRequestError(error.response.data.message);
        setShowNotification(true);
      } else {
        setRequestError('An unknown error related to the users list occurred.');
        setShowNotification(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBatchDeleteUsers = async () => {
    try {
      await batchDeleteUserRequest(checkedUsers);
      await handleFetchUsersList();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchUsersList(searchText);
  };

  const handleEditUser = () => {
    navigate(`/user-details/${selectedUser?.id}/edit`, {
      state: { user: selectedUser },
    });
  };

  const handleDetailUser = (_user: any) => {
    navigate(`/user-details/${_user?.id}`, {
      state: { user: _user },
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="User archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      <ResourcePageHeader inputRef={searchInputRef} headerText="Users" />

      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {userPerms.add && (
            <BaseButton
              iconClass="material-icons-round"
              iconText="add"
              buttonText="New User"
              linkTo="/users/create"
              width="151px"
            />
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
              marginLeft: !userPerms.add ? 'auto' : undefined,
            }}
          >
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by username..."
              sx={{
                ...homePortalSearchInputStyles,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      '& > span': { color: '#69707B' },
                    }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
            {checkedUsers.length > 0 &&
              (userPerms.change || userPerms.delete) && (
                <ListButtons
                  deleteHandler={userPerms.delete && handleBatchDeleteUsers}
                  editHandler={userPerms.change && handleEditUser}
                  checkedResources={checkedUsers}
                  resourceName="user"
                />
              )}
          </Box>
        </Box>

        <Grid item xs={12} md={12}>
          <UsersListTable
            usersListData={usersListData}
            loading={loading}
            setCheckedUsers={setCheckedUsers}
            setSelectedUser={setSelectedUser}
            handleDetailUser={handleDetailUser}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default UsersList;
