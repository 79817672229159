import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';
import online from '../../assets/online.jpg';
import phone from '../../assets/phone.jpg';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../styles/textStyles';
import { getReportUiDataRquest } from '../../api/whistleblower';
import { tertiaryButtonStyles } from '../../components/styles';
import ReportingFormPhone from './ReportingFormPhone';
import ReportingOnlineForm from './ReportingOnlineForm';
import ReportingImageHeader from './ReportingImageHeader';

const ConcernTypeBox = ({
  copy,
  setConcernType,
}: {
  copy: any;
  setConcernType: any;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Box>
      <img
        src={copy.photo}
        style={{
          borderRadius: '12px',
          width: '100%',
          height: '150px',
          objectFit: 'cover',
          objectPosition: copy.objectPosition,
        }}
        alt={copy.title}
        onLoad={() => setImageLoaded(true)}
      />
      {imageLoaded && (
        <>
          <Typography
            sx={{
              ...sofiaProBoldFontStyles,
              color: '#202020',
              textTransform: 'uppercase',
              fontSize: '18px',
              fontWeight: '500',
              marginTop: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',

              '& > span': {
                fontSize: '18px',
                color: '#1a5eff',
              },
            }}
          >
            <span className="material-icons-round">{copy.icon}</span>
            {copy.title}
          </Typography>
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontSize: '14px',
              color: '#202020',
              marginTop: '5px',
            }}
          >
            {copy.content}
          </Typography>
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              paddingLeft: '0px',
              marginRight: 'auto',
            }}
            variant="text"
            onClick={() => setConcernType(copy.onClick)}
          >
            <Typography sx={{ fontSize: '17px' }}>{copy.buttonText}</Typography>
          </Button>
        </>
      )}
    </Box>
  );
};

const ReportingForm = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [concernType, setConcernType] = useState<string | null>(null);
  const [phoneLines, setPhoneLines] = useState<any[]>([]);
  const [configCopy, setConfigCopy] = useState<any>(null);
  const [copyList, setCopyList] = useState<any[]>([]);

  const handleFetchUIData = async () => {
    try {
      setLoadingData(true);
      const response = await getReportUiDataRquest();
      setPhoneLines(response.hotlines);
      setConfigCopy({
        header: response.reporting_form_main_header,
        content: response.reporting_form_main_content,
      });
      setCopyList([
        {
          title: response.reporting_form_form_header,
          content: response.reporting_form_form_content,
          buttonText: response.reporting_form_form_link,
          onClick: 'form',
          icon: 'language',
          photo: online,
          objectPosition: 'unset',
        },
        {
          title: response.reporting_form_phone_header,
          content: response.reporting_form_phone_content,
          buttonText: response.reporting_form_phone_link,
          onClick: 'phone',
          icon: 'phone',
          photo: phone,
          objectPosition: 'unset',
        },
      ]);
      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchUIData();
  }, []);

  return loadingData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        '& > span': {
          color: '#0053FF',
        },
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: { xs: '10px 20px', md: '10px 50px' },
      }}
    >
      <ReportingImageHeader />

      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          textTransform: 'uppercase',
          fontSize: { xs: '20px', md: '25px' },
          fontWeight: '500',
          textAlign: 'center',
          margin: 'auto',
          marginBottom: { xs: 2, md: 3 },
        }}
      >
        {configCopy.header}
      </Typography>

      <Typography
        sx={{
          ...karlaProRegularFontStyles,
          fontSize: { xs: '12px', md: '14px' },
          color: '#202020',
          marginTop: '20px',
          paddingLeft: { xs: '5%', md: '20%' },
          paddingRight: { xs: '5%', md: '20%' },
          textAlign: 'center',
        }}
      >
        {configCopy.content}
      </Typography>

      {concernType === null && (
        <Grid
          container
          spacing={2}
          sx={{ padding: { xs: '20px', md: '40px' } }}
        >
          {copyList.map((copy, index) => (
            <Grid item xs={12} md={6} key={index}>
              <ConcernTypeBox setConcernType={setConcernType} copy={copy} />
            </Grid>
          ))}
        </Grid>
      )}

      {/* FORM */}
      {concernType === 'form' && <ReportingOnlineForm />}
      {/* PHONE */}
      {concernType === 'phone' && (
        <ReportingFormPhone phoneLines={phoneLines} />
      )}
    </Box>
  );
};

export default ReportingForm;
