import { Box, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoaderSpinner from '../components/shared/misc-components/LoaderSpinner';
import { primaryButtonStyles } from '../components/styles';
import LEIAAContext from '../context/context';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../styles/textStyles';

const ErrorPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(LEIAAContext);
  const location = useLocation();
  const goBackDashboard = location.state?.goBackDashboard || false;

  const handleGoBack = () => {
    if (goBackDashboard) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  return user && user.role ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '> *': {
            margin: '10px 0px !important',
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{ ...sofiaProBoldFontStyles, color: '#0053FF' }}
        >
          404
        </Typography>
        <Typography
          variant="h3"
          sx={{ ...sofiaProBoldFontStyles, color: '#0053FF' }}
        >
          PAGE NOT FOUND
        </Typography>
        <Typography
          variant="caption"
          component="p"
          sx={{ ...karlaProRegularFontStyles }}
        >
          Something went wrong. The page that you are looking for does not exit.
        </Typography>
        <Button
          variant="contained"
          onClick={handleGoBack}
          sx={primaryButtonStyles()}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  ) : (
    <LoaderSpinner loading onClose={() => console.log('')} />
  );
};

export default ErrorPage;
