import { Box, Button } from '@mui/material';
import BaseButton from '../base/BaseButton';
import { homePortalButtonsStyles } from './styles';

const HomePortalButtons = () => {
  return (
    <Box sx={homePortalButtonsStyles}>
      <Button sx={{ display: 'none !important' }}>
        <span className="material-icons-round">filter_alt</span>
        Filter
      </Button>
      <BaseButton
        iconClass="material-icons-round"
        iconText="add"
        buttonText="New Matter"
        linkTo="/matters/new"
      />
    </Box>
  );
};

export default HomePortalButtons;
