import { LexicalComposer } from '@lexical/react/LexicalComposer';
import './index.css';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import Editor from './Editor';
import { TableContext } from './plugins/TablePlugin';
import { useSettings } from './context/SettingsContext';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';

interface ReportEditorProps {
  editorRef: any;
}

const ReportEditor = ({ editorRef }: ReportEditorProps) => {
  const {
    settings: { isCollab, emptyEditor },
  } = useSettings();

  let editorState;

  if (isCollab) {
    editorState = null;
  } else if (emptyEditor) {
    editorState = undefined;
  }

  const initialConfig = {
    editorState,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <SharedAutocompleteContext>
            <div className="editor-shell">
              <Editor ref={editorRef} />
            </div>
            {/* {measureTypingPerf ? <TypingPerfPlugin /> : null} */}
          </SharedAutocompleteContext>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
};

export default ReportEditor;
