import { karlaProRegularFontStyles } from '../../styles/textStyles';

export const relativityActionsBoxStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '40px',
  alignSelf: 'stretch',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
};

export const relativityFilterByButtonStyles = {
  boxShadow: 'none',
  '& > p': {
    width: '100%',
    ...karlaProRegularFontStyles,
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.2px',
  },
  display: 'flex',
  height: '40px',
  padding: '8px 8px 8px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  color: '#0053FF',
  textTransform: 'none',
  '&:hover': {
    color: '#0B25B0',
  },
  '&:active': {
    color: '#140070',
  },
};

export const metadataSpanStyles = {
  fontSize: '16px',
  ...karlaProRegularFontStyles,
  lineHeight: '22px',
  fontWeight: '500',
};
