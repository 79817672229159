// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/store';
import { MatterUser } from '../../../types/matters';

// Define a type for the slice state
interface MatterUsers {
  users: MatterUser[];
}

// Define the initial state using that type
const initialState: MatterUsers = {
  users: [],
};

export const matterUsersSlice = createSlice({
  name: 'matterUsers',
  initialState,
  reducers: {
    addMatterUser: (state, action: PayloadAction<MatterUser>) => {
      const { payload } = action;
      // Check if the array is empty
      if (state.users.length === 0) {
        state.users.push(payload);
      } else {
        // Add the file to the array
        state.users.push(payload);
      }
    },
    updateMatterUser: (state, action: PayloadAction<MatterUser>) => {
      const { payload } = action;
      const { users } = state;

      // Find the index of the user in the array
      const userIndex = users.findIndex((user) => user.id === payload.id);

      if (userIndex !== -1) {
        // Update the user object with the new values
        users[userIndex] = payload;
      }
    },
    removeMatterUser: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      // Find the index of the file in the array
      const index = state.users.findIndex((user) => user.id === payload);
      // Remove the file if found
      if (index !== -1) {
        state.users.splice(index, 1);
      }
    },
    clearMatterUsers: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.users = [];
    },
  },
});

export const {
  addMatterUser,
  updateMatterUser,
  removeMatterUser,
  clearMatterUsers,
} = matterUsersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMatterUsers = (state: RootState) =>
  state.matterUsersReducer.users;

export default matterUsersSlice.reducer;
