import { Box, Typography } from '@mui/material';
import { creationNotificationStyles } from '../matters/matter-creation/styles';

interface CreationDocumentNotificationProps {
  showNotification: boolean;
  handleCloseNotification: () => void;
}

const CreationDocumentNotification = ({
  showNotification,
  handleCloseNotification,
}: CreationDocumentNotificationProps) => {
  return (
    <Box sx={creationNotificationStyles(showNotification)}>
      <Box>
        <Box>
          <span className="material-icons-round">check_circle</span>
        </Box>
        <Box>
          <Box>
            <Typography>New document added successfully!</Typography>
            <Typography>
              You can access your new document in the Repository List.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box onClick={handleCloseNotification}>
        <Typography>Close</Typography>
      </Box>
    </Box>
  );
};

export default CreationDocumentNotification;
