import { InterviewsListItem } from '../components/shared/interviews-list-table/types';
import { MattersListItem } from '../components/shared/matters-list-table/types';
import {
  AllocatedUsersResponse,
  InterviewsListResponse,
  MatterInfoResponse,
  MattersListResponse,
  ScheduledInterviewsResponse,
} from '../types/homeportal';
import { transformArrayObjectKeysToCamelCase } from '../utils/utils';
import api from './index';

async function matterInfoRequest(): Promise<MatterInfoResponse> {
  const response = await api.get('/dashboard-matter-information');

  return {
    insightOne: response.data.active_clients,
    insightTwo: response.data.active_matters,
    insightThree: response.data.avg_matter_duration,
  };
}

async function allocatedUsersRequest(): Promise<AllocatedUsersResponse> {
  const response = await api.get('/dashboard-allocated-users');
  // const transformedData: AllocatedUsersItem[] =
  //   transformArrayObjectKeysToCamelCase(
  //     response.data.allocated_users
  //   ) as AllocatedUsersItem[];

  return {
    allocated_users: response.data.allocated_users,
  };
}

async function scheduledInterviewsRequest(
  startDate: string
): Promise<ScheduledInterviewsResponse> {
  const response = await api.get('/dashboard-scheduled-interviews', {
    params: { start_day: startDate },
  });
  // const transformedData = response.data.scheduled_interviews.map(
  //   (item: ScheduledInterviewsItem) => ({
  //     ...item,
  //     matters: item.matters.map((matter: ScheduledInterviewsMattersItem) => ({
  //       ...matter,
  //       interviews: transformArrayObjectKeysToCamelCase(matter.interviews),
  //     })),
  //   })
  // );

  return {
    scheduled_interviews: response.data.scheduled_interviews,
  };
}

async function mattersListRequest(
  dashboard: boolean,
  searchText: string,
  client = null
): Promise<MattersListResponse> {
  const filters: { limit?: number; search?: string; client_id?: string } = {};

  if (dashboard) {
    filters.limit = 6;
  }

  if (client) {
    filters.client_id = client;
  }

  if (searchText !== '') {
    filters.search = searchText;
  }

  const response = await api.get('/dashboard-matter-list', {
    params: filters,
  });

  const transformedData: MattersListItem[] =
    transformArrayObjectKeysToCamelCase(
      response.data.matters_list
    ) as MattersListItem[];

  return {
    mattersList: transformedData,
  };
}

async function interviewsListRequest(
  searchText: string,
  matterId: any
): Promise<InterviewsListResponse> {
  const filters: { search_text?: string; matter_id?: number } = {};

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  if (matterId !== undefined) {
    filters.matter_id = matterId;
  }

  const response = await api.get('/interviews', {
    params: filters,
  });

  const transformedData: InterviewsListItem[] =
    transformArrayObjectKeysToCamelCase(
      response.data.interviews
    ) as InterviewsListItem[];

  return {
    interviewsList: transformedData,
  };
}

async function clientsListRequest(searchText: string): Promise<any> {
  const filters: { search_text?: string } = {};

  if (searchText !== '') {
    filters.search_text = searchText;
  }

  const response = await api.get('/clients', {
    params: filters,
  });

  const transformedData: any[] = transformArrayObjectKeysToCamelCase(
    response.data.clients
  ) as any[];
  return {
    clients: transformedData,
  };
}

async function clientDashboardRequest(
  clientId: string,
  startDate: string
): Promise<any> {
  const response = await api.get(`client/${clientId}`, {
    params: { start_day: startDate },
  });

  return response.data;
}

async function feedbackRequest(
  relevance: number | null,
  speed: number | null,
  process: number | null,
  recommended: boolean | null,
  suggestions: string | null
) {
  const data: { [key: string]: any } = {};

  if (relevance !== -1) {
    data.relevant_data = relevance;
  }
  if (speed !== -1) {
    data.report_speed = speed;
  }
  if (process !== -1) {
    data.interview_process = process;
  }
  if (recommended !== undefined) {
    data.recommended = recommended;
  }
  if (suggestions !== undefined || suggestions !== '') {
    data.suggestions = suggestions;
  }

  await api.post(`/feedback`, data);
}

async function fetchIncidentListRequest(resource: any = undefined) {
  const filters: { resource?: string } = {};

  if (resource) {
    filters.resource = resource;
  }

  const response = await api.get(`/incidents`, {
    params: filters,
  });
  return response.data;
}

export {
  matterInfoRequest,
  allocatedUsersRequest,
  scheduledInterviewsRequest,
  mattersListRequest,
  interviewsListRequest,
  clientsListRequest,
  clientDashboardRequest,
  feedbackRequest,
  fetchIncidentListRequest,
};
