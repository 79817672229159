import api from './index';

async function getLivekitTokenRequest(interviewId?: string): Promise<any> {
  const response = await api.get('/interview-token', {
    params: {
      interview_id: interviewId,
    },
  });

  if (response.data.token) {
    localStorage.setItem('livekit_token', response.data.token);
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getLivekitTokenRequest };
