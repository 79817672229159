import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../styles/textStyles';
import FileTypeCell from '../shared/docs-list-table/FileTypeCell';
import LEIAAPagination from '../shared/lists/LEIAAPagination';
import SortableHeader from '../shared/matters-list-table/SortableHeader';
import {
  mattersListWrapperStyles,
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
} from '../shared/matters-list-table/styles';
import ImanageStatus from './ImanageStatus';

interface ImanageDocumentsTableProps {
  imanageDocListData: any | null;
  loading: boolean;
  listClass: string;
  setCheckedImanageDocuments?: any;
  setSelectedImanageDocument?: any;
  handleDetailImanageDocument?: any;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No iManage documents.
    </Typography>
  </GridOverlay>
);

const ImanageDocumentsTable = ({
  imanageDocListData,
  loading,
  listClass,
  setCheckedImanageDocuments,
  setSelectedImanageDocument,
  handleDetailImanageDocument,
}: ImanageDocumentsTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && imanageDocListData?.documents !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, imanageDocListData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'file_extension',
      headerName: 'Document type',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      // eslint-disable-next-line react/no-unused-prop-types
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return <FileTypeCell type={cellValue || ''} />;
      },
    },
    {
      field: 'imanage_doc_number',
      headerName: 'Imanage ID',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;
        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return (
          <ImanageStatus
            status={cellValue}
            heightValue="30px"
            wrapperHeight="36px"
          />
        );
      },
    },
  ];

  const rowsAreEmpty = imanageDocListData?.documents.length === 0;

  return (
    <Box sx={mattersListWrapperStyles(rowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={imanageDocListData?.documents as any[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              setCheckedImanageDocuments(ids);
              if (ids.length !== 0) {
                setSelectedImanageDocument(
                  imanageDocListData?.documents.find(
                    (row: any) => row.id === ids[0]
                  )
                );
              }
            }}
            onRowClick={(params) => {
              if (params.row.analysis !== '"-"') {
                handleDetailImanageDocument(params.row);
              }
              // maybe insert warning?
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            hideFooter={false}
            sx={mattersListStyles}
            className={listClass}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <CircularProgress />
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '22px',
                  lineHeight: '22px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Syncing LEIAA documents with iManage database...
              </Typography>
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ImanageDocumentsTable;
