import { Fragment, useEffect, useState } from 'react';
import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import { Controller, FieldValues, UseFormWatch } from 'react-hook-form';
import { AuthProps, LoginError } from '../../../types/authProps';
import {
  forgotPasswordButtonStyles,
  formInputWrapperStyles,
  loginFormButtonStyles,
  loginFormInputStyles,
} from '../styles';
import PasswordInputInstructions from './PasswordInputInstructions';
import ShowPassword from './ShowPassword';

export interface FormField {
  name: string;
  label: string;
  inputPlaceholder: string;
  type?: string;
  required?: boolean;
}

export interface FormProps extends AuthProps {
  fields: FormField[];
  submitButtonText: string;
  showForgotPasswordButton?: boolean;
  handleForgotPassword?: () => void | undefined;
  isResetPasswordStep?: boolean;
  watch: UseFormWatch<FieldValues>;
  errors?: LoginError;
}

const Form = ({
  handleSubmit,
  onSubmit,
  control,
  fields,
  submitButtonText,
  showForgotPasswordButton = false,
  handleForgotPassword = undefined,
  isResetPasswordStep = false,
  watch,
  errors,
}: FormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorsData, setErrorsData] = useState(errors);
  const fieldsData = watch();
  const showPasswordInputInstructions =
    showForgotPasswordButton || isResetPasswordStep;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleInputsAreFilled = () => {
    if (Object.keys(fieldsData).length === 0) {
      return false;
    }

    return Object.values(fieldsData).every((value) => value !== '');
  };

  useEffect(() => {
    setErrorsData(errors);
  }, [errors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
        {fields.map(
          ({
            name,
            label,
            inputPlaceholder,
            type = 'text',
            required = false,
          }) => {
            const isError = errorsData && errorsData.field === name;
            const errorMessage = isError ? errorsData.message : '';

            return (
              <Fragment key={name}>
                <Box sx={formInputWrapperStyles}>
                  <InputLabel data-testid="form-label-id" htmlFor={name}>
                    {label}
                  </InputLabel>
                  <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, ...rest } }) => {
                      return (
                        <TextField
                          {...rest}
                          placeholder={inputPlaceholder}
                          InputProps={{
                            endAdornment:
                              name === 'password' ||
                              name === 'two_factor_auth_code' ||
                              name === 'new_password' ? (
                                <ShowPassword
                                  showPassword={showPassword}
                                  handleClickShowPassword={
                                    handleClickShowPassword
                                  }
                                />
                              ) : null,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            'data-testid': 'form-text-field-id',
                          }}
                          required={required}
                          onChange={(e) => {
                            onChange(e.target.value);
                            if (isError) {
                              setErrorsData(undefined); // Clear the error when the value changes
                            }
                          }}
                          error={isError}
                          helperText={errorMessage}
                          sx={loginFormInputStyles('352px', '40px')}
                          InputLabelProps={{ shrink: false }}
                          type={
                            // eslint-disable-next-line no-nested-ternary
                            type === 'password'
                              ? showPassword
                                ? 'text'
                                : 'password'
                              : type
                          }
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        />
                      );
                    }}
                  />
                </Box>
              </Fragment>
            );
          }
        )}
      </Box>

      {showPasswordInputInstructions && (
        <PasswordInputInstructions fieldsData={fieldsData} />
      )}

      {showForgotPasswordButton && (
        <Button
          data-testid="forgot-password-link-id"
          onClick={handleForgotPassword}
          sx={forgotPasswordButtonStyles}
        >
          <Typography>Forgot password?</Typography>
        </Button>
      )}

      <Button
        data-testid="form-button"
        className="form-action-button"
        disabled={!handleInputsAreFilled()}
        variant="contained"
        type="submit"
        sx={loginFormButtonStyles(submitButtonText)}
      >
        {submitButtonText}
      </Button>
    </form>
  );
};

export default Form;
