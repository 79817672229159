import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { forwardRef, useImperativeHandle } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';
import { $createParagraphNode, $getRoot } from 'lexical';

const HTMLSeederLoaderPlugin = forwardRef((props, ref) => {
  const [editor] = useLexicalComposerContext();

  useImperativeHandle(ref, () => ({
    loadHTMLState: (state: any) => {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(state, 'text/html');

        const nodes = $generateNodesFromDOM(editor, dom);

        const root = $getRoot();

        const paragraphNode = $createParagraphNode();

        nodes.forEach((n) => paragraphNode.append(n));

        root.append(paragraphNode);
      });
    },
  }));

  return null;
});

HTMLSeederLoaderPlugin.displayName = 'HTMLSeederLoaderPlugin';

export default HTMLSeederLoaderPlugin;
