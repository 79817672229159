/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Drawer, Typography, List, ListItem, Button } from '@mui/material';
import {
  karlaProRegularFontStyles,
  karlaProBoldFontStyles,
} from '../../../styles/textStyles';
import { tertiaryButtonStyles } from '../../styles';

interface FolderFileInformationDrawerProps {
  item: any;
  open: boolean;
  setOpen: any;
  folderPermissionsHandler?: any;
  folderUploadLinkHandler?: any;
  handleDownloadFile?: any;
  addToFraudDetectionHandler?: any;
  renameHandler?: any;
  fileDownloadLinkHandler?: any;
}

const FolderFileInformationDrawer = ({
  item,
  open,
  setOpen,
  folderPermissionsHandler,
  folderUploadLinkHandler,
  handleDownloadFile,
  addToFraudDetectionHandler,
  renameHandler,
  fileDownloadLinkHandler,
}: FolderFileInformationDrawerProps) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US');
  };

  const folderDataGenerator = (folderItem: any) => {
    const uniqueDocs = new Set();
    const uniqueFolders = new Set();

    const traverse = (folder: any) => {
      (folder.subfolders || []).forEach((subfolder: any) => {
        uniqueFolders.add(subfolder.id);
        traverse(subfolder);
      });

      (folder.subfolders || []).forEach((subfolder: any) => {
        (subfolder.docs || []).forEach((doc: any) => uniqueDocs.add(doc.id));
      });

      (folder.content || []).forEach((content: any) =>
        uniqueDocs.add(content.id)
      );
    };

    traverse(folderItem);

    return {
      uniqueDocs: uniqueDocs.size,
      uniqueFolders: uniqueFolders.size,
    };
  };

  const folderMetaData = item.folder
    ? folderDataGenerator(item)
    : {
        uniqueDocs: '',
        uniqueFolders: '',
      };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ width: '400px', padding: '16px' }} role="presentation">
        <Typography
          variant="h5"
          mb={2}
          sx={{
            ...karlaProBoldFontStyles,
          }}
        >
          {item.name}
        </Typography>

        <List sx={{ marginBottom: '8px' }}>
          <ListItem>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ ...karlaProBoldFontStyles, marginRight: '5px' }}
              >
                Type:
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{
                  ...karlaProRegularFontStyles,
                }}
              >
                {item.folder ? ' Folder' : ' File'}
              </Typography>
            </Box>
          </ListItem>

          {item.folder ? (
            <>
              {[
                { label: 'Unique Documents', value: folderMetaData.uniqueDocs },
                { label: 'Sub-Folders', value: folderMetaData.uniqueFolders },
                {
                  label: 'Permissions',
                  value: item.permissions
                    .map((user: any) => user.name)
                    .join(', '),
                },
              ].map(({ label, value }, index) => (
                <ListItem key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ ...karlaProBoldFontStyles, marginRight: '5px' }}
                    >
                      {label}:{' '}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{
                        ...karlaProRegularFontStyles,
                      }}
                    >
                      {value}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </>
          ) : (
            <>
              {[
                { label: 'File Extension', value: item.file_extension },
                { label: 'Source', value: item.source },
                { label: 'Last Modified', value: formatDate(item.updated) },
                {
                  label: 'Permissions',
                  value: item.permissions
                    .map((user: any) => user.name)
                    .join(', '),
                },
              ].map(({ label, value }, index) => (
                <ListItem key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ ...karlaProBoldFontStyles, marginRight: '5px' }}
                    >
                      {label}:{' '}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{
                        ...karlaProRegularFontStyles,
                      }}
                    >
                      {value}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </>
          )}
        </List>

        <List sx={{ marginBottom: '8px' }}>
          <ListItem>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ ...karlaProBoldFontStyles, marginRight: '5px' }}
              >
                {item.folder ? 'Folder ' : 'File '} Actions:
              </Typography>
            </Box>
          </ListItem>
          {renameHandler && (
            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <Button
                  sx={{
                    ...tertiaryButtonStyles(),
                    height: '34px',
                  }}
                  variant="text"
                  onClick={() => renameHandler()}
                >
                  <span
                    style={{ fontSize: '24px' }}
                    className="material-icons-round"
                  >
                    border_color
                  </span>
                  <Typography>Rename</Typography>
                </Button>
              </Box>
            </ListItem>
          )}
          {item.folder ? (
            <>
              {folderPermissionsHandler && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => folderPermissionsHandler()}
                    >
                      <span
                        style={{ fontSize: '24px' }}
                        className="material-icons-round"
                      >
                        manage_accounts
                      </span>
                      <Typography>Folder Permissions</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
              {folderUploadLinkHandler && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => folderUploadLinkHandler()}
                    >
                      <span
                        style={{ fontSize: '24px' }}
                        className="material-icons-round"
                      >
                        drive_folder_upload
                      </span>
                      <Typography>Folder Upload Link</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
            </>
          ) : (
            <>
              {handleDownloadFile && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => handleDownloadFile()}
                    >
                      <span
                        style={{ fontSize: '24px' }}
                        className="material-icons-round"
                      >
                        cloud_download
                      </span>
                      <Typography>Download</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
              {addToFraudDetectionHandler &&
                process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' && (
                  <ListItem>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <Button
                        sx={{
                          ...tertiaryButtonStyles(),
                          height: '34px',
                        }}
                        variant="text"
                        onClick={() => addToFraudDetectionHandler()}
                      >
                        <span
                          style={{
                            fontSize: '24px',
                          }}
                          className="material-icons-round"
                        >
                          add
                        </span>
                        <Typography>Add to Fraud Detection Database</Typography>
                      </Button>
                    </Box>
                  </ListItem>
                )}
              {fileDownloadLinkHandler && (
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '100%',
                    }}
                  >
                    <Button
                      sx={{
                        ...tertiaryButtonStyles(),
                        height: '34px',
                      }}
                      variant="text"
                      onClick={() => fileDownloadLinkHandler()}
                    >
                      <span
                        style={{
                          fontSize: '24px',
                        }}
                        className="material-icons-round"
                      >
                        file_present
                      </span>
                      <Typography>Create Download Link</Typography>
                    </Button>
                  </Box>
                </ListItem>
              )}
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default FolderFileInformationDrawer;
