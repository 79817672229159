import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { LEIAASelect } from '../inputs';
import {
  matterDetailDocumentsRequest,
  matterDetailImanageDocOpsRequest,
  matterDetailImanageFileListRequest,
} from '../../api/matters';
import { imanageActionsBoxStyles } from './styles';
import { homePortalSearchInputStyles } from '../shared/resource-page-header/styles';
import NotificationToast from '../shared/toast/NotificationToast';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  tertiaryButtonStyles,
} from '../styles';
import ImanageAuth from './ImanageAuth';
import ImanageDocumentsTable from './ImanageDocumentsTable';
import CreationNotification from '../shared/creation-notification/CreationNotification';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import ImanageExportDialog from './ImanageExportDialog';

const MetadataItem: React.FC<{
  label: any;
  value: any;
}> = ({ label, value }: { label: any; value: any }) => (
  <Typography>
    <span
      style={{
        color: '#464B53',
        fontSize: '16px',
        ...karlaProRegularFontStyles,
        lineHeight: '22px',
      }}
    >
      {label}
    </span>
    :{' '}
    <span style={{ display: 'inline-block', wordBreak: 'break-all' }}>
      {String(value)}
    </span>
  </Typography>
);
interface ImanageTabProps {
  matterId: string;
  imanageUserData: any;
  setImanageUserData: any;
  matterDetailDocs: any;
  documentsOptions: any;
}

const ImanageTab = ({
  matterId,
  imanageUserData,
  setImanageUserData,
  matterDetailDocs,
  documentsOptions,
}: ImanageTabProps) => {
  const [imanageDocListData, setImanageDocListData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [checkedImanageDocuments, setCheckedImanageDocuments] = useState([]);
  const [selectedImanageDocument, setSelectedImanageDocument] = useState<
    any | null
  >(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFilterBy, setSelectedFilterBy] = useState('');
  const [selectedFilterValue, setSelectedFilterValue] = useState<any>('');
  const [clickedDocument, setClickedDocument] = useState<any>(false);
  const [openExportDialog, setOpenExportDialog] = useState<any>(false);
  const [operationMessage, setOperationMessage] = useState<string>('');
  const [showOperationMessage, setShowOperationMessage] = useState(false);
  const [openMetadataModal, setOpenMetadataModal] = useState(false);
  const [openImportIntoFolderModal, setOpenImportIntoFolderModal] =
    useState(false);
  const [selectedFolder, setSelectedFolder] = useState('');
  const { control, setValue } = useForm();
  const [matterDocs, setMatterDocs] = useState([]);

  const handleCloseFolderSelection = () => {
    setValue('selectedFolder', '');
    setSelectedFolder('');
    setOpenImportIntoFolderModal(false);
  };

  const handleGetMatterDetailDocuments = async () => {
    try {
      const response = await matterDetailDocumentsRequest(matterId, '');
      setMatterDocs(response.documents);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handleDetailImanageDocument = async (document: any) => {
    setClickedDocument(document);
    setOpenMetadataModal(true);
  };

  const handleGetImanageDocuments = async (
    filterBy = selectedFilterBy,
    filterValue = selectedFilterValue
  ) => {
    setLoading(true);
    try {
      const response = await matterDetailImanageFileListRequest(
        {
          token: imanageUserData.token,
          customer: imanageUserData.customer,
        },
        matterId,
        inputRef.current?.value || '',
        filterBy,
        filterValue
      );

      setImanageDocListData(response);
    } catch (error: any) {
      setImanageDocListData({ documents: [] });
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentsOperations = async (
    action: string,
    all_documents: boolean,
    docs_to_upload?: any[],
    folder?: string
  ) => {
    try {
      if (action === 'pull') {
        await matterDetailImanageDocOpsRequest(
          {
            token: imanageUserData.token,
            customer: imanageUserData.customer,
          },
          matterId,
          action,
          all_documents
            ? imanageDocListData.documents.map((doc: any) => doc.id)
            : checkedImanageDocuments,
          folder
        );
        setOperationMessage(
          'Importing iManage documents, this may take a while.'
        );
      } else if (action === 'push') {
        if (!docs_to_upload) {
          return;
        }
        const files: number[] = [];
        docs_to_upload.forEach((element: any) => {
          if (element.folder) {
            if (element.documents && Array.isArray(element.documents)) {
              element.documents
                .filter((resource: any) => !resource.folder)
                .forEach((doc: any) => {
                  files.push(doc.id);
                });
            }
            if (element.subfolders && Array.isArray(element.subfolders)) {
              element.subfolders.forEach((subfolder: any) => {
                if (subfolder.docs && Array.isArray(subfolder.docs)) {
                  subfolder.docs.forEach((doc: any) => {
                    files.push(doc.id);
                  });
                }
              });
            }
          } else {
            files.push(element.id);
          }
        });

        await matterDetailImanageDocOpsRequest(
          {
            token: imanageUserData.token,
            customer: imanageUserData.customer,
          },
          matterId,
          action,
          files
        );
        setOperationMessage(
          'Uploading documents, your files should arrive at imanage in a few minutes.'
        );
      }
      setLoading(true);
      setShowOperationMessage(true);
      handleGetImanageDocuments();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handleListSearchbarInputChange = () => {
    handleGetImanageDocuments();
  };

  const handleFolderSelect = (event: React.ChangeEvent<{ value: any }>) => {
    setValue('selectedFolder', event.target.value);
    setSelectedFolder(event.target.value);
  };

  useEffect(() => {
    if (imanageUserData.connected) {
      handleGetImanageDocuments();
      handleGetMatterDetailDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imanageUserData]);

  return (
    <Box sx={{ marginTop: '40px' }}>
      <ImanageAuth
        matterId={matterId}
        imanageUserData={imanageUserData}
        setImanageUserData={setImanageUserData}
      />
      {imanageUserData.connected && (
        <>
          {showErrorNotification && (
            <NotificationToast
              showNotification={showErrorNotification}
              requestError={requestError}
              handleCloseNotification={() => setShowErrorNotification(false)}
            />
          )}
          {showOperationMessage && (
            <CreationNotification
              title={operationMessage}
              subTitle=""
              showNotification={showOperationMessage}
              handleCloseNotification={() => setShowOperationMessage(false)}
            />
          )}

          <ImanageExportDialog
            open={openExportDialog}
            onClose={() => setOpenExportDialog(false)}
            docList={matterDetailDocs?.documents}
            documentsOptions={documentsOptions}
            matterId={matterId}
            handleDocumentsOperations={handleDocumentsOperations}
          />

          {openMetadataModal && (
            <Modal
              open={openMetadataModal}
              onClose={() => setOpenMetadataModal(false)}
              aria-labelledby="modal-metadata-title"
              aria-describedby="modal-metadata-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '736px',
                  padding: '48px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  border: '1px solid #D3ECF6',
                  background: '#FFF',
                  boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  iManage document metadata
                </Typography>
                {clickedDocument && (
                  <Box
                    sx={{
                      padding: '0px 16px',
                      maxHeight: '200px',
                      overflowY: 'scroll',
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                      sx={{
                        marginBottom: '10px',
                      }}
                    >
                      {Object.entries(clickedDocument.metadata).map(
                        ([key, value]) => (
                          <MetadataItem key={key} label={key} value={value} />
                        )
                      )}
                    </Stack>
                  </Box>
                )}

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '100%', marginTop: '35px !important' }}
                >
                  <Button
                    variant="contained"
                    sx={{ ...secondaryButtonStyles(), width: '100%' }}
                    onClick={() => setOpenMetadataModal(false)}
                  >
                    <Typography>Close</Typography>
                  </Button>
                </Stack>
              </Box>
            </Modal>
          )}

          {openImportIntoFolderModal && (
            <Modal
              open={openImportIntoFolderModal}
              onClose={handleCloseFolderSelection}
              aria-labelledby="modal-metadata-title"
              aria-describedby="modal-metadata-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '736px',
                  padding: '48px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  border: '1px solid #D3ECF6',
                  background: '#FFF',
                  boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  Select Folder to import into
                </Typography>
                <Stack>
                  <LEIAASelect
                    labelText=""
                    control={control}
                    controllerName="selectedFolder"
                    inputPlaceholder="Select folder to import into"
                    inputHeight="40px !important"
                    inputWidth="100%"
                    paperCustomStylings={{ height: '300px' }}
                    handleChange={handleFolderSelect}
                  >
                    {matterDocs
                      ?.filter((doc: any) => doc.folder)
                      .map((folder: any) => {
                        return (
                          <MenuItem key={folder.id} value={folder.id}>
                            {folder.name}
                          </MenuItem>
                        );
                      })}
                  </LEIAASelect>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '100%', marginTop: '35px !important' }}
                >
                  <Button
                    variant="contained"
                    sx={{ ...primaryButtonStyles(), width: '100%' }}
                    onClick={() => {
                      handleDocumentsOperations(
                        'pull',
                        false,
                        undefined,
                        selectedFolder.split('-')[1]
                      );
                      setOpenImportIntoFolderModal(false);
                    }}
                    disabled={selectedFolder === ''}
                  >
                    <Typography>Import into folder</Typography>
                    <span className="material-icons-round">
                      drive_folder_upload
                    </span>
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ ...secondaryButtonStyles(), width: '100%' }}
                    onClick={handleCloseFolderSelection}
                  >
                    <Typography>Cancel</Typography>
                  </Button>
                </Stack>
              </Box>
            </Modal>
          )}

          <Box sx={imanageActionsBoxStyles}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '32px',
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDocumentsOperations('pull', true);
                  }}
                  sx={{ ...primaryButtonStyles(), height: '40px' }}
                >
                  <span className="material-icons-round">cloud_download</span>
                  <Typography>Import All</Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDocumentsOperations('pull', false);
                  }}
                  sx={{ ...secondaryButtonStyles(), height: '40px' }}
                  disabled={checkedImanageDocuments.length === 0}
                >
                  <span className="material-icons-round">
                    system_update_alt
                  </span>
                  <Typography>Import Selected</Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenImportIntoFolderModal(true);
                  }}
                  sx={{ ...secondaryButtonStyles(), height: '40px' }}
                  disabled={checkedImanageDocuments.length === 0}
                >
                  <span className="material-icons-round">
                    system_update_alt
                  </span>
                  <Typography>Import into folder</Typography>
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenExportDialog(true);
                  }}
                  sx={{ ...tertiaryButtonStyles(), height: '40px' }}
                >
                  <Typography>Export from LEIAA</Typography>
                </Button>
              </Box>

              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Search by document..."
                  sx={{
                    ...homePortalSearchInputStyles,
                    marginLeft: 'auto',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ '& > span': { color: '#69707B' } }}
                      >
                        <span className="material-icons-round">search</span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    onChange: handleListSearchbarInputChange,
                    inputRef,
                  }}
                  variant="standard"
                />
              </Stack>
            </Box>
            {/* <ListButtons
            deleteHandler={handleBatchDeleteDocs}
            checkedResources={checkedDocs}
            resourceName={checkedBundles.length > 1 ? 'documents' : 'document'}
            renameHandler={() => setRenameDialogOpen(true)}
            handleDownloadFile={handleDownloadFile}
          /> */}
          </Box>
          <ImanageDocumentsTable
            imanageDocListData={imanageDocListData}
            listClass="imanage-list"
            loading={loading}
            setCheckedImanageDocuments={setCheckedImanageDocuments}
            setSelectedImanageDocument={setSelectedImanageDocument}
            handleDetailImanageDocument={handleDetailImanageDocument}
          />
        </>
      )}
    </Box>
  );
};

export default ImanageTab;
