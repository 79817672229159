import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';

interface StatusChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const StatusChip = ({
  status,
  heightValue,
  wrapperHeight,
}: StatusChipProps) => {
  let backgroundColor;
  const color = '#FFFFFF';

  switch (status) {
    case 'New':
      backgroundColor = '#38B763';
      break;
    case 'Open':
      backgroundColor = '#3375FF';
      break;
    case 'Delayed':
      backgroundColor = '#EDB900';
      break;
    case 'Blocked':
      backgroundColor = '#E22727';
      break;
    case 'Closed':
      backgroundColor = '#A962D5';
      break;
    default:
      backgroundColor = '#CCCCCC';
      break;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        height: wrapperHeight,
        alignSelf: 'flex-start',
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 10px',
          gap: '4px',
          heightValue,
          backgroundColor,
          borderRadius: '10px',
          '& > p': {
            ...karlaProRegularFontStyles,
            textTransform: 'capitalize',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.3px',
            color,
          },
        },
      }}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default StatusChip;
