/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  Button,
  Stack,
  CircularProgress,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import {
  getWhistleblowerReport,
  pushToMatterRequest,
  getReportAdminDataRequest,
  updateAdminReportRequest,
} from '../../../api/whistleblower';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import ReportingChat from '../ReportingChat';
import { ReportInformation } from '../ReportingFollowDashboard';
import {
  primaryButtonStyles,
  simpleGoBackButtonStyles,
  tertiaryButtonStyles,
} from '../../../components/styles';
import {
  LEIAAInput,
  LEIAASelect,
  LEIAAStatusSelect,
} from '../../../components/inputs';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationWrapperStyles,
} from '../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  reportStatusSelectStyles,
  reportWhistleblowerStatusSelectStyles,
  severitySelectStyles,
} from '../../../components/inputs/styles';
import CreationNotification from '../../../components/shared/creation-notification/CreationNotification';
import {
  a11yProps,
  CustomTabPanel,
} from '../../../components/matters/matter-detail/MatterDetailDocuments';
import AllegationSubjects from './AllegationSubjects';
import LEIAAContext from '../../../context/context';

const ReportDetail = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [reportData, setReportData] = useState<any>(null);
  const [filledReport, setFilledReport] = useState<any>(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingAdminData, setLoadingAdminData] = useState(true);
  const [loadingPushToMatter, setLoadingPushToMatter] = useState(false);
  const [assignableUsers, setAssignableUsers] = useState<any[]>([]);
  const [reportAdminData, setReportAdminData] = useState<any>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [tabValue, setTabValue] = useState(state?.defaultTab || 0);
  const listRef = useRef<HTMLUListElement>(null);
  const { user } = useContext(LEIAAContext);

  const goBack = () => {
    if (state?.previous) {
      if (state.previous === 'kanban') {
        navigate(`/reports`, { state: { currentStep: 1 } });
      } else if (state.previous === 'list') {
        navigate(`/reports`, { state: { currentStep: 0 } });
      } else if (state.previous === 'matter-detail-detail') {
        navigate(`/matters/detail/${reportAdminData?.matter}`, {
          state: { currentStep: 1 },
        });
      }
    } else {
      navigate(-1);
    }
  };

  const handleFetchReport = async () => {
    setLoadingData(true);
    try {
      const response = await getWhistleblowerReport(id);
      setReportData(response.report_information);
      setFilledReport(response.initial_report_data);

      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const pushToMatterHandler = async () => {
    setLoadingPushToMatter(true);

    try {
      const response = await pushToMatterRequest(id);
      setLoadingPushToMatter(false);

      navigate(`/matters/detail/${response.id}`, {
        state: {},
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFetchReportAdminData = async () => {
    setLoadingAdminData(true);
    try {
      const response = await getReportAdminDataRequest(id);
      setReportAdminData(response);
      setAssignableUsers(response.assignable);
      setLoadingAdminData(false);
      setValue('severity', response.severity);
      setValue('adminstatus', response.adminstatus);
      setValue('status', response.status);
      setValue('subject', response.subject);
      setValue('assignee', response.assignee);
      setValue('engagedPersons', response.engagedPersons);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      await updateAdminReportRequest(id, data);
      handleFetchReportAdminData();
      setShowNotification(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleSetMatterLead = (event: React.ChangeEvent<{ value: any }>) => {
    const userId = event.target.value;
    const assignedUser = assignableUsers?.find(
      (u) => Object.keys(u)[0] === userId
    );
    const assigneeName = assignedUser ? Object.values(assignedUser)[0] : '';

    setValue('assigneeName', assigneeName);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!['Admin', 'Superuser', 'Leader'].includes(user.role)) navigate(-1);
    handleFetchReport();
    handleFetchReportAdminData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px 0 32px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflowY: 'scroll',
      }}
      ref={listRef}
      className="scrollable-content"
    >
      <Button
        sx={{ ...simpleGoBackButtonStyles, marginRight: 'auto' }}
        variant="text"
        onClick={() => goBack()}
      >
        <span className="material-symbols-outlined">arrow_back</span>
        <Typography variant="caption" component="p">
          Back
        </Typography>
      </Button>
      {showNotification && (
        <CreationNotification
          title="Saved successfully!"
          subTitle=""
          showNotification={showNotification}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {
        // eslint-disable-next-line no-nested-ternary
        loadingData || loadingAdminData || loadingPushToMatter ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
            {loadingPushToMatter && (
              <Typography
                sx={{
                  ...sofiaProBoldFontStyles,
                  color: '#202020',
                  fontSize: '20px',
                  fontWeight: '500',
                  marginTop: '10px',
                }}
              >
                Creating matter ...
              </Typography>
            )}
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                ...sofiaProBoldFontStyles,
                color: '#202020',
                textTransform: 'uppercase',
                fontSize: '25px',
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              {reportData.topic} - {reportAdminData.publicid}
            </Typography>

            <Box
              sx={{
                width: '100%',
                border: '1px solid #a4c1fd',
                padding: '20px',
                margin: '15px 0',
                borderRadius: '12px',
              }}
            >
              {/* PROGRESS */}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                  '& > div > p': {
                    fontSize: '16px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                    fontWeight: '500',
                  },
                  '& > div > p > span': {
                    color: '#464B53',
                    fontSize: '16px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                  },
                }}
              >
                <Box>
                  <Typography>
                    {/* <span className="material-icons-round">summarize</span> */}
                    <span>Stage: </span>
                    {reportAdminData.stage}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {/* <span className="material-icons-round">summarize</span> */}
                    <span>Severity: </span>
                    {reportAdminData.severity}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {/* <span className="material-icons-round">today</span>{' '} */}
                    <span>Days open: </span> {reportAdminData.daysopen}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {/* <span className="material-icons-round">summarize</span> */}
                    <span>Date Received: </span>
                    {reportAdminData.date}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  width: '100%',
                  borderBottom: '1px solid #949CA9',
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    flexGrow: 1,
                    '& > div': {
                      '& > div': {
                        '& > button': {
                          display: 'flex',
                          flex: '1',
                          padding: '8px 24px',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: '8px',
                          borderRadius: '24px 24px 0px 0px',
                          '&.Mui-selected': {
                            backgroundColor: '#F5FAFD',
                            color: '#0053FF',
                          },

                          color: '#69707B',
                          textTransform: 'none',
                          ...karlaProRegularFontStyles,
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '22px',
                        },
                      },
                      '& > span.MuiTabs-indicator': {
                        backgroundColor: '#0053FF',
                      },
                    },
                  }}
                >
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons-round">
                          find_in_page
                        </span>
                        <span style={{ marginLeft: '8px' }}>Status</span>
                      </div>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons-round">
                          people_outline
                        </span>
                        <span style={{ marginLeft: '8px' }}>
                          Allegation subjects
                        </span>
                      </div>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons-round">file_copy</span>
                        <span style={{ marginLeft: '8px' }}>
                          Initial Report Information
                        </span>
                      </div>
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons-round">
                          question_answer
                        </span>
                        <span style={{ marginLeft: '8px' }}>
                          Secure Communication
                        </span>
                      </div>
                    }
                    onClick={() => {
                      if (listRef.current) {
                        listRef.current.scrollTop =
                          listRef.current.scrollHeight;
                      }
                    }}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                width: '100%',
                padding: '15px 5px 10px 5px',
                marginTop: tabValue === 3 ? 'auto' : 'unset',
              }}
            >
              <Box sx={{ width: '100%' }}>
                {/* STATUS CONTROLS */}
                <CustomTabPanel value={tabValue} index={0}>
                  <Box
                    sx={{
                      marginRight: 'auto',
                      width: '100%',
                      padding: '24px 16px',
                      borderRadius: '24px',
                      backgroundColor: '#F5FAFD',
                    }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box sx={matterInformationWrapperStyles}>
                        <Box
                          sx={{
                            ...matterInformationFormWrapperStyles,
                            width: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              ...matterInformationInputWrapperStyles,
                              paddingTop: '5px',
                            }}
                          >
                            <Box sx={{ width: '40%' }}>
                              <LEIAASelect
                                labelText="Matter lead"
                                control={control}
                                controllerName="assignee"
                                inputPlaceholder="Select matter lead"
                                inputHeight="40px !important"
                                inputWidth="100%"
                                paperCustomStylings={{
                                  height: '300px',
                                }}
                                handleChange={handleSetMatterLead}
                              >
                                {assignableUsers?.map((userObj: any) => {
                                  const userId = Object.keys(
                                    userObj
                                  )[0] as string;
                                  const name = Object.values(
                                    userObj
                                  )[0] as string;

                                  return (
                                    <MenuItem key={userId} value={userId}>
                                      {name}
                                    </MenuItem>
                                  );
                                })}
                              </LEIAASelect>
                            </Box>
                            <Box sx={{ width: '60%' }}>
                              <LEIAAInput
                                labelText="Subject"
                                controllerName="subject"
                                control={control}
                                inputPlaceholder="Enter subject"
                                inputWidth="100%"
                                inputHeight="100px"
                                multiline
                                required
                                customClassName="scrollable-content"
                              />
                            </Box>
                            <Box>
                              <LEIAAStatusSelect
                                labelText="Severity"
                                controllerName="severity"
                                control={control}
                                required
                                statusSelectStyle={severitySelectStyles}
                                defaultValue="TBD"
                              >
                                <MenuItem value="0">0</MenuItem>
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="TBD">TBD</MenuItem>
                              </LEIAAStatusSelect>
                            </Box>
                            <Box>
                              <LEIAAStatusSelect
                                labelText="Status"
                                controllerName="adminstatus"
                                control={control}
                                required
                                statusSelectStyle={reportStatusSelectStyles}
                                defaultValue="New"
                              >
                                <MenuItem value="New">New</MenuItem>
                                <MenuItem value="Open">Open</MenuItem>
                                <MenuItem value="Delayed">Delayed</MenuItem>
                                <MenuItem value="Blocked">Blocked</MenuItem>
                                <MenuItem value="Closed">Closed</MenuItem>
                              </LEIAAStatusSelect>
                            </Box>
                            <Box>
                              <LEIAAStatusSelect
                                labelText="Whistleblower Facing Status"
                                controllerName="status"
                                control={control}
                                required
                                statusSelectStyle={
                                  reportWhistleblowerStatusSelectStyles
                                }
                                defaultValue="Received"
                              >
                                <MenuItem value="Received">Received</MenuItem>
                                <MenuItem value="In Progress">
                                  In Progress
                                </MenuItem>
                                <MenuItem value="Completed">Completed</MenuItem>
                                <MenuItem value="Further Information Required">
                                  Further Information Required
                                </MenuItem>
                              </LEIAAStatusSelect>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  ...karlaProRegularFontStyles,
                                  fontWeight: 400,
                                  fontSize: '14px',
                                  lineHeight: '22px',
                                }}
                              >
                                Related Matter
                              </Typography>
                              <Box sx={{ marginTop: '-30px' }}>
                                {reportAdminData?.matter ? (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      ...tertiaryButtonStyles(),
                                      height: '40px',
                                    }}
                                    onClick={() =>
                                      navigate(
                                        `/matters/detail/${reportAdminData?.matter}`,
                                        {
                                          state: {},
                                        }
                                      )
                                    }
                                  >
                                    <Typography>
                                      {reportAdminData?.mattername} - Matter
                                      Dashboard
                                    </Typography>
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      ...tertiaryButtonStyles(),
                                      height: '40px',
                                    }}
                                    onClick={() => pushToMatterHandler()}
                                    disabled={
                                      !(
                                        reportAdminData.severity &&
                                        reportAdminData.status &&
                                        reportAdminData.adminstatus &&
                                        reportAdminData.subject &&
                                        reportAdminData.assignee
                                      )
                                    }
                                  >
                                    <Typography>Push to Matter</Typography>
                                  </Button>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        sx={{ marginTop: '20px' }}
                      >
                        <Button
                          variant="contained"
                          sx={primaryButtonStyles()}
                          type="submit"
                        >
                          <Typography>Save</Typography>
                          <span className="material-icons-round">save</span>
                        </Button>
                      </Stack>
                    </form>
                  </Box>
                </CustomTabPanel>

                {/* ALLEGATION SUBJECTS */}
                <CustomTabPanel value={tabValue} index={1}>
                  <Box
                    sx={{
                      marginRight: 'auto',
                      width: '100%',
                      paddingBottom: '10px',
                    }}
                  >
                    <AllegationSubjects
                      reportAdminData={reportAdminData}
                      refreshData={handleFetchReportAdminData}
                      userRole={user?.role}
                    />
                  </Box>
                </CustomTabPanel>

                {/* REPORT COPY */}
                <CustomTabPanel value={tabValue} index={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginRight: 'auto',
                      width: '100%',
                      borderBottom: '1px solid  #D3ECF6',
                    }}
                  >
                    <ReportInformation data={filledReport} />
                  </Box>
                </CustomTabPanel>

                {/* SECURE CHAT */}
                <CustomTabPanel value={tabValue} index={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginRight: 'auto',
                      width: '100%',
                      marginBottom: '-5px',
                    }}
                  >
                    <ReportingChat reportId={id} />
                  </Box>
                </CustomTabPanel>
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  );
};

export default ReportDetail;
