import * as React from 'react';
import type { Observable } from 'rxjs';

/**
 * @internal
 */
// eslint-disable-next-line import/prefer-default-export
export function useObservableState<T>(
  observable: Observable<T> | undefined,
  startWith: T
) {
  const [state, setState] = React.useState<T>(startWith);
  React.useEffect(() => {
    // observable state doesn't run in SSR
    if (typeof window === 'undefined' || !observable) return;
    const subscription = observable.subscribe(setState);
    // eslint-disable-next-line consistent-return
    return () => subscription.unsubscribe();
  }, [observable]);
  return state;
}
