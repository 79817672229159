import {
  sofiaProRegularFontStyles,
  karlaProRegularFontStyles,
} from '../../../styles/textStyles';

export const interviewPreJoinGuidelinesStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    paddingLeft: '16px',
    alignItems: 'center',
    alignSelf: 'stretch',
    '& > p': {
      ...sofiaProRegularFontStyles,
      fontWeight: 700,
      fontSize: '17px',
      letterSpacing: '0.3px',
      color: '#0053FF',
    },
  },
  '& > div:not(:first-of-type)': {
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    '& > div > p': {
      ...karlaProRegularFontStyles,
      color: '#202020',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
    },
  },
};

export const interviewAutomatedStyles = {
  display: 'flex',
  padding: '16px',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  borderRadius: '16px',
  backgroundColor: '#EBF8FE',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
    '& > p': {
      ...karlaProRegularFontStyles,
      color: '#202020',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      '& > p': {
        ...karlaProRegularFontStyles,
        color: '#32353C',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.2px',
      },
    },
  },
};
