import { Box, Typography } from '@mui/material';
import { mattersListStatusChipWrapperStyles } from '../matters-list-table/styles';

interface ScoreChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const ScoreChip = ({ status, heightValue, wrapperHeight }: ScoreChipProps) => {
  let backgroundColor;
  let color = '#FFFFFF';

  switch (status) {
    case 'TRUSTED':
      backgroundColor = '#38B763';
      break;
    case 'NORMAL':
      backgroundColor = '#3375FF';
      break;
    case 'WARNING':
      backgroundColor = '#EDB900';
      color = '#202020';
      break;
    case 'HIGH_RISK':
      backgroundColor = '#E22727';
      break;
    default:
      backgroundColor = '#e0e0e0'; // Default color if status is not recognized
      break;
  }

  return (
    <Box
      sx={mattersListStatusChipWrapperStyles(
        backgroundColor,
        color,
        heightValue,
        wrapperHeight
      )}
    >
      <Box>
        <Typography>{status === 'HIGH_RISK' ? 'HIGH RISK' : status}</Typography>
      </Box>
    </Box>
  );
};

export default ScoreChip;
