import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../styles/textStyles';

// eslint-disable-next-line import/prefer-default-export
export const interviewDetailStyles = {
  display: 'flex',
  width: '100%',
  padding: '48px 32px',
  backgroundColor: '#FFFFFF',
  overflowX: 'hidden',
  gap: '40px',
  flexDirection: 'column',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '& > div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      '& > div:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        '& > div': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '10px',
          '& > p': {
            color: '#202020',
            fontSize: '18px',
            ...sofiaProBoldFontStyles,
            lineHeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'uppercase',
          },
        },
        '& > button': {
          justifyContent: 'flex-start',
        },
      },
    },
    '& > div:last-of-type': {
      display: 'flex',
      gap: '24px',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
  },
  '& > div:last-of-type': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
    '& > div': {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',
    },
  },
};

export const interviewDetailInfoStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
  padding: '8px 16px',
  border: '1px solid #D3ECF6',
  borderRadius: '12px',
  width: 'fit-content',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > span': {
      color: '#69707B',
    },
    '& > p': {
      color: '#69707B',
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
    },
  },
};
