import { karlaProRegularFontStyles } from '../../styles/textStyles';

export const docsharePaginatorStyles = {
  height: '30px',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '12px',
  backgroundColor: '#464B53',
  ' &:disabled': {
    backgroundColor: '#32353C',
    color: '#CBCFDD',
  },
};

export const docshareActionButtonStyles = {
  ...karlaProRegularFontStyles,
  fontWeight: 500,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#464B53 !important',
  borderRadius: '16px !important',
  gap: '8px',
  width: '170px',
  height: '30px',
  '&:hover': {
    border: '2px solid #0053FF !important',
  },
  '& > p': {
    textAlign: 'center',
    fontSize: '16px',
    ...karlaProRegularFontStyles,
    letterSpacing: '0.2px',
    textTransform: 'none',
    color: 'white',
  },
};

export const docshareSearchInputStyles = {
  '&.MuiFormControl-root': {
    padding: '0px 12px',
    width: '224px',
    height: '30px',
    backgroundColor: '#32353C',
    color: 'white',
    border: '1px solid white',
    borderRadius: '14px',
    transition: 'border-color 0.3s',
    '&:focus-within': {
      borderColor: 'white',
    },

    '& input': {
      caretColor: 'white',
    },
  },
  '& > div': {
    height: 'inherit',
    gap: '8px',
    '&:hover': {
      cursor: 'default',
    },
    '& > input': {
      '&:hover': {
        cursor: 'default',
      },
      padding: '0px',
      height: '22px',
      fontSize: '14px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      '::placeholder': {
        color: 'white',
      },
      color: 'white',
    },
  },
};
