import { karlaProRegularFontStyles } from '../../styles/textStyles';
import { MatterHistory } from '../../types/matters';

export const sortButtonsWrapperStyles = {
  width: '100%',
  display: 'flex',
  gap: '16px',
  '& > button': {
    display: 'flex',
    height: '30px',
    padding: '8px 8px 8px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    border: '1px solid #0053FF',
    background: '#FFF',
    color: '#0053FF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#EBF8FE',
      color: '#0B25B0',
      border: '1px solid #0B25B0',
    },
    '&:focus': {
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:active': {
      backgroundColor: '#EBF8FE',
      borderColor: '#140070',
      color: '#140070',
    },
  },
};

export const sortButtonsPaperWrapperStyles = {
  '& > div.MuiPaper-root': {
    marginTop: '8px',
    display: 'flex',
    padding: '24px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '16px',
    border: '1px solid #0053FF',
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 10px 0px rgba(21, 10, 84, 0.30)',
    '& > ul': {
      padding: '0px',
      width: '100%',
      '& > div': {
        gap: '10px',
        '& > label': {
          display: 'flex',
          height: '32px',
          padding: '0px 16px',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
          margin: '0px',
          '& > span': {
            padding: '0px',
          },
          '& > span:nth-of-type(1) > svg': {
            width: '16px',
            height: '16px',
          },
          '& > span:nth-of-type(2)': {
            color: '#464B53',
            textAlign: 'center',
            fontSize: '14px',
            ...karlaProRegularFontStyles,
            lineHeight: '22px',
          },
        },
      },
    },
  },
};

export const historyListWrapperStyles = {
  width: '100%',
  maxHeight: '100%',
  padding: '0px',
  margin: '0px',
  overflowY: 'scroll',
  '& > li': {
    padding: '0px',
    margin: '0px',
    flexDirection: 'column',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      padding: '10px 0px',
      flexDirection: 'row',
      width: '100%',
      gap: '8px',
      //   icon styles
      '& > div:nth-of-type(1)': {
        height: '26px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > span': {
          color: '#81D4E1',
          lineHeight: '8px',
          fontSize: '8px',
        },
      },
      //   content wrapper
      '& > div:nth-of-type(2)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '12px',
        flex: '1 0 0',
      },
    },
  },
};

export const historyListItemContentWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: '8px',
  '& > p': {
    ...karlaProRegularFontStyles,
    color: '#6F6C7F',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '22px',
    width: 'calc(100% - 140px)',
  },
};

export const matterStatusChipWrapperStyles = (item: MatterHistory) => {
  return {
    display: 'flex',
    height: '24px',
    padding: '6px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '8px',
    backgroundColor: item.isMatterActive ? '#3375FF' : '#CBCFDD',
    alignSelf: 'flex-start',
    '& > p': {
      ...karlaProRegularFontStyles,
      color: item.isMatterActive ? '#fff' : '#6F6C7F',
      fontSize: '14px',
      lineHeight: '22px',
    },
  };
};
