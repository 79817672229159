// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/store';

interface Permission {
  add: boolean;
  change: boolean;
  delete: boolean;
  view: boolean;
}

export interface PermissionsObject {
  matter: Permission;
  users: Permission;
  wiki_template: Permission;
  wiki_repository: Permission;
  matter_documents: Permission;
  matter_tasks: Permission;
  matter_interviews: Permission;
  matter_reports: Permission;
  interviews: Permission;
}

// Define a type for the slice state
interface UserPermissions {
  permissions: PermissionsObject;
}

// Define the initial state using that type
const initialState: UserPermissions = {
  permissions: {
    matter: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    users: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    wiki_template: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    wiki_repository: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    matter_documents: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    matter_tasks: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    matter_interviews: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    matter_reports: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
    interviews: {
      add: false,
      change: false,
      delete: false,
      view: false,
    },
  },
};

export const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    setUserPermissions: (state, action: PayloadAction<PermissionsObject>) => {
      const { payload } = action;
      // eslint-disable-next-line no-param-reassign
      state.permissions = payload;
    },
  },
});

export const { setUserPermissions } = userPermissionsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectUserPermissions = (state: RootState) =>
  state.userPermissionsReducer.permissions;

export default userPermissionsSlice.reducer;
