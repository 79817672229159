import { useEffect, useRef, useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import axios from 'axios';
import ResourcePageHeader from '../components/shared/resource-page-header/ResourcePageHeader';
import './styles/userlist.css';
import '../components/styles/datagridStyles.css';
import { homePortalSearchInputStyles } from '../components/shared/resource-page-header/styles';
import ListButtons from '../components/shared/list-buttons/ListButtons';
import { User } from '../types/auth';
import CreationNotification from '../components/shared/creation-notification/CreationNotification';
import NotificationToast from '../components/shared/toast/NotificationToast';
import AnalysisListTable from '../components/analysis/AnalysisListTable';
import {
  getAnalysisListRequest,
  batchDeleteAnalysisRequest,
  getAnalysisURLRequest,
} from '../api/analysis';
import AnalysisCreateModal from '../components/analysis/AnalysisCreateModal';

const AnalysisList = () => {
  const [checkedAnalysis, setCheckedAnalysis] = useState([]);
  const [selectedAnalysis, setSelectedAnalysis] = useState<User | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [analysisListData, setAnalysisListData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showGeneratedNotification, setShowGeneratedNotification] =
    useState(false);

  const handleFetchAnalysisList = async () => {
    try {
      setLoading(true);

      const response = await getAnalysisListRequest(
        inputRef.current?.value || ''
      );
      setAnalysisListData(response);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setRequestError(error.response.data.message);
        setShowNotification(true);
      } else {
        setRequestError(
          'An unknown error related to the analysis list occurred.'
        );
        setShowNotification(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBatchDeleteAnalysis = async () => {
    try {
      await batchDeleteAnalysisRequest(checkedAnalysis);
      await handleFetchAnalysisList();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleListSearchbarInputChange = () => {
    handleFetchAnalysisList();
  };

  const handleDetailAnalysis = async (analysis: any) => {
    const data = await getAnalysisURLRequest(analysis.id);
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = data.file;
    link.download = analysis.name;
    link.click();
  };

  useEffect(() => {
    handleFetchAnalysisList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Analysis archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {showGeneratedNotification && (
        <CreationNotification
          title="Analysis generated successfully!"
          subTitle=""
          showNotification={showGeneratedNotification}
          handleCloseNotification={() => setShowGeneratedNotification(false)}
        />
      )}
      <ResourcePageHeader inputRef={searchInputRef} headerText="Analysis" />

      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}>
            <AnalysisCreateModal
              refreshData={handleFetchAnalysisList}
              triggerSuccessNotification={setShowGeneratedNotification}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search analysis..."
              sx={{
                ...homePortalSearchInputStyles,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      '& > span': { color: '#69707B' },
                    }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
            {checkedAnalysis.length > 0 && (
              <ListButtons
                deleteHandler={handleBatchDeleteAnalysis}
                checkedResources={checkedAnalysis}
                resourceName="analysis"
              />
            )}
          </Box>
        </Box>

        <Grid item xs={12} md={12}>
          <AnalysisListTable
            analysisListData={analysisListData}
            loading={loading}
            setCheckedAnalysis={setCheckedAnalysis}
            setSelectedAnalysis={setSelectedAnalysis}
            handleDetailAnalysis={handleDetailAnalysis}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default AnalysisList;
