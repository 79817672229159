/* eslint-disable @typescript-eslint/naming-convention */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { MatterInterview } from '../../../../types/matters';
import {
  matterAddedInterviewAccordionDetailsStyles,
  matterAddedInterviewAccordionStyles,
} from '../matter-creation-steps/matter-interviews-step/styles';
import InterviewTypeTag from './InterviewTypeTag';

interface MatterAddedInterviewsInformationProps {
  selectedIndex: number;
  matterInterviews: any;
  handleSelectInterview: (id: string, interviews: any) => void;
  handleRemoveMatterField: (id: string) => void;
  addInterviewBtn?: any;
}

const MatterAddedInterviewsInformation = ({
  selectedIndex,
  matterInterviews,
  handleSelectInterview,
  handleRemoveMatterField,
  addInterviewBtn,
}: MatterAddedInterviewsInformationProps) => {
  const [scopingAccordionExpandedState, setScopingAccordionExpandedState] =
    useState<boolean>(true);

  const [witnessAccordionExpandedState, setWitnessAccordionExpandedState] =
    useState<boolean>(true);

  const validateEmail = (email: string | undefined) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email) {
      return !!email && emailRegex.test(email);
    }
    return false;
  };

  const validInterview = (interview: MatterInterview) => {
    const {
      interview_type,
      interview_category,
      interviewee_first_name,
      interviewee_last_name,
      interviewee_email,
      date,
      start_hour,
      end_hour,
      whistleblower,
    } = interview;

    const commonConditions =
      interview_category === '' ||
      date === '' ||
      start_hour === '' ||
      end_hour === '' ||
      start_hour >= end_hour;

    if (whistleblower) {
      if (interview_type === '' || commonConditions) {
        return false;
      }
    } else if (
      interview_type === '' ||
      interviewee_first_name === '' ||
      interviewee_last_name === '' ||
      !validateEmail(interviewee_email) ||
      commonConditions
    ) {
      return false;
    }

    return true;
  };

  return (
    <Box>
      <Box>
        <Box>
          <Typography>Added Interviews</Typography>
        </Box>
      </Box>
      {/* Scoping Acc */}
      <Accordion
        expanded={scopingAccordionExpandedState}
        onChange={() => {
          setScopingAccordionExpandedState(!scopingAccordionExpandedState);
        }}
        sx={matterAddedInterviewAccordionStyles(
          selectedIndex, // check this
          0, // check this
          scopingAccordionExpandedState
        )}
      >
        <Box>
          <AccordionSummary
            expandIcon={
              <span className="material-icons-round">arrow_drop_down</span>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Scoping</Typography>
          </AccordionSummary>
        </Box>
        <AccordionDetails sx={matterAddedInterviewAccordionDetailsStyles}>
          {matterInterviews
            .filter((i: MatterInterview) => i.interview_category === 'Scoping')
            .map((mi: MatterInterview, index: number) => {
              return (
                <Box
                  key={mi.id}
                  onClick={() => handleSelectInterview(mi.id, matterInterviews)}
                  sx={{
                    backgroundColor:
                      selectedIndex === index ? '#F0F8FD' : '#FFF',
                  }}
                >
                  <Box>
                    <Box>
                      <Typography>{index + 1}.</Typography>
                      <Typography>
                        {(() => {
                          if (mi.whistleblower) {
                            return 'whistleblower';
                          }
                          if (mi.interviewee_first_name === '') {
                            return 'Current interviewee';
                          }
                          return mi.interviewee_first_name;
                        })()}
                      </Typography>
                    </Box>
                    {!validInterview(mi) && (
                      <Box
                        sx={{
                          display: 'flex',
                          marginLeft: 'auto',
                          marginRight: '16px',
                          alignItems: 'center',
                          '& > span': {
                            fontSize: '16px',
                            color: '#E22727',
                          },
                        }}
                      >
                        <Tooltip
                          title="Please complete all required fields"
                          placement="left"
                        >
                          <span className="material-icons-round">error</span>
                        </Tooltip>
                      </Box>
                    )}
                    <Box>
                      {mi.interview_type !== '' && (
                        <InterviewTypeTag type={mi.interview_type} />
                      )}
                      <IconButton
                        data-testid="clear-interviewee-add-id"
                        aria-label="clear interviewee added"
                        sx={{
                          padding: '0px',
                          '&:hover': { background: 'transparent' },
                          '& > span': {
                            fontSize: '16px',
                          },
                        }}
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the event from bubbling up to the parent Box
                          handleRemoveMatterField(mi.id);
                        }}
                      >
                        <span className="material-icons-round">close</span>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          {matterInterviews.filter(
            (i: MatterInterview) => i.interview_category === 'Scoping'
          ).length === 0 && (
            <Box
              sx={{
                backgroundColor: '#FFF',
                pointerEvents: 'none',
              }}
            >
              <Box>
                <Box>
                  <Typography>No entries</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      {/* Witness Acc */}
      <Accordion
        expanded={witnessAccordionExpandedState}
        onChange={() => {
          setWitnessAccordionExpandedState(!witnessAccordionExpandedState);
        }}
        sx={matterAddedInterviewAccordionStyles(
          selectedIndex, // check this
          0, // check this
          witnessAccordionExpandedState
        )}
      >
        <Box>
          <AccordionSummary
            expandIcon={
              <span className="material-icons-round">arrow_drop_down</span>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Witness</Typography>
          </AccordionSummary>
        </Box>
        <AccordionDetails sx={matterAddedInterviewAccordionDetailsStyles}>
          {matterInterviews
            .filter((i: MatterInterview) => i.interview_category === 'Witness')
            .map((mi: MatterInterview, index: number) => {
              return (
                <Box
                  key={mi.id}
                  onClick={() => handleSelectInterview(mi.id, matterInterviews)}
                  sx={{
                    backgroundColor:
                      selectedIndex === index ? '#F0F8FD' : '#FFF',
                  }}
                >
                  <Box>
                    <Box>
                      <Typography>{index + 1}.</Typography>
                      <Typography>
                        {(() => {
                          if (mi.whistleblower) {
                            return 'Whistleblower';
                          }
                          if (mi.interviewee_first_name === '') {
                            return 'Current interviewee';
                          }
                          return mi.interviewee_first_name;
                        })()}
                      </Typography>
                    </Box>
                    <Box>
                      {mi.interview_type !== '' && (
                        <InterviewTypeTag type={mi.interview_type} />
                      )}
                      <IconButton
                        data-testid="clear-interviewee-add-id"
                        aria-label="clear interviewee added"
                        sx={{
                          padding: '0px',
                          '&:hover': { background: 'transparent' },
                          '& > span': {
                            fontSize: '16px',
                          },
                        }}
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent the event from bubbling up to the parent Box
                          handleRemoveMatterField(mi.id);
                        }}
                      >
                        <span className="material-icons-round">close</span>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          {matterInterviews.filter(
            (i: MatterInterview) => i.interview_category === 'Witness'
          ).length === 0 && (
            <Box
              sx={{
                backgroundColor: '#FFF',
                pointerEvents: 'none',
              }}
            >
              <Box>
                <Box>
                  <Typography>No entries</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      {addInterviewBtn}
    </Box>
  );
};

export default MatterAddedInterviewsInformation;
