/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Stack,
} from '@mui/material';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  karlaProBoldItalicFontStyles,
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../styles/textStyles';
import {
  getWhistleblowerReportMessagesRequest,
  createWhistleblowerReportMessageRequest,
} from '../../api/whistleblower';
import {
  matterInformationInputWrapperStyles,
  matterQuestionnaireUploadAreaWrapperStyles,
} from '../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  LEIAADialog,
  LEIAAInput,
  LEIAAUploadFileArea,
} from '../../components/inputs';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
} from '../../components/styles';
import { MatterAddedDocumentsInformation } from '../../components/matters/matter-creation/sidepanel-information';

export interface Message {
  sender: string;
  message: string;
  file: any;
  notice: boolean;
  timestamp: string;
  self: boolean;
  interview: boolean;
}

interface FileWithId extends File {
  id: string;
  uploaded: boolean;
}

const downloadFile = (url: string, name: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const words = name.split(' ');

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      words.length > 1
        ? `${words[0][0]}${words[1][0]}`.toUpperCase()
        : `${name[0]}`.toUpperCase(),
  };
}

const ReportingChat = (reportId: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  const privKey = reportId.reportId;
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [messages, setMessages] = useState<Message[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  let templateReport = watch('files');
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [repeatedFiles, setRepeatedFiles] = useState<FileWithId[]>([]);
  const [uploading, setUploading] = useState(true);
  const [userScrolled, setUserScrolled] = useState(false);

  const listRef = useRef<HTMLUListElement>(null);

  const handleClose = () => {
    setValue('files', undefined);
    templateReport = watch('files');
    setRepeatedFiles([]);
    setOpen(false);
  };

  const handleFetchMessages = async () => {
    setUploading(true);
    try {
      const response = await getWhistleblowerReportMessagesRequest(privKey);
      setMessages(response);
      setUploading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    if (watch('message') === '') return;
    try {
      await createWhistleblowerReportMessageRequest(privKey, data);
      handleClose();
      setValue('message', '');
      await handleFetchMessages();
      setUserScrolled(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleUploadFiles = (fileList: FileList) => {
    let existingFiles: FileWithId[] = [];

    if (fileList.length === 0) return;

    if (templateReport && templateReport.length > 0) {
      existingFiles = templateReport.filter((md: FileWithId) =>
        Array.from(fileList).some(
          (selectedFile) => md.name === selectedFile.name
        )
      );
      setRepeatedFiles(existingFiles);
    }

    if (existingFiles.length > 0) {
      setOpenAlertOpenDialog(true);
    } else {
      const filesArray: FileWithId[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        const fileId = uuidv4();
        const fileWithId = Object.assign(file, { id: fileId, uploaded: false });
        filesArray.push(fileWithId);
      }
      setValue('files', [...(templateReport || []), ...filesArray]);
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.target.files as FileList;
    handleUploadFiles(fileList);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const fileList = e.dataTransfer.files;
      handleUploadFiles(fileList);
    }
  };

  const handleRemoveFile = (id: string | number) => {
    setValue(
      'files',
      (templateReport || []).filter((file: FileWithId) => file.id !== id)
    );
    if (typeof id === 'number') {
      const filesIdsToDelete = watch('files_to_delete') || [];
      setValue('files_to_delete', [...filesIdsToDelete, id]);
    }
  };

  const handleUploadAndSubmit = () => {
    setValue('message', 'file');
    handleSubmit(onSubmit)();
  };

  // implement different method when time is abundant
  const startAutoRefresh = () => {
    const intervalId = setInterval(() => {
      handleFetchMessages();
    }, 5000); // Refresh every 5 seconds

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    handleFetchMessages();

    const intervalCleanup = startAutoRefresh();

    if (listRef.current && !userScrolled) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }

    return () => {
      intervalCleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listRef.current && !userScrolled) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages, userScrolled]);

  const handleScroll = () => {
    setUserScrolled(true);
  };

  return (
    <Box
      sx={{
        paddingTop: '5px',
        paddingBottom: '5px',
        width: '100%',
      }}
    >
      <List
        sx={{
          width: '100%',
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
        className="scrollable-content"
        id="chat-list"
        ref={listRef}
        onScroll={handleScroll}
      >
        {messages && messages.length > 0 ? (
          messages.map((message) => (
            <ListItem
              key={uuidv4()}
              sx={{
                marginLeft: message.self ? 'auto' : '0',
                textAlign: message.self ? 'right' : 'left',
                background: message.self ? '#d3ecf6' : '#f1fafe',
                borderRadius: '12px',
                marginTop: '5px',
                marginBottom: '5px',
                width: 'fit-content',
              }}
            >
              {!message.self && (
                <ListItemAvatar>
                  <Avatar {...stringAvatar(message.sender)} />
                </ListItemAvatar>
              )}

              {!message.file ? (
                <ListItemText
                  primary={
                    message.interview ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: message.message }}
                      />
                    ) : (
                      message.message
                    )
                  }
                  secondary={`${!message.self ? `${message.sender} - ` : ''}${
                    message.timestamp
                  }`}
                  sx={{
                    '& > span': {
                      ...karlaProRegularFontStyles,
                      fontSize: '16px',
                      color: '#202020',
                    },
                    '& > p': {
                      ...karlaProRegularItalicFontStyles,
                      fontSize: '12px',
                      color: '#202020',
                    },
                  }}
                />
              ) : (
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        ...karlaProRegularFontStyles,
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        color: '#140070',
                        '&:hover': {
                          color: '#02c1eb',
                        },
                      }}
                      onClick={() =>
                        downloadFile(message.file, message.message)
                      }
                    >
                      <span
                        style={{ marginRight: '5px' }}
                        className="material-icons-round"
                      >
                        description
                      </span>
                      {message.message}
                    </Typography>
                  }
                  secondary={`${!message.self ? `${message.sender} - ` : ''}${
                    message.timestamp
                  }`}
                  sx={{
                    '& > p:nth-child(2)': {
                      ...karlaProRegularItalicFontStyles,
                      fontSize: '12px',
                      color: '#202020',
                    },
                  }}
                />
              )}
            </ListItem>
          ))
        ) : (
          <Typography
            sx={{
              ...karlaProRegularItalicFontStyles,
              fontSize: '16px',
              color: '#202020',
              textAlign: 'center',
              padding: '40px',
              height: '68vh',
            }}
          >
            ...
          </Typography>
        )}
      </List>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: '90%',
              width: '100%',
              padding: '24px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '16px',
              border: '1px solid #D3ECF6',
              background: '#FFF',
              boxShadow: '0px 0px 20px 0px rgba(0, 94, 253, 0.08)',
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                marginBottom: '16px',
              }}
            >
              If you have documents or files that support your report, please
              upload them here.
            </Typography>
            <Box
              sx={{
                maxHeight: '100px',
                overflowY: 'scroll',
                marginBottom: '8px',
              }}
              className="scrollable-content"
            >
              {templateReport && templateReport.length > 0 && (
                // eslint-disable-next-line react/jsx-no-undef
                <MatterAddedDocumentsInformation
                  matterDocuments={templateReport}
                  handleRemoveFile={handleRemoveFile}
                  showHeader={false}
                />
              )}
            </Box>
            <LEIAAUploadFileArea
              title="Select a document to import."
              subTitle=""
              handleDrag={handleDrag}
              handleAddFile={handleAddFile}
              uploadAreaStyles={{
                ...matterQuestionnaireUploadAreaWrapperStyles,
                paddingLeft: '0px',
              }}
            />
            <LEIAADialog
              open={openAlertOpenDialog}
              onClose={() => setOpenAlertOpenDialog(false)}
              title="alert"
              description="We have detected similar existing files. Please change the final name if you want to add this file."
              simpleButtonText="Ok"
              onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
              isTextAlign
            />

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={{ xs: 'center', md: 'space-between' }}
              alignItems="center"
              spacing={2}
              sx={{ marginTop: '16px' }}
            >
              <Button
                variant="contained"
                sx={{
                  ...secondaryButtonStyles(),
                  width: { xs: '100%', md: 'auto' },
                }}
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  ...primaryButtonStyles(),
                  width: { xs: '100%', md: 'auto' },
                }}
                onClick={handleUploadAndSubmit}
                disabled={!(templateReport && templateReport.length > 0)}
              >
                {uploading ? (
                  <>
                    <Typography>Uploading</Typography>
                    <span className="material-icons-round">sync</span>
                  </>
                ) : (
                  <>
                    <Typography>Upload</Typography>
                    <span className="material-icons-round">upload_file</span>
                  </>
                )}
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '15px',
              '& > p': {
                color: '#202020',
                fontSize: '14px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
              },
            }}
          >
            <Box sx={{ ...matterInformationInputWrapperStyles, width: '100%' }}>
              <Box>
                <LEIAAInput
                  labelText=""
                  controllerName="message"
                  control={control}
                  inputPlaceholder="Enter message"
                  inputWidth="100%"
                  inputHeight="48px"
                  required={false}
                />
              </Box>
            </Box>
            <Box sx={{ marginLeft: 'auto' }}>
              <Button
                variant="contained"
                sx={secondaryButtonStyles()}
                onClick={handleOpen}
              >
                <span className="material-icons-round">attach_file</span>
              </Button>
            </Box>
            <Box sx={{ marginLeft: 'auto' }}>
              <Button
                variant="contained"
                sx={primaryButtonStyles()}
                type="submit"
              >
                <Typography>Send</Typography>
                <span className="material-icons-round">send</span>
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ReportingChat;
