import { Fragment, useContext, useEffect, useState } from 'react';
import {
  IconButton,
  List,
  ListItem,
  Typography,
  Menu,
  MenuItem,
  Box,
  Badge,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MoreHoriz, AccountCircle } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import LEIAALogo1 from '../../assets/LEIAALogo1.png';
import { User } from '../../types/auth';
import { SidebarData, sidebarItemTitles } from './SidebarData';
import {
  mainSidebarContainerStyles,
  sidebarNavigationContainerStyles,
  sidebarNavigationListItemStyles,
  sidebarNavigationListStyles,
  sidebarUserMenuContainerStyles,
} from './styles';
import { sofiaProBoldFontStyles } from '../../styles/textStyles';
import { createMatterDraftRequest } from '../../api/matters';
import { PermissionsObject } from '../../redux/features/user-permissions/userPermissionsSlice';
import { arrayPop, deepCloneArray } from '../../utils/utils';
import { usePermissions } from '../../hooks/usePermissions';
import LEIAAContext from '../../context/context';
import { reportsRequest } from '../../api/whistleblower';

interface SidebarProps {
  username: string;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

function getPermittedSidebarItems(perms: PermissionsObject, user: any) {
  const {
    matter,
    // matter_interviews: interviews,
    users,
    wiki_repository: wikiRepository,
    wiki_template: wikiTemplate,
  } = perms;
  const permittedItems = deepCloneArray(SidebarData);

  const removeSidebarItem = (itemTitle: string, sbItems = permittedItems) => {
    arrayPop(
      sbItems,
      sbItems.findIndex((it) => it.title === itemTitle)
    );
  };

  if (!matter.view) {
    removeSidebarItem(sidebarItemTitles.matters);
  } else if (!matter.add) {
    const matterItem = permittedItems.find(
      (it) => it.title === sidebarItemTitles.matters
    );

    removeSidebarItem(sidebarItemTitles.newMatter, matterItem?.subitems);
  }

  if (!users.view) {
    removeSidebarItem(sidebarItemTitles.users);
  }

  if (!['Admin', 'Superuser', 'Leader'].includes(user.role)) {
    removeSidebarItem(sidebarItemTitles.users);
    removeSidebarItem(sidebarItemTitles.matters);
    removeSidebarItem(sidebarItemTitles.reports);
  }

  return permittedItems;
}

const Sidebar = ({ username, setUser }: SidebarProps) => {
  const { user } = useContext(LEIAAContext);
  const permissions = usePermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [selectedItem, setSelectedItem] = useState('/');
  const [metrics, setMetrics] = useState<any>(null);

  const { logout } = useAuth0();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleCloseMenu();
    setUser({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      role: '',
      date_joined: '',
      phone_number: '',
      organisation: '',
    });
    localStorage.clear();
    logout();
    navigate('/login');
  };

  const handleGoToDetailsPage = () => {
    navigate('/user-details');
    handleCloseMenu();
  };

  const handleGoToHomePage = () => {
    navigate('/');
  };

  const handleGoToPage = async (link: string) => {
    if (link === '/matters/new') {
      try {
        const response = await createMatterDraftRequest();
        if (
          location.pathname.startsWith('/matters/new') ||
          location.pathname.startsWith('/matters/edit')
        ) {
          navigate(`${link}/${response.matter_id}`);
          navigate(0);
        } else {
          navigate(`${link}/${response.matter_id}`);
        }
      } catch (error: any) {
        console.error(error);
      }
    } else {
      navigate(link);
    }
  };

  const handleFetchReportsMetrics = async () => {
    try {
      const response = await reportsRequest('metrics', '', false);
      setMetrics(response.metrics);
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    setSelectedItem(location.pathname);
    handleFetchReportsMetrics();
  }, [location]);

  return (
    <Box sx={mainSidebarContainerStyles}>
      <Box sx={sidebarNavigationContainerStyles}>
        <Box onClick={handleGoToHomePage}>
          <img
            src={LEIAALogo1}
            style={{ width: '120px', cursor: 'pointer' }}
            alt="LEIAALogo1"
          />
        </Box>
        <List className="scrollable-content" sx={sidebarNavigationListStyles}>
          {getPermittedSidebarItems(permissions, user).map((val) => {
            return (
              <Fragment key={val.title}>
                <ListItem
                  key={val.title}
                  sx={sidebarNavigationListItemStyles(
                    selectedItem,
                    val.link,
                    !!val.subitems,
                    !!val.subitems && val.subitems.length > 0
                  )}
                  onClick={() => handleGoToPage(val.link)}
                >
                  {val.icon}
                  <Typography>
                    {val.title === 'Reports' && metrics && (
                      <Box
                        key="ReportsBadge"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: '12%',
                          zIndex: 1,
                        }}
                      >
                        <Badge
                          color="secondary"
                          overlap="circular"
                          badgeContent={metrics.new}
                          sx={{
                            '& > span': {
                              backgroundColor: '#ffb900',
                            },
                          }}
                        />
                      </Box>
                    )}
                    {val.title}
                  </Typography>
                </ListItem>
                {val.subitems && val.subitems.length > 0 && (
                  <List key={`${val.title}-subitems`}>
                    {val.subitems.map((subitem) => (
                      <ListItem
                        key={subitem.title}
                        onClick={() => handleGoToPage(subitem.link)}
                        sx={{
                          '& > svg': {
                            color: '#464B53',
                          },
                        }}
                      >
                        {subitem.icon}
                        <Typography
                          sx={{
                            ...sofiaProBoldFontStyles,
                            fontWeight: 300,
                            fontSize: '16px',
                            lineHeight: '18px',
                            letterSpacing: '0.3px',
                            color: '#464B53',
                          }}
                        >
                          {subitem.title}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Fragment>
            );
          })}
        </List>
      </Box>

      <Box sx={sidebarUserMenuContainerStyles}>
        <Box>
          <Box>
            <AccountCircle />
            <Typography>{username}</Typography>
          </Box>
          <IconButton
            data-testid="more-vert-button-id"
            color="primary"
            component="button"
            sx={{ color: '#A0A3AE' }}
            onClick={handleOpenMenu}
          >
            <MoreHoriz />
          </IconButton>
        </Box>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleGoToDetailsPage}>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Sidebar;
