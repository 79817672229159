import { FC, useState, useRef } from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';
import { MatterTaskDetail } from '../../../types/matters';
import { useAutosizeTextArea } from '../../../hooks';
import { taskCommentsStyles } from './styles';
import TaskComment from './TaskComment';

type Props = {
  task?: any;
  onNewCommentAdded: (comment: string) => void;
  styleOverride?: any;
};

const TaskCommentBox: FC<Props> = ({
  task,
  onNewCommentAdded,
  styleOverride = null,
}) => {
  const [newComment, setNewComment] = useState<string>('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [comments, setComments] = useState<any[]>(task?.comments || []);
  const [reRendered, setReRendered] = useState(false);

  useAutosizeTextArea(textAreaRef.current, newComment);

  const onNewCommentAddedHandler = async (newCommentAdded: any) => {
    await onNewCommentAdded(newCommentAdded);
    setReRendered(!reRendered);
  };

  return (
    <Box
      sx={styleOverride || taskCommentsStyles.wrapper}
      className="scrollable-content"
    >
      <Typography sx={taskCommentsStyles.title} variant="h5">
        Task Comment(s)
      </Typography>

      {/* Task comments  */}
      <Box className="scrollable-content" sx={taskCommentsStyles.commentList}>
        {comments?.map((comment: any, index: number) => {
          const isLastItem = index === comments.length - 1;

          return (
            <>
              <TaskComment key={uuidv4()} comment={comment} />
              {!isLastItem && <Divider sx={taskCommentsStyles.divider} />}
            </>
          );
        })}

        {/* Add new comment */}
        <TextField
          className="scrollable-content"
          placeholder="Add a new comment"
          onChange={({ target }) => {
            setNewComment(target.value);
          }}
          type="text"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline // textarea
          rows={1}
          inputRef={textAreaRef}
          sx={taskCommentsStyles.input}
        />
      </Box>

      {/* New task button */}
      <Button
        variant="contained"
        disabled={!newComment}
        sx={taskCommentsStyles.addCommentButton}
        onClick={() => onNewCommentAddedHandler(newComment)}
      >
        <span className="material-icons-round">add</span>
        <Typography sx={taskCommentsStyles.addCommentButtonText}>
          Add new task comment
        </Typography>
      </Button>
    </Box>
  );
};

export default TaskCommentBox;
