import { karlaProRegularFontStyles } from '../../styles/textStyles';

export const listNoteTagStyles = (
  backgroundColor: string,
  color: string,
  height: string,
  wrapperHeight: string
) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    height: wrapperHeight,
    alignSelf: 'flex-start',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '6px 10px',
      gap: '4px',
      height,
      backgroundColor,
      borderRadius: '10px',
      '& > p': {
        ...karlaProRegularFontStyles,
        textTransform: 'capitalize',
        fontSize: '16px',
        color,
      },
      '& > p > span': {
        verticalAlign: 'middle',
        marginRight: '5px',
        fontSize: '18px',
        color,
      },
    },
  };
};

export const noteSubjectInputStyles = (width: string, height: string) => {
  return {
    background: 'white',
    margin: '0px',
    paddingTop: '5px',
    '& input, & textarea': {
      caretColor: '#0053FF',
    },
    '& > div': {
      width,
      height,
      padding: '0px !important',
      '&:has(textarea)': {
        padding: '8px 12px !important',
      },
      '& > textarea': {
        overflow: 'hidden scroll !important',
        height: '100% !important',
      },
      '& > input': {
        padding: '0px 12px !important',
      },
      '& > div.MuiInputAdornment-positionStart': {
        '& > button': {
          padding: 0,
          marginLeft: '12px',
          '& > svg': {
            height: '24px',
            width: '24px',
          },
        },
      },
      '& > input, & > textarea': {
        width,
        height,
        ...karlaProRegularFontStyles,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#202020',
        '&:hover': {
          cursor: 'default',
        },
        '::placeholder': {
          ...karlaProRegularFontStyles,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
          display: 'flex',
          alignItems: 'center',
          color: '#69707B',
        },
      },
      '& > fieldset': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0,
      },
    },
  };
};
