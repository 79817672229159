/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { exportReport, updateReport } from '../../api/general';
import ReportEditor from '../../components/note-editor/ReportEditor';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
  simpleGoBackButtonStyles,
  tertiaryButtonStyles,
} from '../../components/styles';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../styles/textStyles';
import { reportSeedHTML } from '../../utils/utils';

const MatterDetailReportDetail = () => {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const report = location.state?.report;
  const matter = location.state?.matter;
  const locationPreviousState = location.state?.previous;
  const [toggleNameEdit, setToggleNameEdit] = useState(false);
  const [reportName, setReportName] = useState(report.name);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const editorRef = useRef();

  const handleGoBack = () => {
    if (locationPreviousState === 'matter-reports') {
      navigate(`/matters/detail/${report.investigation}`, {
        state: { currentStep: 6 },
      });
    } else {
      navigate(-1);
    }
  };

  const handleNameClick = (e: any) => {
    e.stopPropagation();
    setToggleNameEdit(true);
  };

  const closeEdit = () => {
    if (toggleNameEdit) {
      setToggleNameEdit(false);
    }
  };

  const reportSaveHandler = async () => {
    try {
      if (editorRef.current) {
        const state = (editorRef.current as any).export();
        const stateJson = JSON.stringify(state.json);

        await updateReport(report.id, reportName, stateJson);

        setSnackbarOpen(true);
      }
    } catch (error: unknown) {
      console.error(error);
      setErrorSnackbarOpen(true);
    }
  };

  const insertHTMLIntoTemplate = (
    template: string,
    dynamicHTML: string
  ): string => {
    return template.replace('<body></body>', `<body>${dynamicHTML}</body>`);
  };

  const exportAs = async (extension: string) => {
    try {
      const { html } = (editorRef.current as any).export();
      const template = reportSeedHTML;
      const completeHTML = insertHTMLIntoTemplate(template, html);
      const response = await exportReport(report.id, extension, completeHTML);
      let url = null;
      if (extension === 'docx') {
        url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          })
        );
      } else if (extension === 'pdf') {
        url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/pdf',
          })
        );
      }
      const link = document.createElement('a');
      link.href = url as string;
      link.setAttribute('download', `${report.name}.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url as string);
    } catch (error) {
      console.error('Failed to export DOCX:', error);
      setErrorSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (report && editorRef.current) {
        try {
          if (report.seed) {
            (editorRef.current as any).loadHTML(report.seed);
          } else {
            const content = JSON.parse(report.content);
            (editorRef.current as any).load(content);
          }
        } catch (error) {
          console.error('Failed to parse report content:', error);
        }
      }
    }, 100);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        '& > span': {
          color: '#0053FF',
        },
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Report saved!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          zIndex: '99999999',
          '& > div': {
            background: '#49b44c',
          },
        }}
      />
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setErrorSnackbarOpen(false)}
        message="Error"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          zIndex: '99999999',
          '& > div': {
            background: '#b44949',
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: '15px 32px 15px 32px',
          backgroundColor: '#FFFFFF',
          overflowX: 'hidden',
          flexDirection: 'column',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            '& > div:first-of-type': {
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              '& > div:first-of-type': {
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                '& > div': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: '10px',
                  '& > p': {
                    color: '#202020',
                    fontSize: '18px',
                    ...sofiaProBoldFontStyles,
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                  },
                },
                '& > button': {
                  justifyContent: 'flex-start',
                },
              },
            },
            '& > div:last-of-type': {
              display: 'flex',
              gap: '24px',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
            },
          },
        }}
      >
        <Box>
          <Box>
            <Box>
              <Box>
                <Breadcrumbs
                  separator={
                    <span className="material-icons-round">chevron_right</span>
                  }
                  aria-label="breadcrumb"
                  sx={{
                    '& > ol': {
                      gap: '6px',
                      '& > .MuiBreadcrumbs-li > div': {
                        '& > button': {
                          ...sofiaProRegularFontStyles,
                          color: '#69707B',
                          fontSize: '18px',
                          fontWeight: '700',
                          lineHeight: 'normal',
                          letterSpacing: '2px',
                          textTransform: 'uppercase',
                          padding: '10px 0px',
                        },
                        '& > p': {
                          color: '#949CA9',
                          ...karlaProRegularFontStyles,
                          fontSize: '12px',
                          lineHeight: '16px',
                          letterSpacing: '0.2px',
                        },
                      },
                      '& > .MuiBreadcrumbs-separator': {
                        margin: '0px',
                        padding: '10px 0',
                        alignSelf: 'baseline',
                      },
                    },
                  }}
                >
                  {matter && (
                    <Box>
                      <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        onClick={() =>
                          navigate(`/matters/detail/${report.investigation}`, {
                            state: {},
                          })
                        }
                      >
                        {matter.name ? matter.name : 'Matter'}
                      </Link>
                      <Typography>Matter</Typography>
                    </Box>
                  )}
                  <Box>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      onClick={() =>
                        navigate(`/matters/detail/${report.investigation}`, {
                          state: { currentStep: 6 },
                        })
                      }
                    >
                      Reports
                    </Link>
                    <Typography>Reports</Typography>
                  </Box>
                  <Box>
                    <Link
                      component="button"
                      variant="body2"
                      underline="none"
                      sx={{
                        ...sofiaProBoldFontStyles,
                        color: '#202020 !important',
                        fontSize: '18px !important',
                        fontWeight: '700 !important',
                        lineHeight: 'normal !important',
                        letterSpacing: '2px !important',
                        textTransform: 'uppercase !important',
                        padding: '10px 0 !important',
                        cursor: 'default',
                        pointerEvents: 'none',
                      }}
                    >
                      {report.name}
                    </Link>
                    <Typography>Report Name</Typography>
                  </Box>
                </Breadcrumbs>
              </Box>
              <Box>
                <Button
                  sx={simpleGoBackButtonStyles}
                  variant="text"
                  onClick={handleGoBack}
                >
                  <span className="material-symbols-outlined">arrow_back</span>
                  <Typography variant="caption" component="p">
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            padding: '0px',
            overflowX: 'hidden',
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
          onClick={() => closeEdit()}
        >
          {/* {toggleNameEdit || !reportName ? (
              <TextField
                defaultValue={reportName}
                placeholder="Enter report name"
                variant="standard"
                inputProps={{
                  'data-testid': 'form-text-field-id',
                  inputMode: 'text',
                  className: 'scrollable-content',
                }}
                onChange={(e: any) => {
                  setReportName(e.target.value);
                }}
                autoComplete="off"
                type="text"
                size="small"
                InputLabelProps={{ shrink: false }}
                margin="normal"
                sx={{
                  ...noteSubjectInputStyles('100%', '40px'),
                  marginBottom: '5px',
                }}
                onClick={handleNameClick}
              />
            ) : (
              <Typography
                variant="h5"
                mb={2}
                onClick={() => setToggleNameEdit(true)}
                sx={{
                  ...karlaProBoldFontStyles,
                  marginBottom: '-15px',
                }}
              >
                {reportName}
              </Typography>
            )} */}
          <Box
            sx={{
              overflowY: 'auto',
              flexGrow: 1,
            }}
            className="scrollable-content"
          >
            <ReportEditor editorRef={editorRef} />
          </Box>
        </Box>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
          sx={{
            display: 'flex',
            width: '100%',
            padding: '10px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '24px',
            borderTop: '1px solid  #D3ECF6',
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#FFF',
            zIndex: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{ ...tertiaryButtonStyles(), width: '5%' }}
            onClick={() => handleGoBack()}
          >
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ ...secondaryButtonStyles(), width: '15%' }}
            onClick={() => exportAs('docx')}
          >
            <Typography>Export as DOCX</Typography>
            <span className="material-icons-round">upload_file</span>
          </Button>
          <Button
            variant="contained"
            sx={{ ...secondaryButtonStyles(), width: '15%' }}
            onClick={() => exportAs('pdf')}
          >
            <Typography>Export as PDF</Typography>
            <span className="material-icons-round">picture_as_pdf</span>
          </Button>
          <Button
            variant="contained"
            sx={{ ...primaryButtonStyles(), width: '20%' }}
            onClick={() => reportSaveHandler()}
          >
            <Typography>Save</Typography>
            <span className="material-icons-round">save</span>
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default MatterDetailReportDetail;
