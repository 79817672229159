/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState } from 'react';

import { Box, Typography, Stack, List, ListItem } from '@mui/material';

import { sofiaProBoldFontStyles } from '../../../styles/textStyles';
import FaqPage from './settings/FaqPage';
import FollowUpPage from './settings/FollowUpPage';
import ReportConcernPage from './settings/ReportConcernPage';
import ReportingMainPage from './settings/ReportingMainPage';
import WorkflowManagementPage from './settings/WorkflowManagementPage';

const ReportSettings = () => {
  const [selectedPage, setSelectedPage] = useState<string | null>(null);

  const handleListItemClick = (pageName: string) => {
    setSelectedPage(pageName);
  };

  const renderPageContent = () => {
    switch (selectedPage) {
      case 'workflow-management':
        return <WorkflowManagementPage />;
      case 'reporting-main-page':
        return <ReportingMainPage />;
      case 'faq-page':
        return <FaqPage />;
      case 'report-concern-page':
        return <ReportConcernPage />;
      case 'follow-up-page':
        return <FollowUpPage />;
      default:
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography
              sx={{
                ...sofiaProBoldFontStyles,
                color: '#202020',
                fontSize: '15px',
                fontWeight: '500',
                textAlign: 'center',
              }}
            >
              Select settings page from the menu
            </Typography>
          </Box>
        );
    }
  };

  const selectedListItemStyles = {
    backgroundColor: '#EBF8FE',
    '& > p': {
      fontFamily: 'Karla, sans-serif !important',
      fontWeight: '700 !important',
      color: '#0053FF !important',
    },
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflowY: 'scroll',
      }}
      className="scrollable-content"
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0}
      >
        <List
          className="scrollable-content"
          sx={{
            width: '400px',
            background: '#f5fafd',
            height: '100%',
            border: '2px solid #EBF8FE',
            '> li': {
              width: '100%',
              height: '48px',
              cursor: 'pointer',
              marginTop: '10px',
              '&:hover': {
                background: '#EBF8FE',
              },
            },
            '> li > p': {
              ...sofiaProBoldFontStyles,
              fontWeight: 300,
              fontSize: '16px',
              lineHeight: '18px',
              letterSpacing: '0.3px',
              color: '#464B53',
            },
          }}
        >
          <ListItem
            onClick={() => handleListItemClick('workflow-management')}
            sx={
              selectedPage === 'workflow-management'
                ? selectedListItemStyles
                : {}
            }
          >
            <Typography>Workflow Management</Typography>
          </ListItem>
          <ListItem
            sx={
              selectedPage === 'reporting-main-page'
                ? selectedListItemStyles
                : {}
            }
            onClick={() => handleListItemClick('reporting-main-page')}
          >
            <Typography>Reporting main page</Typography>
          </ListItem>
          <ListItem
            sx={selectedPage === 'faq-page' ? selectedListItemStyles : {}}
            onClick={() => handleListItemClick('faq-page')}
          >
            <Typography>Frequently asked questions page</Typography>
          </ListItem>
          <ListItem
            sx={
              selectedPage === 'report-concern-page'
                ? selectedListItemStyles
                : {}
            }
            onClick={() => handleListItemClick('report-concern-page')}
          >
            <Typography>Report concern page</Typography>
          </ListItem>
          <ListItem
            sx={selectedPage === 'follow-up-page' ? selectedListItemStyles : {}}
            onClick={() => handleListItemClick('follow-up-page')}
          >
            <Typography>Follow up page</Typography>
          </ListItem>
        </List>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            overflowY: 'scroll',
            width: '100%',
            height: '100%',
            paddingTop: '10px',
            paddingRight: '10px',
            paddingBottm: '10px',
            paddingLeft: '10px',
            border: '2px solid #EBF8FE',
          }}
          className="scrollable-content"
        >
          {renderPageContent()}
        </Box>
      </Stack>
    </Box>
  );
};

export default ReportSettings;
