/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
  MenuItem,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationWrapperStyles,
} from '../../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { LEIAAInput, LEIAASelect } from '../../../../components/inputs';
import { primaryButtonStyles } from '../../../../components/styles';
import { sofiaProBoldFontStyles } from '../../../../styles/textStyles';
import {
  getAdminReportingSettingsRequest,
  updateAdminReportingSettingsRequest,
} from '../../../../api/whistleblower';
import CreationNotification from '../../../../components/shared/creation-notification/CreationNotification';

const WorkflowManagementPage = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [assignableUsers, setAssignableUsers] = useState<any[]>([]);

  const matterStages = [
    'Report Acknowledgement',
    'Scoping',
    'Data Collection',
    'Analysis',
    'Further Steps',
    'Interviews',
    'Report',
    'Closed',
  ];

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleFetchData = async () => {
    setLoadingData(true);
    try {
      const response = await getAdminReportingSettingsRequest();

      Object.keys(response).forEach((fieldName) => {
        setValue(fieldName, response[fieldName]);
      });

      setAssignableUsers(response.assignable);

      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      await updateAdminReportingSettingsRequest(data);
      setShowNotification(true);
      handleFetchData();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showNotification) {
      // Scroll to the top after form submission
      window.scrollTo({ top: 0 });
    }
  }, [showNotification]);

  return loadingData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        '& > span': {
          color: '#0053FF',
        },
        marginTop: '50%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showNotification && (
        <CreationNotification
          title="Settings saved successfully!"
          subTitle=""
          showNotification={showNotification}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '20px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        Workflow Management
      </Typography>

      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '15px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        Manage the assigned matter lead for each future matter stage.
      </Typography>

      <Box sx={matterInformationWrapperStyles}>
        <Box
          sx={{
            ...matterInformationFormWrapperStyles,
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...matterInformationInputWrapperStyles,
              paddingTop: '5px',
            }}
          >
            <Box>
              {matterStages.map((stage: any, index: any) => {
                const isClosedBox = index === matterStages.length - 1;

                return (
                  <>
                    <LEIAASelect
                      key={`stage-b-${index}`}
                      labelText={stage}
                      control={control}
                      controllerName={`stage[${index}]`}
                      inputPlaceholder="Select matter lead"
                      inputHeight="40px !important"
                      inputWidth="170%"
                      paperCustomStylings={{ height: '300px' }}
                      required={false}
                    >
                      {assignableUsers?.map((user: any) => {
                        const userId = Object.keys(user)[0] as string;
                        const name = Object.values(user)[0] as string;

                        return (
                          <MenuItem key={userId} value={userId}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </LEIAASelect>
                    {!isClosedBox && (
                      <>
                        <LEIAAInput
                          labelText={`${stage} duration in days`}
                          controllerName={`stage_days[${index}]`}
                          control={control}
                          inputPlaceholder="Enter duration"
                          inputWidth="200px"
                          inputHeight="40px"
                          type="NUMERIC"
                          required
                        />
                        <Box sx={{ borderBottom: '1px solid  #D3ECF6' }} />
                      </>
                    )}
                  </>
                );
              })}
            </Box>
            <Box sx={{ height: '2px', borderBottom: '1px solid  #D3ECF6' }} />
          </Box>
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Button variant="contained" sx={primaryButtonStyles()} type="submit">
          <Typography>Save</Typography>
          <span className="material-icons-round">save</span>
        </Button>
      </Stack>
    </form>
  );
};

export default WorkflowManagementPage;
