import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { primaryButtonStyles } from '../../styles';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import { interviewAutomatedQuestionnaireRequest } from '../../../api/interviews';

interface AutomatedQuestionnaireProps {
  interviewDetails: any;
}

const AutomatedQuestionnaire = ({
  interviewDetails,
}: AutomatedQuestionnaireProps) => {
  const [startQuestionnaire, setStartQuestionnaire] = useState(false);
  const [endQuestionnaire, setEndQuestionnaire] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [disabled, setDisabled] = useState(true);

  const handleStartQuestionnaire = async () => {
    try {
      const response = await interviewAutomatedQuestionnaireRequest(
        interviewDetails.room,
        'start'
      );

      setCurrentQuestionIndex(response.data.next_index);
      setCurrentQuestion(response.data.next_question);
      setStartQuestionnaire(true);
      setTimeout(() => setDisabled(false), 5000);
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleNextQuestion = async () => {
    try {
      const response = await interviewAutomatedQuestionnaireRequest(
        interviewDetails.room,
        'question',
        currentQuestionIndex
      );

      setDisabled(true);
      setCurrentQuestionIndex(response.data.next_index);
      setCurrentQuestion(response.data.next_question);
      setEndQuestionnaire(response.data.end);
      setTimeout(() => setDisabled(false), 5000);
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleListenQuestion = (questionText: string) => {
    const voices = window.speechSynthesis.getVoices();
    const selectedVoice = voices.find(
      (voice: any) => voice.voiceURI === 'Google UK English Male'
    );
    const value = new SpeechSynthesisUtterance(questionText);
    if (selectedVoice) {
      value.voice = selectedVoice;
      window.speechSynthesis.speak(value);
    } else {
      window.speechSynthesis.speak(value);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        backgroundColor: '#32353C',
        padding: '8px',
        '& > div:nth-of-type(1)': {
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& > p': {
            ...karlaProRegularFontStyles,
            color: '#FFF',
            textAlign: 'center',
            fontSize: '26px',
            fontWeight: 400,
            lineHeight: '40px',
          },
        },
        '& > div:nth-of-type(2)': {
          display: 'flex',
          justifyContent: 'space-between',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            alignItems: 'center',
            padding: '2px 8px',
            borderRadius: '10px',
            backgroundColor: '#202020',
            '& > p': {
              color: '#FFF',
              textAlign: 'center',
              ...karlaProRegularFontStyles,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px',
            },
          },
          '& > div:nth-of-type(2)': {
            display: 'flex',
            gap: '16px',
          },
        },
      }}
    >
      {!startQuestionnaire ? (
        <Box sx={{}}>
          <Button
            variant="contained"
            sx={primaryButtonStyles()}
            onClick={handleStartQuestionnaire}
          >
            <Typography>Start Questionnaire</Typography>
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <Typography>
              {currentQuestionIndex === -1
                ? currentQuestion
                : `${currentQuestionIndex + 1}. ${currentQuestion}`}
            </Typography>
          </Box>
          {currentQuestionIndex !== -1 ? (
            <Box>
              <Box>
                <Typography>
                  {`Questionnaire from ${interviewDetails.organisation}`}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    backgroundColor: '#464B53',
                    border: '2px solid #464B53',
                    '&:hover': {
                      boxShadow: 'none',
                      border: '2px solid #0053FF',
                      backgroundColor: '#464B53',
                    },
                    height: '30px',
                  }}
                  onClick={() => handleListenQuestion(currentQuestion)}
                >
                  <span className="material-icons-round">
                    play_circle_filled
                  </span>
                  <Typography>Listen</Typography>
                </Button>
                <Button
                  variant="contained"
                  sx={{ ...primaryButtonStyles(), height: '30px' }}
                  onClick={handleNextQuestion}
                  disabled={disabled}
                >
                  <Typography>Next question</Typography>
                  <span className="material-icons-round">arrow_forward</span>
                </Button>
              </Box>
            </Box>
          ) : (
            <Button
              variant="contained"
              sx={{
                ...primaryButtonStyles(),
                backgroundColor: '#464B53',
                border: '2px solid #464B53',
                '&:hover': {
                  boxShadow: 'none',
                  border: '2px solid #0053FF',
                  backgroundColor: '#464B53',
                },
                height: '30px',
                width: '100px',
                alignSelf: 'flex-end',
              }}
              onClick={() => handleListenQuestion(currentQuestion)}
            >
              <span className="material-icons-round">play_circle_filled</span>
              <Typography>Listen</Typography>
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default AutomatedQuestionnaire;
