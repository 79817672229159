import { Box, IconButton, Typography } from '@mui/material';
import { matterAddedDocsWrapperStyles } from '../matter-creation-steps/matter-documents-step/styles';

interface MatterAddedInterviewersInformationProps {
  matterAddedInterviewers: any;
  userIdToName: (id: number) => string;
  handleRemoveInterviewer: (id: string) => void;
}

const MatterAddedInterviewersInformation = ({
  matterAddedInterviewers,
  userIdToName,
  handleRemoveInterviewer,
}: MatterAddedInterviewersInformationProps) => {
  return (
    <Box>
      <Box>
        <Box>
          <Typography>Added Interviewers</Typography>
        </Box>
      </Box>
      {matterAddedInterviewers.map((addedInterviewerId: any, index: number) => {
        return (
          <Box key={index} sx={matterAddedDocsWrapperStyles}>
            <Box>
              <Box>
                <Box>
                  <Box>
                    <Typography>{index + 1}.</Typography>
                    <Typography>{userIdToName(addedInterviewerId)}</Typography>
                  </Box>
                  <Box>
                    <IconButton
                      data-testid="clear-interviewer-added-id"
                      aria-label="clear interviewer added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                      }}
                      onClick={() =>
                        handleRemoveInterviewer(addedInterviewerId)
                      }
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MatterAddedInterviewersInformation;
