import { Box, IconButton, Typography } from '@mui/material';
import { notificationToastWrapperStyles } from './styles';

interface NotificationToastProps {
  showNotification: boolean;
  requestError: string | null;
  handleCloseNotification: any;
  type?: string;
}

const NotificationToast = ({
  showNotification,
  requestError,
  handleCloseNotification,
  type = 'error',
}: NotificationToastProps) => {
  let border;
  let backgroundColor;

  switch (type) {
    case 'error':
      border = '1px solid #E22727';
      backgroundColor = '#FDF1F2';
      break;
    case 'info':
      border = 'unset';
      backgroundColor = '#BADDEB';
      break;
    default:
      border = '1px solid #E22727';
      backgroundColor = '#FDF1F2';
      break;
  }
  return (
    <Box
      sx={notificationToastWrapperStyles(
        showNotification,
        border,
        backgroundColor
      )}
    >
      <Box>
        <Box>
          <Box>
            <Typography>{requestError}</Typography>
            <IconButton
              onClick={handleCloseNotification}
              sx={{ '& > span': { color: '#202020', fontSize: '20px' } }}
            >
              <span className="material-icons-round">close</span>
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationToast;
