import {
  Avatar,
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  getInterviewBundlesListRequest,
  updateInterviewNoteRequest,
} from '../../../api/interviews';
import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';
import NotificationToast from '../../shared/toast/NotificationToast';
import { LEIAAChat } from '../chat/LEIAAChat';
import { noteInputStyles } from './styles/styles';

interface LEIAAPanelProps {
  interviewDetails: any;
  openPanel: any;
  setOpenDocSharing: any;
  openDocSharing: any;
  widgetState: any;
  messageFormatter: any;
  sendSocketMessage: any;
  currentUser: any;
  setShowNotification: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ display: value !== index ? 'none' : 'block', height: '100%' }}
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Box>
  );
}

const LEIAAPanel = ({
  interviewDetails,
  openPanel,
  setOpenDocSharing,
  openDocSharing,
  widgetState,
  messageFormatter,
  sendSocketMessage,
  currentUser,
  setShowNotification,
}: LEIAAPanelProps) => {
  const [tabValue, setTabValue] = useState(
    currentUser.role === 'Guest' ? 0 : 1
  );
  const [interviewBundlesList, setInterviewBundlesList] = useState<any>([]);
  const [loadingBunles, setLoadingBundles] = useState(true);
  const [interviewNotes, setInterviewNotes] = useState(
    interviewDetails.notes || ''
  );
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowInternalNotification] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (currentUser.role !== 'Guest') {
      setTabValue(newValue);
    }
  };

  const handleInputChange = (event: any) => {
    setInterviewNotes(event.target.value);
  };

  const handleGetInterviewBundlesList = async () => {
    try {
      if (currentUser.role !== 'Guest') {
        const response = await getInterviewBundlesListRequest(
          interviewDetails.id
        );

        setInterviewBundlesList(response.documents);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowInternalNotification(true);
      console.error(error);
    } finally {
      setLoadingBundles(false);
    }
  };

  const updateInterviewNote = async () => {
    try {
      await updateInterviewNoteRequest(interviewDetails.id, interviewNotes);
      setShowNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowInternalNotification(true);
      console.error(error);
    }
    // success pop up
  };

  useEffect(() => {
    handleGetInterviewBundlesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        display: openPanel ? 'flex' : 'none',
        flexDirection: 'column',
        width: '480px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          width: '480px',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& > div': {
              '& > div': {
                '& > button': {
                  display: 'flex',
                  flexGrow: 1,
                  padding: '8px 24px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  borderRadius: '24px 24px 0px 0px',
                  backgroundColor: '#69707B',
                  '&.Mui-selected': {
                    backgroundColor: '#0053FF',
                    color: 'white',
                  },

                  color: 'white',
                  textTransform: 'none',
                  ...karlaProRegularFontStyles,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '22px',
                },
                borderBottom: '2px solid #F0F8FD',
              },
              '& > span.MuiTabs-indicator': {
                border: '2x solid #3375FF',
                backgroundColor: 'var(--shades-grey-5, #69707B)',
              },
            },
          }}
        >
          {currentUser.role !== 'Guest' && (
            <Tab label="Interview Bundles" {...a11yProps(0)} />
          )}
          <Tab
            label="Chat"
            {...a11yProps(currentUser.role === 'Guest' ? 0 : 1)}
          />
          {currentUser.role !== 'Guest' && (
            <Tab label="Notes" {...a11yProps(2)} />
          )}
          {currentUser.role !== 'Guest' && (
            <Tab label="Outline" {...a11yProps(3)} />
          )}
        </Tabs>
      </Box>

      <Box
        sx={{
          width: '100%',
          flex: '1',
          padding: '40px 16px 16px 16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
          alignSelf: 'stretch',
          borderRadius: '0px 0px 24px 24px',
          backgroundColor: '#32353C',
          '& > div': {
            width: '100%',
          },
        }}
      >
        {currentUser.role !== 'Guest' && (
          <CustomTabPanel value={tabValue} index={0}>
            <Box
              sx={{
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
              className="scrollable-content"
            >
              {interviewBundlesList?.map((doc: any, index: number) => {
                return (
                  <Box
                    key={doc.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      borderRadius: '12px',
                      padding: '8px 16px',
                      backgroundColor: '#464B53',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        width: '100%',
                        '& > p': {
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          '& > span': {
                            fontSize: '20px',
                          },
                          color: '#FFF',
                          ...sofiaProRegularFontStyles,
                          fontSize: '16px',
                          lineHeight: 'normal',
                          letterSpacing: '0.3px',
                        },
                      }}
                    >
                      <Typography>
                        <span className="material-icons-round">
                          picture_as_pdf
                        </span>
                        {doc.name}
                      </Typography>

                      <Button
                        sx={{
                          ...karlaProRegularFontStyles,
                          fontWeight: 500,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#69707B !important',
                          borderRadius: '16px !important',
                          padding: '8px 16px',
                          gap: '8px',
                          width: '170px',
                          height: '30px',
                          border: '1px solid #69707B !important',
                          '&:hover': {
                            border: '1px solid #0053FF !important',
                          },
                          '& > p': {
                            textAlign: 'center',
                            fontSize: '16px',
                            ...karlaProRegularFontStyles,
                            letterSpacing: '0.2px',
                            textTransform: 'none',
                            color: 'white',
                          },
                        }}
                        onClick={() => {
                          if (openDocSharing && openDocSharing === doc.id) {
                            setOpenDocSharing(undefined);
                            sendSocketMessage({
                              open: 'CLOSE_SIGNAL',
                            });
                          } else {
                            setOpenDocSharing(doc.id);
                            sendSocketMessage({
                              open: doc.id,
                            });
                          }
                        }}
                      >
                        {openDocSharing && openDocSharing === doc.id ? (
                          <>
                            <span
                              style={{ marginRight: '8px', color: 'white' }}
                              className="material-icons-round"
                            >
                              disabled_by_default
                            </span>
                            <Typography>Stop</Typography>
                          </>
                        ) : (
                          <>
                            <span
                              style={{ marginRight: '8px', color: 'white' }}
                              className="material-icons-round"
                            >
                              upload_file
                            </span>
                            <Typography>Present</Typography>
                          </>
                        )}
                      </Button>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </CustomTabPanel>
        )}
        <CustomTabPanel
          value={tabValue}
          index={currentUser.role === 'Guest' ? 0 : 1}
        >
          <LEIAAChat
            currentUser={currentUser}
            interviewDetails={interviewDetails}
            messageFormatter={messageFormatter}
          />
        </CustomTabPanel>
        {currentUser.role !== 'Guest' && (
          <CustomTabPanel value={tabValue} index={2}>
            <TextField
              placeholder="Enter written note(s)"
              inputProps={{
                'data-testid': 'form-text-field-id',
                inputMode: 'text',
                className: 'scrollable-content',
              }}
              type="text"
              size="small"
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                ...noteInputStyles('100%', '130px'),
              }}
              multiline
              value={interviewNotes}
              onChange={handleInputChange}
            />
            <Button
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 500,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#69707B !important',
                borderRadius: '16px !important',
                padding: '8px 16px',
                gap: '8px',
                width: '170px',
                height: '30px',
                '&:hover': {
                  border: '2px solid #0053FF !important',
                },
                '& > p': {
                  textAlign: 'center',
                  fontSize: '16px',
                  ...karlaProRegularFontStyles,
                  letterSpacing: '0.2px',
                  textTransform: 'none',
                  color: 'white',
                },
                marginLeft: 'auto',
                marginTop: '10px',
              }}
              onClick={updateInterviewNote}
            >
              <Typography>Save Note</Typography>
            </Button>
          </CustomTabPanel>
        )}
        {currentUser.role !== 'Guest' &&
          interviewDetails?.interview_outline && (
            <CustomTabPanel value={tabValue} index={3}>
              <Box sx={{ maxHeight: '66vh', overflowY: 'scroll' }}>
                {interviewDetails.interview_outline.map(
                  (outline: any, index: number) => (
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        padding: '10px',
                        marginBottom: '5px',
                        borderRadius: '10px',
                      }}
                      key={index}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                      >
                        <Box>
                          <Avatar
                            sx={{
                              bgcolor: '#7d91ff',
                              width: '24px',
                              height: '24px',
                              fontSize: '1 rem',
                            }}
                          >
                            {index + 1}
                          </Avatar>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: 'black',
                            }}
                          >
                            {outline.question}
                          </Typography>
                        </Box>
                      </Stack>

                      <Box>
                        <Typography
                          sx={{
                            color: 'black',
                            marginLeft: '15px',
                            marginTop: '10px',
                          }}
                        >
                          {outline.description}
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </CustomTabPanel>
          )}
      </Box>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowInternalNotification(false)}
        />
      )}
    </Box>
  );
};
export default LEIAAPanel;
