import { useEffect, useRef, useState } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ResourcePageHeader from '../components/shared/resource-page-header/ResourcePageHeader';
import './styles/interviewlist.css';
import '../components/styles/datagridStyles.css';
import { homePortalSearchInputStyles } from '../components/shared/resource-page-header/styles';
import { clientsListRequest } from '../api/homeportal';
import { matterDetailsRequest } from '../api/matters';
import NotificationToast from '../components/shared/toast/NotificationToast';
import ClientListTable from '../components/shared/clients-list-table/ClientListTable';

const ClientList = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [clientListData, setClientListData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const handleFetchClientsList = async (searchText = '') => {
    setLoading(true); // Start loading

    try {
      const response = await clientsListRequest(searchText);
      setClientListData(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchClientsList(searchText);
  };

  const handleDetailClient = (_client: any) => {
    navigate(`/client/${_client?.id}`);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <ResourcePageHeader inputRef={searchInputRef} headerText="Clients" />

      <Box>
        <Box
          sx={{
            paddingLeft: '18px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by name..."
              sx={{
                width: '250px !important',
                ...homePortalSearchInputStyles,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
          </Box>
        </Box>
        <Grid item xs={12} md={12}>
          <ClientListTable
            clientListData={clientListData}
            loading={loading}
            listClass="clients-list"
            handleDetailClient={handleDetailClient}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default ClientList;
