import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../pages/styles/interviewmatterlist.css';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import { primaryButtonStyles } from '../../styles';
import { interviewsListRequest } from '../../../api/homeportal';
import { InterviewsListResponse } from '../../../types/homeportal';
import InterviewListTable from '../../shared/interviews-list-table/InterviewListTable';
import { InterviewsListItem } from '../../shared/interviews-list-table/types';
import ListButtons from '../../shared/list-buttons/ListButtons';
import { batchDeleteInterviewRequest } from '../../../api/interviews';
import { matterDetailsRequest } from '../../../api/matters';
import { useInterviewMatterPermissions } from '../../../hooks/usePermissions';
import NotificationToast from '../../shared/toast/NotificationToast';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import MatterNotesListModal from '../../matter-notes/MatterNotesListModal';

interface MatterDetailInterviewsProps {
  matterId: any;
  matterDetails: any;
  setMatterDetails: any;
}

const MatterDetailInterviews = ({
  matterId,
  matterDetails,
  setMatterDetails,
}: MatterDetailInterviewsProps) => {
  const interviewPerms = useInterviewMatterPermissions();
  const [loading, setLoading] = useState(true);
  const [interviewsListData, setInterviewsListData] =
    useState<InterviewsListResponse | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [selectedInterview, setSelectedInterview] =
    useState<InterviewsListItem | null>(null);
  const [checkedInterviews, setCheckedInterviews] = useState([]);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);

  const handleFetchInterviewsList = async (searchText = '') => {
    setLoading(true);

    try {
      const response = await interviewsListRequest(searchText, matterId);
      setInterviewsListData(response as InterviewsListResponse);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
      setCheckedInterviews([]);
    }
  };

  useEffect(() => {
    handleFetchInterviewsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchInterviewsList(searchText);
  };

  const handleEditInterview = async () => {
    navigate(`/matters/edit/${matterId}`, {
      state: { currentStep: 4, matterDetails, previous: 'matter-interviews' },
    });
  };

  const handleAddInterviewClick = () => {
    navigate(`/matter/addInterview/${matterId}`, {
      state: { currentStep: 4, matterDetails },
    });
  };

  const handleDetailInterview = (_interview: any) => {
    navigate(`/matters/detail/${matterId}/interviews/${_interview?.id}`, {
      state: {
        interview: _interview,
        matter: matterDetails,
        previous: 'matter-interviews',
      },
    });
  };

  const handleJoinInterview = () => {
    if (selectedInterview?.status !== 'Conducted') {
      navigate(`${selectedInterview?.link}`);
    } else {
      navigate('/interview/end', {
        state: { interviewDetails: selectedInterview },
      });
    }
  };

  const addNoteHandler = () => {
    setOpenNoteModal(true);
  };

  const handleUpdateMatterDetails = async () => {
    try {
      if (matterId) {
        const response = await matterDetailsRequest(matterId);
        setMatterDetails(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleBatchDeleteInterviews = async () => {
    try {
      await batchDeleteInterviewRequest(checkedInterviews);
      await handleFetchInterviewsList();
      await handleUpdateMatterDetails();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  return (
    <>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Interview archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {openNoteModal && (
        <MatterNotesListModal
          matterId={matterId}
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          resourceName="Interview"
          resourceId={checkedInterviews[0]}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '40px',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '32px' }}>
            {interviewPerms.add && (
              <Button
                variant="contained"
                onClick={handleAddInterviewClick}
                sx={{ ...primaryButtonStyles(), height: '40px' }}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add interview</Typography>
              </Button>
            )}
          </Box>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search by interviewee..."
            sx={{
              ...homePortalSearchInputStyles,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& > span': { color: '#69707B' } }}
                >
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              onChange: handleListSearchbarInputChange,
              inputRef,
            }}
            variant="standard"
          />
        </Box>
        {checkedInterviews.length > 0 &&
          (interviewPerms.change || interviewPerms.delete) && (
            <ListButtons
              deleteHandler={
                interviewPerms.delete && handleBatchDeleteInterviews
              }
              editHandler={interviewPerms.change && handleEditInterview}
              addNoteHandler={addNoteHandler}
              interviewHandler={handleJoinInterview}
              checkedResources={checkedInterviews}
              resourceName="interview"
            />
          )}
      </Box>
      <Box sx={{ width: '100%' }}>
        <InterviewListTable
          interviewsListData={interviewsListData}
          loading={loading}
          listClass="interviews-matter-list"
          setCheckedInterviews={setCheckedInterviews}
          setSelectedInterview={setSelectedInterview}
          handleDetailInterview={handleDetailInterview}
          matterDetail
        />
      </Box>
    </>
  );
};

export default MatterDetailInterviews;
