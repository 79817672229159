import * as React from 'react';
import { Track } from 'livekit-client';

/**
 * @internal
 */
// eslint-disable-next-line import/prefer-default-export
export function getSourceIcon(source: Track.Source, enabled: boolean) {
  switch (source) {
    case Track.Source.Microphone:
      return enabled ? (
        <span className="material-icons-round">keyboard_voice</span>
      ) : (
        <span className="material-icons-round">mic_off</span>
      );
    case Track.Source.Camera:
      return enabled ? (
        <span className="material-icons-round">videocam</span>
      ) : (
        <span className="material-icons-round">videocam_off</span>
      );
    case Track.Source.ScreenShare:
      return enabled ? (
        <span className="material-icons-round">present_to_all</span>
      ) : (
        <span className="material-icons-round">cancel_presentation</span>
      );
    default:
      return undefined;
  }
}
