import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

interface ImageDisplayFraudProps {
  indicator: any;
  analysisData: any;
}

interface HighlightBoxesProps {
  currentPage: number;
  indicator: any;
  originalWidth: number;
  originalHeight: number;
}

const HighlightBoxes: React.FC<HighlightBoxesProps> = ({
  currentPage,
  indicator,
  originalWidth,
  originalHeight,
}) => {
  const color = indicator.type === 'TRUST' ? 'green' : '#f57373';
  const elements = indicator.metadata.data
    .filter((item: any) => item.page_id === currentPage)
    .map((item: any) => item.bbox)
    .flat();

  return (
    <div style={{ position: 'relative' }} className="highlight-container">
      {elements.map((highlight: any, index: any) => {
        const left = (highlight.x / originalWidth) * 600; // Calculate based on original width and scale to 600px
        const top =
          ((originalHeight - (highlight.y + highlight.height)) /
            originalHeight) *
          700; // Flip vertically

        return (
          <div
            key={`highlight_${index}`}
            style={{
              left: `${left}px`,
              top: `${top}px`,
              width: `${(highlight.width / originalWidth) * 600}px`, // Scale width
              height: `${(highlight.height / originalHeight) * 700}px`, // Scale height
              position: 'absolute',
              backgroundColor: color,
              opacity: 0.5,
              border: `2px solid ${color}`,
            }}
          />
        );
      })}
    </div>
  );
};

const ImageDisplayFraud: React.FC<ImageDisplayFraudProps> = ({
  indicator,
  analysisData,
}) => {
  const currentPage = 0;
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);

  useEffect(() => {
    const imgElement = new Image();
    imgElement.src = analysisData.file_url;
    imgElement.onload = () => {
      setOriginalWidth(imgElement.width);
      setOriginalHeight(imgElement.height);
    };
  }, [analysisData.file_url]);

  return (
    <Box
      sx={{
        overflowY: 'scroll',
      }}
      id="image-fraud-box"
      className="scrollable-content"
    >
      {indicator && (
        <HighlightBoxes
          currentPage={currentPage}
          indicator={indicator}
          originalWidth={originalWidth}
          originalHeight={originalHeight}
        />
      )}
      <img
        src={analysisData.file_url}
        alt="document preview"
        style={{ width: '600px', height: '700px' }}
        loading="lazy"
      />
    </Box>
  );
};

export default ImageDisplayFraud;
