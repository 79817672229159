import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';

export const interviewWeekDayItemWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0px',
  gap: '16px',
  width: '100%',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px',
    width: '22px',
    height: '33px',
    '& > p:nth-of-type(1)': {
      ...karlaProRegularFontStyles,
      fontWeight: 300,
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '0.2px',
      textTransform: 'uppercase',
      color: '#69707B',
    },
    '& > h4': {
      ...sofiaProRegularFontStyles,
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '21px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      color: '#69707B',
    },
  },
};

export const interviewsItemsWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0px',
  width: '100%',
  maxHeight: '274px',
  overflowY: 'auto',
  gap: '8px',
  scrollbarColor: 'transparent transparent',
};

export const interviewsItemWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0px',
  gap: '16px',
  width: '100%',
};

export const scheduledInterviewsWrapperStyles = {
  padding: '24px',
  width: '100%',
  height: '427px',
  background: '#FFFFFF',
  border: '1px solid #D3ECF7',
  borderRadius: '24px',
  '& > div:nth-of-type(1)': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      gap: '24px',
      alignItems: 'center',
      height: '24px',
      '& > div': {
        gap: '8px',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
};

export const scheduledInterviewsButtonsWrapperStyles = {
  '& > svg': {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
    color: '#464B53',
  },
  '& > p': {
    ...karlaProRegularFontStyles,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    letterSpacing: '0.2px',
  },
};

export const interviewsInfoWrapperStyles = {
  gap: '2px',
  '& > div:nth-of-type(1)': {
    '& > p': {
      ...karlaProRegularFontStyles,
      fontWeight: 300,
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '0.2px',
      textTransform: 'uppercase',
      color: '#464B53',
    },
  },
  '& > div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    '& > p': {
      ...karlaProRegularFontStyles,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.2px',
      color: '#464B53',
    },
  },
};
