import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { FieldValues, UseFormWatch, UseFormSetValue } from 'react-hook-form';
import { karlaProRegularFontStyles } from '../../styles/textStyles';
import {
  CustomTabPanel,
  a11yProps,
} from '../matters/matter-detail/MatterDetailDocuments';

import WikiDocReview from './wiki-creation-steps/wiki-doc-review-protocol-step/WikiDocReview';
import WikiPlans from './wiki-creation-steps/wiki-plans-step/WikiPlans';
import WikiReport from './wiki-creation-steps/wiki-report-step/WikiReport';

interface WikiDocumentsProps {
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const WikiDocuments = ({ watch, setValue }: WikiDocumentsProps) => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          width: '100%',
          borderBottom: '1px solid #949CA9',
          marginTop: '-25px',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="sub tabs"
          sx={{
            '& > div': {
              '& > div': {
                '& > button': {
                  display: 'flex',
                  width: '224px',
                  padding: '8px 24px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  borderRadius: '5px 5px 0px 0px',
                  '&.Mui-selected': {
                    backgroundColor: '#F5FAFD',
                    color: '#0053FF',
                  },

                  color: '#69707B',
                  textTransform: 'none',
                  ...karlaProRegularFontStyles,
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '22px',
                },
              },
              '& > span.MuiTabs-indicator': {
                backgroundColor: '#0053FF',
              },
            },
          }}
        >
          <Tab label="Report" {...a11yProps(0)} />
          <Tab label="Investigation Plans" {...a11yProps(1)} />
          <Tab label="Review Protocols" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <WikiReport watch={watch} setValue={setValue} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <WikiPlans watch={watch} setValue={setValue} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <WikiDocReview watch={watch} setValue={setValue} />
      </CustomTabPanel>
    </>
  );
};

export default WikiDocuments;
