import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/interviewlist.css';
import './reportlist.css';
import '../../../components/styles/datagridStyles.css';
import NotificationToast from '../../../components/shared/toast/NotificationToast';
import ResourcePageHeader from '../../../components/shared/resource-page-header/ResourcePageHeader';
import { homePortalSearchInputStyles } from '../../../components/shared/resource-page-header/styles';
import { reportsRequest } from '../../../api/whistleblower';
import ReportListTable from '../../../components/shared/report-list-table/ReportListTable';
import { insightsContentWrapperStyles } from '../../../components/homeportal/insights-content/styles';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import KanbanBoard from '../../../components/shared/kanban-board/KanbanBoard';
import BaseButton from '../../../components/base/BaseButton';
import ReportSettings from './ReportSettings';
import LEIAAContext from '../../../context/context';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ display: value !== index ? 'none' : 'block' }}
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const ReportList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationCurrentStep = location.state?.currentStep;
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [reportListData, setReportListData] = useState<any | null>(null);
  const [reportMetricsData, setReportMetricsData] = useState<any | null>(null);
  const [reportBoardData, setReportBoardData] = useState<any | null>(null);
  const [tabValue, setTabValue] = useState(locationCurrentStep || 0);
  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [closedFilter, setClosedFilter] = useState(false);
  const { user } = useContext(LEIAAContext);

  const handleFetchReportsList = async (
    searchText = '',
    showClosed = closedFilter
  ) => {
    try {
      const response = await reportsRequest('list', searchText, showClosed);
      setReportListData(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleFetchReportsMetrics = async () => {
    setLoading(true);
    try {
      const response = await reportsRequest('metrics', '', false);
      setReportMetricsData(response.metrics);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchReportsBoard = async () => {
    try {
      const response = await reportsRequest('board', '', false);
      setReportBoardData(response.board);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleClosedReportChange = () => {
    setClosedFilter(!closedFilter);
    handleFetchReportsList(inputRef.current?.value || '', !closedFilter);
  };

  useEffect(() => {
    handleFetchReportsList();
    handleFetchReportsMetrics();
    handleFetchReportsBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchReportsList(searchText);
  };

  const handleDetailReport = (_report: any) => {
    if (['Admin', 'Superuser', 'Leader'].includes(user.role))
      navigate(`/report/${_report?.key}`, {
        state: { previous: 'list' },
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // setCheckedTasks([]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setTabValue(newValue);
    handleFetchReportsList();
    if (newValue === 0) {
      handleFetchReportsList();
    } else {
      handleFetchReportsBoard();
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <ResourcePageHeader
        inputRef={searchInputRef}
        headerText="Whistleblower Reports"
      />
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            '& > span': {
              color: '#0053FF',
            },
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Metrics  */}
          <Box sx={insightsContentWrapperStyles}>
            <Box>
              <Typography>New</Typography>

              <Typography variant="h1">
                {reportMetricsData?.new !== null
                  ? `${reportMetricsData?.new}`
                  : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography>In Progress</Typography>

              <Typography variant="h1">
                {reportMetricsData?.inprogress !== null
                  ? `${reportMetricsData?.inprogress}`
                  : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography>Delayed</Typography>

              <Typography variant="h1">
                {reportMetricsData?.delayed !== null
                  ? `${reportMetricsData?.delayed}`
                  : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography>Blocked</Typography>

              <Typography variant="h1">
                {reportMetricsData?.blocked !== null
                  ? `${reportMetricsData?.blocked}`
                  : '-'}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                width: '100%',
                borderBottom: '1px solid #949CA9',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  '& > div': {
                    '& > div': {
                      '& > button': {
                        display: 'flex',
                        width: '224px',
                        padding: '8px 24px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '8px',
                        borderRadius: '24px 24px 0px 0px',
                        '&.Mui-selected': {
                          backgroundColor: '#F5FAFD',
                          color: '#0053FF',
                        },

                        color: '#69707B',
                        textTransform: 'none',
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '22px',
                      },
                    },
                    '& > span.MuiTabs-indicator': {
                      backgroundColor: '#0053FF',
                    },
                  },
                }}
              >
                <Tab
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="material-icons-round">view_list</span>
                      <span style={{ marginLeft: '8px' }}>List View</span>
                    </div>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="material-icons-round">view_kanban</span>
                      <span style={{ marginLeft: '8px' }}>Board View</span>
                    </div>
                  }
                  {...a11yProps(1)}
                />
                {['Admin', 'Superuser', 'Leader'].includes(user?.role) && (
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons-round">tune</span>
                        <span style={{ marginLeft: '8px' }}>
                          Reporting Settings
                        </span>
                      </div>
                    }
                    {...a11yProps(2)}
                  />
                )}
              </Tabs>
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: '5px',
              }}
            >
              {tabValue !== 2 && (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <BaseButton
                    iconClass="material-icons-round"
                    iconText="add"
                    buttonText="New Report"
                    linkTo="/report-form"
                    width="auto"
                  />
                  {tabValue === 0 && (
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={closedFilter}
                          onChange={() => handleClosedReportChange()}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 24 },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: '#464B53',
                            fontSize: '16px',
                            ...karlaProRegularFontStyles,
                            lineHeight: '22px',
                            display: 'flex',
                            marginRight: 'auto',
                          }}
                        >
                          Closed reports
                        </Typography>
                      }
                    />
                  )}
                </Stack>
              )}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '20px',
                }}
              >
                {tabValue === 0 && (
                  <TextField
                    id="input-with-icon-textfield"
                    placeholder="Search by subject..."
                    sx={{
                      width: '250px !important',
                      ...homePortalSearchInputStyles,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ '& > span': { color: '#69707B' } }}
                        >
                          <span className="material-icons-round">search</span>
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      onChange: handleListSearchbarInputChange,
                      inputRef,
                    }}
                    variant="standard"
                  />
                )}
              </Box>
            </Box>
            <Box sx={{ width: '100%', marginTop: '-40px' }}>
              <CustomTabPanel value={tabValue} index={0}>
                {reportListData && !loading && (
                  <ReportListTable
                    reportsListData={reportListData}
                    loading={loading}
                    listClass="reports-list"
                    handleReportDetail={handleDetailReport}
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                {reportBoardData && !loading && (
                  <Box sx={{ marginTop: '50px' }}>
                    <KanbanBoard
                      columns={reportBoardData}
                      setShowErrorNotification={setShowErrorNotification}
                      setRequestError={setRequestError}
                      setColumns={setReportBoardData}
                      resourceName="Reports"
                    />
                  </Box>
                )}
              </CustomTabPanel>
              {['Admin', 'Superuser', 'Leader'].includes(user?.role) && (
                <CustomTabPanel value={tabValue} index={2}>
                  {reportBoardData && !loading && (
                    <Box
                      sx={{
                        marginTop: '5px',
                      }}
                    >
                      <ReportSettings />
                    </Box>
                  )}
                </CustomTabPanel>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReportList;
