import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { InterviewQuestion } from '../../../types/wiki';
import { matterAddedTasksWrapperStyles } from '../../matters/matter-creation/matter-creation-steps/matter-tasks-step/styles';

interface TemplateAddedQuestionsInformationProps {
  selectedIndex: number;
  interviewQuestions: any;
  handleSelectQuestion: any;
  handleRemoveInterviewQuestion: any;
  addInterviewQuestionBtn: any;
}

const TemplateAddedQuestionsInformation = ({
  selectedIndex,
  interviewQuestions,
  handleSelectQuestion,
  handleRemoveInterviewQuestion,
  addInterviewQuestionBtn,
}: TemplateAddedQuestionsInformationProps) => {
  const validQuestion = (newQuestion: InterviewQuestion) => {
    const { question } = newQuestion;
    return question !== '';
  };

  return (
    <Box>
      <Box>
        <Box>
          <Typography>Added Questions</Typography>
        </Box>
      </Box>
      {interviewQuestions.map((mt: InterviewQuestion, index: number) => {
        return (
          <Box
            key={`${mt.id}-${index}`}
            sx={matterAddedTasksWrapperStyles(selectedIndex, index)}
            onClick={() => handleSelectQuestion(index)}
          >
            <Box>
              <Box>
                <Box>
                  <Box>
                    <Typography>{index + 1}.</Typography>
                    <Typography>
                      {mt.question === '' ? 'Current Question' : mt.question}
                    </Typography>
                  </Box>
                  <Box>
                    {!validQuestion(mt) && (
                      <Box
                        sx={{
                          display: 'flex',
                          '& > span': {
                            fontSize: '16px',
                            color: '#E22727',
                          },
                        }}
                      >
                        <span className="material-icons-round">error</span>
                      </Box>
                    )}
                    <IconButton
                      data-testid="clear-task-added-id"
                      aria-label="clear task added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                      }}
                      onClick={() => handleRemoveInterviewQuestion(mt.id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
      {addInterviewQuestionBtn}
    </Box>
  );
};

export default TemplateAddedQuestionsInformation;
