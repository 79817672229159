import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { folderPermsRequest } from '../../../api/matters';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import ErrorMessage from '../../error-message/ErrorMessage';
import DropdownMenuIcon from '../../matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import {
  matterInformationSelectPaperStyles,
  matterInformationSelectStyles,
} from '../../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import NotificationToast from '../toast/NotificationToast';
import {
  leiaaAddToDialogContentStyles,
  leiaaAddToWrapperStyles,
} from './styles';

export interface DocumentFolderPermsProps {
  open: boolean;
  onClose: () => void;
  folder: any;
  refreshTable: () => void;
  setShowNotification: any;
  userList: any;
}

const DocumentFolderPerms = ({
  open,
  onClose,
  folder,
  refreshTable,
  setShowNotification,
  userList,
}: DocumentFolderPermsProps) => {
  const [menuFolderOpen, setMenuFolderOpen] = useState(false);
  const [value, setValue] = useState<any>(folder);
  const [addedUsers, setAddedUsers] = useState<any>(folder.permissions);
  const [showWarning, setShowWarning] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const cleanData = () => {
    setShowWarning(false);
    setAddedUsers([]);
    setValue('');
    setMenuFolderOpen(false);
    setRequestError(null);
    setShowErrorNotification(false);
  };

  const handleClose = () => {
    cleanData();
    onClose();
  };

  const handleFolderMenuOpen = () => {
    setMenuFolderOpen(true);
  };

  const handleFolderMenuClose = () => {
    setMenuFolderOpen(false);
  };

  const handleSubmit = async () => {
    try {
      let usersId: number[] = [];
      if (addedUsers) {
        addedUsers.forEach((element: any) => {
          usersId = [...usersId, element.user_id];
        });
      }
      await folderPermsRequest(value.id, usersId);
      handleClose();
      refreshTable();
      setShowNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleUserChange = (event: SelectChangeEvent<any>) => {
    const selectedValue = event.target.value;

    if (selectedValue !== null && selectedValue !== undefined) {
      setAddedUsers([...addedUsers, selectedValue]);
    }
  };

  const handleRemoveUser = (id: any) => {
    setAddedUsers(addedUsers.filter((user: any) => user.user_id !== id));
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={leiaaAddToWrapperStyles}>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <Box sx={leiaaAddToDialogContentStyles()}>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: '#202020',
                ...sofiaProBoldFontStyles,
                fontSize: '18px',
                lineHeight: 'normal',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              folder permissions
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                alignSelf: 'stretch',
                maxHeight: '140px',
                overflowY: 'scroll',
              }}
              className="scrollable-content"
            >
              {addedUsers?.map((user: any, index: number) => {
                return (
                  <Box
                    key={user.user_id}
                    sx={{
                      borderRadius: '12px',
                      background: '#F0F8FD',
                      display: 'inline-flex',
                      width: '100%',
                      height: '38px',
                      padding: '16px',
                      marginBottom: '16px',
                      alignItems: 'center',
                      '& > p': {
                        ...karlaProRegularFontStyles,
                        fontSize: '14px',
                        fontWeight: '400',
                      },
                    }}
                  >
                    <Typography sx={{ marginRight: '5px', color: '#949CA9' }}>
                      {index + 1}.
                    </Typography>
                    <Typography sx={{ color: '#464B53' }}>
                      {user.name}
                    </Typography>
                    <IconButton
                      data-testid="clear-doc-id"
                      aria-label="clear doc added"
                      sx={{
                        padding: '0px',
                        '&:hover': { background: 'transparent' },
                        '& > span': {
                          fontSize: '16px',
                        },
                        marginLeft: 'auto',
                      }}
                      onClick={() => handleRemoveUser(user.user_id)}
                    >
                      <span className="material-icons-round">close</span>
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ width: '100%' }}>
              <Select
                displayEmpty
                labelId="leiaa-simple-select-label"
                id="leiaa-simple-select"
                size="small"
                open={menuFolderOpen}
                onClose={handleFolderMenuClose}
                onOpen={handleFolderMenuOpen}
                // eslint-disable-next-line react/no-unstable-nested-components
                IconComponent={() => (
                  <DropdownMenuIcon
                    isMenuOpen={menuFolderOpen}
                    handleDropdownClientIconClick={handleFolderMenuOpen}
                    isAdornment={false}
                  />
                )}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      ...matterInformationSelectPaperStyles,
                    },
                  },
                }}
                value=""
                sx={{
                  ...matterInformationSelectStyles('40px !important', '100%'),
                  width: '100%',
                }}
                onChange={handleUserChange}
              >
                <MenuItem disabled value="">
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#69707B',
                    }}
                  >
                    Select Users to add permissions
                  </Typography>
                </MenuItem>
                {userList?.map((user: any) => {
                  return (
                    <MenuItem key={user.user_id} value={user}>
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {showWarning && (
              <ErrorMessage type="error" message="Incorrect Information" />
            )}
            <Box sx={{ display: 'inherit', width: '100%', gap: '16px' }}>
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={onClose}
                >
                  <Typography>Cancel</Typography>
                </Button>
              </Box>
              <Box sx={{ width: '50%', margin: 'auto' }}>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={handleSubmit}
                >
                  <Typography>Change</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentFolderPerms;
