/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
  IconButton,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationWrapperStyles,
} from '../../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { LEIAAInput } from '../../../../components/inputs';
import {
  primaryButtonStyles,
  secondaryButtonStyles,
} from '../../../../components/styles';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../../styles/textStyles';
import {
  getAdminReportingSettingsRequest,
  updateAdminReportingSettingsRequest,
} from '../../../../api/whistleblower';
import CreationNotification from '../../../../components/shared/creation-notification/CreationNotification';

const ReportConcernPage = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [countryHotlines, setCountryHotlines] = useState<any>([]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleFetchData = async () => {
    setLoadingData(true);
    try {
      const response = await getAdminReportingSettingsRequest();

      Object.keys(response).forEach((fieldName) => {
        setValue(fieldName, response[fieldName]);
      });

      setCountryHotlines(response.hotlines);

      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      data.hotlines = watch('hotlines');
      await updateAdminReportingSettingsRequest(data);
      setShowNotification(true);
      handleFetchData();
    } catch (error: any) {
      console.log(error);
    }
  };

  const addNewHotline = () => {
    const newHotline = {
      name: '',
      phone: '',
      id: uuidv4(),
    };

    // Update form data using setValue
    setValue('hotlines', [...countryHotlines, newHotline]);

    // Update local state
    setCountryHotlines([...countryHotlines, newHotline]);
  };

  const removeHotline = (id: number, index: number) => {
    const updatedCountryHotlines = countryHotlines.filter(
      (hotline: any) => hotline.id !== id
    );
    setValue('hotlines', updatedCountryHotlines);

    setCountryHotlines(updatedCountryHotlines);
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showNotification) {
      // Scroll to the top after form submission
      window.scrollTo({ top: 0 });
    }
  }, [showNotification]);

  return loadingData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        '& > span': {
          color: '#0053FF',
        },
        marginTop: '50%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showNotification && (
        <CreationNotification
          title="Settings saved successfully!"
          subTitle=""
          showNotification={showNotification}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '20px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        Reporting concern page
      </Typography>

      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '15px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        This page provides the whistleblower with two options to submit a
        report, through a phone call or an online form. located at{' '}
        <a
          href={`${process.env.REACT_APP_SELF_URL}report-form`}
          target="_blank"
          rel="noreferrer"
        >
          /report-form
        </a>
      </Typography>

      <Box sx={matterInformationWrapperStyles}>
        <Box
          sx={{
            ...matterInformationFormWrapperStyles,
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...matterInformationInputWrapperStyles,
              paddingTop: '5px',
            }}
          >
            <Box>
              <LEIAAInput
                labelText="Main header text"
                controllerName="reporting_form_main_header"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="40px !important"
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  ...karlaProRegularFontStyles,
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '22px',
                  marginBottom: '-20px',
                }}
              >
                Available country phone lines
              </Typography>
              {countryHotlines.map((hotline: any, index: any) => {
                return (
                  <Box
                    key={`hotline-b-${index}`}
                    className="engaged-box-report"
                  >
                    <LEIAAInput
                      labelText="Country name"
                      controllerName={`hotlines[${index}].name`}
                      control={control}
                      handleChange={(e: ChangeEvent<{ value: any }>) => {
                        countryHotlines[index].name = e;
                        setCountryHotlines(countryHotlines);
                      }}
                      inputPlaceholder="Enter name"
                      inputWidth="100%"
                      inputHeight="40px"
                      required
                      key={`hotline-f-n-${uuidv4()}`}
                    />
                    <LEIAAInput
                      labelText="Phone number"
                      controllerName={`hotlines[${index}].phone`}
                      control={control}
                      handleChange={(e: ChangeEvent<{ value: any }>) => {
                        countryHotlines[index].phone = e;
                        setCountryHotlines(countryHotlines);
                      }}
                      inputPlaceholder="Enter phone"
                      inputWidth="100%"
                      inputHeight="40px"
                      required
                      key={`hotline-l-n-${uuidv4()}`}
                    />

                    <Box key={`hotline-delete-${uuidv4()}`}>
                      <IconButton
                        onClick={() => removeHotline(hotline.id, index)}
                        sx={{ '& > span': { color: '#464B53' } }}
                      >
                        <span className="material-icons-round">close</span>
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}

              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    height: '30px',
                    padding: '8px 16px',
                    gap: '8px',
                  }}
                  onClick={() => addNewHotline()}
                >
                  <span className="material-icons-round">add</span>
                  <Typography>Add new phone line</Typography>
                </Button>
              </Box>
            </Box>
            {/* phone */}
            <Box sx={{ height: '2px', borderBottom: '1px solid  #D3ECF6' }} />
            <Box>
              <LEIAAInput
                labelText="Phone section sub-header"
                controllerName="reporting_form_phone_header"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="40px !important"
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box>
              <LEIAAInput
                labelText="Phone section content"
                controllerName="reporting_form_phone_content"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="100px"
                multiline
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box>
              <LEIAAInput
                labelText="Phone section link text"
                controllerName="reporting_form_phone_link"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="40px !important"
                required
                customClassName="scrollable-content"
              />
            </Box>
            {/* online */}
            <Box sx={{ height: '2px', borderBottom: '1px solid  #D3ECF6' }} />
            <Box>
              <LEIAAInput
                labelText="Online section sub-header"
                controllerName="reporting_form_form_header"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="40px !important"
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box>
              <LEIAAInput
                labelText="Online section content"
                controllerName="reporting_form_form_content"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="100px"
                multiline
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box>
              <LEIAAInput
                labelText="Online section link text"
                controllerName="reporting_form_form_link"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="40px !important"
                required
                customClassName="scrollable-content"
              />
            </Box>
            {/* faq */}
            <Box sx={{ height: '2px', borderBottom: '1px solid  #D3ECF6' }} />
          </Box>
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Button variant="contained" sx={primaryButtonStyles()} type="submit">
          <Typography>Save</Typography>
          <span className="material-icons-round">save</span>
        </Button>
      </Stack>
    </form>
  );
};

export default ReportConcernPage;
