import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { QuestionAnswer } from '../types/matters';
import { questionnaireFileTranslatorRequest } from '../api/matters';

type Action = 'upload' | 'replace' | 'add';

const useQuestionnaireManagement = (
  // FORM CONTROLS PROPS
  watch: any,
  setValue: any,
  // MATTER QUESTIONNAIRE PROPS, PATH
  matterAddedQuestionnaire: any,
  matterFieldPath: string,
  matterFieldQAPath: string,
  updateBothFields: boolean,
  // FILE PROPS AND QUESTIONNAIRE FIELD PATH
  uploadedFile: File | undefined,
  setUploadedFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  questionnairePath: string,
  // DISPATCH FUNCTIONS TO CONTROL FILES AND FILENAMES
  interviewFileNamesPath: string | undefined,
  addQuestionnaireFileDispatchFn: any,
  addUploadedFileDispatchFn: any,
  replaceFilesNameDispatchFn: any,
  replaceFilesDispatchFn: any,
  removeUploadedFileDispachFn: any,
  removeQuestionnaireFilesDispatchFn: any,
  questionsToDeletePath: string | undefined,
  fieldToDeletePath: string | undefined,
  // FORM CONTROLS PROPS
  setIsAccordionExpanded: any,
  handleConfirmationDialogClose: () => void,
  setOpenAlertOpenDialog: any,
  setRequestError: any,
  setShowNotification: any,
  forInterview: boolean
) => {
  const dispatch = useDispatch();

  const handleQuestionnaireAction = async (file: File, action: Action) => {
    try {
      const response = await questionnaireFileTranslatorRequest(
        file,
        forInterview
      );
      dispatch(
        addQuestionnaireFileDispatchFn({
          fileName: file.name,
          q_a: response,
        })
      );

      let transformedQA;
      if (forInterview) {
        transformedQA = response.map(({ answer, ...qa }: QuestionAnswer) => ({
          ...qa,
          id: uuidv4(),
        }));
      } else {
        transformedQA = response.map((qa: QuestionAnswer) => ({
          ...qa,
          id: uuidv4(),
        }));
      }

      if (action === 'upload') {
        setValue(questionnairePath, transformedQA);
        setIsAccordionExpanded(true);
      } else if (action === 'replace') {
        if (matterAddedQuestionnaire.length > 0) {
          if (uploadedFile) {
            dispatch(replaceFilesNameDispatchFn(uploadedFile.name));
            dispatch(replaceFilesDispatchFn(uploadedFile.name));
          }
        }
        setValue(questionnairePath, []);
        setValue(questionnairePath, transformedQA);
        handleConfirmationDialogClose();
      } else if (action === 'add') {
        const currentQuestionnaire = matterAddedQuestionnaire;
        const updatedQuestionnaire = [
          ...currentQuestionnaire,
          ...transformedQA,
        ];
        setValue(questionnairePath, updatedQuestionnaire);

        setOpenAlertOpenDialog(false);
      }
      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleUploadFile = (file: File) => {
    handleQuestionnaireAction(file, 'upload');
  };

  const handleReplaceCurrentQuestions = () => {
    if (uploadedFile) {
      handleQuestionnaireAction(uploadedFile, 'replace');
    }
  };

  const handleAddNewQuestions = () => {
    if (uploadedFile) {
      handleQuestionnaireAction(uploadedFile, 'add');
    }
  };

  const handleAddFile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.target.files[0] as File;
    if (!file) return;

    setUploadedFile(file);
    dispatch(addUploadedFileDispatchFn(file.name));
    if (interviewFileNamesPath) {
      const currentFileNames = watch(interviewFileNamesPath) || [];

      setValue(interviewFileNamesPath, [...currentFileNames, file.name]);
    }
    if (matterAddedQuestionnaire.length > 0) {
      setOpenAlertOpenDialog(true); // Open the dialog
    } else {
      handleUploadFile(file);
    }
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'drop') {
      const file = e.dataTransfer.files[0];
      if (!file) return;
      file.isUploading = true;
      setUploadedFile(file);
      dispatch(addUploadedFileDispatchFn(file));
      if (interviewFileNamesPath) {
        const currentFileNames = watch(interviewFileNamesPath) || [];

        setValue(interviewFileNamesPath, [...currentFileNames, file.name]);
      }
      if (matterAddedQuestionnaire.length > 0) {
        setOpenAlertOpenDialog(true); // Open the dialog
      } else {
        handleUploadFile(file);
      }
    }
  };

  const handleRemoveFile = (fileName: string) => {
    dispatch(removeUploadedFileDispachFn(fileName));
    dispatch(removeQuestionnaireFilesDispatchFn(fileName));

    const currentQuestionnaire = matterAddedQuestionnaire;
    const updatedQuestionnaire = currentQuestionnaire.filter(
      (q: QuestionAnswer) => q.source !== fileName
    );
    setValue(matterFieldQAPath, updatedQuestionnaire);

    if (interviewFileNamesPath) {
      const currentFileNames = watch(interviewFileNamesPath) || [];
      const updatedInterviewFileNames = currentFileNames.filter(
        (f: string) => f !== fileName
      );
      setValue(interviewFileNamesPath, updatedInterviewFileNames);
    }
  };

  const handleRemoveMatterField = (id: string | number) => {
    const newValue = watch(matterFieldPath).filter((mi: any) => mi.id !== id);

    if (fieldToDeletePath) {
      const fieldIdsToDelete = watch(fieldToDeletePath) || [];
      if (typeof id === 'number') {
        setValue(fieldToDeletePath, [...fieldIdsToDelete, id]);
      }
    }
    setValue(matterFieldPath, newValue);
  };

  const handleAddManualQuestionAnswer = () => {
    // Get the current questionnaire field array from the form payload
    const currentQuestionnaire = matterAddedQuestionnaire;
    // Add the new question and answer to the questionnaire field array
    const updatedQuestionnaire = [
      ...currentQuestionnaire,
      {
        id: uuidv4(),
        question: '',
        ...(updateBothFields ? { answer: '' } : {}), // Only add answer field if updateBothFields is true
        type: 'manual',
      },
    ];

    // Set the updated questionnaire field array as the new value of 'questionnaire' using setValue
    setValue(matterFieldQAPath, updatedQuestionnaire);
    setIsAccordionExpanded(true); // Open the accordion
  };

  const handleRemoveManualQuestionAnswer = (id: string | number) => {
    const newQuestionnaireValue = matterAddedQuestionnaire.filter(
      (q: QuestionAnswer) => q.id !== id
    );

    if (questionsToDeletePath) {
      const questionIdsToDelete = watch(questionsToDeletePath) || [];
      if (typeof id === 'number') {
        setValue(questionsToDeletePath, [...questionIdsToDelete, id]);
      }
    }

    setValue(matterFieldQAPath, newQuestionnaireValue);
  };

  const handleAddTemplateQuestionAnswer = () => {
    const interviewCategory = watch('interview_category');
    const interviewType = watch('interview_type');
    const template = watch('template_data');
    const currentQuestionnaire = matterAddedQuestionnaire;
    const newQuestions: {
      type: string;
      answer?: any;
      id: string;
      question: any;
    }[] = [];

    template.interview_template.forEach((templateQuestion: any) => {
      if (
        templateQuestion.type === interviewType &&
        templateQuestion.category === interviewCategory
      ) {
        newQuestions.push({
          id: uuidv4(),
          question: templateQuestion.question,
          ...(templateQuestion.answer
            ? { answer: templateQuestion.answer }
            : {}),
          type: 'manual',
        });
      }
    });

    const updatedQuestionnaire = [...currentQuestionnaire, ...newQuestions];

    setValue(matterFieldQAPath, updatedQuestionnaire);
    setIsAccordionExpanded(true);
  };

  return {
    handleRemoveMatterField,
    handleAddManualQuestionAnswer,
    handleRemoveManualQuestionAnswer,
    handleAddTemplateQuestionAnswer,
    handleReplaceCurrentQuestions,
    handleAddNewQuestions,
    handleAddFile,
    handleDrag,
    handleRemoveFile,
  };
};

export default useQuestionnaireManagement;
