import { Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { karlaProRegularItalicFontStyles } from '../../styles/textStyles';
import LEIAAPagination from '../shared/lists/LEIAAPagination';
import SortableHeader from '../shared/matters-list-table/SortableHeader';
import {
  mattersListWrapperStyles,
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
} from '../shared/matters-list-table/styles';

interface AnalysisListTableProps {
  analysisListData: any[];
  loading: boolean;
  setCheckedAnalysis?: any;
  setSelectedAnalysis?: any;
  handleDetailAnalysis?: any;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No Analyses.
    </Typography>
  </GridOverlay>
);

const AnalysisListTable = ({
  analysisListData,
  loading,
  setCheckedAnalysis,
  setSelectedAnalysis,
  handleDetailAnalysis,
}: AnalysisListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleScroll = () => {
    const containerElement = document.querySelector('.analysis-list');

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector('.analysis-list');

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && analysisListData !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector('.analysis-list');
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, analysisListData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'created_date',
      headerName: 'Date of Creation',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;

        const jsDate = new Date(cellValue);

        const day = jsDate.getDate().toString().padStart(2, '0');
        const month = (jsDate.getMonth() + 1).toString().padStart(2, '0');
        const year = jsDate.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;

        return (
          <Box>
            <p className="MuiDataGrid-cellContent">{formattedDate}</p>
          </Box>
        );
      },
    },
    {
      field: 'created_by',
      headerName: 'Requested By',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
  ];

  const analysisRowsAreEmpty = analysisListData?.length === 0;

  return (
    <Box sx={mattersListWrapperStyles(analysisRowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            ref={containerRef}
            rows={analysisListData}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            onRowSelectionModelChange={(ids) => {
              setCheckedAnalysis(ids);
              if (ids.length !== 0) {
                setSelectedAnalysis(
                  analysisListData?.find((row) => row.id === ids[0])
                );
              }
            }}
            pageSizeOptions={[6]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowClick={(params) => {
              handleDetailAnalysis(params.row);
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            sx={mattersListStyles}
            className="analysis-list"
          />
        )}
      </Box>
    </Box>
  );
};

export default AnalysisListTable;
