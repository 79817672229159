import {
  sofiaProBoldFontStyles,
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../../styles/textStyles';

// eslint-disable-next-line import/prefer-default-export
export const leiaaRenameDialogContentStyles = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    width: '100%',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        alignSelf: 'stretch',
        '& > span': {
          color: '#EDB900',
          fontSize: '40px',
        },
        '& > div': {
          display: 'flex',
          height: '40px',
          padding: '10px 0px',
          alignItems: 'flex-start',
          gap: '5px',
          '& > p': {
            color: '#202020',
            ...sofiaProBoldFontStyles,
            fontSize: '18px',
            lineHeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'uppercase',
          },
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '48px',
        alignSelf: 'stretch',
        '& > div:nth-of-type(1)': {
          display: 'grid',
          alignItems: 'flex-start',
          gap: '5px',
          alignSelf: 'stretch',
          '& > p': {
            color: '#202020',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
        '& > div:nth-of-type(2)': {
          display: 'flex',
          alignItems: 'flex-start',
          gap: '16px',
          alignSelf: 'stretch',
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            flex: '1 0 0',
            '& > div': {
              display: 'flex',
              padding: '0px 16px',
              alignItems: 'flex-start',
              gap: '10px',
              alignSelf: 'stretch',
              '& > p': {
                color: '#949CA9',
                textAlign: 'center',
                ...karlaProRegularItalicFontStyles,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: 'normal',
              },
            },
          },
        },
      },
    },
  };
};

export const leiaaRenameDialogWrapperStyles = {
  '& > div:nth-of-type(3)': {
    background: 'rgba(211, 236, 246, 0.70)',
    backdropFilter: 'blur(2px)',
    '& > div.MuiPaper-root': {
      display: 'flex',
      width: '544px',
      padding: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '24px',
      border: '1px solid #D3ECF6',
      background: '#FFF',
      boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
      maxHeight: 'unset',
      maxWidth: 'unset',
    },
  },
};

export const leiaaAddToWrapperStyles = {
  '& > div:nth-of-type(3)': {
    background: 'rgba(211, 236, 246, 0.70)',
    backdropFilter: 'blur(2px)',
    '& > div.MuiPaper-root': {
      display: 'flex',
      width: '736px',
      padding: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '24px',
      border: '1px solid #D3ECF6',
      background: '#FFF',
      boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
      maxHeight: 'unset',
      maxWidth: 'unset',
    },
  },
};

// eslint-disable-next-line import/prefer-default-export
export const leiaaAddToDialogContentStyles = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    width: '100%',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        alignSelf: 'stretch',
        '& > span': {
          color: '#EDB900',
          fontSize: '40px',
        },
        '& > div': {
          display: 'flex',
          height: '40px',
          padding: '10px 0px',
          alignItems: 'flex-start',
          gap: '5px',
          '& > p': {
            color: '#202020',
            ...sofiaProBoldFontStyles,
            fontSize: '18px',
            lineHeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'uppercase',
          },
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '40px',
        alignSelf: 'stretch',
        '& > div:nth-of-type(1)': {
          display: 'grid',
          alignItems: 'flex-start',
          gap: '5px',
          alignSelf: 'stretch',
          '& > p': {
            color: '#202020',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
      },
    },
  };
};

export const addToInterviewDialog = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    width: '100%',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        alignSelf: 'stretch',
        padding: '0',
        '& > span': {
          color: '#EDB900',
          fontSize: '40px',
        },
        '& > div': {
          display: 'flex',
          height: '40px',
          padding: '10px 0px',
          alignItems: 'flex-start',
          gap: '5px',
          '& > p': {
            color: '#202020',
            ...sofiaProBoldFontStyles,
            fontSize: '18px',
            lineHeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'uppercase',
          },
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '40px',
        alignSelf: 'stretch',
        '& > div:nth-of-type(1)': {
          display: 'grid',
          alignItems: 'flex-start',
          gap: '5px',
          alignSelf: 'stretch',
          '& > p': {
            color: '#202020',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
      },
    },
  };
};
