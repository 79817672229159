import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../../styles/textStyles';

export const interviewChatContainerStyles = {
  padding: '16px',
  backgroundColor: '#32353C',
  height: '100%',
  width: '100%',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > p': {
      ...sofiaProRegularFontStyles,
      fontWeight: 500,
      fontSize: '16px',
      margin: '26px 0px',
    },
  },
  '& > ul': {
    marginBottom: '18px !important',
  },
  border: '0',
};

export const interviewChatControlsStyles = {
  '& > form': {
    borderTop: 'none',
    padding: '0px',
    height: '40px',
    gap: 'unset',
    '& > div:nth-of-type(2)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > button:nth-of-type(1)': {
        display: 'none',
      },
    },
    '& > div > input': {
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      color: '#E4E8F2',
      marginRight: '8px',
      width: '375px',
      backgroundColor: 'transparent !important',
      border: '1px solid #E4E8F2 !important',
      borderRadius: '14px !important',
      '::placeholder': {
        color: '#E4E8F2',
      },
    },
    '& > div > input:nth-of-type(2)': {
      display: 'none',
    },
    '& > div > button': {
      padding: '8px',
      backgroundColor: 'transparent !important',
      '&:hover': {
        fontWeight: 800,
        color: '#0053FF',
      },
      '&:nth-of-type(1)': {
        width: '40px',
      },
      '&:nth-of-type(2)': {
        width: '55px',
      },
    },
  },
};

export const interviewChatListItemStyles = {
  padding: '0px',
  display: 'block',
  margin: '0px',
  '& > span.lk-message-body': {
    padding: '0px 12px',
    height: '40px',
    display: 'flex',
    borderRadius: '14px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&.lk-chat-entry[data-lk-message-origin="remote"] .lk-message-body': {
    backgroundColor: '#F2FAFE !important',
    color: '#202020',
  },
  '&.lk-chat-entry[data-lk-message-origin="local"] .lk-message-body': {
    backgroundColor: '#69707B !important',
  },
};
