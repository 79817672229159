import { Box, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import {
  FilteredTemplatesList,
  FilteredTemplatesListResponse,
} from '../../../types/wiki';
import LEIAAPagination from '../lists/LEIAAPagination';
import SortableHeader from '../matters-list-table/SortableHeader';
import {
  dataGridWrapperStyles,
  mattersListMaskShadowStyles,
  mattersListStyles,
} from '../matters-list-table/styles';
import templatesListWrapperStyles from './styles';

interface TemplateListTableProps {
  templatesListData: FilteredTemplatesListResponse | null;
  loading: boolean;
  listClass: string;
  dashboard?: boolean;
  setCheckedTemplates?: any;
  setSelectedTemplate?: any;
  handleDetailTemplate?: any;
}

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <Typography
      variant="body1"
      sx={{
        ...karlaProRegularItalicFontStyles,
        color: '#69707B',
        fontSize: '14px',
      }}
    >
      No templates.
    </Typography>
  </GridOverlay>
);

const TemplatesListTable = ({
  templatesListData,
  loading,
  dashboard = false,
  listClass,
  setCheckedTemplates,
  setSelectedTemplate,
  handleDetailTemplate,
}: TemplateListTableProps) => {
  const apiRef = useGridApiRef();
  const containerRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);

  const handleScroll = () => {
    const containerElement = document.querySelector(`.${listClass}`);

    if (containerElement) {
      const { scrollLeft, scrollWidth, clientWidth } = containerElement;
      setShowShadow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const addScrollListener = useCallback(() => {
    setTimeout(() => {
      const containerElement = document.querySelector(`.${listClass}`);

      if (containerElement) {
        containerElement.addEventListener('scroll', handleScroll);
      }
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      addScrollListener();
    };

    if (!loading && templatesListData?.templatesList !== undefined) {
      addScrollListener();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);

      const containerElement = document.querySelector(`.${listClass}`);
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, templatesListData]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Template Name',
      flex: 1,
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
    {
      field: 'topic_name',
      headerName: 'Investigation Topic',
      flex: 1.5,
      renderCell: (params: GridCellParams) => {
        const cellValue = params.row.topic.name as string;
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '0px',
              height: '36px',
              '& > p': {
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                width: '100%',
              },
            }}
          >
            <p className="MuiDataGrid-cellContent" title={cellValue}>
              {cellValue}
            </p>
          </Box>
        );
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const sort = apiRef.current.state.sorting.sortModel.filter(
          (s) => s.field === params.field
        )?.[0]?.sort;

        return <SortableHeader params={params} sort={sort} />;
      },
    },
  ];

  const templateRowsAreEmpty = templatesListData?.templatesList.length === 0;

  return (
    <Box sx={templatesListWrapperStyles(templateRowsAreEmpty)}>
      <Box
        sx={{
          ...dataGridWrapperStyles,
          position: 'relative',
        }}
      >
        <Box
          className="table-mask-shadow"
          sx={mattersListMaskShadowStyles(showShadow)}
        >
          <Box>
            <Box />
          </Box>
        </Box>
        {!loading && apiRef !== null ? (
          <DataGrid
            ref={containerRef}
            rows={templatesListData?.templatesList as FilteredTemplatesList[]}
            columns={columns}
            apiRef={apiRef}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection={!dashboard}
            onRowSelectionModelChange={(ids) => {
              setCheckedTemplates(ids);
              if (ids.length !== 0) {
                setSelectedTemplate(
                  templatesListData?.templatesList.find(
                    (row: any) => row.id === ids[0]
                  )
                );
              }
            }}
            disableRowSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'Mui-even'
                : 'Mui-odd'
            }
            onRowClick={(params) => {
              handleDetailTemplate(params.row);
            }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              pagination: LEIAAPagination,
            }}
            hideFooter={dashboard}
            sx={mattersListStyles}
            className={listClass}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TemplatesListTable;
