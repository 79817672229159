import React from 'react';
import { useMaybeRoomContext } from '@livekit/components-react';
import type {
  CaptureOptionsBySource,
  ToggleSource,
} from '@livekit/components-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  log,
  setupManualToggle,
  setupMediaToggle,
} from '@livekit/components-core';
import { mergeProps } from '../../../vendors/mergeProps';
import { useObservableState } from '../../../vendors/useObservableState';
import { getSourceIcon } from '../../../vendors/util';

/** @public */
export type TrackToggleProps<T extends ToggleSource> = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'onChange'
> & {
  source: T;
  showIcon?: boolean;
  deviceKind?: MediaDeviceKind;
  initialState?: boolean;
  onChange?: (enabled: boolean) => void;
  captureOptions?: CaptureOptionsBySource<T>;
};

/** @public */
export type UseTrackToggleProps<T extends ToggleSource> = Omit<
  TrackToggleProps<T>,
  'showIcon' | 'deviceKind'
>;

/** @public */
export function useTrackToggle<T extends ToggleSource>({
  source,
  onChange,
  initialState,
  captureOptions,
  ...rest
}: UseTrackToggleProps<T>) {
  const room = useMaybeRoomContext();
  const track = room?.localParticipant?.getTrack(source);

  const { toggle, className, pendingObserver, enabledObserver } = React.useMemo(
    () =>
      room
        ? setupMediaToggle<T>(source, room, captureOptions)
        : setupManualToggle(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [room, source, JSON.stringify(captureOptions)]
  );

  const pending = useObservableState(pendingObserver, false);
  const enabled = useObservableState(
    enabledObserver,
    initialState ?? !!track?.isEnabled
  );

  React.useEffect(() => {
    onChange?.(enabled);
  }, [enabled, onChange]);

  React.useEffect(() => {
    if (initialState !== undefined) {
      log.debug('forcing initial toggle state', source, initialState);
      toggle(initialState);
    }
    // only execute once at the beginning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newProps = React.useMemo(
    () => mergeProps(rest, { className }),
    [rest, className]
  );

  const clickHandler: React.MouseEventHandler<HTMLButtonElement> =
    React.useCallback(
      (evt) => {
        toggle();
        rest.onClick?.(evt);
      },
      [rest, toggle]
    );

  return {
    toggle,
    enabled,
    pending,
    track,
    buttonProps: {
      ...newProps,
      'aria-pressed': enabled,
      'data-lk-source': source,
      'data-lk-enabled': enabled,
      disabled: pending,
      onClick: clickHandler,
    } as React.ButtonHTMLAttributes<HTMLButtonElement>,
  };
}

/**
 * With the TrackToggle component it is possible to mute and unmute your camera and microphone.
 * The component uses an html button element under the hood so you can treat it like a button.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <TrackToggle source={Track.Source.Microphone} />
 *   <TrackToggle source={Track.Source.Camera} />
 * </LiveKitRoom>
 * ```
 * @public
 */
export function LEIAATrackToggle<T extends ToggleSource>({
  showIcon,
  ...props
}: TrackToggleProps<T>) {
  const { buttonProps, enabled, track } = useTrackToggle(props);
  let label = '';

  if (track?.kind === 'audio' || props.source === 'microphone') {
    if (enabled) {
      label = 'Mute';
    } else {
      label = 'Unmute';
    }
  } else if (track?.kind === 'video' || props.source === 'camera') {
    if (enabled) {
      label = 'Disable video';
    } else {
      label = 'Enable video';
    }
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button {...buttonProps}>
      {(showIcon ?? true) && getSourceIcon(props.source, enabled)}
      {label}
      {props.children}
    </button>
  );
}
