import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  createFilterOptions,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { User } from '../types/auth';
import { userUpdateRequest, usersRequest } from '../api/users';
import LEIAAInput from '../components/inputs/LEIAAInput';
import { matterCreationHeaderWrapperStyles } from '../components/matters/matter-creation/styles';
import { simpleGoBackButtonStyles } from '../components/styles';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import LEIAASelect from '../components/inputs/LEIAASelect';
import { loginFormInputStyles } from '../components/login/styles';
import UserCreationFooter from '../components/user-profile/UserCreationFooter';
import UserCreationConfirmation from '../components/user-profile/UserCreationConfirmation';
import ShowPassword from '../components/login/form/ShowPassword';
import PasswordInputInstructions from '../components/login/form/PasswordInputInstructions';
import NotificationToast from '../components/shared/toast/NotificationToast';
import 'react-phone-number-input/style.css';
import { ClientOptionType } from '../types/matters';
import { clientsRequest } from '../api/matters';
import { LEIAAAutoComplete } from '../components/inputs';

const UsersCreate = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userCreationFinish, setUserCreationFinish] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [clients, setClients] = useState<readonly ClientOptionType[]>();
  const clientFilter = createFilterOptions<ClientOptionType>();

  const handleFetchClients = async () => {
    try {
      const response = await clientsRequest();
      setClients(response as ClientOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const onSubmit: SubmitHandler<
    FieldValues | Omit<User, 'dateJoined'>
  > = async (data) => {
    try {
      if (userId) {
        await userUpdateRequest(userId, data as Omit<User, 'dateJoined'>);
      } else {
        await usersRequest('POST', data as Omit<User, 'dateJoined'>);
      }
      setUserCreationFinish(true);
      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleValidate = (value?: string) => {
    if (!value) {
      return true;
    }
    if (typeof value === 'string' && isValidPhoneNumber(value)) {
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }
    return false;
  };

  useEffect(() => {
    handleFetchClients();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        position: 'relative',
        '& > form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            padding: '48px 32px',
            flex: 1,
          },

          width: '100%',
        },
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {!userCreationFinish ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box sx={matterCreationHeaderWrapperStyles}>
              <Box>
                <Box>
                  <Typography>New User</Typography>
                  {/* SEARCH INPUT BOX  */}
                </Box>
                <Button
                  sx={simpleGoBackButtonStyles}
                  variant="text"
                  onClick={handleCancel}
                >
                  <span className="material-symbols-outlined">arrow_back</span>
                  <Typography variant="caption" component="p">
                    Back
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box sx={matterInformationWrapperStyles}>
              {/* FORM  */}
              <Box sx={matterInformationFormWrapperStyles}>
                <Typography>Add new user</Typography>
                <Box sx={matterInformationInputWrapperStyles}>
                  <Box>
                    <LEIAAInput
                      labelText="First Name"
                      controllerName="first_name"
                      control={control}
                      inputPlaceholder="Enter first name"
                      inputWidth="100%"
                      inputHeight="40px"
                    />
                  </Box>
                  <Box>
                    <LEIAAInput
                      labelText="Last Name"
                      controllerName="last_name"
                      control={control}
                      inputPlaceholder="Enter last name"
                      inputWidth="100%"
                      inputHeight="40px"
                    />
                  </Box>
                  <Box>
                    <LEIAAInput
                      labelText="Username"
                      controllerName="username"
                      control={control}
                      inputPlaceholder="Enter username"
                      inputWidth="100%"
                      inputHeight="40px"
                    />
                  </Box>
                  <Box>
                    <LEIAAAutoComplete
                      labelText="Organisation"
                      controllerName="organisation"
                      control={control}
                      autoCompleteFilter={clientFilter}
                      autoCompleteOptions={clients}
                      inputPlaceholder="Enter organisation name"
                      inputWidth="100%"
                      inputHeight="40px"
                    />
                  </Box>
                  <Box>
                    <LEIAASelect
                      labelText="Role"
                      controllerName="role"
                      control={control}
                      inputPlaceholder="Select role"
                      inputHeight="40px"
                      inputWidth="100%"
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Superuser">Superuser</MenuItem>
                      <MenuItem value="Leader">Leader</MenuItem>
                      <MenuItem value="Agent">Agent</MenuItem>
                      <MenuItem value="Collaborator">Collaborator</MenuItem>
                    </LEIAASelect>
                  </Box>
                  <Box>
                    <LEIAAInput
                      labelText="Email address"
                      controllerName="email"
                      control={control}
                      inputPlaceholder="Enter email address"
                      inputWidth="100%"
                      inputHeight="40px"
                      type="email"
                    />
                  </Box>
                  <Box>
                    <Controller
                      name="phone_number"
                      defaultValue=""
                      control={control}
                      rules={{ validate: (value) => handleValidate(value) }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Box>
                            <InputLabel
                              data-testid="matter-form-label-id"
                              htmlFor="Phone number"
                            >
                              Enter phone number
                            </InputLabel>
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="GB"
                              id="phone-input"
                            />
                            <InputLabel
                              data-testid="matter-form-label-id"
                              htmlFor="Phone number"
                              style={{ color: 'red' }}
                            >
                              {errors.phone_number
                                ? 'Enter a correct phone number, this phone number is invalid!'
                                : null}
                            </InputLabel>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <UserCreationFooter />
        </form>
      ) : (
        <UserCreationConfirmation isEdition={false} />
      )}
    </Box>
  );
};

export default UsersCreate;
