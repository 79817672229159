/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Typography,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../../styles/textStyles';
import { LEIAAInput } from '../../inputs';
import { matterInformationInputWrapperStyles } from '../../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { primaryButtonStyles } from '../../styles';
import { getEmailsData, postEmail } from '../../../api/general';

export interface Message {
  from: string;
  message: string;
  notice: boolean;
  timestamp: string;
  staff: boolean;
  interview: boolean;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  const words = name.split(' ');
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      words.length > 1
        ? `${words[0][0]}${words[1][0]}`.toUpperCase()
        : `${name[0]}`.toUpperCase(),
  };
}

const InterviewEmailChat = (interview: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  const interviewId = interview.interview.id;
  // eslint-disable-next-line react/destructuring-assignment
  const intervieweeName = interview.interview.interviewee_name;

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [messages, setMessages] = useState<Message[]>([]);
  const [userScrolled, setUserScrolled] = useState(false);

  const listRef = useRef<HTMLUListElement>(null);

  const handleFetchMessages = async () => {
    try {
      const response = await getEmailsData('interview_emails', interviewId);
      setMessages(response);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    if (watch('message') === '') return;
    try {
      await postEmail('interview_emails', data, interviewId);
      setValue('message', '');
      await handleFetchMessages();
      setUserScrolled(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  // implement different method when time is abundant
  const startAutoRefresh = () => {
    const intervalId = setInterval(() => {
      handleFetchMessages();
    }, 5000); // Refresh every 5 seconds

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    handleFetchMessages();

    const intervalCleanup = startAutoRefresh();

    if (listRef.current && !userScrolled) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }

    return () => {
      intervalCleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listRef.current && !userScrolled) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages, userScrolled]);

  const handleScroll = () => {
    setUserScrolled(true);
  };

  return (
    <Box
      sx={{
        paddingTop: '5px',
        paddingBottom: '5px',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          color: '#464B53  !important',
          ...karlaProRegularFontStyles,
          fontSize: '17px !important',
          fontStyle: 'normal  !important',
          fontWeight: '400  !important',
          lineHeight: '30px  !important',
          borderBottom: '1px solid #D3ECF6',
          width: '100%',
          pointerEvents: 'none',
        }}
      >
        Interviewee email communication
      </Typography>
      <List
        sx={{
          width: '100%',
          maxHeight: '70vh',
          overflowY: 'scroll',
        }}
        className="scrollable-content"
        id="chat-list"
        ref={listRef}
        onScroll={handleScroll}
      >
        {messages && messages.length > 0 ? (
          messages.map((message) => (
            <ListItem
              key={uuidv4()}
              sx={{
                marginLeft: message.staff ? 'auto' : '0',
                textAlign: message.staff ? 'right' : 'left',
                background: message.staff ? '#d3ecf6' : '#f1fafe',
                borderRadius: '12px',
                marginTop: '5px',
                marginBottom: '5px',
                width: 'fit-content',
              }}
            >
              {!message.staff && (
                <ListItemAvatar>
                  <Avatar {...stringAvatar(intervieweeName)} />
                </ListItemAvatar>
              )}

              <ListItemText
                primary={
                  message.interview ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    />
                  ) : (
                    message.message
                  )
                }
                secondary={`${
                  !message.staff ? `${message.from} - ` : 'LEIAA - '
                }${message.timestamp}`}
                sx={{
                  '& > span': {
                    ...karlaProRegularFontStyles,
                    fontSize: '16px',
                    color: '#202020',
                  },
                  '& > p': {
                    ...karlaProRegularItalicFontStyles,
                    fontSize: '12px',
                    color: '#202020',
                  },
                }}
              />
            </ListItem>
          ))
        ) : (
          <Typography
            sx={{
              ...karlaProRegularItalicFontStyles,
              fontSize: '16px',
              color: '#202020',
              textAlign: 'center',
              padding: '40px',
            }}
          >
            No messages
          </Typography>
        )}
      </List>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '15px',
              '& > p': {
                color: '#202020',
                fontSize: '14px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
              },
            }}
          >
            <Box sx={{ ...matterInformationInputWrapperStyles, width: '100%' }}>
              <Box>
                <LEIAAInput
                  labelText=""
                  controllerName="message"
                  control={control}
                  inputPlaceholder="Enter message, message will be sent via email"
                  inputWidth="100%"
                  inputHeight="48px"
                  required={false}
                  disabled={!messages || messages.length <= 0}
                />
              </Box>
            </Box>
            <Box sx={{ marginLeft: 'auto' }}>
              <Button
                variant="contained"
                sx={primaryButtonStyles()}
                type="submit"
                disabled={!messages || messages.length <= 0}
              >
                <Typography>Send</Typography>
                <span className="material-icons-round">send</span>
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default InterviewEmailChat;
