// eslint-disable-next-line import/prefer-default-export
export const homePortalButtonsStyles = {
  display: 'flex',
  flexDirection: 'row',
  padding: '0px',
  gap: '16px',
  width: '100%',
  '& > button': {
    display: 'flex',
    padding: '8px 20px',
    gap: '8px',
    width: '100%',
    height: '40px',
    borderRadius: '16px',
    textTransform: 'none',
  },
  '& > button:nth-of-type(1)': {
    backgroundColor: '#FFFFFF',
    color: '#0053FF',
    border: '1px solid #0053FF',

    '&:hover': {
      backgroundColor: '#EBF8FE',
      color: '#0B25B0',
      border: '1px solid #0B25B0',
    },
    '&:focus': {
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:active': {
      backgroundColor: '#EBF8FE',
      borderColor: '#140070',
      color: '#140070',
    },
  },
  '& > button:nth-of-type(2)': {
    backgroundColor: '#0053FF',
    color: '#FFF',
    border: '1px solid #0053FF',

    '&:hover': {
      backgroundColor: '#0B25B0',
      border: '1px solid #0B25B0',
    },
    '&:focus': {
      border: '1px solid #A962D5',
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:active': {
      backgroundColor: '#140070',
    },
  },
};

export const redHomePortalButtonsStyles = {
  display: 'flex',
  flexDirection: 'row',
  padding: '0px',
  gap: '16px',
  width: '100%',
  '& > button': {
    display: 'flex',
    padding: '8px 20px',
    gap: '8px',
    width: '100%',
    height: '40px',
    borderRadius: '16px',
    textTransform: 'none',
  },
  '& > button:nth-of-type(1)': {
    backgroundColor: '#FFFFFF',
    color: '#FC4242', // Base red color
    border: '1px solid #FC4242', // Base red color

    '&:hover': {
      backgroundColor: '#FEEBEB', // Light red color
      color: '#B00B0B', // Dark red color
      border: '1px solid #B00B0B', // Dark red color
    },
    '&:focus': {
      boxShadow: '0px 0px 10px rgba(176, 11, 11, 0.3)', // Dark red color
    },
    '&:active': {
      backgroundColor: '#FEEBEB', // Light red color
      borderColor: '#700014', // Darker red color
      color: '#700014', // Darker red color
    },
  },
  '& > button:nth-of-type(2)': {
    backgroundColor: '#FC4242', // Base red color
    color: '#FFF',
    border: '1px solid #FC4242', // Base red color

    '&:hover': {
      backgroundColor: '#B00B0B', // Dark red color
      border: '1px solid #B00B0B', // Dark red color
    },
    '&:focus': {
      border: '1px solid #D5A962', // Lighter red color
      boxShadow: '0px 0px 10px rgba(176, 11, 11, 0.3)', // Dark red color
    },
    '&:active': {
      backgroundColor: '#700014', // Darker red color
    },
  },
};
