type Force = [number, number];
type Listener = (force: Force, e: TouchEvent) => void;
type ElementValues = {
  start: Force | null;
  listeners: Set<Listener>;
  handleTouchstart: (e: TouchEvent) => void;
  handleTouchend: (e: TouchEvent) => void;
};

const elements = new WeakMap<HTMLElement, ElementValues>();

function readTouch(e: TouchEvent): Force | null {
  const touch = e.changedTouches[0];
  if (!touch) {
    return null;
  }
  return [touch.clientX, touch.clientY];
}

function deleteListener(element: HTMLElement, cb: Listener): void {
  const elementValues = elements.get(element);
  if (!elementValues) return;

  const { listeners } = elementValues;
  listeners.delete(cb);

  if (listeners.size === 0) {
    elements.delete(element);
    const { handleTouchstart, handleTouchend } = elementValues;
    element.removeEventListener('touchstart', handleTouchstart);
    element.removeEventListener('touchend', handleTouchend);
  }
}

function addListener(element: HTMLElement, cb: Listener): () => void {
  let elementValues = elements.get(element) as any;
  if (!elementValues) {
    const listeners = new Set<Listener>();

    const handleTouchstart = (e: TouchEvent) => {
      const start = readTouch(e);
      if (start) {
        elementValues.start = start;
      }
    };

    const handleTouchend = (e: TouchEvent) => {
      const { start } = elementValues as any;
      if (!start) return;

      const end = readTouch(e);
      if (end) {
        const [startX, startY] = start;
        const [endX, endY] = end;
        const force: Force = [endX - startX, endY - startY];
        listeners.forEach((listener) => listener(force, e));
      }
    };

    element.addEventListener('touchstart', handleTouchstart);
    element.addEventListener('touchend', handleTouchend);

    elementValues = {
      handleTouchend,
      handleTouchstart,
      listeners,
      start: null,
    };
    elements.set(element, elementValues);
  }

  elementValues.listeners.add(cb);
  return () => deleteListener(element, cb);
}

export function addSwipeLeftListener(
  element: HTMLElement,
  cb: (_force: number, e: TouchEvent) => void
) {
  return addListener(element, ([x, y], e) => {
    if (x < 0 && -x > Math.abs(y)) {
      cb(x, e);
    }
  });
}

export function addSwipeRightListener(
  element: HTMLElement,
  cb: (_force: number, e: TouchEvent) => void
) {
  return addListener(element, ([x, y], e) => {
    if (x > 0 && x > Math.abs(y)) {
      cb(x, e);
    }
  });
}

export function addSwipeUpListener(
  element: HTMLElement,
  cb: (_force: number, e: TouchEvent) => void
) {
  return addListener(element, ([x, y], e) => {
    if (y < 0 && -y > Math.abs(x)) {
      cb(x, e);
    }
  });
}

export function addSwipeDownListener(
  element: HTMLElement,
  cb: (_force: number, e: TouchEvent) => void
) {
  return addListener(element, ([x, y], e) => {
    if (y > 0 && y > Math.abs(x)) {
      cb(x, e);
    }
  });
}
