/* eslint-disable @typescript-eslint/no-shadow */
import {
  Box,
  Button,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import {
  createGeneralTaskRequest,
  getTaskBoardRequest,
} from '../../../api/tasks';
import NotificationToast from '../../../components/shared/toast/NotificationToast';
import { primaryButtonStyles } from '../../../components/styles';
import { sortButtonsPaperWrapperStyles } from '../../../components/user-profile/styles';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import TaskBoard from './TaskBoard';
import { Column } from './taskstyles';
import TaskCreationDialog from './TaskCreationDialog';
import {
  a11yProps,
  CustomTabPanel,
} from '../../../components/matters/matter-detail/MatterDetailDocuments';
import LEIAAContext from '../../../context/context';

const Tasks = () => {
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [columns, setColumns] = useState<Column | null>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const openSortMenu = Boolean(sortAnchorEl);
  const [selectedSortTasks, setSelectedSortTasks] = useState('');
  const [loading, setLoading] = useState(true);
  const [showTaskCreationDialog, setShowTaskCreationDialog] = useState(false);
  const [newAddedTask, setNewAddedTask] = useState<any>(null);
  const [tabValue, setTabValue] = useState(0);
  const { user } = useContext(LEIAAContext);

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleFetchColumnData = async (sort: any = false, mine = true) => {
    setLoading(true); // Start loading

    try {
      const response = await getTaskBoardRequest(false, sort, mine);

      setColumns(response);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setShowErrorNotification(true);
        setRequestError(error.response.data.message);
      } else {
        setShowErrorNotification(true);
        setRequestError('Task management error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSortTasksChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const { value } = event.currentTarget.childNodes[0] as HTMLInputElement;
    if (value === selectedSortTasks) {
      setSelectedSortTasks('');
      handleFetchColumnData(false, tabValue === 0);
    } else if (value) {
      setSelectedSortTasks(value);
      handleFetchColumnData(value, tabValue === 0);
    }
  };

  const generalTaskCreation = async () => {
    try {
      const response = await createGeneralTaskRequest();
      handleFetchColumnData(selectedSortTasks, tabValue === 0);
      setNewAddedTask(response);
      setShowTaskCreationDialog(false);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setShowErrorNotification(true);
        setRequestError(error.response.data.message);
      } else {
        setShowErrorNotification(true);
        setRequestError('Task management error');
      }
    }
  };

  useEffect(() => {
    handleFetchColumnData(selectedSortTasks, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retryAttempts = 3;
  const retryInterval = 500;
  const retryClick = (attempt: number) => {
    const element = document.querySelector(
      `[data-rbd-draggable-id="${newAddedTask.id}"]`
    );

    if (element) {
      const clickableElement = element.querySelector('div') as HTMLElement;
      clickableElement.click();
    } else {
      console.log('Element not found!');
      if (attempt < retryAttempts) {
        setTimeout(() => retryClick(attempt + 1), retryInterval);
      }
    }
  };

  useEffect(() => {
    if (newAddedTask) {
      retryClick(1);
      setNewAddedTask(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAddedTask]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 0) {
      handleFetchColumnData(selectedSortTasks, true);
    } else {
      handleFetchColumnData(selectedSortTasks, false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showTaskCreationDialog && (
        <TaskCreationDialog
          open={showTaskCreationDialog}
          onClose={() => setShowTaskCreationDialog(false)}
          generalTaskCreation={generalTaskCreation}
        />
      )}
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            borderBottom: '1px solid #949CA9',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& > div': {
                '& > div': {
                  '& > button': {
                    display: 'flex',
                    width: '300px',
                    padding: '8px 24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '24px 24px 0px 0px',
                    '&.Mui-selected': {
                      backgroundColor: '#F5FAFD',
                      color: '#0053FF',
                    },

                    color: '#69707B',
                    textTransform: 'none',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                '& > span.MuiTabs-indicator': {
                  backgroundColor: '#0053FF',
                },
              },
            }}
          >
            <Tab label="My tasks" {...a11yProps(0)} />
            {['Admin', 'Superuser', 'Leader'].includes(user?.role) && (
              <Tab label="All assigned matter tasks" {...a11yProps(1)} />
            )}
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '15px' }}>
        <Button
          variant="contained"
          onClick={() => setShowTaskCreationDialog(true)}
          sx={{ ...primaryButtonStyles(), height: '40px' }}
        >
          <span className="material-icons-round">add</span>
          <Typography>Add task</Typography>
        </Button>
        <Button
          id="sort-button"
          aria-controls={openSortMenu ? 'sort-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openSortMenu ? 'true' : undefined}
          onClick={handleSortClick}
          endIcon={
            openSortMenu ? (
              <span className="material-icons-round">arrow_drop_up</span>
            ) : (
              <span className="material-icons-round">arrow_drop_down</span>
            )
          }
          sx={{
            boxShadow: openSortMenu
              ? '0px 0px 10px 0px rgba(21, 10, 84, 0.30)'
              : 'none',
            '& > p': {
              width: '56px',
              ...karlaProRegularFontStyles,
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: openSortMenu ? 700 : 500,
              letterSpacing: '0.2px',
            },
            display: 'flex',
            height: '40px',
            padding: '8px 8px 8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px',
            border: '1px solid #0053FF',
            background: '#FFF',
            color: '#0053FF',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#EBF8FE',
              color: '#0B25B0',
              border: '1px solid #0B25B0',
            },
            '&:focus': {
              boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
            },
            '&:active': {
              backgroundColor: '#EBF8FE',
              borderColor: '#140070',
              color: '#140070',
            },
          }}
        >
          <Typography variant="caption" component="p">
            Sort By
          </Typography>
        </Button>
        <Menu
          id="sort-menu"
          anchorEl={sortAnchorEl}
          open={openSortMenu}
          onClose={handleSortClose}
          MenuListProps={{
            'aria-labelledby': 'sort-button',
          }}
          sx={sortButtonsPaperWrapperStyles}
        >
          <RadioGroup value={selectedSortTasks}>
            <FormControlLabel
              value="due_date"
              control={<Radio onClick={handleSortTasksChange} />}
              label="Due Date: newest to oldest"
            />
            <FormControlLabel
              value="title"
              control={<Radio onClick={handleSortTasksChange} />}
              label="Task Name: Alphabetically"
            />
            <FormControlLabel
              value="investigation__title"
              control={<Radio onClick={handleSortTasksChange} />}
              label="Matter Name: Alphabetically"
            />
          </RadioGroup>
        </Menu>
      </Box>
      <Box>
        <Box sx={{ width: '100%' }}>
          <CustomTabPanel value={tabValue} index={0}>
            {columns && (
              <TaskBoard
                columns={columns}
                setShowErrorNotification={setShowErrorNotification}
                setRequestError={setRequestError}
                setColumns={setColumns}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {columns && (
              <TaskBoard
                columns={columns}
                setShowErrorNotification={setShowErrorNotification}
                setRequestError={setRequestError}
                setColumns={setColumns}
              />
            )}
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Tasks;
