import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { primaryButtonStyles } from '../../styles';
import NotificationToast from '../toast/NotificationToast';
import { getDocumentURLFromTokenRequest } from '../../../api/matters';
import LEIAALogo1 from '../../../assets/LEIAALogo1.png';

const DirectDownloadLink = () => {
  const { token } = useParams();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDownloadFile = async () => {
    try {
      if (token) {
        setLoading(true);
        const data = await getDocumentURLFromTokenRequest(token);
        const link = document.createElement('a');
        link.href = data.file;
        link.download = data.name;
        link.click();
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
    }
  };

  /* useEffect(() => {
    handleDownloadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
*/
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'space-around',
        height: '100vh',
        width: '100vw',
        padding: '40px',
        boxSizing: 'border-box',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}

      <Box
        sx={{
          padding: '40px',
          background: '#FFFFFF',
          border: '1px solid #D3ECF7',
          boxShadow: '0px 0px 45px rgba(0, 94, 253, 0.08)',
          borderRadius: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
          }}
        >
          <img
            src={LEIAALogo1}
            style={{ width: '228.12px', height: '99.41px' }}
            alt="LEIAALogo1"
          />
          <img
            src={process.env.REACT_APP_CLIENT_LOGO_URL}
            style={{
              width: `calc(228.12px * ${process.env.REACT_APP_CLIENT_LOGO_RATIO})`,
              height:
                process.env.REACT_APP_CLIENT_LOGO_FULLHEIGHT === 'true'
                  ? '100%'
                  : `calc(99.41px * ${process.env.REACT_APP_CLIENT_LOGO_RATIO})`,
              marginLeft: '15px',
              borderLeft: '1px solid #e5e5e5',
            }}
            alt="Clients logo"
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            sx={{ ...primaryButtonStyles() }}
            onClick={handleDownloadFile}
          >
            <Typography>Download File</Typography>
            <span className="material-icons-round">file_present</span>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DirectDownloadLink;
