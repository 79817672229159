import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';

export interface Task {
  id: number;
  title: string;
  description: string;
  investigation_name: string;
  due_date: string;
  comments_count: string;
  matter_id: string;
  assignee: string;
}

export interface Column {
  title: string;
  items: Task[];
}

export const boardWrapperBoxStyles = {
  overflowX: 'scroll',
  paddingBottom: '5px',
  display: 'flex',
  width: '100%',
  minHeight: '80vh',
  // To Do - Progress - Blocked - Review -Done
  '& >  div:nth-of-type(1) > div:nth-of-type(1)': {
    background: '#A962D5 !important',
    color: 'white',
  },
  '& >  div:nth-of-type(2) > div:nth-of-type(1)': {
    background: '#3375FF !important',
    color: 'white',
  },
  '& >  div:nth-of-type(3) > div:nth-of-type(1)': {
    background: '#EDB900 !important',
    color: 'white',
  },
  '& >  div:nth-of-type(4) > div:nth-of-type(1)': {
    background: '#70BC42 !important',
    color: 'white',
  },
  '& >  div:nth-of-type(5) > div:nth-of-type(1)': {
    background: '#E22727 !important',
    color: 'white',
  },
  '& >  div:nth-of-type(5)': {
    marginRight: '0px !important',
  },
};

export const boardColumnHeaderStyles = {
  padding: '2px 10px',
  borderRadius: '5px',
  alignSelf: 'flex-start',
};

// 24px border rad

export const boardColumnHeaderWrapperBoxStyles = {
  minHeight: '100px',
  display: 'flex',
  flexDirection: 'column',
  background: '#f3f3f3',
  minWidth: '341px',
  borderRadius: '10px',
  padding: '15px 15px',
  marginRight: '45px',
  border: '2px solid #F0F8FD',
};

export const taskCardWrapperBoxStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0 15px',
  minHeight: '106px',
  borderRadius: '10px',
  maxWidth: '311px',
  background: 'white',
  marginTop: '15px',
  border: '1px solid #F0F8FD',
  gap: '5px',
  '&:hover': {
    background: '#f5fafd',
    border: '1px solid rgb(78 157 250)',
  },
};

export const taskCardTitleStyles = {
  ...karlaProRegularFontStyles,
  textTransform: 'capitalize',
  fontSize: '18px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
};

export const taskCardDueTextStyles = {
  ...karlaProRegularFontStyles,
  textTransform: 'capitalize',
  fontSize: '16px',
};

export const taskCardFooterTextStyles = {
  display: 'flex',
  alignItems: 'center',
  ...karlaProRegularFontStyles,
  textTransform: 'capitalize',
  fontSize: '14px',
};

export const taskCardFooterBoxStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
};

export const taskCardFooterIconStyles = {
  verticalAlign: 'middle',
  marginRight: '5px',
  fontSize: '18px',
  color: 'rgb(78 157 250)',
};

export const taskCardFooterSubTextStyles = {
  color: '#4f4f4f',
};

// eslint-disable-next-line import/prefer-default-export
export const taskCreationDialogContentStyles = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    width: '100%',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
    '& > div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      alignSelf: 'stretch',
      '& > div:nth-of-type(1)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        alignSelf: 'stretch',
        '& > span': {
          color: '#EDB900',
          fontSize: '40px',
        },
        '& > div': {
          display: 'flex',
          height: '40px',
          padding: '10px 0px',
          alignItems: 'flex-start',
          gap: '5px',
          '& > p': {
            color: '#202020',
            ...sofiaProBoldFontStyles,
            fontSize: '18px',
            lineHeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'uppercase',
          },
        },
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        alignSelf: 'stretch',
        '& > div:nth-of-type(1)': {
          display: 'grid',
          alignItems: 'flex-start',
          gap: '5px',
          alignSelf: 'stretch',
          '& > p': {
            color: '#202020',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '22px',
          },
        },
        '& > div:nth-of-type(2)': {
          display: 'flex',
          alignItems: 'flex-start',
          gap: '16px',
          alignSelf: 'stretch',
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            flex: '1 0 0',
            '& > div': {
              display: 'flex',
              padding: '0px 16px',
              alignItems: 'flex-start',
              gap: '10px',
              alignSelf: 'stretch',
              '& > p': {
                color: '#949CA9',
                textAlign: 'center',
                ...karlaProRegularItalicFontStyles,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: 'normal',
              },
            },
          },
        },
      },
    },
  };
};

export const taskCreationialogWrapperStyles = {
  '& > div:nth-of-type(3)': {
    background: 'rgba(211, 236, 246, 0.70)',
    backdropFilter: 'blur(2px)',
    '& > div.MuiPaper-root': {
      display: 'flex',
      width: '544px',
      padding: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '24px',
      border: '1px solid #D3ECF6',
      background: '#FFF',
      boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
      maxHeight: 'unset',
      maxWidth: 'unset',
    },
  },
};
