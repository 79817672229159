import { Box, Typography } from '@mui/material';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import {
  detailsInterviewersDataStyles,
  detailsRecordingsDataStyles,
  interviewerDataStyles,
} from './styles';

interface LEIAAInterviewersProps {
  interview?: any;
}
const LEIAAInterviewers = ({ interview = [] }: LEIAAInterviewersProps) => {
  return (
    <Box sx={detailsRecordingsDataStyles}>
      <Typography>Interviewers</Typography>
      <Box sx={detailsInterviewersDataStyles}>
        <Box>
          {interview.interviewers && interview.interviewers.length > 0 ? (
            interview.interviewers.map((interviewer: any, index: any) => (
              <>
                <Box key={index} sx={interviewerDataStyles}>
                  <Box>
                    <Typography>{interviewer.name}</Typography>
                    <Typography>{interviewer.role}</Typography>
                  </Box>
                  <Box>
                    <Typography>{interviewer.email}</Typography>
                  </Box>
                </Box>
                {index < interview.interviewers.length - 1 && (
                  <hr
                    style={{
                      border: 0,
                      borderTop: '1px solid #D3ECF7',
                      width: '100%',
                      margin: '10px 0',
                    }}
                  />
                )}
              </>
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{
                ...karlaProRegularItalicFontStyles,
                color: '#69707B',
                fontSize: '14px',
              }}
            >
              No interviewers.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LEIAAInterviewers;
