import { Box, Typography } from '@mui/material';
import { interviewsListStatusChipWrapperStyles } from './styles';

interface StatusChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const StatusChip = ({
  status,
  heightValue,
  wrapperHeight,
}: StatusChipProps) => {
  let backgroundColor;
  let color = '#FFFFFF';

  if (status === 'false') {
    status = 'Active';
  } else if (status === 'true') {
    status = 'Archived';
  }

  switch (status) {
    case 'Scheduled':
      backgroundColor = '#A962D5';
      break;
    case 'Active':
      backgroundColor = '#3375FF';
      break;
    case 'Conducted':
      backgroundColor = '#38B763';
      break;
    case 'Archived':
      backgroundColor = '#CBCFDD';
      color = '#464B53';
      break;
    default:
      backgroundColor = '#A962D5'; // Default color if status is not recognized
      break;
  }

  return (
    <Box
      sx={interviewsListStatusChipWrapperStyles(
        backgroundColor,
        color,
        heightValue,
        wrapperHeight
      )}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default StatusChip;
