/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import '../../../pages/styles/matterlist.css';
import '../../styles/datagridStyles.css';
import {
  addToFraudDetectionDBRequest,
  batchDeleteMatterDocsRequest,
  createFolderRequest,
  createOrGetDownloadTokenRequest,
  createOrGetUploadTokenRequest,
  editDocumentRequest,
  folderDocsRequest,
  getDocumentURLRequest,
  renameFolderRequest,
} from '../../../api/matters';
import ListButtons from '../../shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';
import DocumentsListTable from '../../shared/docs-list-table/DocumentsListTable';
import DocumentDialog from '../../shared/docs-list-table/DocumentDialog';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import NotificationToast from '../../shared/toast/NotificationToast';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import { useDocumentPermissions } from '../../../hooks/usePermissions';
import DocumentAddToInterviewDialogNested from '../../shared/docs-list-table/DocumentAddToInterviewDialogNested';
import DocumentAddToFolderDialog from '../../shared/docs-list-table/DocumentAddToFolderDialog';
import DocumentFolderPerms from '../../shared/docs-list-table/DocumentFolderPerms';
import FolderFileInformationDrawer from '../../shared/document-detail/FolderFileInformationDrawer';
import api from '../../../api';

const MatterDetailFolderDetail = () => {
  const { matterId, folderId } = useParams();
  const matterIdInt: number = matterId ? parseInt(matterId, 10) : 0;
  const location = useLocation();
  const { matterDetails } = location.state;
  const [loading, setLoading] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(folderId);
  const [folderDocs, setFolderDocs] = useState<any>(location.state.response);
  const documentPerms = useDocumentPermissions();
  const [checkedDocs, setCheckedDocs] = useState<any>([]);
  const [selectedDocs, setSelectedDocs] = useState<any | null>(null);
  const [openRenameDialog, setRenameDialogOpen] = useState(false);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [inputRenameValue, setInputRenameValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showFolderCreationNotification, setShowFolderCreationNotification] =
    useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showRenameNotification, setShowRenameNotification] = useState(false);
  const [openAddToInterviewDialog, setOpenAddToInterviewDialog] =
    useState(false);
  const [openAddToFolderDialog, setOpenAddToFolderDialog] = useState(false);
  const [showAddToFraudDatabase, setShowAddToFraudDatabase] = useState(false);
  const [openFolderPermsDialog, setOpenFolderPermsDialog] = useState(false);
  const [showAddedNotification, setShowAddedNotification] = useState(false);
  const [showFolderPermsNotification, setShowFolderPermsNotification] =
    useState(false);
  const [showAddedBundleNotification, setShowAddedBundleNotification] =
    useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [folderName, setFolderName] = useState(location.state.folderName);
  const [breadcrumbHistory, setBreadcrumbHistory] = useState([
    { name: folderName, id: currentFolderId },
  ]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleGetMatterDetailDocuments = async () => {
    setLoading(true);
    try {
      const response = await folderDocsRequest(
        currentFolderId ? currentFolderId.toString() : '',
        inputRef.current?.value || ''
      );
      setFolderDocs(response);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = async () => {
    setOpenDrawer(false);
    setSelectedDocs(null);
    setCheckedDocs([]);
    await handleGetMatterDetailDocuments();
  };

  const addToFraudDetectionHandler = async () => {
    if (matterId) {
      await addToFraudDetectionDBRequest(matterId, checkedDocs);
      setShowAddToFraudDatabase(true);
      setOpenDrawer(false);
    }
  };

  const handleListSearchbarInputChange = () => {
    refreshData();
  };

  const handleDownloadFile = async () => {
    if (!selectedDocs) return;
    try {
      const data = await getDocumentURLRequest(matterIdInt, selectedDocs.id);
      const link = document.createElement('a');
      link.href = data.file;
      link.download = selectedDocs.name;
      link.click();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleBatchDeleteDocs = async () => {
    await batchDeleteMatterDocsRequest(matterIdInt, checkedDocs);
    await refreshData();
  };

  const renameHandler = async () => {
    try {
      if (selectedDocs.folder) {
        await renameFolderRequest(selectedDocs.id, inputRenameValue);
      } else {
        await editDocumentRequest(
          matterIdInt,
          selectedDocs.id,
          inputRenameValue,
          undefined,
          undefined
        );
      }
      setRenameDialogOpen(false);
      setInputRenameValue('');
      await refreshData();
      setShowRenameNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const removeFromResourceHandler = async () => {
    try {
      if (checkedDocs.length > 1) {
        checkedDocs.forEach(async (docId: any) => {
          await editDocumentRequest(
            matterIdInt,
            docId,
            undefined,
            undefined,
            true
          );
        });
      } else {
        await editDocumentRequest(
          matterIdInt,
          selectedDocs.id,
          undefined,
          undefined,
          true
        );
      }

      await refreshData();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleGoAddDocuments = () => {
    navigate(`/matter/addDocument/${matterId}/${currentFolderId}`, {
      state: { currentStep: 3, matterDetails },
    });
  };

  const handleNewFolder = async () => {
    try {
      await createFolderRequest(inputRenameValue, matterId, currentFolderId);
      setOpenCreateFolderDialog(false);
      setInputRenameValue('');
      await refreshData();
      setShowFolderCreationNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleDetailDocs = async (doc: any) => {
    try {
      if (doc.folder) {
        const response = await folderDocsRequest(doc.id.toString(), '');
        setFolderDocs(response);
        setFolderName(doc.name);
        setCurrentFolderId(doc.id);

        const folderAlreadyExists = breadcrumbHistory.some(
          (item) => item.id === doc.id
        );

        if (!folderAlreadyExists) {
          setBreadcrumbHistory((prevHistory) => [
            ...prevHistory,
            { name: doc.name, id: doc.id },
          ]);
        }
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleBreadcrumbClick = async (index: number) => {
    const newHistory = breadcrumbHistory.slice(0, index + 1);
    const folder = newHistory[index];
    setBreadcrumbHistory(newHistory);

    await handleDetailDocs({
      id: folder.id,
      name: folder.name,
      folder: true,
    });
  };

  const folderUploadLinkHandler = async () => {
    if (!selectedDocs) return;

    try {
      const splitId = selectedDocs.id.split('-');

      const token = await createOrGetUploadTokenRequest(
        splitId[splitId.length - 1]
      );

      const uploadLink = `http://${window.location.host}:/matter/upload-portal/${token}`;
      navigator.clipboard.writeText(uploadLink);
      setShowCopyNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const fileDownloadLinkHandler = async () => {
    if (!selectedDocs) return;

    try {
      const token = await createOrGetDownloadTokenRequest(selectedDocs.id);

      const downloadLink = `http://${window.location.host}:/direct-link/${token}`;
      navigator.clipboard.writeText(downloadLink);
      setShowCopyNotification(true);
    } catch (error: any) {
      console.log(error);
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const informationSideBarHandler = () => {
    setOpenDrawer(true);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '48px 32px',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {showCopyNotification && (
        <CreationNotification
          title="Link copied to clipboard!"
          subTitle=""
          showNotification={showCopyNotification}
          handleCloseNotification={() => setShowCopyNotification(false)}
        />
      )}
      {showRenameNotification && (
        <CreationNotification
          title="Resource renamed successfully!"
          subTitle=""
          showNotification={showRenameNotification}
          handleCloseNotification={() => setShowRenameNotification(false)}
        />
      )}
      {showAddedNotification && (
        <CreationNotification
          title="Added to folder successfully!"
          subTitle=""
          showNotification={showAddedNotification}
          handleCloseNotification={() => setShowAddedNotification(false)}
        />
      )}
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showFolderPermsNotification && (
        <CreationNotification
          title="Changed folder permissions successfully!"
          subTitle=""
          showNotification={showFolderPermsNotification}
          handleCloseNotification={() => setShowFolderPermsNotification(false)}
        />
      )}
      {showAddToFraudDatabase && (
        <CreationNotification
          title="Documents successfully added to fraud detection database!"
          subTitle=""
          showNotification={showAddToFraudDatabase}
          handleCloseNotification={() => setShowAddToFraudDatabase(false)}
        />
      )}
      {showAddedBundleNotification && (
        <CreationNotification
          title="Added to interview bundle successfully!"
          subTitle=""
          showNotification={showAddedBundleNotification}
          handleCloseNotification={() => setShowAddedBundleNotification(false)}
        />
      )}
      {showFolderCreationNotification && (
        <CreationNotification
          title="Created folder successfully!"
          subTitle=""
          showNotification={showFolderCreationNotification}
          handleCloseNotification={() =>
            setShowFolderCreationNotification(false)
          }
        />
      )}
      <DocumentDialog
        open={openCreateFolderDialog}
        onClose={() => setOpenCreateFolderDialog(false)}
        title="New Folder"
        label="Folder name"
        primaryBtnText="Create"
        onPrimaryButtonClick={handleNewFolder}
        setInputValue={setInputRenameValue}
      />
      <DocumentDialog
        open={openRenameDialog}
        onClose={() => setRenameDialogOpen(false)}
        title={selectedDocs?.folder ? 'Rename Folder' : 'Rename Document'}
        label={selectedDocs?.folder ? 'Folder name ' : 'Document name'}
        primaryBtnText="OK"
        onPrimaryButtonClick={renameHandler}
        setInputValue={setInputRenameValue}
      />
      <DocumentAddToInterviewDialogNested
        open={openAddToInterviewDialog}
        onClose={() => setOpenAddToInterviewDialog(false)}
        docList={folderDocs?.documents}
        matterId={matterId}
        setShowAddedBundleNotification={setShowAddedBundleNotification}
        selectedDocs={
          folderDocs?.documents
            ? folderDocs.documents.filter((doc: { id: any }) =>
                checkedDocs.includes(doc.id)
              )
            : []
        }
      />
      <DocumentAddToFolderDialog
        open={openAddToFolderDialog}
        onClose={() => setOpenAddToFolderDialog(false)}
        docList={folderDocs?.documents}
        refreshTable={refreshData}
        setShowAddedNotification={setShowAddedNotification}
        selectedDocs={
          folderDocs?.documents
            ? folderDocs.documents.filter((doc: { id: any }) =>
                checkedDocs.includes(doc.id)
              )
            : []
        }
      />
      {openFolderPermsDialog && (
        <DocumentFolderPerms
          open={openFolderPermsDialog}
          onClose={() => setOpenFolderPermsDialog(false)}
          refreshTable={refreshData}
          setShowNotification={setShowFolderPermsNotification}
          folder={selectedDocs}
          userList={matterDetails?.users}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          },
        }}
      >
        <Box>
          <Breadcrumbs
            separator={
              <span className="material-icons-round">chevron_right</span>
            }
            aria-label="breadcrumb"
            sx={{
              '& > ol': {
                gap: '6px',
                '& > .MuiBreadcrumbs-li > div': {
                  '& > button': {
                    ...sofiaProRegularFontStyles,
                    color: '#69707B',
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    letterSpacing: '2px',
                    textTransform: 'uppercase',
                    padding: '10px 0px',
                  },
                  '& > p': {
                    color: '#949CA9',
                    ...karlaProRegularFontStyles,
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.2px',
                  },
                },
                '& > .MuiBreadcrumbs-separator': {
                  margin: '0px',
                  padding: '10px 0',
                  alignSelf: 'baseline',
                },
                '& > .MuiBreadcrumbs-li:last-child > div > button': {
                  ...sofiaProBoldFontStyles,
                  color: '#202020 !important',
                  fontSize: '18px !important',
                  fontWeight: '700 !important',
                  lineHeight: 'normal !important',
                  letterSpacing: '2px !important',
                  textTransform: 'uppercase !important',
                  padding: '10px 0 !important',
                  cursor: 'default',
                  pointerEvents: 'none',
                },
              },
            }}
          >
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() => navigate(-1)}
              >
                {matterDetails.name}
              </Link>
              <Typography>Matter</Typography>
            </Box>
            <Box>
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={() =>
                  navigate(`/matters/detail/${matterId}`, {
                    state: { currentStep: 3 },
                  })
                }
              >
                Documents
              </Link>
              <Typography>Tab</Typography>
            </Box>
            {breadcrumbHistory.map((folder, index) => (
              <Box key={folder.id}>
                <Link
                  component="button"
                  variant="body2"
                  underline="none"
                  onClick={() => handleBreadcrumbClick(index)}
                >
                  {folder.name}
                </Link>
                <Typography>Folder</Typography>
              </Box>
            ))}
          </Breadcrumbs>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '40px',
            alignSelf: 'stretch',
            '& > div:nth-of-type(1)': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flex: '1 0 0',
            },
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '32px',
              }}
            >
              <Button
                variant="contained"
                sx={{ ...primaryButtonStyles(), height: '40px' }}
                onClick={handleGoAddDocuments}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add documents</Typography>
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpenCreateFolderDialog(true)}
                sx={{ ...secondaryButtonStyles(), height: '40px' }}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add Folder</Typography>
              </Button>
            </Box>

            <TextField
              id="input-with-icon-textfield"
              placeholder="Search by document..."
              sx={{
                ...homePortalSearchInputStyles,
                marginLeft: 'auto',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ '& > span': { color: '#69707B' } }}
                  >
                    <span className="material-icons-round">search</span>
                  </InputAdornment>
                ),
                disableUnderline: true,
                onChange: handleListSearchbarInputChange,
                inputRef,
              }}
              variant="standard"
            />
          </Box>
          {selectedDocs && (
            <FolderFileInformationDrawer
              item={selectedDocs}
              open={openDrawer}
              setOpen={setOpenDrawer}
              folderPermissionsHandler={() => setOpenFolderPermsDialog(true)}
              folderUploadLinkHandler={folderUploadLinkHandler}
              handleDownloadFile={handleDownloadFile}
              addToFraudDetectionHandler={addToFraudDetectionHandler}
              renameHandler={() => setRenameDialogOpen(true)}
              fileDownloadLinkHandler={fileDownloadLinkHandler}
            />
          )}
          {checkedDocs.length > 0 && documentPerms.delete && (
            <ListButtons
              deleteHandler={handleBatchDeleteDocs}
              checkedResources={checkedDocs}
              resourceName="Document"
              removeFromResourceHandler={removeFromResourceHandler}
              handleDownloadFile={handleDownloadFile}
              informationSideBarHandler={informationSideBarHandler}
              limitCheckedDocs={false}
            />
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            '& > div:nth-of-type(1)': {
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flex: '1 0 0',
            },
            marginTop: '20px',
          }}
        >
          {documentPerms.add && (
            <ListButtons
              checkedResources={checkedDocs}
              resourceName="Documents"
              addToInterviewHandler={() => setOpenAddToInterviewDialog(true)}
              addToFolderHandler={() => setOpenAddToFolderDialog(true)}
              addToFraudDetectionHandler={addToFraudDetectionHandler}
            />
          )}
        </Box>
      </Box>

      {folderName === 'Final Report' && (
        <Box
          sx={{
            paddingTop: '15px',
            marginBottom: '-30px',
            paddingLeft: '15px !important',
          }}
        >
          <Typography
            sx={{
              ...karlaProRegularFontStyles,
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
              color: '#69707B',
            }}
          >
            * This folder only accepts a single file in pdf format
          </Typography>
        </Box>
      )}

      <Grid item xs={12} md={12}>
        <DocumentsListTable
          documentsListData={folderDocs}
          listClass="docs-list"
          loading={loading}
          setCheckedDocs={setCheckedDocs}
          setSelectedDocs={setSelectedDocs}
          handleDetailDoc={handleDetailDocs}
          informationSideBarHandler={informationSideBarHandler}
        />
      </Grid>
    </Box>
  );
};

export default MatterDetailFolderDetail;
