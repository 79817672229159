/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationWrapperStyles,
} from '../../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { LEIAAInput } from '../../../../components/inputs';
import { primaryButtonStyles } from '../../../../components/styles';
import { sofiaProBoldFontStyles } from '../../../../styles/textStyles';
import {
  getAdminReportingSettingsRequest,
  updateAdminReportingSettingsRequest,
} from '../../../../api/whistleblower';
import CreationNotification from '../../../../components/shared/creation-notification/CreationNotification';

const FollowUpPage = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [showNotification, setShowNotification] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleFetchData = async () => {
    setLoadingData(true);
    try {
      const response = await getAdminReportingSettingsRequest();

      Object.keys(response).forEach((fieldName) => {
        setValue(fieldName, response[fieldName]);
      });

      setLoadingData(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      await updateAdminReportingSettingsRequest(data);
      setShowNotification(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showNotification) {
      // Scroll to the top after form submission
      window.scrollTo({ top: 0 });
    }
  }, [showNotification]);

  return loadingData ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        '& > span': {
          color: '#0053FF',
        },
        marginTop: '50%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showNotification && (
        <CreationNotification
          title="Settings saved successfully!"
          subTitle=""
          showNotification={showNotification}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '20px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        Follow up page
      </Typography>

      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          fontSize: '15px',
          fontWeight: '500',
          marginBottom: '10px',
        }}
      >
        This page displays the submited report online form information. located
        at{' '}
        <a
          href={`${process.env.REACT_APP_SELF_URL}reporting-follow-up-dashboard/{report_id}`}
          target="_blank"
          rel="noreferrer"
        >
          /reporting-follow-up-dashboard/report_id
        </a>
      </Typography>

      <Box sx={matterInformationWrapperStyles}>
        <Box
          sx={{
            ...matterInformationFormWrapperStyles,
            width: '100%',
          }}
        >
          <Box
            sx={{
              ...matterInformationInputWrapperStyles,
              paddingTop: '5px',
            }}
          >
            <Box>
              <LEIAAInput
                labelText="Main content text"
                controllerName="reporting_follow_up_dashboard_content"
                control={control}
                inputPlaceholder="Enter text"
                inputWidth="100%"
                inputHeight="100px"
                multiline
                required
                customClassName="scrollable-content"
              />
            </Box>
            <Box sx={{ height: '2px', borderBottom: '1px solid  #D3ECF6' }} />
          </Box>
        </Box>
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <Button variant="contained" sx={primaryButtonStyles()} type="submit">
          <Typography>Save</Typography>
          <span className="material-icons-round">save</span>
        </Button>
      </Stack>
    </form>
  );
};

export default FollowUpPage;
