import { Box, Typography, Button } from '@mui/material';
import { useState } from 'react';
import LEIAADialog from '../../inputs/LEIAADialog';
import { tertiaryButtonStyles } from '../../styles';

interface BaseButtonProps {
  checkedResources: any[];
  deleteHandler?: any;
  resourceName: string;
  editHandler?: any;
  renameHandler?: any;
  addToInterviewHandler?: any;
  addToFolderHandler?: any;
  associateInterviewHandler?: any;
  removeFromResourceHandler?: any;
  interviewHandler?: any;
  addToFraudDetectionHandler?: any;
  folderPermissionsHandler?: any;
  handleDownloadFile?: any;
  folderUploadLinkHandler?: any;
  informationSideBarHandler?: any;
  addNoteHandler?: any;
  limitCheckedDocs?: boolean;
}

const ListButtons = ({
  checkedResources,
  deleteHandler,
  editHandler,
  interviewHandler,
  resourceName,
  renameHandler,
  addToInterviewHandler,
  addToFolderHandler,
  associateInterviewHandler,
  removeFromResourceHandler,
  addToFraudDetectionHandler,
  folderPermissionsHandler,
  handleDownloadFile,
  folderUploadLinkHandler,
  informationSideBarHandler,
  addNoteHandler,
  limitCheckedDocs = true,
}: BaseButtonProps) => {
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);

  const closeDialogHandler = () => {
    setOpenAlertOpenDialog(false);
  };

  const deleteDialogHandler = () => {
    deleteHandler();
    setOpenAlertOpenDialog(false);
  };

  return (
    <>
      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description={`You are about to archive ${
          checkedResources.length !== 1
            ? `multiple ${resourceName}s`
            : `a ${resourceName}`
        }. Are you sure you want to archive it?`}
        primaryButtonText={`Yes, archive ${
          checkedResources.length !== 1 ? `${resourceName}s` : resourceName
        }`}
        primaryButtonIcon="archive"
        onPrimaryButtonClick={deleteDialogHandler}
        secondaryButtonText="Cancel"
        secondaryButtonHasIcon={false}
        onSecondaryButtonClick={closeDialogHandler}
      />
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {addNoteHandler ? (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length !== 1 ? { display: 'none' } : {}),
              height: '34px',
            }}
            variant="text"
            onClick={() => addNoteHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              sticky_note_2
            </span>
            <Typography>Notes</Typography>
          </Button>
        ) : null}
        {interviewHandler ? (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length !== 1 ? { display: 'none' } : {}),
              height: '34px',
            }}
            variant="text"
            onClick={() => interviewHandler()}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#0053FF"
                d="M21.8567 13.9207L20.5935 12.4645C21.5039 11.3255 22 9.91734 22 8.44531C22 4.89137 19.1087 2 15.5547 2C12.0007 2 9.10938 4.89137 9.10938 8.44531C9.10938 11.9993 12.0007 14.8906 15.5547 14.8906H21.4141C21.6435 14.8906 21.8519 14.7567 21.9471 14.548C22.0424 14.3392 22.007 14.0941 21.8567 13.9207ZM15.5547 12.4297C15.2311 12.4297 14.9687 12.1673 14.9687 11.8438C14.9687 11.5202 15.2311 11.2578 15.5547 11.2578C15.8783 11.2578 16.1406 11.5202 16.1406 11.8438C16.1406 12.1673 15.8783 12.4297 15.5547 12.4297ZM17.1849 8.21723C16.8994 8.51793 16.5357 8.73203 16.1407 8.8393V10.0698C16.1407 10.3934 15.8783 10.6557 15.5547 10.6557C15.2311 10.6557 14.9688 10.3934 14.9688 10.0698V8.33184C14.9688 8.00824 15.2311 7.7459 15.5547 7.7459C15.8525 7.7459 16.1296 7.62672 16.3351 7.41035C16.5403 7.19418 16.6448 6.91059 16.6293 6.6118C16.6003 6.05238 16.1381 5.60527 15.5772 5.59398C15.5699 5.59383 15.5625 5.59375 15.5552 5.59375C15.0195 5.59375 14.5632 5.98793 14.4891 6.51902C14.4821 6.56848 14.4787 6.61922 14.4787 6.66984C14.4787 6.99344 14.2163 7.25578 13.8927 7.25578C13.5691 7.25578 13.3068 6.99344 13.3068 6.66984C13.3068 6.56523 13.3141 6.46004 13.3284 6.35707C13.4855 5.23117 14.4637 4.39902 15.6008 4.42234C16.773 4.44598 17.7389 5.38102 17.7996 6.55105C17.8319 7.17395 17.6136 7.7657 17.1849 8.21723Z"
              />
              <path
                fill="#0053FF"
                d="M10.3984 14.9689H6.4922C6.1686 14.9689 5.90626 14.7065 5.90626 14.3829C5.90626 14.0594 6.1686 13.797 6.4922 13.797H10.1404C8.92966 12.5722 8.12993 10.9402 7.96837 9.12717C4.6363 9.37209 2.00001 12.1613 2.00001 15.5548C2.00001 17.0269 2.49606 18.435 3.40653 19.574L2.14333 21.0303C1.99298 21.2036 1.95763 21.4487 2.0529 21.6575C2.14817 21.8662 2.35649 22.0001 2.58595 22.0001H8.44532C11.8389 22.0001 14.6281 19.3638 14.873 16.0318C13.4147 15.9018 12.0737 15.3588 10.9672 14.5214C10.9049 14.7781 10.6743 14.9689 10.3984 14.9689ZM10.3984 17.3126H6.4922C6.1686 17.3126 5.90626 17.0503 5.90626 16.7267C5.90626 16.4031 6.1686 16.1408 6.4922 16.1408H10.3984C10.722 16.1408 10.9844 16.4031 10.9844 16.7267C10.9844 17.0503 10.722 17.3126 10.3984 17.3126Z"
              />
            </svg>
            <Typography>Join Interview</Typography>
          </Button>
        ) : null}
        {editHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length !== 1 ? { display: 'none' } : {}),
              height: '34px',
            }}
            variant="text"
            onClick={() => editHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              edit
            </span>
            <Typography>Edit</Typography>
          </Button>
        )}
        {addToInterviewHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              height: '34px',
              paddingLeft: '0px',
            }}
            variant="text"
            onClick={() => addToInterviewHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              add
            </span>
            <Typography>Add to Interview Bundle</Typography>
          </Button>
        )}
        {addToFolderHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              height: '34px',
            }}
            variant="text"
            onClick={() => addToFolderHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              add
            </span>
            <Typography>Add to Folder</Typography>
          </Button>
        )}
        {informationSideBarHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length !== 1 ? { display: 'none' } : {}),
              height: '34px',
            }}
            variant="text"
            onClick={() => informationSideBarHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              info
            </span>
            <Typography>Information</Typography>
          </Button>
        )}
        {folderPermissionsHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              height: '34px',
            }}
            variant="text"
            onClick={() => folderPermissionsHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              add
            </span>
            <Typography>Folder Permissions</Typography>
          </Button>
        )}
        {addToFraudDetectionHandler &&
          process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' && (
            <Button
              sx={{
                ...tertiaryButtonStyles(),
                height: '34px',
              }}
              variant="text"
              onClick={() => addToFraudDetectionHandler()}
              disabled={checkedResources?.length <= 0}
            >
              <span
                style={{
                  fontSize: '24px',
                }}
                className="material-icons-round"
              >
                add
              </span>
              <Typography
                sx={{
                  color:
                    checkedResources?.length <= 0 ? '#cbcbcb !important' : '',
                }}
              >
                Add to Fraud Detection Database
              </Typography>
            </Button>
          )}
        {associateInterviewHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              height: '34px',
              ...(checkedResources.length !== 1 ? { display: 'none' } : {}),
            }}
            variant="text"
            onClick={() => associateInterviewHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              add
            </span>
            <Typography>Associate Interview</Typography>
          </Button>
        )}
        {removeFromResourceHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length === 1 ||
              (!limitCheckedDocs &&
                (typeof checkedResources[0] !== 'string' ||
                  !checkedResources[0].startsWith('folder-')))
                ? {}
                : { display: 'none' }),
              height: '34px',
              ...(checkedResources.length !== 1 && limitCheckedDocs
                ? { display: 'none' }
                : {}),
            }}
            variant="text"
            onClick={() => removeFromResourceHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              remove
            </span>
            <Typography>Remove from folder</Typography>
          </Button>
        )}
        {handleDownloadFile && checkedResources.length !== 0 && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length === 1 &&
              (typeof checkedResources[0] !== 'string' ||
                !checkedResources[0].startsWith('folder-'))
                ? {}
                : { display: 'none' }),

              height: '34px',
            }}
            variant="text"
            onClick={() => handleDownloadFile()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              cloud_download
            </span>
            <Typography>Download</Typography>
          </Button>
        )}

        {folderUploadLinkHandler && checkedResources.length !== 0 && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length === 1 &&
              typeof checkedResources[0] === 'string'
                ? {}
                : { display: 'none' }),

              height: '34px',
            }}
            variant="text"
            onClick={() => folderUploadLinkHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              drive_folder_upload
            </span>
            <Typography>Folder Upload Link</Typography>
          </Button>
        )}
        {renameHandler && (
          <Button
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length !== 1 ? { display: 'none' } : {}),
              height: '34px',
            }}
            variant="text"
            onClick={() => renameHandler()}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              border_color
            </span>
            <Typography>Rename</Typography>
          </Button>
        )}
        {deleteHandler && (
          <Button
            variant="text"
            onClick={() => setOpenAlertOpenDialog(true)}
            sx={{
              ...tertiaryButtonStyles(),
              ...(checkedResources.length === 0 ? { display: 'none' } : {}),
              height: '34px',
            }}
          >
            <span style={{ fontSize: '24px' }} className="material-icons-round">
              archive
            </span>
            <Typography>Archive</Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

export default ListButtons;
