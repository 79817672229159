import * as React from 'react';

import type { WidgetState } from '@livekit/components-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  isEqualTrackRef,
  isTrackReference,
  log,
} from '@livekit/components-core';

import { RoomEvent, Track } from 'livekit-client';
import {
  ConnectionStateToast,
  FocusLayout,
  FocusLayoutContainer,
  GridLayout,
  LayoutContextProvider,
  MessageFormatter,
  RoomAudioRenderer,
  useCreateLayoutContext,
  useParticipants,
  usePinnedTracks,
  useTracks,
} from '@livekit/components-react';

import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { LEIAAControlBar } from '../interview-control-bar/LEIAAControlBar';
import { LEIAACarouselLayout } from './LEIAACarouselLayout';
import { LEIAAParticipantTile } from './LEIAAParticipantTile';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import { LEIAAChat } from '../chat/LEIAAChat';
import {
  videoConferenceContainerStyles,
  videoConferenceInPersonContainerStyles,
} from './styles/styles';
import LEIAAPanel from '../panel/LEIAAPanel';
import DocSharing from '../../../pages/DocSharing';
import NotificationToast from '../../shared/toast/NotificationToast';

/**
 * @public
 */
export interface VideoConferenceProps
  extends React.HTMLAttributes<HTMLDivElement> {
  chatMessageFormatter?: MessageFormatter;
}

interface LEIAAVideoConferenceInPersonProps extends VideoConferenceProps {
  interviewDetails: any;
  currentUser: any;
}

/**
 * This component is the default setup of a classic LiveKit video conferencing app.
 * It provides functionality like switching between participant grid view and focus view.
 *
 * @remarks
 * The component is implemented with other LiveKit components like `FocusContextProvider`,
 * `GridLayout`, `ControlBar`, `FocusLayoutContainer` and `FocusLayout`.
 *
 * @example
 * ```tsx
 * <LiveKitRoom>
 *   <VideoConference />
 * <LiveKitRoom>
 * ```
 * @public
 */
export function LEIAAVideoConferenceInPerson({
  chatMessageFormatter,
  interviewDetails,
  currentUser,
  ...props
}: LEIAAVideoConferenceInPersonProps) {
  const [widgetState, setWidgetState] = React.useState<WidgetState>({
    showChat: false,
  });
  const numParticipants = useParticipants();
  const [openPanel, setOpenPanel] = useState(false);
  const [openDocSharing, setOpenDocSharing] = useState(undefined);
  const webSocketRef = useRef<WebSocket | null>(null); // Set the initial value to null or WebSocket
  const [webSocketConnecting, setWebSocketConnecting] = useState(true);
  const [sentMessage, setSentMessage] = useState<any>(undefined);
  const [receivedMessage, setReceivedMessage] = useState<any>();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged] }
  );

  const widgetUpdate = (state: WidgetState) => {
    log.debug('updating widget state', state);
    setWidgetState(state);
  };

  const layoutContext = useCreateLayoutContext();

  const screenShareTracks = tracks
    .filter(isTrackReference)
    .filter((track) => track.publication.source === Track.Source.ScreenShare);

  const focusTrack = usePinnedTracks(layoutContext)?.[0];
  const carouselTracks = !openDocSharing
    ? tracks.filter((track) => !isEqualTrackRef(track, focusTrack))
    : tracks;
  // const carouselTracks = tracks;

  React.useEffect(() => {
    // if screen share tracks are published, and no pin is set explicitly, auto set the screen share
    if (screenShareTracks.length > 0 && focusTrack === undefined) {
      layoutContext.pin.dispatch?.({
        msg: 'set_pin',
        trackReference: screenShareTracks[0],
      });
    } else if (
      (screenShareTracks.length === 0 &&
        focusTrack?.source === Track.Source.ScreenShare) ||
      tracks.length <= 1
    ) {
      layoutContext.pin.dispatch?.({ msg: 'clear_pin' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(screenShareTracks.map((ref) => ref.publication.trackSid)),
    tracks.length,
    focusTrack?.publication?.trackSid,
  ]);

  const sendSocketMessage = (message: any) => {
    message.room = interviewDetails.room;

    const messageString = JSON.stringify(message);
    if (webSocketRef.current) {
      webSocketRef.current.send(messageString);
      delete message.room;
    } else {
      console.error('No WS Connection');
    }
  };

  useEffect(() => {
    // Create the WebSocket connection
    webSocketRef.current = new WebSocket(
      process.env.REACT_APP_INTERVIEW_SERVICE_WS_URL || 'default-url'
    );

    // WebSocket onmessage event
    webSocketRef.current.onmessage = (event: any) => {
      const WSReceivedMessage = JSON.parse(event.data);
      const treatedMessage = JSON.parse(JSON.stringify(WSReceivedMessage));
      delete treatedMessage.room;

      delete WSReceivedMessage.room;
      if (WSReceivedMessage.open) {
        if (WSReceivedMessage.open === 'CLOSE_SIGNAL') {
          setOpenDocSharing(undefined);
        } else {
          // if (WSReceivedMessage.open !== openDocSharing)
          setOpenDocSharing(WSReceivedMessage.open);
        }
      }
      setReceivedMessage(WSReceivedMessage);
    };

    webSocketRef.current.onopen = () => {
      setWebSocketConnecting(false);
    };

    // Clean-up function to disconnect from the WebSocket
    return () => {
      // Close the WebSocket connection
      if (webSocketRef.current) {
        webSocketRef.current.close();
        setWebSocketConnecting(true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to only run the effect once when the component mounts

  return (
    <Box
      className="lk-video-conference scrollable-content"
      {...props}
      sx={videoConferenceInPersonContainerStyles}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError="Note saved"
          handleCloseNotification={handleCloseNotification}
          type="info"
        />
      )}
      <LayoutContextProvider
        value={layoutContext}
        // onPinChange={handleFocusStateChange}
        onWidgetChange={widgetUpdate}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: '1 0 0',
            gap: '32px',
          }}
          className="lk-video-conference-inner"
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              gap: openPanel ? '32px' : 'unset',
            }}
          >
            {/* INTERVIEW TITLE, BUTTONS AND DOC SHARING   */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '64px',
                alignSelf: 'stretch',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    ...sofiaProBoldFontStyles,
                    fontSize: '18px',
                    lineHeight: '20.7px',
                    letterSpacing: '0.5px',
                    color: '#FFFFFF',
                  }}
                >
                  {interviewDetails.type} {interviewDetails.category}{' '}
                  {interviewDetails.interviewee_name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '4px',
                    '& > p': {
                      color: '#FFF',
                      textAlign: 'center',
                      ...karlaProRegularFontStyles,
                      fontSize: '13px',
                      lineHeight: 'normal',
                      alignSelf: 'center',
                    },
                  }}
                >
                  <span className="material-icons-round">group</span>
                  <Typography>{numParticipants.length}</Typography>
                </Box>
              </Box>
              <Box sx={{ maxHeight: '550px', width: '100%', height: '100%' }}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  !openDocSharing ? (
                    !focusTrack ? (
                      <Box className="lk-grid-layout-wrapper">
                        <GridLayout tracks={tracks}>
                          <LEIAAParticipantTile />
                        </GridLayout>
                      </Box>
                    ) : (
                      <Box
                        className="lk-focus-layout-wrapper"
                        sx={{
                          height: '100%',
                          '& > div': {
                            gap: '32px',
                            padding: '0px',
                            '> aside': {
                              minWidth: '224px',
                              flexDirection: 'column',
                              gap: '32px',
                            },
                          },
                        }}
                      >
                        <FocusLayoutContainer>
                          <LEIAACarouselLayout tracks={carouselTracks}>
                            <LEIAAParticipantTile />
                          </LEIAACarouselLayout>
                          {focusTrack && <FocusLayout track={focusTrack} />}
                        </FocusLayoutContainer>
                      </Box>
                    )
                  ) : (
                    <Box
                      className="lk-focus-layout-wrapper"
                      sx={{
                        height: '100%',
                        '& > div': {
                          gap: '32px',
                          padding: '0px',
                          '> aside': {
                            minWidth: '224px',
                            flexDirection: 'column',
                            gap: '32px',
                          },
                        },
                      }}
                    >
                      <FocusLayoutContainer>
                        <LEIAACarouselLayout tracks={carouselTracks}>
                          <LEIAAParticipantTile />
                        </LEIAACarouselLayout>
                        {openDocSharing && (
                          <DocSharing
                            docId={openDocSharing}
                            currentUser={currentUser}
                            sendSocketMessage={sendSocketMessage}
                            setOpenDocSharing={setOpenDocSharing}
                            receivedMessage={receivedMessage}
                          />
                        )}
                      </FocusLayoutContainer>
                    </Box>
                  )
                }
              </Box>
            </Box>

            {/* PANEL   */}
            <LEIAAPanel
              openPanel={openPanel}
              interviewDetails={interviewDetails}
              openDocSharing={openDocSharing}
              setOpenDocSharing={setOpenDocSharing}
              widgetState={widgetState}
              messageFormatter={chatMessageFormatter}
              sendSocketMessage={sendSocketMessage}
              currentUser={currentUser}
              setShowNotification={setShowNotification}
            />
          </Box>
          <LEIAAControlBar
            controls={{ chat: true }}
            chatBtnPressed={widgetState.showChat}
            openPanel={openPanel}
            setOpenPanel={setOpenPanel}
            currentUser={currentUser}
            intervieweeName={interviewDetails.interviewee_name}
            interviewDetails={interviewDetails}
            documentUpload
          />
        </Box>
      </LayoutContextProvider>
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </Box>
  );
}
