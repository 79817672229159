import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterInformationWrapperStyles,
} from '../matter-information-step/styles';
import { LEIAAAutoComplete, LEIAASelect } from '../../../../inputs';
import { MatterUser, UsersOptionType } from '../../../../../types/matters';
import { matterAvailableUsersRequest } from '../../../../../api/matters';
import {
  addMatterUser,
  removeMatterUser,
  updateMatterUser,
} from '../../../../../redux/features/matter-creation/matterUsersSlice';
import {
  matterUsersAccordionDetailsStyles,
  matterUsersAccordionWrapperStyles,
} from './styles';
import {
  SidePanelInformation,
  MatterAddedGeneralInformation,
} from '../../sidepanel-information';
import NotificationToast from '../../../../shared/toast/NotificationToast';

interface MatterUsersProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  showTitle?: boolean;
}

const usersFilter = createFilterOptions<UsersOptionType>();

const permissionsCombinations = {
  'true,true,true,true': 'CRUD (Create, Read, Update, Delete)',
  'true,true,true,false': 'CRU (Create, Read, Update)',
  'true,true,false,true': 'CRD (Create, Read, Delete)',
  'true,false,true,true': 'CUD (Create, Update, Delete)',
  'false,true,true,true': 'RUD (Read, Update, Delete)',
  'true,false,false,true': 'CD (Create, Delete)',
  'true,false,true,false': 'CU (Create, Update)',
  'true,true,false,false': 'CR (Create, Read)',
  'false,true,false,true': 'RD (Read, Delete)',
  'false,true,true,false': 'RU (Read, Update)',
  'false,false,true,true': 'UD (Update, Delete)',
  'true,false,false,false': 'C (Create)',
  'false,true,false,false': 'R (Read)',
  'false,false,true,false': 'U (Update)',
  'false,false,false,true': 'D (Delete)',
  'false,false,false,false': 'No permission',
};

const MatterUsers = ({
  control,
  watch,
  setValue,
  showTitle = false,
}: MatterUsersProps) => {
  const [usersOptions, setUsersOptions] =
    useState<readonly UsersOptionType[]>();
  const [users, setUsers] = useState<MatterUser[]>();
  const matterUsers = watch('users');
  const [expandedAccordionUserId, setExpandedAccordionUserId] = useState<
    number | null
  >(null);
  const dispatch = useDispatch();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);

  const matterInfoFields = [
    {
      label: process.env.REACT_APP_CLIENT_WORDING_TO_ORGANISATION
        ? 'Organisation Name'
        : 'Client Name',
      value: watch('client'),
    },
    { label: 'Matter Name', value: watch('name') },
    { label: 'Matter Description', value: watch('description') },
    { label: 'Jurisdiction', value: watch('jurisdictionName') },
    { label: 'Template', value: watch('template') },
  ];

  const handleFetchUsers = async () => {
    try {
      const response = await matterAvailableUsersRequest();
      setUsers(response);
      const usersOptionType: UsersOptionType[] = response.map((user) => ({
        id: uuidv4(),
        name: user.name,
      }));
      setUsersOptions(usersOptionType);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
      console.error(error);
    }
  };

  const handleAddMatterUser = (selectedUserName: any) => {
    const userToAdd = users?.find((user) => user.name === selectedUserName);

    if (userToAdd) {
      // Check if the user already exists in the 'users' list
      const userAlreadyExists = watch('users')?.some(
        (existingUser: any) => existingUser.user_id === userToAdd.id
      );

      if (userAlreadyExists) {
        // User already exists, do not add again
        return;
      }

      const updatedUserToAdd = {
        user_id: userToAdd.id,
        permissions: userToAdd.permissions,
        role: userToAdd.role,
      };

      dispatch(addMatterUser(userToAdd));

      if (watch('users') && watch('users').length > 0) {
        const updatedUsers = [...watch('users'), updatedUserToAdd];
        setValue('users', updatedUsers);
      } else {
        setValue('users', [updatedUserToAdd]);
      }

      // Set the expanded accordion user ID to the ID of the added user
      setExpandedAccordionUserId(userToAdd.id);
    }
  };

  const handleRemoveMatterUser = (id: number) => {
    dispatch(removeMatterUser(id));
    const newUsersValue = matterUsers.filter((mu: any) => mu.user_id !== id);
    setValue('users', newUsersValue);
    if (typeof id === 'number') {
      const usersIdsToDelete = watch('users_to_remove') || [];
      setValue('users_to_remove', [...usersIdsToDelete, id]);
    }
  };

  const permissionsObjectToString = (permissions: any) => {
    const order = ['add', 'view', 'change', 'delete'];
    return order.map((key) => (permissions[key] ? 'true' : 'false')).join(',');
  };

  useEffect(() => {
    handleFetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userIdToName = (id: number) => {
    const user = users?.filter((u) => u.id === id)[0];
    if (user) {
      return user.name;
    }
    return '';
  };

  return (
    <Box sx={matterInformationWrapperStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <Box sx={matterInformationFormWrapperStyles}>
        {showTitle && (
          <Box>
            <Typography>Add Users</Typography>
          </Box>
        )}
        {!showTitle && (
          <Typography>Add investigation team to the matter.</Typography>
        )}
        <Box sx={matterInformationInputWrapperStyles}>
          <Box>
            <LEIAAAutoComplete
              controllerName=""
              control={control}
              autoCompleteFilter={usersFilter}
              autoCompleteOptions={usersOptions}
              inputPlaceholder="Search by users to add..."
              inputWidth="100%"
              inputHeight="40px"
              hasCreateOption={false}
              noOptionText="No user found"
              handleChange={handleAddMatterUser}
              required={false}
              startAdornment={
                <InputAdornment position="start" sx={{ marginLeft: '12px' }}>
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              }
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              {matterUsers &&
                matterUsers.length > 0 &&
                matterUsers.map((mu: any, userIndex: number) => {
                  return (
                    <Accordion
                      key={mu.user_id}
                      sx={matterUsersAccordionWrapperStyles(
                        mu.user_id === expandedAccordionUserId
                      )}
                      expanded={mu.user_id === expandedAccordionUserId}
                      onChange={() => {
                        if (mu.user_id === expandedAccordionUserId) {
                          // If the clicked accordion is already expanded, collapse it
                          setExpandedAccordionUserId(null);
                        } else {
                          // Otherwise, expand the clicked accordion
                          setExpandedAccordionUserId(mu.user_id);
                        }
                      }}
                    >
                      <Box>
                        <AccordionSummary
                          expandIcon={
                            <span className="material-icons-round">
                              arrow_drop_down
                            </span>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Grid container>
                            <Grid item xs={5}>
                              <Typography>
                                {mu.name || userIdToName(mu.user_id)}
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography>{mu.role}</Typography>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <IconButton
                          data-testid="clear-file-uploaded-id"
                          aria-label="clear file uploaded"
                          sx={{
                            padding: '0px',
                            '&:hover': { background: 'transparent' },
                            '& > span': {
                              fontSize: '16px',
                            },
                          }}
                          onClick={() => handleRemoveMatterUser(mu.user_id)}
                        >
                          <span className="material-icons-round">close</span>
                        </IconButton>
                      </Box>
                      <AccordionDetails sx={matterUsersAccordionDetailsStyles}>
                        <Box>
                          <Box>
                            <Typography>Permissions</Typography>
                          </Box>
                          {/* MAP PERMISSIONS PER USER  */}
                          {Object.entries(mu.permissions).map(
                            ([permissionGroup], index) => {
                              const controllerName = `users[${userIndex}].permissions.${permissionGroup}`;

                              const handleChangeUserPermissions = (
                                event: any
                              ) => {
                                const selectedValue = event.target.value;

                                const [add, view, change, del] =
                                  selectedValue.split(',');

                                const groupPermissions = {
                                  add: add === 'true',
                                  view: view === 'true',
                                  change: change === 'true',
                                  delete: del === 'true',
                                };

                                const updatedPermissions = {
                                  ...mu.permissions,
                                  [permissionGroup]: groupPermissions,
                                };

                                // Update the user's permissions in the store or state
                                const updatedUser = {
                                  ...mu,
                                  permissions: updatedPermissions,
                                };
                                dispatch(updateMatterUser(updatedUser));

                                // Call setValue to update the form value if needed
                                setValue(controllerName, groupPermissions);
                              };

                              return (
                                <Box key={`${mu.user_id}-${index}}`}>
                                  <Box>
                                    <Typography>
                                      {permissionGroup.charAt(0).toUpperCase() +
                                        permissionGroup.slice(1)}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <LEIAASelect
                                      controllerName={controllerName}
                                      control={control}
                                      required={false}
                                      inputHeight="40px !important"
                                      inputWidth="100%"
                                      paperCustomStylings={{
                                        height: '300px',
                                      }}
                                      handleChange={handleChangeUserPermissions}
                                      mapFunction={permissionsObjectToString}
                                    >
                                      <MenuItem
                                        disabled
                                        value="false,false,false,false"
                                      >
                                        Select a permission
                                      </MenuItem>
                                      {Object.entries(
                                        permissionsCombinations
                                      ).map(([value, label]) => {
                                        return (
                                          <MenuItem key={value} value={value}>
                                            {label}
                                          </MenuItem>
                                        );
                                      })}
                                    </LEIAASelect>
                                  </Box>
                                </Box>
                              );
                            }
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* SIDEPANEL WITH MATTER INFORMATION */}
      <SidePanelInformation>
        {matterInfoFields &&
          matterInfoFields.filter(
            (mi) => mi.value !== '' && mi.value !== undefined
          ).length > 0 && (
            <MatterAddedGeneralInformation
              matterInfoFields={matterInfoFields}
            />
          )}
      </SidePanelInformation>
    </Box>
  );
};

export default MatterUsers;
