import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';

// eslint-disable-next-line import/prefer-default-export
export const detailsDataStyles = {
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    '& > label.MuiFormLabel-root': {
      display: 'flex',
      color: '#464B53',
      fontSize: '14px',
      ...karlaProRegularFontStyles,
      lineHeight: '22px',
    },
    '& > p, & > .material-icons-round': {
      color: '#69707B',
      ...karlaProRegularFontStyles,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
  },
};

export const detailsRecordingsDataStyles = {
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  borderRadius: '24px',
  border: '1px solid #D3ECF6',
  '& > p': {
    color: '#140070',
    ...karlaProRegularFontStyles,
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '30px',
  },
  '& > div': {
    width: '100%',
  },
};

export const detailsInterviewOutlineDataStyles = {
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  borderRadius: '24px',
  border: '1px solid #D3ECF6',
  height: 'auto',
  maxHeight: '300px',
  '& > p': {
    color: '#140070',
    ...karlaProRegularFontStyles,
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '30px',
  },
  '& > div': {
    width: '100%',
  },
};

export const interviewOutlinesStyles = {
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  alignSelf: 'stretch',
  alignItems: 'flex-start',
  overflowY: 'scroll',
  height: 'auto',
  maxHeight: '215px',
};

export const detailsInterviewersDataStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
};

export const interviewerDataStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  width: '100%',
  '& > div:first-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    '& > p:first-of-type': {
      ...sofiaProRegularFontStyles,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      color: '#464B53',
    },
    '& > p:last-of-type': {
      ...karlaProRegularFontStyles,
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#464B53',
    },
  },
  '& > div:last-of-type': {
    '& > p': {
      ...karlaProRegularFontStyles,
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#464B53',
    },
  },
};

export const detailsInterviewNotesDataStyles = {
  '& > p': {
    color: '#464B53',
    ...karlaProRegularFontStyles,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
  },
};

export const recordingsDataStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    '& > p': {
      ...sofiaProRegularFontStyles,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      color: '#464B53',
    },
  },
};

export const recordingsDateStyles = {
  ...karlaProRegularFontStyles,
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '22px',
  color: '#464B53',
};

export const questionsListStyles = {
  display: 'flex',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  '& > div:first-of-type': {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    border: '1px solid #140070',
    '& > p': {
      ...sofiaProRegularFontStyles,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      color: '#140070',
      textAlign: 'center',
      marginTop: '-4px',
    },
  },
  '& > div:last-of-type': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    flex: '1 0 0',
    padding: '0px 12px',
    flexDirection: 'column',
    '& > p': {
      ...karlaProRegularFontStyles,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#464B53',
    },
    '& > div': {
      '& > p': {
        ...karlaProRegularItalicFontStyles,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#69707B',
      },
    },
  },
};
