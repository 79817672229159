import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import { interviewsListRequest } from '../../../api/homeportal';
import { bundleActionsRequest } from '../../../api/matters';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import { InterviewsListResponse } from '../../../types/homeportal';
import ErrorMessage from '../../error-message/ErrorMessage';
import DropdownMenuIcon from '../../matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import {
  matterInformationSelectPaperStyles,
  matterInformationSelectStyles,
} from '../../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import NotificationToast from '../toast/NotificationToast';
import {
  leiaaAddToWrapperStyles,
  leiaaRenameDialogContentStyles,
} from './styles';

export interface DocumentAssociateToInterviewDialogProps {
  open: boolean;
  onClose: () => void;
  setShowNotification: any;
  setNotificationInterviewName: any;
  selectedBundle: any;
  refreshTable: () => void;
  matterId: any;
}

const DocumentAssociateToInterviewDialog = ({
  open,
  onClose,
  setShowNotification,
  setNotificationInterviewName,
  selectedBundle,
  refreshTable,
  matterId,
}: DocumentAssociateToInterviewDialogProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [interviews, setInterviews] = useState<InterviewsListResponse | null>(
    null
  );

  const handleFetchInterviewsList = async (searchText = '') => {
    try {
      const response = await interviewsListRequest(searchText, matterId);
      setInterviews(response as InterviewsListResponse);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchInterviewsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanData = () => {
    setShowWarning(false);
    setValue('');
    setMenuOpen(false);
  };

  const handleClose = () => {
    cleanData();
    onClose();
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    setValue(event.target.value);
    const interviewName = interviews?.interviewsList.find(
      (i: any) => event.target.value === i.id
    )?.name;

    setNotificationInterviewName(interviewName);
  };

  const handleSubmit = async () => {
    if (value) {
      try {
        await bundleActionsRequest(
          selectedBundle.id,
          undefined,
          undefined,
          undefined,
          value,
          undefined
        );
        handleClose();
        refreshTable();
        setShowNotification(true);
      } catch (error: any) {
        setRequestError(error.response.data.message);
        setShowErrorNotification(true);
        console.error(error);
      }
    } else {
      setShowWarning(true);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={leiaaAddToWrapperStyles}>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <Box sx={leiaaRenameDialogContentStyles()}>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: '#202020',
                ...sofiaProBoldFontStyles,
                fontSize: '18px',
                lineHeight: 'normal',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              Add to interview
            </Typography>
          </Box>
          <Box>
            <Box>
              <Select
                displayEmpty
                labelId="leiaa-simple-select-label"
                id="leiaa-simple-select"
                size="small"
                open={menuOpen}
                onClose={handleMenuClose}
                onOpen={handleMenuOpen}
                // eslint-disable-next-line react/no-unstable-nested-components
                IconComponent={() => (
                  <DropdownMenuIcon
                    isMenuOpen={menuOpen}
                    handleDropdownClientIconClick={handleMenuOpen}
                    isAdornment={false}
                  />
                )}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      ...matterInformationSelectPaperStyles,
                    },
                  },
                }}
                sx={matterInformationSelectStyles('40px !important', '100%')}
                value={value}
                onChange={handleChange}
              >
                <MenuItem disabled value="">
                  <Typography
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#69707B',
                    }}
                  >
                    Search by interview...
                  </Typography>
                </MenuItem>
                {interviews?.interviewsList.map((interview: any) => {
                  return (
                    interview.type !== 'Automated' && (
                      <MenuItem key={interview.id} value={interview.id}>
                        {interview.category} - {interview.intervieweeName},{' '}
                        {interview.date.split(' ')[0]}
                      </MenuItem>
                    )
                  );
                })}
              </Select>
            </Box>

            {showWarning && (
              <ErrorMessage type="error" message="Incorrect Information" />
            )}
            <Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={onClose}
                >
                  <Typography>Cancel</Typography>
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={handleSubmit}
                >
                  <Typography>Add</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DocumentAssociateToInterviewDialog;
