import { Box } from '@mui/material';
import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import {
  mainFormWrapperStyles,
  mainLoginContainerStyles,
} from './styles/login';
import LEIAALogo1 from '../assets/LEIAALogo1.png';
import ForgotPasswordForm from '../components/login/ForgotPasswordForm';
import { createErrorObject } from '../utils/utils';
import { getTempTokenRequest } from '../api/auth';
import { LoginError } from '../types/authProps';

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [errorsObject, setErrorsObject] = useState<LoginError>();
  const {
    handleSubmit: handleSubmitForgotPassword,
    control: controlForgotPassword,
    watch: watchForgotPassword,
  } = useForm();

  const onSubmitForgotPassword: SubmitHandler<FieldValues> = async (data) => {
    try {
      const response = await getTempTokenRequest(data.email_address);
      localStorage.setItem('tempToken', response?.tempToken);
      // setTempToken(response?.tempToken);
      setEmailSent(true);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        const errors = createErrorObject(
          error.response.data.message,
          'email_address'
        );
        setErrorsObject(errors);
      }
    }
  };

  return (
    <Box sx={mainLoginContainerStyles}>
      <Box sx={mainFormWrapperStyles}>
        <Box>
          <Box>
            <img
              src={LEIAALogo1}
              style={{ width: '228.12px', height: '99.41px' }}
              alt="LEIAALogo1"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '48px',
              width: '352px',
              '> form': {
                width: '100%',
              },
            }}
          >
            <ForgotPasswordForm
              handleSubmit={handleSubmitForgotPassword}
              onSubmit={onSubmitForgotPassword}
              control={controlForgotPassword}
              watch={watchForgotPassword}
              emailSent={emailSent}
              errors={errorsObject}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
