import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import ResetPasswordForm from '../components/login/ResetPasswordForm';
import { ResetPasswordData, TwoFactorAuthData } from '../types/auth';
import {
  resetPasswordRequest,
  twoFactorAuthenticationRequest,
} from '../api/auth';
import {
  mainFormWrapperStyles,
  mainLoginContainerStyles,
} from './styles/login';
import LEIAALogo1 from '../assets/LEIAALogo1.png';
import { LoginError } from '../types/authProps';
import { createErrorObject } from '../utils/utils';

const ResetPassword = () => {
  const { twoFactorToken, reporting } = useParams<{
    twoFactorToken?: string;
    reporting?: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [errorsObject, setErrorsObject] = useState<LoginError>();
  const [passwordReset, setPasswordReset] = useState(false);
  const [authFailed, setAuthFailed] = useState(false);

  const {
    handleSubmit: handleSubmitResetPassword,
    control: controlResetPassword,
    watch: watchResetPassword,
  } = useForm();

  const onSubmitResetPassword: SubmitHandler<
    FieldValues | ResetPasswordData
  > = async (data) => {
    try {
      await resetPasswordRequest(data as ResetPasswordData);
      setPasswordReset(true);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        const errors = createErrorObject(
          error.response.data.message,
          'new_password'
        );
        setErrorsObject(errors);
      }
    }
  };

  const handleConfirmTwoFactorAuthentication = async (token: string) => {
    setLoading(true);
    try {
      const data = {
        two_factor_auth_code: token,
        temp_token: localStorage.getItem('tempToken'),
      };
      await twoFactorAuthenticationRequest(data as TwoFactorAuthData);
    } catch (error: unknown) {
      setAuthFailed(true);
      if (axios.isAxiosError(error) && error.response) {
        const errors = createErrorObject(error.response.data.message);
        setErrorsObject(errors);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (twoFactorToken) {
      handleConfirmTwoFactorAuthentication(twoFactorToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={mainLoginContainerStyles}>
      <Box sx={mainFormWrapperStyles}>
        <Box>
          <Box>
            <img
              src={LEIAALogo1}
              style={{ width: '228.12px', height: '99.41px' }}
              alt="LEIAALogo1"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '48px',
              width: '352px',
              '> form': {
                width: '100%',
              },
            }}
          >
            <ResetPasswordForm
              handleSubmit={handleSubmitResetPassword}
              onSubmit={onSubmitResetPassword}
              control={controlResetPassword}
              passwordReset={passwordReset}
              watch={watchResetPassword}
              loading={loading}
              errors={errorsObject}
              authFailed={authFailed}
              reportingFollowUp={reporting === 'reporting'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
