/* eslint-disable @typescript-eslint/no-shadow */
import { Box, Stack, Typography } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import {
  boardColumnHeaderStyles,
  boardColumnHeaderWrapperBoxStyles,
} from '../../../pages/my-dashboard/tasks/taskstyles';
import ReportKanbanCard from '../../../pages/whistleblowing/leiaa/ReportKanbanCard';
import { moveReportStageRequest } from '../../../api/whistleblower';

export interface ColumnGeneral {
  title: string;
  items: any[];
}

interface KanbanBoardProps {
  columns: ColumnGeneral;
  setShowErrorNotification: any;
  setRequestError: any;
  setColumns: any;
  resourceName: string;
}

const KanbanBoard = ({
  columns,
  setShowErrorNotification,
  setRequestError,
  setColumns,
  resourceName,
}: KanbanBoardProps) => {
  const handleMoveSave = async (id: any, stage: any) => {
    try {
      const response = await moveReportStageRequest(id, stage);

      const element = document.querySelector(
        `[data-rbd-draggable-id="${id}"] > div > div:last-child > p:last-child > span:last-child`
      );
      if (element) element.innerHTML = response.deadline;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setShowErrorNotification(true);
        setRequestError(error.response.data.message);
      } else {
        setShowErrorNotification(true);
        setRequestError(`${resourceName} move error`);
      }
    }
  };

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      handleMoveSave(
        result.draggableId,
        columns[result.destination.droppableId].title
      );
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            overflowX: 'scroll',
            paddingBottom: '5px',
            display: 'flex',
            width: '100%',
            minHeight: '80vh',
            // To Do - Progress - Blocked - Review -Done
            '& >  div:nth-of-type(1) > div:nth-of-type(1)': {
              background: '#4682B4 !important',
              color: 'white',
            },
            '& >  div:nth-of-type(2) > div:nth-of-type(1)': {
              background: '#32CD32 !important',
              color: 'white',
            },
            '& >  div:nth-of-type(3) > div:nth-of-type(1)': {
              background: '#1E90FF !important',
              color: 'white',
            },
            '& >  div:nth-of-type(4) > div:nth-of-type(1)': {
              background: '#FFD700 !important',
              color: 'white',
            },
            '& >  div:nth-of-type(5) > div:nth-of-type(1)': {
              background: '#FF6347 !important',
              color: 'white',
            },
            '& >  div:nth-of-type(6) > div:nth-of-type(1)': {
              background: '#8A2BE2 !important',
              color: 'white',
            },
            '& >  div:nth-of-type(7) > div:nth-of-type(1)': {
              background: '#4169E1 !important',
              color: 'white',
            },
            '& >  div:nth-of-type(8) > div:nth-of-type(1)': {
              background: '#008000 !important',
              color: 'white',
            },
            '& >  div:nth-of-type(8)': {
              marginRight: '0px !important',
            },
          }}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <Box
                    sx={boardColumnHeaderWrapperBoxStyles}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Box sx={boardColumnHeaderStyles}>{column.title}</Box>
                    {column.items.length > 0 ? (
                      column.items.map((item: any, index: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <ReportKanbanCard item={item} index={index} />
                      ))
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          ...karlaProRegularItalicFontStyles,
                          color: '#69707B',
                          fontSize: '14px',
                          textAlign: 'center',
                          marginTop: '40px',
                        }}
                      >
                        No {resourceName}.
                      </Typography>
                    )}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            );
          })}
        </Box>
      </Box>
    </DragDropContext>
  );
};

export default KanbanBoard;
