import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import {
  matterInformationNestedInputWrapperStyles,
  matterQuestionnaireAccordionDetailsStyles,
  matterQuestionnaireAccordionStyles,
  matterQuestionnaireAddButtonWrapperStyles,
  matterQuestionnaireManualQuestionsWrapperStyles,
  matterQuestionnaireRadioGroupWrapperStyles,
  matterQuestionnaireStyles,
  matterQuestionnaireUploadAreaWrapperStyles,
  matterUploadAreaAndButtonsWrapperStyles,
} from './styles';
import { secondaryButtonStyles } from '../../../../styles';
import {
  LEIAAInput,
  LEIAADialog,
  LEIAARadioButtons,
  LEIAAUploadFileArea,
} from '../../../../inputs';
import {
  addAllegationQuestionnaireFile,
  addUploadedFile,
  clearAllegationQuestionnaireFiles,
  clearUploadedFiles,
  removeAllegationQuestionnaireFiles,
  removeUploadedFile,
  replaceAllegationQuestionnaireFiles,
  replaceFilesName,
  selectUploadedFiles,
} from '../../../../../redux/features/matter-creation/matterInformationSlice';
import useQuestionnaireManagement from '../../../../../hooks/useQuestionnaireManagement';

interface MatterQuestionnaireProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  setRequestError: React.Dispatch<React.SetStateAction<string | null>>;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const MatterQuestionnaire = ({
  control,
  watch,
  setValue,
  unregister,
  setRequestError,
  setShowNotification,
}: MatterQuestionnaireProps) => {
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const uploadedFilesNames: string[] = useSelector(selectUploadedFiles);

  const questionsAnswers = watch('questionnaire');
  const hasQuestionnaire = watch('hasQuestionnaire');

  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(
    !!(questionsAnswers && questionsAnswers.length > 0)
  ); // Track the expanded state
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [openConfirmationDialog, setConfirmationDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClickConfirmationDialogOpen = () => {
    setOpenAlertOpenDialog(false);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleReopenDialog = () => {
    setConfirmationDialogOpen(false);
    setOpenAlertOpenDialog(true);
  };

  const handleChangeHasQuestionnaire = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if ((event.target as HTMLInputElement).value === 'no') {
      setUploadedFile(undefined);
      dispatch(clearUploadedFiles());
      dispatch(clearAllegationQuestionnaireFiles());
      unregister('questionnaire'); // Reset the questionnaire field
    } else {
      setValue('hasQuestionnaire', 'yes');
      setValue('questionnaire', []); // Reset the questionnaire field
    }
  };

  const {
    handleReplaceCurrentQuestions,
    handleAddNewQuestions,
    handleAddFile,
    handleDrag,
    handleRemoveFile,
    handleAddManualQuestionAnswer,
    handleRemoveManualQuestionAnswer,
  } = useQuestionnaireManagement(
    // FORM CONTROLS PROPS
    watch,
    setValue,
    // MATTER QUESTIONNAIRE PROPS, PATH
    questionsAnswers,
    'questionnaire',
    'questionnaire',
    // ADD ANSWERS TO QUESTIONNAIRE OR NOT
    true,
    // FILE PROPS AND QUESTIONNAIRE FIELD PATH
    uploadedFile,
    setUploadedFile,
    'questionnaire',
    undefined,
    // DISPATCH FUNCTIONS TO CONTROL FILES AND FILENAMES
    addAllegationQuestionnaireFile,
    addUploadedFile,
    replaceFilesName,
    replaceAllegationQuestionnaireFiles,
    removeUploadedFile,
    removeAllegationQuestionnaireFiles,
    'questions_to_delete',
    undefined,
    // ACCORDIONS STATES PROPS
    setIsAccordionExpanded,
    handleConfirmationDialogClose,
    setOpenAlertOpenDialog,
    setRequestError,
    setShowNotification,
    // TRANSLATOR FOR MATTER OR INTERVIEW QA
    false
  );

  useEffect(() => {
    if (
      questionsAnswers &&
      questionsAnswers.length > 0 &&
      hasQuestionnaire === undefined
    ) {
      setValue('hasQuestionnaire', 'yes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={matterQuestionnaireStyles}>
      {/* Upload area and radio buttons */}
      <Box>
        <Box sx={matterUploadAreaAndButtonsWrapperStyles}>
          <Box sx={matterInformationNestedInputWrapperStyles(true)}>
            <Typography>Allegation Questionnaire</Typography>
            <LEIAARadioButtons
              labelText="Do you have have information about the alleged misconduct?"
              controllerName="hasQuestionnaire"
              control={control}
              formControlStyles={matterQuestionnaireRadioGroupWrapperStyles}
              customHandleChange={handleChangeHasQuestionnaire}
            >
              <FormControlLabel
                value="yes"
                control={<Radio required />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio required />}
                label="No"
              />
            </LEIAARadioButtons>
          </Box>
        </Box>
        {questionsAnswers && hasQuestionnaire === 'yes' ? (
          <LEIAAUploadFileArea
            uploadedFilesNames={uploadedFilesNames}
            title="Select a collection of questions/answers from a previously run
                  interview to import"
            subTitle="The imported file needs to follow a required structure."
            downloadText="Download the structured file"
            handleDrag={handleDrag}
            handleAddFile={handleAddFile}
            handleRemoveFile={handleRemoveFile}
            downloadUrl={`${process.env.PUBLIC_URL}/questionnaire.txt`}
            uploadAreaStyles={matterQuestionnaireUploadAreaWrapperStyles}
            acceptedFileTypes={['.txt']}
          />
        ) : null}
      </Box>
      {questionsAnswers && hasQuestionnaire === 'yes' ? (
        <>
          {/* Questions */}
          <Box sx={matterQuestionnaireManualQuestionsWrapperStyles}>
            <Box>
              <Typography>
                ...and/or manually insert the answers to the questions:
              </Typography>
            </Box>
            <Box>
              <Accordion
                sx={matterQuestionnaireAccordionStyles}
                expanded={isAccordionExpanded}
                onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
              >
                <AccordionSummary
                  expandIcon={
                    <span className="material-icons-round">
                      arrow_drop_down
                    </span>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Questions and Answers</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={matterQuestionnaireAccordionDetailsStyles}
                >
                  {questionsAnswers &&
                    questionsAnswers.map((m: any, index: number) => {
                      const questionName = `questionnaire[${index}].question`;
                      const answerName = `questionnaire[${index}].answer`;

                      return (
                        <Box key={`${m.id}-${index}}`}>
                          <Box>
                            <Typography>{index + 1}</Typography>
                          </Box>
                          <Box>
                            <Box>
                              {/* Render a TextField instead of Typography when question is empty */}
                              {m.type !== 'manual' ? (
                                <Typography>{m.question}</Typography>
                              ) : (
                                <LEIAAInput
                                  controllerName={questionName}
                                  control={control}
                                  inputPlaceholder="Enter question"
                                  inputWidth="100%"
                                  inputHeight="40px"
                                  required={false}
                                />
                              )}
                            </Box>
                            <LEIAAInput
                              controllerName={answerName}
                              control={control}
                              inputPlaceholder="Enter answer"
                              inputWidth="100%"
                              inputHeight="130px"
                              multiline
                              required={false}
                            />
                            <Box>
                              <Typography>{m.source}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() =>
                                handleRemoveManualQuestionAnswer(m.id)
                              }
                              sx={{
                                padding: '0px',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                                '& > span': {
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                },
                              }}
                            >
                              <span className="material-icons-round">
                                clear
                              </span>
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box sx={matterQuestionnaireAddButtonWrapperStyles}>
            <Button
              variant="contained"
              sx={{
                ...secondaryButtonStyles(),
                height: '30px',
                padding: '8px 16px',
                gap: '8px',
              }}
              onClick={handleAddManualQuestionAnswer}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add new question</Typography>
            </Button>
          </Box>
        </>
      ) : null}
      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description="You are about to upload a new collection of questions/answers. Would you like to replace the current ones, or add new ones?"
        primaryButtonText="Add new questions"
        primaryButtonHelperText="This action will add new uploaded questions to your current ones"
        primaryButtonIcon="add"
        onPrimaryButtonClick={handleAddNewQuestions}
        secondaryButtonText="Replace current questions"
        secondaryButtonHelperText="This action will replace your current questions with the new uploaded ones"
        secondaryButtonIcon="sync"
        onSecondaryButtonClick={handleClickConfirmationDialogOpen}
      />
      <LEIAADialog
        open={openConfirmationDialog}
        onClose={handleConfirmationDialogClose}
        hasBackButton
        title="confirm your request"
        description="You are about to replace your current questions with the new uploaded ones. Are you sure you want to remove the existing questions/answers? This action cannot be undone."
        primaryButtonText="Cancel"
        primaryButtonHasIcon={false}
        secondaryButtonText="Yes, replace current questions"
        secondaryButtonHelperText="This action will replace your current questions with the new uploaded ones"
        secondaryButtonIcon="sync"
        onPrimaryButtonClick={handleConfirmationDialogClose}
        onSecondaryButtonClick={handleReplaceCurrentQuestions}
        onGoBackButtonClick={handleReopenDialog}
        swapButtonStyles
      />
    </Box>
  );
};

export default MatterQuestionnaire;
