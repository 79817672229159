import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { Box } from '@mui/material';
import MatterInformation from './matter-creation-steps/matter-information-step/MatterInformation';
import MatterUsers from './matter-creation-steps/matter-users-step/MatterUsers';
import MatterTasks from './matter-creation-steps/matter-tasks-step/MatterTasks';
import MatterDocuments from './matter-creation-steps/matter-documents-step/MatterDocuments';
import MatterInterviews from './matter-creation-steps/matter-interviews-step/MatterInterviews';
import MatterCreationHeader from './MatterCreationHeader';

interface MatterCreationContentProps {
  activeStep: number;
  previousStep: number | null;
  visited: boolean[];
  steps: string[];
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  currentStep: number;
  touchedFields: any;
  handleAddMatterInterview: any;
  selectedIndex: any;
  setSelectedIndex: any;
  locationState?: any;
  showHeader?: boolean;
}

const MatterCreationContent = ({
  activeStep,
  previousStep,
  visited,
  steps,
  control,
  watch,
  setValue,
  unregister,
  currentStep,
  touchedFields,
  handleAddMatterInterview,
  selectedIndex,
  setSelectedIndex,
  locationState = {},
  showHeader = true,
}: MatterCreationContentProps) => {
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <MatterInformation
            control={control}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
          />
        );
      case 1:
        return (
          <MatterUsers
            control={control}
            watch={watch}
            setValue={setValue}
            showTitle={!showHeader}
          />
        );
      case 2:
        return (
          <MatterTasks
            control={control}
            watch={watch}
            setValue={setValue}
            currentStep={currentStep}
            previousStep={previousStep}
            touchedFields={touchedFields}
          />
        );
      case 3:
        return (
          <MatterDocuments
            watch={watch}
            setValue={setValue}
            currentStep={currentStep}
            previousStep={previousStep}
            control={control}
          />
        );
      case 4:
        return (
          <MatterInterviews
            control={control}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
            handleAddMatterInterview={handleAddMatterInterview}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        );
      // Add cases for other steps and corresponding components
      default:
        return null;
    }
  };
  return (
    <Box>
      {showHeader && (
        <MatterCreationHeader
          activeStep={activeStep}
          steps={steps}
          visited={visited}
          currentStep={currentStep}
          locationState={locationState}
        />
      )}
      {renderStepContent()}
    </Box>
  );
};

export default MatterCreationContent;
