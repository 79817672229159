import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../../styles/textStyles';

export const allocatedUsersBoxWrapperStyles = {
  padding: '24px',
  width: '100%',
  height: '499px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #D3ECF7',
  borderRadius: '24px',
  '& > div': {
    marginTop: '16px',
    display: 'flex',
    padding: '0px',
    gap: '24px',
  },
};

export const matterAllocatedUsersBoxWrapperStyles = {
  padding: '24px',
  width: '100%',
  height: '570px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #D3ECF7',
  borderRadius: '24px',
  '& > div': {
    marginTop: '16px',
    display: 'flex',
    padding: '0px',
    gap: '24px',
  },
};

export const allocatedUsersListStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  overflowY: 'scroll',
  height: '425px',
  padding: '0px',
  marginTop: '16px',
  paddingBottom: '16px',
};

export const matterAllocatedUsersListStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  overflowY: 'scroll',
  height: '500px',
  padding: '0px',
  marginTop: '16px',
  paddingBottom: '16px',
};

export const allocatedUsersListItemStyles = {
  width: '100%',
  height: '80px',
  padding: '0px',
  '& > div': {
    padding: '0px',
    width: '100%',
    height: '80px',
    display: 'flex',
  },
};

export const tasksContentWrapperStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '14px 0px',
  gap: '10px',
  width: '80px',
  height: '80px',
  background: '#F2FAFE',
  borderRadius: '40px',
  '& > div': {
    padding: '0px',
    width: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      '& > p': {
        ...sofiaProRegularFontStyles,
        letterSpacing: '2px',
        color: '#140070',
      },
      '& > p:nth-of-type(1)': {
        fontWeight: 500,
        fontSize: '25px',
        lineHeight: '22px',
      },
      '& > p:nth-of-type(2)': {
        fontWeight: 300,
        lineHeight: '14px',
        fontSize: '14px',
      },
    },
    '& > div:nth-of-type(2)': {
      marginTop: '5px',
      width: '50px',
      '& > p': {
        ...karlaProRegularFontStyles,
        fontWeight: 300,
        fontSize: '10px',
        lineHeight: '12px',
        textAlign: 'center',
        letterSpacing: '0.2px',
        color: '#140070',
      },
    },
  },
};

export const userContentWrapperStyles = {
  display: 'flex',
  padding: '0px 0px 0px 16px',
  width: '100%',
  height: '60px',
  '& > div': {
    padding: '16px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& > p:nth-of-type(1)': {
      ...sofiaProRegularFontStyles,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '18px',
      letterSpacing: '0.3px',
      color: '#464B53',
    },
    '& > p:nth-of-type(2)': {
      ...karlaProRegularFontStyles,
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '22px',
      color: '#6F6C7F',
    },
  },
};
