import { Box, Typography } from '@mui/material';
import { karlaProRegularFontStyles } from '../../../../styles/textStyles';

interface InterviewTypeTagProps {
  type: any;
}

const InterviewTypeTag = ({ type }: InterviewTypeTagProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        alignSelf: 'flex-start',
        marginRight: '10px',
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 5px',
          gap: '4px',
          borderRadius: '5px',
          border: `1px solid ${type === 'Automated' ? ' #38B763' : '#A962D5 '}`,
          '& > p': {
            ...karlaProRegularFontStyles,
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '22px',
            letterSpacing: '0.3px',
            color: type === 'Automated' ? ' #38B763' : '#A962D5 ',
          },
        },
      }}
    >
      <Box>
        <Typography>{type}</Typography>
      </Box>
    </Box>
  );
};

export default InterviewTypeTag;
