import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { interviewTranscriptRequest } from '../../../api/interviews';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import NotificationToast from '../../shared/toast/NotificationToast';
import { secondaryButtonStyles } from '../../styles';
import {
  detailsRecordingsDataStyles,
  recordingsDataStyles,
  recordingsDateStyles,
} from './styles';

interface LEIAAInterviewDetailsProps {
  interview?: any;
}
const LEIAAInterviewDetails = ({
  interview = [],
}: LEIAAInterviewDetailsProps) => {
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [transcriptOpen, setTranscriptOpen] = useState<number[]>([]);
  const [showTranscriptionNotification, setShowTranscriptionNotification] =
    useState(false);
  const [
    showTranscriptionReadyNotification,
    setShowTranscriptionReadyNotification,
  ] = useState(false);

  const formatCustomDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${hours}:${minutes}, ${day}/${month}/${year}`;
  };

  const handleGetTranscript = async (recordId: number) => {
    try {
      setShowTranscriptionNotification(true);
      await interviewTranscriptRequest(recordId);
      setTranscriptOpen((prevState) => [...prevState, recordId]);
      setShowTranscriptionReadyNotification(true);
      setShowTranscriptionNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
      setShowTranscriptionNotification(false);
    }
  };

  const handleOpenTranscript = async (report: any) => {
    try {
      const data = await interviewTranscriptRequest(report.id);
      const link = document.createElement('a');
      link.href = data.url;
      link.download = report.name;
      link.target = '_blank';
      link.click();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    if (interview.recordings && interview.recordings.length > 0) {
      const filteredRecordings = interview.recordings.filter(
        (recording: any) => recording.transcript === true
      );

      const transcriptOpenIds = filteredRecordings.map(
        (recording: any) => recording.id
      );

      setTranscriptOpen(transcriptOpenIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {showTranscriptionNotification && (
        <CreationNotification
          title="Transcribing video!"
          subTitle=""
          showNotification={showTranscriptionNotification}
          handleCloseNotification={() =>
            setShowTranscriptionNotification(false)
          }
        />
      )}
      {showTranscriptionReadyNotification && (
        <CreationNotification
          title="Transcription ready!"
          subTitle=""
          showNotification={showTranscriptionReadyNotification}
          handleCloseNotification={() =>
            setShowTranscriptionReadyNotification(false)
          }
        />
      )}
      {interview.transcription &&
        process.env.REACT_APP_DISABLE_AI === 'false' && (
          <Box sx={detailsRecordingsDataStyles}>
            <Typography>Recording(s)</Typography>
            <Box>
              <Box>
                {interview.recordings && interview.recordings.length > 0 ? (
                  interview.recordings.map((recording: any, index: any) => (
                    <>
                      <Box key={index}>
                        <Box sx={recordingsDataStyles}>
                          <Box>
                            <span className="material-icons-outlined">
                              video_camera_front
                            </span>
                            <Typography>{recording.name}</Typography>
                          </Box>
                          {!transcriptOpen.includes(recording.id) ? (
                            <Button
                              variant="contained"
                              onClick={() => handleGetTranscript(recording.id)}
                              sx={{
                                ...secondaryButtonStyles(),
                                height: '30px',
                              }}
                            >
                              <Typography>Get Transcript</Typography>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M9.00195 13.2252C11.2111 13.2252 13.002 11.4343 13.002 9.2252C13.002 7.01606 11.2111 5.2252 9.00195 5.2252C6.79281 5.2252 5.00195 7.01606 5.00195 9.2252C5.00195 11.4343 6.79281 13.2252 9.00195 13.2252Z"
                                  fill="#0053FF"
                                />
                                <path
                                  d="M9.00195 15.2252C6.33195 15.2252 1.00195 16.5652 1.00195 19.2252V20.2252C1.00195 20.7752 1.45195 21.2252 2.00195 21.2252H16.002C16.552 21.2252 17.002 20.7752 17.002 20.2252V19.2252C17.002 16.5652 11.672 15.2252 9.00195 15.2252ZM15.472 7.9952C15.792 8.7852 15.792 9.6652 15.472 10.4552C15.282 10.9252 15.362 11.4552 15.722 11.8152L15.752 11.8452C16.332 12.4252 17.322 12.3052 17.702 11.5752C18.462 10.1252 18.462 8.4252 17.682 6.9152C17.302 6.1752 16.302 6.0352 15.712 6.6252L15.702 6.6352C15.362 6.9852 15.282 7.5252 15.472 7.9952ZM19.182 3.1152C18.782 3.5152 18.722 4.1352 19.052 4.5952C21.022 7.3352 21.012 11.0052 19.022 13.8452C18.702 14.2952 18.772 14.9152 19.162 15.3052L19.192 15.3352C19.682 15.8252 20.512 15.7852 20.932 15.2352C23.682 11.6952 23.692 6.8652 20.932 3.2152C20.512 2.6652 19.672 2.6252 19.182 3.1152Z"
                                  fill="#0053FF"
                                />
                                <circle
                                  cx="17.5"
                                  cy="15.5"
                                  r="6.5"
                                  fill="white"
                                />
                                <path
                                  d="M18.25 18.5V16.25H20.5C20.9131 16.25 21.25 15.9131 21.25 15.5C21.25 15.0869 20.9131 14.75 20.5 14.75H18.25V12.5C18.25 12.0869 17.9131 11.75 17.5 11.75C17.0869 11.75 16.75 12.0869 16.75 12.5V14.75H14.5C14.0869 14.75 13.75 15.0869 13.75 15.5C13.75 15.9131 14.0869 16.25 14.5 16.25H16.75V18.5C16.75 18.9131 17.0869 19.25 17.5 19.25C17.9131 19.25 18.25 18.9131 18.25 18.5Z"
                                  fill="#0053FF"
                                  stroke="#0053FF"
                                  strokeWidth="0.5"
                                />
                              </svg>
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => handleOpenTranscript(recording)}
                              sx={{
                                ...secondaryButtonStyles(),
                                height: '30px',
                              }}
                            >
                              <Typography>Open Transcript</Typography>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="record_voice_over">
                                  <g id="Vector">
                                    <path
                                      d="M9.00195 13.2252C11.2111 13.2252 13.002 11.4343 13.002 9.2252C13.002 7.01606 11.2111 5.2252 9.00195 5.2252C6.79281 5.2252 5.00195 7.01606 5.00195 9.2252C5.00195 11.4343 6.79281 13.2252 9.00195 13.2252Z"
                                      fill="#0053FF"
                                    />
                                    <path
                                      d="M9.00195 15.2252C6.33195 15.2252 1.00195 16.5652 1.00195 19.2252V20.2252C1.00195 20.7752 1.45195 21.2252 2.00195 21.2252H16.002C16.552 21.2252 17.002 20.7752 17.002 20.2252V19.2252C17.002 16.5652 11.672 15.2252 9.00195 15.2252ZM15.472 7.9952C15.792 8.7852 15.792 9.6652 15.472 10.4552C15.282 10.9252 15.362 11.4552 15.722 11.8152L15.752 11.8452C16.332 12.4252 17.322 12.3052 17.702 11.5752C18.462 10.1252 18.462 8.4252 17.682 6.9152C17.302 6.1752 16.302 6.0352 15.712 6.6252L15.702 6.6352C15.362 6.9852 15.282 7.5252 15.472 7.9952ZM19.182 3.1152C18.782 3.5152 18.722 4.1352 19.052 4.5952C21.022 7.3352 21.012 11.0052 19.022 13.8452C18.702 14.2952 18.772 14.9152 19.162 15.3052L19.192 15.3352C19.682 15.8252 20.512 15.7852 20.932 15.2352C23.682 11.6952 23.692 6.8652 20.932 3.2152C20.512 2.6652 19.672 2.6252 19.182 3.1152Z"
                                      fill="#0053FF"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </Button>
                          )}
                        </Box>
                        <Box>
                          <Typography sx={recordingsDateStyles}>
                            {formatCustomDate(recording.created_date)}
                          </Typography>
                        </Box>
                      </Box>
                      {index < interview.recordings.length - 1 && (
                        <hr
                          style={{
                            border: 0,
                            borderTop: '1px solid #D3ECF7',
                            width: '100%',
                            margin: '10px 0',
                          }}
                        />
                      )}
                    </>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    sx={{
                      ...karlaProRegularItalicFontStyles,
                      color: '#69707B',
                      fontSize: '14px',
                    }}
                  >
                    Interview recordings will appear here.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
};

export default LEIAAInterviewDetails;
