import {
  Box,
  Button,
  List,
  ListItem,
  Modal,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { filesRequest } from '../../../api/documents';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import { secondaryButtonStyles } from '../../styles';
import { CustomTabPanel } from '../matter-detail/MatterDetailDocuments';

interface MatterTemplateViewerProps {
  template: any;
  templateList?: boolean;
  showListDetailModal?: boolean;
  setShowListDetailModal?: any;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MatterTemplateViewer = ({
  template,
  templateList = false,
  showListDetailModal = false,
  setShowListDetailModal,
}: MatterTemplateViewerProps) => {
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleClose = () => {
    setOpen(false);
    if (templateList) setShowListDetailModal(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const downloadFile = async (file: any) => {
    try {
      const data = await filesRequest('generate_signed_url', file.download);
      const link = document.createElement('a');
      link.href = data.url;
      link.download = file.name;
      link.click();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (templateList) {
      setOpen(showListDetailModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showListDetailModal]);

  return (
    <>
      {template && !templateList && (
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography
              sx={{
                color: '#202020',
                fontSize: '14px',
                ...karlaProRegularFontStyles,
                lineHeight: '22px',
                marginLeft: '5px',
              }}
            >
              For {template.topic.name}
            </Typography>

            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{ ...secondaryButtonStyles(), height: '40px' }}
            >
              <span className="material-icons-round">view_kanban</span>
              <Typography>Details</Typography>
            </Button>
          </Stack>
        </Box>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-create-analysis"
          aria-describedby="modal-create-analysis-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              padding: '48px',
              height: '80%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '24px',
              border: '1px solid #D3ECF6',
              background: '#FFF',
              boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
            }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Template Content
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  width: '100%',
                  borderBottom: '1px solid #949CA9',
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    width: '100%',
                    '& > div': {
                      display: 'flex',
                      width: '100%',
                      '& > div': {
                        display: 'flex',
                        width: '100%',
                        '& > button': {
                          flexGrow: 1,
                          padding: '8px 24px',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '8px',
                          borderRadius: '24px 24px 0px 0px',
                          '&.Mui-selected': {
                            backgroundColor: '#F5FAFD',
                            color: '#0053FF',
                          },
                          color: '#69707B',
                          textTransform: 'none',
                          ...karlaProRegularFontStyles,
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '22px',
                        },
                      },
                      '& > span.MuiTabs-indicator': {
                        backgroundColor: '#0053FF',
                      },
                    },
                  }}
                >
                  <Tab label="General information" {...a11yProps(0)} />
                  <Tab label="Scoping Interviews" {...a11yProps(1)} />
                  <Tab label="Witness Interviews" {...a11yProps(2)} />
                  <Tab label="Emails" {...a11yProps(3)} />
                  <Tab label="Documents" {...a11yProps(4)} />
                </Tabs>
              </Box>
            </Box>

            <Box
              sx={{
                height: '70%',
                overflowY: 'auto',
              }}
              className="scrollable-content"
            >
              <CustomTabPanel value={tabValue} index={0}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={0}
                  sx={{ marginTop: '30px' }}
                >
                  <Typography
                    id="modal-modal-title"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      textAlign: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    Name: <span style={{ color: 'gray' }}>{template.name}</span>
                  </Typography>

                  <Typography
                    id="modal-modal-title"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      textAlign: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    Topic:{' '}
                    <span style={{ color: 'gray' }}>
                      {' '}
                      {template.topic.name}
                    </span>
                  </Typography>
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <List sx={{ marginTop: '30px' }}>
                  {template.interview_template
                    .filter((t: any) => t.category === 'Scoping')
                    .map((_t: any, index: any) => {
                      return (
                        <ListItem
                          key={_t.id}
                          sx={{
                            borderRadius: '12px',
                            background: '#F0F8FD',
                            display: 'inline-flex',
                            width: '100%',
                            height: '38px',
                            padding: '16px',
                            marginBottom: '16px',
                            alignItems: 'center',
                            '& > p': {
                              ...karlaProRegularFontStyles,
                              fontSize: '14px',
                              fontWeight: '400',
                            },
                          }}
                        >
                          <Typography
                            sx={{ marginRight: '5px', color: '#949CA9' }}
                          >
                            {index + 1}.
                          </Typography>
                          <Typography sx={{ color: '#464B53' }}>
                            {_t.type} - {_t.question}
                          </Typography>
                        </ListItem>
                      );
                    })}
                </List>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={2}>
                <List sx={{ marginTop: '30px' }}>
                  {template.interview_template
                    .filter((t: any) => t.category === 'Witness')
                    .map((_t: any, index: any) => {
                      return (
                        <ListItem
                          key={_t.id}
                          sx={{
                            borderRadius: '12px',
                            background: '#F0F8FD',
                            display: 'inline-flex',
                            width: '100%',
                            height: '38px',
                            padding: '16px',
                            marginBottom: '16px',
                            alignItems: 'center',
                            '& > p': {
                              ...karlaProRegularFontStyles,
                              fontSize: '14px',
                              fontWeight: '400',
                            },
                          }}
                        >
                          <Typography
                            sx={{ marginRight: '5px', color: '#949CA9' }}
                          >
                            {index + 1}.
                          </Typography>
                          <Typography sx={{ color: '#464B53' }}>
                            {_t.type} - {_t.question}
                          </Typography>
                        </ListItem>
                      );
                    })}
                </List>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={3}>
                <List>
                  {template.email_template.map((email: any, index: any) => {
                    return (
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{
                          marginBottom: '16px',
                          width: '100%',
                          background: '#fcfcfc',
                          borderRadius: '12px',
                          padding: '15px',
                        }}
                        key={email.id}
                      >
                        <Typography
                          sx={{
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#464B53',
                            marginLeft: 'auto',
                            marginBottom: '-20px',
                          }}
                        >
                          {index + 1}
                        </Typography>
                        <Typography
                          sx={{
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#464B53',
                          }}
                        >
                          Category:{' '}
                          <span style={{ color: 'gray' }}>
                            {email.category}
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#464B53',
                          }}
                        >
                          Type:{' '}
                          <span style={{ color: 'gray' }}>{email.type}</span>
                        </Typography>
                        <Typography
                          sx={{
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#464B53',
                          }}
                        >
                          Subject:{' '}
                          <span style={{ color: 'gray' }}>{email.subject}</span>
                        </Typography>
                        <Typography
                          sx={{
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                            color: 'gray',
                            borderRadius: '12px',
                            background: '#F0F8FD',
                            padding: '16px',
                          }}
                        >
                          {email.message}
                        </Typography>
                      </Stack>
                    );
                  })}
                </List>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={4}>
                <List sx={{ width: '100%', marginTop: '30px' }}>
                  <Typography
                    id="modal-modal-title"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '20px',
                      color: '#69707B',
                    }}
                  >
                    Report:
                  </Typography>
                  {template.report.id && (
                    <ListItem
                      sx={{
                        borderRadius: '12px',
                        background: '#F0F8FD',
                        display: 'inline-flex',
                        width: '100%',
                        height: '38px',
                        padding: '16px',
                        marginBottom: '16px',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '& > p': {
                          ...karlaProRegularFontStyles,
                          fontSize: '14px',
                          fontWeight: '400',
                        },
                        '&:hover': {
                          backgroundColor: '#d1eaf6',
                        },
                      }}
                      onClick={() => downloadFile(template.report)}
                    >
                      <Typography sx={{ color: '#464B53' }}>
                        {template.report.filename}
                      </Typography>
                    </ListItem>
                  )}
                </List>

                <Box
                  sx={{
                    width: ' 100%',
                    height: '1px',
                    backgroundColor: '#AABBCC',
                  }}
                />

                <List sx={{ width: '100%' }}>
                  <Typography
                    id="modal-modal-title"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '20px',
                      color: '#69707B',
                    }}
                  >
                    Investigation Plans:
                  </Typography>
                  {template.plans.map((plan: any, index: any) => {
                    return (
                      <ListItem
                        key={plan.id}
                        sx={{
                          borderRadius: '12px',
                          background: '#F0F8FD',
                          display: 'inline-flex',
                          width: '100%',
                          height: '38px',
                          padding: '16px',
                          marginBottom: '16px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          '& > p': {
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                          },
                          '&:hover': {
                            backgroundColor: '#d1eaf6',
                          },
                        }}
                        onClick={() => downloadFile(plan)}
                      >
                        <Typography
                          sx={{ marginRight: '5px', color: '#949CA9' }}
                        >
                          {index + 1}.
                        </Typography>
                        <Typography sx={{ color: '#464B53' }}>
                          {plan.name}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>

                <Box
                  sx={{
                    width: ' 100%',
                    height: '1px',
                    backgroundColor: '#AABBCC',
                  }}
                />

                <List sx={{ width: '100%' }}>
                  <Typography
                    id="modal-modal-title"
                    sx={{
                      ...karlaProRegularFontStyles,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '22px',
                      marginBottom: '20px',
                      color: '#69707B',
                    }}
                  >
                    Review Protocols:
                  </Typography>
                  {template.protocols.map((protocol: any, index: any) => {
                    return (
                      <ListItem
                        key={protocol.id}
                        sx={{
                          borderRadius: '12px',
                          background: '#F0F8FD',
                          display: 'inline-flex',
                          width: '100%',
                          height: '38px',
                          padding: '16px',
                          marginBottom: '16px',
                          alignItems: 'center',
                          cursor: 'pointer',
                          '& > p': {
                            ...karlaProRegularFontStyles,
                            fontSize: '14px',
                            fontWeight: '400',
                          },
                          '&:hover': {
                            backgroundColor: '#d1eaf6',
                          },
                        }}
                        onClick={() => downloadFile(protocol)}
                      >
                        <Typography
                          sx={{ marginRight: '5px', color: '#949CA9' }}
                        >
                          {index + 1}.
                        </Typography>
                        <Typography sx={{ color: '#464B53' }}>
                          {protocol.name}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </CustomTabPanel>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ width: '100%', marginTop: '35px !important' }}
            >
              <Button
                variant="contained"
                sx={{ ...secondaryButtonStyles(), width: '100%' }}
                onClick={handleClose}
              >
                <Typography>Close</Typography>
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default MatterTemplateViewer;
