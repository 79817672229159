import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  InputLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import LEIAAContext from '../context/context';
import { User } from '../types/auth';
import { isObjectFieldsEmpty } from '../utils/utils';
import { matterCreationHeaderWrapperStyles } from '../components/matters/matter-creation/styles';
import { simpleGoBackButtonStyles } from '../components/styles';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import LEIAAInput from '../components/inputs/LEIAAInput';
import LEIAASelect from '../components/inputs/LEIAASelect';
import UserCreationFooter from '../components/user-profile/UserCreationFooter';
import { userDetailsRequest, userMeDetailsRequest } from '../api/auth';
import NotificationToast from '../components/shared/toast/NotificationToast';
import 'react-phone-number-input/style.css';
import UserCreationConfirmation from '../components/user-profile/UserCreationConfirmation';
import { ClientOptionType } from '../types/matters';
import { clientsRequest } from '../api/matters';
import { LEIAAAutoComplete } from '../components/inputs';

const UserDetailsEdit = () => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [meUser, setMeUser] = useState<User>();
  const { setUser } = useContext(LEIAAContext);
  const { userId } = useParams();
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userEditionFinish, setUserEditionFinish] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [clients, setClients] = useState<readonly ClientOptionType[]>();
  const clientFilter = createFilterOptions<ClientOptionType>();

  const handleFetchClients = async () => {
    try {
      const response = await clientsRequest();
      setClients(response as ClientOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    setIsLoading(true);
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      reset(currentUser);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleCancel = () => {
    navigate(-1);
  };

  const getMeDetails = async () => {
    try {
      const meDetails = await userMeDetailsRequest('GET');
      setMeUser(meDetails.user);
      return meDetails;
    } catch (error: any) {
      console.error(error);
    }
    return null;
  };

  const getUserDetails = async () => {
    let userDetails;
    try {
      if (userId) {
        userDetails = await userDetailsRequest('GET', undefined, userId);
      } else {
        userDetails = await userMeDetailsRequest('GET');
      }
      setCurrentUser(userDetails?.user);
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleValidate = (value?: string) => {
    if (!value) {
      return true;
    }
    if (typeof value === 'string' && isValidPhoneNumber(value)) {
      const isValid = isValidPhoneNumber(value);
      return isValid;
    }
    return false;
  };

  useEffect(() => {
    getUserDetails();
    getMeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const onSubmitEdit: SubmitHandler<FieldValues | User> = async (data) => {
    try {
      if (userId) {
        await userDetailsRequest('PATCH', data as User, userId);
        setUserEditionFinish(true);
      } else {
        const newUserDetails = await userMeDetailsRequest(
          'PATCH',
          data as User
        );
        setUser(newUserDetails.user);
        if (
          !['Admin', 'Superuser', 'Leader'].includes(newUserDetails.user.role)
        )
          navigate(-1);
        setUserEditionFinish(true);
      }
      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    handleFetchClients();
  }, []);

  const detailForm = () => {
    return !userEditionFinish ? (
      <form onSubmit={handleSubmit(onSubmitEdit)}>
        <Box>
          <Box sx={matterCreationHeaderWrapperStyles}>
            <Box>
              <Box>
                <Typography>Edit user</Typography>
              </Box>
              <Button
                sx={simpleGoBackButtonStyles}
                variant="text"
                onClick={handleCancel}
              >
                <span className="material-symbols-outlined">arrow_back</span>
                <Typography variant="caption" component="p">
                  Back
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={matterInformationWrapperStyles}>
            {/* FORM  */}
            <Box sx={matterInformationFormWrapperStyles}>
              <Typography>Edit user.</Typography>
              <Box sx={matterInformationInputWrapperStyles}>
                <Box>
                  <LEIAAInput
                    labelText="First Name"
                    controllerName="first_name"
                    control={control}
                    inputPlaceholder="Enter first name"
                    inputWidth="100%"
                    inputHeight="40px"
                    type="STRING"
                  />
                </Box>
                <Box>
                  <LEIAAInput
                    labelText="Last Name"
                    controllerName="last_name"
                    control={control}
                    inputPlaceholder="Enter last name"
                    inputWidth="100%"
                    inputHeight="40px"
                  />
                </Box>
                <Box>
                  <LEIAAInput
                    labelText="Username"
                    controllerName="username"
                    control={control}
                    inputPlaceholder="Enter last name"
                    inputWidth="100%"
                    inputHeight="40px"
                  />
                </Box>
                <Box>
                  <LEIAAAutoComplete
                    labelText="Organisation"
                    controllerName="organisation"
                    control={control}
                    autoCompleteFilter={clientFilter}
                    autoCompleteOptions={clients}
                    inputPlaceholder="Enter organisation name"
                    inputWidth="100%"
                    inputHeight="40px"
                  />
                </Box>
                {userId && meUser?.username !== currentUser?.username && (
                  <Box>
                    <LEIAASelect
                      labelText="Role"
                      controllerName="role"
                      control={control}
                      inputPlaceholder="Select role"
                      inputHeight="40px"
                      inputWidth="100%"
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Superuser">Superuser</MenuItem>
                      <MenuItem value="Leader">Leader</MenuItem>
                      <MenuItem value="Agent">Agent</MenuItem>
                      <MenuItem value="Collaborator">Collaborator</MenuItem>
                    </LEIAASelect>
                  </Box>
                )}
                <Box>
                  <LEIAAInput
                    labelText="Email address"
                    controllerName="email"
                    control={control}
                    inputPlaceholder="Enter email address"
                    inputWidth="100%"
                    inputHeight="40px"
                    type="email"
                  />
                </Box>
                <Box>
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{ validate: (value) => handleValidate(value) }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Box>
                          <InputLabel
                            data-testid="matter-form-label-id"
                            htmlFor="Phone number"
                          >
                            Enter phone number
                          </InputLabel>
                          <PhoneInput
                            value={value}
                            onChange={onChange}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="GB"
                            id="phone-input"
                          />
                          <InputLabel
                            data-testid="matter-form-label-id"
                            htmlFor="Phone number"
                            style={{ color: 'red' }}
                          >
                            {errors.phone_number
                              ? 'Enter a correct phone number, this phone number is invalid!'
                              : null}
                          </InputLabel>
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <UserCreationFooter />
      </form>
    ) : (
      <UserCreationConfirmation isEdition />
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        alignItems: 'flex-start',
        overflowY: 'scroll',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        position: 'relative',
        '& > form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            padding: '48px 32px',
            flex: 1,
          },

          width: '100%',
        },
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}

      {!isLoading &&
      currentUser !== undefined &&
      !isObjectFieldsEmpty(currentUser) ? (
        detailForm()
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > span': {
              color: '#0053FF',
            },
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default UserDetailsEdit;
