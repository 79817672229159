import { karlaProRegularFontStyles } from '../../styles/textStyles';

export const mainSidebarContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: '#F5FAFD',
  boxShadow: '0px 0px 8px rgba(228, 232, 242, 0.15)',
  '& > div:nth-of-type(1)': {
    height: 'calc(100vh - 84px)',
  },
};

export const sidebarNavigationContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '64px',
  width: '256px',
  '& > div': {
    padding: '32px 0px 0px 30px',
    width: '100%',
    height: '64px',
  },
  '& > ul': {
    maxHeight: '100%',
    overflowY: 'auto',
    scrollbarColor: 'transparent transparent',
    marginBottom: '32px',
  },
};

export const sidebarNavigationListStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  width: '100%',
  '> li': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 32px',
    width: '100%',
    height: '48px',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 0,
    cursor: 'pointer',
    '&:hover': {
      background: '#EBF8FE',
    },
    '& + li, & + ul + li': {
      marginTop: '40px',
    },
    '& + ul': {
      cursor: 'pointer',
      marginTop: '8px',
      padding: '0px',
      width: '100%',

      '& > li': {
        gap: '2px',
        padding: '0px 0px 0px 58px',
      },
    },
  },
};

export const sidebarNavigationListItemStyles = (
  selectedItem: string,
  link: string,
  hasSubItems: boolean,
  subItemsLengthGreater: boolean
) => {
  const commonLink = hasSubItems
    ? (selectedItem ?? '').substring(
        0,
        (selectedItem ?? '').lastIndexOf(link) + link.length
      )
    : selectedItem;

  return commonLink === link
    ? {
        gap: subItemsLengthGreater ? '16px' : '8px',
        backgroundColor: '#EBF8FE',
        color: '#0053FF',
        '& > p': {
          ...karlaProRegularFontStyles,
          fontSize: '17px',
          lineHeight: '30px',
          fontWeight: 700,
        },
        '& > svg > path': {
          fill: '#0053FF',
        },
      }
    : {
        gap: subItemsLengthGreater ? '16px' : '8px',
        backgroundColor: 'transparent',
        color: '#464B53',
        '& > p': {
          ...karlaProRegularFontStyles,
          fontSize: '17px',
          lineHeight: '30px',
          fontWeight: 400,
        },
      };
};

export const sidebarUserMenuContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px 32px',
  width: '100%',
  height: '84px',
  flex: 'none',
  order: 1,
  flexGrow: 0,
  borderTop: '1px solid #0053FF',
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: 'auto 0',
    '& > div': {
      display: 'flex',
      '& > p': {
        marginLeft: '12px',
      },
    },
  },
};
