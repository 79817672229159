/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../styles/textStyles';
import {
  getReportUiDataRquest,
  getWhistleblowerReport,
} from '../../api/whistleblower';
import ReportingChat from './ReportingChat';
import {
  a11yProps,
  CustomTabPanel,
} from '../../components/matters/matter-detail/MatterDetailDocuments';
import ReportingImageHeader from './ReportingImageHeader';

export const ReportInformation = ({ data }: any) => {
  const downloadFile = (url: string, name: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  };

  return (
    <Box sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
      {data
        ?.filter(
          (item: any) =>
            item.answer ||
            (item.type === 'documents' && item.documents.length !== 0) ||
            (item.type === 'persons' && item.persons.length !== 0)
        )
        .map((item: any) => {
          return (
            <Box sx={{ marginBottom: '15px' }} key={uuidv4()}>
              <Typography
                sx={{
                  display: 'flex',
                  padding: '0px 12px',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  color: '#464B53',
                  fontSize: '16px',
                  ...karlaProBoldFontStyles,
                  lineHeight: '22px',
                }}
              >
                {item.question}
              </Typography>
              {item.type === 'basic' && (
                <Typography
                  sx={{
                    display: 'flex',
                    padding: '0px 12px',
                    alignItems: 'flex-start',
                    alignSelf: 'stretch',
                    color: '#464B53',
                    fontSize: '14px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                    marginLeft: '5px',
                    marginTop: '2px',
                  }}
                >
                  {item.answer}
                </Typography>
              )}
              {item.type === 'documents' &&
                item.documents?.map((doc: any) => (
                  <Typography
                    sx={{
                      display: 'flex',
                      padding: '0px 12px',
                      alignItems: 'flex-start',
                      alignSelf: 'stretch',
                      color: '#140070',
                      fontSize: '14px',
                      ...karlaProRegularFontStyles,
                      lineHeight: '22px',
                      marginLeft: '5px',
                      marginTop: '2px',
                      '&:hover': {
                        color: '#02c1eb',
                      },
                    }}
                    key={uuidv4()}
                    onClick={() => downloadFile(doc.download_url, doc.name)}
                  >
                    • {doc.name}
                  </Typography>
                ))}
              {item.type === 'persons' &&
                item.persons?.map((person: any) => (
                  <Stack
                    key={uuidv4()}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      marginLeft: '20px !important',
                      '& > div > p': {
                        fontSize: '14px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                        fontWeight: '500',
                      },
                      '& > div > p > span': {
                        color: '#464B53',
                        fontSize: '14px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                      },
                    }}
                  >
                    <Box>
                      <Typography>
                        • <span>First name: </span> {person.first_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        <span>Last name: </span> {person.last_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography>
                        <span>Job title: </span> {person.job_title}
                      </Typography>
                    </Box>
                  </Stack>
                ))}
            </Box>
          );
        })}
    </Box>
  );
};

const ReportingFollowDashboard = () => {
  const { id } = useParams();
  const [reportData, setReportData] = useState<any>(null);
  const [filledReport, setFilledReport] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [loadingUI, setLoadingUI] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [configCopy, setConfigCopy] = useState<any>(null);
  const location = useLocation();
  const { logout } = useAuth0();

  const handleFetchUIData = async () => {
    try {
      setLoadingUI(true);
      const response = await getReportUiDataRquest();

      setConfigCopy(response.reporting_follow_up_dashboard_content);

      setLoadingUI(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleFetchReport = async () => {
    setLoading(true);

    try {
      const response = await getWhistleblowerReport(id);
      setReportData(response.report_information);
      setFilledReport(response.initial_report_data);

      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    // eject user if not authtoken
    handleFetchUIData();
    handleFetchReport();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      localStorage.clear();
      logout();
    };
    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: { xs: '10px 10px', sm: '10px 50px' },
      }}
    >
      <ReportingImageHeader />

      {
        // eslint-disable-next-line no-nested-ternary
        loading || loadingUI ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                ...sofiaProBoldFontStyles,
                color: '#202020',
                textTransform: 'uppercase',
                fontSize: { xs: '20px', sm: '25px' },
                fontWeight: '500',
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              {reportData.topic} - Concern report
            </Typography>

            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontSize: { xs: '12px', md: '14px' },
                color: '#202020',
                marginTop: '20px',
                paddingLeft: { xs: '5%', md: '20%' },
                paddingRight: { xs: '5%', md: '20%' },
                textAlign: 'justify',
              }}
            >
              {configCopy}
            </Typography>

            <Box
              sx={{
                width: '100%',
                border: '1px solid #a4c1fd',
                padding: '20px',
                margin: '15px 0',
                borderRadius: '12px',
              }}
            >
              {/* PROGRESS */}
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                  '& > div > p': {
                    fontSize: '16px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                    fontWeight: '500',
                  },
                  '& > div > p > span': {
                    color: '#464B53',
                    fontSize: '16px',
                    ...karlaProRegularFontStyles,
                    lineHeight: '22px',
                  },
                }}
              >
                <Box>
                  <Typography>
                    {/* <span className="material-icons-round">summarize</span> */}
                    <span>Status: </span>
                    {reportData.status}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    {/* <span className="material-icons-round">today</span>{' '} */}
                    <span>Submission date: </span> {reportData.created_date}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  width: '100%',
                  borderBottom: '1px solid #949CA9',
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    flexGrow: 1,
                    '& > div': {
                      '& > div': {
                        '& > button': {
                          display: 'flex',
                          flex: '1',
                          padding: '8px 24px',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: '8px',
                          borderRadius: '24px 24px 0px 0px',
                          '&.Mui-selected': {
                            backgroundColor: '#F5FAFD',
                            color: '#0053FF',
                          },

                          color: '#69707B',
                          textTransform: 'none',
                          ...karlaProRegularFontStyles,
                          fontSize: { xs: '12px', sm: '14px' },
                          fontWeight: 500,
                          lineHeight: '22px',
                        },
                      },
                      '& > span.MuiTabs-indicator': {
                        backgroundColor: '#0053FF',
                      },
                    },
                  }}
                >
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons-round">file_copy</span>
                        <span style={{ marginLeft: '8px' }}>
                          Initial Report Information
                        </span>
                      </div>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons-round">
                          question_answer
                        </span>
                        <span style={{ marginLeft: '8px' }}>
                          Secure Communication
                        </span>
                      </div>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                width: '100%',
                padding: '15px 0px 10px 0px',
                marginTop: 'auto',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <CustomTabPanel value={tabValue} index={0}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginRight: 'auto',
                      width: '100%',
                      marginBottom: '-5px',
                    }}
                  >
                    {/* REPORT COPY */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        marginRight: 'auto',
                        width: '100%',
                        borderBottom: '1px solid  #D3ECF6',
                      }}
                    >
                      <ReportInformation data={filledReport} />
                    </Box>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginRight: 'auto',
                      width: '100%',
                      marginBottom: '-5px',
                    }}
                  >
                    <ReportingChat reportId={id} />
                  </Box>
                </CustomTabPanel>
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  );
};

export default ReportingFollowDashboard;
