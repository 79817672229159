import { useSelector } from 'react-redux';
import { selectUserPermissions } from '../redux/features/user-permissions/userPermissionsSlice';

const usePermissions = () => {
  const permissions = useSelector(selectUserPermissions);
  return permissions;
};

const useMatterPermissions = () => {
  return usePermissions().matter;
};

const useUsersPermissions = () => {
  return usePermissions().users;
};

const useDocumentPermissions = () => {
  return usePermissions().matter_documents;
};

const useTaskPermissions = () => {
  return usePermissions().matter_tasks;
};

const useInterviewMatterPermissions = () => {
  return usePermissions().matter_interviews;
};

const useInterviewPermissions = () => {
  return usePermissions().interviews;
};

const useReportPermissions = () => {
  return usePermissions().matter_reports;
};

const useWikiPermissions = () => {
  const { wiki_repository: wikiRepository, wiki_template: wikiTemplate } =
    usePermissions();

  return {
    wikiRepository,
    wikiTemplate,
  };
};

export {
  usePermissions,
  useMatterPermissions,
  useUsersPermissions,
  useDocumentPermissions,
  useTaskPermissions,
  useInterviewMatterPermissions,
  useReportPermissions,
  useWikiPermissions,
  useInterviewPermissions,
};
