import { Box, Typography } from '@mui/material';
import { mattersListStatusChipWrapperStyles } from '../shared/matters-list-table/styles';

interface RelativityStatusChipProps {
  status: string;
  heightValue: string;
  wrapperHeight: string;
}

const RelativityStatusChip = ({
  status,
  heightValue,
  wrapperHeight,
}: RelativityStatusChipProps) => {
  let backgroundColor;
  let color = '#FFFFFF';

  switch (status) {
    case 'Imported':
      backgroundColor = '#38B763';
      break;
    case 'Not Imported"':
      backgroundColor = '#EDB900';
      color = '#202020';
      break;
    case 'Error':
      backgroundColor = '#E22727';
      break;
    case 'Importing':
      backgroundColor = '#3375FF';
      break;
    case 'Exported':
      backgroundColor = '#795548';
      break;
    case 'Exporting':
      backgroundColor = '#FFC107';
      break;
    default:
      backgroundColor = '#e0e0e0'; // Default color if status is not recognized
      break;
  }

  return (
    <Box
      sx={mattersListStatusChipWrapperStyles(
        backgroundColor,
        color,
        heightValue,
        wrapperHeight
      )}
    >
      <Box>
        <Typography>{status}</Typography>
      </Box>
    </Box>
  );
};

export default RelativityStatusChip;
