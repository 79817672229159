import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { UploadedAllegationQuestion } from '../../../../types/matters';
import {
  uploadedAllegationQuestionnaireAccordionDetailsStyles,
  uploadedAllegationQuestionnaireAccordionStyles,
  uploadedAllegationQuestionnaireAccordionSummaryContentStyles,
} from '../matter-creation-steps/matter-information-step/styles';

interface MatterAddedQuestionnaireFileInformationProps {
  uploadedInterviewQuestions: any;
  showAnswer: boolean;
}

const MatterAddedQuestionnaireFileInformation = ({
  uploadedInterviewQuestions,
  showAnswer,
}: MatterAddedQuestionnaireFileInformationProps) => {
  return (
    <Box>
      <Box>
        <Box>
          <span className="material-icons-round">file_upload</span>
          <Typography>Uploaded Interview(s) Questions</Typography>
        </Box>
      </Box>
      {uploadedInterviewQuestions.map(
        (ua: UploadedAllegationQuestion, index: number) => {
          return (
            <Accordion
              key={ua.fileName}
              sx={uploadedAllegationQuestionnaireAccordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <span className="material-icons-round">arrow_drop_down</span>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box
                  sx={
                    uploadedAllegationQuestionnaireAccordionSummaryContentStyles
                  }
                >
                  <Box>
                    <Typography>{`${index + 1}`}.</Typography>
                    <Typography>{ua.fileName}</Typography>
                  </Box>
                  <Typography>Original File</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={uploadedAllegationQuestionnaireAccordionDetailsStyles}
              >
                {ua.q_a.map((m: any, i: number) => {
                  return (
                    <Box key={i}>
                      <Box>
                        <Typography>{`${i + 1}. ${m.question}`}</Typography>
                      </Box>
                      {showAnswer && (
                        <Box>
                          <Typography>{m.answer}</Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        }
      )}
    </Box>
  );
};

export default MatterAddedQuestionnaireFileInformation;
