import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createNote, updateNote } from '../../api/general';
import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
} from '../../styles/textStyles';
import { noteSubjectInputStyles } from './styles';
import NoteEditor from '../note-editor/NoteEditor';
import { tertiaryButtonStyles, primaryButtonStyles } from '../styles';
import NoteTagsEditor from './NoteTagsEditor';
import { LEIAADatePicker } from '../inputs';

interface MatterNoteProps {
  matterData: any;
  matterId: any;
  open: boolean;
  setOpen: any;
  tableRefresh: any;
  resourceName: string;
  note?: any;
  tagResourceId?: any;
}

const MatterNote = ({
  matterData,
  matterId,
  open,
  setOpen,
  tableRefresh,
  resourceName,
  note = undefined,
  tagResourceId = undefined,
}: MatterNoteProps) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleSubjectEdit, setToggleSubjectEdit] = useState(false);
  const [loadedNote, setLoadedNote] = useState(note || undefined);
  const [noteSubject, setNoteSubject] = useState(
    note ? note.subject : 'New note'
  );
  const [privileged, setPrivileged] = useState(note ? note.privileged : false);
  const [noteTags, setNoteTags] = useState(
    note
      ? note.tags
      : [
          {
            tag_resource_id: tagResourceId,
            tag_type: resourceName,
            tag_name: resourceName,
          },
        ]
  );
  const { control, setValue, watch } = useForm();
  const editorRef = useRef();
  const noteSaveHandler = async () => {
    setLoading(true);

    try {
      if (editorRef.current) {
        const state = (editorRef.current as any).export();
        const stateJson = JSON.stringify(state.json);

        if (loadedNote) {
          // Update
          await updateNote(
            loadedNote.id,
            noteSubject,
            stateJson,
            noteTags,
            privileged,
            watch('date')
          );
        } else {
          // Create
          const response = await createNote(
            matterId,
            noteSubject,
            stateJson,
            noteTags,
            privileged,
            watch('date')
          );
          setLoadedNote(response.note);
          setNoteTags(response.note.tags);
        }

        setSnackbarOpen(true);
        tableRefresh();
      }
    } catch (error: unknown) {
      console.error(error);
      setErrorSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectClick = (e: any) => {
    e.stopPropagation();
    setToggleSubjectEdit(true);
  };

  const closeEdit = () => {
    if (toggleSubjectEdit) {
      setToggleSubjectEdit(false);
    }
  };

  const handleRemoveTag = (tagToRemove: any) => {
    setNoteTags(noteTags.filter((tag: any) => tag !== tagToRemove));
  };

  const handleAddTag = (tagToAdd: any) => {
    if (tagToAdd.tag_type === 'stage') {
      const filteredTags = noteTags.filter(
        (tag: any) => tag.tag_type !== 'stage'
      );
      setNoteTags([...filteredTags, tagToAdd]);
    } else {
      setNoteTags([...noteTags, tagToAdd]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open && note && editorRef.current) {
        try {
          const content = JSON.parse(note.content);
          (editorRef.current as any).load(content);
          setValue('date', note.date);
        } catch (error) {
          console.error('Failed to parse note content:', error);
        }
      }
    }, 100);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Note saved!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          zIndex: '99999999',
          '& > div': {
            background: '#49b44c',
          },
        }}
      />
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setErrorSnackbarOpen(false)}
        message="Error"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          zIndex: '99999999',
          '& > div': {
            background: '#b44949',
          },
        }}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '1200px',
            width: 'auto',
            maxHeight: '90%',
            padding: '25px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '24px',
            border: '1px solid #D3ECF6',
            background: '#FFF',
            boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
            zIndex: '5',
            height: '90%',
          }}
          onClick={() => closeEdit()}
        >
          <Box sx={{ overflowY: 'auto', flexGrow: 1 }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Box>
                {toggleSubjectEdit || !noteSubject ? (
                  <TextField
                    defaultValue={noteSubject}
                    placeholder="Enter note name"
                    variant="standard"
                    inputProps={{
                      'data-testid': 'form-text-field-id',
                      inputMode: 'text',
                      className: 'scrollable-content',
                    }}
                    onChange={(e: any) => {
                      setNoteSubject(e.target.value);
                    }}
                    autoComplete="off"
                    type="text"
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    margin="normal"
                    sx={{
                      ...noteSubjectInputStyles('auto', '40px'),
                      marginBottom: '5px',
                    }}
                    onClick={handleSubjectClick}
                  />
                ) : (
                  <Typography
                    variant="h5"
                    mb={2}
                    onClick={() => setToggleSubjectEdit(true)}
                    sx={{
                      ...karlaProBoldFontStyles,
                      marginBottom: '0 !important',
                    }}
                  >
                    {noteSubject}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{ borderLeft: '1px solid #d3d3d3', paddingLeft: '10px' }}
              >
                <LEIAADatePicker
                  labelText=""
                  inputPlaceholder="Enter date of event"
                  controllerName="date"
                  control={control}
                  disableHelper
                />
              </Box>
              <Box
                sx={{ borderLeft: '1px solid #d3d3d3', paddingLeft: '10px' }}
              >
                <FormControlLabel
                  labelPlacement="end"
                  control={
                    <Checkbox
                      checked={privileged}
                      onChange={() => setPrivileged(!privileged)}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 24 },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: '#464B53',
                        fontSize: '16px',
                        ...karlaProRegularFontStyles,
                        lineHeight: '22px',
                        display: 'flex',
                        marginRight: 'auto',
                      }}
                    >
                      Privileged
                    </Typography>
                  }
                />
              </Box>
            </Stack>

            {noteTags && (
              <Box
                sx={{
                  marginTop: '20px',
                  maxHeight: '120px',
                  overflowY: 'scroll',
                }}
                className="scrollable-content"
              >
                <NoteTagsEditor
                  matterId={matterId}
                  tags={noteTags}
                  handleRemoveTag={handleRemoveTag}
                  handleAddTag={handleAddTag}
                />
              </Box>
            )}

            <Box
              sx={{
                overflowY: 'scroll',
                flexGrow: 1,
                maxHeight: '400px',
              }}
              className="scrollable-content"
            >
              <NoteEditor editorRef={editorRef} />
            </Box>
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ paddingTop: '20px', paddingBottom: '16px' }}
          >
            <Button
              variant="contained"
              sx={{ ...tertiaryButtonStyles(), width: '10%' }}
              onClick={() => setOpen(false)}
            >
              <Typography>Close</Typography>
            </Button>
            <Button
              variant="contained"
              sx={{ ...primaryButtonStyles(), width: '20%' }}
              onClick={() => noteSaveHandler()}
            >
              <Typography>{loadedNote ? 'Save' : 'Create'}</Typography>
              <span className="material-icons-round">save</span>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default MatterNote;
