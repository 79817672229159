import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../styles/textStyles';
import { primaryButtonStyles } from '../components/styles';

const InterviewCompleted = () => {
  const location = useLocation();
  const interviewDetails: any = location.state?.interviewDetails;
  const isWitness: any = location.state?.isWitness;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: '0 160px',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '32px 0',
          gap: '64px',
          height: '541px',
          '& > div:nth-of-type(1) > h4': {
            ...sofiaProRegularFontStyles,
            fontWeight: 700,
            fontSize: '18px',
            letterSpacing: '0.5px',
            color: '#202020',
          },
          '& > div:nth-of-type(2)': {
            '& > div': {
              display: 'flex',
              padding: '16px',
              alignItems: 'center',
              gap: '32px',
              borderRadius: '16px',
              backgroundColor: '#EBF8FE',
              flexDirection: 'column',
              '& > *': {
                display: 'flex',
                gap: '16px',
                alignSelf: 'flex-start',
              },
            },
          },
        }}
      >
        <Box>
          <Typography component="h4">{interviewDetails?.name}</Typography>
        </Box>
        <Box>
          <Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                  alignSelf: 'baseline',
                  '& > span': {
                    color: '#0053FF',
                    fontSize: '24px',
                  },
                }}
              >
                <span className="material-icons-round">info</span>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  flexDirection: 'column',
                  '& > p:nth-of-type(1)': {
                    ...karlaProRegularFontStyles,
                    color: '#32353C',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.2px',
                  },
                  '& > p:nth-of-type(2)': {
                    ...karlaProRegularFontStyles,
                    color: '#32353C',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0.2px',
                  },
                }}
              >
                <Typography>You have completed this interview.</Typography>
                <Typography>Thank you for participating.</Typography>
              </Box>
            </Box>

            {!isWitness && (
              <Button
                variant="contained"
                sx={primaryButtonStyles()}
                onClick={() => {
                  if (interviewDetails && interviewDetails.matter_id) {
                    navigate(`/matters/detail/${interviewDetails.matter_id}`);
                  } else {
                    navigate('/');
                  }
                }}
              >
                Return home
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InterviewCompleted;
