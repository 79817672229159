/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormControl, InputLabel, RadioGroup } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface LEIAARadioButtonsProps {
  labelText?: string;
  controllerName: string;
  control: Control<FieldValues>;
  required?: boolean;
  formControlStyles: any;
  children: React.ReactNode;
  customHandleChange: any;
}

const LEIAARadioButtons = ({
  labelText,
  controllerName,
  control,
  required = true,
  formControlStyles,
  children,
  customHandleChange,
}: LEIAARadioButtonsProps) => {
  return (
    <Box>
      {labelText && (
        <InputLabel data-testid="matter-form-label-id" htmlFor={labelText}>
          {required ? '*' : ''}
          {labelText}
        </InputLabel>
      )}
      <Controller
        name={controllerName}
        control={control}
        defaultValue=""
        rules={{ required: true }} // Add validation rules as per your requirement
        render={({ field: { onChange, ...rest } }) => {
          return (
            <FormControl sx={formControlStyles}>
              <RadioGroup
                {...rest}
                value={rest.value}
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                onChange={(e) => {
                  onChange(e.target.value);
                  customHandleChange(e);
                }}
              >
                {children}
              </RadioGroup>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default LEIAARadioButtons;
