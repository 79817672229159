/* eslint-disable no-else-return */
import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ActivityHistoryListItemHeader from './ActivityHistoryListItemHeader';
import { Filters, MatterHistory, TaskHistory } from '../../types/matters';
import ActivityHistorySortButtons from './ActivityHistorySortButtons';
import ActivityHistoryListItemContent from './ActivityHistoryListItemContent';
import { historyListWrapperStyles } from './styles';
import {
  userMatterHistoryRequest,
  userTaskHistoryRequest,
} from '../../api/users';
import { User } from '../../types/auth';
import { karlaProRegularItalicFontStyles } from '../../styles/textStyles';
import NotificationToast from '../shared/toast/NotificationToast';

interface ActivityHistoryListProps {
  title: string;
  typeOfData: 'MATTERS' | 'TASKS';
  currentUser?: User | null;
}

const ActivityHistoryList = ({
  title,
  typeOfData,
  currentUser,
}: ActivityHistoryListProps) => {
  const [mattersFilters, setMattersFilters] = useState({});
  const [tasksFilters, setTasksFilters] = useState({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [matterHistoryData, setMatterHistoryData] = useState<MatterHistory[]>();
  const [taskHistoryData, setTaskHistoryData] = useState<TaskHistory[]>();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const navigate = useNavigate();

  const handleFilterChange = (filterName: string, filterValue: string) => {
    if (filterName === 'status') {
      setMattersFilters((prevFilters) => {
        if (filterValue !== '') {
          return {
            ...prevFilters,
            status: filterValue,
          };
          // eslint-disable-next-line no-else-return
        } else {
          const { status, ...restFilters } = prevFilters as {
            status: string;
            sort: string;
          };

          return restFilters;
        }
      });
    } else if (filterName === 'sort') {
      setMattersFilters((prevFilters) => {
        if (filterValue !== '') {
          return {
            ...prevFilters,
            sort: filterValue,
          };
          // eslint-disable-next-line no-else-return
        } else {
          const { sort, ...restFilters } = prevFilters as {
            status: string;
            sort: string;
          };
          return restFilters;
        }
      });
    }
  };

  const handleTaskFilterChange = (filterName: string, filterValue: string) => {
    if (filterName === 'status') {
      setTasksFilters((prevFilters) => {
        if (filterValue !== '') {
          return {
            ...prevFilters,
            status: filterValue,
          };
          // eslint-disable-next-line no-else-return
        } else {
          const { status, ...restFilters } = prevFilters as {
            status: string;
            report: string;
            sort: string;
          };

          return restFilters;
        }
      });
    } else if (filterName === 'report') {
      setTasksFilters((prevFilters) => {
        if (filterValue !== '') {
          return {
            ...prevFilters,
            report: filterValue,
          };
          // eslint-disable-next-line no-else-return
        } else {
          const { report, ...restFilters } = prevFilters as {
            status: string;
            report: string;
            sort: string;
          };
          return restFilters;
        }
      });
    } else if (filterName === 'sort') {
      setTasksFilters((prevFilters) => {
        if (filterValue !== '') {
          return {
            ...prevFilters,
            sort: filterValue,
          };
          // eslint-disable-next-line no-else-return
        } else {
          const { sort, ...restFilters } = prevFilters as {
            status: string;
            report: string;
            sort: string;
          };
          return restFilters;
        }
      });
    }
  };

  const onFilterGet = (filter: string, filterName: string) => {
    const filters: Filters =
      filter === 'matters' ? mattersFilters : tasksFilters;

    if (filter === 'matters') {
      return filters[filterName];
    } else {
      return filters[filterName];
    }
  };

  const fetchMatterFilteredData = async () => {
    try {
      let response;
      if (!currentUser) {
        response = await userMatterHistoryRequest(mattersFilters);
      } else {
        response = await userMatterHistoryRequest(
          mattersFilters,
          currentUser.username
        );
      }

      // Use the fetched data and update your component state
      setMatterHistoryData(response as MatterHistory[]);
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        setRequestError(error.response.data.message);
        setShowErrorNotification(true);
      }
    }
  };

  const fetchTasksFilteredData = async () => {
    try {
      let response;
      if (!currentUser) {
        response = await userTaskHistoryRequest(tasksFilters);
      } else {
        response = await userTaskHistoryRequest(
          tasksFilters,
          currentUser.username
        );
      }

      // Use the fetched data and update your component state
      setTaskHistoryData(response as TaskHistory[]);
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        setRequestError(error.response.data.message);
        setShowErrorNotification(true);
      }
    }
  };

  useEffect(() => {
    fetchMatterFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, mattersFilters]);

  useEffect(() => {
    fetchTasksFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, tasksFilters]);

  const listItemOnClick = (item: any) => {
    if (typeOfData === 'MATTERS') {
      navigate(`/matters/detail/${item?.id}`, {
        state: { item },
      });
    } else if (item.matterId !== '-') {
      navigate(`/matters/detail/${item.matterId}/tasks/${item.id}`, {
        state: { currentStep: 4, previous: 'user-details' },
      });
    }
  };

  return (
    <Box>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      <Box>
        <Box>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <ActivityHistorySortButtons
          typeOfData={typeOfData}
          onMatterFilterChange={handleFilterChange}
          onTaskFilterChange={handleTaskFilterChange}
          onFilterGet={onFilterGet}
        />
      </Box>
      {/* Matter History List  */}
      {((typeOfData === 'MATTERS' ? matterHistoryData : taskHistoryData) || [])
        .length === 0 ? (
        <Box
          sx={{
            height: '100%',
            width: '100',
            display: 'flex',
            justifyContent: 'center !important',
            alignItems: 'center !important',
          }}
        >
          <Typography
            sx={{ ...karlaProRegularItalicFontStyles, color: '#464B53' }}
          >
            No data found.
          </Typography>
        </Box>
      ) : (
        <List className="scrollable-content" sx={historyListWrapperStyles}>
          {/* History Card */}
          {(typeOfData === 'MATTERS'
            ? matterHistoryData
            : taskHistoryData
          )?.map((item: MatterHistory | TaskHistory, index: number) => {
            const dataLength =
              typeOfData === 'MATTERS'
                ? matterHistoryData?.length
                : taskHistoryData?.length || 0;
            const isLastItem = dataLength && index === dataLength - 1;

            return (
              <ListItem
                key={
                  typeOfData === 'MATTERS'
                    ? `${(item as MatterHistory).clientName}-${index}`
                    : `${(item as TaskHistory).taskName}-${index}`
                }
                onClick={() => listItemOnClick(item)}
              >
                <Box>
                  <Box>
                    <span className="material-icons-round">circle</span>
                  </Box>
                  <Box>
                    <ActivityHistoryListItemHeader
                      item={item}
                      typeOfData={typeOfData}
                    />
                    <ActivityHistoryListItemContent
                      item={item}
                      typeOfData={typeOfData}
                    />
                  </Box>
                </Box>
                {!isLastItem && (
                  <Divider
                    sx={{ border: '1px solid #D3ECF6', width: '100%' }}
                  />
                )}
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default ActivityHistoryList;
