/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, createFilterOptions, Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LEIAAAutoComplete, LEIAAInput } from '../../../inputs';
import NotificationToast from '../../../shared/toast/NotificationToast';
import {
  wikiTemplateFormWrapperStyles,
  wikiTemplateInputWrapperStyles,
  wikiTemplateWrapperStyles,
} from './styles';
import { InvestigationOptionType } from '../../../../types/wiki';
import { investigationTopicsRequest } from '../../../../api/wiki';

interface WikiTemplateProps {
  control: Control<FieldValues>;
}

const topicsFilter = createFilterOptions<InvestigationOptionType>();

const WikiTemplate = ({ control }: WikiTemplateProps) => {
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [topics, setTopics] = useState<readonly InvestigationOptionType[]>();

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const handleFetchInvestigationTopics = async () => {
    try {
      const response = await investigationTopicsRequest();
      setTopics(response as InvestigationOptionType[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  useEffect(() => {
    handleFetchInvestigationTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={wikiTemplateWrapperStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {/* FORM  */}
      <Box sx={wikiTemplateFormWrapperStyles}>
        <Typography>
          Create a new template to be used during the matter creation.
        </Typography>
        <Box sx={wikiTemplateInputWrapperStyles}>
          <Box>
            {/* TEMPLATE NAME INPUT  */}
            <LEIAAInput
              labelText="Name"
              controllerName="name"
              control={control}
              inputPlaceholder="Enter template name"
              inputWidth="100%"
              inputHeight="40px"
            />
            {/* INVESTIGATION TOPIC INPUT */}
            <LEIAAAutoComplete
              labelText="Investigation Topic"
              controllerName="investigation"
              control={control}
              autoCompleteFilter={topicsFilter}
              autoCompleteOptions={topics}
              inputPlaceholder="Enter investigation topic (e.g. Fraud, Theft, etc.)"
              inputWidth="100%"
              inputHeight="40px"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WikiTemplate;
