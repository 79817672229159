// useInterviewManagement.ts
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

const baseTimeObj = {
  value: 'Etc/GMT',
  label: '(GMT+0:00) UTC',
  offset: 0,
  abbrev: 'GMT',
  altName: 'British Standard Time',
};

const useInterviewManagement = (
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  setSelectedInterviewIndex: React.Dispatch<React.SetStateAction<number>>,
  matterAddedInterviewers?: any,
  selectedInterviewIndex?: number
) => {
  const handleAddMatterInterview = (category: any = '') => {
    const currentInterviews = watch('interviews');

    const updatedCurrentInterview = {
      id: uuidv4(),
      name: '',
      interview_type: '',
      interview_category: typeof category === 'string' ? category : '',
      description: '',
      instructions: '',
      questionnaire: [],
      interviewee_id: uuidv4(),
      whistleblower: false,
      transcription_enabled: true,
      interviewee_first_name: '',
      interviewee_last_name: '',
      interviewee_email: '',
      interviewee_organisation: '',
      interviewee_subsidiary: '',
      interviewee_job_title: '',
      interviewee_employee_id: '',
      timezone: `${baseTimeObj.abbrev}, ${baseTimeObj.label}; ${baseTimeObj.value}`,
    };

    const newInterviews =
      currentInterviews === undefined
        ? [updatedCurrentInterview]
        : [...currentInterviews, updatedCurrentInterview];

    setValue('interviews', newInterviews);

    if (!currentInterviews || currentInterviews.length === 0) {
      setSelectedInterviewIndex(0);
    } else if (newInterviews.length === 1) {
      setSelectedInterviewIndex(newInterviews.length);
    } else {
      setSelectedInterviewIndex(newInterviews.length - 1);
    }
  };

  const handleRemoveInterviewer = (id: string) => {
    const newInterviewers: any = matterAddedInterviewers.filter(
      (interviewerId: any) => interviewerId !== id
    );

    setValue(
      `interviews[${selectedInterviewIndex}].interviewer_ids`,
      newInterviewers
    );
  };

  const handleSelectInterview = (id: string, matterInterviews: any) => {
    const index = matterInterviews.findIndex(
      (interview: any) => interview.id === id
    );
    setSelectedInterviewIndex(index);
  };

  return {
    handleAddMatterInterview,
    handleRemoveInterviewer,
    handleSelectInterview,
  };
};

export default useInterviewManagement;
