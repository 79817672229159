import { Box, Typography } from '@mui/material';
import { AuthProps } from '../../types/authProps';
import Form from './form/Form';
import {
  formContentWrapperStyles,
  formHeaderWrapperStyles,
  formSubHeaderWrapperStyles,
} from './styles';

const TwoFactorAuthForm = ({
  handleSubmit,
  onSubmit,
  control,
  watch,
  errors,
}: AuthProps) => {
  const fields = [
    {
      name: 'two_factor_auth_code',
      label: 'Two-factor Code',
      inputPlaceholder: 'Enter two-factor code',
      type: 'password',
      required: true,
    },
  ];

  return (
    <>
      <Box sx={formHeaderWrapperStyles}>
        <Typography variant="h3">two-factor authentication</Typography>
      </Box>

      <Box sx={formContentWrapperStyles}>
        <Box sx={formSubHeaderWrapperStyles('inherit')}>
          <Typography variant="caption" component="p">
            We have sent you an authentication code to your email inbox. Please
            enter below.
          </Typography>
        </Box>
        <Form
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          fields={fields}
          submitButtonText="Authenticate"
          watch={watch}
          errors={errors}
        />
      </Box>
    </>
  );
};

export default TwoFactorAuthForm;
