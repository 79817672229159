import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMatterDraftRequest } from '../../../api/matters';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import { matterCreationConfirmationWrapperStyles } from './styles';
import NotificationToast from '../../shared/toast/NotificationToast';

interface MatterCreationConfirmationProps {
  isEdition: boolean;
  matter: any;
}

const MatterCreationConfirmation = ({
  isEdition,
  matter,
}: MatterCreationConfirmationProps) => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setShowNotification(true);
    }, 125);

    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, []);

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleGoToMattersDashboard = () => {
    navigate(`/matters/detail/${matter}`);
  };

  const handleGoToMatterCreation = async () => {
    try {
      const response = await createMatterDraftRequest();
      navigate(`/matters/new/${response.matter_id}`);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  return (
    <Box sx={matterCreationConfirmationWrapperStyles}>
      <Box>
        <Box>
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="200"
              viewBox="0 0 200 200"
              fill="none"
            >
              <rect width="200" height="200" rx="100" fill="#EBFBFB" />
              <g filter="url(#filter0_d_903_38774)">
                <path
                  d="M52.8233 157.556V42.4444C52.8233 41.1413 53.8783 40.0855 55.1808 40.0855H117.729L140.572 63.3948V110.656V159.914H55.1822C53.8794 159.914 52.8233 158.858 52.8233 157.556Z"
                  fill="#F0F8FD"
                  stroke="#E4F5FF"
                  strokeWidth="0.943535"
                />
                <path
                  d="M79.1099 78.0315C78.3639 78.0315 77.6179 77.7469 77.0489 77.1776L68.3032 68.4319C67.1646 67.2932 67.1646 65.4482 68.3032 64.3095C69.4419 63.1709 71.2869 63.1709 72.4256 64.3095L79.1099 70.9945L94.5399 55.5642C95.6785 54.4255 97.5236 54.4255 98.6622 55.5642C99.8009 56.7029 99.8009 58.5479 98.6622 59.6865L81.1709 77.1779C80.6016 77.7469 79.8556 78.0315 79.1099 78.0315Z"
                  fill="#6A9FFB"
                />
                <path
                  d="M117.762 39.6138V60.1768C117.762 61.7401 119.029 63.0074 120.592 63.0074H141.155L117.762 39.6138Z"
                  fill="#BADDEB"
                />
                <rect
                  width="58.3043"
                  height="51.6287"
                  transform="translate(67.4492 90.564)"
                  fill="#F0F8FD"
                />
                <path
                  d="M122.838 96.3946H70.3646C68.7533 96.3946 67.4492 95.0909 67.4492 93.4793C67.4492 91.8681 68.753 90.564 70.3646 90.564H122.838C124.449 90.564 125.754 91.8677 125.754 93.4793C125.753 95.0909 124.449 96.3946 122.838 96.3946Z"
                  fill="#BADDEB"
                />
                <path
                  d="M122.838 111.661H70.3646C68.7533 111.661 67.4492 110.357 67.4492 108.745C67.4492 107.134 68.753 105.83 70.3646 105.83H122.838C124.449 105.83 125.754 107.134 125.754 108.745C125.753 110.357 124.449 111.661 122.838 111.661Z"
                  fill="#BADDEB"
                />
                <path
                  d="M122.838 126.927H70.3646C68.7533 126.927 67.4492 125.623 67.4492 124.011C67.4492 122.4 68.753 121.096 70.3646 121.096H122.838C124.449 121.096 125.754 122.4 125.754 124.011C125.753 125.623 124.449 126.927 122.838 126.927Z"
                  fill="#BADDEB"
                />
                <path
                  d="M122.838 142.193H70.3646C68.7533 142.193 67.4492 140.889 67.4492 139.277C67.4492 137.666 68.753 136.362 70.3646 136.362H122.838C124.449 136.362 125.754 137.666 125.754 139.277C125.753 140.889 124.449 142.193 122.838 142.193Z"
                  fill="#BADDEB"
                />
                <rect
                  x="109.908"
                  y="122.645"
                  width="37.7414"
                  height="37.7414"
                  rx="18.8707"
                  fill="#6A9FFB"
                />
                <path
                  d="M128.487 144.34H126.212L126.187 143.465C126.087 141.265 127.062 140.34 128.687 139.74L129.512 139.415C130.587 139.04 131.387 138.09 131.387 136.89C131.387 135.24 130.337 134.29 128.862 134.29C127.462 134.29 126.087 135.34 126.012 137.24H123.687C123.687 133.99 126.137 131.99 128.887 131.99C131.637 131.99 133.812 133.865 133.812 136.865C133.812 139.29 132.187 141.065 130.262 141.69C129.237 142.015 128.512 142.315 128.512 143.465L128.487 144.34ZM127.337 149.74C126.387 149.74 125.587 149.04 125.587 148.09C125.587 147.14 126.387 146.44 127.337 146.44C128.287 146.44 129.062 147.14 129.062 148.09C129.062 149.04 128.287 149.74 127.337 149.74Z"
                  fill="white"
                />
                <path
                  d="M112.322 158.645L114.085 152.555C114.213 152.114 114.761 151.961 115.099 152.273L119.266 156.119C119.604 156.431 119.495 156.99 119.065 157.152L113.135 159.396C112.658 159.576 112.18 159.135 112.322 158.645Z"
                  fill="#6A9FFB"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_903_38774"
                  x="37.3516"
                  y="19.6138"
                  width="135.299"
                  height="160.772"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="5" />
                  <feGaussianBlur stdDeviation="10" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0815698 0 0 0 0 0.0411458 0 0 0 0 0.329167 0 0 0 0.1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_903_38774"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_903_38774"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={secondaryButtonStyles()}
              onClick={handleGoToMattersDashboard}
            >
              <span className="material-icons-round">people</span>
              <Typography>View Matter</Typography>
            </Button>
            <Button
              variant="contained"
              sx={primaryButtonStyles()}
              onClick={handleGoToMatterCreation}
            >
              <span className="material-icons-round">add</span>
              <Typography>Add New Matter</Typography>
            </Button>
          </Box>
        </Box>
        <CreationNotification
          title={
            !isEdition
              ? 'New matter created successfully!'
              : 'Matter updated successfully!'
          }
          subTitle={
            !isEdition
              ? 'You can now access your new matter.'
              : 'You can now access your updated matter.'
          }
          showNotification={showNotification}
          handleCloseNotification={handleCloseNotification}
        />
      </Box>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
    </Box>
  );
};

export default MatterCreationConfirmation;
