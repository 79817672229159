/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotificationToast from '../../../shared/toast/NotificationToast';
import {
  wikiQuestionnaireAddButtonWrapperStyles,
  wikiTemplateFormWrapperStyles,
  wikiTemplateInputWrapperStyles,
  wikiTemplateQuestionInputWrapperStyles,
  wikiTemplateWrapperStyles,
} from './styles';
import { secondaryButtonStyles } from '../../../styles';
import { LEIAAInput, LEIAASelect } from '../../../inputs';
import { InterviewQuestion, InterviewTemplate } from '../../../../types/wiki';
import {
  getWikiTemplateDetailsRequest,
  getWikiTemplateRequest,
} from '../../../../api/wiki';
import { SidePanelInformation } from '../../../matters/matter-creation/sidepanel-information';
import TemplateAddedQuestionsInformation from '../../sidepanel-information';
import FormStatusChip from '../../../shared/misc-components/FormStatusChip';

interface WikiTemplateProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

const WikiWitnessInterview = ({
  control,
  watch,
  setValue,
}: WikiTemplateProps) => {
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const interviewQuestions = watch('witnessQuestions');
  const editInterviewQuestions = watch('interviewQuestions');
  const [templatesAvailable, setTemplatesAvailable] =
    useState<InterviewQuestion[]>();
  const { templateId } = useParams();
  const [pickedType, setPickedType] = useState('');

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  const handleFetchTemplatesAvailable = async () => {
    try {
      const response = await getWikiTemplateRequest();
      setTemplatesAvailable(response as InterviewQuestion[]);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchTemplate = async () => {
    try {
      if (templateId) {
        await getWikiTemplateDetailsRequest(templateId).then((response) => {
          setValue('interviewQuestions', response.interview_template);
        });
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleFetchTemplatesWitnessInterviewQuestions = () => {
    if (editInterviewQuestions) {
      const witnessQuestions = editInterviewQuestions.filter(
        (question: any) => question.category === 'Witness'
      );

      const updatedWitnessQuestions = witnessQuestions.map((question: any) => ({
        id: question.id,
        type: question.type,
        question: question.question,
        template: '',
      }));

      if (
        updatedWitnessQuestions &&
        updatedWitnessQuestions[0] &&
        updatedWitnessQuestions[0].type
      ) {
        setPickedType(updatedWitnessQuestions[0].type);
      }

      setValue('witnessQuestions', updatedWitnessQuestions);
    }
  };

  useEffect(() => {
    handleFetchTemplatesAvailable();
    handleFetchTemplate();
    handleFetchTemplatesWitnessInterviewQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTemplateChange = (
    event: React.ChangeEvent<{ value: any }>,
    index: number
  ) => {
    const templateName = event.target.value;
    const template = templatesAvailable?.find((t) => t.name === templateName);

    const currentQuestions = watch('witnessQuestions');
    const updatedCurrentQuestions: InterviewTemplate[] = [];

    if (
      template &&
      template.interview_template &&
      template.interview_template.length > 0
    ) {
      let foundWitnessCategory = false;

      template.interview_template.forEach((iq: InterviewQuestion) => {
        if (iq.category === 'Witness') {
          foundWitnessCategory = true;
          const updatedQuestion: InterviewTemplate = {
            id: uuidv4(),
            type: iq.type,
            question: iq.question,
            template: currentQuestions[0].template,
          };

          setPickedType(updatedQuestion.type || '');

          updatedCurrentQuestions.push(updatedQuestion);
        }
      });

      if (!foundWitnessCategory) {
        updatedCurrentQuestions.push({
          id: uuidv4(),
          type: '',
          question: '',
          template: currentQuestions[0].template,
        });
      }
    } else {
      updatedCurrentQuestions.push({
        id: uuidv4(),
        type: pickedType,
        question: '',
        template: currentQuestions[0].template,
      });
    }

    setValue('witnessQuestions', updatedCurrentQuestions);

    if (!template) {
      setValue(`witnessQuestions[${index}].template`, '');
    }
  };

  const handleAddInterviewQuestion = () => {
    // Add the current task to the tasks array in the form payload
    const currentQuestions = watch('witnessQuestions');

    const updatedCurrentQuestion = {
      id: uuidv4(),
      type: pickedType,
      question: '',
      template: '',
    };

    const newQuestions =
      currentQuestions === undefined
        ? [updatedCurrentQuestion]
        : [...currentQuestions, updatedCurrentQuestion];

    setValue('witnessQuestions', newQuestions);

    if (!interviewQuestions || interviewQuestions.length === 0) {
      setSelectedIndex(0);
    } else if (newQuestions.length === 1) {
      setSelectedIndex(newQuestions.length);
    } else {
      setSelectedIndex(newQuestions.length - 1);
    }
  };

  const handleRemoveInterviewQuestion = (id: string | number) => {
    const newQuestionValue = watch('witnessQuestions').filter(
      (mt: InterviewQuestion) => mt.id !== id
    );
    if (typeof id === 'number') {
      const questionsIdsToDelete = watch('questions_to_delete') || [];
      setValue('questions_to_delete', [...questionsIdsToDelete, id]);
    }
    setValue('witnessQuestions', newQuestionValue);
  };

  useEffect(() => {
    // Update the selectedIndex to point to the last task if it exceeds the valid index range
    if (interviewQuestions && interviewQuestions.length > 0) {
      if (selectedIndex >= interviewQuestions.length) {
        setSelectedIndex(interviewQuestions.length - 1);
      }
    }
  }, [interviewQuestions, selectedIndex]);

  const handleSelectQuestion = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={wikiTemplateWrapperStyles}>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      {/* FORM  */}
      <Box sx={wikiTemplateFormWrapperStyles}>
        <Typography>
          Create a set of questions for a witness interview.
        </Typography>
        <Box sx={wikiTemplateInputWrapperStyles}>
          <Box sx={wikiQuestionnaireAddButtonWrapperStyles}>
            {interviewQuestions && interviewQuestions.length > 0
              ? interviewQuestions.map(
                  (mt: InterviewQuestion, index: number) => {
                    return (
                      selectedIndex === index && (
                        <Box
                          key={`${mt.id}-${index}`}
                          sx={wikiTemplateQuestionInputWrapperStyles}
                        >
                          <Box>
                            <LEIAASelect
                              labelText="Witness Interview Template"
                              controllerName={`witnessQuestions[${index}].template`}
                              control={control}
                              required={false}
                              inputPlaceholder="Select from a previous template"
                              inputWidth="100%"
                              inputHeight="40px"
                              handleChange={handleTemplateChange}
                            >
                              {templatesAvailable &&
                              templatesAvailable?.length > 0
                                ? templatesAvailable?.map((tm: any) => (
                                    <MenuItem key={tm.id} value={tm.name}>
                                      {tm.name}
                                    </MenuItem>
                                  ))
                                : null}
                            </LEIAASelect>
                          </Box>
                          <Box>
                            <LEIAASelect
                              labelText="Interview Type"
                              controllerName={`witnessQuestions[${index}].type`}
                              control={control}
                              required={false}
                              inputPlaceholder="Select an interview type"
                              inputHeight="40px"
                              inputWidth="100%"
                            >
                              <MenuItem
                                onClick={() => setPickedType('In-person')}
                                value="In-person"
                              >
                                In-person
                              </MenuItem>
                              <MenuItem
                                onClick={() => setPickedType('Automated')}
                                value="Automated"
                              >
                                Automated
                              </MenuItem>
                            </LEIAASelect>
                          </Box>
                          <Box>
                            <LEIAAInput
                              labelText="Question"
                              controllerName={`witnessQuestions[${index}].question`}
                              control={control}
                              required={false}
                              inputPlaceholder="Enter question"
                              inputWidth="100%"
                              inputHeight="40px"
                            />
                          </Box>
                          <FormStatusChip
                            trigger={watch(
                              `witnessQuestions[${index}].question`
                            )}
                          />
                        </Box>
                      )
                    );
                  }
                )
              : null}
            {interviewQuestions === undefined ||
            interviewQuestions.length === 0 ? (
              <Button
                variant="contained"
                sx={{
                  ...secondaryButtonStyles(),
                  height: '30px',
                  padding: '8px 16px',
                  gap: '8px',
                }}
                onClick={handleAddInterviewQuestion}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add new question</Typography>
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box>
        <SidePanelInformation>
          {interviewQuestions && interviewQuestions.length > 0 && (
            <TemplateAddedQuestionsInformation
              selectedIndex={selectedIndex}
              interviewQuestions={interviewQuestions}
              handleSelectQuestion={handleSelectQuestion}
              handleRemoveInterviewQuestion={handleRemoveInterviewQuestion}
              addInterviewQuestionBtn={
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    height: '30px',
                    padding: '8px 16px',
                    gap: '8px',
                  }}
                  onClick={handleAddInterviewQuestion}
                >
                  <span className="material-icons-round">add</span>
                  <Typography>Add new question</Typography>
                </Button>
              }
            />
          )}
        </SidePanelInformation>
      </Box>
    </Box>
  );
};

export default WikiWitnessInterview;
