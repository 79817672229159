import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  matterDetailImanageAuthRequest,
  matterDetailImanageConfigRequest,
  matterDetailImanageGetMattersRequest,
} from '../../api/matters';
import { LEIAADialog, LEIAAInput, LEIAASelect } from '../inputs';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
} from '../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  secondaryButtonStyles,
  primaryButtonStyles,
  tertiaryButtonStyles,
} from '../styles';
import { karlaProRegularFontStyles } from '../../styles/textStyles';

interface ImanageMatterPickerProps {
  matterId: any;
  matterData: any;
  open: any;
  setOpen: any;
  setShowUpdateNotification: any;
}

const ImanageMatterPicker = ({
  matterId,
  matterData,
  open,
  setOpen,
  setShowUpdateNotification,
}: ImanageMatterPickerProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [content, setContent] = useState('form');
  const [errorMessage, setErrorMessage] = useState('form');
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [imanageMatters, setImanageMatters] = useState<any>([]);

  const handleClose = () => {
    setContent('form');
    setValue('username', '');
    setValue('password', '');
    setValue('matterPicker', false);

    if (
      errorMessage !== 'Incorrect iManage Credentials' ||
      content === 'success'
    ) {
      setOpen(false);
    }
  };

  const handleSetImanageMatter = (event: React.ChangeEvent<{ value: any }>) => {
    const imanageMatterId = event.target.value;
    const matterObj = imanageMatters?.find(
      (m: any) => m.id === imanageMatterId
    );
    setValue('imanageMatterId', matterObj.id);
    setValue('imanageMatterDatabase', matterObj.database);
    setValue('imanageMatterName', matterObj.name);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    try {
      if (watch('matterPicker')) {
        await matterDetailImanageConfigRequest(matterId, data);
        handleClose();
        matterData.imanage_matter_ida = data.imanageMatterId;
        matterData.imanage_library_id = data.imanageMatterDatabasem;
        matterData.imanage_matter_name = data.imanageMatterName;
        setShowUpdateNotification(true);
      } else {
        setContent('loading');
        const authResponse = await matterDetailImanageAuthRequest(
          data,
          matterId
        );
        const matterResponse = await matterDetailImanageGetMattersRequest({
          token: authResponse.token,
          customer: authResponse.customer,
          matter: matterId,
        });
        setImanageMatters(matterResponse.matters);
        setContent('success');
      }
    } catch (error: any) {
      setContent('error');
      setErrorMessage(error.response.data.message);
    }
  };

  const handleSubmitPick = () => {
    setValue('matterPicker', true);
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    setValue('imanageMatterId', matterData.imanage_matter_id);
    setValue('imanageMatterDatabase', matterData.imanage_library_id);
    setValue('imanageMatterName', matterData.imanage_matter_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    switch (content) {
      case 'loading':
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Authenticating...
            </Typography>
          </Stack>
        );
      case 'form':
        return (
          <>
            <Typography
              id="modal-modal-title"
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Use your iManage credentials to browse matters.
              <br />
              <span style={{ fontSize: '12px', color: 'gray' }}>
                We do not store your credentials.
              </span>
            </Typography>

            <Box sx={matterInformationWrapperStyles}>
              <Box
                sx={{
                  ...matterInformationFormWrapperStyles,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    ...matterInformationInputWrapperStyles,
                    gap: '10px !important',
                  }}
                >
                  <Box>
                    <LEIAAInput
                      labelText="Username"
                      controllerName="username"
                      control={control}
                      inputPlaceholder="Enter Imanage username"
                      inputWidth="100%"
                      inputHeight="40px"
                    />
                  </Box>
                  <Box>
                    <LEIAAInput
                      labelText="Password"
                      controllerName="password"
                      control={control}
                      inputPlaceholder="Enter Imanage password"
                      inputWidth="100%"
                      inputHeight="40px"
                      type="password"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <LEIAADialog
              open={openAlertOpenDialog}
              onClose={() => setOpenAlertOpenDialog(false)}
              title="alert"
              description="error"
              simpleButtonText="Ok"
              onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
              isTextAlign
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ width: '100%', marginTop: '35px !important' }}
            >
              <Button
                variant="contained"
                sx={{ ...secondaryButtonStyles(), width: '100%' }}
                onClick={() => setOpen(false)}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                sx={{ ...primaryButtonStyles(), width: '100%' }}
                onClick={handleSubmit(onSubmit)}
              >
                <Typography>Connect</Typography>
                <span className="material-icons-round">settings_remote</span>
              </Button>
            </Stack>
          </>
        );
      case 'error':
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0.5}
          >
            <Typography
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '22px',
                lineHeight: '22px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <span
                className="material-icons-round"
                style={{
                  fontSize: '30px',
                  color: '#E22727',
                  verticalAlign: 'middle',
                  marginRight: '10px',
                }}
              >
                error
              </span>
              {errorMessage}
            </Typography>
            <Button
              variant="contained"
              sx={{ ...tertiaryButtonStyles(), width: '100%' }}
              onClick={handleClose}
            >
              <Typography>Go Back</Typography>
            </Button>
          </Stack>
        );
      case 'success':
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ width: '100%' }}
          >
            <Typography
              id="modal-modal-title"
              sx={{
                ...karlaProRegularFontStyles,
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '22px',
                textAlign: 'center',
              }}
            >
              Select iManage matter
            </Typography>
            <Box sx={{ width: '50%' }}>
              <LEIAASelect
                labelText=""
                control={control}
                controllerName="imanageMatterId"
                inputPlaceholder="Select matter"
                inputHeight="40px !important"
                inputWidth="100%"
                paperCustomStylings={{ height: '300px' }}
                handleChange={handleSetImanageMatter}
              >
                {imanageMatters?.map((m: any) => {
                  return (
                    <MenuItem key={m.id} value={m.id}>
                      {m.name}
                    </MenuItem>
                  );
                })}
              </LEIAASelect>
            </Box>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              sx={{ width: '50%' }}
            >
              <Button
                variant="contained"
                sx={{ ...secondaryButtonStyles(), width: '50%' }}
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                sx={{ ...primaryButtonStyles(), width: '50%' }}
                onClick={handleSubmitPick}
              >
                <Typography>Select</Typography>
                <span className="material-icons-round">fact_check</span>
              </Button>
            </Stack>
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '736px',
            padding: '48px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '24px',
            border: '1px solid #D3ECF6',
            background: '#FFF',
            boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {renderContent()}
          </Stack>
        </Box>
      </Modal>
    </form>
  );
};

export default ImanageMatterPicker;
