import axios from 'axios';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import LEIAALogo1 from '../../assets/LEIAALogo1.png';
import { loginRequest, twoFactorAuthenticationRequest } from '../../api/auth';
import LoginAuthForm from '../../components/login/LoginAuthForm';
import TwoFactorAuthForm from '../../components/login/TwoFactorAuthForm';
import { LoginData, TwoFactorAuthData } from '../../types/auth';
import { LoginError } from '../../types/authProps';
import { createErrorObject } from '../../utils/utils';
import {
  mainLoginContainerStyles,
  mainFormWrapperStyles,
} from '../styles/login';

const ReportingFollowLogin = () => {
  const [tempToken, setTempToken] = useState<string | null>('');
  const [errorsObject, setErrorsObject] = useState<LoginError>();
  const [isTwoFactorAuth, setIsTwoFactorAuth] = useState(false);

  const {
    handleSubmit: handleSubmitLogin,
    control: controlLogin,
    watch,
  } = useForm();
  const {
    handleSubmit: handleSubmitTwoFactor,
    control: controlTwoFactor,
    watch: watchTwoFactor,
  } = useForm();

  const navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate('/reset-password');
  };

  const navigateToReport = () => {
    navigate(`/reporting-follow-up-dashboard/${watch('username')}`);
  };

  const onSubmitLogin: SubmitHandler<LoginData | FieldValues> = async (
    data
  ) => {
    try {
      data.reporting = true;
      const response = await loginRequest(data as LoginData);
      if (response?.reportingAuthToken) {
        localStorage.setItem('authToken', response.reportingAuthToken);
        navigateToReport();
      } else {
        setTempToken(response?.tempToken);
        setIsTwoFactorAuth(true);
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        const errors = createErrorObject(error.response.data.message);
        setErrorsObject(errors);
      }
    }
  };

  const onSubmitTwoFactor: SubmitHandler<
    TwoFactorAuthData | FieldValues
  > = async (data) => {
    try {
      // eslint-disable-next-line no-param-reassign
      data.temp_token = tempToken;
      await twoFactorAuthenticationRequest(data as TwoFactorAuthData);
      navigateToReport();
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        const errors = createErrorObject(
          error.response.data.message,
          'two_factor_auth_code'
        );
        setErrorsObject(errors);
      }
    }
  };

  const renderForm = () => {
    switch (true) {
      case isTwoFactorAuth:
        return (
          <TwoFactorAuthForm
            handleSubmit={handleSubmitTwoFactor}
            onSubmit={onSubmitTwoFactor}
            control={controlTwoFactor}
            watch={watchTwoFactor}
            errors={errorsObject}
          />
        );
      default:
        return (
          <LoginAuthForm
            handleSubmit={handleSubmitLogin}
            onSubmit={onSubmitLogin}
            control={controlLogin}
            watch={watch}
            handleForgotPassword={handleForgotPassword}
            errors={errorsObject}
            reportingFollowUp
          />
        );
    }
  };

  return (
    <Box sx={mainLoginContainerStyles}>
      <Box sx={mainFormWrapperStyles}>
        <Box>
          <Box>
            <img
              src={
                process.env.REACT_APP_REPORTING_WHITELABEL === 'true'
                  ? process.env.REACT_APP_REPORTING_WHITELABEL_LOGO
                  : LEIAALogo1
              }
              style={{ width: '228.12px', height: 'auto' }}
              alt="Enterprise logo"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0px',
              gap: isTwoFactorAuth ? '48px' : '24px',
              width: '352px',
              '> form': {
                width: '100%',
              },
            }}
          >
            {renderForm()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportingFollowLogin;
