import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAssignedMattersRequest,
  matterDetailsRequest,
} from '../../../api/matters';
import DropdownMenuIcon from '../../../components/matters/matter-creation/matter-creation-steps/DropdownMenuIcon';
import {
  matterInformationSelectPaperStyles,
  matterInformationSelectStyles,
} from '../../../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import {
  secondaryButtonStyles,
  primaryButtonStyles,
} from '../../../components/styles';
import {
  sofiaProBoldFontStyles,
  karlaProRegularFontStyles,
} from '../../../styles/textStyles';
import {
  taskCreationDialogContentStyles,
  taskCreationialogWrapperStyles,
} from './taskstyles';

export interface TaskCreationDialogProps {
  open: boolean;
  onClose: () => void;
  generalTaskCreation: any;
}

const TaskCreationDialog = ({
  open,
  onClose,
  generalTaskCreation,
}: TaskCreationDialogProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [value, setValue] = useState('');
  const [assignedMatters, setAssignedMatters] = useState<any[] | null>(null);
  const [showMatterSelection, setShowMatterSelection] = useState(false);
  const navigate = useNavigate();

  const handleFetchAssignedMatters = async () => {
    try {
      const response = await getAssignedMattersRequest();
      // if none - redirect to add single page
      setAssignedMatters(response);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    setValue(event.target.value);
    matterDetailsRequest(event.target.value.toString()).then((response) => {
      navigate(`/matter/addTask/${event.target.value}`, {
        state: { currentStep: 4, matterDetails: response },
      });
    });
  };

  useEffect(() => {
    handleFetchAssignedMatters();
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={taskCreationialogWrapperStyles}
    >
      <Box sx={taskCreationDialogContentStyles()}>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ '& > span': { color: '#464B53' } }}
          >
            <span className="material-icons-round">close</span>
          </IconButton>
        </Box>
        <Box>
          <Box>
            <Typography
              sx={{
                color: '#202020',
                ...sofiaProBoldFontStyles,
                fontSize: '18px',
                lineHeight: 'normal',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              Choose type of task
            </Typography>
          </Box>
          <Box>
            <Box>
              {assignedMatters && showMatterSelection && (
                <Select
                  displayEmpty
                  labelId="leiaa-simple-select-label"
                  id="leiaa-simple-select"
                  size="small"
                  open={menuOpen}
                  onClose={handleMenuClose}
                  onOpen={handleMenuOpen}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  IconComponent={() => (
                    <DropdownMenuIcon
                      isMenuOpen={menuOpen}
                      handleDropdownClientIconClick={handleMenuOpen}
                      isAdornment={false}
                    />
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        ...matterInformationSelectPaperStyles,
                        maxHeight: '20vw',
                        top: '47%',
                      },
                    },
                  }}
                  sx={matterInformationSelectStyles('40px !important', '100%')}
                  value={value}
                  onChange={handleChange}
                >
                  <MenuItem disabled value="">
                    <Typography
                      sx={{
                        ...karlaProRegularFontStyles,
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '22px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#69707B',
                      }}
                    >
                      Select matter...
                    </Typography>
                  </MenuItem>
                  {assignedMatters.map((matter: any) => {
                    return (
                      <MenuItem key={matter.id} value={matter.id}>
                        {matter.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Box>

            <Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...secondaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={() => generalTaskCreation()}
                >
                  <span className="material-icons-round">description</span>
                  <Typography>General Task</Typography>
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    ...primaryButtonStyles(),
                    width: '100%',
                  }}
                  onClick={() => setShowMatterSelection(true)}
                >
                  <span className="material-icons-round">folder</span>
                  <Typography>Matter Task</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default TaskCreationDialog;
