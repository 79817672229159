import {
  karlaProRegularFontStyles,
  sofiaProRegularFontStyles,
} from '../../styles/textStyles';

export const loginFormButtonStyles = (buttonText: string) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 20px',
    gap: '8px',
    width: '352px',
    height: '48px',
    backgroundColor: '#0053FF',
    color: '#FFFFFF !important',
    borderRadius: '16px',
    marginTop: buttonText === 'Login' ? '40px' : '80px',
    textTransform: 'none',
    '&.Mui-disabled': {
      backgroundColor: '#5781D9',
    },
    '&:focus': {
      border: '1px solid #A962D5',
      boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    },
    '&:hover': {
      backgroundColor: '#0B25B0',
    },
    '&:active': {
      backgroundColor: '#140070',
    },
  };
};

export const forgotPasswordButtonStyles = {
  marginTop: '8px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '8px',
  width: '148px',
  height: '30px',
  '&:focus': {
    boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
    borderRadius: '12px',
  },
  '&:hover': {
    '& > p': {
      color: '#0B25B0',
    },
  },
  '&:active': {
    '& > p': {
      color: '#140070',
    },
  },
  '& > p': {
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#0053FF',
    textTransform: 'none',
  },
};

export const formInputWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  '& > label': {
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    color: '#464B53',
    padding: '0 12px',
  },
};

export const loginFormInputStyles = (width: string, height: string) => {
  return {
    background: 'white',
    margin: '0px',
    borderWidth: '1px',
    '&:focus-within': {
      borderColor: '#0053FF',
    },
    '&:focus-within > div > fieldset': {
      borderColor: '#0053FF !important',
      borderWidth: '1px !important',
    },
    '&:focus-within > div.Mui-error > fieldset': {
      borderColor: '#E22727 !important',
      borderWidth: '1px !important',
    },
    '& input, & textarea': {
      caretColor: '#0053FF',
    },
    '& > div': {
      width,
      height,
      padding: '0px !important',
      '&:has(textarea)': {
        padding: '8px 12px !important',
      },
      '& > textarea': {
        overflow: 'hidden scroll !important',
        height: '100% !important',
      },
      '& > input': {
        padding: '0px 12px !important',
        borderRadius: '14px !important',
      },
      '& > div.MuiInputAdornment-positionStart': {
        '& > button': {
          padding: 0,
          marginLeft: '12px',
          '& > svg': {
            height: '24px',
            width: '24px',
          },
        },
      },
      '& > input, & > textarea': {
        width,
        height,
        ...karlaProRegularFontStyles,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#202020',
        '&:hover': {
          cursor: 'default',
        },
        '::placeholder': {
          ...karlaProRegularFontStyles,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '22px',
          display: 'flex',
          alignItems: 'center',
          color: '#69707B',
        },
      },
      '& > fieldset': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        borderWidth: '1px',
        border: '1px solid #32353C',
        borderRadius: '14px',
        flex: 'none',
        order: 1,
        alignSelf: 'stretch',
        flexGrow: 0,
      },
    },
    '& > div.Mui-error': {
      '& > input, & > textarea': {
        color: '#202020',
      },
      '& > fieldset': {
        backgroundColor: 'rgba(226, 39, 39, 0.04)',
        border: '1px solid #E22727',
      },
    },
    '& > p.MuiFormHelperText-root.Mui-error': {
      ...karlaProRegularFontStyles,
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.2px',
      color: '#E22727',
    },
  };
};

export const formSuccessButtonStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 20px',
  gap: '8px',
  width: '352px',
  height: '48px',
  backgroundColor: '#0053FF',
  '&.Mui-disabled': {
    backgroundColor: '#5781D9',
  },
  '&:focus': {
    border: '1px solid #A962D5',
    boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
  },
  '&:hover': {
    backgroundColor: '#0B25B0',
  },
  '&:active': {
    backgroundColor: '#140070',
  },
  borderRadius: '16px',
  textTransform: 'none',
};

export const loginInstructionsListWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '352px',
  padding: '0px 0px 0px 17px',
  '& > ul': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '0px 0px 0px 12px',
    margin: '8px 0px 0px 0px',
    listStyleType: 'disc',
    '& > li': {
      padding: '0px',
      margin: '0px',
      ...karlaProRegularFontStyles,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
};

export const formHeaderWrapperStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '10px 0px',
  gap: '5px',
  width: '352px',
  height: '52px',
  '& > h3': {
    ...sofiaProRegularFontStyles,
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    color: '#202020',
  },
  '& > p': {
    ...karlaProRegularFontStyles,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    color: '#202020',
  },
};

export const formSubHeaderWrapperStyles = (heightValue: string) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px 0px',
    gap: '5px',
    width: '352px',
    height: heightValue,
    '& > p': {
      ...karlaProRegularFontStyles,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      display: 'flex',
      alignItems: 'center',
      color: '#202020',
    },
  };
};

export const formContentWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '24px',
};

export const formSuccessMessageWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px',
  gap: '24px',
  width: '352px',
  height: '311px',
};
