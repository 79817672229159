import { Box } from '@mui/material';
import ScheduledResources from './ScheduledResources';

const MyCalendar = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
      }}
    >
      <Box>
        <ScheduledResources page="my-dashboard" />
      </Box>
    </Box>
  );
};

export default MyCalendar;
