import { Box } from '@mui/material';
import React from 'react';

type EmptyWeekDaysProps = {
  count: number;
};

const EmptyWeekDays = ({ count }: EmptyWeekDaysProps) => (
  <>
    {Array.from({ length: count }).map((_, index) => (
      <Box
        key={`empty-${index}`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0px',
          gap: '16px',
          width: '100%',
        }}
      />
    ))}
  </>
);

export default EmptyWeekDays;
