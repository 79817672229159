/* eslint-disable react/prop-types */
import React from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { Box, CircularProgress } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

interface PDFdisplayProps {
  byteStream: any;
  currentPage: any;
  setTotalNumPages: any;
  totalNumPages: any;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const LoadingBox = () => (
  <Box
    sx={{
      height: '500px',
      width: '611px',
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);

const PDFdisplay: React.FC<PDFdisplayProps> = React.memo(function PDFDisplay({
  byteStream,
  currentPage,
  setTotalNumPages,
  totalNumPages,
}) {
  // pdf viewer set up
  const onDocumentLoadSuccess = (document: any) => {
    const { numPages } = document;
    if (totalNumPages !== numPages) setTotalNumPages(numPages);
  };

  const pdfBlob = new Blob([byteStream], { type: 'application/pdf' });
  const pdfUrl = URL.createObjectURL(pdfBlob);

  return (
    <Box
      sx={{
        borderRadius: '16px',
        backgroundColor: 'white',
        overflowY: 'scroll',
        height: '500px',
        width: '611px',
        alignSelf: 'center',
      }}
      className="scrollable-content"
    >
      {byteStream ? (
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<LoadingBox />}
          error={<LoadingBox />}
        >
          <Page pageNumber={currentPage} />
        </Document>
      ) : (
        <LoadingBox />
      )}
    </Box>
  );
});

PDFdisplay.displayName = 'PDFdisplay'; // Add a display name

export default PDFdisplay;
