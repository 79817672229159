import { Box, InputLabel, TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { loginFormInputStyles } from '../login/styles';

interface LEIAAInputProps {
  labelText?: string;
  controllerName: string;
  control: Control<FieldValues>;
  inputPlaceholder: string;
  inputWidth: string;
  inputHeight: string;
  type?: string;
  required?: boolean;
  multiline?: boolean;
  handleChange?: any;
  inputValue?: string;
  customClassName?: string;
  disabled?: boolean;
}

// leia input
const LEIAAInput = ({
  labelText,
  controllerName,
  control,
  inputPlaceholder,
  inputWidth,
  inputHeight,
  type = 'text',
  required = true,
  multiline = false,
  handleChange,
  inputValue = '',
  customClassName = '',
  disabled = false,
}: LEIAAInputProps) => {
  return (
    <Box>
      {labelText && (
        <InputLabel data-testid="matter-form-label-id" htmlFor={labelText}>
          {required ? '*' : ''}
          {labelText}
        </InputLabel>
      )}
      <Controller
        name={controllerName}
        control={control}
        defaultValue={inputValue}
        render={({ field: { onChange, ...rest } }) => {
          return (
            <TextField
              {...rest}
              placeholder={inputPlaceholder}
              inputProps={{
                'data-testid': 'form-text-field-id',
                inputMode: type === 'number' ? 'numeric' : 'text',
                className: customClassName,
              }}
              required={required}
              onChange={(e) => {
                onChange(e.target.value);
                if (handleChange) {
                  handleChange(e.target.value);
                }
              }}
              autoComplete="off"
              type={type}
              size="small"
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                ...loginFormInputStyles(inputWidth, inputHeight),
              }}
              multiline={multiline}
              disabled={disabled}
            />
          );
        }}
      />
    </Box>
  );
};

export default LEIAAInput;
