import { Box, Typography } from '@mui/material';
import { historyListItemContentWrapperStyles } from './styles';
import { MatterHistory, TaskHistory } from '../../types/matters';
import MatterStatusChip from './MatterStatusChip';
import StatusChipTasks from '../shared/matters-list-table/StatusChipTasks';

interface ActivityHistoryListItemContentProps {
  item: MatterHistory | TaskHistory;
  typeOfData: 'MATTERS' | 'TASKS';
}

const ActivityHistoryListItemContent = ({
  item,
  typeOfData,
}: ActivityHistoryListItemContentProps) => {
  return (
    <Box sx={historyListItemContentWrapperStyles}>
      <Typography variant="caption" component="p">
        {item.matterName}
      </Typography>
      {typeOfData === 'MATTERS' ? (
        <MatterStatusChip item={item as MatterHistory} />
      ) : (
        <StatusChipTasks
          status={(item as TaskHistory).status}
          heightValue="24px"
          wrapperHeight="inherit"
        />
      )}
    </Box>
  );
};

export default ActivityHistoryListItemContent;
