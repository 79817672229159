import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Modal,
} from '@mui/material';
import { uploadPortalRequest } from '../api/matters';
import { LEIAAUploadFileArea, LEIAADialog } from '../components/inputs';
import {
  matterInformationWrapperStyles,
  matterInformationFormWrapperStyles,
  matterInformationInputWrapperStyles,
  matterQuestionnaireUploadAreaWrapperStyles,
} from '../components/matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { MatterAddedDocumentsInformation } from '../components/matters/matter-creation/sidepanel-information';
import NotificationToast from '../components/shared/toast/NotificationToast';
import { primaryButtonStyles } from '../components/styles';
import { sofiaProBoldFontStyles } from '../styles/textStyles';
import LEIAALogo1 from '../assets/LEIAALogo1.png';

const ExternalFileUploadPortal = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const { token } = useParams();
  const templateReport = watch('files');
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [openUploadCompleteDialog, setOpenUploadCompleteDialog] =
    useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      setLoading(true);
      await uploadPortalRequest(data, token);
      setOpenUploadCompleteDialog(true);

      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setValue('files', []);
    }
  };

  const handleUploadFiles = (fileList: FileList) => {
    let existingFiles: any[] = [];

    if (fileList.length === 0) return;

    if (templateReport && templateReport.length > 0) {
      existingFiles = templateReport.filter((md: any) =>
        Array.from(fileList).some(
          (selectedFile) => md.name === selectedFile.name
        )
      );
    }

    if (existingFiles.length > 0) {
      setOpenAlertOpenDialog(true);
    } else {
      const filesArray: any[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        const fileId = uuidv4();
        const fileWithId = Object.assign(file, { id: fileId, uploaded: false });
        filesArray.push(fileWithId);
      }
      setValue('files', [...(templateReport || []), ...filesArray]);
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.target.files as FileList;
    handleUploadFiles(fileList);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const fileList = e.dataTransfer.files;
      handleUploadFiles(fileList);
    }
  };

  const handleRemoveFile = (id: string | number) => {
    setValue(
      'files',
      (templateReport || []).filter((file: any) => file.id !== id)
    );
    if (typeof id === 'number') {
      const filesIdsToDelete = watch('files_to_delete') || [];
      setValue('files_to_delete', [...filesIdsToDelete, id]);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'space-around',
        height: '100vh',
        width: '100vw',
        padding: '40px',
        boxSizing: 'border-box',
      }}
    >
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}

      <Box
        sx={{
          padding: '40px',
          background: '#FFFFFF',
          border: '1px solid #D3ECF7',
          boxShadow: '0px 0px 45px rgba(0, 94, 253, 0.08)',
          borderRadius: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <img
            src={LEIAALogo1}
            style={{ width: '228.12px', height: '99.41px' }}
            alt="LEIAALogo1"
          />
          <img
            src={process.env.REACT_APP_CLIENT_LOGO_URL}
            style={{
              width: `calc(228.12px * ${process.env.REACT_APP_CLIENT_LOGO_RATIO})`,
              height:
                process.env.REACT_APP_CLIENT_LOGO_FULLHEIGHT === 'true'
                  ? '100%'
                  : `calc(99.41px * ${process.env.REACT_APP_CLIENT_LOGO_RATIO})`,
              marginLeft: '15px',
              borderLeft: '1px solid #e5e5e5',
            }}
            alt="Clients logo"
          />
        </Box>
        <Box sx={matterInformationWrapperStyles}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* FORM */}
            {loading && (
              <Modal
                open={loading}
                onClose={() => console.log('closed')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  background: 'rgba(211, 236, 246, 0.70)',
                  backdropFilter: 'blur(2px)',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '736px',
                    padding: '48px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '24px',
                    border: '1px solid #D3ECF6',
                    background: '#FFF',
                    boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      '& > span': {
                        color: '#0053FF',
                      },
                    }}
                  >
                    <CircularProgress />
                    <Typography
                      sx={{
                        ...sofiaProBoldFontStyles,
                        color: '#202020',
                        fontSize: '20px',
                        fontWeight: '500',
                        marginTop: '10px',
                      }}
                    >
                      Uploading...
                    </Typography>
                  </Box>
                </Box>
              </Modal>
            )}
            <Box sx={matterInformationFormWrapperStyles}>
              <Box sx={matterInformationInputWrapperStyles}>
                <Box>
                  {templateReport && templateReport.length > 0 && (
                    <Box sx={{ maxHeight: '200px', overflowY: 'scroll' }}>
                      <MatterAddedDocumentsInformation
                        matterDocuments={templateReport}
                        handleRemoveFile={handleRemoveFile}
                        showHeader={false}
                      />
                    </Box>
                  )}
                  <LEIAAUploadFileArea
                    title="Select a document to import."
                    subTitle=""
                    handleDrag={handleDrag}
                    handleAddFile={handleAddFile}
                    uploadAreaStyles={{
                      ...matterQuestionnaireUploadAreaWrapperStyles,
                      paddingLeft: '0px',
                    }}
                  />
                  {templateReport && (
                    <Box
                      sx={{
                        alignItems: 'flex-end!important',
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{ ...primaryButtonStyles() }}
                        type="submit"
                      >
                        <Typography>Submit</Typography>
                        <span className="material-icons-round">save</span>
                      </Button>
                    </Box>
                  )}
                  <LEIAADialog
                    open={openAlertOpenDialog}
                    onClose={() => setOpenAlertOpenDialog(false)}
                    title="alert"
                    description="We have detected similar existing files. Please change the final name if you want to add this file."
                    simpleButtonText="Ok"
                    onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
                    isTextAlign
                  />
                  <LEIAADialog
                    open={openUploadCompleteDialog}
                    onClose={() => setOpenUploadCompleteDialog(false)}
                    title="Upload Complete"
                    description="Files Successfuly Uploaded"
                    simpleButtonText="Ok"
                    onSimpleButtonClick={() =>
                      setOpenUploadCompleteDialog(false)
                    }
                    isTextAlign
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalFileUploadPortal;
