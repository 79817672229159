// eslint-disable-next-line import/no-extraneous-dependencies
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { documentDataStreamRequest } from '../api/documents';
import PDFdisplay from '../components/doc-sharing/PDFdisplay';
import {
  docshareActionButtonStyles,
  docsharePaginatorStyles,
} from './styles/docsharing';

interface DocSharingProps {
  docId: any;
  currentUser: any;
  sendSocketMessage: any;
  setOpenDocSharing: any;
  receivedMessage: any;
}

const DocSharing = ({
  docId,
  currentUser,
  sendSocketMessage,
  setOpenDocSharing,
  receivedMessage,
}: DocSharingProps) => {
  const [byteStream, setByteStream] = useState<Uint8Array>();
  const [totalNumPages, setTotalNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [showNotification, setShowNotification] = useState(false);
  // Inside the DocSharing component
  const [selectedText, setSelectedText] = useState<string>('');
  const boxRef = useRef<HTMLDivElement>(null);

  const handleDocumentByteStream = async () => {
    const documentData = documentDataStreamRequest(docId);
    const byteCharacters = window.atob(await documentData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const data = new Uint8Array(byteNumbers);
    setByteStream(data);
  };

  useEffect(() => {
    handleDocumentByteStream();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (selection) {
      const selectedTextString = selection.toString();

      setSelectedText(selectedTextString);
    }
  };

  const searchTagByText = (searchText: string) => {
    const elements = document.querySelectorAll('span[role="presentation"]');

    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];

      if (
        element.textContent?.includes(searchText) ||
        element.textContent?.toLowerCase().includes(searchText)
      ) {
        return element;
      }
    }

    return false;
  };

  // action handlers

  const setPage = (number: number) => {
    setCurrentPage(number);
  };

  const handleHighlight = (highlight: any, tag: any) => {
    const targetTag = tag as HTMLElement;
    const spanText = (targetTag as HTMLElement).textContent;

    if (spanText) {
      if (document.getElementsByTagName('mark')[0] !== undefined) {
        const markElement = document.getElementsByTagName('mark')[0];

        const text = markElement.textContent; // Get the text content of the <mark> element
        if (highlight === text) {
          return;
        }

        markElement.parentNode?.replaceChild(
          document.createTextNode(text || ''),
          markElement
        );
      }
      const startIndex = spanText?.indexOf(highlight);

      if (startIndex !== -1) {
        // Create a new text node with the content before the search text
        const beforeText = document.createTextNode(
          spanText.substring(0, startIndex)
        );
        targetTag.appendChild(beforeText);

        // Create the mark tag for the search text
        const markTag = document.createElement('mark');
        markTag.textContent = highlight;
        targetTag.appendChild(markTag);

        // Remove the original text node containing the search text
        const originalText = targetTag.firstChild as Node;
        if (originalText) {
          targetTag.removeChild(originalText);
        }

        // Append the original content after the search text
        const afterText = document.createTextNode(
          spanText.substring(startIndex + highlight.length)
        );

        targetTag.appendChild(afterText);
      }
      targetTag.scrollIntoView();
    }
  };

  const handleFocus = (tag: any) => {
    const targetTag = tag as HTMLElement;
    targetTag.scrollIntoView();
    targetTag.classList.add('underline-animation');
    setTimeout(() => {
      targetTag.classList.remove('underline-animation');
    }, 1500);
  };

  // message triggers and receivers
  /* mock messages examples
    { highlight: 'The incident was said to have occurred on May 14' }
    { page: 2 } # page number to go
    { focus: 'The crime scene, the coffee shop where the theft took place, was examined by' }
    { open: 3 }  # doc id to load
  */

  const goToPreviousPage = () => {
    sendSocketMessage({
      page: currentPage - 1,
    });
    setPage(currentPage - 1);
  };

  const goToNextPage = () => {
    sendSocketMessage({
      page: currentPage + 1,
    });
    setPage(currentPage + 1);
  };

  const onSubmitHighlight = async () => {
    if (selectedText) {
      const tag = searchTagByText(selectedText);
      if (tag) {
        sendSocketMessage({
          highlight: selectedText,
        });
        handleHighlight(selectedText, tag);
      } else {
        setShowNotification(true);
      }
    }
  };

  const onSubmitFocus = async () => {
    const tag = searchTagByText(selectedText);
    if (tag) {
      sendSocketMessage({
        focus: selectedText,
      });
      handleFocus(tag);
    } else {
      setShowNotification(true);
    }
  };

  // recieved message handler
  const documentManipulationHandler = () => {
    let renderManipulations: any;
    const isRenderReady = () => {
      if (document.querySelectorAll('span[role="presentation"]')) {
        clearInterval(renderManipulations);
        if (receivedMessage) {
          switch (Object.keys(receivedMessage)[0]) {
            case 'highlight': {
              if (receivedMessage.highlight) {
                const tag = searchTagByText(receivedMessage.highlight);
                handleHighlight(receivedMessage.highlight, tag);
              }
              break;
            }

            case 'page': {
              if (receivedMessage.page) {
                setPage(receivedMessage.page);
              }
              break;
            }

            case 'focus': {
              if (receivedMessage.focus) {
                const tag = searchTagByText(receivedMessage.focus);
                handleFocus(tag);
              }
              break;
            }

            case 'open': {
              if (receivedMessage.open) {
                if (receivedMessage.open === 'CLOSE_SIGNAL') {
                  setOpenDocSharing(undefined);
                } else if (receivedMessage.open !== docId) {
                  setOpenDocSharing(receivedMessage.open);
                }
              }
              break;
            }

            default:
              break;
          }
        }
      } else {
        console.error('render in progress....');
      }
    };

    renderManipulations = setInterval(isRenderReady, 500);
  };

  useEffect(() => {
    if (receivedMessage) {
      documentManipulationHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedMessage]); // Only re-run the effect if message change

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {byteStream && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          {currentUser.role !== 'Guest' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: '16px',
              }}
            >
              {totalNumPages !== 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={currentPage === 1}
                    onClick={goToPreviousPage}
                    id="prev-page"
                    sx={{
                      ...docsharePaginatorStyles,
                    }}
                  >
                    <span
                      style={{ color: 'white' }}
                      className="material-icons-round"
                    >
                      arrow_back_ios
                    </span>
                  </Button>
                  <Button
                    variant="contained"
                    disabled={currentPage === totalNumPages}
                    onClick={goToNextPage}
                    id="next-page"
                    sx={{
                      ...docsharePaginatorStyles,
                    }}
                  >
                    <span
                      style={{ color: 'white' }}
                      className="material-icons-round"
                    >
                      arrow_forward_ios
                    </span>
                  </Button>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  gap: '8px',
                }}
              >
                <Button
                  sx={{
                    ...docshareActionButtonStyles,
                  }}
                  onClick={onSubmitHighlight}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      marginRight: '8px',
                      color: 'white',
                    }}
                    className="material-icons-round"
                  >
                    border_color
                  </span>
                  <Typography>Highlight text</Typography>
                </Button>
                <Button
                  sx={{
                    ...docshareActionButtonStyles,
                  }}
                  onClick={onSubmitFocus}
                >
                  <span
                    style={{
                      marginRight: '8px',
                      color: 'white',
                      fontSize: '16px',
                    }}
                    className="material-icons-round"
                  >
                    filter_center_focus
                  </span>
                  <Typography>Focus Text</Typography>
                </Button>
              </Box>
            </Box>
          )}
          <Box ref={boxRef} onMouseUp={handleTextSelection}>
            <PDFdisplay
              byteStream={byteStream}
              currentPage={currentPage}
              setTotalNumPages={setTotalNumPages}
              totalNumPages={totalNumPages}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default DocSharing;
