import { karlaProRegularFontStyles } from '../../../../styles/textStyles';

export const wikiTemplateWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
};

export const wikiTemplateFormWrapperStyles = {
  display: 'flex',
  width: '608px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  '& > p': {
    color: '#202020',
    fontSize: '14px',
    ...karlaProRegularFontStyles,
    lineHeight: '22px',
  },
};

export const wikiTemplateInputWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
    '& > div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',
      alignSelf: 'stretch',
    },
  },
};

export const wikiQuestionnaireAddButtonWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
};

export const wikiTemplateQuestionInputWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  alignSelf: 'stretch',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    alignSelf: 'stretch',
    '& > div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
      '& > label.MuiFormLabel-root': {
        display: 'flex',
        padding: '0px 12px',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        color: '#464B53',
        fontSize: '14px',
        ...karlaProRegularFontStyles,
        lineHeight: '22px',
      },
      '& > div.MuiFormControl-root': {
        margin: '0px',
        padding: '0px',
      },
    },
  },
};

export const wikiTemplateEmailButtonStyles = {
  color: '#949CA9',
  textAlign: 'center',
  fontSize: '13px',
  ...karlaProRegularFontStyles,
  fontStyle: 'italic',
  fontWeight: '400',
  width: '352px',
};

export const wikiTemplateEmailHelperTitleStyles = {
  width: '365px',
  color: '#69707B',
  fontSize: '10px',
  lineHeight: '18px',
  marginTop: '-20px',
  marginLeft: '12px',
};

export const wikiTemplateEmailHelperTextStyles = {
  width: '365px',
  color: '#69707B',
  fontSize: '10px',
  lineHeight: '18px',
  marginLeft: '22px',
};
