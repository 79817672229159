import { Box, Divider, Stack, Typography, Tooltip, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import api from '../api';
import { fetchIncidentListRequest } from '../api/homeportal';
import LEIAALogo1 from '../assets/LEIAALogo1.png';
import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
  sofiaProBoldFontStyles,
} from '../styles/textStyles';

interface StatusGraphProps {
  servicesStatusList: any[];
}

interface IncidentListProps {
  incidentList: any[];
}

const getFormattedDate = (date: Date) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${month} ${day}, ${year}`;
};

const getStatusColor = (status: string | undefined) => {
  switch (status) {
    case 'warning':
      return '#FFA500';
    case 'online':
      return '#07c639';
    case 'error':
      return '#f45a5b';
    default:
      return '#f45a5b'; // Default to error color if status is undefined or unrecognized
  }
};

const getStatusIcon = (status: string | undefined) => {
  switch (status) {
    case 'warning':
      return 'report_problem';
    case 'online':
      return 'verified';
    case 'error':
      return 'new_releases';
    default:
      return 'new_releases'; // Default to new_releases icon if status is undefined or unrecognized
  }
};

const focusIncidents = (date: string) => {
  const incidentListDiv = document.getElementById('incident-list');

  if (incidentListDiv) {
    const pTags = Array.from(incidentListDiv.getElementsByTagName('p'));
    const matchingPTag = pTags.find((pTag) => pTag.innerText === date);

    if (matchingPTag) {
      matchingPTag.scrollIntoView({ behavior: 'smooth', block: 'center' });
      matchingPTag.classList.add('focused');

      setTimeout(() => {
        matchingPTag.classList.remove('focused');
      }, 2000);
    } else {
      console.log('No matching p tag found for date:', date);
    }
  }
};

const IncidentList = ({ incidentList }: IncidentListProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        gap: '16px',
        width: '100%',
      }}
      id="incident-list"
    >
      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          textTransform: 'uppercase',
          fontSize: '20px',
          fontWeight: '500',
          width: '100%',
        }}
      >
        Incidents list
        <span
          style={{
            fontSize: '12px',
            ...karlaProRegularItalicFontStyles,
            marginBottom: '16px',
            textTransform: 'lowercase',
            marginLeft: '5px',
          }}
        >
          past 90 days
        </span>
      </Typography>

      {incidentList.length === 0 ? (
        <Typography sx={{ width: '100%' }}>No incidents data</Typography>
      ) : (
        incidentList.map((day) => (
          <Box key={uuidv4()} sx={{ width: '100%' }}>
            <Typography
              sx={{
                fontSize: '16px',
                ...karlaProBoldFontStyles,
              }}
            >
              {day.date}
            </Typography>
            <Divider sx={{ width: '100%', border: '1px solid #D3ECF7' }} />

            {day.incidents.length > 0 ? (
              day.incidents.map((incident: any) => (
                <Box sx={{ marginBottom: '16px' }} key={uuidv4()}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      ...karlaProRegularFontStyles,
                      marginBottom: '5px',
                    }}
                  >
                    {incident.description}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      ...karlaProRegularFontStyles,
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        marginRight: '5px',
                      }}
                    >
                      Status:
                    </span>
                    {incident.status}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      ...karlaProRegularFontStyles,
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        marginRight: '5px',
                      }}
                    >
                      Time:
                    </span>
                    {incident.time}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: '16px',
                  ...karlaProRegularItalicFontStyles,
                  marginBottom: '16px',
                }}
              >
                No incidents for this day
              </Typography>
            )}
          </Box>
        ))
      )}
    </Box>
  );
};

const StatusGraph = ({ servicesStatusList }: StatusGraphProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        gap: '16px',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          textTransform: 'uppercase',
          fontSize: '20px',
          fontWeight: '500',
          width: '100%',
        }}
      >
        LEIAA Services
        <span
          style={{
            fontSize: '12px',
            ...karlaProRegularItalicFontStyles,
            marginBottom: '16px',
            textTransform: 'lowercase',
            marginLeft: '5px',
          }}
        >
          past 90 days
        </span>
      </Typography>

      {servicesStatusList.map((service) => (
        <Box key={uuidv4()} sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontSize: '16px',
              ...karlaProBoldFontStyles,
            }}
          >
            {service.name}
          </Typography>
          <Divider
            sx={{
              width: '100%',
              border: '1px solid #D3ECF7',
              marginBottom: '5px',
            }}
          />

          {service.history.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {service.history.map((status: any) => (
                <Tooltip
                  key={uuidv4()}
                  title={`${status.date} - ${status.occurrence} occurrences`}
                  arrow
                  onClick={() => focusIncidents(status.date)}
                >
                  <Box
                    sx={{
                      flex: '1',
                      height: '30px',
                      backgroundColor: status.functional
                        ? '#07c639 !important'
                        : '#f45a5b !important',
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
          ) : (
            <Typography
              sx={{
                fontSize: '16px',
                ...karlaProRegularItalicFontStyles,
                marginBottom: '16px',
              }}
            >
              No status history for this service
            </Typography>
          )}
        </Box>
      ))}

      {servicesStatusList.length === 0 && (
        <Typography sx={{ width: '100%' }}>No services data</Typography>
      )}
    </Box>
  );
};

const LiveTracker = ({ servicesStatusList }: StatusGraphProps) => {
  const [frontendStatus, setFrontendStatus] = useState<string>();
  const [backendStatus, setBackendStatus] = useState<string>();
  const [aiStatus, setAiStatus] = useState<string>();
  const [interviewStatus, setInterviewStatus] = useState<string>();

  const hasOccurrencesToday = (serviceName: string) => {
    const service = servicesStatusList.find(
      (serviceData) => serviceData.name === serviceName
    );

    if (!service) {
      return false;
    }

    const currentDateFormatted = getFormattedDate(new Date());

    return service.history.some(
      (entry: any) =>
        entry.date === currentDateFormatted && entry.occurrence > 0
    );
  };

  const handleFetchBackendStatus = async () => {
    try {
      const response = await api.get(`/heartbeat`);
      if (response.data.alive) {
        if (hasOccurrencesToday('Backend')) {
          setBackendStatus('warning');
        } else {
          setBackendStatus('online');
        }
      } else {
        setBackendStatus('error');
      }
    } catch (error) {
      console.error('Error fetching backend status:', error);
      setBackendStatus('error');
    }
  };

  const handleFetchAiStatus = async () => {
    try {
      const url = `${process.env.REACT_APP_AI_URL}/v1/heartbeat`;

      axios
        .get(url)
        .then((response) => {
          if (response.data.status === 'OK') {
            if (hasOccurrencesToday('Artificial Intelligence')) {
              setAiStatus('warning');
            } else {
              setAiStatus('online');
            }
          }
        })
        .catch((error) => {
          setAiStatus('error');
        });
    } catch (error) {
      console.error('Error fetching AI status:', error);
      setAiStatus('error');
    }
  };

  const handleFrontendStatus = async () => {
    try {
      // not ideal till we get external service for this status page but:
      if (hasOccurrencesToday('Frontend')) {
        setFrontendStatus('warning');
      } else {
        setFrontendStatus('online');
      }
    } catch (error) {
      setFrontendStatus('error');
    }
  };

  const handleInterviewStatus = async () => {
    try {
      // not ideal till we get a way of testing every black boxed IS service
      if (hasOccurrencesToday('Interview')) {
        setInterviewStatus('warning');
      } else {
        setInterviewStatus('online');
      }
    } catch (error) {
      setInterviewStatus('error');
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_AI === 'false') {
      handleFetchAiStatus();
    }
    handleFetchBackendStatus();
    handleFrontendStatus();
    handleInterviewStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        gap: '16px',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          ...sofiaProBoldFontStyles,
          color: '#202020',
          textTransform: 'uppercase',
          fontSize: '20px',
          fontWeight: '500',
          width: '100%',
        }}
      >
        LEIAA Live Status
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              padding: '16px',
              gap: '16px',
              width: '100%',
              backgroundColor: getStatusColor(frontendStatus),
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                ...karlaProBoldFontStyles,
                color: 'white',
              }}
            >
              Frontend
            </Typography>
            <span style={{ color: 'white' }} className="material-icons-round">
              {getStatusIcon(frontendStatus)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              padding: '16px',
              gap: '16px',
              width: '100%',
              backgroundColor: getStatusColor(backendStatus),
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                ...karlaProBoldFontStyles,
                color: 'white',
              }}
            >
              Backend
            </Typography>
            <span style={{ color: 'white' }} className="material-icons-round">
              {getStatusIcon(backendStatus)}
            </span>
          </Box>
        </Grid>
        {process.env.REACT_APP_DISABLE_AI === 'false' && (
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                padding: '16px',
                gap: '16px',
                width: '100%',
                backgroundColor: getStatusColor(aiStatus),
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  ...karlaProBoldFontStyles,
                  color: 'white',
                }}
              >
                Artificial Intelligence
              </Typography>
              <span style={{ color: 'white' }} className="material-icons-round">
                {getStatusIcon(aiStatus)}
              </span>
            </Box>
          </Grid>
        )}

        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              padding: '16px',
              gap: '16px',
              width: '100%',
              backgroundColor: getStatusColor(interviewStatus),
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                ...karlaProBoldFontStyles,
                color: 'white',
              }}
            >
              Interview
            </Typography>
            <span style={{ color: 'white' }} className="material-icons-round">
              {getStatusIcon(interviewStatus)}
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const Status = () => {
  const [incidentList, setIncidentList] = useState([]);
  const [servicesStatusList, setServicesStatusList] = useState([]);

  const handleFetchIncidentList = async () => {
    try {
      const response = await fetchIncidentListRequest('incidents');
      setIncidentList(response);
    } catch (error: unknown) {
      console.log('error');
    }
  };

  const handleFetchServicesStatusList = async () => {
    try {
      const response = await fetchIncidentListRequest('status');
      setServicesStatusList(response);
    } catch (error: unknown) {
      console.log('error');
    }
  };

  useEffect(() => {
    handleFetchIncidentList();
    handleFetchServicesStatusList();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Change to column for better stacking
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: '10px 50px',
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: '100%' }}
      >
        {/* image */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '121px',
            width: '100%',
          }}
        >
          <img
            src={LEIAALogo1}
            style={{ width: '228.12px', height: '99.41px' }}
            alt="LEIAALogo1"
          />
        </Box>
        {/* Header */}
        <Box>
          <Typography
            sx={{
              ...sofiaProBoldFontStyles,
              color: '#202020',
              textTransform: 'uppercase',
              fontSize: '25px',
              fontWeight: '500',
              textAlign: 'center',
              margin: 'auto',
            }}
          >
            LEIAA Status tracker
          </Typography>
        </Box>

        {/* Live Tracker */}
        <Box sx={{ width: '100%' }}>
          <LiveTracker servicesStatusList={servicesStatusList} />
        </Box>

        {/* Status Graph */}
        <Box sx={{ width: '100%' }}>
          <StatusGraph servicesStatusList={servicesStatusList} />
        </Box>

        {/* Incident List */}
        <Box sx={{ width: '100%' }}>
          <IncidentList incidentList={incidentList} />
        </Box>
      </Stack>
    </Box>
  );
};

export default Status;
