import {
  Box,
  List,
  Typography,
  CircularProgress,
  Divider,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import {
  karlaProRegularFontStyles,
  karlaProRegularItalicFontStyles,
} from '../../../styles/textStyles';
import {
  allocatedUsersBoxWrapperStyles,
  allocatedUsersListStyles,
  matterAllocatedUsersBoxWrapperStyles,
  matterAllocatedUsersListStyles,
} from './styles';
import AllocatedUsersListItem from './AllocatedUsersListItem';
import { AllocatedUsersItem } from './types';
import { AllocatedUsersResponse } from '../../../types/homeportal';
import { allocatedUsersRequest } from '../../../api/homeportal';

interface AllocatedUsersProps {
  allocatedUsersSent?: any;
  matter?: boolean;
}

const AllocatedUsers = ({
  allocatedUsersSent,
  matter = false,
}: AllocatedUsersProps) => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [allocatedUsersData, setAllocatedUsersData] =
    useState<AllocatedUsersResponse | null>(null);

  const handleFetchAllocatedUsers = async () => {
    setLoading(true); // Start loading

    try {
      const users = await allocatedUsersRequest();
      setAllocatedUsersData(users as AllocatedUsersResponse);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          'An unknown error related to the allocated users occurred.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!allocatedUsersSent) {
      handleFetchAllocatedUsers();
    } else {
      setAllocatedUsersData({ allocated_users: allocatedUsersSent });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={
        matter
          ? matterAllocatedUsersBoxWrapperStyles
          : allocatedUsersBoxWrapperStyles
      }
    >
      <Typography
        variant="h5"
        sx={{
          ...karlaProRegularFontStyles,
          fontWeight: 400,
          fontSize: '17px',
          lineHeight: '30px',
          color: '#140070',
        }}
      >
        Allocated Users
      </Typography>
      {
        // eslint-disable-next-line no-nested-ternary
        loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              '& > span': {
                color: '#0053FF',
              },
            }}
          >
            <CircularProgress />
          </Box>
        ) : allocatedUsersData?.allocated_users.length === 0 ? (
          <Box
            sx={{
              height: '100%',
              width: '100',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '0px !important',
            }}
          >
            <Typography
              sx={{ ...karlaProRegularItalicFontStyles, color: '#464B53' }}
            >
              No users found.
            </Typography>
          </Box>
        ) : (
          <List
            className="scrollable-content"
            sx={
              matter ? matterAllocatedUsersListStyles : allocatedUsersListStyles
            }
          >
            {allocatedUsersData?.allocated_users.map(
              (allocatedUser: AllocatedUsersItem, index: number) => {
                const allocatedUsersLength =
                  allocatedUsersData?.allocated_users.length || 0;
                const isLastItem = index === allocatedUsersLength - 1;

                return (
                  <Fragment key={allocatedUser.id}>
                    <AllocatedUsersListItem allocatedUser={allocatedUser} />
                    {!isLastItem && (
                      <Divider sx={{ border: '1px solid #D3ECF7' }} />
                    )}
                  </Fragment>
                );
              }
            )}
          </List>
        )
      }
    </Box>
  );
};

export default AllocatedUsers;
