import { Box, List, Typography } from '@mui/material';
import { karlaProRegularItalicFontStyles } from '../../../styles/textStyles';
import {
  detailsInterviewOutlineDataStyles,
  interviewOutlinesStyles,
  questionsListStyles,
} from './styles';

interface LEIAAInterviewOutlineProps {
  interview?: any;
}
const LEIAAInterviewOutline = ({
  interview = [],
}: LEIAAInterviewOutlineProps) => {
  return (
    <Box sx={detailsInterviewOutlineDataStyles}>
      <Typography>Interview Outline</Typography>
      <Box>
        <List className="scrollable-content" sx={interviewOutlinesStyles}>
          {interview.interview_outline &&
          interview.interview_outline.length > 0 ? (
            interview.interview_outline.map((question: any, index: any) => (
              <Box key={index} sx={questionsListStyles}>
                <Box>
                  <Typography>{index + 1}</Typography>
                </Box>
                <Box>
                  <Typography>{question.question}</Typography>
                  {question.description ? (
                    <Box>
                      <Typography>{question.description}</Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{
                ...karlaProRegularItalicFontStyles,
                color: '#69707B',
                fontSize: '14px',
              }}
            >
              No interview questions.
            </Typography>
          )}
        </List>
      </Box>
    </Box>
  );
};

export default LEIAAInterviewOutline;
