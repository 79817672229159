import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  karlaProRegularFontStyles,
  sofiaProBoldFontStyles,
} from '../../../styles/textStyles';
import { LEIAAUploadFileArea, LEIAADialog } from '../../inputs';
import { matterQuestionnaireUploadAreaWrapperStyles } from '../../matters/matter-creation/matter-creation-steps/matter-information-step/styles';
import { MatterAddedDocumentsInformation } from '../../matters/matter-creation/sidepanel-information';
import { secondaryButtonStyles, primaryButtonStyles } from '../../styles';
import { controlBarGenericButtonStyles } from '../interview-control-bar/styles/styles';
import { uploadIntervieweeFilesOnInterviewRequest } from '../../../api/interviews';

interface FileWithId extends File {
  id: string;
  uploaded: boolean;
}

interface FileUploadModalProps {
  matterId: string | number;
  interviewDetails: any;
}

const FileUploadModal = ({
  matterId,
  interviewDetails,
}: FileUploadModalProps) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = useState(false);
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);
  const [repeatedFiles, setRepeatedFiles] = useState<FileWithId[]>([]);
  const [uploading, setUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  let filesWatcher = watch('files');

  const handleClose = () => {
    setValue('files', undefined);
    filesWatcher = watch('files');
    setRepeatedFiles([]);
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSuccessSnackbar = () => {
    setSnackbarOpen(true);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data: any) => {
    setUploading(true);
    try {
      await uploadIntervieweeFilesOnInterviewRequest(data, interviewDetails.id);
      showSuccessSnackbar();
      handleClose();
    } catch (error: any) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const handleUploadFiles = (fileList: FileList) => {
    setValue('matter', matterId);
    let existingFiles: FileWithId[] = [];

    if (fileList.length === 0) return;

    if (filesWatcher && filesWatcher.length > 0) {
      existingFiles = filesWatcher.filter((md: FileWithId) =>
        Array.from(fileList).some(
          (selectedFile) => md.name === selectedFile.name
        )
      );
      setRepeatedFiles(existingFiles);
    }

    if (existingFiles.length > 0) {
      setOpenAlertOpenDialog(true);
    } else {
      const filesArray: FileWithId[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const file = fileList[i];
        const fileId = uuidv4();
        const fileWithId = Object.assign(file, { id: fileId, uploaded: false });
        filesArray.push(fileWithId);
      }
      setValue('files', [...(filesWatcher || []), ...filesArray]);
    }
  };

  const handleAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const fileList = event.target.files as FileList;
    handleUploadFiles(fileList);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      const fileList = e.dataTransfer.files;
      handleUploadFiles(fileList);
    }
  };

  const handleRemoveFile = (id: string | number) => {
    setValue(
      'files',
      (filesWatcher || []).filter((file: FileWithId) => file.id !== id)
    );
    if (typeof id === 'number') {
      const filesIdsToDelete = watch('files_to_delete') || [];
      setValue('files_to_delete', [...filesIdsToDelete, id]);
    }
  };

  const handleUploadAndSubmit = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <Box sx={{ marginLeft: 'auto' }}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message="File uploaded successfully!"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          sx={{
            '& > div': {
              background: '#2f7c31',
            },
          }}
        />
        <Button
          variant="contained"
          sx={{
            ...controlBarGenericButtonStyles,
            width: '100%',
            border: !open
              ? '2px solid #464B53 !important'
              : '2px solid #0053FF !important',
            backgroundColor: '#464B53 !important',
            '&:hover': {
              border: '2px solid #0053FF !important',
            },
            '& > p': {
              textAlign: 'center',
              fontSize: '16px',
              ...karlaProRegularFontStyles,
              letterSpacing: '0.2px',
              textTransform: 'none',
            },
          }}
          onClick={() => setOpen(!open)}
        >
          <span style={{ marginRight: '8px' }} className="material-icons-round">
            upload_file
          </span>
          <Typography>Upload</Typography>
        </Button>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '736px',
              padding: '48px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '24px',
              border: '1px solid #D3ECF6',
              background: '#FFF',
              boxShadow: '0px 0px 45px 0px rgba(0, 94, 253, 0.08)',
            }}
          >
            {uploading ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  '& > span': {
                    color: '#0053FF',
                  },
                }}
              >
                <CircularProgress />

                <Typography
                  sx={{
                    ...sofiaProBoldFontStyles,
                    color: '#202020',
                    fontSize: '20px',
                    fontWeight: '500',
                    marginTop: '10px',
                  }}
                >
                  Uploading...
                </Typography>
              </Box>
            ) : (
              <>
                <Typography
                  id="modal-modal-title"
                  sx={{
                    ...karlaProRegularFontStyles,
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '22px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  If you have any documents that support your statements, please
                  upload them here.
                </Typography>
                <Box
                  sx={{
                    maxHeight: '130px',
                    overflowY: 'scroll',
                    marginBottom: '10px',
                  }}
                  className="scrollable-content"
                >
                  {filesWatcher && filesWatcher.length > 0 && (
                    // eslint-disable-next-line react/jsx-no-undef
                    <MatterAddedDocumentsInformation
                      matterDocuments={filesWatcher}
                      handleRemoveFile={handleRemoveFile}
                      showHeader={false}
                    />
                  )}
                </Box>
                <LEIAAUploadFileArea
                  title="Select a document to upload."
                  subTitle=""
                  handleDrag={handleDrag}
                  handleAddFile={handleAddFile}
                  uploadAreaStyles={{
                    ...matterQuestionnaireUploadAreaWrapperStyles,
                    paddingLeft: '0px',
                  }}
                />
                <LEIAADialog
                  open={openAlertOpenDialog}
                  onClose={() => setOpenAlertOpenDialog(false)}
                  title="alert"
                  description="We have detected similar existing files. Please change the final name if you want to add this file."
                  simpleButtonText="Ok"
                  onSimpleButtonClick={() => setOpenAlertOpenDialog(false)}
                  isTextAlign
                />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  sx={{ marginTop: '20px' }}
                >
                  <Box sx={{ width: '100%' }}>
                    <Button
                      variant="contained"
                      sx={{ ...secondaryButtonStyles(), width: '100%' }}
                      onClick={handleClose}
                    >
                      <Typography>Cancel</Typography>
                    </Button>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Button
                      variant="contained"
                      sx={{ ...primaryButtonStyles(), width: '100%' }}
                      disabled={!(filesWatcher && filesWatcher.length > 0)}
                      onClick={handleUploadAndSubmit}
                    >
                      {uploading ? (
                        <>
                          <Typography>Uploading</Typography>
                          <span className="material-icons-round">sync</span>
                        </>
                      ) : (
                        <>
                          <Typography>Upload</Typography>
                          <span className="material-icons-round">
                            upload_file
                          </span>
                        </>
                      )}
                    </Button>
                  </Box>
                </Stack>
              </>
            )}
          </Box>
        </Modal>
      </form>
    </>
  );
};

export default FileUploadModal;
