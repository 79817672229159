import { Box, InputLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Control, Controller, FieldValues } from 'react-hook-form';
import dayjs from 'dayjs';
import { useState } from 'react';
import { datePickerStyles } from './styles';

interface LEIAADatePickerProps {
  labelText?: string;
  inputPlaceholder: string;
  controllerName: string;
  control: Control<FieldValues>;
  required?: boolean;
  handleChange?: any;
  backgroundColor?: string;
  disabled?: boolean;
  disableHelper?: boolean;
}

export const OpenPickerIcon = () => (
  <Box sx={{ height: '24px', '& > span': { color: '#32353C' } }}>
    <span className="material-icons-round">today</span>
  </Box>
);

export const convertDateFormat = (inputDate: string) => {
  // Split the input date using the '/' separator
  const [day, month, year] = inputDate.split('/');
  // Rearrange the parts to the desired format
  const newDate = `${month}/${day}/${year}`;

  return newDate;
};

const LEIAADatePicker = ({
  labelText,
  inputPlaceholder,
  controllerName,
  control,
  required = true,
  handleChange,
  backgroundColor = 'unset',
  disabled = false,
  disableHelper = false,
}: LEIAADatePickerProps) => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const handleOpenPicker = () => {
    setIsPopperOpen(true);
  };

  const handleClosePicker = () => {
    setIsPopperOpen(false);
  };

  return (
    <Box>
      {labelText && (
        <InputLabel data-testid="matter-form-label-id" htmlFor={labelText}>
          {required ? '*' : ''}
          {labelText}
        </InputLabel>
      )}
      <Controller
        name={controllerName}
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...rest } }) => {
          return (
            <DatePicker
              disabled={disabled}
              onOpen={handleOpenPicker}
              onClose={handleClosePicker}
              value={
                rest.value !== '' && rest.value !== null
                  ? dayjs(convertDateFormat(rest.value))
                  : null
              }
              format="D/M/YYYY"
              slotProps={{
                textField: {
                  placeholder: inputPlaceholder,
                  helperText:
                    isPopperOpen && !disableHelper ? 'DD/MM/YYYY' : '',
                },
                popper: {
                  placement: 'bottom-start',
                },
              }}
              slots={{
                openPickerIcon: OpenPickerIcon,
              }}
              sx={{ ...datePickerStyles, background: backgroundColor }}
              onChange={(e) => {
                let formattedDate = '';

                if (e !== null) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  formattedDate = (e as any).$d!.toLocaleDateString('pt-PT', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  });
                }

                onChange(formattedDate);
                if (handleChange) {
                  handleChange(formattedDate);
                }
              }}
            />
          );
        }}
      />
    </Box>
  );
};

export default LEIAADatePicker;
