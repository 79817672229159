import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  batchDeleteTemplateRequest,
  getWikiTemplateDetailsRequest,
  templatesListRequest,
} from '../../api/wiki';
import { FilteredTemplatesListResponse } from '../../types/wiki';
import BaseButton from '../base/BaseButton';
import MatterTemplateViewer from '../matters/matter-creation/MatterTemplateViewer';
import CreationNotification from '../shared/creation-notification/CreationNotification';
import ListButtons from '../shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../shared/resource-page-header/styles';
import TemplatesListTable from '../shared/templates-list-table/TemplateListTable';
import { TemplatesListItem } from '../shared/templates-list-table/types';
import NotificationToast from '../shared/toast/NotificationToast';

const WikiTemplates = () => {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplatesListItem | null>(null);
  const [checkedTemplates, setCheckedTemplates] = useState([]);
  const [templatesListData, setTemplatesListData] =
    useState<FilteredTemplatesListResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showNotification, setShowNotification] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [clickedTemplate, setClickedTemplate] = useState<any>(null);
  const [showListDetailModal, setShowListDetailModal] = useState(false);

  const handleFetchTemplatesList = async (searchText = '') => {
    setLoading(true); // Start loading

    try {
      const response = await templatesListRequest(false, searchText);

      setTemplatesListData({
        templatesList: response.templatesList,
      });

      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setCheckedTemplates([]);
    }
  };

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setShowNotification(false);
    }, 5000);

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [showNotification]);

  useEffect(() => {
    handleFetchTemplatesList();
  }, []);

  const handleBatchDeleteTemplate = async () => {
    try {
      await batchDeleteTemplateRequest(checkedTemplates);
      await handleFetchTemplatesList();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleClickTemplate = async (template: any) => {
    setClickedTemplate(template);
    setShowListDetailModal(true);
  };

  const handleEditTemplate = async () => {
    try {
      if (selectedTemplate?.id) {
        getWikiTemplateDetailsRequest(selectedTemplate.id.toString()).then(
          (response) => {
            navigate(`/wiki/templates/edit/${selectedTemplate?.id}`, {
              state: { currentStep: 0, templateDetails: response },
            });
          }
        );
      }
      setRequestError(null);
      setShowNotification(false);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleListSearchbarInputChange = () => {
    const searchText = inputRef.current?.value || '';
    handleFetchTemplatesList(searchText);
  };

  return (
    <>
      {showNotification && (
        <NotificationToast
          showNotification={showNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowNotification(false)}
        />
      )}
      <MatterTemplateViewer
        template={clickedTemplate}
        templateList
        showListDetailModal={showListDetailModal}
        setShowListDetailModal={setShowListDetailModal}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {showArchiveNotification && (
          <CreationNotification
            title="Template archived successfully!"
            subTitle=""
            showNotification={showArchiveNotification}
            handleCloseNotification={() => setShowArchiveNotification(false)}
          />
        )}
        <BaseButton
          iconClass="material-icons-round"
          iconText="add"
          buttonText="Create template"
          linkTo="/wiki/templates/new"
          width="194px"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '20px',
            marginLeft: 'auto',
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search by template..."
            sx={{
              ...homePortalSearchInputStyles,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& > span': { color: '#69707B' } }}
                >
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              onChange: handleListSearchbarInputChange,
              inputRef,
            }}
            variant="standard"
          />

          {checkedTemplates.length > 0 && (
            <ListButtons
              deleteHandler={handleBatchDeleteTemplate}
              editHandler={handleEditTemplate}
              checkedResources={checkedTemplates}
              resourceName="template"
            />
          )}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TemplatesListTable
            templatesListData={templatesListData}
            loading={loading}
            listClass="templates-list"
            setCheckedTemplates={setCheckedTemplates}
            setSelectedTemplate={setSelectedTemplate}
            handleDetailTemplate={handleClickTemplate}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WikiTemplates;
